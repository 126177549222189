import { Transition } from "@headlessui/react";
import { Fragment } from "react";

const DialogOverlay = ({ unmount }: { unmount: boolean }) => (
  <Transition.Child
    as={Fragment}
    enter="ease-linear duration-100"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="ease-in duration-100"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
    unmount={unmount}
  >
    <div className="fixed inset-0 bg-gray-800 bg-opacity-90" />
  </Transition.Child>
);

export { DialogOverlay };
