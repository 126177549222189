import type { UseQueryOptions } from "@tanstack/react-query";
import type { TaskSerializer } from "@/utils/damage-prevention";
import type { FlexTicket } from "@/models";
import { useQueries, useQuery } from "@tanstack/react-query";
import { Task } from "@/models";
import { UrbintApi } from "@/utils/UrbintApi";
import { queryClient } from "@/api/client";
import { handleApiResponse } from "@/api/helpers";

const api = new UrbintApi();

// TODO: getMany seems to paginate.
// So we're using getPage. If there is ever
// more than 100 tasks for one ticket, we should
// implement the pagination logic here
const fetchTasks = (
  ticketId: number,
  withDeletedTasks = false,
  withAttachmentCount = false
) =>
  api
    .getPage<TaskSerializer>({
      endPoint: "tasks",
      page: 1,
      queryParams: {
        ticket: ticketId.toString(),
        withDelete: withDeletedTasks.toString(),
        attachment_count: withAttachmentCount.toString(),
      },
    })
    .then(handleApiResponse)
    .then((res) => res.results?.map((r) => new Task(r)));

//
// --- Export
//
const ticketTasksIdent = (
  ticketId: number,
  withDeletedTasks = false,
  withAttachmentCount = false
) => ["ticket/tasks", ticketId, withDeletedTasks, withAttachmentCount];

const useTasksForTicket = (
  ticketId: number,
  opts?: UseQueryOptions<Task[]>,
  withDeletedTasks = false,
  withAttachmentCount = false
) =>
  useQuery<Task[]>(
    ticketTasksIdent(ticketId, withDeletedTasks, withAttachmentCount),
    async () => {
      const tasks = await fetchTasks(
        ticketId,
        withDeletedTasks,
        withAttachmentCount
      );
      return tasks.sort((a, b) => a.compare(b));
    },
    opts
  );

const useTasksforTickets = (tickets: FlexTicket[]) =>
  useQueries({
    queries: tickets.map((ticket) => ({
      queryKey: ticketTasksIdent(ticket.id ?? 0, false, false),
      queryFn: () => fetchTasks(ticket.id ?? 0, false, false),
      enabled: !!ticket.id,
    })),
  });

const prefetchTasksForTicket = (ticketId: number) =>
  queryClient.prefetchQuery(ticketTasksIdent(ticketId, false, false), () =>
    fetchTasks(ticketId, false, false)
  );

const useTaskByIdForTicket = (
  ticketId: number,
  taskId: number,
  opts?: UseQueryOptions<Task | undefined>,
  includeDeletedTasks = false,
  withAttachmentCount = false
) => {
  const { data: tasks, isFetched } = useTasksForTicket(
    ticketId,
    {
      enabled: opts?.enabled !== false,
    },
    includeDeletedTasks,
    withAttachmentCount
  );
  return useQuery<Task | undefined>(
    ["ticket/tasks", ticketId, taskId, tasks],
    () => tasks?.find((task) => task.id === taskId),
    {
      ...opts,
      enabled: opts === undefined ? isFetched : opts.enabled && isFetched,
      keepPreviousData: true,
    }
  );
};

const invalidateUseTasksForTicketQuery = async (ticketId: number) => {
  await queryClient.invalidateQueries(ticketTasksIdent(ticketId, true));
  await queryClient.invalidateQueries(ticketTasksIdent(ticketId, false));
};

export {
  useTasksForTicket,
  useTaskByIdForTicket,
  invalidateUseTasksForTicketQuery,
  prefetchTasksForTicket,
  useTasksforTickets,
};
