import type {
  FilterDialogProps,
  FilterDialogState,
} from "./FilterDialog.types";
import type { dialogFooter } from "@/components/Dialog";
import type { TicketView } from "@/components/TicketViewEditorProvider";
import classNames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTicketViewEditor } from "@/components/TicketViewEditorProvider";
import { Dialog } from "@/components/Dialog";
import { useSavedViews } from "@/api";
import { FilterTabs } from "./FilterTabs";
import { FilterDialogHeader } from "./FilterDialogHeader";
import { FilterDialogFooter } from "./FilterDialogFooter/FilterDialogFooter";

const FilterDialog = ({
  isOpen,
  onClose,
  onCreate,
  onUpdate,
  onDelete,
}: FilterDialogProps) => {
  const {
    dirtyView,
    isDirty,
    isShared,
    name,
    setDirtyView,
    toSavedView,
    clearEmptyFilters,
  } = useTicketViewEditor();

  const [state, setState] = useState<FilterDialogState>({
    editMode: undefined,
    isNameTouched: false,
  });

  const [originalView, setOriginalView] = useState<TicketView>();

  useEffect(() => {
    setOriginalView(dirtyView);
  }, [isOpen]);

  const { data: savedViews } = useSavedViews();

  const isNameEmpty = !name;
  const isNameDuplicate = useMemo(() => {
    const lowerName = name.toLowerCase().trim();
    if (!savedViews) return false;
    for (const savedView of savedViews) {
      const editingSelf =
        state.editMode === "edit" && dirtyView.id === savedView.id;
      if (!editingSelf && lowerName === savedView.name.toLowerCase().trim())
        return true;
    }
    return false;
  }, [savedViews, name, dirtyView.id, state.editMode]);
  const isNameValid = !isNameEmpty && !isNameDuplicate;

  const isEditing = state.editMode !== undefined;

  //
  // --- Handlers
  //

  const handleSaveClick = useCallback(() => {
    if (isDirty) {
      const newDirtyView = clearEmptyFilters();
      const savedView = toSavedView(newDirtyView);
      if (state.editMode === "create") {
        onCreate(savedView);
      } else if (state.editMode === "edit") {
        onUpdate(savedView);
      }
    }
    setState((state) => ({
      ...state,
      isNameTouched: false,
      editMode: undefined,
    }));
  }, [
    toSavedView,
    isDirty,
    setState,
    state.editMode,
    onUpdate,
    onCreate,
    clearEmptyFilters,
  ]);

  const handleClose = useCallback(
    (applyChanges = false) => {
      if (applyChanges) {
        const newDirtyView = clearEmptyFilters();
        onClose(newDirtyView);
      } else {
        onClose(originalView!);
        // set dirtyView after closing so the user doesn't see the filters changing while closing
        setDirtyView(originalView!);
      }
    },
    [clearEmptyFilters, onClose]
  );

  const dialogHeader = (close: any) => (
    <FilterDialogHeader
      isShared={isShared}
      isEditing={isEditing}
      isDirty={isDirty}
      onClose={close}
      viewName={dirtyView.name}
      editMode={state.editMode}
      setFilterDialogState={setState}
      onDelete={onDelete}
      error={
        isNameEmpty && state.isNameTouched
          ? "This field is required."
          : undefined
      }
      warning={
        isNameDuplicate ? "A view with that name already exists." : undefined
      }
    />
  );

  const modalFooter: dialogFooter = {
    toRender: () => (
      <FilterDialogFooter
        handleClose={handleClose}
        handleSaveClick={handleSaveClick}
        isEditing={isEditing}
        isNameValid={isNameValid}
        isCreatingSavedView={state.editMode === "create"}
      />
    ),
  };

  return (
    <Dialog
      isOpen={isOpen}
      header={({ close }) => dialogHeader(close)}
      footer={modalFooter}
      theme={{
        content: `${classNames({
          "mt-14 sm:mt-8": isEditing,
        })}`,
        wrapper: "md:min-h-[40rem]",
        contentFullWidth: true,
      }}
      onClose={() => handleClose(false)}
    >
      <div className="flex flex-col flex-grow w-full h-full">
        <FilterTabs />
      </div>
    </Dialog>
  );
};

export { FilterDialog };
