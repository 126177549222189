import { useState } from "react";
import { useResponsive } from "@/hooks";
import { Header } from "./Header";
import { SortAndFilterTab } from "./SortAndFilterTab";
import { ColumnsTab } from "./ColumnsTab";
import { ColumnsDetails } from "./ColumnsDetails";

const FilterDialogTab = {
  sortAndFilters: "Sort and filters",
  columns: "Columns",
  details: "Sharing and details",
};
const MobileDialogTabs = {
  sortAndFilters: FilterDialogTab.sortAndFilters,
  details: FilterDialogTab.details,
};

const FilterTabs = () => {
  const { isSm } = useResponsive();
  const tabs = isSm ? FilterDialogTab : MobileDialogTabs;
  const [activeTab, setActiveTab] = useState(tabs.sortAndFilters);

  const onTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  const tabToRender = (tab: string) => {
    switch (tab) {
      case FilterDialogTab.sortAndFilters:
        return <SortAndFilterTab />;
      case FilterDialogTab.columns:
        return <ColumnsTab />;
      case FilterDialogTab.details:
        return <ColumnsDetails />;
      default:
        return <SortAndFilterTab />;
    }
  };

  return (
    <>
      <Header tabs={tabs} value={activeTab} onChange={onTabClick} />
      <div className="p-6">{tabToRender(activeTab)}</div>
    </>
  );
};

export { FilterTabs };
