/* eslint-disable no-promise-executor-return */
import type { Task } from "@/models";
import { prefetchUseAnswerSet, prefetchTaskType } from "@/api";
import { prefetchResponseForms } from "./prefetchResponseForms";

const prefetchSingleTaskInformation = async (task: Task) => {
  const { id, taskTypeId } = task ?? {};

  await prefetchUseAnswerSet(id);
  await prefetchTaskType(taskTypeId);
  await prefetchResponseForms(task);
};

export { prefetchSingleTaskInformation };
