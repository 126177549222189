import type { DialogContentProps } from "./DialogContent.types";
import { Dialog, Transition } from "@headlessui/react";
import classnames from "classnames";
import { Fragment } from "react";
import { modalSize } from "../Dialog.constants";
import { DialogHeader } from "./DialogHeader";
import { DialogBody } from "./DialogBody";
import { DialogFooter } from "./DialogFooter";

const DialogContent = ({
  theme,
  header,
  onClose,
  dataTestId,
  children,
  footer,
  variant,
  size,
  unmount,
}: DialogContentProps) => (
  <Transition.Child
    enter="ease-out duration-300"
    enterFrom="opacity-0 scale-95"
    enterTo="opacity-100 scale-100"
    leave="ease-in duration-200"
    leaveFrom="opacity-100 scale-100"
    leaveTo="opacity-0 scale-95"
    as={Fragment}
    unmount={unmount}
  >
    <Dialog.Panel
      className={classnames(
        "text-left w-full max-h-full flex flex-col shadow-xl rounded-lg bg-white",
        modalSize[size],
        theme?.wrapper
      )}
      data-testid={dataTestId}
    >
      <DialogHeader
        header={header}
        variant={variant}
        onClose={onClose}
        theme={theme}
      />
      <DialogBody theme={theme}>{children} </DialogBody>
      <DialogFooter footer={footer} />
    </Dialog.Panel>
  </Transition.Child>
);

export { DialogContent };
