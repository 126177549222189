import type { TaskStepSerializer } from "@/utils/damage-prevention";
import { UrbintModel } from "@/utils/UrbintModel";

class TaskStep extends UrbintModel<TaskStepSerializer> {
  get id() {
    return this.castNumber(this.data.id);
  }

  get name() {
    return this.castString(this.data.name);
  }

  get sequence() {
    return this.castNumber(this.data.sequence);
  }

  get initial() {
    return this.castBool(this.data.initial);
  }

  get terminating() {
    return this.castBool(this.data.terminating);
  }

  get taskTypeId() {
    return this.castNumber(this.data.task_type);
  }

  compare(instance: TaskStep) {
    return this.name.localeCompare(instance.name);
  }

  serialize(): TaskStepSerializer {
    return this.data;
  }
}

export { TaskStep };
