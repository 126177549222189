import { BANNER_STATUS, BANNER_TYPE } from "./NetworkStatusBanner.constants";

const getBannerStructure = (type: BANNER_TYPE) => {
  switch (type) {
    case BANNER_TYPE.SUCCESS:
      return BANNER_STATUS.SUCCESS;
    case BANNER_TYPE.ERROR:
      return BANNER_STATUS.ERROR;
    case BANNER_TYPE.SYNCING:
      return BANNER_STATUS.SYNCING;
    case BANNER_TYPE.OFFLINE_FORM_DEFAULT:
      return BANNER_STATUS.OFFLINE_FORM_DEFAULT;
    case BANNER_TYPE.OFFLINE_FORM_SUBMITTED:
      return BANNER_STATUS.OFFLINE_FORM_SUBMITTED;
    case BANNER_TYPE.OFFLINE_ATTACHMENTS_SUBMITTED:
      return BANNER_STATUS.OFFLINE_ATTACHMENTS_SUBMITTED;
    case BANNER_TYPE.OFFLINE_ATTACHMENT_DELETED:
      return BANNER_STATUS.OFFLINE_ATTACHMENTS_DELETED;
    default:
      return BANNER_STATUS.OFFLINE;
  }
};

export { getBannerStructure };
