import type { TicketListItemProps } from "./TicketListItem.types";
import React from "react";
import cx from "classnames";

import isEqual from "lodash/isEqual";

import { TicketCard } from "@/components/TicketCard";

const TicketListItem = React.memo(
  ({
    ticket,
    selectedTasks,
    isSelected,
    isHovered,
    onHover,
    selectMode,
    isShowingTasks,
    onSelect,
    onSelectTask: onTaskSelect,
    shouldShowTask,
  }: TicketListItemProps) => {
    // Moved this in here to avoid sending a new ref here every list iteration
    ticket.tasks = ticket.tasks?.filter(shouldShowTask);

    return (
      <div
        className={cx("bg-white w-full hover:bg-gray-10 relative", {
          "border-brand-40 border-l-4 border-r": isSelected,
          "pl-1 pr-[1px] border-neutral-shade-border-light": !isSelected,
          "bg-gray-10": isHovered,
        })}
        onMouseEnter={() => onHover?.(ticket.id)}
        onMouseLeave={() => onHover?.(undefined)}
        data-testid="ticket-list-item"
      >
        {/* Bottom border on the outer div is covered below the card below.
            This fixes that.
        */}
        <div
          className={cx("w-full border-solid border-b", {
            "border-brand-40": isSelected,
            "border-transparent": !isSelected,
          })}
        />
        <TicketCard
          ticket={ticket}
          selectedTasks={selectedTasks}
          showTasks={isShowingTasks}
          onSelect={onSelect}
          onTaskSelected={onTaskSelect}
          selected={isSelected}
          selectMode={selectMode}
        />
        <div
          className={cx("w-full border-solid border-b ", {
            "border-brand-40": isSelected,
            "border-neutral-shade-border-light": !isSelected,
          })}
        />
      </div>
    );
  },
  (prev, next) => isEqual(prev, next)
);

TicketListItem.displayName = "TicketListItem";

export { TicketListItem };
