abstract class UrbintModel<T> {
  protected readonly data: T;

  constructor(data: T) {
    this.data = data;
  }

  get id() {
    return "";
  }

  set id(id: string | number) {
    // do nothing - just make strict-mode happy
  }

  /**
   * Compare two instances of the same type.
   * @example
   *  someCollection.sort((a, b) => a.compare(b))
   */
  abstract compare(instance: UrbintModel<T>): number;

  matches(termOrPattern: string | RegExp, fulltext: string) {
    if (termOrPattern instanceof RegExp) {
      return termOrPattern.test(fulltext);
    }
    return fulltext
      .toLocaleLowerCase()
      .includes(termOrPattern.toLocaleLowerCase());
  }

  /**
   * Should yield a payload appropriate for shipping off to the server via POST
   */
  abstract serialize(): T;

  /**
   * To test for invalid date objects:
   * ```
   * const date = this.castDate("nope");
   * if (isNaN(date.getTime())) {
   *   // Invalid Date
   * } else {
   *   // date is valid
   * }
   * ```
   */
  protected castDate(arg: any) {
    return new Date(arg);
  }

  protected castString(arg: any) {
    return String(arg ?? "");
  }

  protected castNumber(arg: any, fallback = NaN) {
    return Number(arg ?? fallback);
  }

  protected castBool(arg: any) {
    return arg === true;
  }

  protected castArray<U>(arg: U[] | any): U[] {
    if (Array.isArray(arg)) return arg;
    return arg ?? true ? [] : [arg];
  }
}

export { UrbintModel };
