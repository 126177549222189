import type { SliderWrapperProps } from "./SliderWrapper.types";
import { Portal } from "@urbint/silica";
import { Transition } from "@headlessui/react";
import { Fragment } from "react";

// eslint-disable-next-line no-empty-pattern
const SliderWrapper = ({ children, isSliderOpen }: SliderWrapperProps) => (
  <Portal id="test-portal">
    <Transition
      show={isSliderOpen}
      style={{ height: `${window.innerHeight}px` }}
      className="flex items-start sm:items-center h-screen overflow-y-auto"
    >
      <Transition.Child
        as={Fragment}
        enter="ease-linear duration-75"
        enterFrom="opacity-10"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="absolute inset-0 bg-gray-800 bg-opacity-90" />
      </Transition.Child>
      {children}
    </Transition>
  </Portal>
);

export { SliderWrapper };
