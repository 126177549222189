import type { RiskChipProps } from "./RiskChip.types";
import { formatWholeNumber } from "@/utils";
import { riskColor } from "@/format";

const RiskChip = ({ type, count }: RiskChipProps) => (
  <div
    className={`px-3 py-1 flex rounded-full items-center ${riskColor(
      type,
      "bg"
    )} text-xs text-white font-bold`}
  >
    {formatWholeNumber(count)}
  </div>
);

export { RiskChip };
