import type { SwiperModule, Swiper as SwiperClass } from "swiper/types";
import type { IconName } from "@urbint/silica";
import type { GalleryProps, Slide } from "./Gallery.types";
import { memo, useEffect, useState } from "react";
import cx from "classnames";
import { Keyboard, Navigation, Thumbs, Zoom } from "swiper/modules";
// Direct React component imports
import { Swiper } from "swiper/react";
// Styles must use direct files imports
// core Swiper
import "swiper/css";
// Navigation module
import "swiper/css/navigation";
// Thumbs module
import "swiper/css/thumbs";
import { Icon } from "@urbint/silica";
import { datadogRum } from "@datadog/browser-rum";
import isEqual from "lodash/isEqual";
import {
  OverflowMenuGroup,
  OverflowMenuItem,
  OverflowMenu,
} from "@/components/OverflowMenu";
import { useOnlineStatus, useResponsive } from "@/hooks";
import { Thumbnail } from "@/common/Thumbnail";
import { Button } from "@/common/Button";
import { GallerySlide } from "./GallerySlide";

import styles from "./Gallery.module.css";
import { GalleryNavigation } from "./GalleryNavigation/GalleryNavigation";

const SLIDE_GAP = 25;
const SLIDES_PER_VIEW = 1;
const THUMBNAIL_SLIDE_GAP = 10;
const THUMBNAIL_SLIDES_PER_VIEW = "auto";
const ESCAPE_KEY = 27;

const DATADOG_DOWNLOAD_ERROR = "GALLERY_DOWNLOAD_FILE";

type DownloadResult = "success" | "error";

const Gallery = memo(
  ({
    className,
    opened = false,
    navigation = true,
    thumbnails = true,
    zoom = true,
    closeButton = true,
    slides,
    initialSlide,
    canDelete = false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSlideChange = () => {},
    onDelete = undefined,
    onDownload = undefined,
    onClose,
  }: GalleryProps) => {
    // store thumbs swiper instance
    const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass>();
    const [currentSlide, setCurrentSlide] = useState<Slide | undefined>(
      slides[0]
    );
    const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);

    const { isSm, isMd } = useResponsive();

    const isOnline = useOnlineStatus();

    const swiperModules: SwiperModule[] = [
      Zoom,
      Keyboard,
      ...(navigation ? [Navigation] : []),
      ...(thumbnails ? [Thumbs] : []),
    ];

    const formattedTitle = () => {
      if (!currentSlide) return;

      const { title } = currentSlide;
      if (!isSm && title.length > 15) {
        return `${title.substring(0, 15)}...${title.substring(
          title.length - 9,
          title.length
        )}`;
      }

      return title;
    };

    const formattedSubtitle = () => {
      if (!currentSlide) return;

      const { createdAt, author } = currentSlide;
      const subtitle = `${createdAt} · ${author}`;

      return subtitle;
    };

    const iconName = () => {
      let iconType: IconName = "file_archive";

      if (!currentSlide) {
        return iconType;
      }

      const { type: fileType } = currentSlide;
      if (fileType === "image") {
        iconType = "file_image";
      }
      if (fileType === "video") {
        iconType = "video";
      }
      if (fileType === "pdf") {
        iconType = "file_pdf";
      }
      return iconType;
    };

    const onSlideChangeHandler = (activeIndex: number) => {
      setCurrentSlide(slides[activeIndex]);

      if (onSlideChange) {
        onSlideChange(activeIndex);
      }
    };

    const onDeleteHandler = () => {
      if (currentSlide && onDelete) {
        onDelete(currentSlide);
        onClose();
      }
    };

    const download = async (slide: Slide): Promise<DownloadResult> => {
      let result: DownloadResult = "success";

      try {
        const file = await fetch(slide.image);
        const fileBlob = await file.blob();
        const url = URL.createObjectURL(fileBlob);

        const link = document.createElement("a");
        link.href = url;
        link.download = slide.title;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        result = "error";
        datadogRum.addError(error, {
          errorIdentifier: DATADOG_DOWNLOAD_ERROR,
        });
      }

      return result;
    };

    const onDownloadHandler = async () => {
      if (!currentSlide) return;

      if (onDownload) {
        // handle on parent
        onDownload(currentSlide);
      } else {
        // or download directly
        await download(currentSlide);
      }
    };

    useEffect(() => {
      if (slides.length) {
        if (initialSlide) {
          const index = slides.findIndex((slide) => {
            const { metadata: { attachmentId = null } = {} } = slide;

            if (attachmentId) {
              return initialSlide === +attachmentId;
            }

            return false;
          });

          setCurrentSlideIndex(index);
        }

        setCurrentSlide(slides[currentSlideIndex]);
      } else {
        // close if no attachments
        onClose();
      }

      const handleEsc = (event: { keyCode: number }) => {
        if (event.keyCode === ESCAPE_KEY) {
          onClose();
        }
      };
      window.addEventListener("keydown", handleEsc);

      return () => {
        window.removeEventListener("keydown", handleEsc);
      };
    }, [currentSlideIndex, initialSlide, onClose, slides]);

    return (
      <div
        className={cx(styles.Gallery, className, {
          [`${styles.GalleryOpened}`]: opened,
        })}
      >
        <div className={styles.GalleryBackdrop} />
        <div className={styles.GalleryHeader}>
          <div className={styles.GalleryHeaderSummary}>
            <Icon name={iconName()} className={styles.GalleryFileIcon} />
            <div className={styles.GalleryHeaderDescription}>
              <p className={styles.GalleryHeaderTitle}>{formattedTitle()}</p>
              <p className={styles.GalleryHeaderSubtitle}>
                {formattedSubtitle()}
              </p>
            </div>
          </div>
          <div className={styles.GalleryHeaderActions}>
            {isMd && (
              <>
                <Button
                  variant="tertiary"
                  iconStart="download"
                  style={{
                    width: "100%",
                    height: "100%",
                    textAlign: "left",
                  }}
                  onClick={() => onDownloadHandler()}
                >
                  Download
                </Button>
                {isOnline && canDelete ? (
                  <Button
                    variant="tertiary"
                    iconStart="trash_full"
                    style={{
                      width: "100%",
                      height: "100%",
                      textAlign: "left",
                    }}
                    className={styles.MainDeleteButton}
                    onClick={() => onDeleteHandler()}
                  >
                    Delete
                  </Button>
                ) : null}
              </>
            )}
            {!isMd && (
              <OverflowMenu buttonIcon="more_horizontal" buttonLabel="">
                <OverflowMenuGroup>
                  <OverflowMenuItem
                    overflow={isMd}
                    onClick={() => onDownloadHandler()}
                  >
                    <Icon name="download" />
                    <span className={styles.OverflowMenuItemLabel}>
                      Download
                    </span>
                  </OverflowMenuItem>
                  <OverflowMenuItem
                    overflow={isMd}
                    onClick={() => onDeleteHandler()}
                  >
                    <Icon
                      name="trash_full"
                      className={styles.OverflowMenuItemHighlighted}
                    />
                    <span
                      className={cx(
                        styles.OverflowMenuItemLabel,
                        styles.OverflowMenuItemHighlighted
                      )}
                    >
                      Delete
                    </span>
                  </OverflowMenuItem>
                </OverflowMenuGroup>
              </OverflowMenu>
            )}
            {closeButton && (
              <Button
                variant="tertiary"
                iconStart="close_big"
                onClick={onClose}
                style={{ color: "white" }}
              />
            )}
          </div>
        </div>
        <div className={styles.GalleryContent}>
          <Swiper
            className={styles.GallerySwiper}
            spaceBetween={SLIDE_GAP}
            slidesPerView={SLIDES_PER_VIEW}
            onSlideChange={({ activeIndex }) =>
              onSlideChangeHandler(activeIndex)
            }
            zoom
            centeredSlides
            thumbs={{ swiper: thumbsSwiper }}
            modules={swiperModules}
            keyboard={{ enabled: true }}
          >
            {slides &&
              slides.map((slide, index) => (
                <GallerySlide
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  zoom={zoom}
                  className={styles.GallerySwiperSlide}
                >
                  <img src={slide.image} alt={slide.alt} />
                </GallerySlide>
              ))}
            {navigation && <GalleryNavigation slideIndex={currentSlideIndex} />}
          </Swiper>
        </div>

        {thumbnails && (
          <div className={styles.GalleryFooter}>
            <Swiper
              className={styles.GallerySwiperThumbnails}
              spaceBetween={THUMBNAIL_SLIDE_GAP}
              slidesPerView={THUMBNAIL_SLIDES_PER_VIEW}
              modules={[Thumbs]}
              watchSlidesProgress
              onSwiper={(swiper: SwiperClass) => setThumbsSwiper(swiper)}
            >
              {slides &&
                slides.map((slide, index) => (
                  <GallerySlide
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    zoom={false}
                    className={styles.GallerySwiperThumbnailsSlide}
                  >
                    <Thumbnail
                      url={slide.image}
                      alt={slide.alt}
                      allowDelete={false}
                    />
                  </GallerySlide>
                ))}
            </Swiper>
          </div>
        )}
      </div>
    );
  },
  (prevProps: GalleryProps, nextProps: GalleryProps) =>
    isEqual(prevProps, nextProps)
);

Gallery.displayName = "Gallery";

export { Gallery };
