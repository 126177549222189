import type { AccordionItemProps } from "./AccordionItem.types";
import { Icon } from "@urbint/silica";
import cx from "classnames";

import React from "react";
import { useAccordionContext } from "..";

const AccordionItem = ({
  className,
  itemId,
  summary,
  children,
  testId,
}: AccordionItemProps) => {
  const [selected, setSelected] = useAccordionContext();
  const isOpen = itemId === selected;
  const handleToggleHandler = () => setSelected(isOpen ? undefined : itemId);

  const hasContent = React.Children.count(children);

  return (
    <li className={cx("w-full bg-backgrounds-white", className)}>
      <button
        type="button"
        className={cx(
          "w-full px-3 py-3 border-b border-solid border-transparent flex justify-between items-center gap-2 hover:bg-backgrounds-gray focus-visible:bg-backgrounds-gray",
          {
            "border-actions-secondary-active": isOpen,
          }
        )}
        onClick={handleToggleHandler}
        data-testid={testId}
      >
        <div className="truncate">{summary}</div>
        <Icon
          name="chevron_big_down"
          className={cx("w-4 h-4 transition-transform duration-500", {
            "scale-y-[-1]": isOpen,
          })}
        />
      </button>
      {isOpen && hasContent ? (
        <div className="px-3 py-3">{children}</div>
      ) : null}
    </li>
  );
};

export { AccordionItem };
