import type { TaskOptionsProps } from "./TaskOptions.types";
import { useEffect, useMemo, useState } from "react";
import { OverflowMenuGroup, OverflowMenuItem } from "@/components/OverflowMenu";
import { Tooltip } from "@/common/Tooltip";
import {
  useDamagePreventionAuth,
  useResponsive,
  useTicketColumns,
} from "@/hooks";
import { useTicketSelector } from "@/components/TicketSelectorProvider";
import { useResponseFormsForTasks } from "@/api/tasks";
import { QuestionAnswerType } from "@/models/Question";
import { useTicketViewEditor } from "@/components/TicketViewEditorProvider";
import { useFlexTickets } from "@/api";
import {
  BULK_ACTION_DISABLED_COPY,
  MAX_SELECTION_THRESHOLD,
} from "../../../ActionBar.constants";

const TaskOptions = ({
  allSelectedTasks,
  triggerAction,
  setModal,
  bulkDisabled,
  hasSelections,
  handleClearSelection,
}: TaskOptionsProps) => {
  const { isSm } = useResponsive();
  const { currentUser } = useDamagePreventionAuth();
  const [numberOfSelectedTasks, setNumberOfSelectedTasks] = useState(0);
  const { flexFieldsForTableColumns } = useTicketColumns();

  const { canOnlyViewTicketsWithOwnedTasks, canSubmitResponseForm } =
    currentUser;

  const { selectedTickets, selectAllTasks, getAllSelectedTasks } =
    useTicketSelector();

  const { isShowingTasks, dirtyView, isInitialized, columns } =
    useTicketViewEditor();

  const { data: flexTickets } = useFlexTickets(
    {
      ...dirtyView,
      fields: useMemo(
        () => flexFieldsForTableColumns(columns, isShowingTasks),
        [columns, isShowingTasks]
      ),
    },
    { enabled: isInitialized }
  );

  useEffect(() => {
    setNumberOfSelectedTasks(Object.keys(getAllSelectedTasks()).length);
  }, [Object.keys(getAllSelectedTasks()).length]);

  const tickets = useMemo(
    () =>
      flexTickets?.pages
        ? flexTickets?.pages.flatMap((ticket) => ticket.data)
        : [],
    [flexTickets?.pages]
  );

  const hasNoCompletedTasks = !allSelectedTasks.find(
    (task) => task.taskStep?.terminating
  );

  const bulkResponseForms = useResponseFormsForTasks(allSelectedTasks);

  const hasSameResponseForm = bulkResponseForms.length === 1;

  const hasNoPRResponse =
    bulkResponseForms.filter(
      (r) =>
        !!r.questions.find(
          (q) => q.answerType === QuestionAnswerType.POSITIVE_RESPONSE_CODE
        )
    ).length === 0;

  const getCompleteTaskTooltipText = () => {
    if (selectedTickets.length > MAX_SELECTION_THRESHOLD)
      return BULK_ACTION_DISABLED_COPY;
    if (!hasNoCompletedTasks)
      return "Some of the selected tasks are already completed";
    if (!hasSameResponseForm)
      return "All tasks must have the same response form";
    if (!hasNoPRResponse)
      return "The response form contains a PR code response";
    return "";
  };

  const canBulkCompleteTasks =
    hasSameResponseForm && hasNoCompletedTasks && hasNoPRResponse;

  const totalNumberOfTasks = useMemo(() => {
    let totalTasks = 0;
    tickets?.forEach((ticket) => {
      if (ticket.id) {
        totalTasks += ticket.tasks?.length || 0;
      }
    });

    return totalTasks;
  }, [tickets]);

  const handleSelectAllTasks = () => {
    if (tickets) {
      selectAllTasks(tickets);
    }
  };

  const handleClear = () => {
    handleClearSelection();
  };

  return (
    <>
      {allSelectedTasks.length > 0 && (
        <>
          <OverflowMenuGroup>
            {!canOnlyViewTicketsWithOwnedTasks && (
              <OverflowMenuItem
                onClick={() =>
                  triggerAction(() => setModal("bulkChangeAssignee"))
                }
                dataTestId="edit-assignees-button"
                overflow
              >
                Edit assignee
              </OverflowMenuItem>
            )}
            {canSubmitResponseForm && (
              <OverflowMenuItem
                onClick={() =>
                  triggerAction(() => setModal("bulkCompleteTasks"))
                }
                overflow
                disabled={bulkDisabled || !canBulkCompleteTasks}
                dataTestId="complete-tasks-button"
              >
                <Tooltip
                  title={getCompleteTaskTooltipText()}
                  disabled={!bulkDisabled && canBulkCompleteTasks}
                >
                  Complete tasks
                </Tooltip>
              </OverflowMenuItem>
            )}
          </OverflowMenuGroup>
          {!isSm && hasSelections && (
            <OverflowMenuGroup>
              <OverflowMenuItem onClick={handleClear}>
                Clear selection
              </OverflowMenuItem>
            </OverflowMenuGroup>
          )}
        </>
      )}
      {totalNumberOfTasks !== numberOfSelectedTasks && (
        <OverflowMenuGroup>
          <OverflowMenuItem onClick={handleSelectAllTasks}>
            Select all tasks
          </OverflowMenuItem>
        </OverflowMenuGroup>
      )}
    </>
  );
};

export { TaskOptions };
