import { Fragment, useState, useMemo, useCallback } from "react";
import { IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { useTicketViewEditor } from "@/components/TicketViewEditorProvider";
import { startCase } from "@/format";
import { useTicketColumns } from "@/hooks";
import { FilterDialogSection } from "../FilterDialogSection";

const ColumnsTab = () => {
  const { COLUMN_DEFINITIONS_BY_ID } = useTicketColumns();
  const { columns, addColumn, resetColumns, removeColumn, moveColumn } =
    useTicketViewEditor();

  const availableColumns = useMemo(() => {
    const selectedIds = new Set(columns.map((x) => x.field));
    return new Array(...COLUMN_DEFINITIONS_BY_ID.values())
      .filter((def) => !selectedIds.has(def.id) && !def.exclude)
      .map((def) => ({
        label: def.headerText ?? startCase(def.Header),
        value: def.id,
      }))
      .sort((a, b) => {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
      });
  }, [columns]);
  const [dragIx, setDragIx] = useState<number | undefined>(undefined);
  const [dropIx, setDropIx] = useState<number | undefined>(undefined);
  const abortDrag = useCallback(() => {
    setDragIx(undefined);
    setDropIx(undefined);
  }, [setDragIx, setDropIx]);

  const allowDrop = useCallback((e) => {
    e.preventDefault();
  }, []);

  const onDrop = useCallback(
    (e) => {
      e.preventDefault();
      moveColumn({ index: dragIx! }, dropIx!);
      abortDrag();
    },
    [moveColumn, dragIx, dropIx, abortDrag]
  );

  const dropTarget = (
    <li
      onDragOver={allowDrop}
      onDrop={onDrop}
      className="w-full h-8 bg-gray-100 rounded border-rounded"
    />
  );
  return (
    <FilterDialogSection
      title="COLUMNS"
      onReset={resetColumns}
      addOptions={availableColumns}
      onAdd={(field) => addColumn({ field })}
    >
      <ul className="space-y-2">
        {columns.map(({ field }, columnIx) => {
          const def = COLUMN_DEFINITIONS_BY_ID.get(field);
          if (!def) {
            return null;
          }
          const isDragging = dragIx === columnIx;
          return (
            <Fragment key={field}>
              {columnIx !== 0 &&
                columnIx === dropIx &&
                !isDragging &&
                dragIx! > dropIx &&
                dropTarget}
              <li
                draggable={isDragging}
                onDragEnd={abortDrag}
                onDragOver={() => setDropIx(columnIx)}
                className={`flex items-center bg-white w-full p-2 border border-black border-opacity-11
                                rounded shadow text-black text-opacity-88 leading-tight`}
              >
                {!def.isLocked && (
                  <div
                    onMouseDown={() => setDragIx(columnIx)}
                    className="flex flex-col justify-between w-2 h-3 mr-2 cursor-move"
                  >
                    {[0, 1, 2].map((x) => (
                      <div
                        key={x}
                        className="w-full h-[2px] bg-black bg-opacity-33 rounded"
                      />
                    ))}
                  </div>
                )}
                {startCase(def.headerText ?? def.Header)}
                {!def.isLocked && (
                  <button
                    type="button"
                    onClick={() => removeColumn({ field })}
                    className="flex items-center ml-auto"
                    data-testid="columns-tab-remove-column-button"
                  >
                    <IonIcon
                      className="text-black icon-semibold text-opacity-33"
                      icon={closeOutline}
                    />
                  </button>
                )}
              </li>
              {columnIx === dropIx &&
                !isDragging &&
                dragIx! < dropIx &&
                dropTarget}
            </Fragment>
          );
        })}
      </ul>
    </FilterDialogSection>
  );
};

export { ColumnsTab };
