import type { TicketDetailsSectionProps } from "./TicketDetailsSection.types";
import type { DetailProps } from "../DetailsSectionSplitContent/Detail";
import { useSetTicketStage } from "@/api";
import { useDamagePreventionAuth, useOnlineStatus } from "@/hooks";
import { useToasts } from "@/components/Toasts";
import { DetailsSection } from "../DetailsSection";
import { DetailsSectionSplitContent } from "../DetailsSectionSplitContent";
import { DetailLink } from "./DetailLink";
import { ticketStageOptions } from "./TicketDetailsSection.constants";
import {
  getLeftFields,
  getRightFields,
} from "./utils/TicketDetailsSection.utils";

const TicketDetailsSection = ({ ticket }: TicketDetailsSectionProps) => {
  const { addToast } = useToasts();
  const isOnline = useOnlineStatus();
  const { id, stage } = ticket ?? {};
  const mutation = useSetTicketStage(id!);

  const {
    currentUser: { canEditTicketLifeCycle },
  } = useDamagePreventionAuth();

  if (ticket && !ticketStageOptions.includes(stage!))
    ticketStageOptions.unshift(stage!);

  const leftFields: DetailProps[] = getLeftFields({
    ticket,
    canEditTicketLifeCycle: canEditTicketLifeCycle && isOnline,
    mutation,
    addToast,
  });

  const rightFields = getRightFields({ ticket });

  return (
    <DetailsSection title="Ticket details">
      <DetailsSectionSplitContent left={leftFields} right={rightFields} />
      {isOnline && (
        <DetailLink to={`/ticket-history/${id}/history`}>
          View One Call Center ticket text and version history...
        </DetailLink>
      )}
    </DetailsSection>
  );
};

export { TicketDetailsSection };
