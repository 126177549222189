import type { DialogHeaderButtonsProps } from "./DialogHeaderButtons.types";
import classNames from "classnames";

const DialogHeaderButtons = ({
  children,
  className,
}: DialogHeaderButtonsProps) => (
  <div className={classNames(`text-2xl flex h-full self-start`, className)}>
    {children}
  </div>
);

export { DialogHeaderButtons };
