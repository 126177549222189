import type { MapboxGeoJSONFeature, MapboxMap } from "react-map-gl";
import type { MapCollection } from "react-map-gl/dist/esm/components/use-map";
import { Marker, useMap } from "react-map-gl";
import { useEffect, useMemo, useState } from "react";
import { debounce } from "lodash";
import { ClusterMarker } from "@/components/Map/Markers";
import { TICKETS_SOURCE } from "../ClusterLayer.constants";
import { getClusters } from "./Clusters.utils";

const Clusters = () => {
  const { current: map }: MapCollection<MapboxMap> = useMap();
  const [clusters, setClusters] = useState<MapboxGeoJSONFeature[]>([]);

  const updateClusters = () => {
    if (map) {
      setClusters(getClusters(map));
    }
  };

  const debouncedUpdateClusters = debounce(updateClusters, 50);

  useEffect(() => {
    if (map) {
      const handleDataLoad = () => {
        if (map.isSourceLoaded(TICKETS_SOURCE)) {
          updateClusters();
        }
      };
      // Update clusters on zoom
      map.on("zoom", debouncedUpdateClusters);
      map.on("zoomend", debouncedUpdateClusters);
      map.on("sourcedata", handleDataLoad);

      return () => {
        map.off("zoom", debouncedUpdateClusters);
        map.off("zoomend", debouncedUpdateClusters);
        map.off("sourcedata", handleDataLoad);
      };
    }
  }, [map]);

  if (!map || !clusters) return null;
  const visibleClusters = useMemo(
    () =>
      clusters.map((cluster: MapboxGeoJSONFeature) => {
        const { properties, geometry, id } = cluster;

        if (geometry.type === "Point" && !geometry?.coordinates) return null;

        if (geometry.type !== "Point") return null;

        const [long, lat] = geometry.coordinates;

        if (!long || !lat) return null;
        map.project([long, lat]);

        return (
          <Marker key={id} longitude={long} latitude={lat}>
            <ClusterMarker vals={properties} />
          </Marker>
        );
      }),
    [clusters.length]
  );

  return <>{visibleClusters}</>;
};

export { Clusters };
