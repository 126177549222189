// TODO: add optional label
import type { FieldComponentProps } from "./Field.types";
import { Controller } from "react-hook-form";
import isEmpty from "lodash/isEmpty";
import { getDateBrowserProof } from "@/format";
import { QuestionAnswerType } from "@/models";
import { FieldErrorMessage } from "../../ErrorMessages";
import { FormLabel } from "../FormLabel";
import { INPUT_TYPE_MAP, SELECT_FIELDS } from "./Field.constants";

const Field = ({
  sectionName,
  formField,
  control,
  errors,
  onBlur,
  onSelectChange,
  testId,
}: FieldComponentProps) => {
  const Component = INPUT_TYPE_MAP[formField.type];
  const isSelectField = SELECT_FIELDS.includes(
    formField.type as QuestionAnswerType
  );
  const isDateTimeField =
    formField.type === QuestionAnswerType.DATE_SELECTOR ||
    formField.type === QuestionAnswerType.DATE_TIME_SELECTOR;
  const fieldError = errors[sectionName]?.[formField.id];
  const errorBorder = fieldError
    ? "ring-system-error-30 ring-opacity-100 border-system-error-30"
    : "";

  const rules = {
    ...formField.validations,
    required: formField?.validations?.required
      ? "This field is required."
      : undefined,
    pattern: {
      value: /.*[^ ].*/g,
      message: "This field cannot be left blank",
    },
  };

  return Component ? (
    <Controller
      key={`${sectionName}-${formField.id}`}
      name={`${sectionName}.${formField.id}`}
      control={control}
      rules={rules}
      defaultValue={formField.value || ""}
      render={({ field }) => {
        // After changing the date this is being returned as a Date. Before changing the date it's an object...
        const isDateFieldString =
          isDateTimeField && typeof field.value === "string";

        const dateObject = isDateFieldString
          ? new Date(getDateBrowserProof(field.value)) // When it's a string we need to remove the dashes because of Safari..
          : field.value;

        const dateProps = isDateTimeField
          ? {
              mode: "single",
              type:
                formField.type === QuestionAnswerType.DATE_SELECTOR
                  ? "date"
                  : "datetime",
              onDateChange: (x: Date) => field.onChange(x),
              staticMode: true,
            }
          : {};

        if (field.value !== "") {
          Object.assign(dateProps, { date: dateObject });
        }

        return (
          <>
            <div className="flex justify-between" data-testid={testId}>
              <FormLabel htmlFor={formField.id.toString()}>
                {formField.label}
              </FormLabel>
              {!formField.validations?.required && (
                <span className="text-sm text-default-on-light opacity-60">
                  optional
                </span>
              )}
            </div>
            <Component
              {...formField}
              {...field}
              ref={null}
              placeholder={formField.placeholder || "None"}
              className={`${
                !isDateTimeField && "border rounded"
              } ${errorBorder}`}
              onBlur={onBlur(field.onBlur)}
              onChange={
                isSelectField ? onSelectChange(field.onChange) : field.onChange
              }
              label={undefined}
              {...dateProps}
              optionsClassName={isSelectField && "max-w-full"}
              hasError={!!fieldError}
              testId={`${testId}-answer`}
            />
            {fieldError && (
              <FieldErrorMessage
                message={fieldError.message as unknown as string}
              />
            )}
          </>
        );
      }}
    />
  ) : null;
};

export { Field };
