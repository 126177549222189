import type { FormEventHandler } from "react";
import type { dialogFooter } from "@/components/Dialog";
import type { EmailTicketProps } from "./EmailTicket.types";
import { useState } from "react";
import { Icon, Subheading } from "@urbint/silica";
import { useEmailTicket } from "@/api";
import { useToasts } from "@/components/Toasts";
import { TextInput } from "@/components/TextInput";
import { AutoGrowingTextArea } from "@/components/AutoGrowingTextArea";
import { Dialog } from "@/components/Dialog";
import { isEmails } from "./EmailTicket.utils";

/**
 * Email ticket modal
 *
 * If there is only a single element in the dropdown options then it is a
 * confirmation modal for that option. Otherwise it will show a selection
 * dropdown first
 *
 * @param param0
 * @returns
 */
const EmailTicket = ({ ticket, isOpen, onDidDismiss }: EmailTicketProps) => {
  const emailMutation = useEmailTicket(ticket?.id);
  const { addToast } = useToasts();
  const [emailing, setEmailing] = useState(false);

  const [emailAddresses, setEmailAddresses] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");

  const isDisabled =
    emailing || !isEmails(emailAddresses) || emailAddresses === "";

  const confirmEmailTicket = () =>
    new Promise((resolve, reject) => {
      emailMutation.mutate(
        {
          receiver_email: emailAddresses,
          body,
          subject,
        },
        {
          onSuccess: resolve,
          onError: reject,
        }
      );
    });

  const doConfirm: FormEventHandler<HTMLFormElement> = async (e) => {
    e?.preventDefault();

    if (isDisabled) {
      return;
    }

    setEmailing(true); // Gate the call
    try {
      await confirmEmailTicket();
      // Reset modal
      setEmailAddresses("");
      setBody("");
      setSubject("");

      // Toast the user
      addToast("Ticket emailed", "success");
    } catch (error) {
      addToast("Failed to email ticket. Please try again.", "error");
      console.error(error);
    } finally {
      setEmailing(false);
    }
    onDidDismiss();
  };

  const modalFooter: dialogFooter = {
    toRender: "Send Email",
    onClose: onDidDismiss,
    onClick: doConfirm,
    submitButtonDisabled: isDisabled,
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onDidDismiss}
      header=""
      footer={modalFooter}
      variant="warning"
    >
      <Subheading>Email ticket #${ticket.id}?</Subheading>
      <form onSubmit={doConfirm} className="mt-2">
        <div className="mb-2">
          <label
            htmlFor="ticket-email-address-input"
            className="text-sm font-semibold text-gray-600"
          >
            Recipient...
          </label>
          <TextInput
            id="ticket-email-address-input"
            onChangeText={setEmailAddresses}
            value={emailAddresses}
            type="text"
            placeholder="Insert email address"
            required
          />
          <div className="h-4 text-sm font-semibold text-gray-600 align-middle">
            {/* Note: We have the out div to keep the space even when there is no warning to not flickr */}
            {emailAddresses === "" && (
              <span>
                <Icon name="error" className="mr-1 text-risk-very-high" />
                This field is required
              </span>
            )}

            {emailAddresses !== "" && !isEmails(emailAddresses) && (
              <span>
                <Icon name="error" className="mr-1 text-risk-very-high" />
                This field should contain emails
              </span>
            )}
          </div>
        </div>
        <div className="mb-2">
          <label
            htmlFor="ticket-email-subject-input"
            className="text-sm font-semibold text-gray-600"
          >
            Subject...
          </label>
          <TextInput
            id="ticket-email-subject-input"
            onChangeText={setSubject}
            value={subject}
            type="text"
            placeholder="Insert email subject"
          />
        </div>

        <div className="mb-2">
          <label
            htmlFor="ticket-email-body-input"
            className="text-sm font-semibold text-gray-600"
          >
            Body...
          </label>
          <AutoGrowingTextArea
            onChange={setBody}
            id="ticket-email-body-input"
            value={body}
            placeholder="Insert email body"
          />
        </div>
        <p>
          This will send an email with the raw One Call Center ticket text
          included from your email address
        </p>
      </form>
    </Dialog>
  );
};

export { EmailTicket };
