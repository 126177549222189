import type { LayerProps } from "react-map-gl";

const measurePoint: LayerProps = {
  id: "measure-points",
  type: "circle",
  paint: {
    "circle-radius": 5,
    "circle-color": "#FFFFFF",
    "circle-stroke-color": "#000000",
    "circle-stroke-width": 2,
  },
  filter: ["in", "$type", "Point"],
};

const measureLine: LayerProps = {
  id: "measure-lines",
  type: "line",
  layout: {
    "line-cap": "round",
    "line-join": "round",
  },
  paint: {
    "line-color": "#000",
    "line-width": 2.5,
  },
  filter: ["in", "$type", "LineString"],
};

export { measurePoint, measureLine };
