import type { DamagePreventionUserProfile } from "@/utils/types";
import type { UrbintAuthContextInstance } from "@urbint/auth-client";
import { useLocation } from "react-router";
import {
  useCurrentTicketQueue,
  useOptimizeTicketQueue,
  usePublicConfig,
} from "@/api";
import { useDamagePreventionAuth } from "@/hooks";
import { NavBarView } from "./NavBarView";

const NavBar = () => {
  const { data } = usePublicConfig();
  const location = useLocation();
  const auth: UrbintAuthContextInstance<DamagePreventionUserProfile> =
    useDamagePreventionAuth();
  const queue = useCurrentTicketQueue();
  const optimizeQueue = useOptimizeTicketQueue();

  return (
    <NavBarView
      publicConfig={data}
      location={location}
      auth={auth}
      queue={queue}
      optimizeQueue={optimizeQueue}
    />
  );
};
export { NavBar };
