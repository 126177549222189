import type { SidebarContentContainerProps } from "./SidebarContentContainer.types";

const SidebarContentContainer = ({
  className = "",
  children,
}: SidebarContentContainerProps) => (
  <div className={`h-full overflow-y-auto p-4 border-box ${className}`}>
    {children}
  </div>
);

export { SidebarContentContainer };
