import type { ImpactPotential } from "@/models/ImpactPotential";
import type {
  ImpactIconProps,
  ImpactInfoProps,
  ImpactListProps,
} from "./ImpactList.types";
import { ComponentLabel, Icon } from "@urbint/silica";
import { ListItem } from "@/common/ListItem";
import { ListView } from "@/common/ListView";
import { InsightName } from "@/models";
import { getIcon, getTitle } from "../ImpactModalContent.utils";

const ImpactInfo = ({ title, subtitle }: ImpactInfoProps) => (
  <div className="flex flex-col gap-2 py-1.5">
    <ComponentLabel>{title}</ComponentLabel>
    <ComponentLabel className="text-system-info-40">{subtitle}</ComponentLabel>
  </div>
);

const ImpactIcon = ({ iconName }: ImpactIconProps) => (
  <Icon className="text-system-info-40 text-3xl" name={iconName} />
);

const ImpactList = ({ list }: ImpactListProps) => (
  <ListView className="gap-2 justify-center">
    {list.map((impact: ImpactPotential) => {
      const { insightData, id, insight } = impact;
      const { insightNameType = InsightName.NOT_FOUND } = insight ?? {};

      return (
        <ListItem
          key={id}
          className="border-system-info-40 rounded border bg-system-info-10"
          leftSlot={<ImpactIcon iconName={getIcon(insightNameType)} />}
          centerSlot={
            <ImpactInfo
              title={getTitle(insightNameType)}
              subtitle={insightData}
            />
          }
        />
      );
    })}
  </ListView>
);

export { ImpactList };
