import type { UploadButtonsWrapperProps } from "./UploadButtonsWrapper.types";
import type { Task } from "@/models";
import { useState } from "react";
import { Icon } from "@urbint/silica";
import classNames from "classnames";
import { MenuPanel } from "@/components/MenuPanel";
import { useDamagePreventionAuth } from "@/hooks";
import { useTaskByIdForTicket } from "@/api";
import { FileType } from "@/hooks/useFileInput/useFileInput";

import {
  getAttachmentType,
  isAttachmentFromTicket,
} from "../AttachmentsSection.utils";
import { UploadButton } from "./UploadButton";

const UploadButtonsWrapper = ({
  attachmentType,
  onInvalidAttachmentSize,
}: UploadButtonsWrapperProps) => {
  const { currentUser } = useDamagePreventionAuth();
  let task: Task | undefined;

  const attachment = getAttachmentType(attachmentType);

  if (isAttachmentFromTicket(attachment)) {
    const { data } = useTaskByIdForTicket(
      attachment.ticketId!,
      attachment.taskId!,
      {
        enabled: !!(attachment.ticketId && attachment.taskId),
      }
    );

    task = data;
  }

  const [attachmentMenuOpen, setAttachmentMenuOpen] = useState(false);

  return (
    <div className="flex flex-wrap gap-2">
      {currentUser.canUploadPhotos && (
        <UploadButton
          attachmentType={attachmentType}
          label="Take photo"
          className="text-neutral-shade-secondary shadow-5 border-neutral-shade-border-default border rounded leading-tight font-semibold"
          icon={<Icon name="camera" className="mr-1.5 text-xl" />}
          task={task}
          type={FileType.IMAGES}
          onInvalidAttachmentSize={onInvalidAttachmentSize}
        />
      )}
      {/* TODO: Verify if user is allowed to upload documents */}
      {currentUser.canUploadPhotos && (
        <MenuPanel
          show={attachmentMenuOpen}
          placement="bottom-start"
          onClickOutside={() => {
            setAttachmentMenuOpen(false);
          }}
          className="py-2 px-0.5"
          renderButton={() => (
            <button
              type="button"
              className={classNames(
                "flex flex-row-reverse items-center py-2 px-2.5 text-neutral-shade-secondary border-neutral-shade-border-default shadow-5 border rounded leading-tight font-semibold",
                { "bg-neutral-shade-background-medium": attachmentMenuOpen }
              )}
              onClick={() => setAttachmentMenuOpen(!attachmentMenuOpen)}
            >
              <Icon name="chevron_big_down" />
              Attach
            </button>
          )}
        >
          <UploadButton
            attachmentType={attachmentType}
            label="PDF Document"
            icon={<Icon name="file_pdf" className="mr-1.5 text-lg" />}
            task={task}
            type={FileType.PDF}
            customClickEvent={() => setAttachmentMenuOpen(false)}
            onInvalidAttachmentSize={onInvalidAttachmentSize}
          />
        </MenuPanel>
      )}
    </div>
  );
};

export { UploadButtonsWrapper };
