import type { FlexTask, Task, TaskType, User } from "@/models";
import { useCallback, useMemo } from "react";
import { indexBy, indexByKV } from "@/utils";
import { useUsers, useTaskTypes, useWorkflows } from "@/api";
import {
  canBeAssignedToLocatorTasks,
  canBeAssignedToInspectorTasks,
  canBeAssignedToNonTicketedEvents,
} from "@/utils/djangoPermissions";

const useAssignableUsersForTaskTypes = (taskTypes?: TaskType[]) => {
  const { data: workflows } = useWorkflows();
  const { data: users } = useUsers();
  const taskTypeIdToWorkflow = useMemo(
    () =>
      indexByKV(taskTypes || [], (x) => [
        x.id,
        workflows?.find((wf) => wf.id === x.workflowId),
      ]),
    [taskTypes, workflows]
  );

  const uniqueWorkflowNames = useMemo(() => {
    const result = new Set<string>();
    for (const taskType of taskTypes || []) {
      const workflow = taskTypeIdToWorkflow.get(taskType.id);
      workflow?.name && result.add(workflow?.name);
    }
    return result;
  }, [taskTypeIdToWorkflow, taskTypes]);

  const userFilterFn = useCallback(
    (user: User) => {
      if (!user.isActive) return false;
      if (user.isUrbintAdmin) return true;
      const isLocatorValid = uniqueWorkflowNames.has("Locator")
        ? canBeAssignedToLocatorTasks(user)
        : true;
      const isInspectorValid = uniqueWorkflowNames.has("Inspector")
        ? canBeAssignedToInspectorTasks(user)
        : true;
      return isLocatorValid && isInspectorValid;
    },
    [uniqueWorkflowNames]
  );

  return useMemo(
    () => users?.filter(userFilterFn) || [],
    [users, userFilterFn]
  );
};

const useAssignableUsersForTasks = (tasks?: (Task | FlexTask)[]) => {
  const { data: taskTypes } = useTaskTypes();
  const taskTypeIdToTaskType = useMemo(
    () => indexBy(taskTypes || [], (x) => x.id),
    [taskTypes]
  );

  const taskTypesForTasks = useMemo(
    () =>
      tasks?.map(
        (x) =>
          taskTypeIdToTaskType.get(
            // Check whether x is a Task or FlexTask
            // TODO: Fix no-non-null-asserted-optional-chain
            "taskTypeId" in x ? x.taskTypeId : x.taskType?.id!
          )!
      ),
    [tasks, taskTypeIdToTaskType]
  );

  return useAssignableUsersForTaskTypes(taskTypesForTasks);
};

const useAssignableUsersForNonTicketedEvents = () => {
  const { data: users } = useUsers();

  const userFilterFn = useCallback((user: User) => {
    if (!user.isActive) return false;

    return canBeAssignedToNonTicketedEvents(user);
  }, []);

  return useMemo(
    () => users?.filter(userFilterFn) || [],
    [users, userFilterFn]
  );
};

export {
  useAssignableUsersForTaskTypes,
  useAssignableUsersForTasks,
  useAssignableUsersForNonTicketedEvents,
};
