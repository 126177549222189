import type { HeaderProps } from "./Header.types";
import { Icon } from "@urbint/silica";
import { useOnlineStatus, useResponsive } from "@/hooks";
import { Tooltip } from "@/common/Tooltip";
import { usePrefetch } from "@/components/PrefetchOfflineData";
import { Container } from "../commons";
import { SummaryCard } from "./SummaryCard";
import { MapEntry } from "./Map/MapEntry";

const Header = ({ ticket }: HeaderProps) => {
  const { isLg } = useResponsive();
  const isOnline = useOnlineStatus();
  const shouldRenderMapWrapper = isOnline || isLg;
  const { checkTicketPrefetched } = usePrefetch();
  const isTicketPrefetched = checkTicketPrefetched(ticket?.id);

  return (
    <div className="relative flex flex-col isolate">
      {/* Conditionally rendered mobile header */}
      {shouldRenderMapWrapper && (
        <div className="w-full h-52 md:h-80 lg:h-[17.5rem] z-10">
          <header className="hidden md:block bg-black bg-opacity-66 font-semibold text-white py-1.5 absolute w-full top-0 z-10">
            <Container className="flex gap-2 items-center">
              {isTicketPrefetched && (
                <Tooltip title="Ticket is ready for offline">
                  <Icon
                    name="cloud_check"
                    className="text-xl text-brand-blue mx-1"
                  />
                </Tooltip>
              )}
              <p>#{ticket?.number}</p>
            </Container>
          </header>
          <MapEntry ticket={ticket} />
        </div>
      )}
      <div className="w-full lg:absolute lg:mt-12">
        {isLg ? (
          <Container>
            <div className="relative z-20 h-full overflow-hidden rounded shadow w-80 bg-bluegray-50">
              <SummaryCard />
            </div>
          </Container>
        ) : (
          <SummaryCard />
        )}
      </div>
    </div>
  );
};

export { Header };
