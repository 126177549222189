import type { AccordionProps } from "./Accordion.types";
import { useState } from "react";
import { AccordionContext } from "./Accordion.context";

const Accordion = ({ className, openItemId, children }: AccordionProps) => {
  const setter = useState(openItemId);

  return (
    <ul className={className}>
      <AccordionContext.Provider value={setter}>
        {children}
      </AccordionContext.Provider>
    </ul>
  );
};

export { Accordion };
