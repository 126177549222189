import { lazy, Suspense, useEffect, useState } from "react";

const LazyReactQueryDevtools = lazy(() =>
  // eslint-disable-next-line import/extensions
  import("@tanstack/react-query-devtools/build/lib/index.prod.js").then(
    (d) => ({
      default: d.ReactQueryDevtools,
    })
  )
);

const ReactQueryDevtoolsProduction = () => {
  const [showDevtools, setShowDevtools] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.toggleDevtools = () => setShowDevtools((old) => !old);
  }, []);

  return showDevtools ? (
    <Suspense fallback={null}>
      <LazyReactQueryDevtools />
    </Suspense>
  ) : null;
};

export { ReactQueryDevtoolsProduction };
