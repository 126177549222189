import type { MultiDateTimeProps } from "./MultiDateTime.types";
import { useCallback } from "react";
import { CustomInput } from "../CustomInput";
import {
  getDateFormat,
  getPlaceholder,
  parseDate,
} from "../DateTimeInput.utils";

const MultiDateTime = ({
  onDatesChange,
  startDate,
  endDate,
  type,
  disabled,
  onBlur,
  staticMode,
}: MultiDateTimeProps) => {
  const handleStartDateChange = useCallback(
    ([date]: Date[]) => {
      if (!onDatesChange) return;

      onDatesChange([date, parseDate(endDate)]);
    },
    [onDatesChange, endDate]
  );

  const handleEndDateChange = useCallback(
    ([date]: Date[]) => {
      if (!onDatesChange) return;

      onDatesChange([parseDate(startDate), date]);
    },
    [onDatesChange, startDate]
  );

  return (
    <div className="inline-flex flex-col">
      <CustomInput
        disabled={disabled}
        onClose={onBlur}
        static={staticMode}
        options={{
          mode: "single",
          dateFormat: getDateFormat(type),
          enableTime: type === "datetime" || type === "time",
          noCalendar: type === "time",
          maxDate: endDate,
        }}
        className="mb-1"
        value={startDate}
        label="From:"
        minWidth={240}
        type={type}
        onChange={handleStartDateChange}
        placeholder={getPlaceholder(type)}
      />
      <CustomInput
        disabled={disabled}
        onClose={onBlur}
        static={staticMode}
        options={{
          mode: "single",
          dateFormat: getDateFormat(type),
          enableTime: type === "datetime" || type === "time",
          noCalendar: type === "time",
          minDate: startDate,
        }}
        value={endDate}
        label="To:"
        minWidth={240}
        type={type}
        onChange={handleEndDateChange}
        placeholder={getPlaceholder(type)}
      />
    </div>
  );
};

export { MultiDateTime };
