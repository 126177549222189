import type { UserSelectDefaultButtonProps } from ".";
import { Icon } from "@urbint/silica";
import { isUnassignedUser } from "@/utils/users";
import { Avatar } from "@/common/Avatar";

const UserSelectDefaultButton = ({
  selectedUser,
  disabled,
  isCurrentUser,
  ref,
}: UserSelectDefaultButtonProps) => (
  <button
    type="button"
    className={`w-full h-9 py-1 px-3 mb-2 flex flex-row items-center justify-start
  border border-solid border-neutral-shade-border-light rounded-md
  ${disabled ? "cursor-default" : "focus:border-brand-40"}
  `}
    ref={ref}
  >
    {!isUnassignedUser(selectedUser) && <Avatar user={selectedUser} />}
    <span
      className={`px-2 truncate
  ${isUnassignedUser(selectedUser) ? "text-neutral-shade-tertiary" : ""}
  `}
    >
      {!isUnassignedUser(selectedUser) && selectedUser?.displayName}
      {isCurrentUser ? <span>(you)</span> : ""}
    </span>
    <Icon
      className={`ml-auto ${
        disabled ? "" : "cursor-pointer"
      } text-neutral-shade-tertiary`}
      name="chevron_down"
    />
  </button>
);

export { UserSelectDefaultButton };
