import type { ThreatRankType } from "@/models/DamageThreatInsight";
import get from "lodash/get";
import preval from "preval.macro";
import {
  DueDateCategorization,
  ImpactPotential,
  DamagePotential,
} from "@/models";

const themeColors = preval`
  const resolveConfig = require('tailwindcss/resolveConfig');
  const tailwindConfig = require('../../tailwind.config');
  const fullConfig = resolveConfig(tailwindConfig);
  module.exports = fullConfig.theme.colors;
`;

const getThemeColor = (className: string | undefined): string => {
  if (!className) return "";

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_type, color, ...rest] = className.split("-");
  const weight = rest.join("-");

  if (!color) return "";
  if (!weight) return get<string>(themeColors, [color], "");

  return get(themeColors, [color, weight], "") ?? "";
};

const ONE_DAY = 86400000;

const formatRelativeTime = (time: Date, relativeOrigin: Date = new Date()) => {
  const delta = time.getTime() - relativeOrigin.getTime();
  let absDelta = Math.abs(delta);
  if (absDelta > ONE_DAY) {
    const timeAtMidnight = new Date(time);
    timeAtMidnight.setHours(0, 0, 0, 0);
    const originAtMidnight = new Date(relativeOrigin);
    originAtMidnight.setHours(0, 0, 0, 0);
    absDelta = Math.abs(timeAtMidnight.getTime() - originAtMidnight.getTime());
  }

  let label: string | undefined;
  const units: [number, string][] = [
    [7 * 86400000, "week"],
    [86400000, "day"],
    [3600000, "hour"],
    [60000, "minute"],
    [1000, "second"],
  ];

  // Iterate over each of the respective ms / unit label (sorted above) and
  // find the largest one for our given absolute delta. As soon as we find one
  // assign the `label` to that value of units and break.
  for (const [x, unit] of units) {
    if (absDelta >= x) {
      const qty = Math.floor(absDelta / x);
      const pluralizedUnit = qty > 1 ? `${unit}s` : unit;
      label = `${qty} ${pluralizedUnit}`;
      break;
    }
  }

  label = label || "a few seconds";

  if (delta > 0) {
    return `in ${label}`;
  }
  return `${label} ago`;
};

const nDaysAgo = (n: number) => {
  const d = new Date();
  d.setHours(0, 0, 0, 0);
  d.setDate(d.getDate() - n);
  return d;
};

const relativeKeywords: { [key: string]: () => Date } = {
  yesterday: () => nDaysAgo(1),
  now: () => new Date(),
  today: () => nDaysAgo(0),
  tomorrow: () => nDaysAgo(-1),
};

const units: { [key: string]: number } = {
  week: 7 * 86400000,
  day: 86400000,
  hour: 3600000,
  minute: 60000,
  second: 1000,
};

const parseRelativeDateTime = (relative: string | Date | undefined) => {
  if (!relative) {
    return "";
  }
  if (relative instanceof Date) {
    return relative;
  }

  // handle special keywords
  if (relativeKeywords[relative as string]) {
    const specialFn = relativeKeywords[relative as string];
    return specialFn && specialFn();
  }

  const parts = relative.split(" ");
  if (parts.length === 1) {
    // handle parsing iso date strings
    return new Date(relative);
  }
  if (parts.length === 2) {
    // handle relative strs
    const [num, unit] = parts;
    const singleUnit = unit?.replace(/s$/, "") as string;
    const parsedNum = parseInt(num as string, 10);

    if (units[singleUnit]) {
      const millis = units[singleUnit] as number;
      const d = new Date();
      d.setTime(d.getTime() + millis * parsedNum);
      return d;
    }
    console.warn(
      "Unknown unit string in relative date string",
      singleUnit,
      relative
    );
  } else {
    console.warn("Unparsable relative date string", relative);
  }
};

const fullDateTimeFormatter = new Intl.DateTimeFormat("en-US", {
  day: "numeric",
  month: "short",
  year: "numeric",
  hour: "numeric",
  minute: "numeric",
});

// Return dates in the format of Sep 10, 2021 9:30am
const formatFullDateTime = (date?: Date | string | null): string => {
  if (date) {
    if (date instanceof Date) {
      return fullDateTimeFormatter.format(date);
    }
    if (typeof date === "string") {
      if (!Number.isNaN(Date.parse(date))) {
        const createNewDateFromString = new Date(date);
        return fullDateTimeFormatter.format(createNewDateFromString);
      }
    }
  }
  return "";
};

const formatPhoneNumber = (phoneNumber?: string) => {
  // (xxx)x -> (xxx) x
  if (!phoneNumber) return "";
  return phoneNumber.replace(/^\((\d{3})\)(\S)/, "($1) $2");
};

const fullDateFormatter = new Intl.DateTimeFormat("en-US", {
  weekday: "short",
  day: "numeric",
  month: "short",
  year: "numeric",
});

// Returns dates in the format of Sun, Dec 31, 2020
const formatFullDate = (d?: Date | "") => {
  if (!d) return "";
  return fullDateFormatter.format(d);
};

const calendarDateFormatter = new Intl.DateTimeFormat("en-US", {
  day: "numeric",
  month: "numeric",
  year: "numeric",
});

// Returns dates in the format of 12/31/2020
const formatCalendarDate = (d?: Date | "") => {
  if (!d) return "";
  return calendarDateFormatter.format(d);
};

// Returns dates in the format of 12-31-2020
const formatDateInUTC = (d?: Date | "") => {
  if (!d) return "";
  return `${d.getUTCMonth() + 1}-${d.getUTCDate()}-${d.getUTCFullYear()}`;
};

const formatDateTime = (rawDate?: Date) => {
  const [date, time] = rawDate
    ? new Date(rawDate).toISOString().split("T")
    : new Date().toISOString().split("T");

  return `${date} ${time}`;
};

// This replace is to prevent the safari exception. It doesn't like dashes and the server sometimes returns dashes..
const getDateBrowserProof = (value: string) =>
  new Date(value.replace(/-/g, "/"));

const clockTimeFormatter = new Intl.DateTimeFormat("en-US", {
  hour12: true,
  hour: "numeric",
  minute: "numeric",
});
// Returns the time component of the date as 2:00pm
const formatClockTime = (d?: Date | "") => {
  if (!d) return "";
  return clockTimeFormatter.format(d).toLocaleLowerCase().replace(" ", "");
};

const startCase = (value?: string | null) => {
  if (!value) return "";
  return value
    ?.trim()
    .split(/[_| ]+/)
    .map((v) => v[0]?.toUpperCase() + v.slice(1).toLowerCase())
    .join(" ");
};

const riskLabel = (
  risk?:
    | DamagePotential
    | ImpactPotential
    | DueDateCategorization
    | ThreatRankType
) => {
  // Right now, our enums map over 1:1 when start cased
  // Expand this if that changes
  if (risk === ImpactPotential.NONE || risk === DamagePotential.NONE) {
    return "No Category";
  }
  // if falsy, then startCase function should take care of it
  return startCase(risk);
};

const riskColor = (
  risk?: DamagePotential | ImpactPotential | DueDateCategorization,
  mode: "text" | "bg" | "text-contrast" = "text"
) => {
  if (mode === "text") {
    switch (risk) {
      case DueDateCategorization.PAST_DUE:
      case ImpactPotential.VERY_HIGH:
        return "text-risk-very-high";
      case DueDateCategorization.TODAY:
      case ImpactPotential.HIGH:
        return "text-risk-high";
      case DueDateCategorization.TOMORROW:
      case ImpactPotential.MEDIUM:
        return "text-risk-medium";
      case DueDateCategorization.AFTER_TOMORROW:
      case ImpactPotential.LOW:
        return "text-risk-low";
      case ImpactPotential.MISSING_DATA:
        return "text-risk-missing-data";
      default:
        return "text-risk-none";
    }
  } else if (mode === "bg") {
    switch (risk) {
      case DueDateCategorization.PAST_DUE:
      case ImpactPotential.VERY_HIGH:
        return "bg-risk-very-high";
      case DueDateCategorization.TODAY:
      case ImpactPotential.HIGH:
        return "bg-risk-high";
      case DueDateCategorization.TOMORROW:
      case ImpactPotential.MEDIUM:
        return "bg-risk-medium";
      case DueDateCategorization.AFTER_TOMORROW:
      case ImpactPotential.LOW:
        return "bg-risk-low";
      case ImpactPotential.MISSING_DATA:
        return "bg-risk-missing-data";
      default:
        return "bg-risk-none";
    }
  } else if (mode === "text-contrast") {
    switch (risk) {
      default:
        return "text-white";
    }
  }
};

const addCommaInArrayValues = (value?: Array<any>) => {
  if (!value) return "";
  return value.join(", ");
};

const wholeNumber = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 0,
});

const pluralize = (count: number, noun: string, suffix = "s") =>
  `${wholeNumber.format(count)} ${noun}${count === 1 ? "" : suffix}`;

/**
 * Transforms a original string or replacement string to upper case.
 * @param str original string
 * @param sub replacement string
 * @returns string in upper case
 */
const safeToUpperCase = (str: string | null | undefined, sub = ""): string => {
  if (str) {
    return str.toUpperCase();
  }
  return sub.toUpperCase();
};

export {
  getThemeColor,
  formatRelativeTime,
  parseRelativeDateTime,
  formatFullDateTime,
  formatPhoneNumber,
  formatFullDate,
  formatCalendarDate,
  formatDateInUTC,
  formatDateTime,
  getDateBrowserProof,
  formatClockTime,
  riskLabel,
  riskColor,
  startCase,
  pluralize,
  safeToUpperCase,
  addCommaInArrayValues,
};
