import { useMutation } from "@tanstack/react-query";

import { UrbintApi } from "@/utils/UrbintApi";

import {
  invalidateUseTicketActivityQuery,
  invalidateUseFlexTicketsQuery,
} from "@/api";

interface IUseAddCommentToTicket {
  ticket: number;
  comment: string;
  creator: string;
}

const api = new UrbintApi();

const addCommentToTicket = (body: IUseAddCommentToTicket) =>
  api.post("ticket_comments", JSON.stringify(body));

const useAddCommentToTicket = () =>
  useMutation((opts: IUseAddCommentToTicket) => addCommentToTicket(opts), {
    onSuccess: (_, { ticket: ticketId }) => {
      // Invalidate any queries that might be affected
      // by this change
      invalidateUseTicketActivityQuery(ticketId);
      invalidateUseFlexTicketsQuery();
    },
  });

// eslint-disable-next-line import/prefer-default-export
export { useAddCommentToTicket };
