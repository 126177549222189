import type { DetailsSectionProps } from "./DetailsSection.types";

const DetailsSection = ({ title, children }: DetailsSectionProps) => (
  <section className="pt-6 group">
    <h3 className="text-black font-semibold mb-3 text-lg">{title}</h3>
    <div className="mb-6">{children}</div>
    <hr className="h-px w-12 bg-black bg-opacity-11 group-last:hidden" />
  </section>
);

export { DetailsSection };
