import { useMutation } from "@tanstack/react-query";

import { UrbintApi } from "@/utils/UrbintApi";

import {
  invalidateUseFlexTicketQuery,
  invalidateUseFlexTicketsQuery,
  invalidateUseTicketActivityQuery,
} from "@/api";

import { handleApiResponse } from "@/api/helpers";

const api = new UrbintApi();

const bulkCreateTicketComments = (body: BulkCreateTicketCommentPayload[]) =>
  api.post("tickets/bulk_action", JSON.stringify(body)).then(handleApiResponse);

interface BulkCreateTicketCommentPayload {
  ticket: number;
  comment: string;
}

const useBulkCreateTicketComments = () =>
  useMutation(bulkCreateTicketComments, {
    onSuccess: (_, actions) => {
      actions.forEach((action) => {
        invalidateUseTicketActivityQuery(action.ticket);
        invalidateUseFlexTicketQuery(action.ticket);
      });
      invalidateUseFlexTicketsQuery();
    },
  });

// eslint-disable-next-line import/prefer-default-export
export { useBulkCreateTicketComments };
