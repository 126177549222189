import type { RightSideElementsProps } from "./RightSideElements.types";
import { Icon } from "@urbint/silica";

const RightSideElements = ({
  shouldRenderClearAllOptions,
  setOptions,
}: RightSideElementsProps) => (
  <div className="absolute right-4 top-1/2 -translate-y-1/2 flex flex-nowrap justify-center">
    {shouldRenderClearAllOptions && (
      <Icon
        name="close_big"
        className="block mr-2 cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          setOptions([]);
        }}
      />
    )}
    <Icon name="chevron_big_down" className="cursor-pointer" />
  </div>
);

export { RightSideElements };
