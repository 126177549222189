import type { SpinnerProps } from "./Spinner.types";
import cx from "classnames";
import { Icon } from "@urbint/silica";

const Spinner = ({ className }: SpinnerProps) => (
  <span
    className={cx("flex animate-spin font-[1.125rem] items-center", className)}
  >
    <Icon name="uploading" />
  </span>
);

export { Spinner };
