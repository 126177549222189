import type { AutomationEngineRuleSerializer } from "@/utils/damage-prevention";
import { UrbintModel } from "@/utils/UrbintModel";

class AutoAssignmentRule extends UrbintModel<AutomationEngineRuleSerializer> {
  get id() {
    return this.castNumber(this.data.id);
  }

  get name() {
    return this.castString(this.data.name);
  }

  get memberCodes(): string[] {
    return this.castArray(this.data.member_codes);
  }

  get ticketTypes(): string[] {
    return this.castArray(this.data.ticket_types);
  }

  get shift() {
    return this.castString(this.data.shift);
  }

  get assigneeUsername() {
    return this.castString(this.data.assignee_username);
  }

  get notifySms(): boolean {
    return this.castBool(this.data.notify_sms);
  }

  get notifyEmail(): boolean {
    return this.castBool(this.data.notify_email);
  }

  serialize() {
    return this.data;
  }

  compare(instance: AutoAssignmentRule) {
    return this.id - instance.id;
  }
}

export { AutoAssignmentRule };
