import type { ListItemProps } from "./ListItem.types";
import cx from "classnames";

const ListItem = ({
  leftSlot,
  centerSlot,
  rightSlot,
  withRadius = false,
  className,
}: ListItemProps) => (
  <li
    className={cx(
      "flex justify-start gap-2 p-2 w-full sm:min-w-[17rem]",
      {
        "rounded-md": withRadius,
      },
      className
    )}
  >
    {leftSlot && (
      <div className="flex w-16 justify-center items-center">{leftSlot}</div>
    )}
    {centerSlot && (
      <div className="flex flex-grow items-center">{centerSlot}</div>
    )}
    {rightSlot && (
      <div className="flex w-16 justify-center items-center">{rightSlot}</div>
    )}
  </li>
);

export { ListItem };
