import { TicketDetailsAction } from "./TicketDetailsAction";
import { TicketDetailsNavigation } from "./TicketDetailsNavigation";

const NavBarSection = () => (
  <nav className="bg-backgrounds-gray p-2 text-neutrals-secondary w-full flex justify-center">
    <div className="flex items-center max-w-2xl w-full justify-between flex-wrap">
      <TicketDetailsAction />
      <TicketDetailsNavigation />
    </div>
  </nav>
);

export { NavBarSection };
