const VISIBLE_OPTIONS_LIMIT = 1000;

const SELECT_ALL_MIN_CHARACTER_LENGTH = 3;

const SELECTED_LABEL = "Selected";

export {
  VISIBLE_OPTIONS_LIMIT,
  SELECT_ALL_MIN_CHARACTER_LENGTH,
  SELECTED_LABEL,
};
