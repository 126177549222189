import type { ThreatRankType } from "@/models/DamageThreatInsight";
import { DamagePotential } from "@/models";

const getGaugeArcColor = (value?: DamagePotential | ThreatRankType) => {
  switch (value) {
    case DamagePotential.VERY_HIGH:
      return "text-badges-red";
    case DamagePotential.HIGH:
      return "text-badges-orange";
    case DamagePotential.MEDIUM:
      return "text-badges-blue";
    case DamagePotential.LOW:
      return "text-badges-green";
    case DamagePotential.MISSING_DATA:
      return "text-badges-darkgray";
    default:
      return "text-badges-gray";
  }
};

export { getGaugeArcColor };
