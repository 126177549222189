import type { ResponseForm, Task } from "@/models";
import type { UseMutateFunction } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";

import { datadogRum } from "@datadog/browser-rum";
import { StatusCodes } from "http-status-codes";
import {
  invalidateUseAnswerSetQuery,
  invalidateUseDynamicOptionsQuery,
  invalidateUseTasksForTicketQuery,
  invalidateUsePositiveResponsesForTicketQuery,
  invalidateUseTicketActivityQuery,
  invalidateUseFlexTicketQuery,
  invalidateUseTicketQuery,
  invalidateUseFlexTicketsQuery,
} from "@/api";

import { UrbintApi } from "@/utils/UrbintApi";
import { handleApiResponse } from "@/api/helpers";
import { retryDelayStrategy } from "@/api/utils";
import { MAXIMUM_RETRIES } from "@/api/constants";
import { queryClient } from "@/api/client";

const DATADOG_ONMUTATE_CACHE_CLEANING_ERROR =
  "ANSWER_RESPONSE_FORM_ON_MUTATION_CACHE_HANDLE";

interface Question {
  id: number;
  response: any;
}

interface IUseAnswerResponseForm {
  task: Task;
  responseForm?: ResponseForm;
  questions: Question[];
  autoSave: boolean;
  isOnline: boolean;
}

type mutationData = {
  taskId: number;
  ticketId: number;
};

const api = new UrbintApi();

const answerResponseFormIdent = ({ taskId, ticketId }: mutationData) => [
  "answerResponseForm",
  ticketId,
  taskId,
];

const answerResponseForm = (opts: IUseAnswerResponseForm) => {
  let set_initial_step = true;
  opts.questions.forEach((question) => {
    if (typeof question.response === "string" && question.response !== "") {
      set_initial_step = false;
    }
    if (Array.isArray(question.response) && question.response.length > 0) {
      set_initial_step = false;
    }
  });
  return api
    .post(
      "response_form_answers",
      JSON.stringify({
        task_id: opts.task?.id,
        response_form_id: opts.responseForm?.id,
        questions: opts.questions,
        auto_save: opts.autoSave,
        set_initial_step,
      })
    )
    .then(handleApiResponse);
};

const useAnswerResponseForm = ({ taskId, ticketId }: mutationData) =>
  useMutation(
    answerResponseFormIdent({ taskId, ticketId }),
    answerResponseForm,
    {
      onSuccess: (_, { task, responseForm }) => {
        // Invalidate any queries that might be affected
        // by this change
        invalidateUseTicketActivityQuery(task.ticketId);
        invalidateUseTasksForTicketQuery(task.ticketId);
        invalidateUseAnswerSetQuery(task.id);
        invalidateUsePositiveResponsesForTicketQuery(task.ticketId);
        invalidateUseFlexTicketQuery(task.ticketId);
        invalidateUseTicketQuery(task.ticketId, true);
        invalidateUseFlexTicketsQuery();
        if (responseForm)
          invalidateUseDynamicOptionsQuery(responseForm.id, task.id);
      },
      onMutate: async ({ isOnline }) => {
        if (!isOnline) {
          try {
            const mutationCache = await queryClient
              .getMutationCache()
              .findAll({
                mutationKey: answerResponseFormIdent({ taskId, ticketId }),
              })
              .filter(({ state }) => {
                const { status } = state;
                return status === "loading";
              });

            if (mutationCache && mutationCache.length > 1) {
              mutationCache.slice(0, -1).forEach((mutation) => {
                queryClient.getMutationCache().remove(mutation);
              });
            }
          } catch (error) {
            datadogRum.addError(error, {
              errorIdentifier: DATADOG_ONMUTATE_CACHE_CLEANING_ERROR,
            });
          }
        }
      },
      retry: (failureCount, error) => {
        if (error instanceof Error) {
          if (
            error.message.includes(String(StatusCodes.INTERNAL_SERVER_ERROR))
          ) {
            return false;
          }
          if (error.message.includes(String(StatusCodes.UNAUTHORIZED))) {
            return true;
          }
        }

        return MAXIMUM_RETRIES > failureCount;
      },
    }
  );

const mutationQueue: Promise<void> = Promise.resolve();

function saveAnswerResponseForm(
  mutate: UseMutateFunction<unknown, unknown, IUseAnswerResponseForm, unknown>,
  opts: IUseAnswerResponseForm
) {
  return mutationQueue.then(() => mutate(opts));
}

export type { Question };
export { saveAnswerResponseForm, useAnswerResponseForm };
