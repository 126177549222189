import type { SingleDateTimeProps } from "./SingleDateTime.types";
import { useMemo } from "react";
import { CustomInput } from "../CustomInput";
import { getDateFormat, getPlaceholder } from "../DateTimeInput.utils";

const SingleDateTime = ({
  onDateChange,
  date,
  type,
  disabled,
  onBlur,
  staticMode,
  className,
  hasClear,
  clearTooltipText,
  hasError,
}: SingleDateTimeProps) => {
  const handleChange = useMemo(
    () =>
      ([newDate]: Date[]) => {
        if (onDateChange) onDateChange(newDate);
        onBlur?.();
      },
    [onDateChange, onBlur]
  );

  return (
    <CustomInput
      disabled={disabled}
      onChange={handleChange}
      static={staticMode}
      options={{
        mode: "single",
        dateFormat: getDateFormat(type),
        enableTime: type === "datetime" || type === "time",
        noCalendar: type === "time",
      }}
      minWidth={240}
      type={type}
      value={date}
      placeholder={getPlaceholder(type)}
      className={className}
      hasError={hasError}
      hasClear={hasClear}
      clearTooltipText={clearTooltipText}
    />
  );
};

export { SingleDateTime };
