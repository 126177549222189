import type { TicketPageSidebarProps } from "./TicketPageSideBar.types";
import { useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import cx from "classnames";
import { useFullScreen, useOnlineStatus } from "@/hooks";

import { Activities, ActivitiesOffline } from "../Activities";
import { TaskDetail } from "./TaskDetail";
import { Sidebar } from "./Sidebar";
import { TasksSection } from "./TasksSection";

const TicketPageSideBar = ({ ticket }: TicketPageSidebarProps) => {
  const { search } = useLocation();
  const history = useHistory();
  const { isFullscreen } = useFullScreen();
  const isOnline = useOnlineStatus();

  const taskIdFromSearch = useMemo(() => {
    const queryParams = new URLSearchParams(search);
    const taskId = queryParams.get("taskId")
      ? Number(queryParams.get("taskId"))
      : undefined;
    return taskId;
  }, [search]);

  const updateSearchParams = (taskId: number | undefined) => {
    const searchParams = new URLSearchParams(window.location.search);

    if (taskId) {
      searchParams.set("taskId", taskId.toString());
    } else {
      searchParams.delete("taskId");
    }
    const searchParamsStr = searchParams.toString();
    history.replace({ search: searchParamsStr });
  };

  return (
    <div
      className={cx(
        "md:w-[550px] lg:w-[375px] md:h-full z-10 md:relative md:overflow-hidden",
        { hidden: isFullscreen }
      )}
    >
      {taskIdFromSearch && (
        <TaskDetail
          ticket={ticket}
          taskId={taskIdFromSearch}
          onClose={() => {
            updateSearchParams(undefined);
          }}
        />
      )}
      <Sidebar tabs={["Tasks", "Activity"]}>
        <TasksSection
          ticket={ticket}
          onTaskClick={({ id }) => updateSearchParams(id)}
        />
        {isOnline ? <Activities ticket={ticket} /> : <ActivitiesOffline />}
      </Sidebar>
    </div>
  );
};

export { TicketPageSideBar };
