import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { UserPreferences } from "@/models";
import { UrbintApi } from "@/utils/UrbintApi";
import { handleApiResponse } from "@/api/helpers";

const api = new UrbintApi();

const preferencesIdent = () => ["me/preferences"];

const fetchPreferences = () =>
  api
    .getOne({
      endPoint: "me/preferences",
    })
    .then(handleApiResponse)
    .then((x) => new UserPreferences(x));

const usePreferences = (
  opts?: UseQueryOptions<UserPreferences, unknown, UserPreferences, string[]>
) => useQuery(preferencesIdent(), fetchPreferences, opts);

export { preferencesIdent, usePreferences };
