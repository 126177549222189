import type { TicketQueueCardViewProps } from "./TicketQueueCardView.types";
import { Link } from "react-router-dom";
import { MapsButton, TelButton } from "@/common/Button";
import { TicketContent } from "@/components/TicketCard";
import { TicketStatusMenu } from "./TicketStatusMenu";

const TicketQueueCardView = ({
  item,
  onChangeStatus,
  onClickRemove,
  ticket,
  canRemoveFromQueue,
}: TicketQueueCardViewProps) => {
  const {
    excavationCity,
    excavationState,
    excavationStreetName,
    excavationStreetNumber,
    contacts = [],
  } = ticket.latestVersion ?? {};

  const address = `${excavationStreetNumber} ${excavationStreetName} ${excavationCity}, ${excavationState}`;
  const primaryContact = contacts.find((c) => c.primary);
  const onSiteContact = contacts.find((c) => c.onSite);
  const firstContact = contacts[0];

  let phoneNumber = "";
  if (primaryContact?.phoneNumber) {
    phoneNumber = primaryContact.phoneNumber;
  } else if (onSiteContact?.phoneNumber) {
    phoneNumber = onSiteContact.phoneNumber;
  } else if (firstContact?.phoneNumber) {
    phoneNumber = firstContact.phoneNumber;
  }

  return (
    <div className="flex flex-1 flex-col py-2" data-testid="ticket-card-queue">
      <Link to={`/ticket/${ticket.id}`}>
        <TicketContent ticket={ticket} />
      </Link>
      <div className="flex flex-1 items-center justify-start py-2 mt-1">
        <div className="mr-2">
          <TicketStatusMenu
            canRemoveFromQueue={canRemoveFromQueue}
            onChangeStatus={onChangeStatus}
            onClickRemove={onClickRemove}
            item={item}
          />
        </div>
        <div className="mr-2">
          <TelButton phoneNumber={phoneNumber} />
        </div>
        <MapsButton query={address} />
      </div>
    </div>
  );
};

export { TicketQueueCardView };
