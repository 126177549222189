import type { SavedViewSerializer } from "@/utils/damage-prevention";
import type { TicketView } from "./TicketViewEditor.types";
import isEqual from "lodash/isEqual";
import { useCallback, useEffect, useMemo, useState } from "react";

import { useSavedViews } from "@/api";
import { SavedView } from "@/models";

const STORAGE_KEY = "@urbint/damage-prevention/ticket-view-storage";

const read = <T>(aKey: string) => {
  const result = sessionStorage.getItem(aKey);
  if (result !== null) {
    return JSON.parse(result) as T;
  }
};

const write = (aKey: string, aValue?: Record<string, any>) => {
  if (aValue) {
    const data = JSON.stringify(aValue);
    sessionStorage.setItem(aKey, data);
  } else {
    sessionStorage.removeItem(aKey);
  }
};

const writeSavedView = (view: SavedView) =>
  write(`${STORAGE_KEY}/saved-view`, view.serialize());

const readSavedView = () => {
  const data = read<SavedViewSerializer>(`${STORAGE_KEY}/saved-view`);
  if (data) return new SavedView(data);
};

// this dirty view storage supports editing filters and persisting those edits across refreshes
const writeDirtyView = (view?: TicketView) => {
  write(`${STORAGE_KEY}/dirty-view`, view);
};

const readDirtyView = () => {
  const data = read<TicketView>(`${STORAGE_KEY}/dirty-view`);
  if (data) return parseTicketView(data);
};

/**
 * Seems like `writing` to local storage will wipe any keys with
 * undefined attached to them. That will make our equality checks used for
 * `isDirty` fail. Also, dates are persisted as strings and note as date objects
 * so we need to parse them back on load
 */
const parseTicketView = (view: TicketView): TicketView => ({
  ...view,
  id: view.id,
  name: view.name,
  createdOn: view.createdOn ? new Date(view.createdOn) : view.createdOn,
  updatedOn: view.updatedOn ? new Date(view.updatedOn) : view.updatedOn,
  userId: view.userId,
  isShared: view.isShared,
  isShowingTasks: view.isShowingTasks,
  order: view.order,
  filters: view.filters,
  columns: view.columns,
  // Don't add this one though! That doesn't exist in the saved view,
  // so adding that will yet again result in a isDirty===true
  // searchTerm: view.searchTerm,
});

const useViewStore = () => {
  const { data: savedViews } = useSavedViews();

  const [dirtyView, setDirtyView] = useState(readDirtyView());
  const [savedView, setSavedView] = useState(readSavedView());

  const defaultSavedView = useMemo(() => {
    if (!savedViews) return undefined;

    return savedViews.find((x) => x.isDefaultView) || savedViews.find(Boolean);
  }, [savedViews]);

  const updateSavedView = useCallback((savedView: SavedView) => {
    writeSavedView(savedView);
    setSavedView(savedView);
  }, []);

  const updateDirtyView = useCallback((dirtyView: TicketView) => {
    writeDirtyView(dirtyView);
    setDirtyView(dirtyView);
  }, []);

  const clearDirtyView = useCallback(() => {
    writeDirtyView();
    setDirtyView(undefined);
  }, []);

  useEffect(() => {
    // make sure our selected view matches the view from the backend,
    // unless we have a dirty view
    if (savedView && !dirtyView) {
      if (
        savedViews &&
        savedViews.filter((v) => isEqual(v, savedView)).length === 0
      ) {
        // no matching view found! the cached state.selectedView must be outdated
        const match = savedViews
          .filter((v) => v.id === savedView?.id)
          .find(Boolean);
        if (match) updateSavedView(match);
        else if (defaultSavedView) {
          // we don't have access to this saved view anymore (it was unassigned or deleted)
          // so try to assign one that will work
          updateSavedView(defaultSavedView);
        }
      }
    }

    // If we don't have the savedViews or preferences loaded, or if
    // there is already a selectedView, bail out.
    if (!savedView && defaultSavedView) {
      updateSavedView(defaultSavedView);
    }
  }, [savedView, savedViews, defaultSavedView]);

  return {
    dirtyView,
    savedView,
    defaultSavedView,
    updateSavedView,
    updateDirtyView,
    clearDirtyView,
  };
};

export {
  writeSavedView,
  readSavedView,
  writeDirtyView,
  readDirtyView,
  useViewStore,
};
