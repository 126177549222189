import type {
  DamagePotentialCardColorPalette,
  ThreatDetails,
} from "./DamageThreatCard.types";
import type {
  DamagePotential as DamagePotentialType,
  DamageThreat,
} from "@/models";
import { DamagePotential, InsightName } from "@/models";

const getDamageThreatCardDetails = ({
  insight: { insightNameType },
}: DamageThreat): ThreatDetails => {
  switch (insightNameType) {
    case InsightName.EXCAVATOR_RANK:
      return {
        title: "Excavator Rank",
        insightIcon: "excavator",
      };
    case InsightName.TICKET_TYPE:
      return {
        title: "Ticket Type",
        insightIcon: "cross_hammer_wrench",
      };
    case InsightName.WORK_TYPE_RISK:
      return {
        title: "Work Type",
        insightIcon: "worker_helmet",
      };
    case InsightName.LOCATION_COMPLEXITY:
      return {
        title: "Location Complexity",
        insightIcon: "map_location_pin",
      };
    default:
      return {
        title: "Unknown Threat",
        insightIcon: "help_questionmark",
      };
  }
};

const getDamageThreatCardColors = (
  threatRank: DamagePotentialType | string
): DamagePotentialCardColorPalette => {
  switch (threatRank) {
    case DamagePotential.VERY_HIGH:
      return {
        backgroundColor: "bg-feedback-error-background",
        borderColor: "border-feedback-error-border",
        iconColor: "text-feedback-error-content",
      };
    case DamagePotential.HIGH:
      return {
        backgroundColor: "bg-feedback-warning-background",
        borderColor: "border-feedback-warning-border",
        iconColor: "text-feedback-warning-content",
      };
    case DamagePotential.MEDIUM:
      return {
        backgroundColor: "bg-feedback-info-background",
        borderColor: "border-feedback-info-border",
        iconColor: "text-feedback-info-content",
      };

    case DamagePotential.LOW:
      return {
        backgroundColor: "bg-feedback-success-background",
        borderColor: "border-feedback-success-border",
        iconColor: "text-feedback-success-content",
      };

    // DamagePotential.NONE DamagePotential.NA or others
    default:
      return {
        backgroundColor: "bg-info-background",
        borderColor: "border-info-border",
        iconColor: "text-info-content",
      };
  }
};

export { getDamageThreatCardDetails, getDamageThreatCardColors };
