import { IonIcon } from "@ionic/react";
import { contractOutline, expandOutline } from "ionicons/icons";
import { useFullScreen } from "@/hooks";
import { MapControl } from "../MapControl";

const MapFullscreenControl = () => {
  const { isFullscreen, toggleFullscreen } = useFullScreen();

  return (
    <MapControl onClick={toggleFullscreen}>
      <div className="w-full h-full flex items-center justify-center">
        <IonIcon
          icon={isFullscreen ? contractOutline : expandOutline}
          className="text-xl"
        />
      </div>
    </MapControl>
  );
};

export { MapFullscreenControl };
