import type { GaugeProps } from "./Gauge.types";
import { scaleLinear } from "d3-scale";
import cx from "classnames";
import {
  DEFAULT_GREEN_ARC_COLOR,
  DEFAULT_ORANGE_ARC_COLOR,
  DEFAULT_RED_ARC_COLOR,
  DEFAULT_YELLOW_ARC_COLOR,
  firstArc,
  secondArc,
  thirdArc,
  fourthArc,
  FILL_CURRENT,
} from "./Gauge.constants";
import { angleScale, getDefaultBlobColor, getCoordsOnArc } from "./Gauge.utils";
import { GaugeDescription } from "./GaugeDescription";

const Gauge = ({ value = 0, min = 0, max = 100, label, theme }: GaugeProps) => {
  const percentScale = scaleLinear().domain([min, max]).range([0, 1]);
  const percent = percentScale(value);
  const angle = angleScale(percent);
  const markerLocation = getCoordsOnArc(angle);

  return (
    <div className={cx("relative w-full text-center p-2", theme?.wrapper)}>
      <svg
        className={cx("overflow-visible", theme?.svg)}
        viewBox={[-1, -1, 2, 1].join(" ")}
      >
        <defs>
          <filter id="drop-shadow" x="-15%" y="-15%" width="140%" height="140%">
            <feGaussianBlur in="SourceAlpha" stdDeviation="0.003" />
          </filter>
        </defs>
        <circle
          id="shadowed-circle"
          cx={markerLocation[0]}
          cy={markerLocation[1]}
          r="0.08"
          strokeWidth="0.04"
          fill="transparent"
          stroke="white"
          filter="url(#drop-shadow)"
        />
        <path
          d={firstArc}
          className={cx(
            FILL_CURRENT,
            theme?.arcColor ?? DEFAULT_GREEN_ARC_COLOR
          )}
        />
        <path
          d={secondArc}
          className={cx(
            FILL_CURRENT,
            theme?.arcColor ?? DEFAULT_YELLOW_ARC_COLOR
          )}
        />
        <path
          d={thirdArc}
          className={cx(
            FILL_CURRENT,
            theme?.arcColor ?? DEFAULT_ORANGE_ARC_COLOR
          )}
        />
        <path
          d={fourthArc}
          className={cx(FILL_CURRENT, theme?.arcColor ?? DEFAULT_RED_ARC_COLOR)}
        />
        <circle
          cx={markerLocation[0]}
          cy={markerLocation[1]}
          r="0.08"
          strokeWidth="0.04"
          className={cx(
            FILL_CURRENT,
            theme?.arcColor ?? getDefaultBlobColor(percent * 100)
          )}
          stroke="white"
        />
      </svg>
      <GaugeDescription value={value} min={min} max={max} label={label} />
    </div>
  );
};

export { Gauge };
