import type { TopRightMapControlsProps } from "./TopRightMapControls.types";
import { MeasureToolControls } from "@/components/MeasureTool";
import {
  MapControls,
  MapLayerControls,
  MapLocateMeControl,
  MapZoomControls,
} from "@/components/Map/Controls";
import { usePublicConfig } from "@/api";

const TopRightMapControls = ({
  setOnlocateMe,
  MapLayerControlsProps,
  measureModeState,
}: TopRightMapControlsProps) => {
  const { data: publicConfig } = usePublicConfig();

  return (
    <MapControls>
      <MapZoomControls />

      <MapLocateMeControl onLocateMe={setOnlocateMe} />
      <MapLayerControls
        layers={MapLayerControlsProps.layers}
        onLayerToggle={MapLayerControlsProps.toggleLayer}
      />
      {publicConfig?.enableMeasuringTool && (
        <MeasureToolControls
          isInMeasureMode={measureModeState.get}
          setIsInMesureMode={measureModeState.set}
        />
      )}
    </MapControls>
  );
};

export { TopRightMapControls };
