import type { PropsWithChildren } from "react";
import { createContext, useContext, useMemo } from "react";
import { SEARCH_ICON_SUFFIX } from "../TypeheadSelect.constants";

type TypeaheadProviderProps = PropsWithChildren<{
  /** This should be a unique name */
  name: string;
}>;

const TypeaheadContext = createContext<
  { name: string; searchIconId: string } | undefined
>(undefined);

const TypeaheadProvider = ({ children, name }: TypeaheadProviderProps) => {
  const searchIconId = `${name}${SEARCH_ICON_SUFFIX}`;
  const value = useMemo(() => ({ name, searchIconId }), [name, searchIconId]);
  return (
    <TypeaheadContext.Provider value={value}>
      {children}
    </TypeaheadContext.Provider>
  );
};

const useTypeahead = () => {
  const context = useContext(TypeaheadContext);
  if (!context) {
    throw new Error("useTypeahead must be used within a TypeaheadProvider");
  }

  return context;
};

export { TypeaheadProvider, useTypeahead };
