import { useMutation } from "@tanstack/react-query";

import { UrbintApi } from "@/utils/UrbintApi";
import { handleApiResponse } from "@/api/helpers";
import {
  invalidateUseFlexTicketQuery,
  invalidateUseFlexTicketsQuery,
  invalidateUseTicketActivityQuery,
  invalidateUseTasksForTicketQuery,
} from "@/api";

const api = new UrbintApi();

const createTask = (body: ICreateTask) =>
  api.post("tasks", JSON.stringify(body)).then(handleApiResponse);

interface ICreateTask {
  task_type: number;
  ticket: number;
  assignee?: number;
  member_code?: number;
}

const useCreateTask = () =>
  useMutation(createTask, {
    onSuccess: (_, { ticket }) => {
      invalidateUseTasksForTicketQuery(ticket);
      invalidateUseTicketActivityQuery(ticket);
      invalidateUseFlexTicketQuery(ticket);
      invalidateUseFlexTicketsQuery();
    },
  });

export { useCreateTask };
