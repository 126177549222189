import type { TaskStepProps } from "./TasksSteps.types";
import type { PropsWithChildren } from "react";
import type { TaskStatus } from "@/models";
import { useMemo, useState } from "react";

import { TasksStepsContext } from "./TasksSteps.context";

type TaskStepProviderProps = PropsWithChildren<unknown>;

const TaskStepProvider = ({ children }: TaskStepProviderProps) => {
  const [tasksSteps, setTasksSteps] = useState<TaskStepProps[]>([]);

  const updateTaskStep = (taskId: number, taskStep: TaskStatus) => {
    // eslint-disable-next-line no-return-assign
    const newTasksSteps = tasksSteps.map((task) => {
      if (task.taskId === taskId) {
        // eslint-disable-next-line no-param-reassign
        return {
          taskId,
          taskStep,
        };
      }

      return task;
    });
    setTasksSteps(newTasksSteps);
  };

  const getTaskStep = (taskId: number) => {
    if (tasksSteps.find((task) => task.taskId === taskId)) {
      return tasksSteps.find((task) => task.taskId === taskId)?.taskStep;
    }

    return undefined;
  };

  const removeTaskStep = (taskId: number) =>
    tasksSteps.filter((task) => task.taskId !== taskId);

  const value = useMemo(
    () => ({
      tasksSteps,
      updateTaskStep,
      getTaskStep,
      removeTaskStep,
    }),
    [tasksSteps]
  );

  return (
    <TasksStepsContext.Provider value={value}>
      {children}
    </TasksStepsContext.Provider>
  );
};

export { TaskStepProvider };
