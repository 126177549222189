// Direct React component imports
import type { GallerySlideProps } from "./GallerySlide.types";
import { SwiperSlide } from "swiper/react";
import cx from "classnames";

const GallerySlide = ({
  className,
  zoom,
  index,
  children,
}: GallerySlideProps) => (
  <SwiperSlide
    key={index}
    className={cx("h-full !flex justify-center items-center", className)}
    zoom={zoom}
  >
    {children}
  </SwiperSlide>
);

GallerySlide.displayName = "SwiperSlide";

export { GallerySlide };
