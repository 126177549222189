import type { TaskStatusBadgeProps } from "./TaskStatusBadge.types";
import { TaskCardIcon } from "@/pages/TicketPage/TicketPageSideBar/TasksSection/WorkflowGroup/TaskCard/TaskCardIcon";

const TaskStatusBadge = ({ status, className = "" }: TaskStatusBadgeProps) => {
  const { label, bg } = {
    todo: { label: "TO-DO", bg: "bg-gray-20" },
    inprogress: { label: "IN PROGRESS", bg: "bg-yellow-10" },
    done: { label: "DONE", bg: "bg-green-10" },
  }[status];
  return (
    <div
      data-testid={`task-status-${status}`}
      className={`${className} ${bg} flex items-center text-sm leading-tight rounded-full font-semibold shadow-inner-5 py-0.5 px-1.5 ml-2 text-neutral-shade-secondary`}
    >
      <TaskCardIcon status={status} className="mr-1.5 text-xs" />
      {label}
    </div>
  );
};

export { TaskStatusBadge };
