import type { ClusterMarkerPopupProps } from "./ClusterMakerPopup.types";

const parseRisks = (risks: ClusterMarkerPopupProps["risks"]) => ({
  VERY_HIGH: risks?.VERY_HIGH,
  HIGH: risks?.HIGH,
  MEDIUM: risks?.MEDIUM,
  LOW: risks?.LOW,
  NONE: risks?.NONE,
  MISSING_DATA: risks?.MISSING_DATA,
});

const evaluatePadding = (risks: ClusterMarkerPopupProps["risks"]) => {
  const totalCount =
    Object.values(parseRisks(risks)).reduce((a, b) => a + b, 0) ?? 0;
  let padding = "pb-2";
  if (totalCount > 1000) padding = "pb-6";
  else if (totalCount > 500) padding = "pb-4";
  else if (totalCount > 100) padding = "pb-2";

  return padding;
};

export { parseRisks, evaluatePadding };
