import type { TicketQueueSerializer } from "@/utils/damage-prevention";
import { useMutation } from "@tanstack/react-query";

import { TicketQueue } from "@/models";
import { UrbintApi } from "@/utils/UrbintApi";
import { handleApiResponse } from "@/api/helpers";
import { invalidateCurrentTicketQueue } from "@/api";

const api = new UrbintApi();
const addItem = ({
  queue,
  itemIds = [],
}: {
  queue: TicketQueue;
  itemIds: number[];
}) => {
  // we only want to attempt to remove ids that are presently in the queue.
  const itemsToRemove = itemIds.filter((id) => queue.containsItemId(id));
  const body = { item_ids: itemsToRemove };

  // prevent a call to the server when there are no items to remove
  if (itemsToRemove.length === 0) {
    return Promise.resolve(queue);
  }

  return api
    .fetch<TicketQueueSerializer>(`ticket_queues/${queue.id}/remove`, {
      method: "POST",
      body: JSON.stringify(body),
    })
    .then(handleApiResponse)
    .then((res) => new TicketQueue(res));
};

/**
 * Removes one or more items from the user's current ticket queue.
 */
const useRemoveItemsFromTicketQueue = () =>
  useMutation(addItem, { onSuccess: invalidateCurrentTicketQueue });

export { useRemoveItemsFromTicketQueue };
