import type { GeocodeInputFieldErrorProps } from "./GeocodeInputFieldError.types";
import { Icon, CaptionText } from "@urbint/silica";

const GeocodeInputFieldError = ({ error }: GeocodeInputFieldErrorProps) =>
  error ? (
    <div className="flex items-center">
      <Icon className="mr-1 text-sm text-red-600" name="error" />
      <CaptionText color="text-neutral-shade-secondary">
        {error.message}
      </CaptionText>
    </div>
  ) : null;

export { GeocodeInputFieldError };
