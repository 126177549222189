import type { BaseInputProps } from "./BaseInput.types";
import classNames from "classnames";
import { TripleSeg } from "../TripleSeg/TripleSeg";

/**
 * @deprecated to be replaced with Field from Silica
 * Provides the most basic of styling and functionality for any input.
 *
 * Handles borders, sizing, and disabled styles. It also exposes and passes
 * through all `TripleSegProps` to the underlying `TripleSeg` component.
 */
const BaseInput = ({
  children,
  className,
  compact,
  disabled = false,
  endIcon,
  grow,
  omitEdgePadding,
  startIcon,
  tabIndex = -1,
  ...divProps
}: BaseInputProps) => (
  <div
    className={classNames(
      "border min-w-[5rem] relative",
      {
        "h-7": !grow && compact,
        "h-9": !grow && !compact,
        "focus-within:ring-1": !disabled,
        "opacity-40": disabled,
        "overflow-hidden": !grow,
        "rounded-md": !compact,
        rounded: compact,
      },
      className
    )}
    tabIndex={tabIndex}
    {...divProps}
  >
    <TripleSeg
      compact={compact}
      endIcon={endIcon}
      omitEdgePadding={omitEdgePadding}
      startIcon={startIcon}
    >
      {children}
    </TripleSeg>
  </div>
);

export { BaseInput };
