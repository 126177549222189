import type {
  AddToastOptions,
  ToastItem,
  ToastType,
} from "@/components/Toasts/Toasts.types";
import * as React from "react";
import { useContext, useMemo } from "react";
import { ToastsList } from "@/components/Toasts/ToastsList";
import { ToastContext } from "@/components/Toasts/Toasts.context";
import { Portal } from "../Portal";

let idCounter = 0;

const Toasts = React.memo(
  ({ children }: { children?: React.ReactNode | React.ReactNode[] }) => {
    //
    // --- State
    //
    const [toasts, changeToasts] = React.useState<ToastItem[]>([]);
    //
    // --- Logic
    //
    const addToast = (
      toast: string,
      type: ToastType,
      { timeout = 5000, dismissOnPress, className }: AddToastOptions = {}
    ) => {
      // Create an identifiable toast object
      const toastObj: ToastItem = {
        id: `${idCounter}`,
        message: toast,
        dismissOnPress,
        type,
        className,
      };

      // Increment toast counter
      idCounter++;

      // If timeout isn't 0, dismiss the toast after the timeout
      if (timeout > 0) {
        setTimeout(() => dismissToast(toastObj.id), timeout);
      }

      // Update the toasts
      changeToasts((errs) => [...errs, toastObj]);

      return toastObj.id;
    };

    const dismissToast = (id: string) => {
      changeToasts((ts) => ts.filter((e) => e.id !== id));
    };

    //
    // --- Provider Value
    //
    const providerValue = useMemo(
      () => ({
        toasts,
        addToast,
        dismissToast,
      }),
      [toasts]
    );

    //
    // --- Render
    //
    return (
      <ToastContext.Provider value={providerValue}>
        <>
          {children}
          <Portal>
            <ToastsList toasts={toasts} />
          </Portal>
        </>
      </ToastContext.Provider>
    );
  }
);

Toasts.displayName = "Toasts";

const useToasts = () => useContext(ToastContext)!;

export { Toasts, useToasts };
