import type { MeasureToolControlsProps } from "./MeasureToolControls.types";
import type { Unit } from "./MeasureToolControls.constants";
import { useState } from "react";
import cx from "classnames";
import { Transition } from "@headlessui/react";
import { MapControl } from "@/components/Map/Controls/MapControl";
import { useResponsive } from "@/hooks";
import { Units, isValidUnit } from "./MeasureToolControls.constants";

const dispatchCustomEvent = (eventName: string, payload?: object) => {
  const event = new CustomEvent(eventName, { detail: payload });
  window.dispatchEvent(event);
};

const MeasureToolControls = ({
  isInMeasureMode,
  setIsInMesureMode,
  subMenuAlignment = "top",
}: MeasureToolControlsProps) => {
  const [state, setUseState] = useState<boolean>(false);
  const [currentUnit, setCurrentUnit] = useState<Unit>(Units[0]);
  const { isSm } = useResponsive();

  return (
    <div className="relative">
      <MapControl
        className={cx("z-20 relative", {
          "shadow-inner bg-gray-100": isInMeasureMode,
        })}
        onClick={() => {
          setIsInMesureMode(!isInMeasureMode);
        }}
        icon="ruler"
      />
      <Transition
        show={isInMeasureMode}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
        className={cx(
          "absolute pr-1 z-10 right-full",
          subMenuAlignment === "top" ? "top-0" : "bottom-0"
        )}
      >
        <MapControl
          onClick={() => {
            const currentIndex = Units.findIndex(
              (unit) => unit.label === currentUnit.label
            );
            const nextUnit = Units[(currentIndex + 1) % Units.length];
            if (isValidUnit(nextUnit)) {
              setCurrentUnit(nextUnit);
              dispatchCustomEvent("toggleUnit", {
                measureUnit: nextUnit,
              });
            }
          }}
        >
          {currentUnit.abbreviation}
        </MapControl>

        {isSm && (
          <MapControl
            onClick={() => {
              dispatchCustomEvent("cancelMeasure");
              setUseState(!state);
            }}
            icon={state ? "arrowhead_outline" : "close_big"}
          />
        )}
        <MapControl
          onClick={() => {
            dispatchCustomEvent("clearMeasurePath");
          }}
          icon="trash_full"
        />
      </Transition>
    </div>
  );
};
export { MeasureToolControls };
