import { useMutation } from "@tanstack/react-query";

import { UrbintApi } from "@/utils/UrbintApi";
import { handleApiResponse } from "@/api/helpers";
import {
  parseNonTicketedEventDetail,
  invalidateInfiniteNonTicketedEvents,
} from "../queries";

type NewNonTicketedEvent = {
  eventTypeId: number;
  date: string;
  assigneeId?: number;
  location?: string;
};

const api = new UrbintApi();

const addNonTicketedEvent = (opts: NewNonTicketedEvent) =>
  api
    .post(
      "non_ticketed_events",
      JSON.stringify({
        event_type: opts.eventTypeId,
        date: opts.date,
        assignee: opts.assigneeId,
        location: opts.location,
      })
    )
    .then(handleApiResponse)
    .then(parseNonTicketedEventDetail);

const useAddNonTicketedEvent = () =>
  useMutation(addNonTicketedEvent, {
    onSuccess: () => {
      invalidateInfiniteNonTicketedEvents();
    },
  });

// eslint-disable-next-line import/prefer-default-export
export { useAddNonTicketedEvent };
