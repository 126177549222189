// Group by items using the keys and values returned by `keyValFn`. Returning undefined will result in
// the item not being included in the map.
const groupByKV = <T, K, V>(
  coll: Iterable<T>,
  keyValFn: (item: T) => [K, V] | undefined
): Map<K, [V]> => {
  const result: Map<K, [V]> = new Map();
  for (const x of coll) {
    const keyVal = keyValFn(x);
    if (!keyVal) continue;
    const [key, val] = keyVal;
    if (result.has(key)) {
      const target = result.get(key)!;
      target.push(val);
    } else {
      result.set(key, [val]);
    }
  }
  return result;
};

const groupBy = <T, K>(coll: Iterable<T>, keyFn: (item: T) => K): Map<K, [T]> =>
  groupByKV(coll, (x) => [keyFn(x), x]);

// Reduce items of `coll` into a map using `K V` tuples returned by `keyValFn`.
const indexByKV = <T, K, V>(
  coll: Iterable<T>,
  keyValFn: (item: T) => [K, V]
): Map<K, V> => {
  const result = new Map();
  for (const x of coll) {
    const [key, val] = keyValFn(x);
    result.set(key, val);
  }
  return result;
};

// Reduce items of `coll` into a map using a key returned from `keyFn`
const indexBy = <V, K>(coll: Iterable<V>, keyFn: (item: V) => K): Map<K, V> =>
  indexByKV(coll, (x) => [keyFn(x), x]);

const distinctBy = <T, V>(
  coll: Iterable<T>,
  distinctFn: (value: T) => V
): T[] => {
  const result: T[] = [];
  const seen: Set<V> = new Set();
  for (const x of coll) {
    const k = distinctFn(x);
    if (!seen.has(k)) {
      seen.add(k);
      result.push(x);
    }
  }
  return result;
};

const hasKey = <T, K extends keyof T>(
  obj: Partial<T>,
  key: K
): obj is Partial<T> & { [k in K]: T[k] } =>
  Object.prototype.hasOwnProperty.call(obj, key);

const getKeys = <T extends object>(v: T) => Object.keys(v) as (keyof T)[];

export { groupByKV, groupBy, indexByKV, indexBy, distinctBy, hasKey, getKeys };
