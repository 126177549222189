import type { DistancePopupProps } from "./DistancePopup.types";
import { CaptionText } from "@urbint/silica";
import { Popup } from "react-map-gl";

const DistancePopup = ({
  coordinates,
  className,
  distance,
  unit,
}: DistancePopupProps) => {
  const [longitude, latitude] = coordinates;

  if (!longitude || !latitude) {
    return null;
  }
  return (
    <Popup
      longitude={longitude}
      latitude={latitude}
      closeButton={false}
      closeOnClick={false}
      anchor="top"
      className={`${className} distance-popup`}
    >
      <CaptionText>
        {distance.toFixed(2)}&nbsp;{unit}
      </CaptionText>
    </Popup>
  );
};

export { DistancePopup };
