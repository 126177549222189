import type { DialogBodyProps } from "./DialogBody.types";
import classnames from "classnames";

const DialogBody = ({ theme, children }: DialogBodyProps) => (
  <section
    className={classnames(
      "relative flex-grow px-6 pb-4 overflow-y-auto",
      theme?.content,
      {
        "pb-6": !theme?.contentFullWidth,
      }
    )}
  >
    {children}
  </section>
);
export { DialogBody };
