import type { FleetLocationRQSerializer } from "@/utils/damage-prevention";
import { useMutation } from "@tanstack/react-query";

import { UrbintApi } from "@/utils/UrbintApi";
import { handleApiResponse } from "@/api/helpers";
import { FETCH_USER_LOCATION_TIME_INTERVAL } from "../fleetLocation.constants";

const api = new UrbintApi();

let lastCheckIn = new Date();
lastCheckIn.setDate(lastCheckIn.getDate() - 1);

const reportLocation = ({ lat, lng }: { lat: number; lng: number }) => {
  const payload = { lat, lon: lng, timestamp: new Date() };
  const delta = new Date().getTime() - lastCheckIn.getTime();

  // Early return if our last check in was less than 5min ago
  if (delta < FETCH_USER_LOCATION_TIME_INTERVAL) {
    return Promise.resolve({
      ...payload,
      timestamp: new Date().toISOString(),
    } as FleetLocationRQSerializer);
  }

  // update the lastCheckIn time to now
  lastCheckIn = payload.timestamp;

  return api
    .post<FleetLocationRQSerializer>("fleet_location", JSON.stringify(payload))
    .then(handleApiResponse);
};

/**
 * Report the users current location to the server for fleet tracking
 */
function useReportFleetLocation() {
  return useMutation(reportLocation);
}

// eslint-disable-next-line import/prefer-default-export
export { useReportFleetLocation };
