import type { DamagePreventionUserProfile } from "@/utils/types";
import type { Opco } from "@/models";
import { identify } from "@fullstory/browser";

const identifyUserSession = (
  currentUser: DamagePreventionUserProfile,
  tenantInfo: Opco
) => {
  const { email, firstName, lastName, djangoId: userId } = currentUser;
  const { name: tenantName } = tenantInfo;
  identify(userId.toString(), {
    displayName: `${firstName} ${lastName}`,
    email,
    tenant: tenantName,
  });
};

export { identifyUserSession };
