import type { TicketSerializer } from "@/utils/damage-prevention";
import { UrbintModel } from "@/utils/UrbintModel";
import { MemberCode } from "./MemberCode";
import { PositiveResponse } from "./PositiveResponse";
import { TicketVersion } from "./TicketVersion";
import { ServiceCenter } from "./ServiceCenter";

class Ticket extends UrbintModel<TicketSerializer> {
  get id() {
    return this.castNumber(this.data.id);
  }

  get number() {
    return this.castString(this.data.number);
  }

  get createTime() {
    return this.castDate(this.data.create_time);
  }

  get serviceCenterId() {
    return this.castNumber(this.data.service_center);
  }

  get serviceCenters() {
    if (!this.data.service_centers) {
      return [];
    }

    return this.data.service_centers.map(
      (serviceCenter) => new ServiceCenter(serviceCenter)
    );
  }

  get oneCallCenterId() {
    return this.castNumber(this.data.eight11_center);
  }

  get stage() {
    return this.data.stage;
  }

  get latestTicketComment() {
    return this.castString(this.data.latest_ticket_comment);
  }

  get openTaskTypes(): string[] {
    return this.data.open_task_types;
  }

  get centroid(): { lng: number; lat: number } | undefined {
    if (this.data.centroid === null) {
      return undefined;
    }
    const [lon, lat] = this.castArray(this.data.centroid);
    return { lng: this.castNumber(lon), lat: this.castNumber(lat) };
  }

  get workArea(): [number, number][][] {
    return this.data.work_area;
  }

  get versionCount() {
    return this.castNumber(this.data.version_count);
  }

  get latestVersion() {
    return this.data.latest_version
      ? new TicketVersion(this.data.latest_version)
      : undefined;
  }

  get isEmergency() {
    return this.castBool(this.data.is_emergency);
  }

  get countResponseFormAnswers() {
    return this.castNumber(this.data.count_response_form_answers);
  }

  get expiresOn() {
    return this.data.expires_on ? this.castDate(this.data.expires_on) : null;
  }

  get positiveResponses() {
    if (!this.data.positive_responses) {
      return [];
    }

    return this.data.positive_responses.map(
      (positiveResponse) => new PositiveResponse(positiveResponse)
    );
  }

  get memberCodes() {
    if (!this.data.member_codes) {
      return [];
    }

    return this.data.member_codes.map(
      (memberCode) => new MemberCode(memberCode)
    );
  }

  // Is this used?
  compare(instance: Ticket): number {
    if (
      !this.latestVersion?.workStartDate ||
      !instance.latestVersion?.workStartDate
    )
      return 0;

    return (
      this.latestVersion?.workStartDate.getTime() -
      instance.latestVersion?.workStartDate.getTime()
    );
  }

  serialize(): TicketSerializer {
    return this.data;
  }
}

export { Ticket };
