import type { GeocodeInputEndAdornmentProps } from "./GeocodeInputEndAdornment.types";
import { LoadingIndicator } from "@/common/LoadingIndicator";
import { GeocodeInputAdornmentButton } from "./GeocodeInputAdornmentButton";

const GeocodeInputEndAdornment = ({
  busy,
  disabled,
  mode,
  onClickUseCurrentLocation,
  onClear,
}: GeocodeInputEndAdornmentProps) => {
  if (disabled) return null;
  const showLoading = busy;
  const showClear = !showLoading && mode === "clear";
  const showMyLocation = !showLoading && mode === "myLocation";

  return (
    <div className="flex h-8 -mr-2.5 items-center">
      {showLoading ? <LoadingIndicator lineWeight="thin" color="dark" /> : null}
      <GeocodeInputAdornmentButton
        show={showMyLocation}
        onClick={onClickUseCurrentLocation}
        iconName="target"
      />
      <GeocodeInputAdornmentButton
        show={showClear}
        onClick={onClear}
        iconName="close_big"
      />
    </div>
  );
};

export { GeocodeInputEndAdornment };
