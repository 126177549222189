import type { TaskResponseFormMultiPRQuestionProps } from "./TaskResponseFormMultiPRQuestion.types";
import { TaskResponseFormQuestionHeader } from "../../TaskResponseFormQuestionHeader";

const TaskResponseFormMultiPRQuestion = ({
  value,
  testId,
}: TaskResponseFormMultiPRQuestionProps) => {
  const hasAnswer = value && value.length > 0;
  return (
    <>
      {value?.map(({ memberCode: label, prCode: answer }) => (
        <div key={`${label}-${answer}`}>
          <TaskResponseFormQuestionHeader>
            {label}
          </TaskResponseFormQuestionHeader>
          <p
            data-testid={testId}
            className={`leading-tight mb-4 ${
              hasAnswer
                ? "text-neutral-shade-primary"
                : "text-neutral-shade-tertiary"
            }`}
          >
            {answer || "–"}
          </p>
        </div>
      ))}
    </>
  );
};

export { TaskResponseFormMultiPRQuestion };
