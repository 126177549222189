import type { UseQueryOptions } from "@tanstack/react-query";
import type { ResponseFormSerializer } from "@/utils/damage-prevention";
import { useQuery } from "@tanstack/react-query";
import { ResponseForm } from "@/models";
import { UrbintApi } from "@/utils/UrbintApi";
import { queryClient } from "@/api/client";
import { handleApiResponse } from "@/api/helpers";
import { responseFormsIdent } from "@/api";

const api = new UrbintApi();

const responseFormIdent = (responseFormId: number) => [
  "response_forms",
  responseFormId,
];

const fetchResponseForm = (responseFormId: number) =>
  api
    .getOne<ResponseFormSerializer>({
      endPoint: "response_forms",
      id: responseFormId,
    })
    .then(handleApiResponse)
    .then((r) => new ResponseForm(r));

const useResponseForm = (
  responseFormId: number,
  opts?: UseQueryOptions<ResponseForm>
) =>
  useQuery<ResponseForm>(
    responseFormIdent(responseFormId),
    () => fetchResponseForm(responseFormId),
    {
      ...opts,
      initialData: () =>
        queryClient
          .getQueryData<ResponseForm[]>(responseFormsIdent())
          ?.find((t) => t.id === responseFormId),
      initialDataUpdatedAt: () =>
        queryClient.getQueryState(responseFormsIdent())?.dataUpdatedAt,
    }
  );

const prefetchUseResponseForm = (responseFormId: number) =>
  queryClient.prefetchQuery(responseFormIdent(responseFormId), () =>
    fetchResponseForm(responseFormId)
  );

export { useResponseForm, prefetchUseResponseForm };
