import type { EditingHeaderProps } from "./EditingHeader.types";
import type { FilterDialogState } from "../../FilterDialog.types";
import { useCallback } from "react";
import { Transition } from "@headlessui/react";
import { Icon } from "@urbint/silica";
import { useTicketViewEditor } from "@/components/TicketViewEditorProvider";
import { useDamagePreventionAuth } from "@/hooks";
import { Switch } from "@/components/Switch";
import { Button } from "@/common/Button";
import { onBlur } from "./EditingHeader.utils";

const EditingHeader = ({
  setFilterDialogState,
  error,
  viewName,
  editMode,
  warning,
}: EditingHeaderProps) => {
  const { isShared, setName, resetView, setIsShared, resetName } =
    useTicketViewEditor();

  const {
    currentUser: { canShareSavedView },
  } = useDamagePreventionAuth();

  const handleCancelEdit = useCallback(() => {
    if (editMode === "create") {
      resetView();
    } else if (editMode === "edit") {
      resetName();
    }
    setFilterDialogState((state: FilterDialogState) => ({
      ...state,
      isNameTouched: false,
      editMode: undefined,
    }));
  }, [editMode, resetName, setFilterDialogState, resetView]);

  return (
    <div className="w-full">
      <div className="flex items-center">
        <input
          data-testid="filter-modal-view-name-input"
          name="view_name"
          onBlur={() => onBlur(setFilterDialogState)}
          className={`rounded border px-3 text-lg font-semibold text-black text-opacity-88 h-10 border-box flex items-center flex-grow
      -my-2 -mx-2 focus:border-blue-600 focus:outline-none focus:shadow-sm
      ${error && "border-red-600"}`}
          autoComplete="off"
          placeholder="View name"
          value={viewName}
          onChange={(e) => setName(e.target.value)}
        />
        <Button
          type="button"
          className="ml-6 text-lg text-black text-opacity-66 font-semibold"
          onClick={handleCancelEdit}
        >
          Cancel
        </Button>
      </div>
      {(error || warning) && (
        <div className="flex items-center mt-2 -mb-2 -ml-2">
          <Icon
            name={error ? "off_close" : "error"}
            className={`${error ? "text-red-600" : "text-yellow-500"} mr-2`}
          />
          <span className="text-sm text-black text-opacity-66">
            {error || warning}
          </span>
        </div>
      )}
      <Transition
        show={canShareSavedView}
        appear
        className="transition duration-200 origin-top transform-gpu"
        enterFrom="scale-y-0"
        enterTo="scale-y-100"
        leaveFrom="scale-y-100"
        leaveTo="scale-y-0"
      >
        <Switch
          className="absolute items-start mt-4 overflow-hidden sm:items-center"
          dataTestId="filter-modal-share-button"
          label="Share view with everyone in your operating company."
          labelClassName="text-black text-opacity-88"
          checked={isShared}
          onChange={setIsShared}
        />
      </Transition>
    </div>
  );
};

export { EditingHeader };
