import type { TopSummaryCardProps } from "./TopSummaryCard.types";
import { BodyText } from "@urbint/silica";
import { useParams } from "react-router";
import { Button } from "@/common/Button";
import { startCase } from "@/format";
import { useTicket } from "@/api";
import { getTicketLocation } from "../SummaryCard.utils";

const TopSummaryCard = ({
  isSectionVisible,
  toggleSectionVisibility,
}: TopSummaryCardProps) => {
  const { ticketId } = useParams<{ ticketId: string }>();
  const { data: ticket } = useTicket(+ticketId, undefined, true);
  const { address, cityState } = getTicketLocation(ticket?.latestVersion);
  return (
    <header className="pl-4 py-2 bg-white border-b border-black md:pl-8 lg:pl-4 lg:pb-2 border-opacity-6">
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-semibold text-black text-opacity-88">
          {startCase(address)}
          <BodyText className="text-black text-opacity-66">
            {cityState}
          </BodyText>
        </h2>
        <Button
          iconEnd={
            isSectionVisible ? "circle_chevron_up" : "circle_chevron_down"
          }
          className="text-xl mt-1"
          onClick={toggleSectionVisibility}
        />
      </div>
    </header>
  );
};

export { TopSummaryCard };
