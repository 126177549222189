import type { SidebarContentMobileHeaderProps } from "./SidebarContentMobileHeader.types";
import { IonIcon } from "@ionic/react";

const SidebarContentMobileHeader = ({
  title,
  buttonText,
  buttonIcon,
  onButtonClick,
  showButton,
}: SidebarContentMobileHeaderProps) => (
  <div className="md:hidden flex items-center mt-4 mb-6">
    <h3 className="text-black font-semibold text-lg">{title}</h3>
    {showButton && (
      <button
        type="button"
        className="ml-auto flex text-blue-600 items-center text-lg font-semibold"
        onClick={onButtonClick}
      >
        <IonIcon className="text-xl mr-2" icon={buttonIcon} />
        {buttonText}
      </button>
    )}
  </div>
);

export { SidebarContentMobileHeader };
