import type { UseMutationOptions } from "@tanstack/react-query";
import type {
  AutoAssignmentMutationSerializer,
  AutomationEngineRuleSerializer,
} from "@/utils/damage-prevention";
import { useMutation } from "@tanstack/react-query";
import { autoAssignmentIdent } from "@/api";
import { AutoAssignmentRule } from "@/models";
import { queryClient } from "@/api/client";
import { request, useDefaultFetchHeaders } from "@/api/helpers";

type UpdateAutoAssignmentResponse = AutoAssignmentMutationSerializer & {
  id: number;
  // For now.... sigh.
  assignee_username?: string;
};

const changeAutoAssignment = (
  { id, ...body }: UpdateAutoAssignmentResponse,
  headers: HeadersInit
) =>
  request<AutomationEngineRuleSerializer>(
    `/api/rules/${id}/update_assignment`,
    {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    }
  );

const useUpdateAutoAssignment = (
  opts: UseMutationOptions<
    AutomationEngineRuleSerializer,
    unknown,
    UpdateAutoAssignmentResponse,
    unknown
  > = {}
) => {
  const headers = useDefaultFetchHeaders();

  return useMutation<
    AutomationEngineRuleSerializer,
    unknown,
    UpdateAutoAssignmentResponse
  >((payload) => changeAutoAssignment(payload, headers), {
    ...opts,
    onSuccess: (data, vars, context) => {
      if (opts.onSuccess) opts.onSuccess(data, vars, context);
    },
    onMutate: async (payload) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(autoAssignmentIdent());

      // Snapshot the previous value

      const previousRules = queryClient.getQueryData<AutoAssignmentRule[]>(
        autoAssignmentIdent()
      );

      // Optimistically update to the new value
      const oldRule = previousRules?.find((r) => r.id === payload.id);

      if (!oldRule) return;

      const newRule = new AutoAssignmentRule({
        ...oldRule.serialize(),
        notify_sms: payload.sms_enabled,
        notify_email: payload.email_enabled,
        assignee_username: payload.assignee_id
          ? payload.assignee_username
          : null,
      });

      queryClient.setQueryData<AutoAssignmentRule[]>(
        autoAssignmentIdent(),
        (old) => [...(old?.filter((o) => o.id !== payload.id) ?? []), newRule]
      );

      // Return a context object with the snapshotted value

      return { previousRules };
    },
    onError: (_1, _2, context: any) => {
      queryClient.setQueryData(autoAssignmentIdent(), context.prevousRules);
    },
    onSettled: () => {
      queryClient.invalidateQueries(autoAssignmentIdent());
    },
  });
};

// eslint-disable-next-line import/prefer-default-export
export { useUpdateAutoAssignment };
