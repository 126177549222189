import type { CommentSidebarActionProps } from "./CommentSidebarAction.types";
import { useCallback, useState } from "react";
import { useAddCommentToTicket } from "@/api";
import { useDamagePreventionAuth } from "@/hooks";
import { Avatar } from "@/common/Avatar";
import {
  SidebarActionButton,
  SidebarActionContainer,
} from "@/pages/TicketPage/TicketPageSideBar/Sidebar";

const CommentSidebarAction = ({ ticket }: CommentSidebarActionProps) => {
  const { currentUser } = useDamagePreventionAuth();
  const [text, setText] = useState("");
  const hasComment = text !== "";
  const addCommentToTicket = useAddCommentToTicket();
  const createComment = useCallback(
    async (comment: string) => {
      await addCommentToTicket.mutateAsync({
        ticket: ticket!.id,
        comment,
        creator: String(currentUser.djangoId),
      });
      setText("");
    },
    [currentUser, ticket, setText, addCommentToTicket]
  );
  return (
    <SidebarActionContainer
      className={`${hasComment ? "" : "h-16 overflow-hidden"}`}
    >
      <div className="flex items-start space-x-3 mb-3">
        <span className="mt-2">
          <Avatar user={currentUser} />
        </span>
        <textarea
          value={text}
          data-testid="activity-comment-input"
          placeholder="Add comment..."
          onChange={(e) => setText(e.target.value)}
          rows={hasComment ? 2 : 1}
          className={`${
            hasComment ? "" : "h-10"
          } text-black text-opacity-66 border rounded flex-grow resize-none px-3 py-2.5 border-box leading-tight placeholder-black placeholder-opacity-33 focus:outline-none focus:border-blue-600`}
        />
      </div>
      <SidebarActionButton
        onClick={() => createComment(text)}
        dataTestId="sidebar-activity-action-button"
      >
        Add Comment
      </SidebarActionButton>
    </SidebarActionContainer>
  );
};

export { CommentSidebarAction };
