import type { GeoJSONSource } from "mapbox-gl";
import type { TicketCentroids } from "@/api";

import type { ClusterLayerProps } from "./ClusterLayer.types";
import { Layer, Source, useMap } from "react-map-gl";
import React, { useEffect, useMemo } from "react";
import { Transition } from "@headlessui/react";
import { useTicketPoints } from "@/api";
import { useTicketSelector } from "@/components/TicketSelectorProvider";
import { useTicketFocusContext } from "@/pages/MapPage";
import { useResponsive } from "@/hooks";
import { TicketQueueLayer } from "../TicketQueueLayer";
import {
  clusterLayer,
  RISK_MAPPER,
  source,
  ticketLayer,
  TICKETS_SOURCE,
  ticketSelectionLayer,
} from "./ClusterLayer.constants";
import { Clusters } from "./Clusters";
import { flyAndCenterClusters } from "./ClusterLayer.utils";

//
// --- Non render triggering variables
// The intention with these variables is to track our markers
// without forcing a react re-render every time we mutate them.
// We want mapbox optimized rendering to do the work for us
let lastHovered: number | null = null;
const ticketCentroids: TicketCentroids = {
  type: "FeatureCollection",
  features: [],
};

const ClusterLayer = React.memo(({ scoreType, view }: ClusterLayerProps) => {
  const { current: map } = useMap();
  const { isSm } = useResponsive();

  const { selectedTickets, selectedTasks } = useTicketSelector();
  const { focusedTicket } = useTicketFocusContext();
  const { data } = useTicketPoints(view);

  if (!map) return null;

  const ticketPoints: TicketCentroids = useMemo(() => {
    if (!data) return ticketCentroids;

    const features = data.features.map((feature) => {
      let risk = "UNKNOWN";
      switch (scoreType) {
        case "damage":
          risk = feature.properties.d!;
          break;
        case "impact":
          risk = feature.properties.i!;
          break;
        case "due_date":
          risk = RISK_MAPPER[feature.properties.t!] ?? "UNKNOWN";
          break;
        // no default
      }

      return { ...feature, properties: { risk: risk! } };
    });

    // Update our permanent map reference with the new features
    ticketCentroids.features = features;

    // We're passing a new reference to make sure new data
    // will retrigger a map render
    return { type: "FeatureCollection", features };

    // Every change to this comes with a pretty significant performance
    // hit at scale. So try to avoid it for values that frequently change.
    // And debounce any value that might change rapidly
  }, [data, scoreType]);

  useEffect(() => {
    if (data && map) {
      const coords = ticketPoints.features.map((t) => t.geometry.coordinates);
      flyAndCenterClusters(coords, isSm, map);
    }
  }, [data]);

  // This is to make sure we pass the same feature collection
  // reference to the map every time we update these values
  useEffect(() => {
    const getTicketsource = map?.getSource(TICKETS_SOURCE) as GeoJSONSource;
    if (getTicketsource) {
      const selectedTicketIdsFromTasks = new Set(
        Object.keys(selectedTasks).map(Number)
      );

      const selectedTicketIds = new Set([
        ...selectedTickets,
        ...Object.keys(selectedTasks).map(parseInt),
      ]);

      ticketCentroids.features.forEach((feature) => {
        if (!feature || !feature.properties) return;
        const isSelected =
          selectedTicketIds.has(feature.id as number) ||
          selectedTicketIdsFromTasks.has(feature.id as number);

        feature.properties.selected = isSelected;
      });
      getTicketsource.setData(ticketCentroids);
    }
  }, [selectedTasks, selectedTickets, ticketPoints]);

  // Update hover effects by setting feature state of the map markers
  // This ensures we only update a single/two markers at a time
  useEffect(() => {
    if (map.isStyleLoaded() && lastHovered) {
      map.setFeatureState(
        { id: lastHovered, source: TICKETS_SOURCE },
        { hovered: false }
      );
    }
    if (map.isStyleLoaded() && focusedTicket) {
      map.setFeatureState(
        { id: focusedTicket, source: TICKETS_SOURCE },
        { hovered: true }
      );
      lastHovered = focusedTicket;
    }
  }, [focusedTicket]);

  return (
    <>
      <Source
        {...source}
        id={source.id}
        type={source.type as "geojson"}
        data={ticketPoints}
      >
        {data && (
          <>
            <Clusters />
            <Layer {...clusterLayer} />
            <Layer {...ticketLayer} />
            <Layer {...ticketSelectionLayer} />
            <TicketQueueLayer />
          </>
        )}
      </Source>
      <Transition
        appear
        show={!data}
        className="w-full h-full"
        enter="duration-400 overflow-hidden"
        enterFrom="opacity-25"
        enterTo="opacity-100"
        leave="duration-200 overflow-hidden"
        leaveFrom="opacity-100 py-5"
        leaveTo="opacity-0 py-0"
      >
        <div className="relative flex items-center justify-center w-full h-full">
          <div className="absolute w-full h-full bg-black bg-opacity-30" />
          <div className="z-[5] font-sans text-lg text-white">
            Loading ticket points...
          </div>
        </div>
      </Transition>
    </>
  );
});

export { ClusterLayer };
