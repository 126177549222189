import type { FieldMemberPopupProps } from ".";

/**
 *  Initial `Popup` props
 */
const INIT_FIELD_MEMBER_POPUP_PROPS: FieldMemberPopupProps = {
  userId: 0,
  firstName: "",
  lastName: "",
  phoneNumber: "",
  latitude: 0,
  longitude: 0,
  lastUpdate: new Date(),
  ticketCount: 0,
};

export { INIT_FIELD_MEMBER_POPUP_PROPS };
