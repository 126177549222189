import type { MouseEvent } from "react";
import type { TicketCardProps } from "./TicketCard.types";
import classNames from "classnames";
import { memo, useCallback } from "react";
import { Link } from "react-router-dom";
import noop from "lodash/noop";
import isEqual from "lodash/isEqual";
import { useLinkClick } from "@/hooks";
import { Checkbox } from "@/common/Checkbox";
import { TicketContent } from "./TicketContent";
import {
  TICKET_CARD_FIELDS_WITH_TASKS,
  TICKET_CARD_FIELDS,
} from "./TicketCard.constants";
import { TicketCardTaskList } from "./TicketCardTaskList";

function ticketCardPropsAreEqual(prev: TicketCardProps, next: TicketCardProps) {
  if (prev === next) return true;
  if (prev.showTasks !== next.showTasks) return false;
  if (prev.ticket.id !== next.ticket.id) return false;
  if (prev.selected !== next.selected) return false;
  if (prev.selectMode !== next.selectMode) return false;
  if (!isEqual(prev.selectedTasks, next.selectedTasks)) return false;

  return isEqual(prev.ticket, next.ticket);
}

const flexFieldsForTicketCard = (showTasks: boolean) =>
  showTasks ? TICKET_CARD_FIELDS_WITH_TASKS : TICKET_CARD_FIELDS;

const TicketCard = memo(
  ({
    ticket,
    style = {},
    className,
    containerClassName,
    showTasks = false,
    selectMode,
    selected,
    onSelect,
    onTaskSelected,
    selectedTasks,
  }: TicketCardProps) => {
    const url = `/ticket/${ticket.id}`;
    const linkClick = useLinkClick(url);

    const handleClick = useCallback(
      (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (selectMode && selectMode === "tickets") {
          return onSelect && onSelect(ticket);
        }

        if (!selectMode) {
          return linkClick.handleClick(e);
        }
      },
      [linkClick, onSelect, selectMode, ticket]
    );

    const isSelectingTickets = selectMode === "tickets";
    const isSelectingTasks = selectMode === "tasks";

    return (
      <button
        style={style}
        className={classNames(
          "block text-left p-0 m-0 w-full select-text",
          className,
          {
            "cursor-pointer": !selectMode || isSelectingTickets,
          }
        )}
        onClick={handleClick}
      >
        <div className="flex flex-row w-full">
          {isSelectingTickets && (
            <div className="pt-3 pl-3">
              <Checkbox
                checked={selected}
                onChange={noop}
                dataTestId="ticket-card-checkbox"
              />
            </div>
          )}
          <div
            className={classNames(
              "p-3 flex flex-col text-sm space-y-1 font-sans",
              isSelectingTickets ? "w-11/12" : "w-full",
              containerClassName
            )}
          >
            <TicketContent ticket={ticket} />
            {showTasks && (
              <div className="pt-3">
                <TicketCardTaskList
                  ticketId={ticket.id!}
                  disabled={isSelectingTickets}
                  tasks={ticket.tasks}
                  selectedTasks={selectedTasks}
                  onTaskSelected={onTaskSelected}
                  isSelectable={isSelectingTasks}
                />
              </div>
            )}
          </div>
        </div>
      </button>
    );
  },
  ticketCardPropsAreEqual
);

TicketCard.displayName = "TicketCard";

export { flexFieldsForTicketCard, TicketCard };
