import type { ImpactPotentialSerializer } from "@/utils/damage-prevention";
import { UrbintModel } from "@/utils/UrbintModel";
import { Insight } from "./Insight";

class ImpactPotential extends UrbintModel<ImpactPotentialSerializer> {
  get id() {
    return this.castNumber(this.data.id);
  }

  get insightData() {
    return this.castString(this.data.insight_data);
  }

  get insight() {
    return this.data.insight ? new Insight(this.data.insight) : undefined;
  }

  serialize(): ImpactPotentialSerializer {
    return this.data;
  }

  compare(): number {
    throw new Error("Method not implemented.");
  }
}

export { ImpactPotential };
