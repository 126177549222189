import type { AttachmentActivityProps } from "./AttachmentActivity.types";
import { useTaskAttachments } from "@/api";
import { Thumbnail } from "@/common/Thumbnail";
import { FileType } from "@/hooks";

const AttachmentActivity = ({
  activity,
  onPhotoClick,
  activityName,
}: AttachmentActivityProps) => {
  const photoId = Number(activity.objectId);

  const { data, isInitialLoading: isPhotoLoading } = useTaskAttachments(
    activity.taskId!,
    photoId,
    {
      retry: false,
      enabled:
        !!activity.taskId &&
        !Number.isNaN(photoId) &&
        !activity.attachmentDeleted,
    }
  );

  const photoInfo = data?.[0]?.serialize();

  return (
    <>
      Submitted an attachment for task {activityName}
      {!isPhotoLoading && !photoInfo ? (
        " but it was later deleted"
      ) : (
        <Thumbnail
          allowDelete={false}
          type={photoInfo?.type}
          url={photoInfo?.thumbnail}
          alt="Ticket Activity Attachment"
          onClick={() => {
            if (photoInfo?.type === FileType.PDF && window) {
              window.open(photoInfo.file, "_blank");
            } else if (onPhotoClick) {
              onPhotoClick(photoInfo?.id || 0);
            }
          }}
        />
      )}
    </>
  );
};
export { AttachmentActivity };
