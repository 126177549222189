import type { UseQueryOptions } from "@tanstack/react-query";
import type { ResponseOption } from "@/models";
import { useQuery } from "@tanstack/react-query";
import { UrbintApi } from "@/utils/UrbintApi";
import { queryClient } from "@/api/client";
import { handleApiResponse } from "@/api/helpers";

const api = new UrbintApi();

interface IDynamicOptions {
  taskId: number;
  responseFormId: number;
}

type DynamicOptionsReturn = Record<number, Array<ResponseOption>>;

const dynamicOptionsIdent = ({ responseFormId, taskId }: IDynamicOptions) => [
  "response_form_dynamic_options",
  responseFormId,
  taskId,
];

const fetchDynamicOptions = ({ responseFormId, taskId }: IDynamicOptions) =>
  api
    .getMany({
      endPoint: "response_forms",
      action: "dynamic_options",
      id: responseFormId,
      queryParams: new URLSearchParams({
        task_id: String(taskId),
      }),
    })
    .then(handleApiResponse)
    .then((res) => res as DynamicOptionsReturn);

const useDynamicOptions = (
  args: IDynamicOptions,
  opts?: UseQueryOptions<DynamicOptionsReturn>
) =>
  useQuery<DynamicOptionsReturn>(
    dynamicOptionsIdent(args),
    () => fetchDynamicOptions(args),
    opts
  );

const prefetchDynamicOptions = (args: IDynamicOptions) =>
  queryClient.prefetchQuery(dynamicOptionsIdent(args), () =>
    fetchDynamicOptions(args)
  );

const invalidateUseDynamicOptionsQuery = (
  responseFormId: number,
  taskId: number
) =>
  queryClient.invalidateQueries(
    dynamicOptionsIdent({ responseFormId, taskId })
  );

export type { DynamicOptionsReturn };

export {
  invalidateUseDynamicOptionsQuery,
  useDynamicOptions,
  prefetchDynamicOptions,
};
