import type { SortingRule } from "react-table";
import type { TicketField } from "@/hooks/useFlexFields";
import type { TicketViewOrder } from "@/components/TicketViewEditorProvider";
import { type ColumnData } from "@/components/TicketTable";
import { usePublicConfig } from "@/api";
import { type SavedViewField, SortOrderDirection } from "@/models";
import { indexBy } from "@/utils";
import { useDamagePreventionAuth } from "../useDamagePreventionAuth";
import {
  getAllowedColumns,
  prepareColumnDefinitions,
} from "./useTicketColumns.utils";

const useTicketColumns = () => {
  const { data: publicConfig } = usePublicConfig();
  const { currentUser } = useDamagePreventionAuth();

  const columnsDefinitionsById = indexBy(
    prepareColumnDefinitions(getAllowedColumns(publicConfig, currentUser)),
    (x) => x.id
  );

  const columnsDefinitions = prepareColumnDefinitions(
    getAllowedColumns(publicConfig, currentUser)
  );

  const ticketOrderToSortBy = (
    order?: TicketViewOrder
  ): SortingRule<ColumnData>[] | [] => {
    if (!order) return [];

    const columns = columnsDefinitions.filter((col) =>
      col.sortFields?.includes(order.field)
    );

    return columns.map((c) => ({
      id: c.id,
      desc: order.direction === SortOrderDirection.DESC,
    }));
  };

  const flexFieldsForTableColumns = (
    columns: SavedViewField[],
    isShowingTasks: boolean
  ): TicketField[] => {
    const result: TicketField[] = [];
    for (const col of columns) {
      const def = columnsDefinitionsById.get(col.field);
      const fields = def?.fields || [];
      for (const field of fields) {
        result.push(field);
      }
    }
    // Add the task related fields when we enable the task column
    if (isShowingTasks) {
      const def = columnsDefinitionsById.get("tasks");
      const fields = def?.fields || [];
      for (const field of fields) {
        if (!result.includes(field)) {
          result.push(field);
        }
      }
    }

    return result;
  };

  return {
    ticketOrderToSortBy,
    flexFieldsForTableColumns,
    COLUMN_DEFINITIONS: columnsDefinitions,
    COLUMN_DEFINITIONS_BY_ID: columnsDefinitionsById,
  };
};

export { useTicketColumns, prepareColumnDefinitions };
