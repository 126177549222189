import type { Question } from "@/models";
import type { SelectOption } from "@/components/Select";
import { answerValidator } from "@/models";

type FormValues = { [key: number]: string | string[] };
type QuestionValue = string | string[] | undefined;

const isFormValid = (questions: Question[], values: FormValues): boolean => {
  const requiredQuestions = questions.filter((q) => q.required);
  return requiredQuestions.every((rq) =>
    answerValidator(rq.answerType, values[rq.id])
  );
};

const sameLabelAndValueOptions = (label: string): SelectOption<string> => ({
  label,
  value: label,
});

export type { FormValues, QuestionValue };
export { isFormValid, sameLabelAndValueOptions };
