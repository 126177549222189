import type { Task, TaskAttachments } from "@/models";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import {
  invalidateUseTicketActivityQuery,
  invalidateUseTaskAttachmentsQuery,
  taskAttachmentsIdent,
} from "@/api";
import { queryClient } from "@/api/client";
import { uploadAttachment } from "./uploadAttachment.utils";

interface IUseUploadAttachment {
  attachment: File[];
  task: Task;
}

const uploadTaskAttachmentIdent = () => ["upload_task_attachments"];

const getDefaultAttachment = (attachment: File, task: Task) =>
  ({
    type: "image",
    createdAt: new Date(),
    taskId: task.id,
    file: URL.createObjectURL(attachment as Blob),
    thumbnailUrl: URL.createObjectURL(attachment as Blob),
    creatorId: -1,
    offline: true,
  } as TaskAttachments);

const useUploadTaskAttachment = () => {
  const [progress, setProgress] = useState<number>(-1);

  const request = new XMLHttpRequest();

  const res = useMutation(
    uploadTaskAttachmentIdent(),
    (opts: IUseUploadAttachment) =>
      uploadAttachment(
        {
          attachments: opts.attachment,
          endpoint: "tasks",
          modelId: opts.task.id,
          request,
        },
        setProgress
      ),
    {
      // When mutate is called:
      onMutate: ({ attachment, task }) => {
        // Optimistically update to the new value
        const newAttachments: TaskAttachments[] = [];

        attachment.forEach((newAttachment) =>
          newAttachments.push(getDefaultAttachment(newAttachment, task))
        );

        queryClient.setQueryData(
          taskAttachmentsIdent(task.id),
          (currentAttachments: any) => {
            const oldAttachments = currentAttachments || [];
            return [...oldAttachments, ...newAttachments];
          }
        );

        // Return a context object with the snapshotted value
        return attachment;
      },
      onSuccess: (_, { task }) => {
        // TODO: There seems to be a delay from Attachment upload
        // until activities are updated. So we're setting
        // a timeout here. Can we fix this on the backend?
        invalidateUseTaskAttachmentsQuery(task.id);
        setTimeout(() => {
          invalidateUseTaskAttachmentsQuery(task.id);
          invalidateUseTicketActivityQuery(task.ticketId);
        }, 500);
      },
    }
  );

  return { ...res, progress, error: res.error as Error, abort: request.abort };
};

export { useUploadTaskAttachment, uploadTaskAttachmentIdent };
