import type { BottomRightMapControlsProps } from "./BottomRightMapControls.types";
import { MapControls } from "@/components/Map/Controls";
import { MapLegend } from "@/components/Map/MapLegend";
import { useResponsive } from "@/hooks";
import { ClusterMarkerCard } from "@/components/Map/ClusterMarkerCard";
import { evaluatePadding } from "@/components/Map/Popups/ClusterMarkerPopup/ClusterMarkerPopup.utils";
import { RenderSelectedTickets } from "../RenderSelectedTickets";

const BottomRightMapControls = ({
  mapLayerControlsProps,
  ticketsToShow,
  scoreTypeState,
  mapToggleButton,
  clusterToShow,
}: BottomRightMapControlsProps) => {
  const { isSm } = useResponsive();

  const { layers } = mapLayerControlsProps;

  const { scoreType, setScoreType } = scoreTypeState;

  if (!layers.legend) return null;

  return (
    <MapControls position="bottom" dataTestId="map-legend-container">
      {layers.legend.enabled && layers.legend.visible && (
        <MapLegend
          layers={layers}
          scoreType={scoreType}
          onLegendChange={setScoreType}
        />
      )}
      {mapToggleButton}
      {/* Render Tickets information info on Mobile click */}
      {!isSm && ticketsToShow && (
        <RenderSelectedTickets
          tickets={ticketsToShow.tickets}
          classname="w-full mt-1"
        />
      )}

      {!isSm &&
        clusterToShow &&
        clusterToShow?.geometry.type === "Point" &&
        clusterToShow.geometry.coordinates &&
        clusterToShow.properties !== null && (
          <ClusterMarkerCard
            selectedCount={clusterToShow.properties.selectedTickets}
            className={`${evaluatePadding(clusterToShow.properties)} mt-1`}
            risks={clusterToShow.properties}
            scoreType={scoreType}
          />
        )}
    </MapControls>
  );
};

export { BottomRightMapControls };
