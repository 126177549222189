import type { DamageThreatInsightFeedbackSliderProps } from "./DamageThreatInsightFeedbackSlider.types";
import { Swiper } from "swiper/react";
import { EffectFade } from "swiper/modules";

// Styles must use direct files imports
// core Swiper
import "swiper/css";
// Navigation module
import "swiper/css/navigation";
// Thumbs module
import "swiper/css/thumbs";

import "swiper/css/effect-fade";
import "swiper/css/a11y";
import "swiper/css/keyboard";

import { Slide } from "./Slide";
import { SliderFooter } from "./SliderFooter";
import { SliderWrapper } from "./SliderWrapper";
import { SliderHeader } from "./SliderHeader";

const DamageThreatInsightFeedbackSlider = ({
  damageThreatList,
  gallerySlideIndex,
  closeSlider,
  setGallerySlideIndex,
}: DamageThreatInsightFeedbackSliderProps) => (
  <SliderWrapper isSliderOpen={gallerySlideIndex !== undefined}>
    {gallerySlideIndex !== undefined && (
      <Swiper
        modules={[EffectFade]}
        autoHeight
        effect="fade"
        allowTouchMove={false}
        onSwiper={(swiper) => swiper.slideTo(gallerySlideIndex)}
        className="max-w-[98%] rounded-lg sm:max-w-xl flex flex-col shadow-xl bg-white !overflow-visible"
      >
        <SliderHeader closeSlider={closeSlider} />
        {damageThreatList.map((damageThreat) => (
          <Slide key={damageThreat.id} slideInfo={damageThreat} />
        ))}
        <SliderFooter
          sliderLenght={damageThreatList.length - 1}
          selectedSlideIndex={gallerySlideIndex}
          closeSlider={closeSlider}
          setGallerySlideIndex={setGallerySlideIndex}
        />
      </Swiper>
    )}
  </SliderWrapper>
);

export { DamageThreatInsightFeedbackSlider };
