import { useState } from "react";
import { useIsMutating } from "@tanstack/react-query";
import { useOnlineStatus } from "@/hooks";
import { LimitedConnectionModal } from "../Modals";
import { BANNER_TYPE, NetworkStatusBanner } from "../NetworkStatusBanner";

const OfflineBanner = () => {
  const isOnline = useOnlineStatus();
  const [limitedConnectionModalOpened, setLimitedConnectionModalOpened] =
    useState(false);
  const isMutating = useIsMutating({
    predicate: (mutation) =>
      mutation?.state.isPaused && mutation?.state.status === "loading",
  });

  if (!isOnline) {
    return (
      <>
        <NetworkStatusBanner
          type={BANNER_TYPE.OFFLINE}
          onClick={() => setLimitedConnectionModalOpened(true)}
        />
        <LimitedConnectionModal
          isOpened={limitedConnectionModalOpened}
          onClose={() => setLimitedConnectionModalOpened(false)}
        />
      </>
    );
  }

  if (isMutating) {
    return <NetworkStatusBanner type={BANNER_TYPE.SYNCING} />;
  }

  return null;
};

export { OfflineBanner };
