import type { DialogProps } from "./Dialog.types";
import { Dialog as HeadlessDialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import classnames from "classnames";
import { DialogContent } from "./DialogContent";
import { DialogOverlay } from "./DialogOverlay";

const Dialog = ({
  header,
  footer,
  isOpen,
  onClose,
  children,
  dataTestId,
  theme,
  afterClose,
  variant = "normal",
  size = "medium",
  unmount = true,
}: DialogProps) => (
  <Transition
    show={isOpen}
    as={Fragment}
    afterLeave={afterClose}
    unmount={unmount}
  >
    <HeadlessDialog
      onClose={onClose}
      className={classnames("relative z-10", theme?.dialog)}
      unmount={unmount}
    >
      <DialogOverlay unmount={unmount} />
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex h-full items-center justify-center md:p-16 text-center">
          <DialogContent
            header={header}
            footer={footer}
            theme={theme}
            dataTestId={dataTestId}
            onClose={onClose}
            variant={variant}
            size={size}
            unmount={unmount}
          >
            {children}
          </DialogContent>
        </div>
      </div>
    </HeadlessDialog>
  </Transition>
);

export { Dialog };
