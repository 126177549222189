import type { DateState, isSameDateParams } from "./ExpirationDateModal.types";
import { formatFullDateTime } from "@/format";

/**
 * We don't need to compare seconds or milliseconds for expiration date.
 *  */
const isSameDate: isSameDateParams = (newDate, actualDate) => {
  if (!actualDate) {
    return false;
  }

  newDate.setSeconds(0, 0);
  actualDate.setSeconds(0, 0);

  return newDate.getTime() === actualDate.getTime();
};

const getSuccessMessage = (
  dateTime: DateState,
  hasMoreThanOneTicket: boolean,
  numberOfTickets: number
) =>
  dateTime
    ? `Expiration date changed to ${formatFullDateTime(dateTime)}${
        hasMoreThanOneTicket ? ` for ${numberOfTickets} tickets` : ""
      }`
    : `Expiration date cleared ${
        hasMoreThanOneTicket ? ` for ${numberOfTickets} tickets` : ""
      }`;

export { isSameDate, getSuccessMessage };
