import { useMutation } from "@tanstack/react-query";

import { useDamagePreventionAuth } from "@/hooks";
import { getCookies } from "@/utils";

type EmailTicketPayload = {
  receiver_email: string;
  subject: string;
  body: string;
};

type EmailTicketResponse = {
  message: string;
};

async function emailTicketFetcher(
  body: EmailTicketPayload,
  bearerToken: string,
  ticketId?: number
) {
  if (ticketId === undefined) {
    console.error("No ticket ID provided, can not email ticket");
    return;
  }
  const cookies = getCookies();
  const res = await fetch(`/api/tickets/${ticketId}/email/`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${bearerToken}`,
      Accept: "application/json",
      "X-CSRFToken": cookies.csrftoken,
    },
  });
  const result = await res.json();
  return result;
}

const useEmailTicket = (ticketId?: number) => {
  const { bearerToken } = useDamagePreventionAuth();

  return useMutation<EmailTicketResponse, unknown, EmailTicketPayload>(
    (emailTicketPayload) =>
      emailTicketFetcher(emailTicketPayload, bearerToken, ticketId)
  );
};

// eslint-disable-next-line import/prefer-default-export
export { useEmailTicket };
