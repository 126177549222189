import type { TasksStepsContextProps } from "./TasksSteps.types";
import { createContext, useContext } from "react";

const TasksStepsContext = createContext<TasksStepsContextProps | undefined>(
  undefined
);

const useTasksStepsContext = () => {
  const context = useContext(TasksStepsContext);
  if (context === undefined) {
    throw new Error(
      "useTasksStepsContext must be used within a TasksStepsProvider"
    );
  }
  return context;
};

export { TasksStepsContext, useTasksStepsContext };
