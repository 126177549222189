import type { MapControlProps } from "./MapControl.types";
import classNames from "classnames";
import { Icon } from "@urbint/silica";
import { Button } from "@/common/Button";

const MapControl = ({
  icon,
  onClick,
  children,
  className,
  dataTestId,
  as: Component = Button,
}: MapControlProps) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Component
      className={classNames(
        "rounded bg-white drop-shadow-lg flex items-center justify-center w-9 h-9 cursor-pointer mb-[1px] !p-0",
        className
      )}
      onClick={() => handleClick()}
      data-testid={dataTestId}
    >
      {icon && <Icon name={icon} className="text-lg justify-center flex" />}
      {children}
    </Component>
  );
};

export { MapControl };
