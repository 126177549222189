import type { DynamicOptionsReturn } from "@/api";
import type { Question, ResponseForm } from "@/models";
import type { SelectOption } from "@/components/Select";
import { useCallback, useMemo, useRef, useState } from "react";
import { QuestionAnswerType } from "@/models";
import { useTaskTypes } from "@/api";
import { isFormValid, type FormValues, type QuestionValue } from "@/utils";

const useResponseFormHandler = (
  responseForm?: ResponseForm,
  dynamicOptions?: DynamicOptionsReturn
) => {
  const { data: taskTypes } = useTaskTypes();

  const [formValues, setFormValuesState] = useState<FormValues>({});
  const [dirtyQuestions, setDirtyQuestions] = useState<{
    [key: number]: boolean;
  }>({});
  const formValuesRef = useRef<FormValues>({});

  const setFormValues = useCallback(
    (
      valOrFn: FormValues | ((val: FormValues) => FormValues),
      prevailLocalFormValue = false
    ) => {
      const newFormValue =
        typeof valOrFn === "function"
          ? valOrFn(formValuesRef.current)
          : valOrFn;

      const formValue = prevailLocalFormValue
        ? { ...newFormValue, ...formValuesRef.current }
        : newFormValue;

      setFormValuesState(formValue);
      formValuesRef.current = formValue;
    },
    [setFormValuesState, formValuesRef]
  );

  const getIsQuestionDirty = useCallback(
    (question: Question): boolean => dirtyQuestions[question.id] || false,
    [dirtyQuestions]
  );
  const setIsQuestionDirty = useCallback(
    (question: Question, isDirty = true) => {
      setDirtyQuestions((x) => ({ ...x, [question.id]: isDirty }));
    },
    [setDirtyQuestions]
  );
  const setQuestionValue = useCallback(
    (question: Question, value: QuestionValue) => {
      setFormValues((formValues) => ({
        ...formValues,
        [question.id]: value ?? "",
      }));
      setIsQuestionDirty(question);
    },
    [setFormValues, setIsQuestionDirty]
  );

  const setAllQuestionsAsDirty = useCallback(() => {
    const acc: { [key: number]: boolean } = {};
    const dirtyQuestions =
      responseForm?.questions.reduce((acc, question) => {
        acc[question.id] = true;
        return acc;
      }, acc) || acc;
    setDirtyQuestions(dirtyQuestions);
  }, [setDirtyQuestions, responseForm?.questions]);

  const getQuestionValue = useCallback(
    (question: Question): QuestionValue => formValues[question.id],
    [formValues]
  );

  const getOptionsForQuestion = useCallback(
    (question: Question): SelectOption<string | undefined>[] | undefined => {
      let value;

      if (
        question.hasDynamicOptions &&
        question.answerType === QuestionAnswerType.POSITIVE_RESPONSE_CODE &&
        dynamicOptions
      ) {
        value = dynamicOptions[question.id];
      } else if (question.answerType === QuestionAnswerType.FOLLOW_UP_TASK) {
        value = taskTypes?.map((x) => ({ id: x.id, text: x.name }));
      }
      // All other scenarios
      else {
        value = responseForm?.getResponseOptions(question.id);
      }

      const options: SelectOption<string | undefined>[] | undefined =
        value?.map((x) => ({ value: String(x.id), label: x.text }));

      // Natural sort options by label
      return options?.sort((firstOption, secondOption) =>
        firstOption?.label.localeCompare(secondOption?.label, "en-US", {
          numeric: true,
          sensitivity: "base",
        })
      );
    },
    [dynamicOptions, responseForm, taskTypes]
  );

  const isReadyForSubmission = useMemo(
    () =>
      responseForm === undefined ||
      isFormValid(responseForm?.questions, formValues),
    [responseForm, formValues]
  );

  return {
    formValuesRef,
    getQuestionValue,
    getIsQuestionDirty,
    getOptionsForQuestion,
    setIsQuestionDirty,
    setQuestionValue,
    setAllQuestionsAsDirty,
    setFormValues,
    isReadyForSubmission,
    formValues,
  };
};

export { useResponseFormHandler };
