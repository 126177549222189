import type { getRightFieldsProps } from "../TicketDetailsSection.types";
import { formatFullDateTime } from "@/format";
import { ExpirationDate } from "../ExpirationDate";

const getRightFields = ({ ticket }: getRightFieldsProps) => {
  const { latestVersion } = ticket ?? {};
  return [
    {
      label: "Work starts",
      value: formatFullDateTime(latestVersion?.workStartDate),
      testId: "workStarts",
    },
    {
      label: "Reported on",
      value: formatFullDateTime(latestVersion?.reportedDate),
      testId: "reportedOn",
    },
    {
      label: "Response due",
      value: formatFullDateTime(latestVersion?.responseDueDate),
      testId: "responseDue",
    },
    {
      label: "Last updated",
      value: formatFullDateTime(latestVersion?.updatedOn),
      testId: "lastUpdated",
    },
    {
      label: "Expiration date",
      value: ticket ? <ExpirationDate ticket={ticket} /> : "",
      testId: "expirationDate",
    },
  ];
};

export { getRightFields };
