import type { RectangleSVGProps } from "./RectangleSVG.types";
import React from "react";

const RectangleSVG = ({ fill, stroke, opacity = 0.5 }: RectangleSVGProps) => (
  <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
    <rect
      width="20"
      height="20"
      fill={fill}
      fillOpacity={opacity}
      stroke={stroke}
      strokeWidth="2"
    />
  </svg>
);

export { RectangleSVG };
