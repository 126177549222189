import type { DropdownSelectDefaultButtonProps } from "./DropdownSelectDefaultButton.types";
import { Icon } from "@urbint/silica";
import { SelectButton } from "./SelectButton";

const DropdownSelectDefaultButton = <T,>({
  testId,
  placeholder,
  selectedOption,
  className,
}: DropdownSelectDefaultButtonProps<T>) => {
  const textClass =
    selectedOption && selectedOption.value !== undefined
      ? "text-neutral-shade-primary"
      : "text-neutral-shade-tertiary";
  return (
    <SelectButton className={className} testId={testId}>
      <p className={`overflow-hidden overflow-ellipsis flex-1 ${textClass}`}>
        {selectedOption ? selectedOption.label : placeholder}
      </p>
      <Icon className="ml-auto" name="chevron_down" />
    </SelectButton>
  );
};

export { DropdownSelectDefaultButton };
