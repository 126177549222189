import type { FilterDialogFooterProps } from "./FilterDialogFooter.types";
import { Button } from "@/common/Button";

const FilterDialogFooter = ({
  isEditing,
  handleClose,
  handleSaveClick,
  isCreatingSavedView,
  isNameValid,
}: FilterDialogFooterProps) => (
  <>
    {isEditing ? (
      <Button
        type="button"
        variant="primary"
        data-testid="filter-modal-save-button"
        onClick={handleSaveClick}
        disabled={!isNameValid}
        className="w-full h-10"
      >
        {isCreatingSavedView ? "Save as new view" : "Save changes"}
      </Button>
    ) : (
      <Button
        variant="primary"
        type="submit"
        onClick={() => handleClose(true)}
        className="w-full"
        data-testid="filter-modal-apply-changes-button"
      >
        Apply changes
      </Button>
    )}
  </>
);

export { FilterDialogFooter };
