import type { AttachmentsSectionProps } from "./AttachmentsSection.types";
import cx from "classnames";
import { useNonTicketedEventAttachments, useTaskAttachments } from "@/api";
import { UploadButtonsWrapper } from "./UploadButtonsWrapper";
import { ThumbnailWrapper } from "./ThumbnailWrapper";
import { AttachmentDetailSectionHeader } from "./AttachmentDetailSectionHeader";
import {
  getAttachmentType,
  isAttachmentFromNonTicketedEvent,
  isAttachmentFromTicket,
} from "./AttachmentsSection.utils";

const AttachmentsSection = ({
  attachmentType,
  canDelete = false,
  onDeleteClick,
  onAttachmentClick,
  onInvalidAttachmentSize,
  className,
}: AttachmentsSectionProps) => {
  const attachment = getAttachmentType(attachmentType);
  let attachmentsData;

  if (isAttachmentFromNonTicketedEvent(attachment)) {
    attachmentsData = useNonTicketedEventAttachments(
      attachment.eventId,
      undefined,
      {
        enabled: !!attachment.eventId,
      }
    );
  } else if (isAttachmentFromTicket(attachment)) {
    attachmentsData = useTaskAttachments(attachment.taskId, undefined, {
      enabled: !!attachment.taskId,
    });
  }

  const { data: attachments = [] } = attachmentsData || {};

  return (
    <section className={cx("bg-white", className)}>
      <AttachmentDetailSectionHeader>
        {`Attachments (${attachments?.length ?? 0})`}
      </AttachmentDetailSectionHeader>
      <ThumbnailWrapper
        attachments={attachments}
        canDelete={canDelete}
        onThumbnailDelete={onDeleteClick}
        onThumbnailClick={onAttachmentClick}
      />
      <UploadButtonsWrapper
        attachmentType={attachmentType}
        onInvalidAttachmentSize={onInvalidAttachmentSize}
      />
    </section>
  );
};

export { AttachmentsSection };
