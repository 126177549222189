import type { UseQueryOptions } from "@tanstack/react-query";
import type { ServiceCenterSerializer } from "@/utils/damage-prevention";
import { useQuery } from "@tanstack/react-query";
import { ServiceCenter } from "@/models";
import { UrbintApi } from "@/utils/UrbintApi";
import { handleApiResponse } from "@/api/helpers";

type IReturn = ServiceCenter[];
const api = new UrbintApi();
const serviceCenterIdent = () => ["serviceCenters"];

const fetchServiceCenters = () =>
  api
    .getPage<ServiceCenterSerializer>({
      endPoint: "service_centers",
      page: 1,
    })
    .then(handleApiResponse)
    .then((res) => res.results.map((r) => new ServiceCenter(r)));

const useServiceCenters = (opts?: UseQueryOptions<IReturn>) =>
  useQuery<IReturn>(serviceCenterIdent(), () => fetchServiceCenters(), opts);

export { serviceCenterIdent, useServiceCenters };
