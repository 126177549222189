import type {
  ResponsiveAPI,
  ResponsiveBreakpoints,
} from "./useResponsive.types";
import { useEffect, useMemo, useState } from "react";

/**
 * Hook for checking which responsive breakpoints are currently being rendered.
 * This hook should ONLY be used for conditional rendering of components, while
 * conditional styling should use Tailwind's responsive class variants instead.
 */
const useResponsive = (): ResponsiveAPI => {
  const queries = useMemo(
    () => ({
      isSm: window.matchMedia("(min-width: 640px)"),
      isMd: window.matchMedia("(min-width: 768px)"),
      isLg: window.matchMedia("(min-width: 1024px)"),
      isXl: window.matchMedia("(min-width: 1280px)"),
      is2xl: window.matchMedia("(min-width: 1536px)"),
    }),
    []
  );

  const [state, setState] = useState<ResponsiveAPI>({
    isSm: queries.isSm.matches,
    isMd: queries.isMd.matches,
    isLg: queries.isLg.matches,
    isXl: queries.isXl.matches,
    is2xl: queries.is2xl.matches,
  });

  useEffect(() => {
    const entries = Object.entries(queries) as [
      ResponsiveBreakpoints,
      MediaQueryList
    ][];
    entries.forEach(([prop, query]) => {
      query.onchange = (e) => {
        setState((originalState) => {
          const newState = { ...originalState };
          newState[prop] = e.matches;
          return newState;
        });
      };
    });

    return () => {
      entries.forEach(([, query]) => {
        query.onchange = null;
      });
    };
  }, [queries]);

  return state;
};

export { useResponsive };
