import type { SavedView } from "@/models";
import { useMutation } from "@tanstack/react-query";

import { UrbintApi } from "@/utils/UrbintApi";
import { setSavedViewsData } from "@/api";

const api = new UrbintApi();

const deleteSavedView = (savedView: SavedView) =>
  api.delete("saved_views", savedView.id);

const useDeleteSavedView = () =>
  useMutation(deleteSavedView, {
    onSuccess: (_, deleted) => {
      // Invalidate any queries that might be affected
      // by this change
      setSavedViewsData((data) => {
        if (!data) return [];
        const ix = data.findIndex((x) => x.id === deleted.id);
        if (ix === -1) return data;
        const newData = [...data];
        newData.splice(ix, 1);
        return newData;
      });
    },
  });

// eslint-disable-next-line import/prefer-default-export
export { useDeleteSavedView };
