import type { UseQueryOptions } from "@tanstack/react-query";
import type { UserSerializer } from "@/utils/damage-prevention";
import { useQuery } from "@tanstack/react-query";
import { User } from "@/models";
import { UrbintApi } from "@/utils/UrbintApi";
import { queryClient } from "@/api/client";
import { handleApiResponse } from "@/api/helpers";

const api = new UrbintApi();

const usersIdent = () => ["users"];

const fetchUsers = () =>
  api
    .getMany<UserSerializer>({
      endPoint: "users",
    })
    .then(handleApiResponse)
    .then((res) => res.map((r) => new User(r)));

const useUsers = (opts?: UseQueryOptions<User[]>) =>
  useQuery<User[]>(usersIdent(), fetchUsers, opts);

const prefetchUsers = () => queryClient.prefetchQuery(usersIdent(), fetchUsers);

export { usersIdent, useUsers, prefetchUsers };
