import type { ArcType } from "./Gauge.types";
import { arc } from "d3-shape";

const FILL_CURRENT = "fill-current";
const DEFAULT_GREEN_ARC_COLOR = "text-[#2BA329]";
const DEFAULT_YELLOW_ARC_COLOR = "text-[#EEBF13]";
const DEFAULT_ORANGE_ARC_COLOR = "text-[#FFAB07]";
const DEFAULT_RED_ARC_COLOR = "text-[#E7183E]";

const firstArc =
  arc<ArcType>()
    .innerRadius(1)
    .outerRadius(0.9)
    .startAngle(-Math.PI / 2)
    .endAngle(-Math.PI / 4)
    .padAngle(0)
    .cornerRadius(2)() || undefined;

const secondArc =
  arc<ArcType>()
    .innerRadius(1)
    .outerRadius(0.9)
    .startAngle(-Math.PI / 4.15)
    .endAngle(0)
    .padAngle(0)
    .cornerRadius(2)() || undefined;

const thirdArc =
  arc<ArcType>()
    .innerRadius(1)
    .outerRadius(0.9)
    .startAngle(0.04)
    .endAngle(Math.PI / 4)
    .padAngle(0)
    .cornerRadius(2)() || undefined;

const fourthArc =
  arc<ArcType>()
    .innerRadius(1)
    .outerRadius(0.9)
    .startAngle(Math.PI / 3.85)
    .endAngle(Math.PI / 2)
    .padAngle(0)
    .cornerRadius(2)() || undefined;

export {
  firstArc,
  secondArc,
  thirdArc,
  fourthArc,
  FILL_CURRENT,
  DEFAULT_GREEN_ARC_COLOR,
  DEFAULT_YELLOW_ARC_COLOR,
  DEFAULT_ORANGE_ARC_COLOR,
  DEFAULT_RED_ARC_COLOR,
};
