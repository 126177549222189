import type { UseQueryOptions } from "@tanstack/react-query";
import type { PositiveResponseCodeSerializer } from "@/utils/damage-prevention";
import { useQuery } from "@tanstack/react-query";
import { PositiveResponseCode } from "@/models";
import { UrbintApi } from "@/utils/UrbintApi";
import { handleApiResponse } from "@/api/helpers";

const api = new UrbintApi();

const positiveResponseCodeTransitionsIdent = (prcId: number) => [
  "positive_response_codes/transitions",
  prcId,
];

const fetchPositiveResponseCodeTransitions = (prcId: number) =>
  api
    .getOne<PositiveResponseCodeSerializer[]>({
      endPoint: "positive_response_codes",
      action: "transitions",
      id: prcId,
    })
    .then(handleApiResponse)
    .then((res) => res.map((r) => new PositiveResponseCode(r)));

const usePositiveResponseCodeTransitions = (
  prcId: number,
  opts?: UseQueryOptions<PositiveResponseCode[]>
) =>
  useQuery<PositiveResponseCode[]>(
    positiveResponseCodeTransitionsIdent(prcId),
    () => fetchPositiveResponseCodeTransitions(prcId),
    opts
  );

export {
  positiveResponseCodeTransitionsIdent,
  usePositiveResponseCodeTransitions,
};
