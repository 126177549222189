import type { UseQueryOptions } from "@tanstack/react-query";
import type { MemberCodeSerializer } from "@/utils/damage-prevention";
import { useQuery } from "@tanstack/react-query";
import isBoolean from "lodash/isBoolean";
import { MemberCode } from "@/models";
import { UrbintApi } from "@/utils/UrbintApi";
import { queryClient } from "@/api/client";
import { handleApiResponse } from "@/api/helpers";

type IReturn = MemberCode[];

const api = new UrbintApi();

const memberCodesIdent = (ticketId?: number) =>
  ticketId ? ["member_codes", ticketId] : ["member_codes"];

const fetchMemberCodes = (ticketId?: number) =>
  api
    .getMany<MemberCodeSerializer>({
      endPoint: "member_codes",
      queryParams: ticketId ? { ticket: ticketId.toString() } : undefined,
    })
    .then(handleApiResponse)
    .then((res) => res.map((r) => new MemberCode(r)));

const useMemberCodes = (ticketId?: number, opts?: UseQueryOptions<IReturn>) =>
  useQuery<IReturn>(
    memberCodesIdent(ticketId),
    () => fetchMemberCodes(ticketId),
    opts
  );

const useMemberCode = (
  memberCodeId: number,
  opts?: UseQueryOptions<
    MemberCode | undefined,
    unknown,
    MemberCode | undefined,
    Array<string | number | IReturn | undefined>
  >
) => {
  const { data: memberCodes } = useMemberCodes();

  // We don't want to refire this until we actually have memberCodes to pull from
  const isEnabled =
    !!memberCodes && (isBoolean(opts?.enabled) ? opts?.enabled : true);

  return useQuery(
    ["member_codes", "data", memberCodes, memberCodeId],
    () => {
      const filteredMemberCode = memberCodes?.filter(
        (x) => x.id === memberCodeId
      )[0];

      /* FIXME: This is an quick fix to avoid undefined data being 
        returned by the useQuery success callback. With REactQuery 
        migrated to TanStack Query a new exception is raised if useQuery
        returns undefined. Whilst it doesn't impact on the functionality, 
        this would be better to refactor and avoid new instances and
        using defaults. */
      if (!filteredMemberCode)
        return new MemberCode({ key: "", eight11_center: "" });

      return filteredMemberCode;
    },
    { ...opts, enabled: isEnabled }
  );
};

const prefetchMemberCodes = (ticketId?: number) =>
  queryClient.prefetchQuery(memberCodesIdent(), () =>
    fetchMemberCodes(ticketId)
  );

export { useMemberCodes, useMemberCode, prefetchMemberCodes };
