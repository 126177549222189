import type { DependencyList } from "react";
import { useCallback, useEffect } from "react";

const useWindowEventListener = (
  event: string,
  fn: (event: any) => void,
  opts?: boolean | AddEventListenerOptions | undefined,
  deps?: DependencyList
) => {
  const cb = useCallback(fn, [fn, deps]);
  useEffect(() => {
    window.addEventListener(event, cb, opts);
    return () => window.removeEventListener(event, cb, opts);
  }, [event, cb, opts]);
};

export { useWindowEventListener };
