// TODO: Add margin to each field
import type { SyntheticEvent } from "react";
import type { FormBuilderProps, FormDataSerialized } from "./FormBuilder.types";
import { useState } from "react";
import { useForm } from "react-hook-form";

import { useToasts } from "../Toasts";
import { Field } from "./Field";
import { AutoSaveStatus } from "./AutoSaveStatus";

const FormBuilder = ({
  testId,
  title,
  titleClassName,
  className,
  formBlueprint,
  onSubmit,
  submitButtonLabel,
  submitButtonClassName,
  autoSaveRequest,
}: FormBuilderProps) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
  } = useForm();
  const [isAutoSaving, setIsAutoSaving] = useState(false);
  const [autoSavedAt, setAutoSavedAt] = useState<Date | undefined>(undefined);
  const [autoSaveHasError, setAutoSaveHasError] = useState<boolean>(false);
  const { addToast } = useToasts();
  const autoSave = async (requestFunction: () => void) => {
    try {
      setAutoSaveHasError(false);
      setIsAutoSaving(true);
      setAutoSavedAt(undefined);
      await requestFunction();
      setAutoSavedAt(new Date());
    } catch (error) {
      setAutoSaveHasError(true);
      if (error instanceof Error) {
        addToast(`Error saving form: ${error.message}`, "error");
      } else {
        addToast("Error saving form", "error");
      }
    } finally {
      setIsAutoSaving(false);
    }
  };

  const onSelectChange =
    (onChangeFromHooksForm: (e: SyntheticEvent) => void) =>
    async (e: SyntheticEvent) => {
      onChangeFromHooksForm(e);
      autoSaveRequest && (await autoSave(autoSaveRequest(getValues)));
    };

  const onBlur =
    (onBlurFromHookForms: (e: SyntheticEvent) => void) =>
    async (e: SyntheticEvent) => {
      onBlurFromHookForms(e);
      autoSaveRequest && (await autoSave(autoSaveRequest(getValues)));
    };

  const onSubmitForm = (data: FormDataSerialized) => {
    onSubmit(data);
  };

  return (
    <form
      data-testid={testId}
      onSubmit={handleSubmit(onSubmitForm)}
      className={className}
    >
      <div className="flex justify-between mb-6">
        <span className={titleClassName}>{title}</span>
        <AutoSaveStatus
          isAutoSaving={isAutoSaving}
          autoSavedAt={autoSavedAt}
          autoSaveHasError={autoSaveHasError}
        />
      </div>
      {Object.entries(formBlueprint).map(([sectionName, formSection]) =>
        formSection.fields.map((formField, ix) => (
          <div className="mb-6" key={`${sectionName}-${formField.id}`}>
            <Field
              control={control}
              errors={errors}
              sectionName={sectionName}
              formField={formField}
              onBlur={onBlur}
              onSelectChange={onSelectChange}
              testId={`form-question-${ix}`}
            />
          </div>
        ))
      )}
      <button
        type="submit"
        className={`w-full bg-brand-40 text-white shadow-5 leading-tight py-2 font-semibold rounded-[6px] ${submitButtonClassName}`}
        data-testid="submit-form"
      >
        {submitButtonLabel}
      </button>
    </form>
  );
};

export { FormBuilder };
