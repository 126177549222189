import type { DetailsSectionSplitContentProps } from "./DetailsSectionSplitContent.types";
import type { DetailProps } from "./Detail";
import { useResponsive } from "@/hooks";
import { Detail } from "./Detail";

const DetailsSectionSplitContent = ({
  left = [],
  right = [],
}: DetailsSectionSplitContentProps) => {
  const { isLg } = useResponsive();
  const toDetail = (props: DetailProps) => (
    <Detail key={props.label} {...props} />
  );
  if (isLg) {
    return (
      <div className="lg:space-x-6 xl:space-x-24 flex mb-3 flex-col lg:flex-row">
        <div className="flex-1">
          {left.map((props: DetailProps) => (
            <Detail key={props.label} {...props} />
          ))}
        </div>
        <div className="flex-1">
          {right.map((props: DetailProps) => (
            <Detail key={props.label} {...props} />
          ))}
        </div>
      </div>
    );
  }
  return <div>{left.concat(right).map(toDetail)}</div>;
};

export { DetailsSectionSplitContent };
