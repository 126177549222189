import type { GeocodeRouteModalProps } from "./GeocodeRouteModal.types";
import type { GeocoderResult } from "@/components/GeocodeAutocompleteInput";
import type { dialogFooter } from "@/components/Dialog";
import { useState } from "react";
import { GeocodeAutocompleteInput } from "@/components/GeocodeAutocompleteInput";
import { Switch } from "@/components/Switch";
import { Dialog } from "@/components/Dialog";
import { INPUT_ERROR } from "./GeocodeRouteModal.constants";

const GeocodeRouteModal = ({
  isOpen,
  onClose,
  onOptimize,
}: GeocodeRouteModalProps) => {
  // track start and end locations
  const [locationOptions, setLocationOptions] = useState<
    [start: GeocoderResult | null, end: GeocoderResult | null]
  >([null, null]);

  // show invalid location error
  const [showError, setShowError] = useState<[start: boolean, end: boolean]>([
    false,
    false,
  ]);

  // track toggle switch state
  const [useStartAsEnd, setUseStartAsEnd] = useState(true);

  //
  // --- CONVENIENCE FUNCTIONS ---
  //

  const useStartAsEndOn = () => setUseStartAsEnd(true);
  const useStartAsEndOff = () => setUseStartAsEnd(false);

  const setStartLocation = (newStart: GeocoderResult | null) =>
    setLocationOptions(([, end]) => [newStart, end]);

  const setEndLocation = (newEnd: GeocoderResult | null) =>
    setLocationOptions(([start]) => [start, newEnd]);

  const setStartError = (newStart: boolean) =>
    setShowError(([, end]) => [newStart, end]);

  const setEndError = (newEnd: boolean) =>
    setShowError(([start]) => [start, newEnd]);

  const resetError = () => setShowError([false, false]);

  // Handle user interaction with optimize button
  const handleOptimize = () => {
    const [start, end] = locationOptions;
    if (useStartAsEnd && start) onOptimize([start, start]);
    else if (start && end) onOptimize([start, end]);
    else {
      if (!start) setStartError(true);
      if (!end) setEndError(true);
    }
  };

  const showStartError = showError[0];
  const showEndError = showError[1];

  if (showStartError || showEndError) {
    setTimeout(() => resetError(), 1000);
  }

  const startLocation = locationOptions[0];
  const endLocation = useStartAsEnd ? locationOptions[0] : locationOptions[1];

  const modalFooter: dialogFooter = {
    toRender: "Optimize",
    onClose,
    onClick: handleOptimize,
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      header="Set a start and end location"
      footer={modalFooter}
    >
      <>
        <section
          data-id="route-modal-description-section"
          className="mt-2 text-base text-neutral-shade-secondary"
        >
          To help us optimize your upcoming tickets, please specify where you
          would like to start and end your day.
        </section>

        {/* Start location */}
        <section
          data-id="start-route-section"
          className="mt-8 mb-10 text-neutral-shade-secondary"
        >
          <label
            className="text-sm font-semibold"
            data-id="start-route-section-label"
            htmlFor="start-route-input"
          >
            Starting location
          </label>
          <div className="mt-1 mb-1">
            <GeocodeAutocompleteInput
              id="start-route-input"
              key={startLocation?.id}
              onAddressSelected={setStartLocation}
              selectedAddress={startLocation}
              invalidError={showStartError ? INPUT_ERROR : null}
              placeholder="Enter a start location"
            />
          </div>
        </section>

        {/* End location */}
        <section className="mb-5 text-neutral-shade-secondary">
          <label className="text-sm font-semibold" htmlFor="end-route-input">
            End location
          </label>
          <div className="mt-1 mb-1 text-base font-medium align-middle">
            <Switch
              dataTestId="starting-location-geocode-switch"
              checked={useStartAsEnd}
              onChange={useStartAsEnd ? useStartAsEndOff : useStartAsEndOn}
              label="Same as starting location"
            />
          </div>
          <div className="mt-2 mb-1">
            <GeocodeAutocompleteInput
              disabled={useStartAsEnd}
              id="end-route-input"
              key={endLocation?.id}
              onAddressSelected={setEndLocation}
              selectedAddress={endLocation}
              invalidError={showEndError ? INPUT_ERROR : null}
              placeholder="Enter an end location"
            />
          </div>
        </section>
      </>
    </Dialog>
  );
};

export { GeocodeRouteModal };
