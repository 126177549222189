const formatResponseDate = (date: Date) =>
  `${date.toLocaleString("en-US", {
    weekday: "long",
    month: "long",
    year: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  })}`;

export { formatResponseDate };
