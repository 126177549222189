import type { UserSerializer } from "@/utils/damage-prevention";
import { UrbintModel } from "@/utils/UrbintModel";

class User extends UrbintModel<UserSerializer> {
  get displayName() {
    return this.castString(this.data.display_name) || this.email;
  }

  get username() {
    return this.castString(this.data.username);
  }

  get email() {
    return this.castString(this.data.email);
  }

  get phoneNumber() {
    return this.castString(this.data.phone_number);
  }

  get permissions(): string[] {
    return this.castArray(this.data.user_permissions);
  }

  get id() {
    return this.castNumber(this.data.id);
  }

  get isActive() {
    return this.castBool(this.data.is_active);
  }

  get isUrbintAdmin() {
    return this.castBool(this.data.is_superuser);
  }

  compare(instance: User) {
    return this.displayName.localeCompare(instance.displayName);
  }

  serialize() {
    return this.data;
  }

  matches(termOrPattern: string | RegExp) {
    const fulltext = [this.displayName, this.email].join(" ");
    return super.matches(termOrPattern, fulltext);
  }
}

export { User };
