import type { RiskIconProps } from "./RiskIcon.types";
import React from "react";
import { riskColor } from "@/format";
import { CircleSVG } from "./CircleSVG";

const RiskIcon = ({ type }: RiskIconProps) => (
  <div className={riskColor(type)}>
    <CircleSVG />
  </div>
);

export { RiskIcon };
