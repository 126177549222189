import { useCallback, useMemo } from "react";
import { IonIcon } from "@ionic/react";
import { arrowDownOutline } from "ionicons/icons";
import { DropdownSelect } from "@/components/Select";
import { getFieldLabel, SortOrderDirection } from "@/models";
import { AvailableTicketFilters, usePublicConfig } from "@/api";
import { useTicketViewEditor } from "@/components/TicketViewEditorProvider";
import { getKeys } from "@/utils";
import { Spinner } from "@/common/Spinner";
import { useDamagePreventionAuth } from "@/hooks";
import { FilterDialogSection } from "../FilterDialogSection";
import { FilterDialogCard } from "../FilterDialogCard";
import { FilterDialogFieldFilterCard } from "./FilterDialogFieldFilterCard";
import { getAllowedSortOptions } from "./SortAndFilterTab.utils";

const SortAndFilterTab = () => {
  const { data: availableFilters, isInitialLoading } = AvailableTicketFilters();
  const { data: publicConfig } = usePublicConfig();
  const { currentUser } = useDamagePreventionAuth();
  const {
    sortOrder,
    filters: filtersData,
    excludeFilters,
    resetFilters,
    resetOrder,
    setSortOrder,
    addFilter,
    removeFilter,
    replaceFilter,
    toggleSortOrderDirection,
  } = useTicketViewEditor();

  // TODO: Ticket status workaround
  // Note: There are other areas with the same workaround please search by the TODO
  // Temporary 🔨 to hide ticket "stage" filter
  // Ideally this should have been done on the BE for more details check: https://urbint.atlassian.net/browse/DPAPP-846
  const ignoredFilters = ["status"];
  const filters = filtersData?.filter(
    (item) => !ignoredFilters.includes(item.field)
  );
  // --------------------------------------------------------- //

  const availableFilterOpts = useMemo(() => {
    const usedFilters = new Set(filters.map((x) => x.field) || []);
    if (!availableFilters) {
      return undefined;
    }
    return getKeys(availableFilters)
      .filter((x) => !usedFilters.has(x))
      .sort()
      .map((key) => ({
        label: getFieldLabel(key),
        value: availableFilters[key]!,
        dataTestId: "filter-option",
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [availableFilters, filters]);

  const onSortOrderChange = useCallback(
    (field) => {
      setSortOrder({
        field,
        direction: SortOrderDirection.DESC,
      });
    },
    [setSortOrder]
  );

  return (
    <div className="mb-16 space-y-8">
      <FilterDialogSection title="SORT" onReset={resetOrder}>
        <FilterDialogCard className="flex items-center space-x-3">
          <div className="flex-grow">
            <DropdownSelect
              options={getAllowedSortOptions(publicConfig, currentUser)}
              value={sortOrder && sortOrder.field}
              className="border rounded"
              onChange={onSortOrderChange}
            />
          </div>
          <button
            type="button"
            onClick={toggleSortOrderDirection}
            className="flex items-center justify-center w-8 h-8 text-xl text-black rounded-full icon-semibold text-opacity-66 bg-blue-50"
          >
            <IonIcon
              className={`transition duration-100 transform-gpu
                    ${
                      sortOrder?.direction === SortOrderDirection.ASC &&
                      "-rotate-180"
                    }`}
              icon={arrowDownOutline}
            />
          </button>
        </FilterDialogCard>
      </FilterDialogSection>
      <FilterDialogSection
        title="FILTERS"
        addOptions={availableFilterOpts}
        onAdd={addFilter}
        onReset={resetFilters}
        dataTestId="filters-section"
      >
        {isInitialLoading && (
          <div className="flex items-center justify-center my-8">
            <Spinner />
            <p className="ml-2 text-neutral-shade-tertiary">
              Loading filters, please wait...
            </p>
          </div>
        )}
        {(!filters || filters.length === 0) && (
          <div className="flex items-center justify-center h-20 font-semibold text-black bg-gray-100 border-4 border-dashed rounded text-opacity-33 text-md">
            No filters currently selected
          </div>
        )}

        {availableFilters &&
          filters?.map((filter) => (
            <FilterDialogFieldFilterCard
              key={filter.field}
              onChange={(newFilter, newExcludeFilter) =>
                replaceFilter(
                  { field: filter.field },
                  newFilter,
                  newExcludeFilter
                )
              }
              filter={filter}
              excludeFilter={excludeFilters.find(
                (excludeFilter) => filter.field === excludeFilter.field
              )}
              definition={availableFilters[filter.field]!}
              formatCasing={filter.field !== "member_codes"}
              onClose={() => removeFilter({ field: filter.field })}
            />
          ))}
      </FilterDialogSection>
    </div>
  );
};

export { SortAndFilterTab };
