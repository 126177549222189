import type { DamagePotential, ImpactPotential, TicketVersion } from "@/models";
import type { TicketRisks } from "./SummaryCard.types";
import { startCase } from "@/format";
import { DamageThreatModalContent } from "./DamageThreatModalContent";
import { ImpactModalContent } from "./ImpactModalContent";
import { DamageThreatText, ImpactText } from "./SummaryCard.constants";

enum RISK_NAME {
  DAMAGE_THREAT = "Damage threat",
  IMPACT = "Impact",
}

const getTicketLocation = (ticketData?: TicketVersion) => {
  const {
    excavationStreetName,
    excavationStreetNumber,
    excavationCity,
    excavationState,
  } = ticketData || {};
  const streetName = startCase(excavationStreetName);
  const streetNum = excavationStreetNumber;
  const city = excavationCity;
  const state = excavationState?.toUpperCase();

  return {
    address: [streetNum, streetName].filter(Boolean).join(" "),
    cityState: [startCase(city), state].filter(Boolean).join(", "),
  };
};

const getTicketRisks = (
  damagePotential: {
    risk: DamagePotential;
    isEnable: boolean;
  },
  impactPotential: {
    risk: ImpactPotential;
    isEnable: boolean;
  }
): TicketRisks[] => [
  {
    riskName: RISK_NAME.DAMAGE_THREAT,
    riskBadge: damagePotential.risk,
    modalTitle: "Damage Threat - Risk Insights",
    footerText: DamageThreatText,
    modalContent: DamageThreatModalContent,
    shouldRenderRiskModal: damagePotential.isEnable,
  },
  {
    riskName: RISK_NAME.IMPACT,
    riskBadge: impactPotential.risk,
    modalTitle: "Impact - Insights",
    footerText: ImpactText,
    modalContent: ImpactModalContent,
    shouldRenderRiskModal: impactPotential.isEnable,
  },
];

export { getTicketLocation, getTicketRisks, RISK_NAME };
