import type { AddTaskModalProps } from "./AddTaskModal.types";
import type { TaskForm } from "@/components/Modals/TaskModal/TaskModal.types";
import type { TaskSerializer } from "@/utils/damage-prevention";
import { useMemo, useState } from "react";
import { TaskModal } from "@/components/Modals/";
import {
  useAssignableUsersForTaskTypes,
  useCreateTask,
  useResponseForm,
  useTaskTypes,
  prefetchSingleTaskInformation,
} from "@/api";
import { useToasts } from "@/components/Toasts";
import { Task } from "@/models";
import { formValid } from "./AddTaskModal.utils";

const AddTaskModal = ({ isOpen, onClose, ticket }: AddTaskModalProps) => {
  if (!ticket) {
    return null;
  }

  const [form, setForm] = useState<TaskForm>({});
  const [loading, setLoading] = useState(false);

  const { addToast } = useToasts();
  const createTask = useCreateTask();

  const { id: ticketId, memberCodes } = ticket;

  const reset = () => {
    setForm({});
  };

  const handleSubmit = () => {
    if (!formValid(form) || !ticket) return;
    setLoading(true);
    createTask.mutate(
      {
        task_type: form.taskTypeId!,
        assignee: form.assigneeId,
        member_code: form.memberCodeId,
        ticket: ticketId,
      },
      {
        onSuccess: (task: TaskSerializer) => {
          setLoading(false);
          addToast(`Task successfully created.`, "success");
          prefetchSingleTaskInformation(new Task(task));
          onClose();
        },
        onError: () => {
          setLoading(false);
          addToast(`Failed to create task.`, "error");
          onClose();
        },
      }
    );
  };

  const { data: taskTypes } = useTaskTypes();
  const taskTypeOptions = useMemo(
    () => taskTypes?.map(({ id, name }) => ({ value: id, label: name })),
    [taskTypes]
  );
  const selectedTaskType = taskTypes?.find((t) => t.id === form.taskTypeId);
  const assignableUsers = useAssignableUsersForTaskTypes(
    selectedTaskType ? [selectedTaskType] : []
  );

  const { data: responseFormForSelectedTask } = useResponseForm(
    // TODO: Fix no-non-null-asserted-optional-chain
    selectedTaskType?.responseFormId!,
    { enabled: selectedTaskType !== undefined }
  );

  const memberCodeOptions = useMemo(() => {
    if (responseFormForSelectedTask === undefined) return [];
    if (responseFormForSelectedTask.hasMultiPRQuestion) return [];
    return memberCodes?.map(({ key, id }) => ({ value: id, label: key }));
  }, [memberCodes, responseFormForSelectedTask]);

  const title = `Add task`;

  return (
    <TaskModal
      title={title}
      actionTitle="Add task"
      isOpen={isOpen}
      isLoading={loading}
      isValid={formValid(form)}
      taskTypeOptions={taskTypeOptions}
      assigneeOptions={assignableUsers}
      memberCodeOptions={memberCodeOptions}
      value={form}
      onChange={setForm}
      afterClose={reset}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  );
};

export { AddTaskModal };
