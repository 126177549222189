import type { UseQueryOptions } from "@tanstack/react-query";
import type { AutomationEngineRuleSerializer } from "@/utils/damage-prevention";
import { useQuery } from "@tanstack/react-query";
import { AutoAssignmentRule } from "@/models";
import { request, useDefaultFetchHeaders } from "@/api/helpers";

const autoAssignmentIdent = () => ["autoAssignmentRules"];

const fetchAutoAssignmentRules = (headers: HeadersInit) =>
  request<AutomationEngineRuleSerializer[]>("/api/rules", {
    method: "GET",
    headers,
  }).then((res) => res.map((r) => new AutoAssignmentRule(r)));

const useAutoAssignmentRules = (
  opts?: UseQueryOptions<AutoAssignmentRule[]>
) => {
  const headers = useDefaultFetchHeaders();
  return useQuery<AutoAssignmentRule[]>(
    autoAssignmentIdent(),
    () => fetchAutoAssignmentRules(headers),
    opts
  );
};
export { autoAssignmentIdent, useAutoAssignmentRules };
