import type { ThumbnailProps } from "./Thumbnail.types";
import { useState } from "react";
import classNames from "classnames";
import { Icon } from "@urbint/silica";
import { FileType } from "@/hooks/useFileInput/useFileInput";
import { useOnlineStatus } from "@/hooks";
import { DeleteOverlay } from "./Elements/DeleteOverlay";
import { Video } from "./Elements/Video";

const Thumbnail = ({
  url,
  alt,
  as: Component = "div",
  allowDelete = false,
  isLoading = false,
  onDeleteClick,
  onClick,
  type = FileType.IMAGES,
}: ThumbnailProps) => {
  const [thumbHover, setThumbHover] = useState(false);
  const [deleteHover, setDeleteHover] = useState(false);
  const isOnline = useOnlineStatus();

  return (
    <Component
      onMouseEnter={() => setThumbHover(true)}
      onMouseLeave={() => setThumbHover(false)}
      onClick={() => {
        // Prevents opening the gallery if the user is offline and attachments are not rendered
        if (!isOnline) return;

        onClick?.();
      }}
      data-testid="thumbnail_Component"
      className={classNames(
        "rounded overflow-hidden w-13 sm:w-18 h-13 sm:h-18 relative cursor-pointer border-solid border border-transparent transition-all",
        {
          "border-data-red-30": deleteHover,
        }
      )}
    >
      {isOnline && allowDelete && !isLoading && (
        <DeleteOverlay
          thumbHover={thumbHover}
          setDeleteHover={setDeleteHover}
          onDeleteClick={onDeleteClick}
          deleteHover={deleteHover}
        />
      )}
      {type === FileType.VIDEO && <Video />}
      {isOnline ? (
        <img
          src={url}
          alt={alt}
          className={classNames("object-cover w-full h-full z-0 hover:", {
            "blur-[1px]": thumbHover,
          })}
        />
      ) : (
        <div className="w-full h-full bg-surfaces-overlay absolute top-0 left-0 z-50 flex justify-center items-center">
          <Icon
            name="urbint"
            className="animate-pulse text-brand-navy text-3xl"
          />
        </div>
      )}
    </Component>
  );
};

export { Thumbnail };
