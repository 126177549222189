import type { UseQueryOptions } from "@tanstack/react-query";
import type {
  NonTicketedEventSummarySerializer,
  NonTicketedEventDetailsSerializer,
} from "@/utils/damage-prevention";
import type { FormSection } from "@/components/FormBuilder";
import type { TaskStatus } from "@/models";
import type { FiltersData } from "@/pages/EventsPage/EventFilters";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { UrbintApi } from "@/utils/UrbintApi";
import { handleApiResponse } from "@/api/helpers";
import { queryClient } from "@/api/client";

const api = new UrbintApi();

type NonTicketedEventTypeSummary = {
  id: number;
  name: string;
};

type UserSummary = {
  id: number;
  username: string;
  displayName: string;
  email?: string;
};

type NonTicketedEventSummary = {
  id: number;
  eventType: NonTicketedEventTypeSummary;
  date: Date;
  assignee?: UserSummary;
  status: TaskStatus;
  location?: string;
};

type NonTicketedEventDetail = NonTicketedEventSummary & {
  form: FormSection;
  formSubmittedAt?: Date;
};

const parseNonTicketedEventSummary = (
  json: NonTicketedEventSummarySerializer
): NonTicketedEventSummary => ({
  id: json.id,
  eventType: json.event_type,
  date: new Date(json.date),
  status: json.status,
  location: json.location ?? undefined,
  assignee: json.assignee && {
    id: json.assignee.id,
    displayName: json.assignee.display_name,
    username: json.assignee.username,
    email: json.assignee.email,
  },
});

const parseNonTicketedEventDetail = (
  json: NonTicketedEventDetailsSerializer
): NonTicketedEventDetail => ({
  ...parseNonTicketedEventSummary(json),
  form: json.form,
  formSubmittedAt: json.form_submitted_at
    ? new Date(json.form_submitted_at)
    : undefined,
});

const fetchNonTicketedEventsById = (id: number) =>
  api
    .getOne({ endPoint: "non_ticketed_events", id })
    .then(handleApiResponse)
    .then(parseNonTicketedEventDetail);

const fetchNonTicketedEvents = (
  filters: FiltersData,
  pageNumber = 1,
  search = ""
) =>
  api
    .getPage<NonTicketedEventSummarySerializer>({
      endPoint: "non_ticketed_events",
      page: pageNumber,
      queryParams: {
        ...(search && { search: search.toString() }),
        ...(filters && JSON.parse(JSON.stringify(filters))),
      },
    })
    .then(handleApiResponse)
    .then((response) => ({
      hasNext: response.next,
      events: response?.results.map(parseNonTicketedEventSummary),
    }));

const nonTicketedEventsIdent = ({
  id,
  pageNumber = 1,
  search = "",
}: {
  id?: number;
  pageNumber?: number;
  search?: string;
}) =>
  id === undefined
    ? ["non_ticketed_events", pageNumber, search]
    : ["non_ticketed_events", id, pageNumber, search];

const useNonTicketedEventsById = (
  id: number,
  opts?: UseQueryOptions<
    NonTicketedEventDetail,
    unknown,
    NonTicketedEventDetail,
    (string | number)[]
  >
) =>
  useQuery(
    nonTicketedEventsIdent({ id }),
    () => fetchNonTicketedEventsById(id),
    opts
  );

const invalidateNonTicketedEventsById = (id: number) =>
  queryClient.invalidateQueries(nonTicketedEventsIdent({ id }));

const setNonTicketedEventData = (data: NonTicketedEventDetail) => {
  queryClient.setQueryData(nonTicketedEventsIdent({ id: data.id }), data);
  const oldEvents: NonTicketedEventSummary[] | undefined =
    queryClient.getQueryData(nonTicketedEventsIdent({}));
  if (oldEvents) {
    const oldIx = oldEvents.findIndex((x) => x.id === data.id);
    if (oldIx !== -1) {
      const newEvents = [...oldEvents];
      newEvents.splice(oldIx, 1, data);
      queryClient.setQueryData(nonTicketedEventsIdent({}), newEvents);
    }
  }
};

const useInfiniteNonTicketedEvents = (
  search: string,
  searchFilters: FiltersData
) =>
  useInfiniteQuery(
    ["non_ticketed_events", search, searchFilters],
    ({ pageParam = 1 }) =>
      fetchNonTicketedEvents(searchFilters, pageParam, search),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage?.hasNext) {
          // Extract the number of the next page /?page=NUMBER
          // "http://strickland-op.dp.local.urbinternal.com:7777/api/non_ticketed_events/?page=2"
          const url = new URL(lastPage.hasNext);
          const urlParams = new URLSearchParams(url.search);
          const page = urlParams.get("page");

          return page;
        }
        return undefined;
      },
    }
  );

const invalidateInfiniteNonTicketedEvents = () =>
  queryClient.invalidateQueries(["non_ticketed_events"]);

export type {
  NonTicketedEventTypeSummary,
  UserSummary,
  NonTicketedEventSummary,
  NonTicketedEventDetail,
};

export {
  parseNonTicketedEventDetail,
  nonTicketedEventsIdent,
  useNonTicketedEventsById,
  invalidateNonTicketedEventsById,
  setNonTicketedEventData,
  useInfiniteNonTicketedEvents,
  invalidateInfiniteNonTicketedEvents,
};
