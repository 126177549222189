import type { UploadButtonProps } from "./UploadButton.types";
import { useCallback } from "react";
import classNames from "classnames";
import { useFileInput } from "@/hooks/useFileInput";
import { useToasts } from "@/components/Toasts";
import {
  useUploadNonTicketedEventAttachment,
  useUploadTaskAttachment,
} from "@/api";
import { useOnlineStatus } from "@/hooks";
import {
  getAttachmentType,
  isAttachmentFromNonTicketedEvent,
  isAttachmentFromTicket,
} from "../../AttachmentsSection.utils";
import { MAX_ATTACHMENT_FILE_SIZE } from "./UploadButton.constants";

const UploadButton = ({
  attachmentType,
  icon,
  label,
  task,
  className,
  type,
  customClickEvent,
  onInvalidAttachmentSize,
}: UploadButtonProps) => {
  const getFiles = useFileInput(type);
  const { addToast, dismissToast } = useToasts();
  const attachment = getAttachmentType(attachmentType);
  const { mutateAsync: uploadNonTicketedEventAttachments } =
    useUploadNonTicketedEventAttachment();
  const { mutateAsync: uploadTaskAttachment } = useUploadTaskAttachment();

  // --- Offline Support --- //
  const isOnline = useOnlineStatus();

  const onAttachFile = useCallback(async () => {
    customClickEvent?.();
    try {
      const stagedAttachments: File[] = await getFiles();
      const maxSizeReached = stagedAttachments.some(
        (file: File) => file.size > MAX_ATTACHMENT_FILE_SIZE
      );

      if (maxSizeReached) {
        // show warning dialog
        onInvalidAttachmentSize?.();
        return;
      }

      let uploadToastID = "";

      try {
        uploadToastID = addToast(
          `${
            isOnline
              ? "Uploading attachments... Please stay on this page."
              : "Attachments will be uploaded when you come back online..."
          }`,
          "info"
        );

        if (isAttachmentFromTicket(attachment)) {
          await uploadTaskAttachment({
            attachment: stagedAttachments,
            task: task!,
          });
        } else if (isAttachmentFromNonTicketedEvent(attachment)) {
          await uploadNonTicketedEventAttachments({
            attachment: stagedAttachments,
            eventId: attachment.eventId,
          });
        }

        dismissToast(uploadToastID);
        addToast("Upload completed.", "success");
      } catch (err) {
        dismissToast(uploadToastID);
        addToast(
          err instanceof Error ? err.message : "Error uploading attachments",
          "error"
        );
      }
    } catch (err) {
      // This happens when we the `stagedDocuments` call was aborted (eg. user clicks cancel)
    }
  }, [
    addToast,
    dismissToast,
    task,
    uploadTaskAttachment,
    uploadNonTicketedEventAttachments,
    getFiles,
    customClickEvent,
    isOnline,
  ]);
  return (
    <button
      type="button"
      onClick={onAttachFile}
      className={classNames(
        "flex items-center p-2.5 text-neutral-shade-secondary leading-tight",
        className
      )}
    >
      {icon && icon}
      {label && label}
    </button>
  );
};

export { UploadButton };
