import type { UseQueryOptions } from "@tanstack/react-query";
import type { Task, TaskType, Workflow } from "@/models";
import { useQuery } from "@tanstack/react-query";
import { groupByKV, indexBy, indexByKV } from "@/utils";
import { queryClient } from "@/api/client";
import {
  prefetchTasksForTicket,
  prefetchTaskTypes,
  prefetchWorkflows,
  useTasksForTicket,
  useTaskTypes,
  useWorkflows,
} from "@/api";

const tasksGroupedByWorkflowBaseIdent = "ticket/tasks-grouped-by-workflow";

const tasksGroupedByWorkflowIdent = (ticketId: number, tasks?: Task[]) => [
  tasksGroupedByWorkflowBaseIdent,
  ticketId,
  tasks,
];

const groupTasksByWorkflow = (
  tasks: Task[],
  workflows: Workflow[],
  taskTypes: TaskType[]
): Map<Workflow, Task[]> => {
  const workflowsById = indexBy(workflows, (workflow) => workflow.id);
  const taskTypeToWorkflow = indexByKV(taskTypes, (taskType) => [
    taskType.id,
    taskType.workflowId,
  ]);
  const workflowToTasks = groupByKV(tasks, (task) => {
    const workflowId = taskTypeToWorkflow.get(task.taskTypeId);
    const workflow = workflowsById.get(workflowId ?? -1);
    return workflow && [workflow, task];
  });
  return indexByKV(workflows, (workflow) => [
    workflow,
    workflowToTasks.get(workflow) || [],
  ]);
};

const useTasksGroupedByWorkflowForTicket = (
  ticketId: number,
  opts?: UseQueryOptions<
    Map<Workflow, Task[]>,
    unknown,
    Map<Workflow, Task[]>,
    Array<string | number | Task[] | undefined>
  >
) => {
  const enabled = opts ? opts.enabled : true;
  const tasks = useTasksForTicket(ticketId, { enabled });
  const workflows = useWorkflows({ enabled });
  const taskTypes = useTaskTypes({ enabled });
  const isDataLoaded =
    tasks.isFetched && workflows.isFetched && taskTypes.isFetched;
  const isError = tasks.isError || workflows.isError || taskTypes.isError;

  const res = useQuery(
    tasksGroupedByWorkflowIdent(ticketId, tasks?.data),
    () => {
      if (isError) {
        throw tasks.error || workflows.error || taskTypes.error;
      }
      return groupTasksByWorkflow(
        tasks.data || [],
        workflows.data || [],
        taskTypes.data || []
      );
    },
    {
      ...opts,
      enabled:
        (isDataLoaded || isError) &&
        (typeof opts?.enabled === "undefined" ? true : opts.enabled),
    }
  );
  return { ...res, isFetched: isDataLoaded && res.isFetched, isError };
};

const prefecthTasksGroupedByWorkflowForTicket = (ticketId: number) => {
  prefetchTasksForTicket(ticketId);
  prefetchWorkflows();
  prefetchTaskTypes();
};

const invalidateUseTasksGroupedByWorkflowForTicket = (ticketId: number) => {
  queryClient.invalidateQueries(tasksGroupedByWorkflowIdent(ticketId));
};

export {
  useTasksGroupedByWorkflowForTicket,
  invalidateUseTasksGroupedByWorkflowForTicket,
  tasksGroupedByWorkflowBaseIdent,
  prefecthTasksGroupedByWorkflowForTicket,
};
