import type { MapboxEvent, MapRef } from "react-map-gl";
import type { Feature, GeoJsonProperties, Geometry } from "geojson";
import type { MapProps } from "./Map.types";
import { Layer, Source } from "react-map-gl";
import { useEffect, useMemo, useRef, useState } from "react";
import { useResponsive } from "@/hooks";
import { usePublicConfig } from "@/api";
import { Map as MapComponent } from "@/components/Map";
import {
  MAP_STYLE_SATELLITE,
  MAP_STYLE_STREET,
} from "@/components/Map/Map.utils";
import { GeoAssetsLayer } from "@/components/Map/Layers";
import {
  MapControls,
  MapFullscreenControl,
  MapZoomControls,
} from "@/components/Map/Controls";
import { TicketMarker } from "@/components/Map/Markers/TicketMarker";
import { useLayers } from "@/hooks/useLayers";
import { MeasureTool, MeasureToolControls } from "@/components/MeasureTool";
import { MapLayerControl } from "./MapLayerControl";
import { LNG_DEVIATION } from "./Map.constants";

const Map = ({ ticket }: MapProps) => {
  const { isLg, isSm } = useResponsive();
  const mapRef = useRef<MapRef>(null);
  const [isInMeasureMode, setIsInMesureMode] = useState<boolean>(false);

  const { data: publicConfig } = usePublicConfig();

  const { layers, toggleLayer, setLayerEnabled } = useLayers({
    satellite: { enabled: true, visible: false },
    utilityAssets: { enabled: false, visible: true },
    electricDistributionAssets: { enabled: false, visible: true },
    fiberAssets: { enabled: false, visible: true },
    electricTransmissionAssets: { enabled: false, visible: true },
    telcoAssets: { enabled: false, visible: true },
    assetBuffers: { enabled: false, visible: true },
    networkAssets: { enabled: false, visible: true },
    damages: { enabled: false, visible: true },
    annotations: { enabled: false, visible: true },
  });

  const ticketPointLat = ticket?.centroid?.lat;
  const ticketPointLng = ticket?.centroid?.lng;

  useEffect(() => {
    if (publicConfig?.mapLayerToggle.utility)
      setLayerEnabled("utilityAssets", true);
    if (publicConfig?.mapLayerToggle.electricDistribution)
      setLayerEnabled("electricDistributionAssets", true);
    if (publicConfig?.mapLayerToggle.electricTransmission)
      setLayerEnabled("electricTransmissionAssets", true);
    if (publicConfig?.mapLayerToggle.fiber)
      setLayerEnabled("fiberAssets", true);
    if (publicConfig?.mapLayerToggle.network)
      setLayerEnabled("networkAssets", true);
    if (publicConfig?.mapLayerToggle.telco)
      setLayerEnabled("telcoAssets", true);
    if (publicConfig?.mapLayerToggle.buffers)
      setLayerEnabled("assetBuffers", true);
    if (publicConfig?.mapLayerToggle.damages) setLayerEnabled("damages", true);
    if (publicConfig?.mapLayerToggle.annotations)
      setLayerEnabled("annotations", true);
  }, [publicConfig, setLayerEnabled]);

  const geojson = useMemo<Feature<Geometry, GeoJsonProperties> | undefined>(
    () =>
      ticket?.workArea && {
        type: "Feature",
        properties: {},
        geometry: { type: "Polygon", coordinates: ticket.workArea ?? [] },
      },
    [ticket?.workArea]
  );

  useEffect(() => {
    if (mapRef.current && ticketPointLat && ticketPointLng) {
      mapRef.current.flyTo({
        center: [ticketPointLng - LNG_DEVIATION, ticketPointLat],
        zoom: 14,
      });
    }
  }, [ticket?.centroid]);

  if (!ticketPointLat || !ticketPointLng) return null;

  const onMapLoad = (mapOnLoadEvent: MapboxEvent) => {
    mapOnLoadEvent.target.resize();
  };

  return (
    <div className="w-full h-full">
      <MapComponent
        onLoad={onMapLoad}
        style={
          layers.satellite.visible ? MAP_STYLE_SATELLITE : MAP_STYLE_STREET
        }
        mapRef={mapRef}
        initialViewState={{
          latitude: ticketPointLat,
          longitude: ticketPointLng - LNG_DEVIATION,
          zoom: 14,
        }}
      >
        {geojson && (
          <>
            <Source id="workarea" data={geojson} type="geojson" />
            <Layer
              source="workarea"
              type="fill"
              paint={{ "fill-color": "#0080ff", "fill-opacity": 0.1 }}
            />
            <Layer
              source="workarea"
              type="line"
              paint={{ "line-color": "#0080ff", "line-width": 1 }}
            />
          </>
        )}
        <GeoAssetsLayer
          utilityShown={layers.utilityAssets.visible}
          electricDistributionShown={layers.electricDistributionAssets.visible}
          electricTransmissionShown={layers.electricTransmissionAssets.visible}
          fiberShown={layers.fiberAssets.visible}
          networkShown={layers.networkAssets.visible}
          telcoShown={layers.telcoAssets.visible}
          buffersShown={layers.assetBuffers.visible}
          damagesShown={layers.damages.visible}
          annotationsShown={layers.annotations.visible}
        />
        {ticket?.centroid && (
          <TicketMarker
            id={ticket.id}
            latitude={ticketPointLat}
            longitude={ticketPointLng}
            scoreType="damage"
            damage={ticket?.latestVersion?.damagePotential}
            impact={ticket?.latestVersion?.impactPotential}
          />
        )}
        {isInMeasureMode && (
          <MeasureTool mapRef={mapRef} isActive={isInMeasureMode} />
        )}
        <MapControls className={isSm ? "translate-y-8" : ""}>
          <MapZoomControls />
          {isLg && <MapFullscreenControl />}
          <MapLayerControl mapLayers={layers} toggleMapLayers={toggleLayer} />
          {publicConfig?.enableMeasuringTool && (
            <MeasureToolControls
              isInMeasureMode={isInMeasureMode}
              setIsInMesureMode={setIsInMesureMode}
              subMenuAlignment="bottom"
            />
          )}
        </MapControls>
      </MapComponent>
    </div>
  );
};

export { Map };
