import type { TicketContentProps } from "./TicketContent.types";
import { IonIcon } from "@ionic/react";
import cls from "classnames";
import { ellipse } from "ionicons/icons";
import { Icon } from "@urbint/silica";
import {
  formatRelativeTime,
  riskColor,
  riskLabel,
  safeToUpperCase,
  startCase,
} from "@/format";
import { Tooltip } from "@/common/Tooltip";
import { usePrefetch } from "@/components/PrefetchOfflineData";
import { truncateTextClass } from "./TicketContent.constants";

/**
 * Formats and displays information about a ticket.
 *
 * Since this component could be used to display any version of a ticket, you'll
 * be expected to pass in the version you want. Generally, you should be able
 * to provide the ticket number and then spread the version you want to display
 * like so:
 *
 * ```tsx
 * const Foo = ({ticket}: {ticket: FlexTicket}) => (
 *  <TicketCard ticket={ticket} />
 * );
 * ```
 */

const TicketContent = ({ ticket }: TicketContentProps) => {
  const { checkTicketPrefetched } = usePrefetch();
  const {
    contacts = [],
    damagePotential,
    excavationCity = "",
    excavationState = "",
    excavationStreetName = "",
    excavationStreetNumber = "",
    excavationWorkType = "",
    type = "",
    workStartDate,
  } = ticket.latestVersion ?? {};
  const ticketNumber = ticket.number ?? "";

  const primaryContact = contacts.find((x) => x.primary);
  const excavatorName = startCase(primaryContact?.companyName);

  const startDate = workStartDate ? new Date(workStartDate) : undefined;
  const startLabel =
    startDate && (startDate.getTime() > Date.now() ? "Starts" : "Started");

  const workType = startCase(excavationWorkType);

  const streetName = startCase(excavationStreetName);
  const streetNum = excavationStreetNumber;
  const address = [streetNum, streetName].filter(Boolean).join(" ");

  const city = startCase(excavationCity);
  const state = safeToUpperCase(excavationState);
  const cityState = [city, state].filter(Boolean).join(", ");

  const workText =
    workType && excavatorName
      ? `${workType} by ${excavatorName}`
      : `${workType}${excavatorName}`;

  const getDamagePreventionPotentialLabel = () => {
    let label = safeToUpperCase(riskLabel(damagePotential), "UNKNOWN");
    if (label === "MISSING DATA") {
      label = "UNKNOWN";
    }
    return label;
  };

  const damagePotentialLabel = getDamagePreventionPotentialLabel();

  const isTicketPrefetched = checkTicketPrefetched(ticket.id);

  return (
    <div className="flex flex-col flex-1 text-sm">
      <div className="flex justify-between font-semibold leading-tight flex-col">
        <div className="flex items-center gap-1">
          <p
            className={cls(
              damagePotential ? riskColor(damagePotential) : "text-gray-500",
              "whitespace-nowrap"
            )}
          >
            {`${damagePotentialLabel} RISK`}
          </p>
          <p className="text-gray-300">|</p>
          <p
            className="text-gray-500 whitespace-nowrap"
            data-testid="ticket-number"
          >
            {ticketNumber ? `#${ticketNumber}` : null}
          </p>
          {isTicketPrefetched && (
            <Tooltip title="Ticket is ready for offline">
              <Icon
                name="cloud_check"
                className="text-xs text-brand-blue mx-1"
              />
            </Tooltip>
          )}
        </div>
        <div className="flex items-center gap-2">
          <p
            className={`flex-shrink ${
              ticket.isEmergency && "text-red-500"
            } ${truncateTextClass}`}
          >
            {safeToUpperCase(type)}
            <span className="italic">{ticket.isEmergency ? "!!" : null}</span>
          </p>
        </div>
      </div>
      <div className="flex flex-col items-start">
        <p
          className={`font-semibold text-brand-50 text-lg ${truncateTextClass}`}
        >
          {address}
        </p>
        <div className="flex flex-row items-center space-x-1">
          <p className={`${truncateTextClass}`}>{cityState}</p>
          {startLabel && (
            <IonIcon
              icon={ellipse}
              className="text-neutral-shade-disabled text-[5px]"
            />
          )}
          <p className={truncateTextClass}>
            {startLabel} {startDate && formatRelativeTime(startDate)}
          </p>
        </div>
      </div>
      <p className="line-clamp-3">{workText}</p>
    </div>
  );
};

export { TicketContent };
