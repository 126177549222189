import type { UseQueryOptions } from "@tanstack/react-query";
import type { TicketSerializer } from "@/utils/damage-prevention";
import { useQuery } from "@tanstack/react-query";
import { Ticket } from "@/models";
import { UrbintApi } from "@/utils/UrbintApi";
import { handleApiResponse } from "@/api/helpers";

const api = new UrbintApi();

const relatedTicketsIdent = (ticketId: number) => ["related-tickets", ticketId];

const fetchRelatedTickets = (ticketId: number) =>
  api
    .getMany<TicketSerializer>({
      endPoint: "tickets",
      action: "relations",
      id: ticketId,
    })
    .then(handleApiResponse)
    .then((res) => res?.map((r) => new Ticket(r)));

const useRelatedTickets = (
  ticketId: number,
  opts?: UseQueryOptions<Ticket[]>
) =>
  useQuery<Ticket[]>(
    relatedTicketsIdent(ticketId),
    () => fetchRelatedTickets(ticketId),
    opts
  );

export { useRelatedTickets };
