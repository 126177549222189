import type { UseQueryOptions } from "@tanstack/react-query";
import type { ReportsConfigSerializer } from "@/utils/damage-prevention";
import { useQuery } from "@tanstack/react-query";
import { ReportsConfig } from "@/models";
import { UrbintApi } from "@/utils/UrbintApi";
import { handleApiResponse } from "@/api/helpers";

type IReturn = ReportsConfig | undefined;

const api = new UrbintApi();
const reportsConfigIdent = () => ["reports_config"];

const fetchReportsConfig = () =>
  api
    .getMany<ReportsConfigSerializer>({
      endPoint: "reports_config",
    })
    .then(handleApiResponse)
    .then((res) => {
      const confs = res.map((r) => new ReportsConfig(r));
      // this endpoint should always return a list of 1, but just in case...
      return confs.length > 0 ? confs[0] : undefined;
    });

const useReportsConfig = (opts?: UseQueryOptions<IReturn>) =>
  useQuery<IReturn>(
    reportsConfigIdent(),
    () => fetchReportsConfig(),
    // this should never be refetched, or it will reload the reports iframe
    { ...opts, refetchInterval: false }
  );

export { reportsConfigIdent, useReportsConfig };
