import type { TaskSerializer } from "@/utils/damage-prevention";
import { useMutation } from "@tanstack/react-query";
import {
  invalidateUseFlexTicketQuery,
  invalidateUseFlexTicketsQuery,
  invalidateUseTasksForTicketQuery,
  invalidateUseTicketActivityQuery,
} from "@/api";
import { useDamagePreventionAuth } from "@/hooks";
import { Task } from "@/models";
import { getCookies } from "@/utils";

const bulkChangeAssignee = (
  body: ChangeAssigneePayload[],
  bearerToken: string
) => {
  const cookies = getCookies();
  return fetch("/api/tasks/bulk/", {
    method: "PATCH",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${bearerToken}`,
      Accept: "application/json",
      "X-CSRFToken": cookies.csrftoken,
    },
  })
    .then((res) => res.json())
    .then((x: TaskSerializer[]) => x.map((t) => new Task(t)));
};

interface ChangeAssigneePayload {
  id: number;
  assignee: number | null;
}

type BulkChangeAssigneeResponse = Array<Task>;

const useBulkChangeAssignee = () => {
  const { bearerToken } = useDamagePreventionAuth();

  return useMutation<
    BulkChangeAssigneeResponse,
    unknown,
    ChangeAssigneePayload[]
  >((tasks) => bulkChangeAssignee(tasks, bearerToken), {
    onSuccess: (resp) => {
      resp.forEach((t) => {
        invalidateUseTasksForTicketQuery(t.ticketId);
        invalidateUseTicketActivityQuery(t.ticketId);
        invalidateUseFlexTicketQuery(t.ticketId);
      });
      invalidateUseFlexTicketsQuery();
    },
  });
};

export type { BulkChangeAssigneeResponse };
export { useBulkChangeAssignee };
