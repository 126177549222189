import type { Activity } from "@/models";
import type { SelectOption } from "@/components/Select";
import { useState, useMemo } from "react";
import { ActivityTypes } from "@/pages/TicketPage/Activities/Activities.constants";

// Number of milliseconds during which we consider the activities performed by the same
// user in the same group.
const GROUP_TIME_THRESHOLD = 1000 * 60 * 3;

type SortOrder = "mostRecent" | "leastRecent";

const sortOptions: SelectOption<SortOrder>[] = [
  { label: "Most recent first", value: "mostRecent" },
  { label: "Most recent last", value: "leastRecent" },
];

type ActivityFilter = "all" | "comments" | "photos" | "system";
const filterOptions: SelectOption<ActivityFilter>[] = [
  { label: "All activity", value: "all" },
  { label: "Comments", value: "comments" },
  { label: "Attachments", value: "photos" },
  { label: "System activity", value: "system" },
];

type OrganizeTicketActivitiesAPI = {
  sortOptions: SelectOption<SortOrder>[];
  sortOrder: SortOrder;
  setSortOrder: (value: SortOrder) => void;
  setSelectedTaskId: (taskId: number | undefined) => void;
  filterOptions: SelectOption<ActivityFilter>[];
  filter: ActivityFilter;
  setFilter: (value: ActivityFilter) => void;
  groupedActivities: [Activity][];
  filteredActivities: Activity[];
  attachmentActivities: Activity[];
};

type OrganizeTicketActivitiesState = {
  taskId?: number;
  sortOrder: SortOrder;
  filter: ActivityFilter;
};

const useOrganizeTicketActivities = (
  activities?: Activity[]
): OrganizeTicketActivitiesAPI => {
  const [orgState, setOrgState] = useState<OrganizeTicketActivitiesState>({
    filter: "all",
    sortOrder: "mostRecent",
  });

  const filteredActivities = useMemo(() => {
    let filter: (x: Activity) => boolean;
    if (orgState.taskId === undefined) {
      switch (orgState.filter) {
        case "all":
          filter = () => true;
          break;
        case "comments":
          filter = (activity) =>
            activity.activityType === ActivityTypes.TICKET_COMMENT ||
            activity.activityType === ActivityTypes.TASK_COMMENT;
          break;
        case "photos":
          filter = (activity) =>
            activity.activityType === ActivityTypes.TASK_ATTACHMENT;
          break;
        case "system":
          filter = (activity) => activity.userId === undefined;
          break;
        // no default
      }
    } else {
      filter = (activity) => activity.taskId === orgState.taskId;
    }
    return (activities || [])
      .filter((x) => x.activityType !== ActivityTypes.IGNORE)
      .filter(filter)
      .sort((a, b) => b.date.valueOf() - a.date.valueOf());
  }, [activities, orgState.filter, orgState.taskId]);

  const groupedActivities = useMemo(() => {
    const groups = filteredActivities.reduce<[Activity][]>((acc, activity) => {
      const lastGroup = acc[acc.length - 1];
      const lastActivity = lastGroup && lastGroup[lastGroup?.length - 1];
      const newGroup =
        !lastActivity ||
        lastActivity.userId !== activity.userId ||
        lastActivity.date.valueOf() - activity.date.valueOf() >
          GROUP_TIME_THRESHOLD;

      // check if activity.objectId has been deleted or not
      const attachmentDeleted = !!activities?.find(
        (a: Activity) =>
          Number(a.objectId) === Number(activity.objectId) &&
          a.activityType === "task_attachment_deleted"
      );

      activity.serialize().attachmentDeleted = attachmentDeleted;
      if (newGroup) {
        acc.push([activity]);
      } else {
        lastGroup!.push(activity);
      }
      return acc;
    }, []);

    if (orgState.sortOrder === "leastRecent") {
      groups.reverse();
    }
    return groups;
  }, [orgState.sortOrder, filteredActivities, activities]);

  const attachmentActivities = useMemo(() => {
    const deletedIds = new Set(
      activities
        ?.filter((x) => x.activityType === "task_attachment_deleted")
        .map((x) => x.objectId)
    );

    return (
      activities?.filter(
        (x) =>
          (x.modelName === "damage_prevention.taskattachment" ||
            x.modelName === "damage_prevention.taskphoto") &&
          x.activityType === "task_attachment" &&
          !deletedIds.has(x.objectId)
      ) || []
    );
  }, [activities]);

  return useMemo(
    () => ({
      filter: orgState.filter,
      setFilter: (filter: ActivityFilter) =>
        setOrgState((newOrgState) => ({ ...newOrgState, filter })),
      setSelectedTaskId: (taskId: number | undefined) =>
        setOrgState((newOrgState) => ({ ...newOrgState, taskId })),
      filterOptions,
      sortOptions,
      groupedActivities,
      filteredActivities,
      attachmentActivities,
      sortOrder: orgState.sortOrder,
      setSortOrder: (sortOrder: SortOrder) =>
        setOrgState((newOrgState) => ({ ...newOrgState, sortOrder })),
    }),
    [
      setOrgState,
      orgState.sortOrder,
      orgState.filter,
      groupedActivities,
      attachmentActivities,
      filteredActivities,
    ]
  );
};

export { useOrganizeTicketActivities };
