import type { ActionBarRightOfflineProps } from "./ActionBarRightOffline.types";
import { useEffect } from "react";
import { Switch } from "@/components/Switch";
import { useTicketViewEditor } from "@/components/TicketViewEditorProvider";
import { useFlexTicketsCount } from "@/api";
import { pluralize } from "@/utils";
import { useTicketSelector } from "@/components/TicketSelectorProvider";

const ActionBarRightOffline = ({
  isShowingTasks,
  toggleIsShowingTasks,
  ticketCount,
  setThresholdToastShown,
}: ActionBarRightOfflineProps) => {
  const { selectMode, reset } = useTicketSelector();
  const { dirtyView, isInitialized } = useTicketViewEditor();
  const { isFetching: ticketsLoading } = useFlexTicketsCount(dirtyView, {
    enabled: isInitialized,
  });

  useEffect(() => {
    if (selectMode) {
      setThresholdToastShown(false);
      reset();
    }
  }, [selectMode]);

  return (
    <div className="w-full flex items-center justify-between pt-2 pb-3 px-3 sm:px-2 sm:py-0">
      <div className="flex items-center space-x-1">
        <p>{!ticketsLoading && pluralize(ticketCount, "ticket")}</p>
      </div>
      <Switch
        label="Show tasks"
        checked={isShowingTasks}
        onChange={() => toggleIsShowingTasks()}
        dataTestId="show-task-switch"
      />
    </div>
  );
};

export { ActionBarRightOffline };
