import type { TextInputProps } from "./TextInput.types";
import classnames from "classnames";

/**
 * @deprecated to be replaced with Field from Silica
 */
const TextInput = ({ onChangeText, className, ...props }: TextInputProps) => {
  const classes = classnames(
    "border-[1px]",
    "border-black",
    "border-opacity-[22%]",
    "border-solid",
    "font-sans",
    "px-3",
    "py-2",
    "rounded-[4px]",
    "text-base",
    "w-full",
    { "cursor-default": props.readOnly },
    className
  );
  return (
    <input
      {...props}
      onChange={(ev) => onChangeText(ev.target.value)}
      disabled={props.readOnly || props.disabled}
      className={classes}
    />
  );
};

export { TextInput };
