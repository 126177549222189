import type { UseQueryOptions } from "@tanstack/react-query";
import type { TicketSerializer } from "@/utils/damage-prevention";
import { useQuery } from "@tanstack/react-query";
import { Ticket } from "@/models";
import { UrbintApi } from "@/utils/UrbintApi";
import { queryClient } from "@/api/client";
import { handleApiResponse } from "@/api/helpers";
import { MAXIMUM_RETRIES } from "@/api/constants";

const api = new UrbintApi();

const ticketIdent = (ticketId: number, withResponseFormCount = false) => [
  "ticket",
  ticketId,
  withResponseFormCount,
];

const fetchTicket = (ticketId: number, withResponseFormCount = false) =>
  ticketId
    ? api
        .getOne<TicketSerializer>({
          endPoint: "tickets",
          id: ticketId,
          queryParams: {
            ...(withResponseFormCount && { count: "response_form_answers" }),
          },
        })
        .then(handleApiResponse)
        .then((r) => new Ticket(r))
    : undefined;

const useTicket = (
  ticketId: number,
  opts?: UseQueryOptions<Ticket | undefined>,
  withResponseFormCount = false
) =>
  useQuery<Ticket | undefined>(
    ticketIdent(ticketId, withResponseFormCount),
    () => fetchTicket(ticketId, withResponseFormCount),
    {
      ...opts,
      retry: MAXIMUM_RETRIES,
    }
  );
const prefetchTicket = (ticketId: number) =>
  queryClient.prefetchQuery({
    queryKey: ticketIdent(ticketId, true),
    queryFn: () => fetchTicket(ticketId, true),
    retry: MAXIMUM_RETRIES,
  });

const invalidateUseTicketQuery = (
  ticketId: number,
  withResponseFormCount = false
) =>
  queryClient.invalidateQueries(ticketIdent(ticketId, withResponseFormCount));

export {
  useTicket,
  invalidateUseTicketQuery,
  prefetchTicket,
  fetchTicket,
  ticketIdent,
};
