import type { FallbackProps } from "react-error-boundary";

import errorOutline from "@/assets/images/error_outline.svg";
import errorCrackCones from "@/assets/images/error-crack-cones.png";
import urbintWordMark from "@/assets/images/Urbint_Wordmark_Small.svg";

const Error = ({ error, resetErrorBoundary }: FallbackProps) => (
  <div className="flex flex-col max-h-[200px] max-w-[800px] md:flex-row md:gap-x-14 md:mb-44 md:p-0 p-8 ">
    <div className="md:w-1/2">
      <img src={urbintWordMark} alt="Urbint" />
      <div>
        <img
          src={errorCrackCones}
          alt="error-cones"
          className="w-full px-8 md:px-0"
        />
      </div>
    </div>
    <div className="md:w-1/2 pb-8">
      <p
        data-testid="error-message"
        className="font-mono text-neutral-shade-secondary text-sm mt-2 inline-flex items-center"
      >
        <img
          src={errorOutline}
          alt="error-outline"
          className="h-4 w-4 inline mr-2"
        />
        {error?.message ? error.message : "Services Unavailable"}
      </p>
      <h1 className="text-3xl mt-8">Issue detected.</h1>
      <p className="my-6">
        Unfortunately, it&apos;s on our end. Our team of expert engineers is
        working to resolve this issue as soon as possible. For updates, contact{" "}
        <a className="text-blue-700" href="mailto:support@urbint.com">
          support@urbint.com
        </a>
        .
      </p>
      <p className="my-6">
        <button
          type="button"
          onClick={resetErrorBoundary}
          className="bg-blue-700 hover:bg-blue-800 text-white shadow py-2 px-4 w-full rounded"
        >
          Reload the application
        </button>
      </p>
      <p>
        Sincerely,
        <br />
        the Urbint team.
      </p>
    </div>
  </div>
);

export { Error };
