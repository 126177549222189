import type { UseQueryOptions } from "@tanstack/react-query";
import type { ResponseFormSerializer } from "@/utils/damage-prevention";
import { useQuery } from "@tanstack/react-query";
import { ResponseForm } from "@/models";
import { UrbintApi } from "@/utils/UrbintApi";
import { queryClient } from "@/api/client";
import { handleApiResponse } from "@/api/helpers";

const api = new UrbintApi();

const responseFormsIdent = () => ["response_forms"];

const fetchResponseForms = () =>
  api
    .getPage<ResponseFormSerializer>({
      endPoint: "response_forms",
      page: 1,
    })
    .then(handleApiResponse)
    .then((res) => res.results.map((r) => new ResponseForm(r)));

const useResponseForms = (opts?: UseQueryOptions<ResponseForm[]>) =>
  useQuery<ResponseForm[]>(
    responseFormsIdent(),
    () => fetchResponseForms(),
    opts
  );

const prefetchResponseForms = () =>
  queryClient.prefetchQuery(responseFormsIdent(), fetchResponseForms);

export { useResponseForms, prefetchResponseForms, responseFormsIdent };
