import type { Notification } from "@/models";
import type { NotificationSerializer } from "@/utils/damage-prevention";
import type { KnownLensEndpointActions } from "@/utils/UrbintApi";
import { useMutation } from "@tanstack/react-query";
import { UrbintApi } from "@/utils/UrbintApi";
import { invalidateNotificationsQuery } from "@/api";

const api = new UrbintApi();

const updateNotification = ({
  notification: model,
  body,
  action,
}: IUseUpdateNotification) =>
  api.patch("notifications", JSON.stringify(body), String(model.id), action);

const readNotification = ({
  notification: model,
}: IuseMarkNotificationAsRead) =>
  api.patch("notifications", JSON.stringify({ read: true }), String(model.id));

//
// --- Export
//
interface IUseUpdateNotification {
  notification: Notification;
  body: Partial<NotificationSerializer>;
  action?: KnownLensEndpointActions;
}

interface IuseMarkNotificationAsRead {
  notification: Notification;
}

const useUpdateNotification = () => useMutation(updateNotification, {});

const useMarkNotificationAsRead = () =>
  useMutation(readNotification, {
    onSuccess: () => {
      // we could merge in the result here, but this is relatively cheap
      invalidateNotificationsQuery();
    },
  });

export { useUpdateNotification, useMarkNotificationAsRead };
