import type { ContactSerializer } from "@/utils/damage-prevention";
import { UrbintModel } from "@/utils/UrbintModel";
import { formatPhoneNumber } from "../format";

class Contact extends UrbintModel<ContactSerializer> {
  get id() {
    return 0;
  }

  get name() {
    return this.castString(this.data.name);
  }

  get companyName() {
    return this.castString(this.data.company_name);
  }

  get email() {
    return this.castString(this.data.email);
  }

  get phoneNumber() {
    return formatPhoneNumber(this.castString(this.data.phone_number));
  }

  get address() {
    return this.castString(this.data.address);
  }

  get primary() {
    return this.castBool(this.data.primary);
  }

  get onSite() {
    return this.castBool(this.data.on_site);
  }

  compare(instance: Contact) {
    return this.name.localeCompare(instance.name);
  }

  serialize(): ContactSerializer {
    return this.data;
  }
}

export { Contact };
