import { init, isInitialized } from "@fullstory/browser";
import { useEffect } from "react";
import { useDamagePreventionAuth } from "@/hooks";
import { useOpcos } from "@/api";
import { identifyUserSession } from "./FullStory.utils";
import { fullStoryNameSpace, fullStoryOrgId } from "./FullStory.constants";

const FullStory = () => {
  const { currentUser } = useDamagePreventionAuth();
  const { data: [tenantInfo] = [] } = useOpcos();
  const isFullstoryInitialized = isInitialized();

  useEffect(() => {
    if (tenantInfo && currentUser && isFullstoryInitialized) {
      identifyUserSession(currentUser, tenantInfo);
    }
  }, [tenantInfo, currentUser, isFullstoryInitialized]);

  if (!isFullstoryInitialized) {
    init({
      orgId: fullStoryOrgId,
      namespace: fullStoryNameSpace,
    });
  }

  return null;
};

export { FullStory };
