import type { NotificationIconProps } from "@/components/NotificationIcon/NotificationIcon.types";
import { IonIcon } from "@ionic/react";
import { notificationsOutline } from "ionicons/icons";

const NotificationIcon = ({ unreadCount, isMobile }: NotificationIconProps) => (
  <div className="flex pt-2 sm:pt-0">
    <IonIcon
      className="flex-grow w-6 h-6"
      icon={notificationsOutline}
      color={isMobile ? "" : "primary"}
    />
    {unreadCount ? (
      <div
        className="self-center w-1 h-1 rounded-full"
        style={{ backgroundColor: "var(--ion-color-primary)" }}
      />
    ) : null}
  </div>
);
export { NotificationIcon };
