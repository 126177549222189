import type { UseQueryOptions } from "@tanstack/react-query";
import type { EgressRouteSerializer } from "@/utils/damage-prevention";
import { useQuery } from "@tanstack/react-query";
import { EgressRoute } from "@/models";
import { UrbintApi } from "@/utils/UrbintApi";
import { handleApiResponse } from "@/api/helpers";

type EgressType = "EIGHT11" | "THIRD_PARTY";

const api = new UrbintApi();
const egressIdent = (type?: EgressType) => ["egresses", type ?? "undefined"];

const fetchEgresses = async (type?: EgressType) => {
  const queryParams: { type: EgressType } | Record<string, any> = type
    ? { type }
    : {};
  const res = await api.getMany<EgressRouteSerializer>({
    endPoint: "egress_routes",
    queryParams,
  });
  const res_1 = handleApiResponse(res);
  return res_1.map((r) => new EgressRoute(r));
};

const useEgresses = (
  type?: EgressType,
  opts?: UseQueryOptions<EgressRoute[]>
) =>
  useQuery<EgressRoute[]>(egressIdent(type), () => fetchEgresses(type), opts);

export { egressIdent, useEgresses };
