import type { Feature, Geometry, Position } from "geojson";
import type {
  ClearMeasurementPointsParams,
  Coordinates,
  HandleKeyDownParams,
  ToggleLineActiveParams,
  UpdateUnitParams,
  removeLastPointProps,
} from "./MeasureTool.types";
import type { Units } from "@turf/turf";
import type { MapMouseEvent } from "mapbox-gl";
import { length, lineString } from "@turf/turf";
import { uniqueId } from "lodash";
import { hasCoordinates } from "@/pages/MapPage";
import { isValidUnit } from "./MeasureToolControls";

const extractCoordinatesFromEvent = (
  e: MapMouseEvent,
  map: mapboxgl.Map
): Coordinates => {
  const { clientX, clientY } = e.originalEvent;
  const rect = map.getContainer().getBoundingClientRect();
  const x = clientX - rect.left;
  const y = clientY - rect.top;
  const lngLat = map.unproject([x, y]);
  return { lng: lngLat.lng, lat: lngLat.lat, x, y };
};

const extractCoordinates = (geometry: Geometry): Position | null =>
  hasCoordinates(geometry) ? (geometry.coordinates as Position) : null;

const calculateDistance = (points: number[][], unit: Units): number => {
  if (points.length < 2) return 0;
  const line = lineString(points);
  return length(line, { units: unit });
};

const calculateCumulativeDistance = (
  points: Feature[],
  unit: Units
): number => {
  const coordinates = points
    .map((point) =>
      hasCoordinates(point.geometry)
        ? (point.geometry.coordinates as number[])
        : null
    )
    .filter((coord): coord is number[] => coord !== null);

  return calculateDistance(coordinates, unit);
};

const updateMeasurementPoints = (
  features: mapboxgl.MapboxGeoJSONFeature[],
  lng: number,
  lat: number,
  prevPoints: Feature[]
): Feature[] => {
  if (features.length > 0 && features[0]?.properties) {
    const { id } = features[0].properties;
    return prevPoints.filter((point) => point.properties?.id !== id);
  }
  const point: Feature = {
    type: "Feature",
    geometry: { type: "Point", coordinates: [lng, lat] },
    properties: { id: uniqueId() },
  };
  return [...prevPoints, point];
};

const clearMeasurementPoints = ({
  setMeasurementPoints,
  setTotalDistance,
  setCurrentDistance,
  setMousePosition,
}: ClearMeasurementPointsParams) => {
  setMeasurementPoints([]);
  setTotalDistance(0);
  setCurrentDistance(null);
  setMousePosition(null);
};

const toggleLineActive = ({
  setIsLineActive,
  setMousePosition,
}: ToggleLineActiveParams) => {
  setIsLineActive((prev) => {
    if (prev) {
      setMousePosition(null); // Clear mouse position when disabling the line
    }
    return !prev;
  });
};

const updateUnit = ({ event, setUnit }: UpdateUnitParams) => {
  const customEvent = event as CustomEvent;
  const { measureUnit } = customEvent.detail;
  if (isValidUnit(measureUnit)) {
    setUnit(measureUnit);
  }
};

const removeLastPoint = ({
  setMeasurementPoints,
  setTotalDistance,
  unit,
}: removeLastPointProps) => {
  setMeasurementPoints((prevPoints) => {
    const updatedPoints = prevPoints.slice(0, -1);
    const newTotalDistance = calculateCumulativeDistance(updatedPoints, unit);
    setTotalDistance(newTotalDistance);
    return updatedPoints;
  });
};

const handleKeyDown = ({
  event,
  handleToggleLineActive,
  handleRemoveLastPoint,
}: HandleKeyDownParams) => {
  const keyboardEvent = event as KeyboardEvent;
  if (keyboardEvent.key === "Escape") {
    handleToggleLineActive();
  } else if (keyboardEvent.key === "Backspace") {
    handleRemoveLastPoint();
  }
};

export {
  handleKeyDown,
  clearMeasurementPoints,
  updateUnit,
  removeLastPoint,
  toggleLineActive,
  updateMeasurementPoints,
  calculateCumulativeDistance,
  extractCoordinatesFromEvent,
  calculateDistance,
  extractCoordinates,
};
