import type { TicketOptionsProps } from "./TicketOptions.types";
import { useCallback } from "react";
import { OverflowMenuGroup, OverflowMenuItem } from "@/components/OverflowMenu";
import { useAddItemsToTicketQueue, useCurrentTicketQueue } from "@/api";
import { Tooltip } from "@/common/Tooltip";
import { useDamagePreventionAuth, useResponsive } from "@/hooks";
import { useTicketSelector } from "@/components/TicketSelectorProvider";
import {
  BULK_ACTION_DISABLED_COPY,
  MAX_SELECTION_THRESHOLD,
} from "../../../ActionBar.constants";
import {
  SELECT_ALL_DISABLED,
  SELECT_ALL_LIMIT,
} from "./TicketOptions.constants";

const TicketOptions = ({
  allTicketIds,
  triggerAction,
  setModal,
  bulkDisabled,
  hasSelections,
  handleClearSelection,
}: TicketOptionsProps) => {
  const { isSm } = useResponsive();

  const { selectedTickets, setSelectedTickets } = useTicketSelector();
  const { currentUser } = useDamagePreventionAuth();
  const { data: queue } = useCurrentTicketQueue();
  const { canAddTicketComment } = currentUser;

  const addItemsToQueue = useAddItemsToTicketQueue();

  const handleAddToTicketQueue = useCallback(() => {
    triggerAction(() => {
      if (queue) {
        addItemsToQueue.mutateAsync({
          queue,
          ticketIds: Array.from(selectedTickets),
        });
      }
    });
  }, [addItemsToQueue, queue, selectedTickets, triggerAction]);

  const handleSelectAllTickets = () => {
    if (allTicketIds.length > SELECT_ALL_LIMIT) return;
    setSelectedTickets(allTicketIds);
  };

  return (
    <>
      {selectedTickets.length > 0 && (
        <OverflowMenuGroup>
          <OverflowMenuItem
            onClick={() => triggerAction(() => setModal("bulkCreateTasks"))}
            overflow
            disabled={selectedTickets.length > MAX_SELECTION_THRESHOLD}
            dataTestId="bulk-add-task-button"
          >
            <Tooltip title={BULK_ACTION_DISABLED_COPY} disabled={!bulkDisabled}>
              Add task
            </Tooltip>
          </OverflowMenuItem>
          {canAddTicketComment && (
            <OverflowMenuItem
              onClick={() =>
                triggerAction(() => setModal("bulkCreateTicketComments"))
              }
              overflow
              disabled={selectedTickets.length > MAX_SELECTION_THRESHOLD}
              dataTestId="add-comment-button"
            >
              <Tooltip
                title={BULK_ACTION_DISABLED_COPY}
                disabled={!bulkDisabled}
              >
                Add comment
              </Tooltip>
            </OverflowMenuItem>
          )}

          <OverflowMenuItem
            onClick={handleAddToTicketQueue}
            overflow
            disabled={selectedTickets.length > MAX_SELECTION_THRESHOLD}
            dataTestId="add-to-queue-button"
          >
            <Tooltip title={BULK_ACTION_DISABLED_COPY} disabled={!bulkDisabled}>
              Add to queue
            </Tooltip>
          </OverflowMenuItem>
        </OverflowMenuGroup>
      )}
      <OverflowMenuGroup>
        {allTicketIds.length !== selectedTickets.length && (
          <OverflowMenuItem
            onClick={handleSelectAllTickets}
            disabled={allTicketIds.length > SELECT_ALL_LIMIT}
            dataTestId="select-all-option"
          >
            <Tooltip
              disabled={allTicketIds.length <= SELECT_ALL_LIMIT}
              title={SELECT_ALL_DISABLED}
            >
              Select all tickets
            </Tooltip>
          </OverflowMenuItem>
        )}
        {selectedTickets.length > 0 && (
          <OverflowMenuItem
            onClick={() =>
              triggerAction(() => setModal("bulkChangeExpirationDate"))
            }
            disabled={selectedTickets.length > SELECT_ALL_LIMIT}
            dataTestId="edit-expiration-date"
          >
            <Tooltip
              disabled={selectedTickets.length <= SELECT_ALL_LIMIT}
              title={SELECT_ALL_DISABLED}
            >
              Edit expiration date
            </Tooltip>
          </OverflowMenuItem>
        )}
        {!isSm && hasSelections && (
          <OverflowMenuItem onClick={handleClearSelection}>
            Clear selection
          </OverflowMenuItem>
        )}
      </OverflowMenuGroup>
    </>
  );
};

export { TicketOptions };
