import type { FlexTask } from "@/models/Flex";
import { useCallback, useState } from "react";

const useBulkModals = <T extends string>(
  actionCount: number,
  warningThreshold: number
) => {
  const [nextAction, setNextAction] = useState<() => void>();
  const showWarning = !!nextAction;
  const [modal, setModal] = useState<T>();

  const triggerAction = useCallback(
    (action: () => void) => {
      if (actionCount > warningThreshold) {
        setNextAction(() => action);
      } else {
        action();
      }
    },
    [actionCount, warningThreshold]
  );

  const confirmAction = () => {
    if (nextAction) nextAction();
    setNextAction(undefined);
  };

  const cancelAction = () => {
    setNextAction(undefined);
    setModal(undefined);
  };

  return {
    showWarning,
    triggerAction,
    confirmAction,
    cancelAction,
    setModal,
    completeAction: cancelAction,
    modal,
  };
};

const convertSelectedTasksToFlatArray = (tasks: {
  [index: number]: FlexTask[];
}) =>
  Object.keys(tasks)
    .map((taskId) => {
      const ticketId = parseInt(taskId, 10);
      return tasks[ticketId]!.map((task) => ({ ...task, ticketId }));
    })
    .flat();

export { useBulkModals, convertSelectedTasksToFlatArray };
