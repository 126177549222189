import "@urbint/silica/dist/index.css";
import { useEffect, useState } from "react";
import { useGeolocation } from "react-use";
import "./App.css";
import { useIsRestoring, useQuery } from "@tanstack/react-query";
import { datadogRum } from "@datadog/browser-rum";
import {
  prefetchMemberCodes,
  prefetchOpcos,
  prefetchResponseForms,
  prefetchTaskSteps,
  prefetchTaskTypes,
  prefetchUsers,
  useNotifications,
  prefetchWorkflows,
  prefetchSavedViews,
  usePublicConfig,
} from "@/api";
import { LocationContext } from "@/hooks/useGeoLocation/useGeoLocation";
import {
  FlexFieldsProvider,
  useDamagePreventionAuth,
  useOnlineStatus,
  useResponsive,
} from "@/hooks";
import { RESPONSE_FORM_PREFIX } from "@/hooks/useResponseFormLogic";
import { AppMobile } from "./AppMobile";
import { AppDesktop } from "./AppDesktop";
import "./ionic-variables.css";
import "./urbint-variables.css";
import { removeAllLocalStorageItemsWithPrefix } from "./utils/localStorage";
import {
  PrefetchOfflineData,
  PrefetchProvider,
} from "./components/PrefetchOfflineData";

const App = () => {
  const { isFetched } = useQuery(["ticket-points"]);
  const isRestoring = useIsRestoring();
  const [enablePrefetchOfflineData, setEnablePrefetchOfflineData] =
    useState(false);

  const { isMd } = useResponsive();
  const isOnline = useOnlineStatus();
  const { data: publicConfig } = usePublicConfig();
  // TODO: I believe we don't need to pass the notifications by props... we just need to use the hook where we need notifications.
  const { data: notifications = [] } = useNotifications();

  // Ideally we should find a sweet spot between performance & power consumption (mobile)
  // but for now this will do
  // https://developer.mozilla.org/en-US/docs/Web/API/Geolocation/getCurrentPosition
  const options = {
    enableHighAccuracy: true,
    maximumAge: 2000,
    timeout: 10000,
  };

  // Setting Current user information to fetch in Datadog logs
  const {
    currentUser: { djangoId, displayName, email },
  } = useDamagePreventionAuth();
  useEffect(() => {
    if (djangoId && displayName && email) {
      datadogRum.setUser({
        id: djangoId.toString(),
        name: displayName,
        email,
      });
    }
  }, [djangoId, displayName, email]);

  // Initialize geolocation
  const geo = useGeolocation(options);

  useEffect(() => {
    // App initialization
    prefetchWorkflows();
    prefetchUsers();
    prefetchTaskSteps();
    prefetchTaskTypes();
    prefetchResponseForms();
    prefetchSavedViews();
    prefetchMemberCodes();
    prefetchOpcos();

    // --- Offline Support --- //
    removeAllLocalStorageItemsWithPrefix(RESPONSE_FORM_PREFIX);

    /* Hides generic loader overlay when webapp is fully rendered */
    document
      .getElementsByClassName("urbint-loader-overlay")[0]
      ?.setAttribute("data-loader-hidden", "");
  }, []);

  /**
   * TODO: Experimental
   *
   * We want to wait for the ticket points fetch to be done before enabling prefetching for offline data
   */
  useEffect(() => {
    if (isFetched) {
      console.log(
        "Ticket points fetched, enabling prefetching for offline data"
      );

      setEnablePrefetchOfflineData(true);
    }
  }, [isFetched]);

  return (
    <LocationContext.Provider value={geo}>
      {!isRestoring && (
        <FlexFieldsProvider>
          <PrefetchProvider>
            {isOnline &&
              enablePrefetchOfflineData &&
              publicConfig?.enableOfflinePrefetch && <PrefetchOfflineData />}
            {isMd ? (
              <AppDesktop notifications={notifications} />
            ) : (
              <AppMobile notifications={notifications} />
            )}
          </PrefetchProvider>
        </FlexFieldsProvider>
      )}
    </LocationContext.Provider>
  );
};

export { App };
