import { lazy } from "react";

//  Do not lazy load this page
//  https://urbint.atlassian.net/browse/DPAPP-1788
import TicketPage from "./TicketPage";

const EventsPage = lazy(
  () => import(/* webpackChunkName: "EventsPage" */ "@/pages/EventsPage")
);

const NotificationsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "NotificationsPage" */ "@/pages/NotificationsPage"
    )
);

const ReportsPage = lazy(
  () => import(/* webpackChunkName: "ReportsPage" */ "@/pages/ReportsPage")
);

const SettingsPage = lazy(
  () => import(/* webpackChunkName: "SettingsPage" */ "@/pages/SettingsPage")
);

const TicketHistoryPage = lazy(
  () =>
    import(
      /* webpackChunkName: "TicketHistoryPage" */ "@/pages/TicketHistoryPage"
    )
);

const ProfilePage = lazy(
  () => import(/* webpackChunkName: "ProfilePage" */ "@/pages/ProfilePage")
);

const TicketPrintPage = lazy(
  () =>
    import(/* webpackChunkName: "TicketPrintPage" */ "@/pages/TicketPrintPage")
);

const TicketQueuePage = lazy(
  () =>
    import(
      /* webpackChunkName: "TicketQueuePageLayout" */ "@/pages/TicketQueuePage"
    )
);

export {
  EventsPage,
  NotificationsPage,
  ReportsPage,
  SettingsPage,
  TicketPage,
  TicketHistoryPage,
  ProfilePage,
  TicketPrintPage,
  TicketQueuePage,
};
