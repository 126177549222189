import type { SelectButtonProps } from "./SelectButton.types";
import { Listbox } from "@headlessui/react";
import classNames from "classnames";

const SelectButton = ({
  testId,
  children,
  className,
  onClick,
}: SelectButtonProps) => (
  <Listbox.Button
    data-testid={testId}
    onClick={() => {
      onClick && onClick();
    }}
    className={classNames(
      "px-3 text-left flex w-full items-center h-9 text-black text-opacity-88 truncate flex-nowrap leading-tight",
      className
    )}
  >
    {children}
  </Listbox.Button>
);

export { SelectButton };
