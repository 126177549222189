import type { DetailLinkProps } from "./DetailLink.types";
import { Link } from "react-router-dom";

const DetailLink = ({ to, children }: DetailLinkProps) => (
  <Link to={to} className="text-blue-600 font-semibold text-sm">
    {children}
  </Link>
);

export { DetailLink };
