const USLocale = "en-US";

const wholeNumber = new Intl.NumberFormat(USLocale, {
  maximumFractionDigits: 0,
});

type Formatter = typeof wholeNumber;

const renderNumber = (formatter: Formatter, value: number) =>
  formatter.format(value);

const throwIfNotNumber = (value: unknown) => {
  if (typeof value !== "number" && typeof value !== "bigint") {
    throw new TypeError(
      `Cannot format ${typeof value}. You should pass a number or bigint.`
    );
  }
};

const formatWholeNumber = (value: number) => {
  throwIfNotNumber(value);
  return renderNumber(wholeNumber, value);
};

const pluralize = (count: number, noun: string, suffix = "s") =>
  `${formatWholeNumber(count)} ${noun}${count === 1 ? "" : suffix}`;

export { formatWholeNumber, pluralize };
