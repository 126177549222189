import type { GeocodeInputAdornmentButtonProps } from "./GeocodeInputAdornmentButton.types";
import { Icon } from "@urbint/silica";

const GeocodeInputAdornmentButton = ({
  show,
  iconName,
  onClick,
}: GeocodeInputAdornmentButtonProps) => {
  if (!show) return null;

  return (
    <button
      className="flex px-1 pt-0 text-2xl text-neutral-shade-tertiary hover:text-brand-40"
      onClick={onClick}
      type="button"
    >
      <Icon name={iconName} />
    </button>
  );
};

export { GeocodeInputAdornmentButton };
