import type { OneCallCenterSerializer } from "@/utils/damage-prevention";
import { UrbintModel } from "@/utils/UrbintModel";

class OneCallCenter extends UrbintModel<OneCallCenterSerializer> {
  get id() {
    return this.castNumber(this.data.id);
  }

  get createdOn() {
    return this.castDate(this.data.created_on);
  }

  get updatedOn() {
    return this.castDate(this.data.updated_on);
  }

  get name() {
    return this.castString(this.data.name);
  }

  serialize(): OneCallCenterSerializer {
    return this.data;
  }

  compare(instance: OneCallCenter): number {
    return this.id - instance.id;
  }
}

export { OneCallCenter };
