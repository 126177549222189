import type { Feature, LineString, Position } from "geojson";
import type { MeasureToolProps } from "./MeasureTool.types";
import type { Unit } from "./MeasureToolControls";
import type { MapLayerMouseEvent } from "react-map-gl";
import { useState, useEffect, useCallback } from "react";
import { Source, Layer } from "react-map-gl";
import debounce from "lodash/debounce";
import { hasCoordinates } from "@/pages/MapPage";
import { useResponsive } from "@/hooks";
import {
  calculateCumulativeDistance,
  calculateDistance,
  clearMeasurementPoints,
  extractCoordinates,
  extractCoordinatesFromEvent,
  handleKeyDown,
  removeLastPoint,
  toggleLineActive,
  updateMeasurementPoints,
  updateUnit,
} from "./MeasureTool.utils";
import { measureLine, measurePoint } from "./MeasureTool.constants";
import { DistancePopup } from "./DistancePopup";
import { PointsLabels } from "./PointsLabels";
import { Units } from "./MeasureToolControls";

const MeasureTool = ({ isActive, mapRef }: MeasureToolProps) => {
  const [measurementPoints, setMeasurementPoints] = useState<Feature[]>([]);
  const [mousePosition, setMousePosition] = useState<{
    lng: number;
    lat: number;
  } | null>(null);
  const [currentDistance, setCurrentDistance] = useState<number | null>(null);
  const [totalDistance, setTotalDistance] = useState<number>(0);
  const [isLineActive, setIsLineActive] = useState(true);
  const [unit, setUnit] = useState<Unit>(Units[0]);
  const { isSm } = useResponsive();

  const handleMapClick = useCallback(
    (e: MapLayerMouseEvent) => {
      if (!mapRef.current) return;

      const map = mapRef.current.getMap();

      const { lng, lat, x, y } = extractCoordinatesFromEvent(e, map);
      const features = map.queryRenderedFeatures([x, y], {
        layers: ["measure-points"],
      });

      setMeasurementPoints((prevPoints) => {
        const updatedPoints = updateMeasurementPoints(
          features,
          lng,
          lat,
          prevPoints
        );
        const newTotalDistance = calculateCumulativeDistance(
          updatedPoints,
          unit.label
        );
        setTotalDistance(newTotalDistance);
        return updatedPoints;
      });
    },
    [mapRef]
  );

  const handleMapMouseMove = debounce((e) => {
    if (!isLineActive || !mapRef.current || !isSm) return;

    const map = mapRef.current.getMap();
    const { lng, lat } = extractCoordinatesFromEvent(e, map);
    if (lng && lat) {
      setMousePosition({ lng, lat });

      if (measurementPoints.length) {
        const lastPoint = measurementPoints[measurementPoints.length - 1];
        if (lastPoint && hasCoordinates(lastPoint.geometry)) {
          const lastPointCoordinates = lastPoint.geometry.coordinates;
          if (
            Array.isArray(lastPointCoordinates) &&
            lastPointCoordinates.length === 2
          ) {
            const distanceToCursor = calculateDistance(
              [lastPointCoordinates as number[], [lng, lat]],
              unit.label
            );
            setCurrentDistance(totalDistance + distanceToCursor);
          }
        }
      } else {
        setCurrentDistance(null);
      }
    }
  }, 1);

  const measurementLineString: Feature<LineString> = {
    type: "Feature",
    geometry: {
      type: "LineString",
      coordinates: [
        ...measurementPoints
          .map((point) => extractCoordinates(point.geometry))
          .filter(
            (coordinates): coordinates is Position => coordinates !== null
          ),
        ...(isLineActive && mousePosition && measurementPoints.length > 0
          ? [[mousePosition.lng, mousePosition.lat]]
          : []),
      ],
    },
    properties: {},
  };

  useEffect(() => {
    const eventHandlers: [string, (event: Event) => void][] = [
      [
        "clearMeasurePath",
        () =>
          clearMeasurementPoints({
            setMeasurementPoints,
            setTotalDistance,
            setCurrentDistance,
            setMousePosition,
          }),
      ],
      [
        "cancelMeasure",
        () => toggleLineActive({ setIsLineActive, setMousePosition }),
      ],
      ["toggleUnit", (event: Event) => updateUnit({ event, setUnit })],
      [
        "keydown",
        (event: Event) =>
          handleKeyDown({
            event: event as KeyboardEvent,
            handleToggleLineActive: () =>
              toggleLineActive({ setIsLineActive, setMousePosition }),
            handleRemoveLastPoint: () =>
              removeLastPoint({
                setMeasurementPoints,
                setTotalDistance,
                unit: unit.label,
              }),
          }),
      ],
    ];

    eventHandlers.forEach(([event, handler]) =>
      window.addEventListener(event, handler)
    );

    return () => {
      eventHandlers.forEach(([event, handler]) =>
        window.removeEventListener(event, handler)
      );
    };
  }, []);

  useEffect(() => {
    if (mapRef.current) {
      const mapContainer = mapRef.current;
      const mapCanvas = mapContainer.getMap().getCanvas();
      if (isActive && mapCanvas) {
        mapCanvas.style.cursor = "crosshair";
        mapContainer.on("click", handleMapClick);
        mapContainer.on("mousemove", handleMapMouseMove);
      } else {
        mapCanvas.style.cursor = "";
        mapContainer.off("click", handleMapClick);
        mapContainer.off("mousemove", handleMapMouseMove);
      }

      return () => {
        mapCanvas.style.cursor = "";
        mapContainer.off("click", handleMapClick);
        mapContainer.off("mousemove", handleMapMouseMove);
      };
    }
  }, [isActive]);

  return (
    <>
      <Source
        id="measurement"
        type="geojson"
        data={{
          type: "FeatureCollection",
          features: [...measurementPoints, measurementLineString],
        }}
      >
        <Layer {...measureLine} />
        <Layer {...measurePoint} />
      </Source>
      {mousePosition && currentDistance !== null && (
        <DistancePopup
          coordinates={[mousePosition.lng, mousePosition.lat]}
          distance={currentDistance}
          unit={unit.abbreviation}
        />
      )}
      <PointsLabels measurementPoints={measurementPoints} unit={unit} />
    </>
  );
};

export { MeasureTool };
