import type { ImpactPotentialSerializer } from "@/utils/damage-prevention";
import { useQuery } from "@tanstack/react-query";
import { ImpactPotential } from "@/models/ImpactPotential";
import { UrbintApi } from "@/utils/UrbintApi";
import { MOCK_IMPACT_POTENTIAL } from "@/test/mockImpactPotential";
import { handleApiResponse } from "@/api/helpers";

const api = new UrbintApi();

const impactPotentialIdent = (ticketId: number) => [
  "impactPotential",
  ticketId,
];

const fetchImpactPotential = (ticketId: number) =>
  api
    .getMany<ImpactPotentialSerializer>({
      endPoint: "tickets",
      action: "impact_insights",
      id: ticketId,
    })
    .then(handleApiResponse)
    .then((results) =>
      results.map(
        (impactPotential: ImpactPotentialSerializer) =>
          new ImpactPotential(impactPotential)
      )
    );

const useImpactPotential = (modalOpen: boolean, ticketId: number) =>
  useQuery<ImpactPotential[]>(
    impactPotentialIdent(ticketId),
    () => fetchImpactPotential(ticketId),
    { enabled: modalOpen }
  );

export { useImpactPotential };
