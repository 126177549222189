import type { UseFlexTicketsOpts } from "@/api/tickets";
import type { Task, TaskAttachments } from "@/models";
import { useEffect, useState } from "react";
import {
  prefetchTaskType,
  prefetchTasksForTicket,
  prefetchTicket,
  prefetchUseAnswerSet,
  useFlexTickets,
} from "@/api";
import { queryClient } from "@/api/client";
import { usePrefetch } from "@/components/PrefetchOfflineData";
import { formatDateTime } from "@/format";
import { ticketIdent } from "@/api/tickets/queries/useTicket";
import { prefetchTaskAttachments } from "@/api/attachments/queries/useTaskAttachments";
import { useDownloadAttachmentsUrls } from "@/api/attachments/queries/useDownloadAttachments";
import { prefetchResponseForms } from "./prefetchResponseForms";

const PREFETCH_TICKET_INTERVAL = 5000;

const prefetchTicketsDataFromSavedView = (
  savedViewArgs: UseFlexTicketsOpts,
  flexTicketsOpts: { enabled: boolean }
) => {
  const { data, isFetched } = useFlexTickets(savedViewArgs, flexTicketsOpts);
  const { pages } = data ?? {};
  const [ticketsGroup] = pages ?? [];
  const { data: tickets = [] } = ticketsGroup ?? {};
  const { addPrefetchedTicket } = usePrefetch();

  const [attachmentsToDownload, setAttachmentsToDownload] = useState<string[]>(
    []
  );

  useDownloadAttachmentsUrls(attachmentsToDownload);

  const ticketPrefetchQueue = tickets.map((ticket) => ticket.id);

  const prefetchFullTask = async (ticketTasks: Task[]) => {
    for (const task of ticketTasks) {
      const { id, taskTypeId } = task ?? {};
      await prefetchUseAnswerSet(id);
      await prefetchTaskType(taskTypeId);
      await prefetchResponseForms(task);
      await prefetchTaskAttachments(id);
    }
  };

  const prefetchAttachments = () => {
    const attachmentsUrls: string[] = [];

    queryClient
      .getQueryCache()
      .findAll(["task/attachments"])
      .forEach((task) => {
        const {
          state: { data: taskAttachmentsList },
        } = task as { state: { data: TaskAttachments[] } };

        taskAttachmentsList.forEach((taskAttachment: TaskAttachments) => {
          attachmentsUrls.push(taskAttachment.file);
          attachmentsUrls.push(taskAttachment.thumbnailUrl);
        });
      });

    if (attachmentsUrls.length) {
      setAttachmentsToDownload(attachmentsUrls);
    }
    return "";
  };

  const prefetchData = async (queue: (number | undefined)[]) => {
    // if queue is empty, return false
    if (!queue?.length) {
      // NOTE: Download of attachments will be disabled until further discussions and decisions on what should be done regarding offine attachments
      // Now that all tickets data is prefetched, let's prefetch attachments in background
      // prefetchAttachments();
      return false;
    }

    // take first element from queue
    const ticketId = queue.shift();

    // if ticketId is undefined, return false
    if (!ticketId) return false;

    // prefetch ticket
    await prefetchTicket(ticketId);

    // prefetch ticket tasks
    await prefetchTasksForTicket(ticketId);

    // get local cache of ticket data
    const ticketData = queryClient.getQueryData(ticketIdent(ticketId, true));

    // get local cache of ticket tasks
    const ticketTasks: Task[] | undefined = queryClient.getQueryData([
      "ticket/tasks",
      ticketId,
      false,
      false,
    ]);

    if (ticketData && ticketTasks?.length) {
      // prefetch ticket task information
      await prefetchFullTask(ticketTasks);

      // mark as prefetched only when online
      // We can't use the useOfflineStatus hook here because state is not updated during async calls
      if (navigator.onLine) {
        addPrefetchedTicket(ticketId);

        console.debug(
          `[${formatDateTime()}] Finished prefetching ticket ${ticketId}`
        );

        // prefetch next ticket in queue
        setTimeout(() => prefetchData(queue), PREFETCH_TICKET_INTERVAL);
      }
    } else if (navigator.onLine) {
      // prefetch next ticket in queue even if the prev ticket was not successfully prefetched
      setTimeout(() => prefetchData(queue), PREFETCH_TICKET_INTERVAL);
    }

    return false;
  };

  useEffect(() => {
    prefetchData(ticketPrefetchQueue);
  }, [isFetched]);
};

export { prefetchTicketsDataFromSavedView };
