import type { LocateMeMarkerProps } from "./LocateMeMarker.types";
import classNames from "classnames";
import { Marker } from "react-map-gl";
import "./LocateMeMarker.css";
import { useGeoLocation } from "@/hooks";
import {
  memberStatusClassname,
  memberIdentifierClassname,
  memberMarkerClassname,
} from "../Markers.constants";
import { getFieldMemberIdentifier } from "../FieldMemberMarker/FieldMemberMarker.utils";

// TODO this is very similar to FieldMemberMarker
const LocateMeMarker = ({ currentUser }: LocateMeMarkerProps) => {
  const { firstName = "", lastName = "" } = currentUser;

  const { latitude, longitude } = useGeoLocation();

  if (!latitude && !longitude) {
    return null;
  }

  const memberIdentifier = getFieldMemberIdentifier(firstName, lastName);

  return (
    <Marker latitude={latitude || 0} longitude={longitude || 0}>
      <div className={`${memberMarkerClassname} locate-me-animation`}>
        <span className={memberIdentifierClassname}>{memberIdentifier}</span>
        <div
          className={classNames(`${memberStatusClassname} bg-data-green-30`)}
        />
      </div>
    </Marker>
  );
};

export { LocateMeMarker };
