import type { HTMLAttributes } from "react";
import { forwardRef } from "react";

const FilterDialogCard = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ children, className }, ref) => (
  <div
    ref={ref}
    className={`bg-white rounded border py-5 px-4 mt-3 shadow ${className}`}
  >
    {children}
  </div>
));

export { FilterDialogCard };
