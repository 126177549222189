import type { ServiceCenterColumnProps } from "./ServiceCenterColumn.types";
import type { FlexServiceCenter } from "@/models";
import { useState } from "react";
import { BodyText } from "@urbint/silica";
import { Button } from "@/common/Button";
import { ServiceCenterPopUp } from "@/components/ServiceCenterPopUp";
import { Tooltip } from "@/common/Tooltip";
import { MAX_SERVICE_CENTERS_VISIBLE } from "./ServiceCenterColumn.constants";

const ShowServiceCenters = ({
  serviceCenters,
}: {
  serviceCenters: FlexServiceCenter[];
}) => (
  <>
    {serviceCenters.map((serviceCenter, index) => (
      <Tooltip key={serviceCenter.name} title={serviceCenter.name}>
        <BodyText
          className={`truncate ${
            serviceCenters.length > 1 ? "max-w-[3.5rem]" : "max-w-[11.5rem]"
          } cursor-pointer`}
        >
          {index !== 0 && ", "}
          {serviceCenter.name}
        </BodyText>
      </Tooltip>
    ))}
  </>
);

const ServiceCenterColumn = ({ ticket }: ServiceCenterColumnProps) => {
  const [showServiceCenterPopup, setShowServiceCenterPopup] = useState(false);
  const { serviceCenters = [] } = ticket;

  const renderServiceCenters = () => {
    const visibleServiceCenters = serviceCenters.slice(
      0,
      MAX_SERVICE_CENTERS_VISIBLE
    );

    return (
      <>
        <ShowServiceCenters serviceCenters={visibleServiceCenters} />
        {serviceCenters.length > MAX_SERVICE_CENTERS_VISIBLE && (
          <>
            ,
            <Button
              className="border border-solid border-#BDC1C3 rounded-lg px-2 py-1 ml-[5px] text-sm flex items-center text-brand-50 font-semibold icon-semibold bg-white-600"
              onClick={() => setShowServiceCenterPopup(true)}
            >
              More
            </Button>
          </>
        )}
      </>
    );
  };

  return (
    <>
      {renderServiceCenters()}
      <ServiceCenterPopUp
        isVisible={showServiceCenterPopup}
        setVisibility={setShowServiceCenterPopup}
        ticket={ticket}
      />
    </>
  );
};

export { ServiceCenterColumn };
