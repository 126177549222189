import type { TicketViewEditorAPI } from "./TicketViewEditor.types";
import { createContext, useContext } from "react";

const TicketViewEditorContext = createContext<TicketViewEditorAPI | undefined>(
  undefined
);

const useTicketViewEditor = (): TicketViewEditorAPI =>
  useContext(TicketViewEditorContext)!;

export { TicketViewEditorContext, useTicketViewEditor };
