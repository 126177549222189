import type { SingleLineSVGProps } from "./SingleLineSVG.types";
import React from "react";

const SingleLineSVG = ({
  color,
  width = 1,
  length = 11,
  dashes,
}: SingleLineSVGProps) => (
  <svg
    className="w-5 h-5"
    viewBox={`0 0 ${length} 1`}
    xmlns="http://www.w3.org/2000/svg"
  >
    {dashes ? (
      <>
        {dashes.map(([a, b], i) => (
          <line
            opacity={0.8}
            x1={a}
            y1="0.5"
            y2="0.5"
            x2={b}
            stroke={color}
            strokeWidth={width}
            // eslint-disable-next-line react/no-array-index-key
            key={`segment-${i}`}
          />
        ))}
      </>
    ) : (
      <line
        opacity={0.8}
        x1="0"
        y1="0.5"
        y2="0.5"
        x2="10"
        stroke={color}
        strokeWidth={width}
      />
    )}
  </svg>
);

export { SingleLineSVG };
