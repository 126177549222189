import type { ActionBarRightMenuProps } from "./ActionBarRightMenu.types";
import {
  OverflowMenu,
  OverflowMenuGroup,
  OverflowMenuItem,
} from "@/components/OverflowMenu";
import { useTicketSelector } from "@/components/TicketSelectorProvider";
import { useResponsive } from "@/hooks";
import { useTicketViewEditor } from "@/components/TicketViewEditorProvider";
import { TicketOptions } from "./TicketOptions";
import { TaskOptions } from "./TaskOptions";

const ActionBarRightMenu = ({
  hasSelections,
  bulkDisabled,
  triggerAction,
  setModal,
  allTicketIds,
  allSelectedTasks,
}: ActionBarRightMenuProps) => {
  const { isSm } = useResponsive();
  const {
    selectMode,
    setSelectMode,
    clearSelectedTasks,
    clearSelectedTickets,
  } = useTicketSelector();
  const { toggleIsShowingTasks, isShowingTasks } = useTicketViewEditor();
  const isTasksSelectMode = selectMode === "tasks";
  const isTicketsSelectMode = selectMode === "tickets";

  const handleEnableSelectTasks = () => {
    setSelectMode("tasks");
    if (!isShowingTasks) {
      toggleIsShowingTasks();
    }
  };

  const handleClearSelection = () => {
    if (selectMode === "tickets") {
      clearSelectedTickets();
    } else clearSelectedTasks();
  };

  return (
    <OverflowMenu
      buttonLabel={
        isSm
          ? selectMode
            ? "More"
            : "Select..."
          : selectMode
          ? "Actions"
          : "..."
      }
      buttonIcon={isSm ? "chevron_down" : undefined}
      buttonVariant={!isSm && selectMode ? "primary" : undefined}
      dataTestId="select-button"
    >
      {!isSm && selectMode !== "tasks" && (
        <OverflowMenuGroup>
          <OverflowMenuItem onClick={toggleIsShowingTasks}>
            {isShowingTasks ? "Hide tasks" : "Show tasks"}
          </OverflowMenuItem>
        </OverflowMenuGroup>
      )}
      {!selectMode && (
        <OverflowMenuGroup>
          <OverflowMenuItem
            onClick={() => setSelectMode("tickets")}
            dataTestId="select-tickets-option"
          >
            Select tickets
          </OverflowMenuItem>
          <OverflowMenuItem
            onClick={handleEnableSelectTasks}
            dataTestId="select-tasks-option"
          >
            Select tasks
          </OverflowMenuItem>
        </OverflowMenuGroup>
      )}
      {isTicketsSelectMode && (
        <TicketOptions
          allTicketIds={allTicketIds}
          triggerAction={triggerAction}
          setModal={setModal}
          bulkDisabled={bulkDisabled}
          hasSelections={hasSelections}
          handleClearSelection={handleClearSelection}
        />
      )}
      {isTasksSelectMode && (
        <TaskOptions
          allSelectedTasks={allSelectedTasks}
          hasSelections={hasSelections}
          bulkDisabled={bulkDisabled}
          triggerAction={triggerAction}
          setModal={setModal}
          handleClearSelection={handleClearSelection}
        />
      )}
    </OverflowMenu>
  );
};

export { ActionBarRightMenu };
