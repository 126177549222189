import type { TaskResponseFormSubheaderProps } from "./TaskResponseFormSubheader.types";

const TaskResponseFormSubheader = ({
  children,
  className = "",
}: TaskResponseFormSubheaderProps) => (
  <p
    className={`text-neutral-shade-secondary -mt-5 leading-tight ${className}`}
  >
    {children}
  </p>
);

export { TaskResponseFormSubheader };
