import type { QueuedTicketStatus } from "@/models/TicketQueueItem";
import type { TicketQueueCardProps } from "./TicketQueueCard.types";
import { IonSkeletonText } from "@ionic/react";
import {
  useCurrentTicketQueue,
  useRemoveItemsFromTicketQueue,
  useTicket,
  useUpdateTicketQueueItem,
} from "@/api";
import { TicketQueueCardView } from "./TicketQueueCardView";
import { USER_LACKS_TICKET_ACCESS_ERROR_CODE } from "./TicketQueueCard.constants";

/**
 * Data and event handler for the `TicketQueueCardView` component.
 */
const TicketQueueCard = ({
  item,
  canRemoveFromQueue,
}: TicketQueueCardProps) => {
  const removeItemFromQueue = useRemoveItemsFromTicketQueue();
  const queue = useCurrentTicketQueue();
  const ticket = useTicket(item.ticketId, { enabled: item.ticketId > 0 });

  const updateTicketItem = useUpdateTicketQueueItem();

  // rethrow errors for the nearest error boundary to catch
  if (queue.error) throw queue.error;
  if (ticket.error) {
    if (
      (ticket.error as Error).message.includes(
        USER_LACKS_TICKET_ACCESS_ERROR_CODE
      )
    ) {
      /*
       DPAPP-1245 - 404 errors are triggered when a user doesn't have access to tickets on the queue;
       this can be caused if there are tickets without owned tasks and the
       RESTRICT_LIST_TO_OWN_TICKETS permission is activated
      */
      throw new Error(
        "There is a permission issue on your queue. Please reach out to your manager to fix it."
      );
    } else {
      throw ticket.error;
    }
  }
  if (removeItemFromQueue.error) throw removeItemFromQueue.error;

  if (ticket.isInitialLoading || queue.isInitialLoading)
    return <IonSkeletonText />;
  if (!ticket.data || !queue)
    throw new Error(
      `Failed to load ticket with id ${item.ticketId}. No further information is available`
    );

  const onChangeStatus = (status: QueuedTicketStatus) => {
    if (queue.data) {
      updateTicketItem.mutateAsync({ queue: queue.data, item, status });
    }
  };
  const onClickRemove = () => {
    if (queue.data) {
      removeItemFromQueue.mutateAsync({
        queue: queue.data,
        itemIds: [item.id],
      });
    }
  };

  return (
    <TicketQueueCardView
      item={item}
      onChangeStatus={onChangeStatus}
      onClickRemove={onClickRemove}
      ticket={ticket.data}
      canRemoveFromQueue={canRemoveFromQueue}
    />
  );
};

export { TicketQueueCard };
