const NO_DATE_SET_TEXT =
  "Ticket will not automatically expire if this value is set to empty.";

const SINGLE_TICKET_BODY_TEXT =
  "Changing the Expiration date for this ticket may also change the ticket stage.";

const MULTIPLE_TICKETS_BODY_TEXT =
  "Changing the Expiration date for these tickets may also change their ticket stage.";

export {
  SINGLE_TICKET_BODY_TEXT,
  MULTIPLE_TICKETS_BODY_TEXT,
  NO_DATE_SET_TEXT,
};
