import type {
  GetHeaderProps,
  RiskInsightsModalProps,
} from "./RiskInsightsModal.types";
import { CaptionText } from "@urbint/silica";
import { Dialog } from "@/components/Dialog";
import { getTicketLocation } from "@/pages/TicketPage/Header/SummaryCard";
import { Header } from "./Header";

const getHeader = ({
  close,
  headerTitle,
  address,
  cityState,
}: GetHeaderProps) => (
  <Header
    closeModal={close}
    headerTitle={headerTitle}
    address={`${address} | ${cityState}`}
  />
);

const RiskInsightsModal = ({
  isModalOpen,
  setIsModalOpen,
  ticketLatestVersion,
  modalTitle,
  modalContent,
  footerText,
  ticketId,
}: RiskInsightsModalProps) => {
  const { address, cityState } = getTicketLocation(ticketLatestVersion);
  return (
    <Dialog
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      header={({ close }) =>
        getHeader({ close, headerTitle: modalTitle, address, cityState })
      }
      size="large"
      unmount={false}
    >
      {modalContent({
        isModalOpen,
        setIsModalOpen,
        ticketId,
      })}
      <CaptionText className="italic pt-4">{footerText}</CaptionText>
    </Dialog>
  );
};

export { RiskInsightsModal };
