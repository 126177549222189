import { useQueries, useQuery } from "@tanstack/react-query";
import { queryClient } from "@/api/client";

const attachmentsIdent = (attachmentUrl?: string) => [
  "attachment",
  attachmentUrl,
];

const downloadAttachment = async (url: string | URL) => {
  const response = await fetch(url);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    } catch (e) {
      reject(e);
    }
  });
};

const useDownloadAttachments = (attachmentUrl: string) =>
  useQuery(attachmentsIdent(attachmentUrl), () =>
    downloadAttachment(attachmentUrl)
  );

const useDownloadAttachmentsUrls = (attachmentUrls: string[]) =>
  useQueries({
    queries: attachmentUrls.map((attachmentUrl) => ({
      queryKey: attachmentsIdent(attachmentUrl),
      queryFn: () => downloadAttachment(attachmentUrl),
    })),
  });

const removeUseAttachmentQuery = (attachmentUrl: string) => {
  queryClient.removeQueries(attachmentsIdent(attachmentUrl));
};

const invalidateUseAttachmentsQuery = (attachmentUrl: string) =>
  queryClient.invalidateQueries(attachmentsIdent(attachmentUrl));

export {
  attachmentsIdent,
  useDownloadAttachments,
  useDownloadAttachmentsUrls,
  removeUseAttachmentQuery,
  invalidateUseAttachmentsQuery,
};
