import type { MemberCode, PositiveResponse } from "@/models";

const organizeResponses = (
  memberCodes: MemberCode[],
  positiveResponses: PositiveResponse[] = []
) =>
  memberCodes.map((code) => {
    const responses = positiveResponses
      .filter((response) => response.memberCode === code.id)
      .sort(
        (a, b) =>
          Date.parse(`${b.transmitTime}-0500`) -
          Date.parse(`${a.transmitTime}-0500`)
      );
    return { memberCode: code, responses };
  });

export { organizeResponses };
