/* eslint-disable no-console */
import type { dialogFooter } from "@/components/Dialog";
import type { AddCommentProps } from "./AddComment.types";
import { IonToast } from "@ionic/react";
import { Icon } from "@urbint/silica";
import { useCallback, useRef, useState } from "react";
import { useAddCommentToTask, useAddCommentToTicket } from "@/api";
import { useDamagePreventionAuth } from "@/hooks";
import { AutoGrowingTextArea } from "@/components/AutoGrowingTextArea";
import { Dialog, DialogHeaderButtons, DialogTitle } from "@/components/Dialog";

const AddComment = ({
  isOpen,
  onDidDismiss,
  ticket,
  task,
}: AddCommentProps) => {
  const { currentUser } = useDamagePreventionAuth();
  const formRef = useRef<HTMLFormElement>(null);
  const [commentText, setCommentText] = useState<string>("");
  const [toastMessage, setToastMessage] = useState<string>();
  const addCommentToTask = useAddCommentToTask();
  const addCommentToTicket = useAddCommentToTicket();

  const failureToastMessage =
    "There was an error creating the comment. Please try again, if the issue persists, reach out to customer support";

  const closeModal = () => {
    setCommentText("");
    onDidDismiss();
  };

  const createComment = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      try {
        await addCommentToTicket.mutateAsync({
          ticket: ticket!.id,
          comment: commentText,
          creator: String(currentUser.djangoId),
        });

        setCommentText("");
        setToastMessage("Comment added to ticket");
      } catch (e) {
        setToastMessage(failureToastMessage);
      }
    },
    [
      addCommentToTask,
      addCommentToTicket,
      commentText,
      currentUser.djangoId,
      task,
      ticket,
    ]
  );

  const dialogHeader = (close: any) => (
    <div className="p-6 pb-4 relative">
      <DialogHeaderButtons>
        <button type="button" className="flex items-center" onClick={close}>
          <Icon name="close_big" />
        </button>
      </DialogHeaderButtons>
      <p className="text-sm text-neutral-shade-secondary mb-1">
        Ticket #{ticket?.number}
      </p>
      <DialogTitle>Add comment</DialogTitle>
    </div>
  );

  const modalFooter: dialogFooter = {
    toRender: "Add comment",
    onClose: closeModal,
    cancelButtonTestId: "modal-close-button",
    submitButtonTestId: "addcomment-submit-button",
    submitButtonDisabled: !commentText,
    onClick: () => {
      closeModal();
      formRef.current?.requestSubmit();
    },
  };

  return (
    <>
      <Dialog
        isOpen={isOpen}
        onClose={closeModal}
        header={({ close }) => dialogHeader(close)}
        footer={modalFooter}
      >
        <form onSubmit={(e) => createComment(e)} ref={formRef}>
          <p className="text-sm font-bold text-neutral-shade-secondary mb-1">
            Comment
          </p>
          <AutoGrowingTextArea
            className="min-h-[75px]"
            onCtrlOrMetaEnter={() => {
              closeModal();
              formRef.current?.requestSubmit();
            }}
            value={commentText}
            onChange={setCommentText}
            testId="addcomment-textarea"
          />
        </form>
      </Dialog>

      <IonToast
        isOpen={toastMessage !== undefined}
        onDidDismiss={() => setToastMessage(undefined)}
        message={toastMessage}
        position="top"
        duration={3000}
        data-testid="addcomment-toast"
      />
    </>
  );
};

export { AddComment };
