import type { DamagePreventionUserProfile } from "./types";
import { appMetadata } from "./appMetadata";

let timeoutHandle: number;
/** the max amount of time to wait for the pendo client to be available */
const maxWaitTime = 10000;
let timeOfFirstCall: number;
let initUserId: string | undefined;

/**
 * Initializes the pendo client using the `user` information to configure
 * what is sent to pendo about the current user.
 */
const initPendo = (user: DamagePreventionUserProfile) => {
  if (initUserId === user.id) return;
  if (!controlInitPendoCalls(user)) return;

  const { clientId, env, opco, realm } = appMetadata();
  const data = {
    visitor: {
      appRoles: JSON.stringify(user.appRoles),
      email: user.email ?? "",
      full_name: user.displayName,
      id: user.id,
      isAppAdmin: user.isAppAdmin,
      realmRoles: user.realmRoles,
      roles: user.appRoles[clientId]?.roles ?? [],
    },
    account: { id: realm, env, opco, clientId },
  };

  if (initUserId) {
    pendo.identify(data);
  } else {
    pendo.initialize(data);
  }
  initUserId = user.id;
};

/**
 * Debounces calls to initPendo and eventually expires them after `maxWaitTime`
 */
function controlInitPendoCalls(user: DamagePreventionUserProfile) {
  if (!timeOfFirstCall) {
    timeOfFirstCall = Date.now();
  } else if (Date.now() - timeOfFirstCall > maxWaitTime) {
    console.warn("Pendo client unavailable.", "Waited", maxWaitTime, "ms");
    return false;
  }
  // cancel pending timeouts to avoid initializing more than once
  clearTimeout(timeoutHandle);

  if (!(window as unknown as any).pendo) {
    timeoutHandle = window.setTimeout(() => initPendo(user), 100);
    return false;
  }

  return true;
}

export { initPendo };
