import type { AttachmentDetailSectionHeaderProps } from "./AttachmentDetailSectionHeader.types";

const AttachmentDetailSectionHeader = ({
  children,
  className = "",
}: AttachmentDetailSectionHeaderProps) => (
  <h2
    className={`text-neutral-shade-primary font-semibold text-lg mb-6 ${className}`}
  >
    {children}
  </h2>
);

export { AttachmentDetailSectionHeader };
