import type { User } from "@/models/User";
import { UNASSIGNED_USER_DEFAULT_INDEX } from "./UserSelect.constants";

/**
 * Adds the unassignedUser into the options array into the @UNASSIGNED_USER_DEFAULT_INDEX position
 *
 * @param options
 * @param unassignedUser
 */
const addUnassignedUser = (options: Array<User>, unassignedUser: User) => {
  options.splice(UNASSIGNED_USER_DEFAULT_INDEX, 0, unassignedUser);
};

export { addUnassignedUser };
