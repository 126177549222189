import type { IconName } from "@urbint/silica";
import type { ReactElement } from "react";
import type { TripleSegProps } from "./TripleSeg.types";
import { Icon } from "@urbint/silica";
import classNames from "classnames";
import { useCallback, Children } from "react";

/**
 * This component manages what is probably one of the more common design
 * patterns in the app; a surface that is divided into three segments. This
 * pattern can be seen in buttons with icons either on the left or right of the
 * text, input boxes with search and clear icons, select boxes with chevrons on
 * the right, checkboxes with labels on the right or left, and so on,
 *
 * It's a very simple pattern, and by that definition one that will be
 * manually implemented over and over again through out the app but each with
 * its own slight variations on spacing.
 *
 * This component, while extremely small and simple, aims to reduce those
 * implementations down to a single component to provide a strong sense of
 * consistency all throughout the app.
 *
 * ## About the arguments
 *
 * In most cases, `startIcon` and `endIcon` will be used, and in those cases
 * `TripeSeg` will manage the whitespace for you. However, you can put anything
 * you want in those slots to gain full control over the styling by using slots.
 *
 * ### Example Using Icons
 * 
 ```tsx
   <TripleSeg
    startIcon="search"
    endIcon="off_outline_close"
   >
     Hello!
   </TripleSeg>
 ```
 *  
 * ### Example Using Slots
 * 
 ```tsx
   <TripleSeg>
     <div slot="start">👋</div>
     Hello!
     <div slot="end">👋</div>
   </TripleSeg>
 ```
 * With typescript, your custom components must accept a `slot` prop. You don't
 * have to do anything with that prop inside your component, but it does need to
 * have a value of either `start` or `end` when invoked or it will not render
 * at all.
 * 
 ```tsx
   <TripleSeg>
     <WickedWidget slot="start" />
     Hello!
     <WickedWidget slot="dub-tee-eff" />
   </TripleSeg>
 ```
 * In the above example, `WickedWidget` would only render once, and in the
 * start slot because the second invocation of `WickedWidget` has an invalid
 * slot value. It the second `WickedWidget`'s slot property was omitted
 * entirely, it would render in the center along with the text `Hello!`.
 */
const TripleSeg = ({
  children,
  compact = false,
  endIcon,
  omitEdgePadding = false,
  startIcon,
}: TripleSegProps) => {
  let StartViewProjection: ReactElement | null = null;
  let EndViewProjection: ReactElement | null = null;

  // icon template
  const EdgeIcon = useCallback<(props: { name: IconName }) => JSX.Element>(
    ({ name }) => (
      <Icon
        className={classNames("self-center", {
          "p-1": compact,
          "p-2": !compact,
        })}
        name={name}
      />
    ),
    [compact]
  );

  // If we have icons...
  if (startIcon) StartViewProjection = <EdgeIcon name={startIcon} />;
  if (endIcon) EndViewProjection = <EdgeIcon name={endIcon} />;

  // If we have slotted children...
  Children.forEach(children as (ReactElement | null)[], (child) => {
    // We're casting the children to ReactElement[] to avoid a bunch of extra
    // type checking, BUT that means we still need to be very careful here
    // so we don't end up trying to access the `props` attribute of `null`,
    // `undefined` or a boolean, etc.
    if (
      child !== undefined &&
      child !== null &&
      Object.keys(child).includes("props")
    ) {
      if (child.props.slot === "start") {
        StartViewProjection = child;
      } else if (child.props.slot === "end") {
        EndViewProjection = child;
      }
    }
  });

  return (
    <div className="flex justify-between w-full">
      {StartViewProjection}
      <div
        className={classNames("flex flex-grow overflow-hidden truncate px-1", {
          "py-0.5": !omitEdgePadding && compact,
          "py-1.5": !omitEdgePadding && !compact,
        })}
      >
        {(Children.toArray(children) as ReactElement[]).filter(
          ({ props }) => !props?.slot
        )}
      </div>
      {EndViewProjection}
    </div>
  );
};

export { TripleSeg };
