import type { NonTicketedEventAttachments } from "@/models/NonTicketedEventAttachments";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { nonTicketedEventAttachmentsIdent } from "@/api";
import { queryClient } from "@/api/client";
import { invalidateUseNonTicketedEventAttachmentsQuery } from "../queries/useNonTicketedEventAttachments";
import { uploadAttachment } from "./uploadAttachment.utils";

interface IUseUploadAttachment {
  attachment: File[];
  eventId: number;
}

const useUploadNonTicketedEventAttachmentsIdent = () => [
  "upload_non_ticketed_event_attachments",
];

const getDefaultAttachment = (attachment: File, eventId: number) =>
  ({
    type: "image",
    createdAt: new Date(),
    eventId,
    file: URL.createObjectURL(attachment as Blob),
    thumbnailUrl: URL.createObjectURL(attachment as Blob),
    creatorId: -1,
  } as NonTicketedEventAttachments);

const useUploadNonTicketedEventAttachment = () => {
  const [progress, setProgress] = useState<number>(-1);
  const request = new XMLHttpRequest();

  const res = useMutation(
    useUploadNonTicketedEventAttachmentsIdent(),
    (opts: IUseUploadAttachment) =>
      uploadAttachment(
        {
          attachments: opts.attachment,
          endpoint: "non_ticketed_events",
          modelId: opts.eventId,
          request,
        },
        setProgress
      ),
    {
      onMutate: ({ attachment, eventId }) => {
        const newAttachments: NonTicketedEventAttachments[] = [];
        attachment.forEach((newAttachment) =>
          newAttachments.push(getDefaultAttachment(newAttachment, eventId))
        );
        queryClient.setQueryData(
          nonTicketedEventAttachmentsIdent(eventId),
          (currentAttachments: any) => {
            const oldAttachments = currentAttachments || [];
            return [...oldAttachments, ...newAttachments];
          }
        );
        return attachment;
      },
      onSuccess: (_, { eventId }) => {
        invalidateUseNonTicketedEventAttachmentsQuery(eventId);
      },
    }
  );

  return { ...res, progress, error: res.error as Error, abort: request.abort };
};

export {
  useUploadNonTicketedEventAttachment,
  useUploadNonTicketedEventAttachmentsIdent,
};
