import { BodyText } from "@urbint/silica";
import { MobileHeaderTabsAndAnchorsNames } from "../../TicketPage.constants";

const ActivitiesOffline = () => (
  <div
    id={MobileHeaderTabsAndAnchorsNames.Activity}
    className="p-4 text-center"
  >
    <BodyText>Ticket activity is not supported when offline.</BodyText>
  </div>
);

export { ActivitiesOffline };
