import type { MapToggleProps } from "./MapToggle.types";
import { IonIcon } from "@ionic/react";
import { listOutline, mapOutline } from "ionicons/icons";

const MapToggle = ({ isShown, onToggle }: MapToggleProps) => (
  <button
    type="button"
    className="bg-white drop-shadow-md fit font-sans font-semibold inline-block mt-2 px-4 py-2 rounded-full text-blue-600 w-auto"
    onClick={onToggle}
  >
    <div className="flex flex-row items-center justify-center">
      <IonIcon
        icon={isShown ? listOutline : mapOutline}
        className="text-blue-600 mr-2 text-lg"
      />
      {isShown ? "Show list" : "Show map"}
    </div>
  </button>
);

export { MapToggle };
