import type { TicketStageCancelModalProps } from "./TicketStageCancelModal.types";
import type { dialogFooter } from "@/components/Dialog/DialogContent/DialogFooter";
import { BodyText, Subheading } from "@urbint/silica";
import { Dialog } from "@/components/Dialog";

const TicketStageCancelModal = ({
  modalVisibility,
  setModalVisibility,
  handleCancelTicketStage,
}: TicketStageCancelModalProps) => {
  const modalFooter: dialogFooter = {
    toRender: "Confirm",
    onClose: () => setModalVisibility(false),
    onClick: () => handleCancelTicketStage(),
    submitButtontype: "danger",
  };

  return (
    <Dialog
      isOpen={modalVisibility}
      onClose={() => setModalVisibility(false)}
      variant="warning"
      header=""
      footer={modalFooter}
    >
      <Subheading>
        Are you sure you want to set the ticket stage to Canceled?
      </Subheading>
      <BodyText className="mt-4">
        <p>
          This action cannot be <span className="font-bold">undone</span>.
        </p>
        <p className="pt-2">
          Please note that positive responses will still be sent and tasks can
          still be created and completed.
        </p>
      </BodyText>
    </Dialog>
  );
};

export { TicketStageCancelModal };
