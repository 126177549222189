import type { TicketQueueSerializer } from "@/utils/damage-prevention";
import { useMutation } from "@tanstack/react-query";

import { TicketQueue } from "@/models";
import { UrbintApi } from "@/utils/UrbintApi";
import { handleApiResponse } from "@/api/helpers";
import { invalidateCurrentTicketQueue } from "@/api";

const api = new UrbintApi();
const addItem = ({
  queue,
  ticketIds = [],
}: {
  queue: TicketQueue;
  ticketIds: number[];
}) => {
  const newTicketIds = ticketIds.filter((id) => !queue.containsTicketId(id));
  const body = { ticket_ids: newTicketIds };

  // prevent a call to the server when there are no new tickets to add
  if (newTicketIds.length === 0) {
    return Promise.resolve(queue);
  }

  return api
    .fetch<TicketQueueSerializer>(`ticket_queues/${queue.id}/add`, {
      method: "POST",
      body: JSON.stringify(body),
    })
    .then(handleApiResponse)
    .then((res) => new TicketQueue(res));
};

/**
 * Add new tickets items to the user's current queue.
 */
const useAddItemsToTicketQueue = () =>
  useMutation(addItem, { onSuccess: invalidateCurrentTicketQueue });

export { useAddItemsToTicketQueue };
