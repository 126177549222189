const memberMarkerClassname =
  "relative shadow-5 bg-data-blue-40 rounded-full border-2 border-white box-border flex items-center justify-center w-7 h-7";
const memberStatusClassname =
  "shadow-5 rounded-sm border-white border box-border flex w-2 h-2 absolute bottom-0 right-[-1.5px]";
const memberIdentifierClassname =
  "align-middle text-white text-xs subpixel-antialiased";
export {
  memberMarkerClassname,
  memberStatusClassname,
  memberIdentifierClassname,
};
