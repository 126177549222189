import type { FallbackProps } from "react-error-boundary";
import { Icon } from "@urbint/silica";
import { useCurrentTicketQueue } from "@/api";
import { Button } from "@/common/Button";
import { EmptyStateContainer } from "../EmptyStateContainer";

const TicketQueueErrorFallback = ({
  error,
  resetErrorBoundary,
}: FallbackProps) => {
  const currentQueue = useCurrentTicketQueue();

  function remount() {
    currentQueue.refetch({ stale: true });
    resetErrorBoundary();
  }

  return (
    <div className="w-full mt-1 mb-0 max-h-max min-h-min max-w-[450px]">
      <EmptyStateContainer mode="error">
        <div className="flex items-center w-full">
          <div className="mr-6">
            <Icon
              name="error"
              className="inline-flex float-left mr-2 text-xl text-data-red-30"
            />
            <span className="inline-flex pt-[0.1rem] mr-2 font-semibold">
              Error:
            </span>
            <div className="inline-flex pl-0 ml-0 text-sm text-left whitespace-pre-line">
              {error.message || "Unknown error"}
            </div>
          </div>
          <div className="right-0 pt-1">
            <Button
              variant="tertiary"
              className="text-white bg-data-red-30 hover:bg-data-red-40"
              onClick={remount}
            >
              Dismiss
            </Button>
          </div>
        </div>
      </EmptyStateContainer>
    </div>
  );
};
export { TicketQueueErrorFallback };
