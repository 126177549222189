import type { AutoSaveStatusProps } from "./AutoSaveStatus.types";
import { Icon } from "@urbint/silica";

import { useClock } from "@/hooks";
import { Tooltip } from "@/common/Tooltip";
import { formatRelativeTime } from "@/format";

const AutoSaveStatus = ({
  isAutoSaving,
  autoSavedAt,
  autoSaveHasError,
}: AutoSaveStatusProps) => {
  const hasError = !isAutoSaving && autoSaveHasError;
  const isSaving = isAutoSaving && !autoSaveHasError && !autoSavedAt;
  const saved = !isAutoSaving && autoSavedAt;
  const now = useClock();

  return (
    <span className="text-sm ml-auto text-neutral-shade-secondary font-normal flex items-center">
      {isSaving && "Saving..."}
      {hasError && (
        <Tooltip title="There was an error autosaving." origin="right">
          <Icon
            name="info_circle"
            className="mr-1 text-base text-system-error-40"
          />
          Unsaved changes
        </Tooltip>
      )}
      {saved && (
        <Tooltip
          title={`Saved ${formatRelativeTime(autoSavedAt, now)}`}
          origin="right"
        >
          <Icon
            name="circle_check"
            className="mr-1 text-base text-system-success-40"
            data-testid="saved-tooltip"
          />
          Saved
        </Tooltip>
      )}
    </span>
  );
};

export { AutoSaveStatus };
