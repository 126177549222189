import type { NonTicketedEventSummary } from "@/api";
import { useMutation } from "@tanstack/react-query";

import { UrbintApi } from "@/utils/UrbintApi";
import { handleApiResponse } from "@/api/helpers";
import { invalidateInfiniteNonTicketedEvents } from "@/api";

type IUseChangeNonTicketedEventAssignee = {
  event: NonTicketedEventSummary;
  assignee: string | number | undefined;
};

const api = new UrbintApi();

const changeNonTicketedEventAssignee = (
  opts: IUseChangeNonTicketedEventAssignee
) =>
  api
    .patch(
      "non_ticketed_events",
      JSON.stringify({
        assignee: opts.assignee ? opts.assignee : null,
      }),
      opts.event.id
    )
    .then(handleApiResponse);

const useChangeNonTicketedEventAssignee = () =>
  useMutation(changeNonTicketedEventAssignee, {
    onSuccess: () => {
      invalidateInfiniteNonTicketedEvents();
    },
  });

// eslint-disable-next-line import/prefer-default-export
export { useChangeNonTicketedEventAssignee };
