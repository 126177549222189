import type { RiskProps } from "./Risk.types";
import { useState } from "react";
import { Icon } from "@urbint/silica";
import { RiskBadge } from "@/components/RiskBadge";
import { RiskInsightsModal } from "@/components/Modals/RiskInsightsModal";
import { useOnlineStatus, useResponsive } from "@/hooks";
import { Tooltip } from "@/common/Tooltip";

const Risk = ({
  riskName,
  riskBadge,
  shouldRenderRiskModal,
  ...props
}: RiskProps) => {
  const isOnline = useOnlineStatus();
  const { isMd } = useResponsive();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <li className="flex justify-between align-baseline text-black text-opacity-66">
      <p>{riskName}</p>
      <div className="flex flex-nowrap gap-2">
        <RiskBadge value={riskBadge} />
        {isOnline && shouldRenderRiskModal && (
          <button type="button" onClick={() => setIsModalOpen(true)}>
            <Tooltip
              title="What factors go into this rating?"
              origin="left"
              disabled={!isMd || isModalOpen}
            >
              <Icon name="calculator" className="text-2xl cursor-pointer" />
            </Tooltip>
          </button>
        )}
      </div>
      {shouldRenderRiskModal && (
        <RiskInsightsModal
          {...props}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </li>
  );
};

export { Risk };
