import type { TicketTableHeaderProps } from "./TicketTableHeader.types";
import cx from "classnames";

import { IonIcon } from "@ionic/react";
import { arrowDown, arrowUp } from "ionicons/icons";

const TicketTableHeader = ({
  headerGroups,
  sortChange,
}: TicketTableHeaderProps) => (
  <div className="sticky top-0 z-10 min-w-full bg-white shadow _thead w-max">
    {headerGroups.map((headerGroup) => {
      const headerGroupProps = headerGroup.getHeaderGroupProps({
        className: "_tr justify-between flex",
      });
      return (
        <div
          className={headerGroupProps.className}
          key={headerGroupProps.key}
          role={headerGroupProps.role}
          style={headerGroupProps.style}
        >
          {headerGroup.headers.map((column) => {
            const headerProps = column.getHeaderProps({
              className: cx(
                "_th pt-5 pb-3 mx-2 font-bold flex items-center",
                {
                  "text-neutral-shade-secondary": !column.defaultCanSort,
                  "text-neutral-shade-primary cursor-pointer":
                    column.defaultCanSort,
                },
                column.headerClassName
              ),
            });
            return (
              <div
                className={headerProps.className}
                key={headerProps.key}
                onClick={() =>
                  column.defaultCanSort &&
                  sortChange(column.id, column.isSortedDesc)
                }
                onKeyPress={(e) => {
                  if (e.code === "Enter" && column.defaultCanSort) {
                    sortChange(column.id, column.isSortedDesc);
                  }
                }}
                role="button"
                style={headerProps.style}
                tabIndex={0}
                data-testid="table-header-text"
              >
                {column.render("Header")}
                {column.isSorted && (
                  <IonIcon
                    className="ml-2 text-neutral-shade-primary"
                    icon={column.isSortedDesc ? arrowDown : arrowUp}
                  />
                )}
              </div>
            );
          })}
        </div>
      );
    })}
  </div>
);

export { TicketTableHeader };
