import {
  useSavedViews,
  prefetchFlexTicketsCount,
  prefetchTicketsDataFromSavedView,
} from "@/api";
import { TICKET_CARD_FIELDS_WITH_TASKS } from "@/components/TicketCard/TicketCard.constants";
import { DEFAULT_OFFLINE_SAVED_VIEWS } from ".";

const PrefetchOfflineData = () => {
  const { data: savedViews, isFetched } = useSavedViews();

  const filteredSavedViews =
    savedViews?.filter((savedView) =>
      DEFAULT_OFFLINE_SAVED_VIEWS.includes(savedView?.name)
    ) || [];

  filteredSavedViews.forEach((savedView) => {
    prefetchFlexTicketsCount(savedView?.serialize() || {}, {
      enabled: isFetched,
    });

    prefetchTicketsDataFromSavedView(
      {
        ...(savedView?.serialize() || {}),
        fields: TICKET_CARD_FIELDS_WITH_TASKS,
        allowAbort: false,
      },
      { enabled: isFetched }
    );
  });

  return null;
};

export { PrefetchOfflineData };
