import { SidePanelCollapseLevel } from "./SidePanel.constants";

const collapseSidePanel = (
  lvl: SidePanelCollapseLevel
): SidePanelCollapseLevel => {
  switch (lvl) {
    case SidePanelCollapseLevel.Closed:
      return SidePanelCollapseLevel.Closed;
    case SidePanelCollapseLevel.TicketList:
      return SidePanelCollapseLevel.Closed;
    case SidePanelCollapseLevel.TicketTable:
      return SidePanelCollapseLevel.TicketList;
    // no default
  }
};

const expandSidePanel = (
  lvl: SidePanelCollapseLevel
): SidePanelCollapseLevel => {
  switch (lvl) {
    case SidePanelCollapseLevel.Closed:
      return SidePanelCollapseLevel.TicketList;
    case SidePanelCollapseLevel.TicketList:
      return SidePanelCollapseLevel.TicketTable;
    case SidePanelCollapseLevel.TicketTable:
      return SidePanelCollapseLevel.TicketTable;
    // no default
  }
};

export { collapseSidePanel, expandSidePanel };
