import type { TicketNotFoundPageProps } from "./TicketNotFoundPage.types";
import { datadogRum } from "@datadog/browser-rum";
import {
  IonButton,
  IonContent,
  IonIcon,
  IonLabel,
  IonPage,
  IonText,
} from "@ionic/react";
import { home } from "ionicons/icons";
import { useParams } from "react-router-dom";

const DATADOG_TICKET_NOT_FOUND_PAGE_ERROR = "TICKET_NOT_FOUND_PAGE_ERROR";

const TicketNotFoundPage = ({ error }: TicketNotFoundPageProps) => {
  const { ticketId } = useParams<{ ticketId: string }>();

  datadogRum.addError(error, {
    errorIdentifier: DATADOG_TICKET_NOT_FOUND_PAGE_ERROR,
  });

  return (
    <IonPage>
      <IonContent>
        <div className="ion-justify-content-center ion-align-items-center flex flex-col w-full h-full">
          <h1 className="ion-text-center">
            <IonText color="medium">
              Sorry, ticket {ticketId || "that ticket"} is unavailable
            </IonText>
          </h1>
          <IonButton
            routerLink="/"
            routerDirection="back"
            color="primary"
            fill="clear"
          >
            <IonIcon slot="start" icon={home} />
            <IonLabel>Home</IonLabel>
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export { TicketNotFoundPage };
