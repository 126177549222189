import type { TicketMarkerPopupProps } from "./TicketMarkerPopup.types";
import classNames from "classnames";
import { Popup } from "react-map-gl";
import "../MapPopups.css";

const TicketMarkerPopup = ({
  className,
  popupCoordinates,
  offset = [0, 30],
  children,
}: TicketMarkerPopupProps) => {
  const [longitude, latitude] = popupCoordinates;

  if (!latitude || !longitude) return null;

  return (
    <Popup
      latitude={latitude}
      longitude={longitude}
      className={classNames(className, "bg-transparent relative")}
      closeButton={false}
      closeOnClick={false}
      anchor="bottom"
      offset={offset}
      maxWidth="initial"
    >
      {children}
    </Popup>
  );
};

TicketMarkerPopup.displayName = "TicketMarkerPopup";

export { TicketMarkerPopup };
