import type { Insight } from "@/models";
import { InsightName } from "@/models";
import {
  EXCAVATOR_OPTIONS,
  LOCATION_COMPLEXITY_OPTIONS,
  OTHER_OPTION,
  TICKET_TYPE_OPTIONS,
  WORK_TYPE_OPTIONS,
} from "./DamageThreatInsightFeedbackForm.constants";

const getDropdownSelectOptions = ({ insightNameType }: Insight) => {
  switch (insightNameType) {
    case InsightName.EXCAVATOR_RANK:
      return EXCAVATOR_OPTIONS;
    case InsightName.TICKET_TYPE:
      return TICKET_TYPE_OPTIONS;
    case InsightName.WORK_TYPE_RISK:
      return WORK_TYPE_OPTIONS;
    case InsightName.LOCATION_COMPLEXITY:
      return LOCATION_COMPLEXITY_OPTIONS;
    default:
      return [OTHER_OPTION];
  }
};

export { getDropdownSelectOptions };
