/* eslint-disable import/group-exports */
/**
 * DO NOT EDIT - this file is auto generated
 * run: `poetry run ./manage.py generate_client_typing` to re-generate this file
 */
import type { ActivityTypes } from "@/pages/TicketPage/Activities/Activities.constants";

export interface QuestionSerializer {
  id?: number;
  text: string;
  answer_type: any;
  sequence: number;
  required?: boolean;
  has_dynamic_options?: boolean;
  response_form: any;
}

export interface ResponseOptionSerializer {
  id?: number;
  text: string;
  question: any;
  is_deleted?: boolean;
}

export interface AnswerSerializer {
  id?: number;
  is_deleted?: boolean;
  answer_type: any;
  answer_content: string;
  answer_set: any;
  question: any;
  populated_question?: QuestionSerializer | null;
}

export interface AnswerSetSerializer {
  id?: number;
  answers?: any;
  is_deleted?: boolean;
  submitted?: string | null;
  task?: any | null;
  user?: any | null;
}

export interface ResponseFormSerializer {
  id?: number;
  name?: string | null;
  questions: QuestionSerializer[];
  response_options_by_question_id?: any;
  positive_response_options_required?: any;
}

export interface ServiceCenterSerializer {
  id?: number;
  name: string;
}

export interface ContactSerializer {
  company_name: string;
  primary: boolean;
  on_site: boolean;
  name: string;
  email: string;
  phone_number: string;
  address: string;
}

export interface TicketVersionSerializer {
  id: number;
  version: number;
  damage_potential: string;
  impact_potential: string;
  type: string;
  equipment_type: string;
  excavation_work_type: string;
  excavation_state: string;
  excavation_county: string;
  excavation_city: string;
  excavation_street_name: string;
  excavation_street_number: string;
  excavation_address: string;
  work_done_for: string;
  work_start_date: string;
  contacts: ContactSerializer[];
  created_on: string;
  updated_on: string;
  category: string;
  description: string;
  sequence: string;
  revision: string;
  is_current: boolean;
  raw: any;
  extracted: any;
  caller_phone_number: string;
  caller_name: string;
  caller_email_address: string;
  cross_street_1: string;
  cross_street_2: string;
  expiration_date: string;
  ticket_reported_date: string;
  response_due_date: string;
  ticket_transmission_time: string;
  locate_area: string;
  locate_status: string;
  locate_remarks: string;
  locate_updated: string;
  service_area_map_url: string;
  meeting_details: string;
  meeting_date: string;
  scope_of_work: string;
  tlc_received_at?: string;
  scaled_impact_score: number;
  scaled_threat_score: number;
}

export enum TicketStage {
  /**
   * New ticket stage values (https://urbint.atlassian.net/wiki/spaces/PD/pages/3622273210/Tickets#Ticket-Lifecycle-Stage-(currently-Ticket-Status))
   */
  PRE_DIG = "Pre-Dig",
  ACTIVE = "Active",
  EXPIRED = "Expired",
  CANCELED = "Canceled",
}

export interface ServiceCenter {
  id: number;
  name: string;
}

export interface TicketSerializer {
  id?: number;
  version_count?: number;
  latest_version?: TicketVersionSerializer;
  work_area?: any;
  centroid?: any;
  latest_ticket_comment?: any;
  open_task_types?: any;
  create_time?: string;
  number: string;
  stage?: TicketStage;
  expires_on?: string | null;
  last_rule_effect?: string | null;
  lifecycle_state?: any;
  is_emergency?: boolean;
  eight11_center: number;
  service_center?: any | null;
  service_centers?: Array<ServiceCenter> | null;
  last_rule_modified?: any | null;
  member_codes?: MemberCodeSerializer[];
  count_response_form_answers?: number | null;
  positive_responses?: PositiveResponseSerializer[];
}

export interface ActivitySerializer {
  id?: number;
  datetime?: string;
  changed_fields?: string | null;
  activity_type?: ActivityTypes;
  object_repr?: string | null;
  object_json_repr?: string | null;
  content_type?: any;
  event_type: any;
  event_type_label?: any;
  user?: any | null;
  object_id: string;
  attachmentDeleted?: boolean;
}

export interface TicketCommentSerializer {
  id?: number;
  created_at?: string;
  comment: string;
  ticket: any;
  creator?: any | null;
}

export interface UrbintTicketModelSerializer {
  call_center: string;
  ticket_id: string;
}

export interface NotificationSerializer {
  id?: number;
  title: string;
  description: string;
  timestamp?: string;
  read?: boolean;
  dismissed?: boolean;
  dismissible?: boolean;
  dismissed_at?: string | null;
  user: any;
  ticket: any;
}

export interface TicketForwardSerializer {
  egress_id: number;
}

export interface TicketEmailSerializer {
  receiver_email: string;
  subject?: string;
  body?: string;
}

export interface SavedViewSerializer {
  id?: number;
  created_on?: string;
  updated_on?: string;
  name: string;
  shared?: boolean;
  show_tasks?: boolean;
  filters?: any | null;
  exclude_filters?: any | null;
  fields?: any | null;
  order?: any | null;
  assigned_roles?: any[];
  default_for_roles?: any[];
  user: any | null;
  assigned_users?: any[];
  is_default_view?: boolean;
}

export interface WorkflowSerializer {
  id?: number;
  name: string;
}

export interface TaskSerializer {
  id?: number;
  is_deleted?: boolean;
  sequence?: number;
  assign_time?: string | null;
  system_created?: boolean;
  third_party_created?: boolean;
  create_time?: string;
  completed_time?: string;
  last_rule_effect?: string | null;
  ticket: any;
  member_code?: any | null;
  task_type: any;
  task_step?: any;
  assignee?: any | null;
  creator?: any;
  last_rule_modified?: any | null;
  attachment_count: number | null;
  latest_comment?: FlexTaskCommentSerializer;
}

export interface TaskTypeSerializer {
  id?: number;
  key?: string | null;
  name: string;
  workflow: any;
  response_form?: any;
}

export interface TaskStepSerializer {
  id?: number;
  name: string;
  sequence: number;
  initial?: boolean;
  terminating?: boolean;
  task_type: any;
}

export interface CompleteTaskStepSerializer {
  sequence: number;
}

export interface TaskAttachmentSerializer {
  id?: number;
  type?: any;
  created_at?: string;
  task?: any;
  file?: any;
  thumbnail?: any;
  creator?: any;
  offline?: boolean;
  fileBase64?: string;
  thumbnailBase64?: string;
}

export interface UserSerializer {
  id?: number;
  email?: string;
  phone_number?: string | null;
  first_name?: string;
  last_name?: string;
  display_name?: any;
  username: string;
  is_superuser?: boolean;
  is_staff?: boolean;
  is_active?: boolean;
  user_permissions?: any;
  date_joined?: string;
  last_login?: string | null;
}

export interface UserSummarySerializer {
  id: number;
  email?: string;
  display_name?: any;
  username: string;
}

export interface UserPreferencesSerializer {
  user: any;
  default_ticket_view?: any | null;
}

export interface FleetLocationRQSerializer {
  lon: number;
  lat: number;
  timestamp: string;
}

export interface FleetLocationSerializer {
  user: any | null;
  first_name?: any;
  last_name?: any;
  email?: any;
  phone_number?: any;
  location: any;
  timestamp: string;
  ticket_queue_items?: any;
}

export interface InsightDataSerializer {
  id: number;
  insight_name: string;
  insight_type: string;
}

export interface ImpactPotentialSerializer {
  id: number;
  insight: InsightDataSerializer;
  insight_data: string;
  ticket: number;
}

export interface FleetTicketQueueItemSerializer {
  id?: number;
  created_on?: string;
  updated_on?: string;
  ticket_queue_id?: any;
  is_visited?: boolean;
  sequence?: number;
  ticket_id?: any;
}

export interface AutomationEngineRuleSerializer {
  id?: number;
  name: string;
  shift?: any;
  ticket_types?: any;
  assignee_username?: any;
  member_codes?: any;
  notify_sms?: any;
  notify_email?: any;
}

export interface AutoAssignmentMutationSerializer {
  assignee_id?: number;
  sms_enabled?: boolean;
  email_enabled?: boolean;
}

export interface PublicConfigSerializer {
  id?: number;
  country_code?: string;
  default_score_type: any;
  enable_measuring_tool: boolean;
  map_legend_label_regulator_stations?: string;
  map_legend_label_valves?: string;
  map_legend_label_mains?: string;
  map_legend_label_service_lines?: string;
  map_legend_label_transmission_lines?: string;
  map_legend_label_fiber_lines?: string;
  map_legend_label_fiber_line_access?: string;
  map_legend_label_damages?: string;
  ticket_due_date_field?: any;
  timezone: string;
  fleet_tracking?: boolean;
  non_ticketed_events?: boolean;
  support_task_file_upload?: boolean;
  map_layers?: any;
  emergency_ticket_types?: string[] | null;
  enable_offline_mode: boolean;
  enable_offline_prefetch: boolean;
  api_requests_max_retries: number;
  impact_explainer_enabled: boolean;
  threat_explainer_enabled: boolean;
  scaled_impact_score_enabled: boolean;
  scaled_threat_score_enabled: boolean;
  enable_select_all_in_filters: boolean;
}

export interface NonTicketedEventTypeSerializer {
  id: number;
  name: string;
}

export interface NonTicketedEventDetailsSerializer {
  id: number;
  event_type: NonTicketedEventTypeSerializer;
  date: string;
  assignee: UserSummarySerializer;
  location?: string | null;
  status?: any;
  form?: any;
  form_submitted_at?: any;
}

export interface NonTicketedEventSummarySerializer {
  id: number;
  event_type: NonTicketedEventTypeSerializer;
  date: string;
  assignee: UserSummarySerializer;
  location?: string | null;
  status?: any;
}

export interface MemberCodeSerializer {
  id?: number;
  created_on?: string;
  updated_on?: string;
  key: string;
  utility_type?: any;
  eight11_center: any | null;
}

export interface OneCallCenterSerializer {
  id?: number;
  created_on?: string;
  updated_on?: string;
  name: string;
}

export interface EgressRouteSerializer {
  id?: number;
  target: string;
  type?: any;
}

export interface PositiveResponseCodeOverrideSerializer {
  id: number;
  code: string;
  label: string;
  description: string;
  eight11_center: number;
  is_override?: any;
}

export interface PositiveResponseCodeSerializer {
  id?: number;
  is_override?: any;
  created_on?: string;
  updated_on?: string;
  code: string;
  label?: string | null;
  description?: string | null;
  eight11_center: any;
}

export interface PositiveResponseSerializer {
  id?: number;
  locate_issue_codes?: string[] | null;
  code?: string | null;
  description?: string | null;
  label?: string | null;
  created_on?: string;
  comment?: string | null;
  updated_on?: string;
  ticket_revision?: string | null;
  transmit_time: string;
  remarks?: string | null;
  locator_name?: string | null;
  locate_status?: string | null;
  due_date_extended?: string | null;
  extracted?: any | null;
  raw?: any | null;
  locate_activity_id?: string | null;
  third_party_response?: string | null;
  member_code?: number | null;
  pr_code?: number | null;
  pr_code_override?: number | null;
  ticket: number | null;
  ticket_version?: number | null;
  task?: number | null;
}

export interface ReportsConfigSerializer {
  id?: number;
  iframe_url?: any;
  message?: any;
  reports_base_url?: string;
  dashboard_path?: string;
  enabled?: boolean;
}

export interface FlexUserSerializer {
  id?: number;
  email?: string;
  first_name?: string;
  last_name?: string;
  is_active?: boolean;
}

export interface FlexContactSerializer {
  company_name?: string | null;
  primary?: boolean;
  on_site?: boolean;
  phone_number?: string | null;
}

export interface FlexServiceCenterSerializer {
  id?: number;
  name?: string;
}

export interface FlexTicketVersionSerializer {
  damage_potential?: string | null;
  impact_potential?: string | null;
  type?: string | null;
  locate_remarks?: string | null;
  equipment_type?: string | null;
  excavation_work_type?: string | null;
  excavation_state?: string | null;
  excavation_county?: string | null;
  excavation_city?: string | null;
  excavation_street_name?: string | null;
  excavation_street_number?: string | null;
  work_done_for?: string | null;
  work_start_date?: string | null;
  version?: number;
  response_due_date?: string | null;
  ticket_reported_date?: string | null;
  cross_street_1?: string | null;
  cross_street_2?: string | null;
  expires_on?: Date;
  tlc_received_at?: string;
  scaled_impact_score?: number;
  scaled_threat_score?: number;
}

export interface FlexTicketCommentSerializer {
  comment: string;
}

export interface FlexTaskTypeSerializer {
  id?: number;
  name: string;
}

export interface FlexTaskStepSerializer {
  id?: number;
  name: string;
  initial?: boolean;
  terminating?: boolean;
}

export interface FlexTaskCommentSerializer {
  comment: string;
  created_at: Date;
  creator: FlexUserSerializer;
}

export interface FlexTaskSerializer {
  id?: number;
  sequence?: number | null;
  completed_time?: string | null;
  create_time?: string;
  task_type: FlexTaskTypeSerializer;
  task_step: FlexTaskStepSerializer;
  latest_comment: FlexTaskCommentSerializer;
}

export interface FlexMemberCodesSerializer {
  id?: number;
  key: string;
}

export interface FlexTicketSerializer {
  id?: number;
  latest_version?: FlexTicketVersionSerializer | null;
  number: string;
  stage?: TicketStage;
  service_center?: FlexServiceCenterSerializer | null;
  service_centers?: FlexServiceCenterSerializer[] | null;
  create_time?: string;
  is_emergency?: boolean;
  expires_on?: string;
  positive_responses?: PositiveResponseSerializer[];
}

export interface LocationSerializer {
  lat: number;
  lng: number;
}

export interface TicketQueueClearerSerializer {
  everything?: boolean;
}

export interface TicketQueueOptimizeSerializer {
  start: LocationSerializer;
  end?: LocationSerializer;
  departure_time?: string;
}

export interface TicketQueueItemSerializer {
  id?: number;
  created_on?: string;
  updated_on?: string;
  sequence?: number;
  is_visited?: boolean;
  ticket_queue: any;
  ticket: any;
}

export interface TicketQueueBulkAddItemSerializer {
  ticket_ids: number[];
}

export interface TicketQueueBulkRemoveItemSerializer {
  item_ids: number[];
}

export interface TicketQueueBulkMergeSerializer {
  queue_ids: number[];
  destructive?: boolean;
  overwrite_visited_state?: boolean;
}

export interface TicketQueueOrderSerializer {
  item_id: number;
  sequence: number;
}

export interface TicketQueueReorderSerializer {
  new_queue_order: TicketQueueOrderSerializer[];
}

export interface TicketQueueItemUpdateSerializer {
  item_id: number;
  visited?: boolean;
}

export interface TicketQueueSerializer {
  id?: number;
  items?: TicketQueueItemSerializer[];
  created_on?: string;
  updated_on?: string;
  state?: any;
  error_msg?: string | null;
  created_by?: any | null;
  owned_by?: any | null;
}

export interface DamageThreatSerializer {
  id: number;
  ticket: number;
  damage_rate: number | null;
  percentile: number | null;
  feedback_loop: string | null;
  threat_rank: string;
  insight: InsightDataSerializer;
}
export interface NonTicketedEventAttachmentSerializer {
  id: number;
  type: string;
  created_at: string;
  event: number;
  file: string;
  thumbnail: string;
  creator: string;
}

export interface TaggedSerializer {}

export type TicketFilterFieldKey =
  | "create_time"
  | "number"
  | "eight11_center"
  | "service_center"
  | "stage"
  | "expires_on"
  | "member_codes"
  | "last_rule_modified"
  | "last_rule_effect"
  | "latest_version"
  | "lifecycle_state"
  | "is_emergency"
  | "excavator"
  | "excavation_work_type"
  | "equipment_type"
  | "excavation_state"
  | "excavation_city"
  | "excavation_county"
  | "excavation_street_name"
  | "excavation_street_number"
  | "excavation_address"
  | "cross_street_1"
  | "cross_street_2"
  | "damage_potential"
  | "impact_potential"
  | "caller_phone_number"
  | "caller_email_address"
  | "caller_name"
  | "work_done_for"
  | "ticket_queue_status"
  | "expiration_date"
  | "work_start_date"
  | "response_due_date"
  | "response_code"
  | "ticket_reported_date"
  | "ticket_transmission_time"
  | "task_completion_date"
  | "has_tasks"
  | "open_task_assignees"
  | "task_assignees"
  | "open_task_types"
  | "open_task_statuses"
  | "positive_response_code"
  | "ticket_type"
  | "category"
  | "scaled_impact_score"
  | "scaled_threat_score";
export enum TicketOrderField {
  "create_time" = "create_time",
  "created_on" = "created_on",
  "cross_street_1" = "cross_street_1",
  "cross_street_2" = "cross_street_2",
  "damage_potential" = "damage_potential",
  "scaled_threat_score" = "scaled_threat_score",
  "emergency" = "emergency",
  "equipment_type" = "equipment_type",
  "excavation_address" = "excavation_address",
  "excavation_city" = "excavation_city",
  "excavation_county" = "excavation_county",
  "excavation_state" = "excavation_state",
  "excavation_work_type" = "excavation_work_type",
  "excavator" = "excavator",
  "expires_on" = "expires_on",
  "impact_potential" = "impact_potential",
  "scaled_impact_score" = "scaled_impact_score",
  "latest_comments" = "latest_comments",
  "member_codes" = "member_codes",
  "response_due_date" = "response_due_date",
  "service_center" = "service_center",
  "stage" = "stage",
  "task_completed_date" = "task_completed_date",
  "task_count" = "task_count",
  "ticket_number" = "ticket_number",
  "ticket_reported_date" = "ticket_reported_date",
  "ticket_type" = "ticket_type",
  "tlc_received_at" = "tlc_received_at",
  "work_done_for" = "work_done_for",
  "work_start_date" = "work_start_date",
}

export type TicketOrderFieldKey = `${TicketOrderField}`;
