const EXCLUDE_TOOLTIP_TEXT =
  "Check to enter keywords, select from dropdown for exclusions";

const INCLUDE_TITLE = "Include";
const EXCLUDE_TITLE = "Exclude";

const SEARCH_ICON_SUFFIX = "-search-icon";

export {
  EXCLUDE_TOOLTIP_TEXT,
  SEARCH_ICON_SUFFIX,
  INCLUDE_TITLE,
  EXCLUDE_TITLE,
};
