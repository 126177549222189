import type { ThumbnailWrapperProps } from "./ThumbnailWrapper.types";
import { FileType } from "@/hooks/useFileInput/useFileInput";
import { Thumbnail } from "@/common/Thumbnail";

const ThumbnailWrapper = ({
  attachments,
  canDelete = false,
  onThumbnailClick,
  onThumbnailDelete,
}: ThumbnailWrapperProps) => (
  <ul className="flex flex-wrap items-stretch my-6 gap-2">
    {attachments.map((attachment) => {
      let isLoading = false;
      const { thumbnailUrl, id, type, file } = attachment;

      if ("offline" in attachment) {
        isLoading = attachment.offline;
      }

      return (
        <Thumbnail
          key={thumbnailUrl}
          as={type === FileType.PDF ? "a" : "li"}
          allowDelete={canDelete}
          onDeleteClick={() => onThumbnailDelete?.(id)}
          onClick={() => {
            if (type === FileType.PDF && window) {
              window.open(file, "_blank");
            } else if (onThumbnailClick) {
              onThumbnailClick(id);
            }
          }}
          url={thumbnailUrl}
          alt="Task Attachment"
          isLoading={isLoading}
        />
      );
    })}
  </ul>
);

export { ThumbnailWrapper };
