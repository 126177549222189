import type {
  PersistedClient,
  Persister,
} from "@tanstack/react-query-persist-client";
import ESSerializer from "esserializer";
import { get, set, del } from "idb-keyval";
import {
  Activity,
  AutoAssignmentRule,
  Contact,
  EgressRoute,
  FleetLocation,
  MemberCode,
  OneCallCenter,
  Opco,
  PositiveResponse,
  PositiveResponseCode,
  PublicConfig,
  Question,
  ReportsConfig,
  ResponseForm,
  ResponseFormAnswer,
  ResponseFormAnswerSet,
  ResponseOption,
  SavedView,
  ServiceCenter,
  Task,
  TaskActivity,
  TaskAttachments,
  TaskStep,
  TaskType,
  Ticket,
  TicketActivity,
  TicketQueue,
  TicketQueueItem,
  TicketVersion,
  User,
  UserPreferences,
  Workflow,
} from "@/models";

//
// failureReason stores the error when the user make a request in Offline.
// this data break the deserialize of the object.
// this way we are allways returning the default and initial value of failureReason: null.
// this may produce bugs, if react query need this information to work
const setFailureReasonToNull = (valuesArray: Array<unknown>) =>
  valuesArray.map((value: any) => {
    const { state } = value;
    return {
      ...value,
      state: {
        ...state,
        failureReason: null,
        fetchFailureReason: { ...state.fetchFailureReason, stack: null },
      },
    };
  });

/**
 * Creates an Indexed DB persister
 * @see https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API
 */
const createIDBPersister = (
  isOnline: boolean,
  idbValidKey: IDBValidKey = "DP_OFFLINE_CACHE"
) =>
  ({
    persistClient: async (client: PersistedClient) => {
      await set(
        idbValidKey,
        ESSerializer.serialize(client, {
          interceptProperties: {
            clientState: (indexDbClientState: any) => {
              const { queries, mutations } = indexDbClientState;
              return {
                mutations: setFailureReasonToNull(mutations),
                queries: setFailureReasonToNull(queries),
              };
            },
          },
        })
      );
    },
    restoreClient: async () => {
      if (isOnline) return;
      const cachedClient = (await get<string>(idbValidKey)) ?? "";
      let restoredQueryCache;

      try {
        restoredQueryCache = ESSerializer.deserialize(cachedClient, [
          Activity,
          AutoAssignmentRule,
          Contact,
          EgressRoute,
          OneCallCenter,
          FleetLocation,
          MemberCode,
          Notification,
          Opco,
          PositiveResponse,
          PositiveResponseCode,
          PublicConfig,
          Question,
          ReportsConfig,
          ResponseForm,
          ResponseFormAnswer,
          ResponseFormAnswerSet,
          ResponseOption,
          SavedView,
          ServiceCenter,
          Task,
          TaskActivity,
          TaskAttachments,
          TaskStep,
          TaskType,
          Ticket,
          TicketActivity,
          TicketQueue,
          TicketQueueItem,
          TicketVersion,
          User,
          UserPreferences,
          Workflow,
        ]) as PersistedClient;
      } catch (e) {
        console.error("Error deserializing persistent cache", e);
      }

      return restoredQueryCache;
    },
    removeClient: async () => {
      await del(idbValidKey);
    },
  } as Persister);

export { createIDBPersister };
