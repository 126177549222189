import type { ProjectInformationSectionProps } from "./ProjectInformationSection.types";
import { formatFullDateTime } from "@/format";
import { DetailsSection } from "../DetailsSection";
import { DetailsSectionSplitContent } from "../DetailsSectionSplitContent";

const ProjectInformationSection = ({
  ticket,
}: ProjectInformationSectionProps) => (
  <DetailsSection title="Project Information">
    <DetailsSectionSplitContent
      left={[
        {
          label: "Meeting Details",
          value: ticket?.latestVersion?.meetingDetails,
        },
        {
          label: "Meeting Date",
          value: formatFullDateTime(ticket?.latestVersion?.meetingDate),
        },
        { label: "Scope of Work", value: ticket?.latestVersion?.scopeOfWork },
      ]}
    />
  </DetailsSection>
);

export { ProjectInformationSection };
