import type { colorAndIconBadge } from "./RiskBadge.types";
import type { ThreatRankType } from "@/models/DamageThreatInsight";
import { DamagePotential, ImpactPotential } from "@/models/TicketVersion";

const getBadgeColorAndIcon = (
  value?: DamagePotential | ImpactPotential | ThreatRankType
): colorAndIconBadge => {
  switch (value) {
    case DamagePotential.VERY_HIGH:
    case ImpactPotential.VERY_HIGH:
      return {
        color: "red",
        icon: "chevron_duo_up",
      };
    case DamagePotential.HIGH:
    case ImpactPotential.HIGH:
      return {
        color: "orange",
        icon: "chevron_big_up",
      };
    case DamagePotential.MEDIUM:
    case ImpactPotential.MEDIUM:
      return {
        color: "blue",
        icon: "tilde",
      };
    case DamagePotential.LOW:
    case ImpactPotential.LOW:
      return {
        color: "green",
        icon: "chevron_down",
      };
    case DamagePotential.MISSING_DATA:
    case ImpactPotential.MISSING_DATA:
      return {
        color: "darkgray",
        icon: "off_outline_close",
      };
    default:
      // DamagePotential.NONE, ImpactPotential.NONE or others
      return {
        color: "gray",
        icon: "minus",
      };
  }
};

export { getBadgeColorAndIcon };
