import type { NextButtonProps } from "./NextButton.types";
import cx from "classnames";
import { Button } from "@/common/Button";
import styles from "./NextButton.module.css";

const NextButton = ({
  className,
  iconName = "chevron_right",
  isDisabled = false,
  onClick,
}: NextButtonProps) => (
  <Button
    className={cx(styles.NavigationButton, styles.NextButton, className)}
    iconStart={iconName}
    disabled={isDisabled}
    onClick={onClick}
  />
);

export { NextButton };
