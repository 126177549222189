import type { QuestionSerializer } from "@/utils/damage-prevention";
import { UrbintModel } from "@/utils/UrbintModel";

enum QuestionAnswerType {
  DATE_SELECTOR = "DATE_SELECTOR",
  DATE_TIME_SELECTOR = "DATE_TIME_SELECTOR",
  SINGLE_SELECT_PICKLIST = "SINGLE_SELECT_PICKLIST",
  MULTI_SELECT_PICKLIST = "MULTI_SELECT_PICKLIST",
  TEXT_INPUT_FIELD = "TEXT_INPUT_FIELD",
  COMMENT_INPUT_FIELD = "COMMENT_INPUT_FIELD",
  FOLLOW_UP_TASK = "FOLLOW_UP_TASK",
  POSITIVE_RESPONSE_CODE = "POSITIVE_RESPONSE_CODE",
  MULTI_PR_QUESTION = "MULTI_PR_QUESTION",
}

class Question extends UrbintModel<QuestionSerializer> {
  get id() {
    return this.castNumber(this.data.id);
  }

  get text() {
    return this.castString(this.data.text);
  }

  get answerType() {
    return this.castString(this.data.answer_type) as QuestionAnswerType;
  }

  get sequence() {
    return this.castNumber(this.data.sequence);
  }

  get required() {
    return this.castBool(this.data.required);
  }

  get hasDynamicOptions() {
    return this.castBool(this.data.has_dynamic_options);
  }

  get responseFormId() {
    return this.castNumber(this.data.response_form);
  }

  compare(instance: Question): number {
    return this.sequence - instance.sequence;
  }

  serialize(): QuestionSerializer {
    return this.data;
  }
}

export { QuestionAnswerType, Question };
