import type { UseQueryOptions } from "@tanstack/react-query";
import type { PositiveResponseCodeSerializer } from "@/utils/damage-prevention";
import { useQuery } from "@tanstack/react-query";
import { PositiveResponseCode } from "@/models";
import { UrbintApi } from "@/utils/UrbintApi";
import { queryClient } from "@/api/client";
import { handleApiResponse } from "@/api/helpers";

type IReturn = PositiveResponseCode[];
const api = new UrbintApi();
const positiveResponseCodesIdent = () => ["positive_response_codes"];

const fetchPositiveResponseCodes = () =>
  api
    .getPage<PositiveResponseCodeSerializer>({
      endPoint: "positive_response_codes",
      page: 1,
    })
    .then(handleApiResponse)
    .then((res) => res.results.map((r) => new PositiveResponseCode(r)));

const usePositiveResponseCodes = (opts?: UseQueryOptions<IReturn>) =>
  useQuery<IReturn>(
    positiveResponseCodesIdent(),
    () => fetchPositiveResponseCodes(),
    opts
  );

const prefetchPositiveResponseCodes = () =>
  queryClient.prefetchQuery(
    positiveResponseCodesIdent(),
    fetchPositiveResponseCodes
  );

export {
  positiveResponseCodesIdent,
  usePositiveResponseCodes,
  prefetchPositiveResponseCodes,
};
