const getCookies = () => {
  const keyValuePairs = document.cookie.split("; ");
  const cookies: Record<string, any> = {};

  keyValuePairs.forEach((keyValue) => {
    const [key, ...value] = keyValue.split("=");
    cookies[`${key}`.trim()] = value.join("=").trim();
  });

  return cookies;
};

export { getCookies };
