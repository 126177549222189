import type { ReactChild } from "react";

import type { ActivityListTaskItemProps } from "./ActivityListTaskItem.types";
import { useTaskByIdForTicket, useTaskType } from "@/api";

import { ActivityTypes } from "@/pages/TicketPage/Activities/Activities.constants";
import { startCase } from "@/format";
import { AttachmentActivity } from "./AttachmentActivity";

const ActivityListTaskItem = ({
  ticket,
  activity,
  activity: { activityType },
  onPhotoClick,
}: ActivityListTaskItemProps) => {
  let content: ReactChild = `${activity.modelType} - ${activity.activityType}`;
  // Note that we have to pass in the ticket (rather than use the
  // `activity.ticketId`) due to shortcomings in how some messages encode
  // their data. Specifically the `answerset_submit`
  const { data: task } = useTaskByIdForTicket(
    // TODO: Fix no-non-null-asserted-optional-chain
    ticket?.id!,
    activity.taskId!,
    {
      enabled: ticket !== undefined && activity.taskId !== undefined,
    },
    true
  );
  // TODO: Fix no-non-null-asserted-optional-chain
  const { data: taskType } = useTaskType(task?.taskTypeId!, {
    enabled: task?.taskTypeId !== undefined,
  });

  switch (activityType) {
    case ActivityTypes.AUTOMATION_ENGINE_CREATE:
      content = `Created task ${taskType?.name}`;
      break;
    case ActivityTypes.ANSWERSET_SUBMIT:
    case ActivityTypes.ANSWERSET_EDIT:
      content = `Submitted response form for task ${taskType?.name}`;
      break;
    case ActivityTypes.TASK_ASSIGNMENT: {
      // TODO: Resolve assignee from email into name. Alternatively, get the backend to use real user IDs
      const [oldAssignee, newAssignee] = activity.assignedFromAndTo;
      content = `Changed assignee of task ${taskType?.name} from ${oldAssignee} to ${newAssignee}`;
      break;
    }
    case ActivityTypes.TASK_COMMENT:
      content = `Commented on task ${taskType?.name}: "${activity.commentMessage}"`;
      break;
    case ActivityTypes.TASK_ATTACHMENT: {
      content = (
        <AttachmentActivity
          activity={activity}
          onPhotoClick={onPhotoClick}
          activityName={taskType?.name || ""}
        />
      );
      break;
    }
    case ActivityTypes.TASK_DELETED:
      content = `Deleted task ${taskType?.name}`;
      break;
    case ActivityTypes.TASK_ATTACHMENT_DELETED:
      content = `Deleted attachment for task ${taskType?.name}`;
      break;
    case ActivityTypes.TASK_STATUS_UPDATED: {
      const previousStatus = startCase(activity.taskStepFromAndTo?.[0]);
      const newStatus = startCase(activity.taskStepFromAndTo?.[1]);
      content = `Changed task ${taskType?.name} from ${previousStatus} to ${newStatus}`;
      break;
    }
    // no default
  }
  return activity.activityType === ActivityTypes.TASK_ATTACHMENT ? (
    <span>{content}</span>
  ) : (
    <p>{content}</p>
  );
};

export { ActivityListTaskItem };
