import type { PortalProps } from "./Portal.types";
import { useEffect, useMemo } from "react";
import ReactDOM from "react-dom";

// TODO: Replace portal with Silica
const Portal = ({ children }: PortalProps) => {
  const mount = useMemo(() => document.body, []);
  const el = useMemo(() => document.createElement("div"), []);

  useEffect(() => {
    mount.appendChild(el);
    return () => {
      mount.removeChild(el);
    };
  }, [el, mount]);

  return ReactDOM.createPortal(children, el);
};

export { Portal };
