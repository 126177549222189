import type { MutableRefObject, PropsWithChildren } from "react";
import type { FlexTicketQueryFields } from "@/models";
import { createContext, useCallback, useContext, useMemo, useRef } from "react";

type TicketField = FlexTicketQueryFields;
type TicketFieldRef = MutableRefObject<Partial<Record<TicketField, number>>>;

type FieldCounts = {
  tickets: TicketFieldRef;
  ticket: TicketFieldRef;
};

type TicketFieldGetter = () => TicketField[];
type FlexFieldsProviderProps = PropsWithChildren<unknown>;

const FlexFieldsContext = createContext<FieldCounts | undefined>(undefined);

const FlexFieldsProvider = ({ children }: FlexFieldsProviderProps) => {
  const tickets = useRef({});
  const ticket = useRef({});

  const fieldCountRef = useMemo(
    () => ({
      tickets,
      ticket,
    }),
    [tickets, ticket]
  );

  return (
    <FlexFieldsContext.Provider value={fieldCountRef}>
      {children}
    </FlexFieldsContext.Provider>
  );
};

const registerFields = (ref: TicketFieldRef, fields: TicketField[]) => {
  for (const field of fields) {
    ref.current[field] = (ref.current[field] || 0) + 1;
  }
};

const deregisterFields = (ref: TicketFieldRef, fields: TicketField[]) => {
  for (const field of fields) {
    ref.current[field] = (ref.current[field] || 0) - 1;
    if (ref.current[field]! <= 0) {
      delete ref.current[field];
    }
  }
};

const getFields = (ref: TicketFieldRef): TicketField[] =>
  Object.keys(ref.current) as TicketField[];

const useTicketFieldsWithRef = (
  ref: TicketFieldRef,
  ticketFields: TicketField[]
): (() => TicketField[]) => {
  const registeredRef = useRef<TicketField[] | null>(null);
  if (registeredRef.current !== ticketFields) {
    if (registeredRef.current) {
      deregisterFields(ref, registeredRef.current);
    }
    registerFields(ref, ticketFields);
    registeredRef.current = ticketFields;
  }
  return useCallback(() => getFields(ref), [ref]);
};

const useFlexTicketsFields = (
  ticketFields: TicketField[]
): TicketFieldGetter => {
  const { tickets: ref } = useContext(FlexFieldsContext)!;
  return useTicketFieldsWithRef(ref, ticketFields);
};

const useFlexTicketFields = (
  ticketFields: TicketField[]
): TicketFieldGetter => {
  const { ticket: ref } = useContext(FlexFieldsContext)!;
  return useTicketFieldsWithRef(ref, ticketFields);
};

export type { TicketField, TicketFieldGetter };
export { FlexFieldsProvider, useFlexTicketsFields, useFlexTicketFields };
