import type { MapLayerControlProps } from "./MapLayerControl.types";
import { useFullScreen } from "@/hooks";
import { MapLayerControls } from "@/components/Map/Controls";

const MapLayerControl = ({
  mapLayers,
  toggleMapLayers,
}: MapLayerControlProps) => {
  const { isFullscreen } = useFullScreen();

  return (
    <MapLayerControls
      layers={mapLayers}
      onLayerToggle={toggleMapLayers}
      position={isFullscreen ? "top" : "bottom"}
      className={isFullscreen ? "" : "max-h-[9rem] overflow-y-scroll -bottom-5"}
    />
  );
};

export { MapLayerControl };
