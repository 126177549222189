import type { MapRef } from "react-map-gl";
import { useState, useEffect } from "react";
import { useMap } from "react-map-gl";
import { isSafari } from "react-device-detect";

declare global {
  interface Document {
    mozCancelFullScreen: any;
    webkitExitFullscreen: any;
    webkitFullscreenElement: any;
    mozFullScreenElement: any;
    msExitFullscreen: any;
  }
}

const openFullScreen = (elem: any, map: MapRef) => {
  const requestFullScreen =
    elem.requestFullscreen ||
    elem.webkitRequestFullScreen ||
    elem.mozRequestFullScreen ||
    elem.msRequestFullscreen;

  if (!requestFullScreen) {
    console.warn(
      "Did not find a requestFullScreen method on this element",
      elem
    );
    return;
  }
  setTimeout(() => {
    map.resize();
  }, 40);

  requestFullScreen.call(elem);
};

const exitFullScreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  } else {
    console.warn("Did not find an exit fullscreen method on document");
  }
};

const fullscreenChanged = (setIsFullscreen: (value: boolean) => void) => {
  const fullScreenElement =
    document.fullscreenElement ||
    document.webkitFullscreenElement ||
    document.mozFullScreenElement;
  setIsFullscreen(!!fullScreenElement);
};

const useFullScreen = () => {
  const { current: map } = useMap();
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    const eventListenerName = isSafari
      ? "webkitfullscreenchange"
      : "fullscreenchange";
    const onFullscreenChange = () => fullscreenChanged(setIsFullscreen);

    document.addEventListener(eventListenerName, onFullscreenChange);
    return () => {
      document.removeEventListener(eventListenerName, onFullscreenChange);
    };
  }, []);

  const toggleFullscreen = () => {
    const elem = map?.getContainer().parentElement;
    if (!elem) return;

    if (isFullscreen) {
      exitFullScreen();
    } else {
      openFullScreen(elem, map);
    }
  };

  return { isFullscreen, toggleFullscreen };
};

export { useFullScreen };
