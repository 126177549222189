import type { TaskCardIconProps } from "./TaskCardIcon.types";
import { IonIcon } from "@ionic/react";

import {
  checkmarkCircleOutline,
  ellipseOutline,
  hourglassOutline,
} from "ionicons/icons";
import { Tooltip } from "@/common/Tooltip";

const TaskCardIcon = ({
  status,
  className = "",
  tooltipOrigin,
}: TaskCardIconProps) => {
  let title;
  let icon;
  let color;
  switch (status) {
    case "todo":
      title = "To do";
      color = "text-black text-opacity-33";
      icon = ellipseOutline;
      break;
    case "inprogress":
      title = "In progress";
      color = "text-yellow-600";
      icon = hourglassOutline;
      break;
    case "done":
      title = "Done";
      color = "text-green-600";
      icon = checkmarkCircleOutline;
      break;
    // no default
  }
  return (
    <Tooltip title={title} origin={tooltipOrigin}>
      <IonIcon className={`${className} ${color}`} icon={icon} />
    </Tooltip>
  );
};

export { TaskCardIcon };
