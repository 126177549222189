import type { ToastsListProps } from "@/components/Toasts/ToastsList/ToastsList.types";
import * as React from "react";
import { Toast } from "@/components/Toasts/Toast";

const ToastsList = ({ toasts }: ToastsListProps) => (
  <div className="z-50 inline-flex flex-col space-y-2 absolute transform top-0 sm:top-24 md:20 translate-y-7 left-1/2  -translate-x-1/2 pointer-events-none px-2 w-full md:w-auto">
    {toasts.map((toast) => (
      <Toast key={toast.id} toast={toast} />
    ))}
  </div>
);

export { ToastsList };
