/* eslint-disable no-negated-condition */
import type { IconName } from "@urbint/silica";
import { useOnlineStatus } from "@/hooks";
import { useTicketViewEditor } from "@/components/TicketViewEditorProvider";
import {
  MY_OPEN_TICKETS_SAVED_VIEW_NAME,
  MY_RECENTLY_CLOSED_TICKETS_SAVED_VIEW_NAME,
  useSavedViews,
} from "@/api";

const getDropdownOptions = () => {
  const { data: savedViews = [] } = useSavedViews();
  const isOnline = useOnlineStatus();
  // eslint-disable-next-line no-negated-condition
  const savedViewToShow = isOnline
    ? savedViews
    : savedViews.filter(
        (savedView) =>
          savedView.name === MY_OPEN_TICKETS_SAVED_VIEW_NAME ||
          savedView.name === MY_RECENTLY_CLOSED_TICKETS_SAVED_VIEW_NAME
      );

  return savedViewToShow.map((view) => ({
    label: view.name,
    key: view.id.toString(),
    value: view,
    icon: view.shared ? ("group" as IconName) : undefined,
    isDefault: view.isDefaultView,
  }));
};
export { getDropdownOptions };
