/** This file contains the `TicketQueueLayer` component */

import type { ReactElement } from "react";
import type { LayerProps } from "react-map-gl";
import { Layer } from "react-map-gl";

import { TICKET_QUEUE_LAYER_PROPS } from "./TicketQueueLayer.utils";

const TicketQueueLayer = (): ReactElement<LayerProps> => (
  <Layer {...TICKET_QUEUE_LAYER_PROPS} />
);

export { TicketQueueLayer };
