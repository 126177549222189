import type { SliderFooterProps } from "./SliderFooter.types";

import cx from "classnames";
import { useSwiper } from "swiper/react";
import { Button } from "@/common/Button";

// eslint-disable-next-line no-empty-pattern
const SliderFooter = ({
  closeSlider,
  sliderLenght,
  selectedSlideIndex,
  setGallerySlideIndex,
}: SliderFooterProps) => {
  const swiper = useSwiper();

  const isSliderBeggining = !selectedSlideIndex;
  const isSliderEnd = selectedSlideIndex === sliderLenght;

  return (
    <span slot="container-end">
      <footer
        className={cx(
          "flex flex-row gap-2 px-6 py-4 sm:rounded-b-lg h-[66px] shadow-10",
          {
            "justify-between": !isSliderBeggining,
            "justify-end": isSliderBeggining,
          }
        )}
      >
        {!isSliderBeggining && (
          <Button
            iconStart="chevron_left"
            type="button"
            onClick={() => {
              swiper.slideTo(selectedSlideIndex - 1);
              setGallerySlideIndex(selectedSlideIndex - 1);
            }}
            variant="secondary"
          >
            Previous
          </Button>
        )}

        <div>
          {!isSliderEnd && (
            <Button
              type="button"
              onClick={() => {
                swiper.slideTo(selectedSlideIndex + 1);
                setGallerySlideIndex(selectedSlideIndex + 1);
              }}
              variant="primary"
            >
              Next risk factor
            </Button>
          )}
          {isSliderEnd && (
            <Button
              type="button"
              onClick={() => closeSlider()}
              variant="primary"
            >
              Done
            </Button>
          )}
        </div>
      </footer>
    </span>
  );
};

export { SliderFooter };
