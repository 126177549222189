import type { PhoneNumberLineProps } from "./PhoneNumberLine.types";
import cx from "classnames";
import React from "react";

const PhoneNumberLine = ({ phoneNumber }: PhoneNumberLineProps) =>
  phoneNumber ? (
    <>
      <span
        className={cx(
          "text-gray-300",
          "px-2",
          "align-top",
          "text-sm",
          "subpixel-antialiased"
        )}
      >
        {phoneNumber}
      </span>
      <br />
    </>
  ) : null;

PhoneNumberLine.displayName = "PhoneNumberLine";

export { PhoneNumberLine };
