import type { NonTicketedEventAttachmentSerializer } from "@/utils/damage-prevention";
import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { UrbintApi } from "@/utils/UrbintApi";
import { NonTicketedEventAttachments } from "@/models/NonTicketedEventAttachments";
import { handleApiResponse } from "@/api/helpers";
import { queryClient } from "@/api/client";

const api = new UrbintApi();

const nonTicketedEventAttachmentsIdent = (
  eventId: number,
  attachmentId?: number
) => ["non_ticketed_events", eventId, attachmentId];

const fetchNonTicketedEventAttachments = (
  eventId: number,
  attachmentId?: number
) => {
  if (!eventId) {
    const ErrorMessage = new Error(
      "Missing eventId from Task Attachments api call."
    );
    return Promise.reject(ErrorMessage);
  }

  if (attachmentId) {
    return api
      .getOne<NonTicketedEventAttachmentSerializer>({
        endPoint: `non_ticketed_events/${eventId}`,
        action: `attachments/${attachmentId}`,
      })
      .then(handleApiResponse)
      .then((res) => [new NonTicketedEventAttachments(res)]);
  }

  return api
    .getPage<NonTicketedEventAttachmentSerializer>({
      endPoint: "non_ticketed_events",
      id: eventId,
      action: "attachments",
      page: 1,
    })
    .then(handleApiResponse)
    .then((res) => res.results.map((r) => new NonTicketedEventAttachments(r)));
};

const removeNonTicketedEventAttachments = (
  eventId: number,
  attachmentId?: number
) => {
  queryClient.removeQueries(
    nonTicketedEventAttachmentsIdent(eventId, attachmentId)
  );
};

const useNonTicketedEventAttachments = (
  eventId: number,
  attachmentId?: number,
  opts?: UseQueryOptions<NonTicketedEventAttachments[]>
) =>
  useQuery<NonTicketedEventAttachments[]>(
    nonTicketedEventAttachmentsIdent(eventId, attachmentId),
    () => fetchNonTicketedEventAttachments(eventId, attachmentId),
    { ...opts }
  );

const invalidateUseNonTicketedEventAttachmentsQuery = (eventId: number) => {
  queryClient.invalidateQueries(nonTicketedEventAttachmentsIdent(eventId));
};

export {
  useNonTicketedEventAttachments,
  removeNonTicketedEventAttachments,
  invalidateUseNonTicketedEventAttachmentsQuery,
  nonTicketedEventAttachmentsIdent,
};
