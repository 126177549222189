import type { GaugeDescriptionProps } from "./GaugeDescription.types";
import cx from "classnames";
import {} from "../Gauge.constants";
import {
  MIN_MAX_OFFSET,
  absolutePositioning,
  ellipsisText,
  text,
} from "./GaugeDescription.constants";

const GaugeDescription = ({
  value = 0,
  min = 0,
  max = 100,
  label = "Urbint Score",
  theme,
}: GaugeDescriptionProps) => (
  <div className={cx("flex flex-col", theme?.wrapper)}>
    {label && (
      <div
        className={cx(
          "top-[35%]",
          text,
          ellipsisText,
          absolutePositioning,
          theme?.label
        )}
      >
        {label}
      </div>
    )}
    <div
      className={cx(
        "top-[60%] text-6xl leading-20 font-extrabold",
        ellipsisText,
        absolutePositioning,
        theme?.value
      )}
    >
      {`${value}%`}
    </div>
    <div
      className={cx("flex justify-between", {
        "pt-4": value - MIN_MAX_OFFSET <= min || value + MIN_MAX_OFFSET >= max,
      })}
    >
      <div className={text}>{min}</div>
      <div className={text}>{max}</div>
    </div>
  </div>
);

export { GaugeDescription };
