import type { DateTimeInputProps } from "./DateTimeInput.types";
import type { SingleDateTimeProps } from "./SingleDateTime";
import type { RangeDateTimeProps } from "./RangeDateTime";
import type { MultiDateTimeProps } from "./MultiDateTime";
import {
  MultiDateTime,
  RangeDateTime,
  SingleDateTime,
} from "@/components/DateTimeInput";

const DateTimeInput = ({ type, mode, ...props }: DateTimeInputProps) => {
  const singleProps = props as SingleDateTimeProps;
  const rangeProps = props as RangeDateTimeProps;
  const multiProps = props as MultiDateTimeProps;

  switch (mode) {
    case "single":
      return <SingleDateTime {...singleProps} type={type} />;
    case "range":
      return (
        <RangeDateTime
          {...rangeProps}
          type={type === "time" ? "datetime" : type}
        />
      );
    case "multi":
      return <MultiDateTime {...multiProps} type={type} />;
    // no default
  }
};

export { DateTimeInput };
