import type { Activity } from "@/models";
import type { Slide } from "@/components/Gallery/Gallery.types";
import type { ActivitiesProps } from "./Activities.types";
import { chatbubbleEllipsesOutline } from "ionicons/icons";
import { useState } from "react";
import { Portal } from "@/components/Portal";
import { useTicketActivity } from "@/api";
import {
  useDamagePreventionAuth,
  useOnlineStatus,
  useOrganizeTicketActivities,
} from "@/hooks";
import { FileType } from "@/hooks/useFileInput/useFileInput";
import { Gallery } from "@/components/Gallery";
import { isValidHttpUrl } from "@/utils/url";
import { DropdownSelect } from "@/components/Select";
import { AddComment } from "@/components/AddComment";
import { DEVELOPMENT } from "@/utils/environment.constants";
import { DeleteAttachmentModal } from "@/components/AttachmentsSection/DeleteAttachmentModal";
import {
  SidebarContentContainer,
  SidebarContentMobileHeader,
} from "../TicketPageSideBar/Sidebar";

import { MobileHeaderTabsAndAnchorsNames } from "../TicketPage.constants";
import { ActivityList } from "./ActivityList";
import { LOCAL_UPLOADS_FOLDER } from "./Activities.constants";
import { CommentSidebarAction } from "./CommentSidebarAction";

const getImageUrl = (image: string) => {
  if (process.env.NODE_ENV === DEVELOPMENT) {
    return isValidHttpUrl(image) ? image : `${LOCAL_UPLOADS_FOLDER}/${image}`;
  }
  return isValidHttpUrl(image) ? image : null;
};
const Activities = ({ ticket }: ActivitiesProps) => {
  const { currentUser } = useDamagePreventionAuth();
  const [isCommentModalOpen, setCommentModalOpen] = useState(false);
  // TODO: Fix no-non-null-asserted-optional-chain
  const { data: allActivities } = useTicketActivity(ticket?.id!, {
    enabled: ticket !== undefined,
  });
  const isOnline = useOnlineStatus();
  const canComment = currentUser.canAddTicketComment && isOnline;
  const [photoGalleryId, setPhotoGalleryId] = useState<number | undefined>(
    undefined
  );
  const {
    groupedActivities,
    attachmentActivities,
    sortOptions,
    setSortOrder,
    sortOrder,
    setFilter,
    filterOptions,
    filter,
  } = useOrganizeTicketActivities(allActivities || []);

  const [deleteThumbState, setDeleteThumbState] = useState<{
    modalVisibility: boolean;
    thumbId: number | null;
  }>({
    modalVisibility: false,
    thumbId: null,
  });

  const [attachmentTaskId, setAttachmentTaskId] = useState<number | undefined>(
    undefined
  );

  const closeGallery = () => setPhotoGalleryId(undefined);

  const processGalleryAttachments = (galleryAttachments: Activity[]): Slide[] =>
    galleryAttachments
      .filter((attachment) => {
        const {
          object: {
            fields: { type },
          },
        } = attachment;
        return type !== FileType.PDF;
      })
      .map((attachment) => {
        const {
          object: {
            fields: {
              type,
              task: taskId,
              file: image,
              created_at: createdAt,
              creator: userId,
            },
          },
          objectId: attachmentId,
        } = attachment;
        return {
          image: getImageUrl(image),
          alt: "Activity attachment image",
          title: image ? image.split("/").pop().split("?")[0] : "",
          createdAt,
          author: userId,
          type,
          metadata: {
            taskId,
            attachmentId,
          },
        } as unknown as Slide;
      })
      .filter((slide) => !!slide.image);

  const gallerySlides = processGalleryAttachments(attachmentActivities);

  const dropdownClassname =
    "px-3 pr-2 border border-blue-600 border-opacity-33 border-box h-8 flex items-center bg-blue-600 bg-opacity-6 text-blue-600 rounded-lg text-sm leading-tight whitespace-nowrap";

  const canRenderDeleteAttachmentModal =
    deleteThumbState.modalVisibility && ticket?.id && attachmentTaskId;

  return (
    <div
      id={MobileHeaderTabsAndAnchorsNames.Activity}
      className="h-full relative"
    >
      <AddComment
        isOpen={isCommentModalOpen}
        onDidDismiss={() => setCommentModalOpen(false)}
        ticket={ticket}
      />
      <SidebarContentContainer className={canComment ? "md:pb-20" : ""}>
        <SidebarContentMobileHeader
          title="Activity"
          showButton={canComment ?? false}
          buttonText="Comment"
          buttonIcon={chatbubbleEllipsesOutline}
          onButtonClick={() => setCommentModalOpen(true)}
        />
        <div className="flex space-x-1 mb-9 mt-8 md:mt-0 md:mb-6">
          <DropdownSelect
            className={dropdownClassname}
            options={sortOptions}
            value={sortOrder}
            onChange={setSortOrder}
          />
          <DropdownSelect
            className={dropdownClassname}
            options={filterOptions}
            value={filter}
            onChange={setFilter}
          />
        </div>
        {allActivities && (
          <ActivityList
            onPhotoClick={setPhotoGalleryId}
            ticket={ticket}
            groupedActivities={groupedActivities}
          />
        )}
      </SidebarContentContainer>
      {canComment && <CommentSidebarAction ticket={ticket} />}
      {attachmentActivities && !!gallerySlides.length && (
        <Portal>
          <Gallery
            opened={!!photoGalleryId || photoGalleryId === 0}
            onClose={closeGallery}
            canDelete={currentUser.canDeletePhotos}
            onDelete={({ metadata }) => {
              setAttachmentTaskId(metadata?.taskId);
              setDeleteThumbState({
                modalVisibility: true,
                thumbId: metadata?.attachmentId || null,
              });
            }}
            slides={gallerySlides}
            initialSlide={photoGalleryId}
          />
        </Portal>
      )}
      {canRenderDeleteAttachmentModal && (
        <DeleteAttachmentModal
          attachmentType={{ ticketId: ticket?.id, taskId: attachmentTaskId }}
          thumbState={deleteThumbState}
          setModalVisibility={(value: boolean) =>
            setDeleteThumbState((prevState) => ({
              ...prevState,
              modalVisibility: value,
            }))
          }
        />
      )}
    </div>
  );
};

export { Activities };
