import type { NotificationItemProps } from "./NotificationItem.types";
import { useHistory } from "react-router-dom";
import cx from "classnames";
import { useMarkNotificationAsRead } from "@/api";
import { formatRelativeTime } from "@/format";
import { TicketNotification } from "./TicketNotification";

const NotificationItem = ({
  notification,
  currentUser,
}: NotificationItemProps) => {
  const history = useHistory();
  const { mutate: readNotification } = useMarkNotificationAsRead();

  const { read, ticketId, timestamp, title, description } = notification;

  const onClick = () => {
    if (!read && currentUser.canMarkNotificationRead) {
      readNotification({ notification });
    }
    history.push(`/ticket/${ticketId}`);
  };
  return (
    <div className="flex flex-row leading-tight" onClick={onClick}>
      <div className="flex justify-center pt-1 pl-3 mt-4">
        <div
          className="w-2 h-2 rounded-full"
          style={{
            backgroundColor: read ? "" : "var(--ion-color-primary)",
          }}
        />
      </div>
      <div className="flex flex-col w-full p-3 pr-8">
        <div className="flex-grow pb-1 space-x-1 overflow-hidden whitespace-nowrap overflow-ellipsis">
          <p>
            <span
              className={cx("font-semibold", {
                "text-blue-600": !read,
              })}
            >
              {title}
            </span>
            {description ? <span>{` - ${description}`}</span> : null}
          </p>
        </div>
        <TicketNotification ticketId={ticketId} />
        <span className="flex justify-start mt-1 text-sm">
          Received {formatRelativeTime(timestamp)}
        </span>
      </div>
    </div>
  );
};
export { NotificationItem };
