import type { UseQueryOptions } from "@tanstack/react-query";
import type { WorkflowSerializer } from "@/utils/damage-prevention";
import { useQuery } from "@tanstack/react-query";
import { Workflow } from "@/models";
import { UrbintApi } from "@/utils/UrbintApi";
import { queryClient } from "@/api/client";
import { handleApiResponse } from "@/api/helpers";
import { workflowsIdent } from "@/api";

const api = new UrbintApi();

const workflowIdent = (workflowId: number) => ["workflows", workflowId];

const fetchWorkflow = (workflowId: number) =>
  api
    .getOne<WorkflowSerializer>({ endPoint: "workflows", id: workflowId })
    .then(handleApiResponse)
    .then((r) => new Workflow(r));

const useWorkflow = (workflowId: number, opts?: UseQueryOptions<Workflow>) =>
  useQuery<Workflow>(
    workflowIdent(workflowId),
    () => fetchWorkflow(workflowId),
    {
      ...opts,
      initialData: () =>
        queryClient
          .getQueryData<Workflow[]>(workflowsIdent())
          ?.find((w) => w.id === workflowId),
      initialDataUpdatedAt: () =>
        queryClient.getQueryState(workflowsIdent())?.dataUpdatedAt,
    }
  );

export { workflowIdent, useWorkflow };
