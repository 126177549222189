import type { Dispatch, RefObject, SetStateAction } from "react";

const setIsTooltipEnabled = (
  divRef: RefObject<HTMLDivElement>,
  setFunction: Dispatch<SetStateAction<boolean>>
) => {
  if (divRef.current) {
    const { scrollHeight, clientHeight } = divRef.current;

    const isLineClampApplied = scrollHeight > clientHeight;
    setFunction(isLineClampApplied);
  }
};

export { setIsTooltipEnabled };
