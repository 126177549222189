import type { UseQueryOptions } from "@tanstack/react-query";
import type { TaskStepSerializer } from "@/utils/damage-prevention";
import { useQuery } from "@tanstack/react-query";
import { TaskStep } from "@/models";
import { UrbintApi } from "@/utils/UrbintApi";
import { queryClient } from "@/api/client";
import { handleApiResponse } from "@/api/helpers";
import { taskStepsIdent } from "@/api";

const api = new UrbintApi();

const taskStepIdent = (taskStepId: number) => ["task_steps", taskStepId];

const fetchTaskStep = (taskStepId: number) =>
  api
    .getOne<TaskStepSerializer>({ endPoint: "task_steps", id: taskStepId })
    .then(handleApiResponse)
    .then((r) => new TaskStep(r));

const useTaskStep = (taskStepId: number, opts?: UseQueryOptions<TaskStep>) =>
  useQuery<TaskStep>(
    taskStepIdent(taskStepId),
    () => fetchTaskStep(taskStepId),
    {
      ...opts,
      initialData: () =>
        queryClient
          .getQueryData<TaskStep[]>(taskStepsIdent())
          ?.find((w) => w.id === taskStepId),
      initialDataUpdatedAt: () =>
        queryClient.getQueryState(taskStepsIdent())?.dataUpdatedAt,
    }
  );

export { useTaskStep };
