import type { SavedView } from "@/models";
import { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { IonPage } from "@ionic/react";
import { useHistory } from "react-router-dom";
import { TicketSelectorProvider } from "@/components/TicketSelectorProvider";
import {
  TicketViewEditorProvider,
  useViewStore,
} from "@/components/TicketViewEditorProvider";
import { useOnlineStatus, useResponsive } from "@/hooks";
import { useUrl } from "@/utils/url";
import { TicketList } from "@/components/TicketList";
import { MapControls } from "@/components/Map/Controls";
import { MapToggle } from "./MapToggle";
import { SidePanel } from "./SidePanel";
import { ActionBar } from "./ActionBar/ActionBar";
import { MapPageEntry } from "./MapPageMap";
import { TicketFocusProvider } from "./TicketFocusProvider";

const MapPage = () => {
  const {
    dirtyView,
    savedView,
    updateSavedView,
    clearDirtyView,
    updateDirtyView,
    defaultSavedView,
  } = useViewStore();
  const isOnline = useOnlineStatus();
  const [mapInitialized, setMapInitialized] = useState(false);
  const { urlParams, setUrlParams } = useUrl<{ map?: boolean }>();
  const mapShown = !!urlParams.map;
  const history = useHistory();
  const { isSm } = useResponsive();

  /** *****************************************
   *** Handlers
   ***************************************** */
  const handleToggleMap = useCallback(() => {
    setMapInitialized(true);
    setUrlParams({ map: !mapShown });
  }, [mapShown, setUrlParams]);

  const handleViewSelect = useCallback(
    (view?: SavedView) => {
      if (view) updateSavedView(view);
      else if (defaultSavedView) updateSavedView(defaultSavedView);
      clearDirtyView();
    },
    [updateSavedView, defaultSavedView, clearDirtyView]
  );

  //
  // --- Effects
  //
  useEffect(() => {
    const listener = history.listen(() => {
      if (mapShown === true && history.action === "POP") {
        setUrlParams({ map: false });
      }
    });

    if (mapShown) setMapInitialized(true);
    return () => {
      listener();
    };
  }, []);

  return (
    <IonPage>
      <TicketViewEditorProvider
        savedView={savedView}
        onDirtyViewChange={updateDirtyView}
        initialDirtyView={dirtyView}
      >
        <TicketSelectorProvider>
          <ActionBar
            onUpdateSavedView={handleViewSelect}
            onUpdateDirtyView={updateDirtyView}
          />
          <TicketFocusProvider>
            <div className="flex h-full w-full relative">
              {isSm ? (
                <div className="flex flex-row w-full h-full">
                  <SidePanel />
                  <div className="flex-1 h-full">
                    <MapPageEntry />
                  </div>
                </div>
              ) : (
                <>
                  <TicketList />
                  {mapInitialized && (
                    <div
                      className={classNames(
                        "w-full h-full absolute left-0 top-0 bg-white",
                        (!mapShown || !isOnline) && "hidden"
                      )}
                    >
                      <MapPageEntry>
                        {!isSm && (
                          <MapToggle
                            onToggle={handleToggleMap}
                            isShown={mapShown}
                          />
                        )}
                      </MapPageEntry>
                    </div>
                  )}
                </>
              )}
            </div>
          </TicketFocusProvider>
        </TicketSelectorProvider>
      </TicketViewEditorProvider>
      {!isSm && !mapShown && isOnline && (
        <MapControls position="bottom">
          <MapToggle onToggle={handleToggleMap} isShown={mapShown} />
        </MapControls>
      )}
    </IonPage>
  );
};

export { MapPage };
