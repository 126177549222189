import type { FlexTask } from "@/models";
import { useMemo } from "react";
import { useTaskTypes } from "@/api";
import { useResponseForms } from ".";

const useResponseFormsForTasks = (tasks?: FlexTask[]) => {
  const { data: responseForms } = useResponseForms();
  const { data: taskTypes } = useTaskTypes();

  const responseFormsForTasks = useMemo(() => {
    const taskTypeIds = [...new Set(tasks?.map((t) => t.taskType?.id))];
    const responseFormIds = taskTypeIds.map(
      (id) => taskTypes?.find((t) => t.id === id)?.responseFormId
    );

    return responseForms?.filter((r) => responseFormIds.includes(r.id)) ?? [];
  }, [tasks, responseForms, taskTypes]);

  return responseFormsForTasks;
};

export { useResponseFormsForTasks };
