import React from "react";
import { useLocation } from "react-router-dom";

/**
 * A custom hook that build on useLocation to parse the query string for you
 * https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
 * @returns URLSearchParams
 */
function useQuery(): URLSearchParams {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export { useQuery };
