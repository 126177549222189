import { useCallback } from "react";
import { useHistory } from "react-router";

/** Navigation helper
 * TODO: Type our paths so app wide path updates becomes a breeze?
 */
const useNavigate = () => {
  const history = useHistory();

  const navigate = useCallback(
    (path: string, target?: string) => {
      if (target) {
        window.open(path, target);
      } else {
        history.push(path);
      }
    },
    [history]
  );

  return { navigate };
};

export { useNavigate };
