import type { SlideProps } from "./Slide.types";
import { SwiperSlide } from "swiper/react";
import { SlideContent } from "./SlideContent";
import { SlideHeader } from "./SlideHeader";

const Slide = ({ slideInfo }: SlideProps) => (
  <SwiperSlide>
    <SlideHeader slideInfo={slideInfo} />
    <SlideContent slideInfo={slideInfo} />
  </SwiperSlide>
);

Slide.displayName = "SwiperSlide";

export { Slide };
