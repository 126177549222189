import { Icon } from "@urbint/silica";

const FieldErrorMessage = ({ message }: { message: string }) => (
  <p className="flex items-center">
    <Icon name="info_circle" className="text-system-error-40 text-lg mr-1" />
    <span className="text-neutral-shade-secondary font-small">{message}</span>
  </p>
);

export { FieldErrorMessage };
