import type { GeocoderResult } from "./GeocodeAutocompleteInput.types";
import type {
  StateReducerAction,
  AddressAutocompleteInputState,
} from "./stateReducer.types";
import { useCallback, useMemo, useReducer } from "react";

const initialState: AddressAutocompleteInputState = {
  address: null,
  error: undefined,
  loading: false,
  options: [],
  resultsMenuOpen: false,
  searchTerm: "",
};

const stateReducer = (
  state: AddressAutocompleteInputState,
  action: StateReducerAction
): AddressAutocompleteInputState => {
  switch (action.type) {
    case "BLUR":
      return { ...state, resultsMenuOpen: false };
    case "RESET":
      return initialState;
    case "SET_SEARCH":
      return {
        address: null,
        loading: true,
        error: undefined,
        options: state.options,
        resultsMenuOpen: true,
        searchTerm: action.payload,
      };
    case "SEARCH_RESOLVED":
      return {
        address: state.address,
        loading: false,
        error: undefined,
        options: action.payload,
        resultsMenuOpen: true,
        searchTerm: state.searchTerm,
      };
    case "SET_ADDRESS":
      return {
        address: action.payload,
        loading: false,
        error: undefined,
        options: state.options,
        resultsMenuOpen: false,
        searchTerm: action.payload?.placeName ?? "",
      };
    case "ERROR":
      return {
        address: state.address,
        error: action.payload,
        loading: false,
        options: [],
        resultsMenuOpen: false,
        searchTerm: state.searchTerm,
      };
    default:
      return state;
  }
};

const useAddressInputStateReducer = (initialAddress: GeocoderResult | null) => {
  const augmentedInitialState = initialAddress
    ? {
        ...initialState,
        address: initialAddress,
        searchTerm: initialAddress?.placeName ?? "",
      }
    : initialState;
  const [state, dispatch] = useReducer(stateReducer, augmentedInitialState);

  /** Closes the results drawer when the input loses focus */
  const onBlur = useCallback(() => dispatch({ type: "BLUR" }), []);

  /** Reset the state to its initial form */
  const reset = useCallback(() => dispatch({ type: "RESET" }), []);

  /** Records the search term and sets loading to true */
  const setSearchTerm = useCallback(
    (payload: string) => dispatch({ type: "SET_SEARCH", payload }),
    []
  );

  /** Populates the options array */
  const searchResolved = useCallback(
    (payload: GeocoderResult[]) =>
      dispatch({ type: "SEARCH_RESOLVED", payload }),
    []
  );

  /**
   * The user selected an address from the menu, or we detected their current
   * location
   */
  const setAddress = useCallback(
    (payload: GeocoderResult | null) =>
      dispatch({ type: "SET_ADDRESS", payload }),
    []
  );

  /** Clears the state, closes the menu, and show cases the error */
  const setError = useCallback((e: unknown) => {
    const error =
      e instanceof Error || e instanceof GeolocationPositionError
        ? (e as Error)
        : new Error(e as string);
    dispatch({ type: "ERROR", payload: error });
  }, []);

  return useMemo(
    () => ({
      onBlur,
      reset,
      state,
      setSearchTerm,
      searchResolved,
      setAddress,
      setError,
    }),
    [onBlur, reset, setSearchTerm, searchResolved, setAddress, setError, state]
  );
};

export { stateReducer, useAddressInputStateReducer };
