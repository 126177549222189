import type { WorkflowSerializer } from "@/utils/damage-prevention";
import { UrbintModel } from "@/utils/UrbintModel";

class Workflow extends UrbintModel<WorkflowSerializer> {
  get id() {
    return this.castNumber(this.data.id);
  }

  get name() {
    return this.castString(this.data.name);
  }

  compare(instance: Workflow): number {
    return this.name.localeCompare(instance.name);
  }

  serialize(): WorkflowSerializer {
    return this.data;
  }
}

export { Workflow };
