import type { KnownLensEndpoints } from "@/utils/UrbintApi";
import type { ExpirationDateModalProps } from "@/components/Modals";
import { useMutation } from "@tanstack/react-query";

import { UrbintApi } from "@/utils/UrbintApi";
import {
  invalidateUseFlexTicketsQuery,
  invalidateUseTicketActivityQuery,
  invalidateUseTicketQuery,
} from "../queries";

const api = new UrbintApi();

type useUpdateExpirationDateProps = Pick<
  ExpirationDateModalProps,
  "tickets"
> & {
  expiresOn: Date | null;
};

const setUpdateExpirationDate = async (
  tickets: ExpirationDateModalProps["tickets"],
  expiresOn: Date | null
) => {
  const payload =
    tickets.length === 1
      ? { expires_on: expiresOn }
      : tickets.map((element) => ({
          ticket: element.id,
          expires_on: expiresOn,
        }));

  const url: KnownLensEndpoints =
    tickets.length === 1
      ? `tickets/${tickets[0]?.id || 0}`
      : "tickets/bulk_action";

  return await api.patch(url, JSON.stringify(payload));
};

const useUpdateExpirationDate = () =>
  useMutation(
    ({ tickets, expiresOn }: useUpdateExpirationDateProps) =>
      setUpdateExpirationDate(tickets, expiresOn),
    {
      onSuccess: (_, { tickets }) => {
        tickets.forEach(({ id }) => {
          invalidateUseTicketQuery(id, true);
          invalidateUseTicketActivityQuery(id);
        });
        invalidateUseFlexTicketsQuery();
      },
    }
  );

export { useUpdateExpirationDate };
