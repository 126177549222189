import type {
  FleetLocationSerializer,
  TicketQueueSerializer,
  FleetTicketQueueItemSerializer,
} from "@/utils/damage-prevention";
import { parse } from "wellknown";

import { UrbintModel } from "@/utils/UrbintModel";
import { TicketQueue } from "./TicketQueue";

class FleetLocation extends UrbintModel<FleetLocationSerializer> {
  get userId() {
    return this.castNumber(this.data.user);
  }

  get email() {
    return this.data.email;
  }

  get firstName() {
    return this.data.first_name;
  }

  get lastName() {
    return this.data.last_name;
  }

  get phoneNumber() {
    return this.data.phone_number;
  }

  get timestamp() {
    return this.castDate(this.data.timestamp);
  }

  get location() {
    return parse(this.data.location);
  }

  get coords(): { lng: number; lat: number } | null {
    if (!this.location) return null;

    const { coordinates } = this.location;
    return {
      lng: this.castNumber(coordinates[0]),
      lat: this.castNumber(coordinates[1]),
    };
  }

  get latitude() {
    if (!this.location) return null;

    const { coordinates } = this.location;

    return this.castNumber(coordinates[1]);
  }

  get longitude() {
    if (!this.location) return null;

    const { coordinates } = this.location;

    return this.castNumber(coordinates[0]);
  }

  get ticketQueueItems() {
    return this.castArray<TicketQueueSerializer>(
      this.data.ticket_queue_items
    ).map((ticket) => new TicketQueue(ticket));
  }

  get ticketIds() {
    return this.castArray<TicketQueueSerializer>(this.data.ticket_queue_items)
      .map((ticket) => new TicketQueue(ticket))
      .map((item) => item.id);
  }

  get pendingTicketIds() {
    return this.castArray<FleetTicketQueueItemSerializer>(
      this.data.ticket_queue_items
    )
      .filter((ticket) => ticket.is_visited !== true)
      .map((ticket) => this.castNumber(ticket.ticket_id));
  }

  compare(): number {
    throw new Error("Method not implemented.");
  }

  serialize(): FleetLocationSerializer {
    throw new Error("Method not implemented.");
  }
}

export { FleetLocation };
