import type { MouseEventHandler } from "react";
import { useCallback } from "react";
import { useNavigate } from "@/hooks";

/**
 * Hook that helps introduce "link like" behaviour for scenarios
 * where using regular anchor tags isn't an option (if we want text selection)
 */
const useLinkClick = (url: string) => {
  const { navigate } = useNavigate();

  const handleClick: MouseEventHandler = useCallback(
    (e) => {
      e.stopPropagation();
      const openNewTab = e.metaKey || e.ctrlKey;
      navigate(url, openNewTab ? "_blank" : undefined);
    },
    [navigate, url]
  );

  const handleMouseUp: MouseEventHandler = useCallback(
    (e) => {
      if (e.button === 1 && !e.isDefaultPrevented()) {
        e.preventDefault();
        navigate(url, "_blank");
      }
    },
    [navigate, url]
  );

  return {
    handleClick,
    handleMouseUp,
  };
};

export { useLinkClick };
