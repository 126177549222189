import type { TicketQueueSerializer } from "@/utils/damage-prevention";
import { useMutation } from "@tanstack/react-query";

import { TicketQueue } from "@/models";
import { UrbintApi } from "@/utils/UrbintApi";
import { handleApiResponse } from "@/api/helpers";
import { invalidateCurrentTicketQueue } from "@/api";

const api = new UrbintApi();
const addItem = ({
  queue,
  mode,
}: {
  queue: TicketQueue;
  mode: "everything" | "visited-only";
}) => {
  const queueHasVisitedItems = queue.items.some((item) => item.isVisited);
  const queueHasItems = queue.items.length > 0;
  const body = { everything: mode === "everything" };

  const shouldRemoveVisitedItems =
    body.everything === false && queueHasVisitedItems;
  const shouldRemoveAllItems = body.everything === true && queueHasItems;

  if (!shouldRemoveVisitedItems && !shouldRemoveAllItems) {
    // There aren't any visited items, so this is a noop
    return Promise.resolve(queue);
  }

  return api
    .fetch<TicketQueueSerializer>(`ticket_queues/${queue.id}/clear`, {
      method: "POST",
      body: JSON.stringify(body),
    })
    .then(handleApiResponse)
    .then((res) => new TicketQueue(res));
};

/**
 * Two operating modes:
 *
 * - "everything" - remove all tickets from the queue
 * - "visited-only" - remove all visited tickets that have been
 */
const useClearTicketQueue = () =>
  useMutation(addItem, { onSuccess: invalidateCurrentTicketQueue });

export { useClearTicketQueue };
