import type { AnswerSerializer } from "@/utils/damage-prevention";
import isArray from "lodash/isArray";
import isDate from "lodash/isDate";
import isString from "lodash/isString";
import { UrbintModel } from "@/utils/UrbintModel";
import { QuestionAnswerType, Question } from "./Question";

const answerValidator = (
  type: QuestionAnswerType,
  answer: string | string[] | Date | undefined
) => {
  switch (type) {
    case QuestionAnswerType.TEXT_INPUT_FIELD:
    case QuestionAnswerType.COMMENT_INPUT_FIELD:
      return isString(answer) && answer && answer.trim().length > 0;
    case QuestionAnswerType.DATE_SELECTOR:
    case QuestionAnswerType.DATE_TIME_SELECTOR:
      return isDate(answer) || (isString(answer) && isDate(new Date(answer)));
    case QuestionAnswerType.MULTI_SELECT_PICKLIST:
      return isArray(answer) && answer.length > 0;
    default:
      return !!answer;
  }
};

class ResponseFormAnswer extends UrbintModel<AnswerSerializer> {
  get id() {
    return this.castNumber(this.data.id);
  }

  get answerValue() {
    if (
      this.answerType === QuestionAnswerType.MULTI_SELECT_PICKLIST ||
      this.answerType === QuestionAnswerType.FOLLOW_UP_TASK
    ) {
      return this.castString(this.data.answer_content).split(",");
    }

    return this.castString(this.data.answer_content);
  }

  get answerType() {
    return this.castString(this.data.answer_type);
  }

  get questionId() {
    return this.castNumber(this.data.question);
  }

  compare(instance: ResponseFormAnswer): number {
    return this.id - instance.id;
  }

  serialize(): AnswerSerializer {
    return this.data;
  }

  get populatedQuestion(): Question | undefined {
    return (
      (this.data.populated_question &&
        new Question(this.data.populated_question)) ||
      undefined
    );
  }
}

export { answerValidator, ResponseFormAnswer };
