import type { SlideHeaderProps } from "./SlideHeader.types";
import { Icon } from "@urbint/silica";
import { DialogHeaderWrapper, DialogTitle } from "@/components/Dialog";
import {
  getDamageThreatCardColors,
  getDamageThreatCardDetails,
} from "@/components/DamageThreatCard/DamageThreatCard.utils";

const SlideHeader = ({ slideInfo }: SlideHeaderProps) => {
  const { title, insightIcon } = getDamageThreatCardDetails(slideInfo);
  const { iconColor } = getDamageThreatCardColors(slideInfo.threatRank);

  return (
    <DialogHeaderWrapper className="rounded-t-lg pb-0 bg-white">
      <div className="flex flex-nowrap items-center">
        <Icon name={insightIcon} className={`text-3xl mr-3 ${iconColor}`} />
        <DialogTitle>{title}</DialogTitle>
      </div>
    </DialogHeaderWrapper>
  );
};

export { SlideHeader };
