import type { BaseVirtualItem, VirtualListProps } from "./VirtualList.types";
import classNames from "classnames";
import { useCallback, useEffect, useMemo } from "react";
import { useVirtual } from "react-virtual";

/**
 * An abstraction around [react-virtual](https://react-virtual.tanstack.com/).
 */
const VirtualList = <T extends BaseVirtualItem = BaseVirtualItem>({
  estimateItemHeight,
  items,
  scrollParentRef,
  onNeedsMoreItems,
  needsMoreItemsThreshold = 0,
  keyExtractor,
  ViewDelegate = (v) => <div key={v.index}>{v.item as string}</div>,
  fixedHeight,
  className,
  totalSize = items.length,
}: VirtualListProps<T>) => {
  // calculate the index representing the threshold for when onNeedsMoreItems
  // is called.
  const thresholdIndex = useMemo(
    () => items.length - 1 - Math.abs(needsMoreItemsThreshold),
    [items.length, needsMoreItemsThreshold]
  );

  const rowVirtualizer = useVirtual({
    estimateSize: useCallback(() => estimateItemHeight, [estimateItemHeight]),
    keyExtractor,
    overscan: 50,
    parentRef: scrollParentRef,
    size: totalSize,
  });
  const containerHeight = scrollParentRef?.current?.offsetHeight ?? 0;

  /**
   * Determines if the row at the given index is the threshold row and if so,
   * will call onNeedsMoreItems if
   *  1. it is actually defined
   *  2. it has not already been called
   */
  const fireThresholdCrossed = useCallback(
    (lastIndex: number) => {
      if (scrollParentRef?.current && onNeedsMoreItems) {
        // We're past the threshold when the rendered index
        // is higher than the threshold
        const pastThreshold =
          thresholdIndex < lastIndex && items.length <= totalSize;
        if (pastThreshold) {
          onNeedsMoreItems(lastIndex - items.length);
        }
      }
    },
    [
      items.length,
      onNeedsMoreItems,
      rowVirtualizer.virtualItems,
      containerHeight,
    ]
  );

  // It doesn't seem to re-measure properly on first render when items.length changes
  useEffect(() => {
    rowVirtualizer.measure();
  }, [totalSize]);

  return (
    <div
      className={classNames("relative w-full", className)}
      style={{ height: rowVirtualizer.totalSize }}
    >
      {rowVirtualizer.virtualItems.map((virtualRow) => {
        // We don't want the list to render all items
        // if the parent height collapses
        if (containerHeight < 20) return null;

        fireThresholdCrossed(virtualRow.index);
        const item = items[virtualRow.index];
        if (!item) return null;

        return (
          <div
            className="flex flex-1 absolute w-full top-0 left-0"
            style={{
              transform: `translateY(${virtualRow.start}px)`,
            }}
            key={virtualRow.key}
          >
            <div
              className="w-full relative"
              style={{ height: virtualRow.size }}
            >
              <div
                className="absolute top-0 left-0 w-full flex"
                ref={fixedHeight ? undefined : virtualRow.measureRef}
                data-testid="table-row"
              >
                <ViewDelegate
                  item={item}
                  index={virtualRow.index}
                  height={virtualRow.size}
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export { VirtualList };
