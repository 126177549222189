import type { Permissions } from "./types";

/**
 * A list of permissions copied from damage_prevention/api/permission.py.
 * For UrbintAuth internal use only. User permissions are embedded in the
 * currentUser object.
 */
enum DjangoAuthPermission {
  ACCESS_ADMIN_PANEL = "Access to Admin Panel",
  SHARE_SAVED_VIEW = "Share Saved View",
  CREATE_SAVED_VIEW = "Create A Saved View",
  FILTERS_AND_SORTING = "Access Filters & Sorting",
  VIEW_REPORTS = "View Reports",
  CREATE_TASKS = "Create Tasks",
  ASSIGN_TASKS = "Assign Tasks",
  EDIT_DELETE_MY_TASKS = "Edit/Delete My Tasks",
  EDIT_DELETE_ALL_TASKS = "Edit/Delete All Tasks",
  BATCHING_FUNCTIONS = "Batching Functions",
  BE_ASSIGNED_TO_LOCATOR_TASKS = "Be Assigned to Locator Tasks",
  BE_ASSIGNED_TO_INSPECTOR_TASKS = "Be Assigned to Inspector Tasks",
  EDIT_RESPONSE_FORM = "Edit Submitted Task Response Form",
  ADD_TASK_COMMENT = "Add Task Comment",
  ADD_TICKET_COMMENT = "Add Ticket Comment",
  CAN_MODIFY_TICKETS = "Can Modify Tickets",
  EDIT_TASK_STATUS = "Edit Task Status",
  CAMERA_UPLOAD_ACCESS = "Camera/Photo Upload Access",
  CAN_SUBMIT_RESPONSE_FORM = "Can Submit Response Form",
  DELETE_PHOTOS = "Delete Photos",
  REPORTS_EDIT_CHARTS_AND_DASHBOARDS = "Reports Edit Charts and Dashboards",
  REPORTS_SQL_ACCESS = "Reports SQL Access",
  REPORTS_DATA_IMPORTS = "Reports Data Imports",
  CAN_MARK_NOTIFICATION_READ = "Can Mark Notification Read",
  CAN_DISMISS_NOTIFICATION = "Can Dismiss Notification",
  CAN_MODIFY_TICKET_QUEUES = "Can Modify Ticket Queues",
  CAN_MERGE_TICKET_QUEUES = "Can Merge Ticket Queues",
  CAN_REMOVE_TICKET_QUEUE_ITEMS = "Can Remove Ticket Queue Items",
  CAN_FORWARD_TICKETS = "Can Forward Tickets",
  CAN_EMAIL_TICKETS = "Can Email Tickets",
  CAN_ACCESS_SETTINGS = "Can Access Settings",
  CREATE_NON_TICKETED_EVENTS = "Create Non-Ticketed Events",
  BE_ASSIGNED_TO_NON_TICKETED_EVENTS = "Be Assigned to Non-Ticketed Events",
  RESTRICT_LIST_TO_OWN_TICKETS = "Restrict ticket listing to tickets with owned tasks",
  EDIT_SUBMITTED_NON_TICKETED_EVENTS_RESPONSE_FORM = "Edit Submitted Non-Ticketed Event Response Form",
  EDIT_TICKET_LIFECYCLE = "Can edit Ticket lifecycle fields",
  CAN_VIEW_MODEL_EXPLAINER = "Can View Model Explainer",
}

function has(userLike: Permissions, permission: DjangoAuthPermission) {
  return (userLike.permissions ?? []).includes(permission);
}

const canAccessAdminPanel = (userLike: Permissions) =>
  has(userLike, DjangoAuthPermission.ACCESS_ADMIN_PANEL);
const canAccessBatchingFunctions = (userLike: Permissions) =>
  has(userLike, DjangoAuthPermission.BATCHING_FUNCTIONS);
const canAccessFiltersAndSorting = (userLike: Permissions) =>
  has(userLike, DjangoAuthPermission.FILTERS_AND_SORTING);
const canAccessReportsSql = (userLike: Permissions) =>
  has(userLike, DjangoAuthPermission.REPORTS_SQL_ACCESS);
const canAddTaskComment = (userLike: Permissions) =>
  has(userLike, DjangoAuthPermission.ADD_TASK_COMMENT);
const canAddTicketComment = (userLike: Permissions) =>
  has(userLike, DjangoAuthPermission.ADD_TICKET_COMMENT);
const canAssignTasks = (userLike: Permissions) =>
  has(userLike, DjangoAuthPermission.ASSIGN_TASKS);
const canBeAssignedToInspectorTasks = (userLike: Permissions) =>
  has(userLike, DjangoAuthPermission.BE_ASSIGNED_TO_INSPECTOR_TASKS);
const canBeAssignedToLocatorTasks = (userLike: Permissions) =>
  has(userLike, DjangoAuthPermission.BE_ASSIGNED_TO_LOCATOR_TASKS);
const canCreateSavedView = (userLike: Permissions) =>
  has(userLike, DjangoAuthPermission.CREATE_SAVED_VIEW);
const canCreateTasks = (userLike: Permissions) =>
  has(userLike, DjangoAuthPermission.CREATE_TASKS);
const canDeletePhotos = (userLike: Permissions) =>
  has(userLike, DjangoAuthPermission.DELETE_PHOTOS);
const canDismissNotification = (userLike: Permissions) =>
  has(userLike, DjangoAuthPermission.CAN_DISMISS_NOTIFICATION);
const canModifyTickets = (userLike: Permissions) =>
  has(userLike, DjangoAuthPermission.CAN_MODIFY_TICKETS);
const canModifyTicketQueues = (userLike: Permissions) =>
  has(userLike, DjangoAuthPermission.CAN_MODIFY_TICKET_QUEUES);
const canMergeTicketQueues = (userLike: Permissions) =>
  has(userLike, DjangoAuthPermission.CAN_MERGE_TICKET_QUEUES);
const canRemoveTicketQueueItems = (userLike: Permissions) =>
  has(userLike, DjangoAuthPermission.CAN_REMOVE_TICKET_QUEUE_ITEMS);
const canForwardTickets = (userLike: Permissions) =>
  has(userLike, DjangoAuthPermission.CAN_FORWARD_TICKETS);
const canEmailTickets = (userLike: Permissions) =>
  has(userLike, DjangoAuthPermission.CAN_EMAIL_TICKETS);
const canEditDeleteAllTasks = (userLike: Permissions) =>
  has(userLike, DjangoAuthPermission.EDIT_DELETE_ALL_TASKS);
const canEditDeleteMyTasks = (userLike: Permissions) =>
  has(userLike, DjangoAuthPermission.EDIT_DELETE_MY_TASKS);
const canEditReportsChartsAndDashboards = (userLike: Permissions) =>
  has(userLike, DjangoAuthPermission.REPORTS_EDIT_CHARTS_AND_DASHBOARDS);
const canEditResponseForm = (userLike: Permissions) =>
  has(userLike, DjangoAuthPermission.EDIT_RESPONSE_FORM);
const canEditTaskStatus = (userLike: Permissions) =>
  has(userLike, DjangoAuthPermission.EDIT_TASK_STATUS);
const canImportReportData = (userLike: Permissions) =>
  has(userLike, DjangoAuthPermission.REPORTS_DATA_IMPORTS);
const canMarkNotificationRead = (userLike: Permissions) =>
  has(userLike, DjangoAuthPermission.CAN_MARK_NOTIFICATION_READ);
const canShareSavedView = (userLike: Permissions) =>
  has(userLike, DjangoAuthPermission.SHARE_SAVED_VIEW);
const canSubmitResponseForm = (userLike: Permissions) =>
  has(userLike, DjangoAuthPermission.CAN_SUBMIT_RESPONSE_FORM);
const canUploadPhotos = (userLike: Permissions) =>
  has(userLike, DjangoAuthPermission.CAMERA_UPLOAD_ACCESS);
const canViewReports = (userLike: Permissions) =>
  has(userLike, DjangoAuthPermission.VIEW_REPORTS);
const canAccessSettings = (userLike: Permissions) =>
  has(userLike, DjangoAuthPermission.CAN_ACCESS_SETTINGS);
const canCreateNonTicketedEvents = (userLike: Permissions) =>
  has(userLike, DjangoAuthPermission.CREATE_NON_TICKETED_EVENTS);
const canBeAssignedToNonTicketedEvents = (userLike: Permissions) =>
  has(userLike, DjangoAuthPermission.BE_ASSIGNED_TO_NON_TICKETED_EVENTS);
const canOnlyViewTicketsWithOwnedTasks = (userLike: Permissions) =>
  has(userLike, DjangoAuthPermission.RESTRICT_LIST_TO_OWN_TICKETS);
const canEditSubmittedNonTicketedEventsResponseForm = (userLike: Permissions) =>
  has(
    userLike,
    DjangoAuthPermission.EDIT_SUBMITTED_NON_TICKETED_EVENTS_RESPONSE_FORM
  );
const canEditTicketLifeCycle = (userLike: Permissions) =>
  has(userLike, DjangoAuthPermission.EDIT_TICKET_LIFECYCLE);
const canViewModelExplainer = (userLike: Permissions) =>
  has(userLike, DjangoAuthPermission.CAN_VIEW_MODEL_EXPLAINER);

export {
  canAccessAdminPanel,
  canAccessBatchingFunctions,
  canAccessFiltersAndSorting,
  canAccessReportsSql,
  canAccessSettings,
  canAddTaskComment,
  canAddTicketComment,
  canAssignTasks,
  canBeAssignedToInspectorTasks,
  canBeAssignedToLocatorTasks,
  canBeAssignedToNonTicketedEvents,
  canCreateNonTicketedEvents,
  canCreateSavedView,
  canCreateTasks,
  canDeletePhotos,
  canDismissNotification,
  canEditDeleteAllTasks,
  canEditDeleteMyTasks,
  canEditReportsChartsAndDashboards,
  canEditResponseForm,
  canEditSubmittedNonTicketedEventsResponseForm,
  canEditTaskStatus,
  canEmailTickets,
  canForwardTickets,
  canImportReportData,
  canMarkNotificationRead,
  canMergeTicketQueues,
  canModifyTicketQueues,
  canModifyTickets,
  canRemoveTicketQueueItems,
  canShareSavedView,
  canSubmitResponseForm,
  canUploadPhotos,
  canViewReports,
  canOnlyViewTicketsWithOwnedTasks,
  canEditTicketLifeCycle,
  canViewModelExplainer,
};
