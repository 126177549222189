import type { SidebarActionContainerProps } from "./SidebarActionContainer.types";

const SidebarActionContainer = ({
  className,
  children,
}: SidebarActionContainerProps) => (
  <div
    className={`hidden md:block ${className} absolute bottom-0 left-0 w-full bg-gradient-to-b from-white to-gray-100 p-3 backdrop-filter backdrop-blur-sm`}
  >
    {children}
  </div>
);

export { SidebarActionContainer };
