import type { FilterDialogSectionProps } from "./FilterDialogSection.types";
import { IonIcon } from "@ionic/react";
import { addOutline, refreshOutline } from "ionicons/icons";
import { SelectMenu } from "@/components/Select";

const FilterDialogSection = <T,>({
  title,
  onReset,
  addOptions,
  onAdd,
  children,
  dataTestId,
}: FilterDialogSectionProps<T>) => (
  <section>
    <header className="flex items-center mb-3" data-testid={dataTestId}>
      <h2 className="font-semibold tracking-tight text-black text-opacity-88">
        {title}
      </h2>
      <section className="flex items-center ml-auto space-x-4">
        {onReset && (
          <button
            type="button"
            onClick={onReset}
            className="flex items-center p-2 text-sm font-semibold text-yellow-900 icon-semibold"
            data-testid="reset-filters-button"
          >
            <IonIcon icon={refreshOutline} className="mr-2" />
            Reset
          </button>
        )}
        {addOptions && (
          <SelectMenu
            options={addOptions}
            disabled={addOptions.length === 0}
            onSelect={onAdd}
            theme={{
              menuBtn: `rounded px-2 py-1 text-sm flex items-center text-white font-semibold icon-semibold
              ${
                addOptions.length === 0
                  ? "bg-gray-200 cursor-not-allowed"
                  : "bg-blue-600"
              }`,
            }}
          >
            <IonIcon
              icon={addOutline}
              className="mr-2"
              data-testid="add-filters-button"
            />
            Add
          </SelectMenu>
        )}
      </section>
    </header>
    {children}
  </section>
);

export { FilterDialogSection };
