import type { MapboxGeoJSONFeature, MapRef } from "react-map-gl";

import type { MapFeature } from "@/components/Map";
import type { FlexTicket } from "@/models";
import type { TicketsToShowProps } from "./MapPageMap.types";
import type { TicketId } from "../TicketFocusProvider/TicketFocus.types";
import { CLUSTER_POPUP_LAYOUT_CHANGE_ZOOM_VALUE } from "@/components/Map/Layers";

const getTicketListSortedBySideBarList = (
  sideBarTickets: FlexTicket[],
  mapTickets: Array<MapFeature>
) => {
  const orderMap = sideBarTickets
    .filter(Boolean)
    .reduce((acc: any, item: any, index: any) => {
      acc[item.id] = index;
      return acc;
    }, {});

  return mapTickets.sort((a, b) => orderMap[a.id] - orderMap[b.id]);
};

const setOverlapPointScrollLayout = (
  cluster: MapboxGeoJSONFeature,
  map: any,
  tickets: FlexTicket[],
  setTicketsToShow: React.Dispatch<
    React.SetStateAction<TicketsToShowProps | undefined>
  >
) => {
  const clusterId = cluster.properties?.cluster_id;
  const source = map.getSource("tickets");

  if (clusterId && source.getClusterLeaves) {
    const numberOfTicketsToShow = cluster.properties?.point_count;
    source.getClusterLeaves(
      clusterId,
      numberOfTicketsToShow,
      0,
      (_: any, ticketsPoints: Array<MapFeature>) => {
        const ticketsSorted = getTicketListSortedBySideBarList(
          tickets,
          ticketsPoints
        );

        if (cluster && cluster.geometry.type === "Point") {
          setTicketsToShow({
            tickets: ticketsSorted.map((ticket: MapFeature) => ({
              id: ticket.id,
            })),
            position: cluster.geometry.coordinates,
            offset: [0, 20],
          });
        }
      }
    );
  }
};

const handleFeatureInteraction = (
  features: MapboxGeoJSONFeature[],
  mapRef: React.RefObject<MapRef>,
  tickets: FlexTicket[],
  setClusterToShow: React.Dispatch<
    React.SetStateAction<MapboxGeoJSONFeature | undefined>
  >,
  setTicketsToShow: React.Dispatch<
    React.SetStateAction<TicketsToShowProps | undefined>
  >,
  setFocusedTicket: (ticketId?: TicketId) => void,
  isMeasureMode: boolean
) => {
  if (!features || isMeasureMode) return;

  const getCluster = features.find(
    (feature: MapboxGeoJSONFeature) => feature.properties?.cluster
  );

  if (getCluster) {
    if (!mapRef || !mapRef.current) return;
    const CurrentRefmap = mapRef.current.getMap();
    const getZoomLevel = CurrentRefmap.getZoom();
    if (getZoomLevel >= CLUSTER_POPUP_LAYOUT_CHANGE_ZOOM_VALUE) {
      setOverlapPointScrollLayout(
        getCluster,
        CurrentRefmap,
        tickets,
        setTicketsToShow
      );
      return;
    }

    setClusterToShow(getCluster);
    return;
  }

  const getPoint = features.find(Boolean);

  if (
    getPoint &&
    typeof getPoint.id === "number" &&
    getPoint.geometry.type === "Point"
  ) {
    setTicketsToShow({
      tickets: [
        {
          id: getPoint.id,
        },
      ],
      position: getPoint.geometry.coordinates,
    });
    setFocusedTicket(getPoint.id);
  }
};

const isClusterDataValid = (
  clusterToShow: MapboxGeoJSONFeature | undefined
): clusterToShow is MapboxGeoJSONFeature =>
  !!(
    clusterToShow &&
    clusterToShow?.geometry.type === "Point" &&
    clusterToShow?.geometry.coordinates &&
    clusterToShow.properties !== null
  );
export {
  setOverlapPointScrollLayout,
  getTicketListSortedBySideBarList,
  isClusterDataValid,
  handleFeatureInteraction,
};
