import type { FleetLocation } from "@/models";
import type { FieldMemberPopupProps } from "../../Popups";
import type { FieldMemberLayerProps } from "./FieldMemberLayer.types";
import React, { useCallback, useMemo, useState } from "react";
import { useFleetLocation } from "@/api";
import { useReportUserLocationUpdatesToFleet } from "@/hooks/useReportUserLocationUpdatesToFleet";
import {
  FieldMemberMarker,
  FieldMemberStatusType,
  getFieldMemberStatus,
} from "../../Markers";
import { FieldMemberPopup, INIT_FIELD_MEMBER_POPUP_PROPS } from "../../Popups";

const FieldMemberLayer = React.memo(({ shown }: FieldMemberLayerProps) => {
  if (!shown) return null;

  // Retrieves raw data.
  const { data } = useFleetLocation();
  useReportUserLocationUpdatesToFleet();

  // Sets marker hover state.
  const [isHovered, setIsHovered] = useState(false);

  // Sets information to be displayed in the `FieldMemberPopup`
  // whenever `FieldMemberMarker` is hovered.
  const [popupProps, setPopupProps] = useState<FieldMemberPopupProps>(
    INIT_FIELD_MEMBER_POPUP_PROPS
  );

  // Filters raw data and returns an array with valid data to display on the map.
  const fieldMembers = useMemo(
    () =>
      data?.filter((item) => {
        const { latitude, longitude, timestamp } = item;
        const memberStatus = getFieldMemberStatus(timestamp);

        return (
          memberStatus !== FieldMemberStatusType.NONE && latitude && longitude
        );
      }) ?? [],
    [data]
  );

  // Populates `Popup` component with `fleetMember` properties.
  const fillPopup = (fleetMember: FleetLocation) => {
    if (fleetMember.latitude && fleetMember.longitude) {
      setPopupProps({
        userId: fleetMember.userId,
        firstName: fleetMember.firstName,
        lastName: fleetMember.lastName,
        lastUpdate: fleetMember.timestamp,
        phoneNumber: fleetMember.phoneNumber,
        latitude: fleetMember.latitude,
        longitude: fleetMember.longitude,
        ticketCount: fleetMember.pendingTicketIds.length,
      });
    }
  };

  // Returns field member data based on given `userId`.
  const getUserData = (userId: number) =>
    fieldMembers.find((fm) => fm.userId === userId);

  // Handles the `onMouseOver` event in the following ways:
  // 1. fills `FieldMemberPopup` with hovered marker's information;
  // 2. updates `isHovered`.
  const handleMouseOver = useCallback(
    (userId) => {
      const userData = getUserData(userId);

      if (!userData) return;

      fillPopup(userData);
      setIsHovered(true);
    },
    [fieldMembers]
  );

  // Handles the `onMouseLeave` event by updating `isHovered`.
  const handleMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  return (
    <div>
      <div>
        {fieldMembers.map(
          (loc) =>
            loc.latitude &&
            loc.longitude && (
              <FieldMemberMarker
                key={`fieldMember${loc.userId}`}
                userId={loc.userId}
                firstName={loc.firstName}
                lastName={loc.lastName}
                lastUpdate={loc.timestamp}
                latitude={loc.latitude}
                longitude={loc.longitude}
                onMouseOver={handleMouseOver}
                onMouseLeave={handleMouseLeave}
              />
            )
        )}
      </div>
      {isHovered && (
        <FieldMemberPopup
          userId={popupProps.userId}
          firstName={popupProps.firstName}
          lastName={popupProps.lastName}
          lastUpdate={popupProps.lastUpdate}
          phoneNumber={popupProps.phoneNumber}
          latitude={popupProps.latitude}
          longitude={popupProps.longitude}
          ticketCount={popupProps.ticketCount}
        />
      )}
    </div>
  );
});
FieldMemberLayer.displayName = "FieldMemberLayer";

export { FieldMemberLayer };
