import type { DropdownSelectProps } from "./DropdownSelect.types";
import { useMemo, useState } from "react";
import isEqual from "lodash/isEqual";
import { Listbox, Transition } from "@headlessui/react";
import { Icon, UrbintBadge } from "@urbint/silica";
import cx from "classnames";
import { DropdownSelectOption } from "../DropdownSelectOption";
import { filterDuplicatesByLabel } from "../Select.utils";
import { DropdownSelectDefaultButton } from "./DropdownSelectDefaultButton";

const DropdownSelect = <T,>({
  testId = "",
  className = "",
  optionsClassName = "",
  buttonComponent = DropdownSelectDefaultButton,
  headerComponent,
  value,
  options,
  onChange,
  placeholder,
  disabled,
  label,
  comparator = isEqual,
  highlightSelectedOption = false,
  deSelection,
}: DropdownSelectProps<T>) => {
  const visibleOptions = filterDuplicatesByLabel(options);

  const selectedOption = useMemo(
    () => options?.find((x) => comparator(x.value, value)),
    [value, options, comparator]
  );

  const [renderUp, setRenderUp] = useState(false);

  const handleClick = () => {
    setTimeout(() => {
      const dropdown = document.querySelector("ul.ul-opened");
      if (dropdown !== null && dropdown instanceof HTMLElement) {
        const { bottom } = dropdown.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        setRenderUp(
          () => Math.round(bottom + dropdown.offsetHeight + 50) > viewportHeight
        );
      } else {
        setRenderUp(false);
      }
    }, 10);
  };

  const toggleValue = (selectedValue: T) => {
    if (comparator(value, selectedValue) && deSelection) {
      onChange(undefined as T);
    } else {
      onChange(selectedValue);
    }
  };

  return (
    <div className="w-full font-sans">
      {label && (
        <p className="font-semibold font-sans text-sm leading-5 mb-1 text-neutral-shade-secondary">
          {label}
        </p>
      )}
      <div className="relative">
        <Listbox
          value={value || ("" as T)}
          onChange={toggleValue}
          disabled={disabled}
        >
          {({ open }) => (
            <>
              {buttonComponent({
                testId,
                placeholder,
                className,
                selectedOption,
                onClick: handleClick,
              })}
              <Transition
                show={open}
                enter="transform transition ease-in duration-200"
                enterFrom="opacity-0 scale-y-0"
                enterTo="opacity-100 scale-y-100"
                leave="transform transition ease-in duration-200"
                leaveFrom="opacity-100 scale-y-100"
                leaveTo="opacity-0 scale-y-0"
                className={cx(
                  " absolute mb-1 w-full rounded-md bg-white shadow-lg z-10",
                  { "bottom-full origin-bottom": renderUp },
                  { "origin-top": !renderUp }
                )}
              >
                <Listbox.Options
                  static
                  className={`py-2 z-10 bg-white shadow-xl rounded font-normal max-h-56 overflow-y-auto ${optionsClassName} ${
                    open ? "ul-opened" : "ul-closed"
                  }`}
                >
                  {headerComponent && headerComponent({})}

                  {visibleOptions?.map((option) => (
                    <Listbox.Option
                      key={option.key || option.label}
                      value={option.value}
                    >
                      {({ active, selected }) => (
                        <DropdownSelectOption
                          active={active}
                          selected={selected}
                          highlightSelectedOption={highlightSelectedOption}
                        >
                          <p
                            data-testid="urbint-select-option-label"
                            className="flex-1 min-w-0 overflow-hidden overflow-ellipsis"
                          >
                            {option.label}
                          </p>
                          {option.isDefault && (
                            <UrbintBadge
                              color="gray"
                              text="Default"
                              className="shrink-0"
                              testId="default-badge"
                            />
                          )}
                          {option.icon && (
                            <Icon
                              data-testid="urbint-select-option-icon"
                              className="ml-auto pl-4 text-black text-opacity-66"
                              name={option.icon}
                            />
                          )}
                        </DropdownSelectOption>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </>
          )}
        </Listbox>
      </div>
    </div>
  );
};

export { DropdownSelect };
