import type { DeleteAttachmentModalProps } from "./DeleteAttachmentModal.types";
import { useState } from "react";
import { useIsMutating } from "@tanstack/react-query";
import { datadogRum } from "@datadog/browser-rum";
import { BodyText, Subheading } from "@urbint/silica";
import { useToasts } from "@/components/Toasts";
import {
  useDeleteNonTicketedEventAttachment,
  useDeleteTaskAttachment,
} from "@/api";
import { type dialogFooter, Dialog } from "@/components/Dialog";
import { useOnlineStatus } from "@/hooks";
import { deleteTaskAttachmentIdent } from "@/api/attachments/mutations/useDeleteTaskAttachment";
import { deleteNonTicketedEventAttachmentIdent } from "@/api/attachments/mutations/useDeleteNonTicketedEventAttachment";
import {
  getAttachmentType,
  isAttachmentFromNonTicketedEvent,
  isAttachmentFromTicket,
} from "../AttachmentsSection.utils";
import { DATADOG_DELETE_ATTACHMENT_ERROR } from "./DeleteAttachmentModal.constants";

const DeleteAttachmentModal = ({
  attachmentType,
  thumbState: { modalVisibility, thumbId },
  setModalVisibility,
}: DeleteAttachmentModalProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { addToast } = useToasts();
  const deleteTaskAttachment = useDeleteTaskAttachment();
  const deleteNonTicketedEventAttachment =
    useDeleteNonTicketedEventAttachment();

  const attachment = getAttachmentType(attachmentType);

  // --- Offline Support --- //
  const isOnline = useOnlineStatus();
  const deletedInOffline = useIsMutating({
    mutationKey: isAttachmentFromTicket(attachment)
      ? deleteTaskAttachmentIdent()
      : deleteNonTicketedEventAttachmentIdent(),
    predicate: (mutation) =>
      mutation?.state.isPaused &&
      mutation?.state.status === "loading" &&
      mutation?.state?.variables?.attachmentId === thumbId,
  });

  const onDeleteAttachment = async (e: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    // Prevent multiple submissions
    if (isSubmitting) {
      return;
    }

    try {
      setIsSubmitting(true);

      if (thumbId === null) {
        throw new Error("Attachment does not have thumbnail id");
      }

      // --- Offline Support --- //
      if (!isOnline) {
        setModalVisibility(false);
        addToast(
          "Attachment/s deleted while in offline, the action will be synced once we comeback online.",
          "info"
        );
        setIsSubmitting(false);
      }

      if (isAttachmentFromNonTicketedEvent(attachment)) {
        await deleteNonTicketedEventAttachment.mutateAsync(
          {
            eventId: attachment.eventId,
            attachmentId: thumbId,
          },
          {
            onSuccess: () => {
              setModalVisibility(false);
              addToast("Attachment deleted", "success");
            },
          }
        );
      } else if (isAttachmentFromTicket(attachment)) {
        await deleteTaskAttachment.mutateAsync(
          {
            ticketId: attachment.ticketId,
            taskId: attachment.taskId,
            attachmentId: thumbId,
          },
          {
            onSuccess: () => {
              setModalVisibility(false);
              addToast("Attachment deleted", "success");
            },
          }
        );
      }
    } catch (error) {
      if (error instanceof Error) {
        addToast(`Error deleting attachment: ${error.message}`, "error");
        datadogRum.addError(error, {
          errorIdentifier: DATADOG_DELETE_ATTACHMENT_ERROR,
        });
      } else {
        addToast("Error deleting attachment", "error");
      }
      setModalVisibility(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  const modalFooter: dialogFooter = {
    toRender: "Delete",
    onClose: () => setModalVisibility(false),
    onClick: (e) => onDeleteAttachment(e),
    submitButtonIsLoading: isSubmitting,
    submitButtontype: "danger",

    // --- Offline Support --- //
    submitButtonDisabled: !isOnline && deletedInOffline > 0,
  };

  return (
    <Dialog
      isOpen={modalVisibility}
      onClose={() => setModalVisibility(false)}
      header=""
      footer={modalFooter}
      variant="warning"
      theme={{ dialog: "z-20" }}
    >
      <Subheading>Are you sure you want to delete this Attachment?</Subheading>
      <BodyText className="mt-4">
        Once deleted, the attachment cannot be recovered.
        {!isOnline && deletedInOffline > 0 && (
          <p className="text-base font-semibold pt-4">
            This attachment was already requested to be deleted in offline mode.
          </p>
        )}
      </BodyText>
    </Dialog>
  );
};

export { DeleteAttachmentModal };
