import type { MapRef } from "react-map-gl";
import { isTicketCluster } from "../ClusterLayer.utils";
import { clusterLayer } from "../ClusterLayer.constants";

const getClusters = (map: MapRef) => {
  const uniqueIds = new Set<string | number>();
  return map
    .queryRenderedFeatures(undefined, {
      layers: [clusterLayer.id || ""],
      filter: ["has", "point_count"],
    })
    .filter((item) => {
      const { id } = item;
      if (id && !uniqueIds.has(id)) {
        uniqueIds.add(id);
        return isTicketCluster(item);
      }
      return false;
    });
};

export { getClusters };
