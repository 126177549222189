import { useState } from "react";
import cx from "classnames";

import { IonIcon } from "@ionic/react";
import { chevronBackOutline, chevronForwardOutline } from "ionicons/icons";

import { TicketTable } from "@/components/TicketTable";
import { TicketList } from "@/components/TicketList";
import { useOnlineStatus } from "@/hooks";
import { SidePanelCollapseLevel } from "./SidePanel.constants";
import { collapseSidePanel, expandSidePanel } from "./SidePanel.utils";

const SidePanel = () => {
  const isOnline = useOnlineStatus();
  const [sidePanelLevel, setSidePanelLevel] = useState(
    SidePanelCollapseLevel.TicketList
  );

  const handlePanelCollapse = () => {
    setSidePanelLevel(collapseSidePanel);
  };

  const handlePanelExpand = () => {
    setSidePanelLevel(expandSidePanel);
  };

  const expansionButtonClasses =
    "flex items-center justify-center w-6 h-10 bg-white";

  return (
    <section
      className={cx(
        `absolute w-[96%] xl:w-[98%] h-full left-0 top-0 shadow-2xl z-10 isolate
        transform-gpu transition duration-300 ease-in-out bg-gray-100`,
        sidePanelLevel === SidePanelCollapseLevel.Closed && "-translate-x-full",
        sidePanelLevel === SidePanelCollapseLevel.TicketList &&
          `translate-x-[calc(-100%+theme(spacing.sidebar))]`
      )}
    >
      {sidePanelLevel === SidePanelCollapseLevel.TicketTable ? (
        <TicketTable />
      ) : (
        <TicketList />
      )}
      {isOnline && (
        <>
          <div
            className={cx(
              `w-full bg-bluegray-900 h-full absolute top-0 right-0 transform-gpu translate-x-full transition-all duration-300`,
              sidePanelLevel === SidePanelCollapseLevel.TicketTable
                ? "opacity-80"
                : "opacity-0 pointer-events-none"
            )}
          />
          <div
            className="z-10 rounded-r flex-col absolute right-0 transform-gpu translate-x-full
                    top-2 divide-y divide-solid divide-gray-200 text-gray-400 shadow-xl
                    x-10 hidden md:block"
          >
            <button
              type="button"
              className={cx(
                expansionButtonClasses,
                `rounded-tr`,
                sidePanelLevel === SidePanelCollapseLevel.Closed && "hidden",
                sidePanelLevel === SidePanelCollapseLevel.TicketTable &&
                  "rounded-br"
              )}
              onClick={handlePanelCollapse}
              data-testid="collapse-panel-button"
            >
              <IonIcon icon={chevronBackOutline} />
            </button>
            <button
              type="button"
              className={cx(
                expansionButtonClasses,
                `rounded-br`,
                sidePanelLevel === SidePanelCollapseLevel.Closed &&
                  "rounded-tr",
                sidePanelLevel === SidePanelCollapseLevel.TicketTable &&
                  "hidden"
              )}
              onClick={handlePanelExpand}
              data-testid="expand-panel-button"
            >
              <IonIcon icon={chevronForwardOutline} />
            </button>
          </div>
        </>
      )}
    </section>
  );
};

export { SidePanel };
