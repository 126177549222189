import type { UseQueryOptions } from "@tanstack/react-query";
import type { UserSerializer } from "@/utils/damage-prevention";
import { useQuery } from "@tanstack/react-query";
import { User } from "@/models";
import { UrbintApi } from "@/utils/UrbintApi";
import { queryClient } from "@/api/client";
import { handleApiResponse } from "@/api/helpers";
import { usersIdent } from "@/api";

const api = new UrbintApi();

const userIdent = (userId: number) => ["users", userId];

const fetchUser = (userId: number) =>
  api
    .getOne<UserSerializer>({
      endPoint: "users",
      id: userId,
    })
    .then(handleApiResponse)
    .then((res) => new User(res));

const useUser = (userId: number, opts?: UseQueryOptions<User>) =>
  useQuery<User>(userIdent(userId), () => fetchUser(userId), {
    ...opts,
    initialData: () =>
      queryClient
        .getQueryData<User[]>(usersIdent())
        ?.find((u) => u.id === userId),
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(usersIdent())?.dataUpdatedAt,
  });

export { userIdent, useUser };
