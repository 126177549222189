import { IonPage } from "@ionic/react";
import { useParams } from "react-router-dom";
import { useResponsive } from "@/hooks";
import { useTicket } from "@/api";
import { TicketNotFoundPage } from "@/pages/TicketNotFoundPage";
import {
  TicketViewEditorProvider,
  useViewStore,
} from "../../components/TicketViewEditorProvider";
import { TicketPageDetails } from "./TicketPageDetails";
import { TicketPageSideBar } from "./TicketPageSideBar";
import { MobileHeader } from "./MobileHeader";
import { TaskStepProvider } from "./TicketPageDetails/Header/TopHeader/TasksSteps.provider";

const TicketPage = () => {
  const { dirtyView, savedView, updateDirtyView } = useViewStore();
  const { ticketId: ticketIdQueryParam } = useParams<{ ticketId: string }>();
  const ticketId = +ticketIdQueryParam;
  const { isMd } = useResponsive();
  const { data, error } = useTicket(ticketId, undefined, true);

  if (error) {
    return <TicketNotFoundPage error={error} />;
  }

  return (
    <IonPage id="ticket-page">
      <TicketViewEditorProvider
        savedView={savedView}
        onDirtyViewChange={updateDirtyView}
        initialDirtyView={dirtyView}
      >
        <TaskStepProvider>
          {!isMd && <MobileHeader ticket={data} />}
          <div
            id="ticket-page-content"
            className="w-full h-full flex flex-col md:flex-row flex-grow font-sans overflow-y-auto isolate bg-backgrounds-white"
          >
            <TicketPageDetails ticket={data} />
            <TicketPageSideBar ticket={data} />
          </div>
        </TaskStepProvider>
      </TicketViewEditorProvider>
    </IonPage>
  );
};

export { TicketPage };
