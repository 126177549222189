import type { TaskResponseFormQuestionHeaderProps } from "./TaskResponseFormQuestionHeader.types";

const TaskResponseFormQuestionHeader = ({
  children,
  className = "",
}: TaskResponseFormQuestionHeaderProps) => (
  <h2
    className={`text-neutral-shade-secondary font-semibold text-sm mb-2 ${className}`}
  >
    {children}
  </h2>
);

export { TaskResponseFormQuestionHeader };
