import type {
  DamageThreatIconProps,
  DamageThreatInfoProps,
  DamageThreatProps,
} from "./DamageThreatCard.types";
import { Icon, Subheading } from "@urbint/silica";
import { ListItem } from "@/common/ListItem";
import { Button } from "@/common/Button";
import { ThreatRank } from "@/models/DamageThreatInsight";
import {
  getDamageThreatCardColors,
  getDamageThreatCardDetails,
} from "./DamageThreatCard.utils";
import { ThreatBadge } from "./ThreatBadge";

const DamageThreatIcon = ({ name, color }: DamageThreatIconProps) => (
  <Icon name={name} className={`text-5xl ${color}`} />
);

const DamageThreatInfo = ({
  title,
  threatRank,
  openDamageThreatSlider,
}: DamageThreatInfoProps) => (
  <div className="flex flex-col flex-grow gap-2 py-1">
    <Subheading className="text-sm">{title}</Subheading>
    <div className="flex justify-between">
      <ThreatBadge threatRank={threatRank} />
      <Button
        className="h-7 text-sm"
        variant="secondary"
        disabled={threatRank === ThreatRank.NA}
        onClick={() => openDamageThreatSlider()}
      >
        Learn More
      </Button>
    </div>
  </div>
);

const DamageThreatCard = ({
  data,
  openDamageThreatSlider,
}: DamageThreatProps) => {
  const { threatRank } = data;
  const { title, insightIcon } = getDamageThreatCardDetails(data);
  const { backgroundColor, borderColor, iconColor } = getDamageThreatCardColors(
    data.threatRank
  );

  return (
    <ListItem
      withRadius
      className={`w-full border ${backgroundColor} ${borderColor}`}
      leftSlot={<DamageThreatIcon name={insightIcon} color={iconColor} />}
      centerSlot={
        <DamageThreatInfo
          title={title}
          threatRank={threatRank}
          openDamageThreatSlider={openDamageThreatSlider}
        />
      }
    />
  );
};
export { DamageThreatCard };
