import type { TicketMarkerProps } from "./TicketMarker.types";
import React from "react";
import { Marker } from "react-map-gl";
import cx from "classnames";
import { CircleSVG } from "@/components/Map/MapLegend/RiskIcon/CircleSVG/CircleSVG";
import { riskColor } from "@/format";
import { CIRCLE_RADIUS } from "./TicketMarker.constants";

// TODO - review if this has to be memoized
const TicketMarker = React.memo(
  ({
    scoreType, // is always "damage"
    damage,
    impact, // ... which means this is unused
    latitude,
    longitude,
    onClick, // unused
    onHoverChange, // unused
    className,
    children, // unused
  }: TicketMarkerProps) => {
    const color = riskColor(scoreType === "damage" ? damage : impact, "text");

    return (
      <Marker
        latitude={latitude}
        longitude={longitude}
        offset={[-CIRCLE_RADIUS, -CIRCLE_RADIUS]}
      >
        {/* this cursor-pointer makes no sense since there's no associated action for the marker */}
        <div
          className={cx("cursor-pointer", color, className)}
          onClick={() => onClick?.()}
          onMouseEnter={() => onHoverChange?.(true)}
          onMouseLeave={() => onHoverChange?.(false)}
        >
          {/* This CircleSVG doesn't tell us nothing, it's just a dot that's used in multiple places,
          at least rename this */}
          <CircleSVG />
          {children}
        </div>
      </Marker>
    );
  }
);
/**
 * Using a named function to solve the missing display name
 * would throw a prefer-arrow-callback warning, and its fix
 * was to remove the function, going back to the initial state.
 * This way, there's no lint issue.
 */
TicketMarker.displayName = "TicketMarker";
export { TicketMarker };
