import type { TicketRisks } from "../SummaryCard.types";
import { useParams } from "react-router";
import { useDamagePreventionAuth } from "@/hooks";
import { usePublicConfig, useTicket } from "@/api";
import { Risk } from "../Risk/Risk";
import { getTicketRisks } from "../SummaryCard.utils";

const RiskSummary = () => {
  const { ticketId } = useParams<{ ticketId: string }>();
  const { data: ticket } = useTicket(+ticketId, undefined, true);
  const {
    currentUser: { canViewModelExplainer },
  } = useDamagePreventionAuth();

  const { data: publicConfig } = usePublicConfig();

  if (!ticket) return null;

  const { latestVersion, id } = ticket;

  if (!latestVersion) return null;

  const { damagePotential, impactPotential } = latestVersion;
  const ticketRisks = getTicketRisks(
    {
      risk: damagePotential,
      isEnable:
        publicConfig !== undefined &&
        publicConfig.enableThreatExplainer &&
        canViewModelExplainer,
    },
    {
      risk: impactPotential,
      isEnable:
        publicConfig !== undefined &&
        publicConfig.enableThreatExplainer &&
        canViewModelExplainer,
    }
  );

  return (
    <section className="px-4 py-4 pb-6 md:px-8 lg:px-4 lg:pb-4 bg-bluegray-50">
      <h3 className="mb-4 font-semibold text-black text-opacity-88">
        Risk summary
      </h3>
      <ul className="space-y-4">
        {ticketRisks.map((props: TicketRisks) => (
          <Risk
            key={props.riskName}
            {...props}
            ticketLatestVersion={latestVersion}
            ticketId={id}
          />
        ))}
      </ul>
    </section>
  );
};

export { RiskSummary };
