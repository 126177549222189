import type { ActivityListProps } from "./ActivityList.types";
import { ActivityGroupListItem } from "./ActivityGroupListItem";

const ActivityList = ({
  groupedActivities,
  ticket,
  onPhotoClick,
  wrapperClassItem,
}: ActivityListProps) => (
  <ul className="space-y-7 md:ml-1" data-testid="ticket-activity-list">
    {groupedActivities.map((activities) => (
      <ActivityGroupListItem
        key={activities[0]!.id}
        ticket={ticket}
        onPhotoClick={onPhotoClick}
        activities={activities}
        wrapperClass={wrapperClassItem}
      />
    ))}
  </ul>
);

export { ActivityList };
