import type { FormEvent } from "react";
import type { BulkAddTicketCommentsModalProps } from "./BulkAddTicketCommentsModal.types";
import type { dialogFooter } from "@/components/Dialog";
import { useState } from "react";
import { useToasts } from "@/components/Toasts";
import { AutoGrowingTextArea } from "@/components/AutoGrowingTextArea";
import { pluralize } from "@/utils";
import { useBulkCreateTicketComments } from "@/api";
import { Dialog } from "@/components/Dialog";

const BulkAddTicketCommentsModal = ({
  isOpen,
  onClose,
  tickets,
}: BulkAddTicketCommentsModalProps) => {
  const [commentText, setCommentText] = useState<string>("");
  const { addToast } = useToasts();
  const bulkCreateTicketComments = useBulkCreateTicketComments();

  const handleSubmit = (e?: FormEvent) => {
    e?.preventDefault();
    if (!commentText.length) return;
    const payload = Array.from(tickets).map((ticket) => ({
      ticket,
      comment: commentText,
    }));

    bulkCreateTicketComments.mutate(payload, {
      onSuccess: () => {
        addToast(
          `Comment added to ${pluralize(tickets.length, "ticket")}.`,
          "success"
        );
        onClose();
      },
      onError: () => {
        addToast(
          `Failed to add comments for ${pluralize(tickets.length, "ticket")}.`,
          "error"
        );
        onClose();
      },
    });
  };

  const modalFooter: dialogFooter = {
    toRender: "Add comment",
    onClose,
    cancelButtonDisabled: bulkCreateTicketComments.isLoading,
    submitButtonTestId: "bulk-add-ticket-comments-action-button",
    submitButtonIsLoading: bulkCreateTicketComments.isLoading,
    submitButtonDisabled: commentText === "",
    onClick: handleSubmit,
  };
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      afterClose={() => setCommentText("")}
      header={`Add comment on ${pluralize(tickets.length, "ticket")}`}
      footer={modalFooter}
    >
      <form onSubmit={handleSubmit}>
        <p className="mt-4 text-sm font-bold text-neutral-shade-secondary mb-1">
          Comment
        </p>
        <AutoGrowingTextArea
          className="min-h-[75px]"
          onCtrlOrMetaEnter={handleSubmit}
          value={commentText}
          onChange={setCommentText}
          testId="bulk-add-comment-input"
        />
      </form>
    </Dialog>
  );
};

export { BulkAddTicketCommentsModal };
