import type { ContainerProps } from "./Container.types";

const Container = ({ children, className = "" }: ContainerProps) => (
  <div className="flex justify-center h-full w-full">
    <div
      className={`max-w-2xl w-full mx-4 md:ml-8 md:mr-6 lg:ml-10 lg:mr-6 xl:mx-0 ${className}`}
    >
      {children}
    </div>
  </div>
);

export { Container };
