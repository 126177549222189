const VARIANT_STYLES: Record<string, string> = {
  primary:
    "bg-brand-50 hover:bg-brand-40 text-white shadow-5 hover:shadow-10 active:bg-brand-40",
  secondary: "bg-white hover:bg-gray-200 shadow-5 hover:shadow-10",
  tertiary:
    "bg-transparent hover:bg-neutral-shade-background-light shadow-none active:bg-neutral-shade-background-medium active:shadow-inner",
  danger: "bg-system-error-40 hover:bg-system-error-50 text-white",
  none: "",
};

export { VARIANT_STYLES };
