import type { NonTicketedEventSummary } from "@/api";
import { useMutation } from "@tanstack/react-query";

import { UrbintApi } from "@/utils/UrbintApi";
import { handleApiResponse } from "@/api/helpers";
import { invalidateInfiniteNonTicketedEvents } from "@/api";

type IUseChangeNonTicketedEventLocation = {
  event: NonTicketedEventSummary;
  location: string | null;
};

const api = new UrbintApi();

const changeNonTicketedEventLocation = (
  opts: IUseChangeNonTicketedEventLocation
) =>
  api
    .patch(
      "non_ticketed_events",
      JSON.stringify({
        location: opts.location ?? null,
      }),
      opts.event.id
    )
    .then(handleApiResponse);

const useChangeNonTicketedEventLocation = () =>
  useMutation(changeNonTicketedEventLocation, {
    onSuccess: () => {
      invalidateInfiniteNonTicketedEvents();
    },
  });

export { useChangeNonTicketedEventLocation };
