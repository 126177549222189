import type { InsightDataSerializer } from "@/utils/damage-prevention";
import { UrbintModel } from "@/utils/UrbintModel";

enum InsightName {
  ASSET_TYPE = "ASSET_TYPE",
  ASSET_CLASSIFICATION = "ASSET_CLASSIFICATION",
  BORING = "BORING",
  HIGH_PROFILE_BUILDING = "HIGH_PROFILE_BUILDING",
  OUTAGE_ASSET_CRITERIA = "OUTAGE_ASSET_CRITERIA",
  REPAIR_ASSET_CRITERIA = "REPAIR_ASSET_CRITERIA",
  THREAT = "THREAT",
  EXCAVATOR_RANK = "EXCAVATOR_RANK",
  TICKET_TYPE = "TICKET_TYPE",
  WORK_TYPE_RISK = "WORK_TYPE_RISK",
  LOCATION_COMPLEXITY = "LOCATION_COMPLEXITY",
  NOT_FOUND = "NOT_FOUND",
}

function mapStringToEnum(inputString: string): InsightName {
  const enumValues = Object.values(InsightName);

  if (enumValues.includes(inputString as InsightName)) {
    return inputString as InsightName;
  }
  // Handle the case where the input string doesn't match any Enum value
  return InsightName.NOT_FOUND;
}

class Insight extends UrbintModel<InsightDataSerializer> {
  get id() {
    return this.castNumber(this.data.id);
  }

  get insightNameType(): InsightName {
    return mapStringToEnum(this.castString(this.data.insight_name));
  }

  get insightType() {
    return this.castString(this.data.insight_type);
  }

  serialize(): InsightDataSerializer {
    return this.data;
  }

  compare(): number {
    throw new Error("Method not implemented.");
  }
}

export { Insight, InsightName };
