// gas
import regTownBorder from "@/assets/images/reg_town_border.png";
import regCityGate from "@/assets/images/reg_city_gate.png";
import regDistrict from "@/assets/images/reg_district.png";
import regHpCustomer from "@/assets/images/reg_hp_customer.png";
import regLpCustomer from "@/assets/images/reg_lp_customer.png";
import regUnknown from "@/assets/images/reg_unknown.png";
import valve from "@/assets/images/valve.png";

// electric
import fuse from "@/assets/images/fuse.png";
import light from "@/assets/images/light.png";
import pedestal from "@/assets/images/pedestal.png";
import pole from "@/assets/images/pole.png";
import elecSwitch from "@/assets/images/elecSwitch.png";
import surfaceStructure from "@/assets/images/surfaceStructure.png";
import transformer from "@/assets/images/transformer.png";
import manhole from "@/assets/images/manhole.png";
import pullBox from "@/assets/images/pullBox.png";
import spliceBox from "@/assets/images/spliceBox.png";
import splice from "@/assets/images/splice.png";
import accessPoint from "@/assets/images/accessPoint.png";
import building from "@/assets/images/building.png";
import servicePoint from "@/assets/images/servicePoint.png";
import distributionSubstation from "@/assets/images/distributionSubstation.png";
import transmissionSubstation from "@/assets/images/transmissionSubstation.png";
import distributionVault from "@/assets/images/distributionVault.png";
import networkVault from "@/assets/images/networkVault.png";

// damages
import damageIcon from "@/assets/images/damage.png";
import damageWithTicketIcon from "@/assets/images/damage_with_ticket.png";

const GEO_ASSETS_IMAGES = [
  manhole,
  regTownBorder,
  regCityGate,
  regDistrict,
  regHpCustomer,
  regLpCustomer,
  regUnknown,
  valve,
  fuse,
  light,
  pedestal,
  pole,
  elecSwitch,
  surfaceStructure,
  transformer,
  pullBox,
  spliceBox,
  splice,
  accessPoint,
  building,
  servicePoint,
  distributionSubstation,
  transmissionSubstation,
  distributionVault,
  networkVault,
  damageIcon,
  damageWithTicketIcon,
];

export { GEO_ASSETS_IMAGES };
