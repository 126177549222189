import type { DropdownSelectDefaultButtonProps } from "@/components/Select";
import type { ScoreType } from "../../Map.types";
import { Listbox } from "@headlessui/react";
import { Icon } from "@urbint/silica";

const LegendTypeSelector = ({
  selectedOption,
}: DropdownSelectDefaultButtonProps<ScoreType>) => (
  <Listbox.Button className="flex flex-row items-center rounded bg-neutral-shade-background-light p-1 mb-3 w-full relative">
    <Icon
      name="tag-outline"
      className="mr-[10px] text-base text-neutral-shade-tertiary "
    />
    <span className="text-neutral-shade-primary text-base font-normal">
      {selectedOption?.label}
    </span>
  </Listbox.Button>
);

export { LegendTypeSelector };
