import type { UseQueryOptions } from "@tanstack/react-query";
import type { TicketFilterFieldKey } from "@/utils/damage-prevention";
import type { SelectOption } from "@/components/Select";
import { useQuery } from "@tanstack/react-query";
import { UrbintApi } from "@/utils/UrbintApi";
import { startCase } from "@/format";

const api = new UrbintApi();

enum FilterType {
  Default = 0,
  Typeahead = 0x01,
  TypeaheadMultiple = 0x02,
  SelectOne = 0x04,
  SelectMultiple = 0x08,
  String = 0x10,
  Number = 0x11,
  Multiline = 0x12,
  Date = 0x14,
  Boolean = 0x18,
  RangeSlider = 0x20,
}

type FieldAvailableFilterDefinition = {
  type: FilterType;
  field: TicketFilterFieldKey;
};

type FieldFilterDefinition =
  | {
      type:
        | FilterType.Boolean
        | FilterType.Date
        | FilterType.Multiline
        | FilterType.Number
        | FilterType.String
        | FilterType.RangeSlider;
      field: TicketFilterFieldKey;
      options: null;
    }
  | {
      type: FilterType.SelectMultiple | FilterType.SelectOne;
      field: TicketFilterFieldKey;
      options: [string, string][];
    }
  | {
      type: FilterType.Typeahead | FilterType.TypeaheadMultiple;
      field: TicketFilterFieldKey;
      options: ([number, string] | [number, string, string])[];
    };

function castFields(
  fields: Partial<Record<TicketFilterFieldKey, FieldFilterDefinition>>
): Partial<Record<TicketFilterFieldKey, FieldFilterDefinition>> {
  const converted: Partial<
    Record<TicketFilterFieldKey, FieldFilterDefinition>
  > = {};
  for (const key in fields) {
    // Sigh... why can't typescript infer this?
    const typedKey = key as TicketFilterFieldKey;
    switch (fields[typedKey]?.type as FilterType) {
      case FilterType.Boolean:
      case FilterType.Date:
      case FilterType.Multiline:
      case FilterType.Number:
      case FilterType.SelectMultiple:
      case FilterType.SelectOne:
      case FilterType.String:
      case FilterType.Typeahead:
      case FilterType.TypeaheadMultiple:
      case FilterType.RangeSlider:
        converted[typedKey] = { ...fields[typedKey]!, field: typedKey };
        break;
      default:
        throw new TypeError(
          `${fields[typedKey]?.type} is not a recognized field filter type`
        );
    }
  }
  return converted;
}

const AvailableTicketFilters = () =>
  useQuery(["available_ticket_filters"], () =>
    api
      .getOne({ endPoint: "tickets", action: "available_filters" })
      .then(({ error, json }) => {
        if (error) {
          throw error;
        }

        const fields: Partial<
          Record<TicketFilterFieldKey, FieldAvailableFilterDefinition>
        > = {};
        for (const field of json as FieldAvailableFilterDefinition[]) {
          fields[field.field] = field;
        }
        return fields;
      })
  );

type FilterOption = [number, string] | [string, string];

function startCaseValues(
  field: FieldFilterDefinition | undefined,
  formatCasing: boolean
): SelectOption<any>[] {
  if (!field || !field.options) {
    return [];
  }
  return field.options.map(([value, label, extraLabel]) => {
    const fullLabel = extraLabel ? `${label}: ${extraLabel}` : label;
    return {
      label: formatCasing ? startCase(fullLabel) : fullLabel,
      value,
    };
  });
}

const useTicketFilterOptions = (
  field: TicketFilterFieldKey,
  formatCasing: boolean,
  opts?: UseQueryOptions<SelectOption<any>[]>
) =>
  useQuery<SelectOption<any>[]>(
    [`ticket_filters_${field}`],
    () =>
      api
        .getOne({
          endPoint: "tickets",
          action: "filters",
          queryParams: { filters: field },
        })
        .then(({ error, json }) => {
          if (error) {
            throw error;
          }
          const fieldData = castFields(json as any)[field];
          return startCaseValues(fieldData, formatCasing);
        }),
    opts
  );

export type { FieldAvailableFilterDefinition, FilterOption };
export { FilterType, AvailableTicketFilters, useTicketFilterOptions };
