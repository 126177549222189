import type { UseQueryOptions } from "@tanstack/react-query";
import type { TaskTypeSerializer } from "@/utils/damage-prevention";
import { useQuery } from "@tanstack/react-query";
import { TaskType } from "@/models";
import { UrbintApi } from "@/utils/UrbintApi";
import { queryClient } from "@/api/client";
import { handleApiResponse } from "@/api/helpers";
import { taskTypesIdent } from "@/api";

const api = new UrbintApi();

const taskTypeIdent = (taskTypeId: number) => ["task_types", taskTypeId];

const fetchTaskType = (taskTypeId: number) =>
  api
    .getOne<TaskTypeSerializer>({ endPoint: "task_types", id: taskTypeId })
    .then(handleApiResponse)
    .then((r) => new TaskType(r));

const useTaskType = (taskTypeId: number, opts?: UseQueryOptions<TaskType>) =>
  useQuery<TaskType>(
    taskTypeIdent(taskTypeId),
    () => fetchTaskType(taskTypeId),
    {
      ...opts,
      initialData: () =>
        queryClient
          .getQueryData<TaskType[]>(taskTypesIdent())
          ?.find((t) => t.id === taskTypeId),
      initialDataUpdatedAt: () =>
        queryClient.getQueryState(taskTypesIdent())?.dataUpdatedAt,
    }
  );

const prefetchTaskType = (taskTypeId: number) =>
  queryClient.prefetchQuery(taskTypeIdent(taskTypeId), () =>
    fetchTaskType(taskTypeId)
  );

export { useTaskType, prefetchTaskType };
