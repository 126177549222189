import type { FilterDialogHeaderProps } from "./FilterDialogHeader.types";
import { EditingHeader } from "./EditingHeader";
import { DefaultHeader } from "./DefaultHeader";

const FilterDialogHeader = ({
  isEditing,
  onClose,
  viewName,
  setFilterDialogState,
  error,
  warning,
  onDelete,
  editMode,
}: FilterDialogHeaderProps) => (
  <header className="p-6 pb-4 relative flex items-center">
    {isEditing ? (
      <EditingHeader
        setFilterDialogState={setFilterDialogState}
        editMode={editMode}
        error={error}
        viewName={viewName}
        warning={warning}
      />
    ) : (
      <DefaultHeader
        setFilterDialogState={setFilterDialogState}
        onDelete={onDelete}
        viewName={viewName}
        onClose={onClose}
      />
    )}
  </header>
);

export { FilterDialogHeader };
