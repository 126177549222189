import type { GeocodeInputTextFieldProps } from "./GeocodeInputTextField.types";
import { makeStyles, TextField } from "@material-ui/core";

import { GeocodeInputFieldError } from "./GeocodeInputFieldError";
import { GeocodeInputEndAdornment } from "./GeocodeInputEndAdornment";

const useStyles = makeStyles({ root: { fontSize: "14px" } });

const GeocodeInputTextField = ({
  busy,
  error,
  mode,
  onClear,
  onClickUseCurrentLocation,
  ...inputParams
}: GeocodeInputTextFieldProps) => {
  const muiClasses = useStyles();
  const { InputProps, placeholder, disabled, fullWidth, id, inputProps } =
    inputParams;

  return (
    <div>
      <TextField
        {...inputProps}
        fullWidth={fullWidth}
        id={id}
        placeholder={placeholder}
        size="small"
        type="combobox"
        variant="outlined"
        InputProps={{
          ...InputProps,
          error: Boolean(error),
          className: muiClasses.root,
          endAdornment: (
            <GeocodeInputEndAdornment
              busy={busy}
              disabled={disabled}
              mode={mode}
              onClear={onClear}
              onClickUseCurrentLocation={onClickUseCurrentLocation}
            />
          ),
        }}
      />
      {error ? <GeocodeInputFieldError error={error} /> : null}
    </div>
  );
};

export { GeocodeInputTextField };
