import type {
  FilterDialogMenuAction,
  FilterDialogState,
} from "../../FilterDialog.types";
import type { DefaultHeaderProps } from "./DefaultHeader.types";
import { useCallback, useMemo } from "react";
import { IonIcon } from "@ionic/react";
import { createOutline, ellipsisHorizontal, people } from "ionicons/icons";
import { UrbintBadge, Icon } from "@urbint/silica";
import { DialogHeaderButtons, DialogTitle } from "@/components/Dialog";
import { useTicketViewEditor } from "@/components/TicketViewEditorProvider";
import { useDamagePreventionAuth } from "@/hooks";
import { Tooltip } from "@/common/Tooltip";
import { SelectMenu } from "@/components/Select";
import { Button } from "@/common/Button";
import { useUpdatePreferences } from "@/api";
import { getMenuOptions } from "./DefaultHeader.utils";

const DefaultHeader = ({
  setFilterDialogState,
  onDelete,
  viewName,
  onClose,
}: DefaultHeaderProps) => {
  const {
    isDirty,
    isShared,
    dirtyView,
    setDirtyView,
    resetView,
    toSavedView,
    savedView,
  } = useTicketViewEditor();
  const {
    currentUser: { canCreateSavedView, djangoId },
  } = useDamagePreventionAuth();
  const canEdit = canCreateSavedView && djangoId === dirtyView.userId;
  const menuOptions = useMemo(
    () =>
      getMenuOptions(
        isDirty,
        canEdit,
        canCreateSavedView,
        savedView?.isDefaultView || false
      ),
    [isDirty, canEdit, canCreateSavedView]
  );
  const hasMenuOptions = menuOptions.length > 0;
  const { mutate } = useUpdatePreferences();

  const handleMenuSelect = useCallback(
    (val: FilterDialogMenuAction) => {
      switch (val) {
        case "create":
          setDirtyView((dirtyView) => ({
            ...dirtyView,
            name: "",
            userId: djangoId,
            isShared: false,
            createdOn: undefined,
            updatedOn: undefined,
          }));
          return setFilterDialogState(
            (state: FilterDialogState): FilterDialogState => ({
              ...state,
              editMode: "create",
            })
          );
        case "edit":
          return setFilterDialogState((state: FilterDialogState) => ({
            ...state,
            editMode: "edit",
          }));
        case "reset":
          return resetView();
        case "delete":
          if (window.confirm("Permanently delete this view?")) {
            onDelete(toSavedView(dirtyView));
          }
          break;
        case "setDefault":
          if (savedView && savedView.id) {
            mutate(savedView.id);
          }
          break;
        // no default
      }
    },
    [djangoId, dirtyView, onDelete, resetView, setDirtyView, toSavedView]
  );
  return (
    <section className="flex flex-1 justify-between items-center">
      <div className="flex flex-1 items-baseline space-x-3">
        <Tooltip
          theme={{
            tooltip: "w-max sm:max-w-[280px] max-w-[85vw]",
          }}
          title={viewName}
        >
          <DialogTitle className="line-clamp-1">{viewName}</DialogTitle>
        </Tooltip>
        {savedView?.isDefaultView && (
          <UrbintBadge
            color="gray"
            text="Default"
            className="shrink-0"
            testId="filters-default-badge"
          />
        )}
        <div className="flex self-center text-opacity-33 text-xl text-black space-x-3">
          {isShared && (
            <Tooltip title="Shared with organization">
              <IonIcon icon={people} />
            </Tooltip>
          )}
          {isDirty && (
            <Tooltip title="This view has unsaved changes">
              <IonIcon icon={createOutline} />
            </Tooltip>
          )}
        </div>
      </div>
      <DialogHeaderButtons variant="relative">
        {hasMenuOptions && (
          <SelectMenu
            options={menuOptions}
            onSelect={handleMenuSelect}
            theme={{
              close: {
                menuBtn: "bg-neutral-shade-background-medium",
              },
              menuBtn: "w-9 h-9 flex items-center justify-center rounded",
            }}
          >
            <IonIcon
              data-testid="filter-modal-menu-button"
              icon={ellipsisHorizontal}
            />
          </SelectMenu>
        )}
        <Button
          data-testid="filter-modal-close-button"
          className="flex w-9 h-9 px-0 py-0"
          onClick={onClose}
        >
          <Icon name="close_big" />
        </Button>
      </DialogHeaderButtons>
    </section>
  );
};

export { DefaultHeader };
