import type { HeaderProps } from "./Header.types";

const Header = ({ tabs, value, onChange }: HeaderProps) => (
  <nav className="bg-white border-b sticky top-0 z-20">
    <ul className="flex items-center px-6 space-x-6">
      {Object.values(tabs).map((tab: string) => (
        <li key={tab}>
          <button
            type="button"
            className={`text-sm font-semibold border-b-2 border-solid
                                ${
                                  tab === value
                                    ? "border-blue-600 text-blue-600"
                                    : "text-black text-opacity-66 border-transparent"
                                }`}
            onClick={() => onChange(tab)}
          >
            {tab}
          </button>
        </li>
      ))}
    </ul>
  </nav>
);

export { Header };
