import type { CheckboxProps } from "./Checkbox.types";
import cx from "classnames";
import { useCallback, useState } from "react";

import checkboxIndeterminateDefault from "./assets/checkbox-indeterminate-default.png";
import checkboxIndeterminateHover from "./assets/checkbox-indeterminate-hover.png";
import checkboxIndeterminateReadonly from "./assets/checkbox-indeterminate-readonly.png";
import checkboxSelectedDefault from "./assets/checkbox-selected-default.png";
import checkboxSelectedHover from "./assets/checkbox-selected-hover.png";
import checkboxSelectedReadonly from "./assets/checkbox-selected-readonly.png";
import checkboxUnselectedDefault from "./assets/checkbox-unselected-default.png";
import checkboxUnselectedHover from "./assets/checkbox-unselected-hover.png";
import checkboxUnselectedReadonly from "./assets/checkbox-unselected-readonly.png";

const Checkbox = ({
  id,
  checked,
  indeterminate = false,
  disabled = false,
  className,
  onChange,
  label,
  labelClassName,
  "aria-label": ariaLabel,
  "aria-labelledby": ariaLabelledBy,
  dataTestId,
}: CheckboxProps) => {
  const [hovering, setHovering] = useState(false);
  let imgSrc: string;
  let hoverImgSrc: string | undefined;

  // determine which images to use
  if (indeterminate) {
    imgSrc = disabled
      ? checkboxIndeterminateReadonly
      : checkboxIndeterminateDefault;
    hoverImgSrc = disabled ? undefined : checkboxIndeterminateHover;
  } else if (checked) {
    imgSrc = disabled ? checkboxSelectedReadonly : checkboxSelectedDefault;
    hoverImgSrc = disabled ? undefined : checkboxSelectedHover;
  } else {
    imgSrc = disabled ? checkboxUnselectedReadonly : checkboxUnselectedDefault;
    hoverImgSrc = disabled ? undefined : checkboxUnselectedHover;
  }

  const pointerBehavior = disabled ? "cursor-not-allowed" : "cursor-pointer";

  const setMouseOver = useCallback(() => setHovering(true), []);
  const setMouseOut = useCallback(() => setHovering(false), []);
  const handleValueChange = useCallback(() => {
    if (onChange && !disabled) {
      onChange(!checked);
    }
  }, [onChange, disabled, checked]);

  return (
    <div
      id={id}
      data-testid={dataTestId}
      className={cx(pointerBehavior, { flex: label }, className)}
      aria-checked={checked === true}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      aria-disabled={disabled}
      onBlur={setMouseOut}
      onClick={handleValueChange}
      onFocus={setMouseOver}
      onKeyPress={(e) => e.key === "Space" && handleValueChange()}
      onMouseOut={setMouseOut}
      onMouseOver={setMouseOver}
      role="checkbox"
      tabIndex={0}
    >
      <img
        alt="checkbox"
        height={18}
        src={hovering && hoverImgSrc ? hoverImgSrc : imgSrc}
        width={18}
      />
      {label && (
        <label
          htmlFor={id}
          className={cx("ml-2 text-sm", pointerBehavior, labelClassName)}
        >
          {label}
        </label>
      )}
    </div>
  );
};

export { Checkbox };
