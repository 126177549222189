import type {
  FetchTicketsPageParams,
  requestDataProps,
} from "./tickets/queries/useFlexTickets";
import type { DateRangeFilter } from "@/models";
import type { SavedViewFilterValue } from "@/models/SavedView";
import { SortOrderDirection } from "@/models";

interface UseQueryReturnOptions<T> {
  sorter?: (a: T, b: T) => number;
}

type prepareTicketParamsProps = requestDataProps & {
  pageParameters?: FetchTicketsPageParams;
  ticketsLimit?: number;
};

type preparedRequestBody = {
  timezone: string;
  offset: number;
  limit: number;
  search_term?: string;
  order?: string;
  filters: Record<string, SavedViewFilterValue>;
  exclude_filters: Record<string, SavedViewFilterValue>;
  expand?: string[];
  fields?: string[];
};

// Type guard function
function isDateRangeFilter(value: any): value is DateRangeFilter {
  return (
    value &&
    typeof value === "object" &&
    value.type === "date" &&
    value.after &&
    typeof value.after === "string" &&
    value.after &&
    typeof value.before === "string"
  );
}

const prepareRequestBody = ({
  searchTerm,
  order,
  filters,
  excludeFilters,
  ticketsLimit,
  pageParameters,
  ticketsFields,
}: prepareTicketParamsProps): preparedRequestBody => {
  const body: preparedRequestBody = {
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    offset: 0,
    limit: ticketsLimit || 200,
    filters: {},
    exclude_filters: {},
  };
  if (searchTerm) {
    body.search_term = searchTerm;
  }

  if (order) {
    const prefix = order.direction === SortOrderDirection.DESC ? "-" : "";
    body.order = `${prefix}${order.field}`;
  }

  if (filters) {
    filters.forEach((filter) => {
      if (isDateRangeFilter(filter.value)) {
        if (!filter?.value?.after || !filter?.value?.before) return;
        body.filters[`${filter.field}_after`] = filter.value.after;
        body.filters[`${filter.field}_before`] = filter.value.before;
        return;
      }
      if (!filter.value || !filter.field) return;
      body.filters[filter.field] = filter.value;
    });
  }

  if (excludeFilters) {
    excludeFilters.forEach((excludeFilter) => {
      if (!excludeFilter?.field || !excludeFilter.value) return;
      body.exclude_filters[excludeFilter.field] = excludeFilter.value;
    });
  }

  if (pageParameters) {
    body.offset = pageParameters.offset || body.offset;
    body.limit = pageParameters.limit || body.limit;
  }

  if (ticketsFields) {
    const getExpand = ticketsFields.reduce<string[]>((acc, field) => {
      const parts = field.split(".").slice(0, -1);
      parts.forEach((_, idx) => {
        const expansion = parts.slice(0, idx + 1).join(".");
        if (!acc.includes(expansion)) {
          acc.push(expansion); // Only add if the expansion isn't already in the array
        }
      });
      return acc;
    }, []);

    if (getExpand.length > 0) {
      body.expand = getExpand;
    }

    body.fields = ticketsFields;
  }

  return body;
};

/*
  Guarantees that all retries are executed with an exponential delay, preventing undesi
*/
const retryDelayStrategy = (attempt: number) =>
  Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000);

export type {
  UseQueryReturnOptions,
  prepareTicketParamsProps,
  preparedRequestBody,
};
export { retryDelayStrategy, prepareRequestBody };
