import type { FilterDialogFieldFilterCardProps } from "./FilterDialogFieldFilterCard.types";
import { Fragment, useState } from "react";
import { Transition } from "@headlessui/react";
import { IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { getFieldLabel } from "@/models";
import { FilterDialogCard } from "../../FilterDialogCard";
import { buildTicketFilter } from "./FilterDialogFieldFilterCard.utils";

const FilterDialogFieldFilterCard = ({
  filter,
  excludeFilter,
  definition,
  formatCasing = true,
  onClose,
  onChange,
}: FilterDialogFieldFilterCardProps) => {
  const [isOpen, setOpen] = useState(true);
  return (
    <Transition
      as={Fragment}
      appear
      show={isOpen}
      enter="duration-700 overflow-hidden"
      enterFrom="opacity-25"
      enterTo="opacity-100"
      leave="duration-200 overflow-hidden"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      afterLeave={onClose}
    >
      <FilterDialogCard className="box-border py-5">
        <header className="flex items-center mb-4" data-testid="filter-div">
          <h2 className="font-semibold text-black text-opacity-88">
            {getFieldLabel(filter.field)}
          </h2>
          <button
            data-testid="field-dialog-close"
            type="button"
            className="text-black text-lg ml-auto text-opacity-33 icon-semibold flex items-center justify-center"
            onClick={() => setOpen(false)}
          >
            <IonIcon icon={closeOutline} />
          </button>
        </header>
        {buildTicketFilter(
          { filter, excludeFilter },
          definition,
          formatCasing,
          onChange
        )}
      </FilterDialogCard>
    </Transition>
  );
};

export { FilterDialogFieldFilterCard };
