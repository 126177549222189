import type { EgressRoute, MemberCode, OneCallCenter, Ticket } from "@/models";
import type { activityPhraseArgsProps } from "./ActivityListTicketItem.types";
import { formatFullDateTime, startCase } from "@/format";
import {
  useEgresses,
  useOneCallCenters,
  usePositiveResponseCodes,
} from "@/api";
import { NO_EXPIRATION_DATE_SET } from "./ActivityListTicketItem.constants";

const getOneCallCenter = (oneCallCenters: OneCallCenter[], ticket?: Ticket) =>
  oneCallCenters.find(
    (oneCallCenter) => oneCallCenter.id === ticket?.oneCallCenterId
  )?.name ?? "One Call Center";

const getEgressTarget = (egressId: number, egresses: EgressRoute[]) =>
  egresses.find((egress) => egress.id === egressId)?.target ?? "a third party";

const getMemberCodeName = (
  memberCodeId: number,
  ticketMemberCode: MemberCode[]
) =>
  ticketMemberCode.find((memberCode) => memberCode.id === memberCodeId)?.key ??
  "a member code";

const getAutomationEngineCreatePhrase = () =>
  "Received ticket from One Call Center.";
const getAutomationEngineUpdatePhrase = ({
  activity,
}: activityPhraseArgsProps) => {
  const ruleName = activity.changedFieldsJSON.last_rule_modified?.[1];
  const ruleEffect = activity.changedFieldsJSON.last_rule_effect?.[1];
  return ruleName && ruleEffect
    ? `Automated rule "${ruleName}" ${ruleEffect}`
    : `Automation engine performed an action.`;
};
const getPositiveResponsePhrase = ({
  activity,
  ticket,
}: activityPhraseArgsProps) => {
  const { data: positiveResponseCodes = [] } = usePositiveResponseCodes();
  const { data: oneCallCenters = [] } = useOneCallCenters();
  const prCode = positiveResponseCodes.find(
    (code) => code.id === activity.object.fields.pr_code_override
  );
  if (!prCode) {
    return "";
  }
  const { code, label } = prCode;
  return `Positive Response ${code}:${label} sent to ${getOneCallCenter(
    oneCallCenters,
    ticket
  )}`;
};

const getPositiveResponseResponsePhrase = ({
  activity,
  ticket,
}: activityPhraseArgsProps) => {
  const { data: oneCallCenters = [] } = useOneCallCenters();
  return `Positive Response received by ${getOneCallCenter(
    oneCallCenters,
    ticket
  )} with response: ${activity.object.fields.third_party_response}`;
};
const getForwardTicketPhrase = ({
  activity,
  ticket,
}: activityPhraseArgsProps) => {
  const { data: egresses = [] } = useEgresses();
  const thirdPartyName = getEgressTarget(
    activity.object.fields.egress,
    egresses
  );

  if (ticket?.memberCodes && activity.object.fields.member_code) {
    const memberCodeName = getMemberCodeName(
      activity.object.fields.member_code,
      ticket?.memberCodes
    );
    return `Forwarded a ticket to ${thirdPartyName} for ${memberCodeName}`;
  }
  return `Forwarded a ticket to ${thirdPartyName}`;
};
const getFowardTicketResponsePhrase = ({
  activity,
}: activityPhraseArgsProps) => {
  const { data: egresses = [] } = useEgresses();
  return `Received a response from ${getEgressTarget(
    activity.object.fields.egress,
    egresses
  )}: ${activity.object.fields.third_party_response}`;
};
const getTicketVersionPhrase = ({ activity }: activityPhraseArgsProps) =>
  `Updated ticket details from One Call Center as version ${activity.ticketVersion}`;

const getTicketCommentPhrase = ({ activity }: activityPhraseArgsProps) =>
  `Commented on ticket: "${activity.commentMessage}"`;

const getTicketStatusChangedPhrase = ({
  activity,
}: activityPhraseArgsProps) => {
  const previousStatus = startCase(activity.statusFromAndTo[0]);
  const newStatus = startCase(activity.statusFromAndTo[1]);
  if (!previousStatus || !newStatus) return "";
  return `Changed ticket from ${previousStatus} to ${newStatus}`;
};

const getTicketStageChangedPhrase = ({ activity }: activityPhraseArgsProps) => {
  const previousStatus = activity.changedFieldsJSON.stage[0];
  const newStatus = activity.changedFieldsJSON.stage[1];
  if (!previousStatus || !newStatus) return "";
  return `Changed stage from ${previousStatus} to ${newStatus}`;
};

const getTicketExpirationDateChangedPhrase = ({
  activity,
}: activityPhraseArgsProps) => {
  if (!activity.changedFieldsJSON.expires_on) {
    return "";
  }

  const previousStatus = activity.changedFieldsJSON.expires_on[0];
  const newStatus = activity.changedFieldsJSON.expires_on[1];

  const previousDate =
    previousStatus === NO_EXPIRATION_DATE_SET
      ? previousStatus
      : formatFullDateTime(previousStatus);

  const newDate =
    newStatus === NO_EXPIRATION_DATE_SET
      ? newStatus
      : formatFullDateTime(newStatus);

  return `Changed expiration date from ${previousDate} to ${newDate}`;
};

export {
  getAutomationEngineCreatePhrase,
  getAutomationEngineUpdatePhrase,
  getPositiveResponsePhrase,
  getPositiveResponseResponsePhrase,
  getForwardTicketPhrase,
  getFowardTicketResponsePhrase,
  getTicketVersionPhrase,
  getTicketCommentPhrase,
  getTicketStatusChangedPhrase,
  getTicketStageChangedPhrase,
  getTicketExpirationDateChangedPhrase,
};
