import type { PointsLabelsProps } from "./PointsLabels.types";
import { hasCoordinates } from "@/pages/MapPage";
import { calculateCumulativeDistance } from "../MeasureTool.utils";
import { DistancePopup } from "../DistancePopup";

const PointsLabels = ({ measurementPoints, unit }: PointsLabelsProps) => (
  <>
    {measurementPoints.map((point, index) => {
      if (hasCoordinates(point.geometry) && point.properties) {
        const pointCoordinates = point.geometry.coordinates as [number, number];
        const cumulativeDistance = calculateCumulativeDistance(
          measurementPoints.slice(0, index + 1),
          unit.label
        );
        return (
          <DistancePopup
            key={point.properties.id}
            coordinates={pointCoordinates}
            distance={cumulativeDistance}
            unit={unit.abbreviation}
          />
        );
      }
      return null;
    })}
  </>
);

export { PointsLabels };
