import type { DisposablesAPI } from "./useDisposables.types";
import { useEffect, useState } from "react";
import { disposables } from "./useDisposables.utils";

const useDisposables = (): DisposablesAPI => {
  const [d] = useState(disposables());
  useEffect(() => d.dispose(), [d]);
  return d;
};

export { useDisposables };
