import { useMutation } from "@tanstack/react-query";
import { UrbintApi } from "@/utils/UrbintApi";
import { invalidateSavedViewsQuery } from "@/api/savedViews";

const api = new UrbintApi();

const updateSavedView = async (defaultSavedViewId: number) => {
  const resp = await api.patch(
    "me/preferences",
    JSON.stringify({ default_ticket_view: defaultSavedViewId })
  );
  if (resp.error) {
    throw resp.error;
  }
};

const useUpdatePreferences = () =>
  useMutation(updateSavedView, {
    onSuccess: () => {
      invalidateSavedViewsQuery();
    },
  });

export { useUpdatePreferences };
