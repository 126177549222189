import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { Opco } from "@/models";
import { UrbintApi } from "@/utils/UrbintApi";
import { queryClient } from "@/api/client";
import { handleApiResponse } from "@/api/helpers";

type IReturn = Opco[];

const api = new UrbintApi();
const ident = ["opcos"];

const fetchOpcos = () =>
  api
    .getMany<{ name: string; domain: string; current: boolean }>({
      endPoint: "opcos",
    })
    .then(handleApiResponse)
    .then((res) => res.map((x) => new Opco(x)));

const useOpcos = (opts?: UseQueryOptions<IReturn>) =>
  useQuery<IReturn>(ident, fetchOpcos, opts);

const prefetchOpcos = () => queryClient.prefetchQuery(ident, fetchOpcos);

export { useOpcos, prefetchOpcos };
