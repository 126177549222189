import type { NotificationSerializer } from "@/utils/damage-prevention";
import { UrbintModel } from "@/utils/UrbintModel";

class Notification extends UrbintModel<NotificationSerializer> {
  get id() {
    return this.castNumber(this.data.id);
  }

  get title() {
    return this.castString(this.data.title);
  }

  get description() {
    return this.castString(this.data.description);
  }

  get timestamp() {
    return this.castDate(this.data.timestamp);
  }

  get read() {
    return this.castBool(this.data.read);
  }

  get dismissed() {
    return this.castBool(this.data.dismissed);
  }

  get dismissedAt() {
    return this.castDate(this.data.dismissed_at);
  }

  get userId() {
    return this.castNumber(this.data.user);
  }

  get ticketId() {
    return this.castNumber(this.data.ticket);
  }

  compare(instance: Notification) {
    return this.id - instance.id;
  }

  serialize(): NotificationSerializer {
    return this.data;
  }
}

export { Notification };
