import type { UseQueryOptions } from "@tanstack/react-query";
import type { SavedViewSerializer } from "@/utils/damage-prevention";
import { useQuery } from "@tanstack/react-query";
import { SavedView } from "@/models";
import { UrbintApi } from "@/utils/UrbintApi";
import { queryClient } from "@/api/client";
import { handleApiResponse } from "@/api/helpers";

const api = new UrbintApi();

const savedViewsIdent = () => ["saved_views"];

const fetchSavedViews = () =>
  api
    .getMany<SavedViewSerializer>({
      endPoint: "saved_views",
    })
    .then(handleApiResponse)
    .then((res) =>
      res.map((r) => new SavedView(r)).sort((a, b) => a.compare(b))
    );

const useSavedViews = (opts?: UseQueryOptions<SavedView[]>) =>
  useQuery<SavedView[]>(savedViewsIdent(), () => fetchSavedViews(), opts);

const invalidateSavedViewsQuery = () =>
  queryClient.invalidateQueries(savedViewsIdent());

const prefetchSavedViews = () =>
  queryClient.prefetchQuery(savedViewsIdent(), fetchSavedViews);

const setSavedViewsData = (
  updater: SavedView[] | ((oldData: SavedView[] | undefined) => SavedView[])
) => queryClient.setQueryData(savedViewsIdent(), updater);

export {
  savedViewsIdent,
  invalidateSavedViewsQuery,
  prefetchSavedViews,
  setSavedViewsData,
  useSavedViews,
};
