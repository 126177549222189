import type { PositiveResponseSerializer } from "@/utils/damage-prevention";
import { UrbintModel } from "@/utils/UrbintModel";

class PositiveResponse extends UrbintModel<PositiveResponseSerializer> {
  get id() {
    return this.castNumber(this.data.id);
  }

  get transmitTime() {
    return this.castDate(this.data.transmit_time);
  }

  get remarks() {
    return this.castString(this.data.remarks);
  }

  get locatorName() {
    return this.castString(this.data.locator_name);
  }

  get locatorStatus() {
    return this.castString(this.data.locate_status);
  }

  get locateIssueCodes() {
    return this.castString(this.data.locate_issue_codes);
  }

  get dueDateExtended() {
    return (
      (this.data.due_date_extended &&
        this.castDate(this.data.due_date_extended)) ||
      undefined
    );
  }

  get extracted() {
    return JSON.stringify(this.data.extracted, null, "  ");
  }

  get memberCode() {
    return this.castNumber(this.data.member_code);
  }

  get prCode() {
    return this.castNumber(this.data.pr_code);
  }

  get prCodeOverrideId() {
    return this.castNumber(this.data.pr_code_override);
  }

  get ticket() {
    return this.castNumber(this.data.ticket);
  }

  get ticketVersion() {
    return this.castNumber(this.data.ticket_version);
  }

  get code() {
    return this.castString(this.data.code);
  }

  get description() {
    return this.castString(this.data.description);
  }

  get comment() {
    return this.castString(this.data.comment);
  }

  get label() {
    return this.castString(this.data.label);
  }

  serialize(): PositiveResponseSerializer {
    return this.data;
  }

  compare(instance: PositiveResponse): number {
    return instance.transmitTime.getTime() - this.transmitTime.getTime();
  }
}

export { PositiveResponse };
