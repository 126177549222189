import type { MapProps } from "./Map.types";
import { BodyText } from "@urbint/silica";
import { useOnlineStatus } from "@/hooks";
import { Map } from "./Map";

const MapEntry = ({ ticket }: MapProps) => {
  const isOnline = useOnlineStatus();
  if (isOnline) {
    return <Map ticket={ticket} />;
  }
  return (
    <div className="w-full h-full flex justify-center content-center bg-backgrounds-gray items-center lg:absolute">
      <div className="max-w-2xl w-full flex justify-end">
        <div className="md:w-[calc(100%-20rem)] flex justify-center">
          <BodyText className="w-52 text-center">
            The map is currently not supported when offline.
          </BodyText>
        </div>
      </div>
    </div>
  );
};

export { MapEntry };
