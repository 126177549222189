import type { NavBarItemsProps } from "./NavBarItems.types";
import type { Opco } from "@/models";
import {
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
} from "@ionic/react";
import { Icon } from "@urbint/silica";
import { useOnlineStatus, useResponsive } from "@/hooks";
import { useOpcos, useReportsConfig } from "@/api";

const NavBarItems = ({ auth, onClick }: NavBarItemsProps) => {
  const { data: reportsConfig } = useReportsConfig();
  const { logout, redirectToAccountManagement, currentUser } = auth;
  const { isSm } = useResponsive();
  const isOnline = useOnlineStatus();
  const { data: opcos } = useOpcos();
  const otherOpcos = opcos?.filter((x: Opco) => !x.current) || [];
  const { canAccessSettings, canAccessAdminPanel } = currentUser;
  const { protocol, port, hostname } = window.location;
  const url = new URL(`${protocol}//${hostname}:${port}/admin/`);
  const adminUrl = url.href;

  const showSettingsOption = canAccessSettings && isSm && isOnline;

  const handleClick = (onClickFunction?: () => void) => {
    if (onClick) {
      onClick();
    }

    if (onClickFunction) {
      onClickFunction();
    }
  };

  return (
    <IonList lines="none" className="w-64">
      <IonItem
        button
        data-testid="urbint-navbar-hub-btn"
        detail={false}
        onClick={() => handleClick(redirectToAccountManagement)}
      >
        <Icon slot="start" name="user_circle" />
        <IonLabel>Account</IonLabel>
      </IonItem>
      {canAccessAdminPanel && (
        <IonItemGroup>
          <IonItem
            data-testid="urbint-navbar-admin-btn"
            button
            detail={false}
            href={adminUrl}
          >
            <Icon slot="start" name="urbint" />
            <IonLabel>Admin</IonLabel>
          </IonItem>
        </IonItemGroup>
      )}
      {showSettingsOption && (
        <IonItem routerLink="/settings" onClick={() => handleClick()}>
          <Icon slot="start" name="settings" />
          <IonLabel>Settings</IonLabel>
        </IonItem>
      )}
      {reportsConfig?.enabled && (
        <IonItemGroup>
          <IonItem
            data-testid="urbint-navbar-reports-btn"
            button
            detail={false}
            routerLink="/reports"
            onClick={() => handleClick()}
          >
            <Icon slot="start" name="bar_chart_square" />
            <IonLabel>Reports</IonLabel>
          </IonItem>
        </IonItemGroup>
      )}
      {otherOpcos.length ? (
        <IonItemDivider>
          <IonLabel>Switch operating companies&hellip;</IonLabel>
        </IonItemDivider>
      ) : null}
      {otherOpcos.map((opco, i) => (
        <IonItem
          key={opco.domain}
          data-testid="urbint-navbar-opco-btn"
          button
          detail={false}
          href={opco.url}
          lines={otherOpcos[i + 1] ? "none" : "full"}
        >
          {opco.name}
        </IonItem>
      ))}
      <IonItem
        button
        data-testid="urbint-navbar-logout-btn"
        detail={false}
        onClick={logout}
      >
        <Icon slot="start" name="log_out" />
        <IonLabel>Logout</IonLabel>
      </IonItem>
    </IonList>
  );
};
export { NavBarItems };
