import type { DisposablesAPI } from "./useDisposables.types";

const disposables = (): DisposablesAPI => {
  const cleanups: Array<() => void> = [];
  const api: DisposablesAPI = {
    requestAnimationFrame: (...args) => {
      const raf = requestAnimationFrame(...args);
      api.add(() => cancelAnimationFrame(raf));
      return raf;
    },
    nextFrame: (...args) =>
      api.requestAnimationFrame(() => api.requestAnimationFrame(...args)),
    setTimeout: (...args) => {
      const timeoutId = setTimeout(...args);
      api.add(() => clearTimeout(timeoutId));
      return timeoutId;
    },
    setImmediate: (f) => {
      let alive = true;
      api.add(() => {
        alive = false;
      });
      return setImmediate(() => {
        if (alive) {
          f();
        }
      });
    },
    add: (cleanup) => cleanups.push(cleanup),
    dispose: () => {
      for (const cleanup of cleanups) {
        cleanup();
      }
    },
  };
  return api;
};

export { disposables };
