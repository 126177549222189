import type { PositiveResponsesDetailsSectionProps } from "./PositiveResponsesDetailsSection.types";
import { useState } from "react";
import { formatFullDate, formatRelativeTime, startCase } from "@/format";
import { usePositiveResponseData } from "@/hooks/usePositiveResponseData";
import { DetailsSection } from "../DetailsSection";
import { labelClass, pClass } from "../TicketPageDetails.constants";
import { PositiveResponseModal } from "./PositiveResponseModal";
import { DetailButton } from "./DetailButton";

const PositiveResponsesDetailsSection = ({
  ticket,
}: PositiveResponsesDetailsSectionProps) => {
  if (!ticket) {
    return null;
  }

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { positiveResponses, latestResponseAt } =
    usePositiveResponseData(ticket);

  return (
    <DetailsSection title="Member Codes">
      <PositiveResponseModal
        isOpen={isModalOpen}
        onDidDismiss={() => setIsModalOpen(false)}
        ticket={ticket}
      />
      <p className={`${labelClass} mb-3`} data-testid="latestResponseAt">
        {latestResponseAt
          ? `Last updated ${formatFullDate(
              latestResponseAt
            )} (${formatRelativeTime(latestResponseAt)})`
          : "No positive responses yet."}
      </p>
      {positiveResponses.length > 0 && (
        <div className="mb-5">
          <div className="space-x-2 hidden lg:flex">
            <div className="w-7/12 mb-0.5">
              <p className={`${labelClass}`}>Member code</p>
            </div>
            <div className="w-1/4 mb-0.5">
              <p className={`${labelClass}`}>Latest positive response</p>
            </div>
          </div>
          <div className="divide-y divide-solid divide-black divide-opacity-11">
            {positiveResponses.map(
              ({
                memberCode,
                memberCodeLabel,
                positiveResponseCode,
                positiveResponseCodeLabel,
              }) => {
                const memberCodeContent = memberCodeLabel
                  ? `${memberCode}: ${startCase(memberCodeLabel)}`
                  : memberCode;
                const prContent = positiveResponseCodeLabel
                  ? `${positiveResponseCode}: ${startCase(
                      positiveResponseCodeLabel
                    )}`
                  : positiveResponseCode;
                return (
                  <div
                    key={memberCode}
                    className="flex space-y-2 lg:space-y-0 lg:space-x-2 py-1 flex-col lg:flex-row"
                  >
                    <div className="lg:w-7/12" data-testid="member-code-text">
                      <p className={`${pClass} font-semibold lg:font-normal`}>
                        {memberCodeContent}
                      </p>
                    </div>
                    <div className="lg:w-1/4">
                      <p className={`${pClass}`} data-testid="pr-response-text">
                        {prContent}
                      </p>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      )}
      {positiveResponses.length > 0 && (
        <DetailButton onClick={() => setIsModalOpen(true)}>
          View all positive response history...
        </DetailButton>
      )}
    </DetailsSection>
  );
};

export { PositiveResponsesDetailsSection };
