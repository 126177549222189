import type { TelButtonProps } from "./TelButton.types";
import classNames from "classnames";
import { Button } from "../Button";

function initiateCall(phoneNumber: string) {
  if (!phoneNumber || phoneNumber.length === 0) {
    alert("No phone number provided");
  } else {
    try {
      const url = new URL(`tel:${phoneNumber}`);
      window.location.assign(url.href);
    } catch (e) {
      alert(
        `Your browser doesn't support making phone calls. ( ${phoneNumber} )`
      );
    }
  }
}

/**
 * Opens the given `phoneNumber` in the user's default phone app if supported.
 * If the `tel:` protocol isn't supported by the browser, an alert will be
 * presented to the user with the phone number so that they can dial it by
 * other means.
 */
const TelButton = ({
  phoneNumber,
  children,
  onClick,
  className,
  ...props
}: TelButtonProps) => (
  <Button
    aria-label={`Call ${phoneNumber}`}
    className={classNames("border", className)}
    iconStart="phone"
    onClick={(e) => {
      initiateCall(phoneNumber);
      if (onClick) onClick(e);
    }}
    {...props}
  >
    {children || <>Call&hellip;</>}
  </Button>
);

export { TelButton };
