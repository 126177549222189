import type { ExpirationDateProps } from "./ExpirationDate.types";
import cx from "classnames";
import { Icon } from "@urbint/silica";
import { useState } from "react";
import { formatFullDateTime } from "@/format";
import { useDamagePreventionAuth, useOnlineStatus } from "@/hooks";
import { Tooltip } from "@/common/Tooltip";
import { ExpirationDateModal } from "@/components/Modals/ExpirationDateModal";

const ExpirationDate = ({ ticket }: ExpirationDateProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const isOnline = useOnlineStatus();
  const { currentUser } = useDamagePreventionAuth();

  const handleClickEdit = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const expirationDate = ticket.expiresOn ?? undefined;
  const canEditExpirationDate = currentUser.canEditTicketLifeCycle;

  return (
    <div
      className={cx("text-black", {
        "text-opacity-33": !ticket.expiresOn,
      })}
    >
      {ticket.expiresOn ? formatFullDateTime(ticket.expiresOn) : "—"}
      {isOnline && canEditExpirationDate && (
        <button
          type="button"
          className="items-center text-default-on-light"
          onClick={handleClickEdit}
          data-testid="edit-expiration-date"
        >
          <Tooltip
            title="Edit expiration date"
            origin="top"
            disabled={modalOpen}
          >
            <Icon name="edit" className="ml-2" />
          </Tooltip>
        </button>
      )}
      {canEditExpirationDate && modalOpen && (
        <ExpirationDateModal
          isOpen={modalOpen}
          onClose={handleClose}
          date={expirationDate}
          tickets={[{ id: ticket.id, number: ticket.number }]}
        />
      )}
    </div>
  );
};

export { ExpirationDate };
