const colors = [
  "bg-indigo-600 text-white",
  "bg-indigo-900 text-white",
  "bg-blue-600 text-white",
  "bg-blue-800 text-white",
  "bg-yellow-600 text-white",
  "bg-yellow-900 text-white",
  "bg-green-600 text-white",
  "bg-green-900 text-white",
  "bg-bluegray-800 text-white",
  "bg-pink-800 text-white",
  "bg-pink-600 text-white",
];

export { colors };
