import type { PublicConfig } from "@/models";
import type { DamagePreventionUserProfile } from "@/utils/types";
import {
  type ColumnData,
  type ColumnDefinition,
} from "@/components/TicketTable";
import {
  DEFAULT_COLUMNS,
  FEATURE_FLAGGED_CONTROLLED_COLUMNS,
} from "./useTicketColumns.constants";

const prepareColumnDefinitions = (cols: ColumnDefinition<ColumnData>[]) =>
  cols.map(
    (d): ColumnDefinition<ColumnData> => ({
      ...d,
      defaultCanSort: !!d.sortFields,
      disableSortBy: !d.sortFields,
    })
  );

// Control witch columns are allowed by into the App
const getAllowedColumns = (
  publicConfig: PublicConfig | undefined,
  { canViewModelExplainer }: DamagePreventionUserProfile
) => {
  const tableColumns = [...DEFAULT_COLUMNS];

  if (publicConfig) {
    if (publicConfig.enableScaledImpactScore && canViewModelExplainer) {
      const impactScoreColumn = FEATURE_FLAGGED_CONTROLLED_COLUMNS[0];
      if (impactScoreColumn) {
        tableColumns.push(impactScoreColumn);
      }
    }

    if (publicConfig.enableThreatScore && canViewModelExplainer) {
      const damageScoreColumn = FEATURE_FLAGGED_CONTROLLED_COLUMNS[1];
      if (damageScoreColumn) {
        tableColumns.push(damageScoreColumn);
      }
    }
  }

  return tableColumns;
};

export { getAllowedColumns, prepareColumnDefinitions };
