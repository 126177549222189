import type { FieldMemberMarkerProps } from "./FieldMemberMarker.types";
import React from "react";
import classNames from "classnames";
import { Marker } from "react-map-gl";
import {
  memberStatusClassname,
  memberIdentifierClassname,
  memberMarkerClassname,
} from "../Markers.constants";
import { FieldMemberStatusType } from "./FieldMemberMarker.types";
import {
  getFieldMemberIdentifier,
  getFieldMemberStatus,
} from "./FieldMemberMarker.utils";

/**
 * Marker that represents members on the field
 * with activity status indicator.
 */
const FieldMemberMarker = React.memo(
  ({
    userId,
    firstName,
    lastName,
    lastUpdate,
    latitude,
    longitude,
    onMouseOver,
    onMouseLeave,
  }: FieldMemberMarkerProps) => {
    const memberStatus = getFieldMemberStatus(lastUpdate);
    const memberIdentifier = getFieldMemberIdentifier(firstName, lastName);

    return (
      <Marker latitude={latitude} longitude={longitude} offset={[-14, -14]}>
        <div
          className={`${memberMarkerClassname} cursor-pointer`}
          onMouseOver={() => {
            onMouseOver?.(userId);
          }}
          onMouseLeave={() => {
            onMouseLeave?.();
          }}
          onFocus={() => onMouseOver?.(userId)}
        >
          <span className={memberIdentifierClassname}>{memberIdentifier}</span>
          <div
            className={classNames(memberStatusClassname, {
              "bg-data-green-30": FieldMemberStatusType.ACTIVE === memberStatus,
              "bg-gray-50": FieldMemberStatusType.INACTIVE === memberStatus,
              hidden: FieldMemberStatusType.HIDDEN === memberStatus,
            })}
          />
        </div>
      </Marker>
    );
  }
);

export { FieldMemberMarker };
