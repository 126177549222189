import type { Layers, MapLayerControlsProps } from "./MapLayer.types";
import { Menu } from "@headlessui/react";
import classNames from "classnames";
import startCase from "lodash/startCase";
import { MapControl } from "../MapControl";
import { MenuItem } from "./MenuItem";

const MapLayerControls = ({
  layers,
  onLayerToggle,
  position = "top",
  className,
}: MapLayerControlsProps) => (
  <Menu
    as="div"
    className="relative flex flex-row-reverse text-left"
    data-testid="map-layers-button"
  >
    <Menu.Button data-testid="menu-map-layers-button">
      <MapControl icon="layers_outline" as="span" />
    </Menu.Button>

    <Menu.Items>
      <div
        className={classNames(
          "absolute right-10 bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none px-3 pb-4",
          {
            "origin-top-right": position === "top",
            "origin-bottom-right bottom-0": position === "bottom",
          },
          className
        )}
      >
        <Menu.Item disabled>
          <div className="pt-2 mb-1 font-sans text-sm font-semibold text-black text-opacity-66">
            Manage map layers
          </div>
        </Menu.Item>
        {Object.keys(layers)
          .filter((k) => layers[k]?.enabled)
          .map((l) => (
            <MenuItem
              key={l}
              title={startCase(l)}
              enabled={layers[l as keyof Layers]?.visible ?? false}
              onToggle={() => onLayerToggle(l as keyof Layers)}
            />
          ))}
      </div>
    </Menu.Items>
  </Menu>
);

export { MapLayerControls };
