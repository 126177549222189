import type { DamageThreatSerializer } from "@/utils/damage-prevention";
import { useQuery } from "@tanstack/react-query";
import { DamageThreat } from "@/models";
import { UrbintApi } from "@/utils/UrbintApi";
import { handleApiResponse } from "@/api/helpers";

const api = new UrbintApi();

const damageThreatIdent = (ticketId: number) => [
  "damageThreatInsight",
  ticketId,
];

const fetchDamageThreat = (ticketId: number) =>
  api
    .getMany<DamageThreatSerializer>({
      endPoint: `tickets/${ticketId}/threat_insights`,
    })
    .then(handleApiResponse)
    .then((res) => res.map((r) => new DamageThreat(r)));

const useDamageThreat = (isModalOpen: boolean, ticketId: number) =>
  useQuery<DamageThreat[]>(
    damageThreatIdent(ticketId),
    () => fetchDamageThreat(ticketId),
    { enabled: isModalOpen }
  );

export { useDamageThreat };
