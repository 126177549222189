import type { ToastProps } from "@/components/Toasts/Toast/Toast.types";
import { Icon } from "@urbint/silica";
import classNames from "classnames";
import { TOAST_ICONS } from "./Toast.constants";

const Toast = ({ toast: { type, className, message } }: ToastProps) => (
  <div
    className={classNames(
      "inline-flex space-x-2 items-center drop-shadow-lg md:drop-shadow-md font-sans pointer-events-auto rounded-md p-2.5",
      {
        "bg-brand-70 text-white": type === "success",
        "bg-red-900 text-white": type === "error",
        "bg-yellow-900 text-white": type === "warning",
        "bg-system-info-60 text-white": type === "info",
      },
      className
    )}
  >
    <div className="w-5 h-5">
      <Icon
        className={classNames("rounded-full mt-0.5", {
          "text-white bg-green-600 border-[1.5px] border-solid border-white":
            type === "success",
          "bg-white text-red-600 text-xl h-5 w-5 flex items-center justify-center pb-[1px]":
            type === "error",
          "text-white text-xs p pb-[1.5px] w-5 h-5 bg-system-warning-40 border-solid border-[1.5px] border-white flex items-center justify-center":
            type === "warning",
          "text-system-info-40 pb-[1px] text-xl w-5 h-5 flex items-center justify-center bg-white":
            type === "info",
        })}
        name={TOAST_ICONS[type]!}
      />
    </div>
    <div
      className="flex flex-wrap whitespace-pre-wrap"
      data-testid="toast-message"
    >
      {message}
    </div>
  </div>
);

export { Toast };
