import type { PreviousButtonProps } from "./PreviousButton.types";
import cx from "classnames";
import { Button } from "@/common/Button";
import styles from "./PreviousButton.module.css";

const PreviousButton = ({
  className,
  iconName = "chevron_left",
  isDisabled = false,
  onClick,
}: PreviousButtonProps) => (
  <Button
    className={cx(styles.NavigationButton, styles.PreviousButton, className)}
    iconStart={iconName}
    disabled={isDisabled}
    onClick={onClick}
  />
);

export { PreviousButton };
