import type { TicketQueueViewProps } from "./TicketQueueView.types";
import { useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { Icon } from "@urbint/silica";
import classNames from "classnames";
import { Accordion, AccordionItem } from "@/components/Accordion";
import { Button } from "@/common/Button";
import { TicketQueueState } from "@/models";
import { Spinner } from "@/common/Spinner";
import { EmptyState } from "./EmptyState";
import { MoreMenu } from "./MoreMenu";
import { TicketQueueCard } from "./TicketQueueCard";

const TicketQueueView = ({
  busy,
  hasError,
  onClickOptimize,
  queue,
  canRemoveFromQueue,
}: TicketQueueViewProps) => {
  const sortedItems = Array.from(queue.items);
  sortedItems.sort((a, b) => a.compare(b));
  const visitedItems = sortedItems.filter((item) => item.isVisited);
  const queuedItems = sortedItems.filter((item) => !item.isVisited);
  const [showOptimizedSuccess, setShowOptimizedSuccess] = useState(false);

  useEffect(() => {
    let toastTimeout: number | undefined;
    if (queue.state === TicketQueueState.OPTIMIZED) {
      setShowOptimizedSuccess(true);
      toastTimeout = window.setTimeout(() => {
        setShowOptimizedSuccess(false);
      }, 3000);
    }
    return () => clearTimeout(toastTimeout);
  }, [queue.state]);

  const showMoreMenu = canRemoveFromQueue && queue.items.length;
  return (
    <div className="flex flex-col flex-1 h-full max-w-full overflow-y-auto bg-white w-96">
      <h1 className="bg-[#1e3340] flex items-center justify-between font-semibold md:bg-white md:text-gray-700 p-4 text-lg text-white ">
        <div className="flex items-center">
          Your queue
          <Transition
            enterFrom="scale-0"
            enterTo="scale-100"
            leave="duration-200 transition"
            leaveFrom="scale-100"
            leaveTo="scale-0"
            show={busy}
          >
            <Spinner className="ml-3" />
          </Transition>
        </div>
        {showMoreMenu && <MoreMenu />}
      </h1>

      <div
        className={classNames("m-2", {
          hidden: queue.items.length && !hasError,
        })}
      >
        {hasError ? (
          <EmptyState type="failedFetch" />
        ) : (
          <EmptyState type="empty" />
        )}
      </div>

      <Accordion
        className={classNames("overflow-auto flex-1", {
          hidden: queue.items.length === 0,
        })}
        openItemId="queued"
      >
        <AccordionItem
          summary={`Visited (${visitedItems.length})`}
          itemId="visited"
          testId="visited-queue-title"
        >
          {visitedItems.map((item) => (
            <TicketQueueCard
              key={item.id}
              item={item}
              canRemoveFromQueue={canRemoveFromQueue}
            />
          ))}
        </AccordionItem>
        <AccordionItem
          summary={
            <div
              className="flex items-center"
              title="Queue is optimized"
              data-testid="upcoming-queue-title"
            >
              Upcoming ({queuedItems.length})
              {queue.state === TicketQueueState.OPTIMIZED &&
              queuedItems.length > 0 ? (
                <span className="ml-2 text-white rounded-full bg-data-blue-30">
                  <Icon name="lightning" />
                </span>
              ) : null}
            </div>
          }
          itemId="queued"
        >
          {queuedItems.length === 0 ? (
            <div className="-mx-3 -my-2">
              <EmptyState type="complete" />
            </div>
          ) : (
            queuedItems.map((item) => (
              <div key={item.id} className="mb-5">
                <TicketQueueCard
                  item={item}
                  canRemoveFromQueue={canRemoveFromQueue}
                />
              </div>
            ))
          )}
        </AccordionItem>
      </Accordion>
      <Transition
        show={queue.state === TicketQueueState.DIRTY && queuedItems.length > 1}
      >
        <div className="sticky bottom-0 flex flex-1 p-2 bg-gray-100 border-t">
          {queue.state === TicketQueueState.DIRTY ? (
            <Button
              className="flex-1 py-2 leading-normal text-white rounded-md bg-data-blue-30"
              iconStart="lightning"
              onClick={() => onClickOptimize()}
              testId="optimize-tickets-button"
            >
              Optimize upcoming tickets
            </Button>
          ) : null}
        </div>
      </Transition>
      <Transition show={queue.state === TicketQueueState.OPTIMIZING}>
        <div className="sticky bottom-0 flex items-center justify-between flex-1 p-2 text-gray-600 bg-gray-100">
          <div>
            <Spinner className="w-4 h-4 mr-2" />
            Optimizing tickets&hellip;
          </div>
        </div>
      </Transition>
      <Transition
        leave="duration-200 transition"
        leaveFrom="opacity-1"
        leaveTo="opacity-0"
        show={showOptimizedSuccess}
      >
        <div className="sticky bottom-0 flex items-center justify-center flex-1 p-2 font-semibold text-white bg-data-green-30">
          <div>
            <Icon name="check_big" className="mr-2" />
            Queue optimized!
          </div>
        </div>
      </Transition>
    </div>
  );
};
export { TicketQueueView };
