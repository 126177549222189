import type { TagIconProps } from "./Tag.types";

const TagIcons: TagIconProps = {
  success: "check",
  error: "error",
  warning: "warning",
  info: "info_circle",
};

export { TagIcons };
