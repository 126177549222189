import type { NetworkStatusBannerProps } from "./NetworkStatusBanner.types";
import type { IconName } from "@urbint/silica";

import { useState } from "react";
import cx from "classnames";

import { Banner, Icon } from "@urbint/silica";
import { Button } from "@/common/Button";

import { BANNER_TYPE, BASE_STYLE } from "./NetworkStatusBanner.constants";
import { getBannerStructure } from "./NetworkStatusBanner.utils";

const NetworkStatusBanner = ({
  type,
  withClose = type === BANNER_TYPE.SUCCESS,
  onClose,
  onClick,
  className,
}: NetworkStatusBannerProps) => {
  const [isOpen, setOpen] = useState(true);

  const baseBanner = getBannerStructure(type);

  const handleClose = () => {
    setOpen(false);
    onClose && onClose();
  };

  const handleLinkClick = () => {
    onClick && onClick();
  };

  const { icon, iconBackground, label, labelLink } = baseBanner;

  if (!isOpen) {
    return null;
  }

  return (
    <Banner
      className={cx(baseBanner.styles, className)}
      dataTestId={`${type}-banner`}
    >
      <div className={BASE_STYLE}>
        {icon && (
          <Icon
            name={baseBanner.icon as IconName}
            className={cx(
              "min-w-[1.5rem] h-6 flex justify-center items-center rounded-lg text-white",
              iconBackground
            )}
          />
        )}
        {label && <span className="px-2">{label}</span>}
      </div>
      {!labelLink && !onClose ? null : (
        <div className={cx(BASE_STYLE, "justify-end")}>
          {labelLink && onClick && (
            <Button
              className="px-2 py-0 text-blue-600 underline"
              onClick={handleLinkClick}
            >
              {labelLink}
            </Button>
          )}
          {withClose && isOpen && (
            <Button
              testId="close-button"
              className="flex items-center"
              onClick={handleClose}
            >
              <Icon name="close_big" />
            </Button>
          )}
        </div>
      )}
    </Banner>
  );
};

export { NetworkStatusBanner };
