import toNumber from "lodash/toNumber";
import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router";

//
// --- Types
//
type UrlValue = string | number | boolean;
type UrlParams = { [key: string]: UrlValue };

//
// --- Logic
//
const parseValue = (param: string): UrlValue => {
  if (param === "true") return true;
  if (param === "false") return false;
  if (toNumber(param)) return toNumber(param);

  return param;
};

const getUrlParams = <T extends UrlParams>(search: string): Partial<T> => {
  const defaultValue: Partial<T> = {};

  if (!search.includes("?")) {
    return {};
  }

  return search
    .slice(search.indexOf("?") + 1)
    .split("&")
    .map((hash) => hash.split("="))
    .reduce((a: Partial<T>, [key, val]) => {
      if (!key) return a;
      return { ...a, [key]: parseValue(decodeURIComponent(val ?? "")) };
    }, defaultValue);
};

const getNewQueryString = <T extends UrlParams>(
  oldParams: Partial<T>,
  params: Partial<T> = {},
  retainOld = true
) => {
  const newParams: Partial<T> = retainOld
    ? { ...oldParams, ...params }
    : params;

  const query = Object.keys(newParams)
    .map(
      (k) =>
        `${encodeURIComponent(k)}=${encodeURIComponent(newParams[k] ?? "")}`
    )
    .join("&");

  return query.length > 0 ? `?${query}` : "";
};

/**
 * Helper functions to read and manipulate query parameters
 * Will convert strings to booleans and numbers if possible
 * @returns UrlParams
 */
const useUrl = <T extends UrlParams>() => {
  const history = useHistory();
  const location = useLocation();
  const urlParams: Partial<T> = useMemo(
    () => getUrlParams(location.search),
    [location.search]
  );

  const setUrlParams = useCallback(
    (params: Partial<T>, retainOld = true) => {
      const query = getNewQueryString<T>(urlParams, params, retainOld);

      history.push({ ...location, search: query });
    },
    [urlParams, history, location]
  );

  return {
    setUrlParams,
    urlParams,
  };
};

const isValidHttpUrl = (url: string) => {
  let testUrl: URL;

  try {
    testUrl = new URL(url);
  } catch (_) {
    return false;
  }

  return testUrl.protocol === "http:" || testUrl.protocol === "https:";
};

export { getUrlParams, getNewQueryString, useUrl, isValidHttpUrl };
