import type { ReportsConfigSerializer } from "@/utils/damage-prevention";
import { UrbintModel } from "@/utils/UrbintModel";

class ReportsConfig extends UrbintModel<ReportsConfigSerializer> {
  get id() {
    return this.castNumber(this.data.id);
  }

  get message() {
    return this.castString(this.data.message);
  }

  get iframeUrl() {
    return this.castString(this.data.iframe_url);
  }

  get enabled() {
    return this.castBool(this.data.enabled);
  }

  compare() {
    return 1;
  }

  serialize(): ReportsConfigSerializer {
    return this.data;
  }
}

export { ReportsConfig };
