import type { SelectOption } from "@/components/Select";
import type { PublicConfig } from "@/models";
import type { DamagePreventionUserProfile } from "@/utils/types";
import { getFieldLabel } from "@/models";
import { TicketOrderField } from "@/utils/damage-prevention";

// Build default sort options
const searchOrderOptions: SelectOption<TicketOrderField>[] = Object.values(
  TicketOrderField
).map((value) => ({
  label: getFieldLabel(value),
  value,
}));

/**
 * Filter out sort options taking in consideration for
 * feature flags and user roles
 */
const getAllowedSortOptions = (
  publicConfig: PublicConfig | undefined,
  { canViewModelExplainer }: DamagePreventionUserProfile
): SelectOption<TicketOrderField>[] => {
  // Filter out both scores
  if (!canViewModelExplainer) {
    return searchOrderOptions.filter(
      (option) =>
        option.value !== TicketOrderField.scaled_impact_score &&
        option.value !== TicketOrderField.scaled_threat_score
    );
  }

  let sortOptions = [...searchOrderOptions];

  if (publicConfig && !publicConfig.enableScaledImpactScore) {
    sortOptions = sortOptions.filter(
      (option) => option.value !== TicketOrderField.scaled_impact_score
    );
  }

  if (publicConfig && !publicConfig.enableThreatScore) {
    sortOptions = sortOptions.filter(
      (option) => option.value !== TicketOrderField.scaled_threat_score
    );
  }

  return sortOptions;
};

export { getAllowedSortOptions };
