/** This file contains utilities used by the `Map` component */

/**
 * Mapbox GL access token.
 *
 * TODO: Remove hardcoded token
 */
const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1IjoidXJiaW50LWVuZyIsImEiOiJja3VlNjl5MWsxaGRqMnZvM3p5bTBidzZnIn0.KwFom9mm2h3lA9S017a3Fg";

/**
 * Map stylesheets.
 */
const MAP_STYLE_STREET = "mapbox://styles/urbint-eng/ckp2yuytn5dk017phz3m8o6sj";
const MAP_STYLE_SATELLITE = "mapbox://styles/mapbox/satellite-v9";

export { MAPBOX_ACCESS_TOKEN, MAP_STYLE_STREET, MAP_STYLE_SATELLITE };
