import type { PositiveResponseProps } from "./MemberCodeResponse.types";
import { formatResponseDate } from "./MemberCodeResponse.utils";

const MemberCodeResponse = ({ responses }: PositiveResponseProps) => {
  const memberCodeHasResponses = responses.length > 0;

  if (memberCodeHasResponses) {
    return (
      <>
        {responses.map((response) => {
          const { id, remarks, transmitTime, locatorName, code, label } =
            response;
          const hasRemarks = remarks.length > 0;
          return (
            <div
              key={id}
              className="flex flex-col space-y-4 py-3 border-b border-black border-opacity-6"
            >
              <p className="text-lg">
                {code || "00"}
                {`: ${label || "Unknown"}`}
              </p>
              {hasRemarks && <p className="text-lg font-normal">{remarks}</p>}
              <p className="text-lg font-normal">
                {transmitTime && formatResponseDate(transmitTime)}
                {transmitTime && locatorName && ` by ${locatorName}`}
              </p>
            </div>
          );
        })}
      </>
    );
  }

  return (
    <p className="text-lg font-normal px-6 py-3 border-b border-black border-opacity-6">
      No response
    </p>
  );
};

export { MemberCodeResponse };
