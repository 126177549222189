import type { CircleLayer, LayerProps, SourceProps } from "react-map-gl";
import type { DueDateCategorization } from "@/models/TicketVersion";
import { ImpactPotential } from "@/models/TicketVersion";
import { getThemeColor, riskColor } from "@/format";
import { CLUSTER_SIZE_CATEGORY } from "../../Markers";
import { hoverClusterMultiplier, hoverMultiplier } from "./ClusterLayer.utils";

const CLUSTER_POPUP_LAYOUT_CHANGE_ZOOM_VALUE = 13;
//
// --- Clusters
//
const TICKETS_SOURCE = "tickets";
const veryHigh = ["==", ["get", "risk"], "VERY_HIGH"];
const high = ["==", ["get", "risk"], "HIGH"];
const medium = ["==", ["get", "risk"], "MEDIUM"];
const low = ["==", ["get", "risk"], "LOW"];
const noAssets = ["==", ["get", "risk"], "NO_ASSETS"];
const missingData = ["==", ["get", "risk"], "MISSING_DATA"];
const unknown = [
  "!",
  [
    "in",
    ["get", "risk"],
    [
      "literal",
      ["VERY_HIGH", "HIGH", "MEDIUM", "LOW", "NO_ASSETS", "MISSING_DATA"],
    ],
  ],
];
const source: SourceProps = {
  id: TICKETS_SOURCE,
  cluster: true,
  type: "geojson",
  clusterRadius: 100,
  clusterMaxZoom: 20,
  clusterProperties: {
    // keep separate counts for each magnitude category in a cluster
    VERY_HIGH: ["+", ["case", veryHigh, 1, 0]],
    HIGH: ["+", ["case", high, 1, 0]],
    MEDIUM: ["+", ["case", medium, 1, 0]],
    LOW: ["+", ["case", low, 1, 0]],
    NO_ASSETS: ["+", ["case", noAssets, 1, 0]],
    NONE: ["+", ["case", unknown, 1, 0]],
    MISSING_DATA: ["+", ["case", missingData, 1, 0]],
    selectedTickets: ["+", ["case", ["==", ["get", "selected"], true], 1, 0]],
    hovered: ["+", ["case", ["==", ["get", "hovered"], true], 1, 0]],
  },
};
const ticketLayer: CircleLayer = {
  id: "ticket",
  type: "circle",
  source: TICKETS_SOURCE,
  filter: ["!", ["has", "point_count"]],
  paint: {
    "circle-color": [
      "match",
      ["get", "risk"],
      ImpactPotential.MISSING_DATA,
      getThemeColor(riskColor(ImpactPotential.MISSING_DATA)),
      ImpactPotential.NONE,
      getThemeColor(riskColor(ImpactPotential.NONE)),
      ImpactPotential.LOW,
      getThemeColor(riskColor(ImpactPotential.LOW)),
      ImpactPotential.MEDIUM,
      getThemeColor(riskColor(ImpactPotential.MEDIUM)),
      ImpactPotential.HIGH,
      getThemeColor(riskColor(ImpactPotential.HIGH)),
      ImpactPotential.VERY_HIGH,
      getThemeColor(riskColor(ImpactPotential.VERY_HIGH)),
      "gray",
    ],
    "circle-radius": hoverMultiplier(6),
    "circle-stroke-width": 2,
    "circle-stroke-color": "#fff",
  },
  layout: {},
};
const ticketSelectionLayer: LayerProps = {
  id: "ticket-selection",
  type: "circle",
  source: TICKETS_SOURCE,
  filter: [
    "all",
    ["boolean", ["get", "selected"]],
    ["!", ["has", "point_count"]],
  ],
  paint: {
    "circle-radius": hoverMultiplier(8),
    "circle-stroke-width": 1,
    "circle-color": "transparent",
    "circle-stroke-color": getThemeColor("bg-brand-40"),
  },
};

const clusterLayer: LayerProps = {
  id: "cluster",
  type: "circle",
  source: TICKETS_SOURCE,
  filter: ["all", ["has", "point_count"], [">", ["get", "point_count"], 1]],
  paint: {
    "circle-radius": [
      "case",
      [">", ["get", "point_count"], 1000],
      hoverClusterMultiplier(CLUSTER_SIZE_CATEGORY.VERY_LARGE + 4),
      [">", ["get", "point_count"], 500],
      hoverClusterMultiplier(CLUSTER_SIZE_CATEGORY.LARGE + 4),
      [">", ["get", "point_count"], 250],
      hoverClusterMultiplier(CLUSTER_SIZE_CATEGORY.MEDIUM + 4),
      [">", ["get", "point_count"], 100],
      hoverClusterMultiplier(CLUSTER_SIZE_CATEGORY.SMALL + 4),
      hoverClusterMultiplier(CLUSTER_SIZE_CATEGORY.VERY_SMALL + 4),
    ],
    // This layer is used to capture clicks
    // "circle-opacity": ["case", [">", ["get", "selectedTickets"], 0], 1, 0],
    "circle-stroke-width": 10,
    "circle-stroke-color": [
      "case",
      [">", ["get", "selectedTickets"], 0],
      getThemeColor("bg-brand-40"),
      "transparent",
    ],
    "circle-color": "transparent",
  },
};
const RISK_MAPPER: { [key in DueDateCategorization | string]: string } = {
  PAST_DUE: ImpactPotential.VERY_HIGH,
  TODAY: ImpactPotential.HIGH,
  TOMORROW: ImpactPotential.MEDIUM,
  AFTER_TOMORROW: ImpactPotential.LOW,
  UNKNOWN: ImpactPotential.NONE,
};

export {
  clusterLayer,
  ticketSelectionLayer,
  ticketLayer,
  TICKETS_SOURCE,
  source,
  RISK_MAPPER,
  CLUSTER_POPUP_LAYOUT_CHANGE_ZOOM_VALUE,
};
