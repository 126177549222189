import type { TicketStatusMenuProps } from "./TicketStatusMenu.types";
import { useCallback, useMemo, useState } from "react";
import classNames from "classnames";
import { MenuPanel } from "@/components/MenuPanel";
import { QueuedTicketStatus } from "@/models/TicketQueueItem";
import { Button } from "@/common/Button";

const TicketStatusMenu = ({
  item,
  onChangeStatus,
  onClickRemove,
  canRemoveFromQueue,
}: TicketStatusMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const closeMenu = () => setIsOpen(false);
  const openMenu = () => setIsOpen(true);

  const setStatusQueued = () => {
    if (item.isVisited) onChangeStatus(QueuedTicketStatus.QUEUED);
  };
  const setStatusVisited = () => {
    if (!item.isVisited) onChangeStatus(QueuedTicketStatus.VISITED);
  };

  const renderItem = (
    selected: boolean,
    handler: any,
    name: string,
    className?: string,
    testId?: string
  ) => (
    <button
      type="button"
      onClick={handler}
      data-testid={testId}
      className={classNames(
        className,
        "flex items-center px-3 h-10 w-52 relative",
        {
          "before:absolute": selected,
          "before:bg-brand-40": selected,
          "before:h-full": selected,
          "before:left-0": selected,
          "before:rounded-br-xl": selected,
          "before:rounded-tr-xl": selected,
          "before:top-0": selected,
          "before:w-1": selected,
          "cursor-not-allowed": selected,
        }
      )}
    >
      {name}
    </button>
  );

  const removeFromQueueOption = useMemo(
    () =>
      canRemoveFromQueue &&
      renderItem(
        false,
        onClickRemove,
        "Remove from queue",
        "text-red-800",
        "remove-from-queue-option"
      ),
    [canRemoveFromQueue, onClickRemove]
  );

  const renderButton = useCallback(() => {
    const bgClass = item.isVisited ? "bg-green-800" : "bg-brand-40";
    const status = item.isVisited ? "Visited" : "In queue";
    const onClick = isOpen ? closeMenu : openMenu;

    return (
      <Button
        className={`text-white ${bgClass}`}
        iconEnd="chevron_big_down"
        onClick={onClick}
        testId="ticket-queue-status"
      >
        {status}
      </Button>
    );
  }, [isOpen, item.isVisited]);

  return (
    <MenuPanel
      placement="bottom-start"
      show={isOpen}
      renderButton={renderButton}
      onClickOutside={closeMenu}
    >
      <div className="focus:outline-none">
        <header className="bg-gray-200 h-8 flex items-center justify-start px-3 text-gray-700">
          Queued ticket status
        </header>
        {renderItem(
          !item.isVisited,
          setStatusQueued,
          "In queue",
          "",
          "in-queue-status-option"
        )}
        {renderItem(
          item.isVisited,
          setStatusVisited,
          "Visited",
          "",
          "visited-status-option"
        )}
        <hr />
        {removeFromQueueOption}
      </div>
    </MenuPanel>
  );
};

export { TicketStatusMenu };
