import type { TicketQueueProps } from "./TicketQueue.types";
import type { GeocoderResult } from "../GeocodeAutocompleteInput";
import { IonSkeletonText } from "@ionic/react";
import { useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { TicketQueueState } from "@/models/TicketQueue";
import { GeocodeRouteModal } from "@/components/Modals";
import { useToasts } from "../Toasts";
import { TicketQueueErrorFallback } from "./TicketQueueErrorFallback";
import { TicketQueueView } from "./TicketQueueView";

const TicketQueue = ({
  onOpenOptimizeModal,
  onCloseOptimizeModal,
  queue,
  optimizeQueue,
  currentUser,
}: TicketQueueProps) => {
  const { addToast } = useToasts();

  const canRemoveFromQueue = currentUser.canRemoveTicketQueueItems;
  const [modalOpen, setModalOpen] = useState(false);

  if (queue.isInitialLoading) {
    return <IonSkeletonText />;
  }

  if (queue.isError) {
    throw queue.error;
  }

  if (!queue.data) {
    return null;
  }

  if (queue.data.state === TicketQueueState.ERROR) {
    throw new Error(
      queue.data.errorMessage
        ? queue.data.errorMessage
        : "Failed to load your queue. No further information is available"
    );
  }

  //
  // --- Convenience functions ---
  //
  const showModal = () => {
    if (onOpenOptimizeModal) onOpenOptimizeModal();
    setModalOpen(true);
  };
  const hideModal = () => {
    if (onCloseOptimizeModal) onCloseOptimizeModal();
    setModalOpen(false);
  };

  // Handle route optimization
  const optimizeRoute = ([start, end]: [
    start: GeocoderResult,
    end: GeocoderResult
  ]) => {
    hideModal();
    // destructure lat and lng values
    const [startLng, startLat] = start.center;
    const [endLng, endLat] = end.center;
    // check if all lat lng values are not null
    if (queue?.data && startLng && startLat && endLng && endLat) {
      // route optimization
      optimizeQueue
        .mutateAsync({
          queue: queue.data,
          start: { lng: startLng, lat: startLat },
          end: { lng: endLng, lat: endLat },
        })
        .catch((error) => {
          addToast(error.json.message, "error");
        });
    }
  };

  return (
    <ErrorBoundary FallbackComponent={TicketQueueErrorFallback}>
      <GeocodeRouteModal
        isOpen={modalOpen}
        onClose={hideModal}
        onOptimize={optimizeRoute}
      />
      <TicketQueueView
        busy={queue.isFetching}
        hasError={queue.isError}
        onClickOptimize={showModal}
        queue={queue.data}
        canRemoveFromQueue={canRemoveFromQueue}
      />
    </ErrorBoundary>
  );
};
export { TicketQueue };
