import type { EgressRouteSerializer } from "@/utils/damage-prevention";
import { UrbintModel } from "@/utils/UrbintModel";

class EgressRoute extends UrbintModel<EgressRouteSerializer> {
  get id() {
    return this.castNumber(this.data.id);
  }

  get target() {
    return this.castString(this.data.target);
  }

  get type() {
    return this.castString(this.data.type);
  }

  serialize(): EgressRouteSerializer {
    return this.data;
  }

  compare(instance: EgressRoute): number {
    return this.id - instance.id;
  }
}

export { EgressRoute };
