class Opco {
  protected readonly data: any;

  constructor(data: any) {
    this.data = data;
  }

  get name() {
    return this.data.name;
  }

  get domain() {
    return this.data.domain;
  }

  get current() {
    return this.data.current === true;
  }

  get url() {
    const { protocol, port } = window.location;
    const url = new URL(`${protocol}//${this.domain}:${port}`);
    return url.origin;
  }
}

export { Opco };
