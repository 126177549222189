import type { TasksSectionProps } from "./TasksSection.types";
import { addCircleOutline } from "ionicons/icons";
import { useState } from "react";
import { useTasksGroupedByWorkflowForTicket } from "@/api";

import {
  useDamagePreventionAuth,
  useOnlineStatus,
  useResponsive,
} from "@/hooks";
import { AddTaskModal } from "@/components/Modals";

import {
  MobileHeaderTabsAndAnchorsNames,
  mobileSectionBorder,
} from "@/pages/TicketPage/TicketPage.constants";
import {
  SidebarActionButton,
  SidebarActionContainer,
  SidebarContentContainer,
  SidebarContentMobileHeader,
} from "@/pages/TicketPage/TicketPageSideBar/Sidebar";
import { Loading } from "@/common/Loading";
import { WorkflowGroup } from "./WorkflowGroup";

const TasksSection = ({ ticket, onTaskClick }: TasksSectionProps) => {
  const { isMd } = useResponsive();
  const { currentUser } = useDamagePreventionAuth();
  const [isAddTaskModalOpen, setIsAddTaskModalOpen] = useState(false);
  const isOnline = useOnlineStatus();

  const { data: tasksByWorkflow, isLoading } =
    useTasksGroupedByWorkflowForTicket(
      // TODO: Fix no-non-null-asserted-optional-chain
      ticket?.id!,
      {
        enabled: ticket?.id !== undefined,
        // Will fire the first time this particular hooks settles
      }
    );

  const canCreateTask = currentUser.canCreateTasks && isOnline;

  return (
    <div
      id={MobileHeaderTabsAndAnchorsNames.Tasks}
      className={`${mobileSectionBorder} md:h-full relative`}
    >
      {canCreateTask && (
        <AddTaskModal
          ticket={ticket}
          isOpen={isAddTaskModalOpen}
          onClose={() => setIsAddTaskModalOpen(false)}
        />
      )}
      <SidebarContentContainer className={canCreateTask ? "md:pb-16" : ""}>
        <SidebarContentMobileHeader
          title="Tasks"
          showButton={canCreateTask ?? false}
          buttonText="Add task"
          buttonIcon={addCircleOutline}
          onButtonClick={() => setIsAddTaskModalOpen(true)}
        />
        <>
          {isLoading && <Loading small={!isMd} />}
          {[...(tasksByWorkflow || [])].map(([workflow, tasks]) => (
            <WorkflowGroup
              ticket={ticket}
              key={`workflow-${workflow.id}`}
              onTaskClick={onTaskClick}
              tasks={tasks}
              name={workflow.name}
            />
          ))}
        </>
      </SidebarContentContainer>
      {canCreateTask && (
        <SidebarActionContainer>
          <SidebarActionButton
            onClick={() => setIsAddTaskModalOpen(true)}
            dataTestId="sidebar-task-action-button"
          >
            Add task
          </SidebarActionButton>
        </SidebarActionContainer>
      )}
    </div>
  );
};

export { TasksSection };
