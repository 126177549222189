import { useEffect } from "react";
import { useReportFleetLocation, usePublicConfig } from "@/api";
import { useGeoLocation } from "@/hooks/useGeoLocation/useGeoLocation";

const useReportUserLocationUpdatesToFleet = () => {
  const { data: publicConfig } = usePublicConfig();
  const { mutateAsync } = useReportFleetLocation();

  const { loading, error, latitude, longitude } = useGeoLocation();

  useEffect(() => {
    if (publicConfig?.fleetTracking) {
      if (loading) return;

      if (error) {
        // TODO handle the error once we know what to do about it
        console.error(error?.message);
      }

      if (latitude !== null && longitude !== null) {
        mutateAsync({
          lat: latitude,
          lng: longitude,
        });
      }
    }
  }, [error, latitude, loading, longitude, mutateAsync, publicConfig]);

  return null;
};

export { useReportUserLocationUpdatesToFleet };
