import type { ActionBarRightProps } from "./ActionBarRight.types";
import { useOnlineStatus } from "@/hooks";
import { ActionBarRight } from "./ActionBarRight";
import { ActionBarRightOffline } from "./ActionBarRightOffline";

const ActionBarRightEntry = (props: ActionBarRightProps) => {
  const isOnline = useOnlineStatus();

  if (!isOnline) {
    const {
      isShowingTasks,
      toggleIsShowingTasks,
      ticketCount,
      setThresholdToastShown,
    } = props;

    return (
      <ActionBarRightOffline
        isShowingTasks={isShowingTasks}
        toggleIsShowingTasks={toggleIsShowingTasks}
        ticketCount={ticketCount}
        setThresholdToastShown={setThresholdToastShown}
      />
    );
  }
  return <ActionBarRight {...props} />;
};

export { ActionBarRightEntry };
