import type { MapPageMapProps } from "./MapPageMap.types";
import { BodyText } from "@urbint/silica";
import { useOnlineStatus } from "@/hooks";
import { MapPageMap } from "./MapPageMap";

const MapPageEntry = ({ children }: MapPageMapProps) => {
  const isOnline = useOnlineStatus();
  if (isOnline) {
    if (children) {
      return <MapPageMap>{children}</MapPageMap>;
    }
    return <MapPageMap />;
  }

  return (
    <div className="w-full sm:w-[calc(100%-theme(spacing.sidebar))] h-full flex justify-center content-center bg-backgrounds-gray items-center absolute right-0">
      <BodyText>This map is not supported when offline.</BodyText>
    </div>
  );
};
export { MapPageEntry };
