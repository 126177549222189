import type { UseQueryOptions } from "@tanstack/react-query";
import type { TaskStepSerializer } from "@/utils/damage-prevention";
import { useQuery } from "@tanstack/react-query";
import { TaskStep } from "@/models";
import { UrbintApi } from "@/utils/UrbintApi";
import { queryClient } from "@/api/client";
import { handleApiResponse } from "@/api/helpers";

const api = new UrbintApi();

const taskStepsIdent = () => ["task_steps"];

const fetchTaskSteps = () =>
  api
    .getPage<TaskStepSerializer>({
      endPoint: "task_steps",
      page: 1,
    })
    .then(handleApiResponse)
    .then((res) => res.results.map((r) => new TaskStep(r)));

const useTaskSteps = (opts?: UseQueryOptions<TaskStep[]>) =>
  useQuery<TaskStep[]>(taskStepsIdent(), () => fetchTaskSteps(), opts);

const prefetchTaskSteps = () =>
  queryClient.prefetchQuery(taskStepsIdent(), fetchTaskSteps);

export { useTaskSteps, prefetchTaskSteps, taskStepsIdent };
