import type { SelectOption } from "./types";

type T = any;

const filterDuplicatesByLabel = (options?: SelectOption<T>[]) =>
  options?.filter(
    (v: SelectOption<T>, i, array: SelectOption<T>[]) =>
      array.findIndex((v2) => v2.label === v.label) === i
  ) || [];

export { filterDuplicatesByLabel };
