import type { UserPreferencesSerializer } from "@/utils/damage-prevention";
import { UrbintModel } from "@/utils/UrbintModel";

class UserPreferences extends UrbintModel<UserPreferencesSerializer> {
  get id(): number {
    return this.data.user.id;
  }

  get userId(): number {
    return this.castNumber(this.data.user);
  }

  get defaultTicketView(): number | undefined {
    if (!this.data.default_ticket_view) return undefined;
    return this.castNumber(this.data.default_ticket_view);
  }

  serialize(): UserPreferencesSerializer {
    return this.data;
  }

  compare(instance: UserPreferences) {
    return this.userId - instance.userId;
  }
}

export { UserPreferences };
