import type { MarkerProps } from "react-map-gl";

/**
 * Possible status types for each field member.
 */
export enum FieldMemberStatusType {
  ACTIVE = "active",
  INACTIVE = "inactive",
  HIDDEN = "hidden",
  NONE = "none",
}

/**
 * Main component props declaration.
 */
export type FieldMemberMarkerProps = {
  userId: number;
  firstName: string;
  lastName: string;
  lastUpdate: Date;
  onMouseOver?: (id: number) => void;
  onMouseLeave?: () => void;
} & MarkerProps;
