import type { TicketQueueSerializer } from "@/utils/damage-prevention";
import type { ApiResponse } from "@/api/helpers";
import { useMutation } from "@tanstack/react-query";

import { TicketQueue } from "@/models";
import { UrbintApi } from "@/utils/UrbintApi";
import { invalidateCurrentTicketQueue } from "@/api";

const handleApiResponse = <T>(res: ApiResponse<T>) => {
  if (res.error) {
    throw res;
  } else if (!res.json) {
    throw new Error("No Return Value");
  }
  return res.json;
};

const api = new UrbintApi();
const optimize = ({
  queue,
  start,
  end,
}: {
  queue: TicketQueue;
  start: { lng: number; lat: number };
  end?: { lng: number; lat: number };
}) => {
  if (!start) {
    throw new Error("cannot optimize route without a starting location");
  }

  // If the `end` location is not provided, the server will  automatically
  // use the start location as both the start and end locations
  const body = { start, end };
  return api
    .fetch<TicketQueueSerializer>(`ticket_queues/${queue.id}/optimize`, {
      method: "POST",
      body: JSON.stringify(body),
    })
    .then(handleApiResponse)
    .then((res) => new TicketQueue(res));
};

/**
 * Optimize a users current queue (takes, start, end)
 */
const useOptimizeTicketQueue = () =>
  useMutation(optimize, { onSuccess: invalidateCurrentTicketQueue });

export { useOptimizeTicketQueue };
