import type { MenuItemProps } from "./MenuItem.types";
import { Menu } from "@headlessui/react";
import { Switch } from "@/components/Switch";

const MenuItem = ({ title, onToggle, enabled }: MenuItemProps) => (
  <div className="py-2.5">
    <Menu.Item disabled>
      <div
        className="flex flex-row items-center justify-between font-sans text-base whitespace-nowrap"
        data-testid="layer-text"
      >
        Show {title}
        <Switch
          dataTestId={`${title}-menu-item-switch`}
          checked={enabled}
          label="Something"
          onChange={onToggle}
          showLabel={false}
          className="ml-10 pt-0.5"
        />
      </div>
    </Menu.Item>
  </div>
);

export { MenuItem };
