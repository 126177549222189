import type { Task } from "@/models";
import { useMutation } from "@tanstack/react-query";

import { UrbintApi } from "@/utils/UrbintApi";
import { handleApiResponse } from "@/api/helpers";
import {
  invalidateUseFlexTicketQuery,
  invalidateUseFlexTicketsQuery,
  invalidateUseTicketActivityQuery,
  invalidateUseTasksForTicketQuery,
} from "@/api";

interface IUseChangeTaskAssignee {
  task: Task;
  assignee: string | number | undefined;
}

const api = new UrbintApi();

const changeTaskAssignee = (opts: IUseChangeTaskAssignee) =>
  api
    .patch(
      "tasks",
      JSON.stringify({
        assignee: opts.assignee ? opts.assignee : null,
      }),
      opts.task.id
    )
    .then(handleApiResponse);

const useChangeTaskAssignee = () =>
  useMutation(changeTaskAssignee, {
    onSuccess: (_, { task }) => {
      // Invalidate any queries that might be affected
      // by this change
      invalidateUseTicketActivityQuery(task.ticketId);
      invalidateUseTasksForTicketQuery(task.ticketId);
      invalidateUseFlexTicketQuery(task.ticketId);
      invalidateUseFlexTicketsQuery();
    },
  });

export { useChangeTaskAssignee };
