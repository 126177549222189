import type { DropdownSelectOptionProps } from "./DropdownSelectOption.types";
import { Icon } from "@urbint/silica";
import classNames from "classnames";

const DropdownSelectOption = ({
  active,
  selected,
  children,
  highlightSelectedOption = false,
}: DropdownSelectOptionProps) => (
  <div
    className={classNames(
      `px-4 py-3 truncate cursor-default select-none items-center bg-opacity-11 flex`,
      {
        "bg-black": active && !selected,
        "bg-blue-800": active && selected,
        "bg-blue-600": selected && !active,
      }
    )}
  >
    <div className="flex justify-between w-full">
      {children}
      {highlightSelectedOption && selected && (
        <Icon name="check" className="text-badges-green text-xl" />
      )}
    </div>
  </div>
);

export { DropdownSelectOption };
