import type { GeoLocationSensorState } from "react-use/lib/useGeolocation";
import React, { useContext } from "react";

const initialStateGeoLocationState = {
  loading: true,
  accuracy: null,
  altitude: null,
  altitudeAccuracy: null,
  heading: null,
  latitude: null,
  longitude: null,
  speed: null,
  timestamp: null,
};

// https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError/code
const GEO_ERROR_CODES = {
  PERMISSION_DENIED: 1,
  POSITION_UNAVAILABLE: 2,
  TIMEOUT: 3,
};

const LocationContext = React.createContext<GeoLocationSensorState>(
  initialStateGeoLocationState
);

const useGeoLocation = (): GeoLocationSensorState =>
  useContext(LocationContext);

export { GEO_ERROR_CODES, LocationContext, useGeoLocation };
