import type { FallbackProps } from "react-error-boundary";

import { Error } from "@/components/Error";

/**
 * Intended to be used as the `FallbackComponent` prop of the
 * [`ReactErrorBoundary` component](https://github.com/bvaughn/react-error-boundary).
 */
const AppCrashPage = (fallbackProps: FallbackProps) => (
  <div className="bg-white fixed flex h-screen inset-0 md:place-content-center md:place-items-center overflow-auto text-neutral-shade-primary w-screen">
    <Error {...fallbackProps} />
  </div>
);

export { AppCrashPage };
