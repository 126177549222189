import type { PropsWithChildren } from "react";

import type {
  OverflowContextAPI,
  OverflowProviderProps,
} from "./OverflowProvider.types";
import { createContext, useState, useMemo } from "react";

const OverflowContext = createContext<OverflowContextAPI>({} as any);

// TODO: Provider still to restructure
const OverflowProvider = ({ children }: OverflowProviderProps) => {
  const [menuItems, setMenuItems] = useState<OverflowContextAPI["menuItems"]>(
    {}
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const value = useMemo<OverflowContextAPI>(
    () => ({
      menuItems,
      registerMenuItem: (id, item) => {
        setMenuItems((s) => ({
          ...s,
          [id]: {
            ...item,
            isOverflowing: s[id]?.isOverflowing,
          },
        }));
      },
      updateMenuItem: (id, item) => {
        setMenuItems((s) => {
          if (!s[id]) return s;

          return {
            ...s,
            [id]: { ...s[id]!, ...item },
          };
        });
      },
      toggleOverflow: (id, isOverflowing) => {
        setMenuItems((s) => {
          const item = s[id];
          if (item) {
            return { ...s, [id]: { ...item, isOverflowing } };
          }
          return s;
        });
      },
      deregisterMenuItem: (id) => {
        setMenuItems((s) => {
          const newState = { ...s };
          delete newState[id];
          return newState;
        });
      },
      getOverflowItems: () =>
        Object.values(menuItems).filter((o) => o.overflow),
      hasItems: () =>
        Object.values(menuItems).filter(
          (ob) => !ob.overflow || !ob.isOverflowing
        ).length > 0,
      setIsOpen,
      isOpen,
    }),
    [isOpen, menuItems]
  );
  return (
    <OverflowContext.Provider value={value}>
      {children}
    </OverflowContext.Provider>
  );
};

function withOverflowProvider<T>(
  Component: (props: PropsWithChildren<T>) => JSX.Element
) {
  return function OverflowWrapper(props: PropsWithChildren<T>) {
    return (
      <OverflowProvider>
        <Component {...props} />
      </OverflowProvider>
    );
  };
}

export { OverflowProvider, OverflowContext, withOverflowProvider };
