import { uniqueId } from "lodash";
import { createDonutPath, prepareDonutParams } from "./ClusterMarker.utils";
import { fallbackClassNameColor } from "./ClusterMarker.constants";

const ClusterMarker = ({ vals, scaler = 1 }: any) => {
  const {
    donutDiameter,
    fontSize,
    counts,
    donutOffset,
    total,
    innerRadius,
    colors,
    offsets,
  } = prepareDonutParams(vals, scaler);

  if (!counts || counts.length === 0) {
    return null;
  }

  return (
    <div className="rounded-full p-1 bg-white" data-testid="cluster-map">
      <svg
        width={donutDiameter}
        height={donutDiameter}
        viewBox={`0 0 ${donutDiameter} ${donutDiameter}`}
        textAnchor="middle"
        style={{ fontSize: `${fontSize}px`, display: "block" }}
        className="cursor-pointer font-sans font-semibold"
      >
        <circle
          cx={donutOffset}
          cy={donutOffset}
          data-name="iris"
          r={innerRadius}
          fill="white"
        />
        {counts.map((_, i) => {
          const count = counts[i] ?? null;
          const offset = offsets[i] ?? null;
          if (count === null || offset === null) {
            return null; // Skip this iteration if count is 0
          }

          const end = ((offsets[i] ?? 0) + count) / (total ?? 0);

          return (
            <path
              key={uniqueId()}
              d={createDonutPath(offset / total, end, donutOffset, innerRadius)}
              fill="currentColor"
              className={colors[i] ?? fallbackClassNameColor}
            />
          );
        })}
        <text
          fill="black"
          dominantBaseline="central"
          transform={`translate(${donutOffset}, ${donutOffset})`}
        >
          {total.toLocaleString()}
        </text>
      </svg>
    </div>
  );
};

export { ClusterMarker };
