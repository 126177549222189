import type { FlexTask, FlexTicket } from "@/models";
import type { MapTicketCardProps } from "./MapTicketCard.types";
import { useCallback, useMemo } from "react";
import classNames from "classnames";
import { useTicketSelector } from "@/components/TicketSelectorProvider";
import { useFlexTicket } from "@/api";
import { Spinner } from "@/common/Spinner";
import { flexFieldsForTicketCard, TicketCard } from "../../TicketCard";
import { useTicketViewEditor } from "../../TicketViewEditorProvider";

const MapTicketCard = ({ ticketId, className }: MapTicketCardProps) => {
  const { isShowingTasks, showTask } = useTicketViewEditor();
  const {
    selectedTickets,
    selectMode,
    toggleTicketId,
    toggleTask,
    getSelectedTasksForTicket,
  } = useTicketSelector();
  const fields = flexFieldsForTicketCard(isShowingTasks);
  const { isFetching, data } = useFlexTicket(ticketId, { fields });

  const ticket: FlexTicket | undefined = useMemo(() => {
    if (!data) return;
    return { ...data, tasks: data.tasks?.filter(showTask) };
  }, [data, showTask]);
  const selectedTasks = getSelectedTasksForTicket(ticket);

  const handleSelectTicket = useCallback(
    (ticket: FlexTicket) => {
      toggleTicketId(ticket.id!);
    },
    [toggleTicketId]
  );

  const handleSelectTask = useCallback(
    (task: FlexTask, ticketId: number) => {
      toggleTask(task, ticketId);
    },
    [toggleTask]
  );

  return (
    <div
      className={classNames(
        "overflow-hidden rounded flex flex-row items-center justify-center flex-1 drop-shadow-lg min-h-[125px] md:w-[350px] w-full",
        className
      )}
    >
      {ticket && !isFetching ? (
        <TicketCard
          showTasks={isShowingTasks}
          ticket={ticket}
          selectMode={selectMode}
          selected={selectedTickets.includes(ticketId)}
          onSelect={handleSelectTicket}
          onTaskSelected={handleSelectTask}
          selectedTasks={selectedTasks}
          className="w-full bg-white rounded-md overflow-hidden"
        />
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export { MapTicketCard };
