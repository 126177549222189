import type { LimitedConnectionModalProps } from "./LimitedConnectionModal.types";
import { BodyText } from "@urbint/silica";
import { Dialog } from "@/components/Dialog";

const LimitedConnectionModal = ({
  isOpened,
  onClose,
}: LimitedConnectionModalProps) => (
  <Dialog
    dataTestId="limitedConnectionModal"
    isOpen={isOpened}
    onClose={onClose}
    header="No internet connection"
    footer={{
      hasCancel: false,
      toRender: "Got it",
      submitButtonTestId: "gotit-button",
      onClick: onClose,
    }}
  >
    <BodyText className="pb-6">
      Lens for Damage Prevention will run in offline mode until connection is
      restored, after which offline changes will be synced.
    </BodyText>
    <BodyText>
      <span className="pt-6 font-bold">While in offline mode:</span>
    </BodyText>
    <ul className="list-disc list-inside">
      <li className="pl-3">New tickets will not be fetched</li>
      <li className="pl-3">
        Only the tickets in “My open tickets” and “My recently closed tickets”
        saved views will be accessible
      </li>
      <li className="pl-3">Maps are disabled</li>
      <li className="pl-3">
        Task related features are limited to only editing and completing
        response forms
      </li>
    </ul>
  </Dialog>
);

export { LimitedConnectionModal };
