import type { SelectOption } from "@/components/Select";
import type {
  displayTicketStageProps,
  getLeftFieldsProps,
  onTicketStageChangedProps,
} from "../TicketDetailsSection.types";
import { useState } from "react";
import { Tooltip } from "@/common/Tooltip";
import { startCase } from "@/format";
import { TicketStage } from "@/utils/damage-prevention";
import { DropdownSelect } from "@/components/Select";
import { TicketStageCancelModal } from "@/components/Modals/TicketStageCancelModal";
import { ticketStageOptions } from "../TicketDetailsSection.constants";

const TICKET_STAGE_OPTIONS: SelectOption<TicketStage>[] =
  ticketStageOptions.map((option) => ({ value: option, label: option }));

const onTicketStageChanged = async ({
  ticketStage,
  mutation,
  addToast,
}: onTicketStageChangedProps) => {
  await mutation
    .mutateAsync(ticketStage, {
      onSuccess: () => {
        addToast("Ticket stage changed", "success");
      },
    })
    ?.catch((error) => {
      if (error instanceof Error) {
        addToast(`Error updating ticket stage: ${error.message}`, "error");
      } else if (error?.stage) {
        addToast(error.stage, "error");
      } else {
        addToast("Error updating ticket stage", "error");
      }
    });
};

const displayTicketStage = ({
  stage,
  canEditTicketLifeCycle,
  mutation,
  addToast,
}: displayTicketStageProps) => {
  const [modalVisibility, setModalVisibility] = useState(false);

  if (stage === TicketStage.CANCELED) {
    return (
      <Tooltip
        title="Cannot be modified on canceled tickets"
        testId="cancel-stage-tooltip"
      >
        {startCase(stage)}
      </Tooltip>
    );
  }

  const handleCancelTicketStage = () => {
    onTicketStageChanged({
      ticketStage: TicketStage.CANCELED,
      mutation,
      addToast,
    });
  };

  const handleTicketStage = (ticketStage: TicketStage) => {
    if (ticketStage === TicketStage.CANCELED) {
      setModalVisibility(true);
    } else {
      onTicketStageChanged({ ticketStage, mutation, addToast });
    }
  };

  return canEditTicketLifeCycle ? (
    <>
      <DropdownSelect
        testId="edit-ticket-stage-dropdownSelect"
        className="h-auto pl-0 pr-4"
        value={stage}
        options={TICKET_STAGE_OPTIONS}
        onChange={handleTicketStage}
      />
      <TicketStageCancelModal
        modalVisibility={modalVisibility}
        setModalVisibility={setModalVisibility}
        handleCancelTicketStage={handleCancelTicketStage}
      />
    </>
  ) : (
    startCase(stage)
  );
};

const getLeftFields = ({
  ticket,
  canEditTicketLifeCycle,
  mutation,
  addToast,
}: getLeftFieldsProps) => {
  const {
    latestVersion: {
      version = "",
      type = "",
      workDoneFor = "",
      excavationWorkType = "",
      equipmentType = "",
    } = {},
    stage,
    number,
  } = ticket ?? {};

  return [
    {
      label: "Ticket number",
      value: number && `#${number}`,
      testId: "ticketNumber",
    },
    {
      label: "Ticket version",
      value: version,
      testId: "ticketVersion",
    },
    {
      label: "Ticket stage",
      value: displayTicketStage({
        stage,
        canEditTicketLifeCycle,
        mutation,
        addToast,
      }),
      testId: "ticketStage",
    },
    {
      label: "Ticket type",
      value: startCase(type),
      testId: "ticketType",
    },
    {
      label: "Work done for",
      value: startCase(workDoneFor),
      testId: "workDoneFor",
    },
    {
      label: "Work type",
      value: startCase(excavationWorkType),
      testId: "workType",
    },
    {
      label: "Equipment",
      value: startCase(equipmentType),
      testId: "equipment",
    },
  ];
};

export { getLeftFields };
