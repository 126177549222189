import type { NotificationSerializer } from "@/utils/damage-prevention";
import { useQuery } from "@tanstack/react-query";
import { Notification } from "@/models";
import { UrbintApi } from "@/utils/UrbintApi";
import { queryClient } from "@/api/client";
import { handleApiResponse } from "@/api/helpers";

type IReturn = Notification[];

const api = new UrbintApi();
const notificationsIdent = "notifications";

const fetchNotifications = () =>
  api
    .getPage<NotificationSerializer>({
      endPoint: "notifications",
      page: 1,
    })
    .then(handleApiResponse)
    .then((res) => res.results.map((r) => new Notification(r)));

const useNotifications = () =>
  useQuery<IReturn>([notificationsIdent], () => fetchNotifications(), {
    refetchInterval: 1000 * 60, // 1 min polling
  });

const prefetchNotifications = () =>
  queryClient.prefetchQuery([notificationsIdent], fetchNotifications);

const invalidateNotificationsQuery = () =>
  queryClient.invalidateQueries([notificationsIdent]);

export {
  notificationsIdent,
  useNotifications,
  prefetchNotifications,
  invalidateNotificationsQuery,
};
