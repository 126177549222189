import type { MoreMenuViewProps } from "./MoreMenuView.types";
import type { dialogFooter } from "@/components/Dialog";
import { Icon, Subheading } from "@urbint/silica";
import { useState } from "react";
import { MenuPanel } from "@/components/MenuPanel";
import { Dialog } from "@/components/Dialog";

const MoreMenuView = ({
  onClearQueue,
  onRemoveViewedTickets,
}: MoreMenuViewProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const closeMenu = () => setIsOpen(false);
  const openMenu = () => setIsOpen(true);
  const [showConfirmDeleteModal, setConfirmDeleteModal] = useState(false);

  //
  // --- Logic
  //
  const closeConfirmDeleteModal = () => setConfirmDeleteModal(false);

  const clearQueue = () => {
    onClearQueue();
    closeMenu();
  };

  //
  // --- Handlers
  //
  const handleClickClearQueue = () => {
    setConfirmDeleteModal(true);
  };

  const handleClickRemoveViewedTickets = () => {
    onRemoveViewedTickets();
    closeMenu();
  };

  const modalFooter: dialogFooter = {
    toRender: "Yes, continue",
    onClose: closeConfirmDeleteModal,
    onClick: clearQueue,
  };

  return (
    <>
      <MenuPanel
        arrow
        placement="bottom-end"
        show={isOpen}
        onClickOutside={closeMenu}
        renderButton={() => (
          <button
            type="button"
            aria-label="more options"
            onClick={isOpen ? closeMenu : openMenu}
          >
            <Icon name="more_horizontal" />
          </button>
        )}
      >
        <div className="flex flex-col px-3 py-2">
          <button
            type="button"
            className="py-3 text-left"
            onClick={handleClickRemoveViewedTickets}
          >
            Remove all visited tickets
          </button>
          <button
            type="button"
            className="text-red-900 py-3 text-left"
            onClick={handleClickClearQueue}
          >
            Remove all tickets in queue&hellip;
          </button>
        </div>
      </MenuPanel>
      {/* Warning Modal */}
      {/* TODO: We're using this in two places now. A contender for us to "reuse" at some point */}
      <Dialog
        isOpen={showConfirmDeleteModal}
        onClose={closeConfirmDeleteModal}
        header=""
        variant="warning"
        footer={modalFooter}
      >
        <Subheading>
          Are you sure you want to remove all tickets from your queue?
        </Subheading>
      </Dialog>
    </>
  );
};

export { MoreMenuView };
