import type { MutableRefObject, RefObject } from "react";
import { useCallback, useRef, useState } from "react";

/**
 * When you need a ref, but you need changes to that ref to cause a re-render.
 * Example usage would be for VirtualList, which needs to perform layout
 * calculations anytime its parent scroll ref changes.
 */
const useRefState = <T,>(
  defaultValue: T
): [MutableRefObject<T> | RefObject<T>, (value: T) => void] => {
  const ref = useRef<T>(defaultValue);
  const [refState, setRefState] = useState(ref);
  const updateRefState = useCallback((newValue: T) => {
    ref.current = newValue;
    setRefState({ ...ref });
  }, []);

  return [refState, updateRefState];
};

export { useRefState };
