import type { DamageThreatSerializer } from "@/utils/damage-prevention";
import type { DamagePotential } from "./TicketVersion";
import { UrbintModel } from "@/utils/UrbintModel";
import { Insight } from "./Insight";

enum ThreatRank {
  NA = "N/A",
}

type ThreatRankType = ThreatRank | DamagePotential;

class DamageThreat extends UrbintModel<DamageThreatSerializer> {
  get id() {
    return this.castNumber(this.data.id);
  }

  get ticket() {
    return this.castNumber(this.data.ticket);
  }

  get damageRate() {
    if (!this.data.damage_rate) return null;
    return this.castNumber(this.data.damage_rate);
  }

  get percentile() {
    if (!this.data.percentile) return null;
    return this.castNumber(this.data.percentile);
  }

  get feedbackLoop() {
    return this.castString(this.data.feedback_loop);
  }

  get threatRank(): ThreatRankType {
    return this.castString(this.data.threat_rank) as ThreatRankType;
  }

  get insight(): Insight {
    return new Insight(this.data.insight);
  }

  serialize(): DamageThreatSerializer {
    return this.data;
  }

  compare(): number {
    throw new Error("Method not implemented.");
  }
}

export { DamageThreat, ThreatRank };

export type { ThreatRankType };
