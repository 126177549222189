import { useEffect, useState } from "react";

// Default debounce delay in milliseconds
const DEBOUNCE_DEFAULT_DELAY = 300;

/**
 * `T` is a generic type for value parameter, our case this will be string
 * TODO: consider replacing this by https://usehooks-ts.com/react-hook/use-debounce
 */
const useDebounce = <T,>(
  value: T,
  {
    delay = DEBOUNCE_DEFAULT_DELAY,
    throttle,
  }: { delay: number; throttle?: boolean }
): T => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  const [lastChange, setLastChange] = useState<number>();
  useEffect(() => {
    // Update at least every delay tick
    if (throttle && lastChange && Date.now() - lastChange > delay) {
      setDebouncedValue(value);
      setLastChange(Date.now());
    }

    // Update debounced value after delay
    const handler = setTimeout(() => {
      setDebouncedValue(value);
      setLastChange(Date.now());
    }, delay);

    // Make sure we're throttling
    if (!lastChange && throttle) {
      setLastChange(Date.now());
    }

    // Cancel the timeout if value changes (also on delay change or unmount)
    // This is how we prevent debounced value from updating if value is
    // changed within the delay period. Timeout gets cleared and restarted.
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
};

export { useDebounce };
