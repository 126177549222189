import type { AccordionContextProps } from "./Accordion.types";
import noop from "lodash/noop";
import { createContext, useContext } from "react";

const AccordionContext = createContext<AccordionContextProps>([
  undefined,
  noop,
]);

const useAccordionContext = () => useContext(AccordionContext);

export { useAccordionContext, AccordionContext };
