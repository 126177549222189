import { useEffect, useState } from "react";

// Default clock interval timeout in milliseconds
const CLOCK_DEFAULT_TIMEOUT = 1000;

// TODO: consider replacing this by https://usehooks-ts.com/react-hook/use-interval
const useClock = (interval = CLOCK_DEFAULT_TIMEOUT) => {
  const [now, setTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date());
    }, interval);
    return () => {
      clearInterval(intervalId);
    };
  }, [interval]);
  return now;
};

export { useClock };
