import type { LegendItemProps } from "./LegendItem.types";
import React from "react";

const LegendItem = ({ icon, label }: LegendItemProps) => (
  <div className="flex flex-row items-center mb-2 last:mb-0">
    {icon}
    <span className="ml-[10px] font-sans text-neutral-shade-primary">
      {label}
    </span>
  </div>
);

export { LegendItem };
