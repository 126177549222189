import type { RenderSelectedTicketsProps } from "./RenderSelectedTickets.types";
import { MapTicketCard } from "@/components/Map/MapTicketCard";

const RenderSelectedTickets = ({
  tickets,
  classname = "",
}: RenderSelectedTicketsProps) => (
  <div className={`drop-shadow-md rounded-md bg-white w-full ${classname}`}>
    <div className="cursor-pointer w-full rounded-md max-h-56 overflow-y-auto">
      {tickets.map((ticket) => (
        <MapTicketCard
          key={ticket.id}
          ticketId={ticket.id}
          className="shadow-none drop-shadow-none border-b-2 last:border-b-0"
        />
      ))}
    </div>
  </div>
);

export { RenderSelectedTickets };
