import { useMutation } from "@tanstack/react-query";

import { StatusCodes } from "http-status-codes";
import { UrbintApi } from "@/utils/UrbintApi";
import {
  invalidateUseFlexTicketsQuery,
  invalidateUseTicketActivityQuery,
} from "@/api";

interface IUseAddCommentToTask {
  task: number;
  ticket: number;
  comment: string;
  creator: string;
}

const api = new UrbintApi();

const addCommentToTask = async (body: IUseAddCommentToTask) => {
  const val = await api.post(
    "task_comments",
    JSON.stringify({
      task: body.task,
      comment: body.comment,
      creator: body.creator,
    })
  );
  if (val.response?.status !== StatusCodes.CREATED) {
    if (val.response) {
      throw new Error(
        `Unexpected response status code: ${val.response.status}`
      );
    } else {
      throw new Error("Could not reach server.");
    }
  }
  return val;
};

const useAddCommentToTask = () =>
  useMutation(addCommentToTask, {
    onSuccess: (_, { ticket }) => {
      // Invalidate any queries that might be affected
      // by this change
      invalidateUseTicketActivityQuery(ticket);
      invalidateUseFlexTicketsQuery();
    },
  });

export { useAddCommentToTask };
