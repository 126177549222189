/* eslint-disable no-bitwise */
import type { PublicConfigSerializer } from "@/utils/damage-prevention";
import { UrbintModel } from "@/utils/UrbintModel";

enum MapLayerOptions {
  REGULATOR_STATIONS = 2 ** 0,
  VALVES = 2 ** 1,
  MAINS = 2 ** 2,
  SERVICE_LINES = 2 ** 3,
  TRANSMISSION_LINES = 2 ** 4,
  FIBER = 2 ** 5,
  ELECTRIC_TRANSMISSION = 2 ** 10,
  ELECTRIC_DISTRIBUTION = 2 ** 12,
  NETWORK = 2 ** 13,
  TELCO = 2 ** 14,
  DAMAGES = 2 ** 15,
  ANNOTATIONS = 2 ** 16,
}
// TODO: Would be really nice to auto generate this from the backend
// We need to update typomatic to do that, and the new version requires
// the dp to be configured. Skipping that for now
type DefaultScoreType = "damage" | "impact" | "due_date" | undefined;

class PublicConfig extends UrbintModel<PublicConfigSerializer> {
  get id() {
    return this.castNumber(this.data.id);
  }

  get countryCode() {
    return this.castString(this.data.country_code);
  }

  get ticketDueDateField() {
    return this.castString(this.data.ticket_due_date_field);
  }

  get fleetTracking() {
    return this.castBool(this.data.fleet_tracking);
  }

  get displayEventsTab() {
    return this.castBool(this.data.non_ticketed_events);
  }

  get displayTaskFileUpload() {
    return this.castBool(this.data.support_task_file_upload);
  }

  get timezone() {
    return this.castString(this.data.timezone);
  }

  get defaultScoreType(): DefaultScoreType {
    return this.castString(this.data.default_score_type) as DefaultScoreType;
  }

  get mapLayer() {
    const bitmask = this.castNumber(this.data.map_layers);
    return {
      regulatorStations: Boolean(bitmask & MapLayerOptions.REGULATOR_STATIONS),
      valves: Boolean(bitmask & MapLayerOptions.VALVES),
      mains: Boolean(bitmask & MapLayerOptions.MAINS),
      serviceLines: Boolean(bitmask & MapLayerOptions.SERVICE_LINES),
      transmissionLines: Boolean(bitmask & MapLayerOptions.TRANSMISSION_LINES),
      electricDistribution: Boolean(
        bitmask & MapLayerOptions.ELECTRIC_DISTRIBUTION
      ),
      electricTransmission: Boolean(
        bitmask & MapLayerOptions.ELECTRIC_TRANSMISSION
      ),
      fiber: Boolean(bitmask & MapLayerOptions.FIBER),
      network: Boolean(bitmask & MapLayerOptions.NETWORK),
      telco: Boolean(bitmask & MapLayerOptions.TELCO),
      buffers: Boolean(bitmask & MapLayerOptions.TELCO),
      damages: Boolean(bitmask & MapLayerOptions.DAMAGES),
      annotations: Boolean(bitmask & MapLayerOptions.ANNOTATIONS),
    };
  }

  get mapLayerToggle() {
    const l = this.mapLayer;
    return {
      utility:
        l.regulatorStations ||
        l.valves ||
        l.mains ||
        l.serviceLines ||
        l.transmissionLines,
      electricDistribution: l.electricDistribution,
      electricTransmission: l.electricTransmission,
      fiber: l.fiber,
      network: l.network,
      telco: l.telco,
      buffers: l.telco,
      damages: l.damages,
      annotations: l.annotations,
    };
  }

  get enableMeasuringTool() {
    return this.castBool(this.data.enable_measuring_tool);
  }

  get mapLegendLabelRegulatorStations() {
    return this.castString(this.data.map_legend_label_regulator_stations);
  }

  get mapLegendLabelValves() {
    return this.castString(this.data.map_legend_label_valves);
  }

  get mapLegendLabelMains() {
    return this.castString(this.data.map_legend_label_mains);
  }

  get mapLegendLabelServiceLines() {
    return this.castString(this.data.map_legend_label_service_lines);
  }

  get mapLegendLabelTransmissionLines() {
    return this.castString(this.data.map_legend_label_transmission_lines);
  }

  get mapLegendLabelFiberLines() {
    return this.castString(this.data.map_legend_label_fiber_lines);
  }

  get mapLegendLabelFiberLineAccess() {
    return this.castString(this.data.map_legend_label_fiber_line_access);
  }

  get mapLegendLabelDamages() {
    return this.castString(this.data.map_legend_label_damages);
  }

  get enableOfflineMode() {
    return this.castBool(this.data.enable_offline_mode);
  }

  get enableOfflinePrefetch() {
    return this.castBool(this.data.enable_offline_prefetch);
  }

  get apiRequestsMaxRetries() {
    return this.castNumber(this.data.api_requests_max_retries);
  }

  get enableImpactExplainer() {
    return this.castBool(this.data.impact_explainer_enabled);
  }

  get enableThreatExplainer() {
    return this.castBool(this.data.threat_explainer_enabled);
  }

  get enableScaledImpactScore() {
    return this.castBool(this.data.scaled_impact_score_enabled);
  }

  get enableThreatScore() {
    return this.castBool(this.data.scaled_threat_score_enabled);
  }

  get enableSelectAllInFilters() {
    return this.castBool(this.data.enable_select_all_in_filters);
  }

  compare(): number {
    throw new Error("Method not implemented.");
  }

  serialize(): PublicConfigSerializer {
    throw new Error("Method not implemented.");
  }
}

export { PublicConfig };
