import type { MouseEvent } from "react";
import type { TaskStatus } from "@/models";
import type { TaskItemProps } from "./TaskItem.types";
import { memo, useCallback } from "react";
import cx from "classnames";
import noop from "lodash/noop";
import { Icon } from "@urbint/silica";
import { TaskCardIcon } from "@/pages/TicketPage/TicketPageSideBar/TasksSection/WorkflowGroup/TaskCard/TaskCardIcon";
import { Checkbox } from "@/common/Checkbox";
import { useLinkClick } from "@/hooks";
import { Avatar } from "@/common/Avatar";
import { Tooltip } from "@/common/Tooltip";
import { formatFullDateTime } from "@/format";
import {
  RESPONSE_FORM_PREFIX,
  RESPONSE_FORM_SUBMITTED_SUFFIX,
} from "@/hooks/useResponseFormLogic";

const TaskItem = memo(
  ({
    onSelect,
    task,
    isSelectable,
    isSelected,
    ticketId,
    disabled,
  }: TaskItemProps) => {
    const displayName = task.assignee?.is_active
      ? [task.assignee?.firstName, task.assignee?.lastName]
          .filter(Boolean)
          .join(" ") || task.assignee?.email
      : "";
    let status: TaskStatus;
    if (!task.taskStep || task.taskStep?.initial) {
      status = "todo";
    } else if (task.taskStep.terminating) {
      status = "done";
    } else {
      status = "inprogress";
    }

    const linkClick = useLinkClick(`/ticket/${ticketId}?taskId=${task.id}`);

    // ---
    // Temporary fix for offline mode
    // ---
    const isSavedOffline = localStorage.getItem(
      `${RESPONSE_FORM_PREFIX}${ticketId}-${task.id}`
    );

    const isSubmmitedOffline = localStorage.getItem(
      `${RESPONSE_FORM_PREFIX}${ticketId}-${task.id}${RESPONSE_FORM_SUBMITTED_SUFFIX}`
    );

    if (isSavedOffline) {
      status = "inprogress";
    }

    if (isSubmmitedOffline) {
      status = "done";
    }
    // ---
    // Temporary fix for offline mode
    // ---

    const handleClick = useCallback(
      (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (disabled) {
          return;
        }

        if (isSelectable && onSelect) {
          onSelect(task, ticketId);
        } else {
          linkClick.handleClick(e);
        }
      },
      [disabled, isSelectable, linkClick, onSelect, task, ticketId]
    );

    const { latestComment } = task;
    const latestCommentInfo = `${latestComment?.creator.first_name} ${
      latestComment?.creator.last_name
    } commented on ${formatFullDateTime(latestComment?.created_at)}: ${
      latestComment?.comment
    }`;

    return (
      <li
        key={task.id}
        className="flex items-center text-black cursor-pointer text-md text-opacity-88"
        onClick={handleClick}
        data-testid="task-div"
      >
        {isSelectable && (
          <Checkbox className="mr-2" checked={isSelected} onChange={noop} />
        )}
        <div
          className={cx("flex items-center flex-1 p-2 border rounded-md", {
            "border-neutral-shade-background-light bg-neutral-shade-background-light":
              !isSelected,
            "border-brand-40 bg-brand-10": isSelected,
            "hover:bg-brand-10": !disabled,
          })}
        >
          <TaskCardIcon
            tooltipOrigin="left"
            status={status}
            className="ml-1 mr-2"
          />
          {task.taskType?.name || "Unknown"}
          {latestComment && (
            <div className={cx("grow", { "mr-3": displayName })}>
              <Tooltip
                theme={{
                  tooltip: "w-max max-w-[280px] line-clamp-4",
                  container:
                    "float-right text-tertiary-on-light hover:text-default-on-light",
                }}
                title={latestCommentInfo}
              >
                <Icon
                  name="message"
                  data-testid="task-item-latest-comment-icon"
                />
              </Tooltip>
            </div>
          )}
          {displayName && (
            <div className="ml-auto">
              <Avatar name={displayName} />
            </div>
          )}
        </div>
      </li>
    );
  }
);

TaskItem.displayName = "TaskItem";

export { TaskItem };
