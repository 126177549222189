import { useMutation } from "@tanstack/react-query";

import { UrbintApi } from "@/utils/UrbintApi";
import { handleApiResponse } from "@/api/helpers";
import {
  setNonTicketedEventData,
  parseNonTicketedEventDetail,
  invalidateInfiniteNonTicketedEvents,
} from "@/api";

const api = new UrbintApi();

type FormSubmission = {
  id: number;
  body: { [key: string]: any };
};

const submitNonTicketedDynamicForm = (opts: FormSubmission) =>
  api
    .post(
      "non_ticketed_events",
      JSON.stringify(opts.body),
      `${opts.id}/form/submit`
    )
    .then(handleApiResponse);

const autosaveNonTicketedDynamicForm = (opts: FormSubmission) =>
  api
    .post("non_ticketed_events", JSON.stringify(opts.body), `${opts.id}/form`)
    .then(handleApiResponse);

const useSubmitNonTicketedDynamicForm = () =>
  useMutation(submitNonTicketedDynamicForm, {
    onSuccess: (resp) => {
      setNonTicketedEventData(parseNonTicketedEventDetail(resp));
      invalidateInfiniteNonTicketedEvents();
    },
  });

const useAutosaveNonTicketedDynamicForm = () =>
  useMutation(autosaveNonTicketedDynamicForm, {
    onSuccess: (resp) => {
      setNonTicketedEventData(parseNonTicketedEventDetail(resp));
      invalidateInfiniteNonTicketedEvents();
    },
  });

export { useSubmitNonTicketedDynamicForm, useAutosaveNonTicketedDynamicForm };
