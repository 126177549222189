import type { SidebarActionButtonProps } from "./SidebarActionButton.types";

const SidebarActionButton = ({
  onClick,
  dataTestId,
  children,
}: SidebarActionButtonProps) => (
  <button
    type="button"
    onClick={onClick}
    data-testid={dataTestId}
    className="bg-blue-600 rounded flex items-center h-10 w-full justify-center font-semibold text-lg text-white"
  >
    {children}
  </button>
);

export { SidebarActionButton };
