import type { ShowServiceCenterPopupProps } from "./ServiceCenterPopUp.types";
import { Dialog } from "../Dialog";
import { ServiceCenterPopupContent } from "./ServiceCenterPopupContent";

const ServiceCenterPopUp = ({
  isVisible,
  setVisibility,
  ticket,
}: ShowServiceCenterPopupProps) => (
  <Dialog
    isOpen={isVisible}
    onClose={() => setVisibility(false)}
    header={`#${ticket.number} Service Center`}
  >
    <ServiceCenterPopupContent ticket={ticket} />
  </Dialog>
);

export { ServiceCenterPopUp };
