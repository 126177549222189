import type { TicketSelectorContextProps } from "./TicketSelector.types";
import { createContext, useContext } from "react";

const TicketSelectorContext = createContext<
  TicketSelectorContextProps | undefined
>(undefined);

const useTicketSelector = () => useContext(TicketSelectorContext)!;

export { TicketSelectorContext, useTicketSelector };
