import type { UseQueryOptions } from "@tanstack/react-query";
import type { OneCallCenterSerializer } from "@/utils/damage-prevention";
import { useQuery } from "@tanstack/react-query";
import { OneCallCenter } from "@/models";
import { UrbintApi } from "@/utils/UrbintApi";
import { handleApiResponse } from "@/api/helpers";

type IReturn = OneCallCenter[];

const api = new UrbintApi();
const oneCallCenterIdent = () => ["oneCallCenters"];

const fetchOneCallCenters = () =>
  api
    .getPage<OneCallCenterSerializer>({
      endPoint: "811_centers",
      page: 1,
    })
    .then(handleApiResponse)
    .then((res) => res.results.map((r) => new OneCallCenter(r)));

const useOneCallCenters = (opts?: UseQueryOptions<IReturn>) =>
  useQuery<IReturn>(oneCallCenterIdent(), () => fetchOneCallCenters(), opts);

export { oneCallCenterIdent, useOneCallCenters };
