import type { getServerUrlArgs } from "./types";

/**
 * Constructs a `URL` instance that points to the server.
 *
 * If the `REACT_APP_SERVER_URL` environment variable is set _and_ if its
 * value can be parsed by the `URL` constructor, it will be used as an override
 * for the protocol, hostname, port, and pathname properties. Otherwise, the
 * default url will be a sanitized version of the client app's current url.
 *
 * @example
 * ```ts
 * window.location.assign("http://example.com:1234/v1/?oops#ohnoes")
 * console.log(apiUrl({pathname: "me"}).href);
 * // http://example.com:1234/v1/me
 * ```
 *
 * Trailing `/` are also preserved:
 * ```ts
 * window.location.assign("http://example.com:1234/v1/?oops#ohnoes")
 * console.log(apiUrl({pathname: "me/"}).href);
 * // http://example.com:1234/v1/me/
 * ```
 *
 * @see https://create-react-app.dev/docs/adding-custom-environment-variables/
 */
const getServerUrl = ({ pathname }: getServerUrlArgs) => {
  // `origin` is <protocol>://<hostname>:<port> and nothing more
  const url = new URL(window.location.origin);

  try {
    const urlOverride = new URL(process.env.REACT_APP_SERVER_URL || "");
    url.protocol = urlOverride.protocol;
    url.host = urlOverride.host;
    url.port = urlOverride.port;
    url.pathname = urlOverride.pathname;
  } catch {
    // noop
  }

  const pathnameMerged = [
    ...url.pathname.split("/").filter((part) => part.length),
    ...pathname.split("/").filter((part) => part.length),
  ].join("/");

  url.pathname =
    pathname[pathname.length - 1] === "/" // preserve trailing slashes
      ? `${pathnameMerged}/`
      : pathnameMerged;

  return url;
};

export { getServerUrl };
