import type { dialogFooter } from "@/components/Dialog";
import type {
  CheckboxDetail,
  CreatePdfProps,
  TaskState,
  TicketState,
} from "./CreatePdf.types";
import type { Task } from "@/models";
import { useEffect, useState } from "react";
import classnames from "classnames";

import isEmpty from "lodash/isEmpty";

import { useTicketActivity, useTasksForTicket } from "@/api";
import { useOrganizeTicketActivities } from "@/hooks";

import { Checkbox } from "@/common/Checkbox";
import { Dialog } from "@/components/Dialog";
import { TASK_LABELS, TICKET_LABELS } from "./CreatePdf.constants";

const CreatePdf = ({ ticket, isOpen, onDidDismiss }: CreatePdfProps) => {
  const { number, id } = ticket;

  const [ticketCheckbox, setTicketCheckbox] = useState<TicketState>({
    "Ticket Information": true,
    Activity: false,
    "Task List": false,
  });

  const [taskCheckbox, setTaskCheckbox] = useState<TaskState>({
    "Response Form": false,
    Attachments: false,
  });

  const { data: tasks } = useTasksForTicket(id, { enabled: true }, false, true);
  const { data: allActivities } = useTicketActivity(id, { enabled: true });
  const { groupedActivities } = useOrganizeTicketActivities(
    allActivities || []
  );

  const hasActivities = !isEmpty(groupedActivities);
  const hasTasks = !isEmpty(tasks);
  const hasAttachments = !!tasks?.find(
    (task: Task) => task.attachmentCount > 0
  );
  const hasResponseForms = ticket?.countResponseFormAnswers > 0;

  useEffect(() => {
    setTicketCheckbox({
      "Ticket Information": true,
      Activity: hasActivities,
      "Task List": hasTasks,
    });

    setTaskCheckbox({
      "Response Form": hasResponseForms,
      Attachments: hasAttachments,
    });
  }, [hasActivities, hasTasks, hasResponseForms, hasAttachments]);

  const TICKET_DETAILS_LABELS: Array<CheckboxDetail> = [
    {
      label: TICKET_LABELS[0],
      enabled: false,
    },
    {
      label: TICKET_LABELS[1],
      enabled: hasActivities,
    },
    {
      label: TICKET_LABELS[2],
      enabled: hasTasks,
    },
  ];

  const TASK_DETAILS_LABELS: Array<CheckboxDetail> = [
    {
      label: TASK_LABELS[0],
      enabled: hasResponseForms,
    },
    {
      label: TASK_LABELS[1],
      enabled: hasAttachments,
    },
  ];

  const openInNewTab = () => {
    const currentUrlString = window.location.pathname;
    const ticketPrintBaseUrl = currentUrlString.replace(
      "ticket",
      "ticketPrint"
    );

    // Build our query and provide the selected options to the printing page
    const ticketQuery = `activity=${ticketCheckbox.Activity}&taskList=${ticketCheckbox["Task List"]}`;
    const taskQuery = `&responseForm=${taskCheckbox["Response Form"]}&attachments=${taskCheckbox.Attachments}`;
    const ticketPrintUrl = `${ticketPrintBaseUrl}/?${ticketQuery}${taskQuery}`;

    // https://www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/
    const newWindow = window.open(
      ticketPrintUrl,
      "_blank",
      "noopener,noreferrer"
    );

    // reset opener property
    if (newWindow) {
      newWindow.opener = null;
    }

    onDidDismiss();
  };

  const renderCheckBoxesOfType = (
    labels: Array<CheckboxDetail>,
    isTicket = true
  ) => {
    const title = isTicket ? "Ticket details" : "Task Details";
    const checkboxType = isTicket ? ticketCheckbox : taskCheckbox;

    return (
      <div>
        <p className="mb-2">{title}</p>
        {labels.map(({ label, enabled }) => (
          <div className="flex mb-2" key={`input-${label}`}>
            <Checkbox
              label={label}
              id={`checkbox-input-${label}`}
              dataTestId={
                isTicket ? "ticket-detail-checkbox" : "task-detail-checkbox"
              }
              className="mr-2"
              checked={checkboxType[label as keyof typeof checkboxType]}
              disabled={!enabled}
              onChange={() => {
                if (isTicket) {
                  setTicketCheckbox({
                    ...ticketCheckbox,
                    [label]:
                      !ticketCheckbox[label as keyof typeof ticketCheckbox],
                  });
                } else {
                  setTaskCheckbox({
                    ...taskCheckbox,
                    [label]: !taskCheckbox[label as keyof typeof taskCheckbox],
                  });
                }
              }}
            />
          </div>
        ))}
      </div>
    );
  };

  const modalFooter: dialogFooter = {
    toRender: "Create PDF",
    onClose: () => onDidDismiss(),
    onClick: () => openInNewTab(),
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onDidDismiss}
      header={`Create PDF for Ticket #${number}`}
      footer={modalFooter}
    >
      <p className="mb-3">
        This will create a PDF that will automatically download to your device.
      </p>
      <p>
        You can select the ticket details you’d like to include in the list
        below.
      </p>
      <div
        className={classnames(
          "flex py-6 gap-2 flex-col md:gap-10 md:flex-row md:justify-start",
          {
            "ml-0 md:ml-4": hasTasks,
            "md: ml-0": !hasTasks,
          }
        )}
      >
        {renderCheckBoxesOfType(TICKET_DETAILS_LABELS)}
        {hasTasks && renderCheckBoxesOfType(TASK_DETAILS_LABELS, false)}
      </div>
    </Dialog>
  );
};

export { CreatePdf };
