import type { MapLegendProps } from "./MapLegend.types";
import type { ScoreType } from "../Map.types";
import { memo, useEffect, useState } from "react";
import { Accordion, AccordionItem } from "@/components/Accordion";
import { riskLabel } from "@/format";
import { DropdownSelect } from "@/components/Select";
import { usePublicConfig } from "@/api";

// gas
import regTownBorder from "@/assets/images/reg_town_border.png";
import regCityGate from "@/assets/images/reg_city_gate.png";
import regDistrict from "@/assets/images/reg_district.png";
import regHpCustomer from "@/assets/images/reg_hp_customer.png";
import regLpCustomer from "@/assets/images/reg_lp_customer.png";
import regUnknown from "@/assets/images/reg_unknown.png";
import valve from "@/assets/images/valve.png";

// electric
import fuse from "@/assets/images/fuse.png";
import light from "@/assets/images/light.png";
import pedestal from "@/assets/images/pedestal.png";
import pole from "@/assets/images/pole.png";
import surfaceStructure from "@/assets/images/surfaceStructure.png";
import manhole from "@/assets/images/manhole.png";
import pullBox from "@/assets/images/pullBox.png";
import spliceBox from "@/assets/images/spliceBox.png";
import splice from "@/assets/images/splice.png";
import servicePoint from "@/assets/images/servicePoint.png";
import distributionSubstation from "@/assets/images/distributionSubstation.png";
import transmissionSubstation from "@/assets/images/transmissionSubstation.png";
import distributionVault from "@/assets/images/distributionVault.png";
import networkVault from "@/assets/images/networkVault.png";

import {
  DamagePotential,
  DueDateCategorization,
  ImpactPotential,
} from "@/models";
import { LegendItem } from "@/components/Map/MapLegend/LegendItem/LegendItem";
import { RiskIcon } from "@/components/Map/MapLegend/RiskIcon/RiskIcon";
import { SELECT_OPTIONS } from "@/components/Map/MapLegend/MapLegend.constants";
import {
  ELEC_DIST_COLORS,
  ELEC_TRANS_COLORS,
  FIBER_COLORS,
  MAINS_COLORS,
  NETWORK_COLORS,
  SERVICE_COLORS,
  TELCO_COLORS,
  TRANSMISSION_LINES_COLOR,
} from "../Layers/GeoAssetsLayer";
import { LegendTypeSelector } from "./LegendTypeSelector";
import { RectangleSVG } from "./LegendItem/RectangleSVG/RectangleSVG";
import { LegendCircleSVG } from "./LegendItem/LegendCircleSVG/LegendCircleSVG";
import { SingleLineSVG } from "./LegendItem/SingleLineSVG/SingleLineSVG";

const MapLegend = memo(
  ({ layers, scoreType, onLegendChange }: MapLegendProps) => {
    const getTicketItems = (type: ScoreType) => {
      switch (type) {
        case "damage":
          return Object.values(DamagePotential);
        case "impact":
          return Object.values(ImpactPotential);
        case "due_date":
        default:
          return Object.values(DueDateCategorization);
      }
    };

    const [ticketItems, setTicketItems] = useState(getTicketItems(scoreType));
    const { data: publicConfig, isInitialLoading } = usePublicConfig();

    useEffect(() => {
      setTicketItems(getTicketItems(scoreType));
    }, [scoreType]);

    const DASH_4_TO_1 = [
      [0, 3],
      [4, 7],
      [8, 11],
    ];
    const DASH_3_TO_1 = [
      [0, 2],
      [3, 5],
      [6, 8],
      [9, 11],
    ];
    const DASH_5_TO_3 = [
      [0, 4],
      [7, 11],
    ];

    const legendOptions = [
      {
        name: "Tickets",
        visible: true,
        layout: (
          <>
            <DropdownSelect
              value={scoreType}
              options={SELECT_OPTIONS}
              onChange={onLegendChange}
              optionsClassName="rounded-md overflow-hidden overflow-y-hidden"
              headerComponent={() => (
                <div className="w-full px-4 py-2 bg-neutral-shade-background-light text-neutral-shade-secondary text-sm font-semibold rounded-t-md">
                  Color tickets by
                </div>
              )}
              buttonComponent={LegendTypeSelector}
            />
            <div className="pl-1">
              {ticketItems.map((t) => (
                <LegendItem
                  key={`${scoreType}-${t}`}
                  icon={<RiskIcon type={t} />}
                  label={riskLabel(t)}
                />
              ))}
            </div>
          </>
        ),
      },
      {
        name:
          publicConfig?.mapLegendLabelRegulatorStations || "Regulator stations",
        visible:
          !isInitialLoading &&
          layers?.utilityAssets?.visible &&
          publicConfig?.mapLayer.regulatorStations,
        layout: (
          <>
            <LegendItem
              icon={<img src={regTownBorder} alt="" className="w-5 h-5" />}
              label="Town border"
            />
            <LegendItem
              icon={<img src={regCityGate} alt="" className="w-5 h-5" />}
              label="City gate"
            />
            <LegendItem
              icon={<img src={regDistrict} alt="" className="w-5 h-5" />}
              label="District"
            />
            <LegendItem
              icon={<img src={regHpCustomer} alt="" className="w-5 h-5" />}
              label="HP Customer"
            />
            <LegendItem
              icon={<img src={regLpCustomer} alt="" className="w-5 h-5" />}
              label="LP customer"
            />
            <LegendItem
              icon={<img src={regUnknown} alt="" className="w-5 h-5" />}
              label="Unknown"
            />
          </>
        ),
      },
      {
        name: publicConfig?.mapLegendLabelValves || "Valves",
        visible:
          !isInitialLoading &&
          layers?.utilityAssets?.visible &&
          publicConfig?.mapLayer.valves,
        layout: (
          <LegendItem
            icon={<img src={valve} alt="" className="w-5 h-5" />}
            label="Valve"
          />
        ),
      },
      {
        name: publicConfig?.mapLegendLabelMains || "Mains",
        visible:
          !isInitialLoading &&
          layers?.utilityAssets?.visible &&
          publicConfig?.mapLayer.mains,
        layout: (
          <>
            <LegendItem
              icon={<SingleLineSVG color={MAINS_COLORS.HIGH} />}
              label=">60psi"
            />
            <LegendItem
              icon={<SingleLineSVG color={MAINS_COLORS.MEDIUM} />}
              label="10-60psi"
            />
            <LegendItem
              icon={<SingleLineSVG color={MAINS_COLORS.LOW} />}
              label="<10psi"
            />
            <LegendItem
              icon={<SingleLineSVG color={MAINS_COLORS.UNKNOWN} />}
              label="Unknown pressure"
            />
          </>
        ),
      },
      {
        name: publicConfig?.mapLegendLabelServiceLines || "Service lines",
        visible:
          !isInitialLoading &&
          layers?.utilityAssets?.visible &&
          publicConfig?.mapLayer.serviceLines,
        layout: (
          <>
            <LegendItem
              icon={
                <SingleLineSVG
                  color={SERVICE_COLORS.HIGH}
                  dashes={DASH_4_TO_1}
                />
              }
              label=">60psi"
            />
            <LegendItem
              icon={
                <SingleLineSVG
                  color={SERVICE_COLORS.MEDIUM}
                  dashes={DASH_4_TO_1}
                />
              }
              label="10-60psi"
            />
            <LegendItem
              icon={
                <SingleLineSVG
                  color={SERVICE_COLORS.LOW}
                  dashes={DASH_4_TO_1}
                />
              }
              label="<10psi"
            />
            <LegendItem
              icon={
                <SingleLineSVG
                  color={SERVICE_COLORS.UNKNOWN}
                  dashes={DASH_4_TO_1}
                />
              }
              label="Unknown pressure"
            />
          </>
        ),
      },
      {
        name:
          publicConfig?.mapLegendLabelTransmissionLines || "Transmission lines",
        visible:
          !isInitialLoading &&
          layers?.utilityAssets?.visible &&
          publicConfig?.mapLayer.transmissionLines,
        layout: (
          <LegendItem
            icon={<SingleLineSVG color={TRANSMISSION_LINES_COLOR} width={2} />}
            label="Transmission lines"
          />
        ),
      },
      {
        name: "Distribution OH", // electric
        visible:
          !isInitialLoading &&
          layers?.electricDistributionAssets?.visible &&
          publicConfig?.mapLayer.electricDistribution,
        layout: (
          <>
            <LegendItem
              icon={
                <SingleLineSVG
                  color={ELEC_DIST_COLORS.OH_PRIMARY}
                  width={1}
                  dashes={DASH_3_TO_1}
                />
              }
              label="Single phase overhead"
            />
            <LegendItem
              icon={
                <SingleLineSVG
                  color={ELEC_DIST_COLORS.OH_PRIMARY}
                  width={1}
                  dashes={DASH_5_TO_3}
                />
              }
              label="Two phase overhead"
            />
            <LegendItem
              icon={
                <SingleLineSVG color={ELEC_DIST_COLORS.OH_PRIMARY} width={1} />
              }
              label="Three phase overhead"
            />
            <LegendItem
              icon={
                <SingleLineSVG
                  color={ELEC_DIST_COLORS.OH_SECONDARY}
                  width={1}
                />
              }
              label="Secondary overhead"
            />
            <LegendItem
              icon={<img src={fuse} alt="" className="w-5 h-5" />}
              label="Fuse"
            />
            <LegendItem
              icon={<img src={light} alt="" className="w-5 h-5" />}
              label="Light"
            />
            <LegendItem
              icon={<img src={pole} alt="" className="w-5 h-5" />}
              label="Pole"
            />
          </>
        ),
      },
      {
        name: "Distribution UG", // electric
        visible:
          !isInitialLoading &&
          layers?.electricDistributionAssets?.visible &&
          publicConfig?.mapLayer.electricDistribution,
        layout: (
          <>
            <LegendItem
              icon={
                <SingleLineSVG
                  color={ELEC_DIST_COLORS.UG_PRIMARY}
                  width={1}
                  dashes={DASH_3_TO_1}
                />
              }
              label="Single phase underground"
            />
            <LegendItem
              icon={
                <SingleLineSVG
                  color={ELEC_DIST_COLORS.UG_PRIMARY}
                  width={1}
                  dashes={DASH_5_TO_3}
                />
              }
              label="Two phase underground"
            />
            <LegendItem
              icon={
                <SingleLineSVG color={ELEC_DIST_COLORS.UG_PRIMARY} width={1} />
              }
              label="Three phase underground"
            />
            <LegendItem
              icon={
                <SingleLineSVG
                  color={ELEC_DIST_COLORS.UG_SECONDARY}
                  width={1}
                />
              }
              label="Secondary underground"
            />
            <LegendItem
              icon={
                <SingleLineSVG color={ELEC_DIST_COLORS.CONDUIT} width={1} />
              }
              label="Conduit"
            />
            <LegendItem
              icon={
                <SingleLineSVG
                  color={ELEC_DIST_COLORS.ABANDONED_LINE}
                  width={1}
                />
              }
              label="Abandoned line"
            />
            <LegendItem
              icon={<img src={pedestal} alt="" className="w-5 h-5" />}
              label="Pedestal"
            />
            <LegendItem
              icon={<img src={surfaceStructure} alt="" className="w-5 h-5" />}
              label="Surface structure"
            />
            <LegendItem
              icon={
                <img src={distributionSubstation} alt="" className="w-5 h-5" />
              }
              label="Substation"
            />
            <LegendItem
              icon={<img src={manhole} alt="" className="w-5 h-5" />}
              label="Manhole"
            />
            <LegendItem
              icon={<img src={pullBox} alt="" className="w-5 h-5" />}
              label="Pull box"
            />
            <LegendItem
              icon={<img src={spliceBox} alt="" className="w-5 h-5" />}
              label="Splice box"
            />
            <LegendItem
              icon={<img src={distributionVault} alt="" className="w-5 h-5" />}
              label="Vault"
            />
          </>
        ),
      },
      {
        name: publicConfig?.mapLegendLabelFiberLines || "Fiber",
        visible:
          !isInitialLoading &&
          layers?.fiberAssets?.visible &&
          publicConfig?.mapLayer.fiber,
        layout: (
          <>
            <LegendItem
              icon={<SingleLineSVG color={FIBER_COLORS.OH_CABLE} width={1} />}
              label="OH Cable"
            />
            <LegendItem
              icon={<SingleLineSVG color={FIBER_COLORS.UG_CABLE} width={1} />}
              label="UG Cable"
            />
            <LegendItem
              icon={<img src={splice} alt="" className="w-5 h-5" />}
              label="Splice"
            />
            <LegendItem
              icon={<img src={pole} alt="" className="w-5 h-5" />}
              label="Pole"
            />
            <LegendItem
              icon={
                <LegendCircleSVG
                  fill={FIBER_COLORS.ACCESS_POINT}
                  stroke={FIBER_COLORS.ACCESS_POINT_BORDER}
                  opacity={0.5}
                />
              }
              label="Access point"
            />
            <LegendItem
              icon={
                <RectangleSVG
                  fill={FIBER_COLORS.BUILDING}
                  stroke={FIBER_COLORS.BUILDING_BORDER}
                  opacity={0.5}
                />
              }
              label="Building"
            />
          </>
        ),
      },
      {
        name: "Transmission", // electric
        visible:
          !isInitialLoading &&
          layers?.electricTransmissionAssets?.visible &&
          publicConfig?.mapLayer.electricTransmission,
        layout: (
          <>
            <LegendItem
              icon={
                <SingleLineSVG
                  color={ELEC_TRANS_COLORS.COND_SPAN_OH}
                  width={2}
                />
              }
              label="Conducting span OH"
            />
            <LegendItem
              icon={
                <SingleLineSVG
                  color={ELEC_TRANS_COLORS.COND_SPAN_UG}
                  width={2}
                />
              }
              label="Conduction span UG"
            />
            <LegendItem
              icon={<img src={pole} alt="" className="w-5 h-5" />}
              label="Pole"
            />
            <LegendItem
              icon={
                <img src={transmissionSubstation} alt="" className="w-5 h-5" />
              }
              label="Substation"
            />
          </>
        ),
      },
      {
        name: "Network",
        visible:
          !isInitialLoading &&
          layers?.networkAssets?.visible &&
          publicConfig?.mapLayer.network,
        layout: (
          <>
            <LegendItem
              icon={
                <SingleLineSVG
                  color={NETWORK_COLORS.SECONDARY_SEGMENT}
                  width={1}
                />
              }
              label="Secondary segment"
            />
            <LegendItem
              icon={<SingleLineSVG color={NETWORK_COLORS.COAXIAL} width={1} />}
              label="Coaxial"
            />
            <LegendItem
              icon={
                <SingleLineSVG
                  color={NETWORK_COLORS.CIRCUIT_SEGMENT}
                  width={1}
                />
              }
              label="Circuit segment"
            />
            <LegendItem
              icon={<SingleLineSVG color={NETWORK_COLORS.DUCTBANK} width={2} />}
              label="Ductbank"
            />
            <LegendItem
              icon={<img src={servicePoint} alt="" className="w-5 h-5" />}
              label="Service point"
            />
            <LegendItem
              icon={<img src={splice} alt="" className="w-5 h-5" />}
              label="Splice"
            />
            <LegendItem
              icon={<img src={light} alt="" className="w-5 h-5" />}
              label="Lighting"
            />
            <LegendItem
              icon={<img src={networkVault} alt="" className="w-5 h-5" />}
              label="Vault"
            />
            <LegendItem
              icon={<img src={manhole} alt="" className="w-5 h-5" />}
              label="Manhole"
            />
          </>
        ),
      },
      {
        name: "Telco",
        visible:
          !isInitialLoading &&
          layers?.telcoAssets?.visible &&
          publicConfig?.mapLayer.telco,
        layout: (
          <>
            <LegendItem
              icon={<SingleLineSVG color={TELCO_COLORS.CABLE} width={1} />}
              label="Cable"
            />
            <LegendItem
              icon={<SingleLineSVG color={TELCO_COLORS.CONDUIT} width={1} />}
              label="Conduit"
            />
          </>
        ),
      },
      {
        name: "Buffers",
        visible:
          !isInitialLoading &&
          layers?.assetBuffers?.visible &&
          publicConfig?.mapLayer.buffers,
        layout: (
          <>
            <LegendItem
              icon={
                <RectangleSVG
                  fill={TELCO_COLORS.PRIORITY_1}
                  stroke={TELCO_COLORS.PRIORITY_1_BORDER}
                  opacity={0.5}
                />
              }
              label="Priority 1"
            />
            <LegendItem
              icon={
                <RectangleSVG
                  fill={TELCO_COLORS.PRIORITY_2}
                  stroke={TELCO_COLORS.PRIORITY_2_BORDER}
                  opacity={0.5}
                />
              }
              label="Priority 2"
            />
            <LegendItem
              icon={
                <RectangleSVG
                  fill={TELCO_COLORS.PRIORITY_3}
                  stroke={TELCO_COLORS.PRIORITY_3_BORDER}
                  opacity={0.5}
                />
              }
              label="Priority 3"
            />
            <LegendItem
              icon={
                <RectangleSVG
                  fill={TELCO_COLORS.PRIORITY_4}
                  stroke={TELCO_COLORS.PRIORITY_4_BORDER}
                  opacity={0.3}
                />
              }
              label="Priority 4"
            />
          </>
        ),
      },
    ];

    return (
      <div className="rounded drop-shadow-lg w-60">
        <Accordion
          className="overflow-auto max-h-[50vh] sm:max-h-[80vh]"
          openItemId="Tickets"
        >
          {legendOptions.map((key) => {
            if (!key.visible) return null;
            return (
              <AccordionItem
                key={key.name}
                itemId={key.name}
                summary={key.name}
              >
                {key.layout}
              </AccordionItem>
            );
          })}
        </Accordion>
      </div>
    );
  }
);

MapLegend.displayName = "MapLegend";

export { MapLegend };
