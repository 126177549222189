/** This file contains utilities used by the `TicketQueueLayer` component */

import type { LayerProps } from "react-map-gl";

import { ImpactPotential } from "@/models";
import { getThemeColor, riskColor } from "../../../../format";
import {
  DecisionTypeEnum,
  LookupTypeEnum,
  MapLayerIdEnum,
  MapLayerTypeEnum,
  MapSourceIdEnum,
} from "../../Map.types";

/**
 * Layer that displays highlighted markers present in any given
 * hovered field member's ticket queue.
 */
const TICKET_QUEUE_LAYER_PROPS: LayerProps = {
  id: MapLayerIdEnum.TICKET_QUEUE,
  type: MapLayerTypeEnum.CIRCLE,
  source: MapSourceIdEnum.TICKETS,
  filter: ["in", ["id"], ["literal", []]],
  paint: {
    "circle-radius": 6 * 1.2,
    "circle-stroke-width": 1,
    "circle-color": [
      DecisionTypeEnum.MATCH,
      [LookupTypeEnum.GET, "risk"],
      ImpactPotential.MISSING_DATA,
      getThemeColor(riskColor(ImpactPotential.MISSING_DATA)),
      ImpactPotential.NONE,
      getThemeColor(riskColor(ImpactPotential.NONE)),
      ImpactPotential.LOW,
      getThemeColor(riskColor(ImpactPotential.LOW)),
      ImpactPotential.MEDIUM,
      getThemeColor(riskColor(ImpactPotential.MEDIUM)),
      ImpactPotential.HIGH,
      getThemeColor(riskColor(ImpactPotential.HIGH)),
      ImpactPotential.VERY_HIGH,
      getThemeColor(riskColor(ImpactPotential.VERY_HIGH)),
      "gray",
    ],
    "circle-stroke-color": "#000",
  },
  layout: { visibility: "visible" },
};

/**
 * Generates filter expression for `TicketQueueLayer`
 * based on an array of ticket IDs.
 * @param ticketIds
 */
const queueFilter = (ticketIds: number[] | [] = []) => [
  "in",
  ["id"],
  ["literal", ticketIds],
];

export { TICKET_QUEUE_LAYER_PROPS, queueFilter };
