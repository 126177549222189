import type { TaskSerializer } from "@/utils/damage-prevention";
import { UrbintModel } from "@/utils/UrbintModel";

type TaskStatus = "todo" | "inprogress" | "done";

class Task extends UrbintModel<TaskSerializer> {
  get id() {
    return this.castNumber(this.data.id);
  }

  get sequence() {
    return this.castNumber(this.data.sequence);
  }

  get creatorId() {
    return this.castNumber(this.data.creator);
  }

  get createTime() {
    return this.castDate(this.data.create_time);
  }

  get completedTime() {
    return this.castDate(this.data.completed_time);
  }

  get assigneeId() {
    return this.data.assignee ? this.castNumber(this.data.assignee) : undefined;
  }

  get assignTime() {
    return this.castDate(this.data.assign_time);
  }

  get ticketId() {
    return this.castNumber(this.data.ticket);
  }

  get taskTypeId() {
    return this.castNumber(this.data.task_type);
  }

  get taskStepId() {
    return this.castNumber(this.data.task_step);
  }

  get memberCodeId() {
    return this.castNumber(this.data.member_code);
  }

  get attachmentCount() {
    return this.castNumber(this.data.attachment_count);
  }

  get latestComment() {
    return this.data.latest_comment;
  }

  compare(instance: Task): number {
    return this.createTime.getTime() - instance.createTime.getTime();
  }

  serialize(): TaskSerializer {
    return this.data;
  }
}

export type { TaskStatus };
export { Task };
