import type { BaseButtonProps } from "./Button.types";
import classNames from "classnames";
import { forwardRef } from "react";
import { IonSpinner } from "@ionic/react";
import { Icon } from "@urbint/silica";
import { Tooltip } from "../Tooltip";
import { VARIANT_STYLES } from "./Button.constants";

const Button = forwardRef<HTMLButtonElement, BaseButtonProps>(
  (
    {
      children,
      testId,
      className,
      iconEnd,
      iconStart,
      loading,
      disabled,
      variant = "none",
      withDisabledTooltip,
      tooltip,
      ...props
    },
    ref
  ) => (
    <>
      {tooltip && disabled ? (
        <Tooltip title={withDisabledTooltip}>
          <button
            data-testid={testId}
            type="button"
            ref={ref}
            disabled={disabled || loading}
            className={classNames(
              "flex items-center justify-center px-2.5 py-1.5 rounded max-w-full drop-shadow-sm font-sans font-semibold",
              className,
              [VARIANT_STYLES[variant]],
              {
                "cursor-not-allowed": disabled || loading,
              }
            )}
            {...props}
          >
            {iconStart && <Icon name={iconStart} className="mr-1" />}
            <div
              className={classNames("overflow-hidden w-full truncate px-1", {
                "opacity-0": loading,
                "opacity-40": disabled,
              })}
            >
              {children}
            </div>
            {loading && (
              <div className="pt-1 top-1/2 absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <IonSpinner
                  data-testid="button-loading-spinner"
                  className="h-5 w-5"
                />
              </div>
            )}
            {iconEnd && (
              <Icon data-testid="icon-end" name={iconEnd} className="ml-1" />
            )}
          </button>
        </Tooltip>
      ) : (
        <button
          data-testid={testId}
          type="button"
          ref={ref}
          disabled={disabled || loading}
          className={classNames(
            "flex items-center justify-center px-2.5 py-1.5 rounded max-w-full drop-shadow-sm font-sans font-semibold",
            className,
            [VARIANT_STYLES[variant]],
            {
              "cursor-not-allowed": disabled || loading,
            }
          )}
          {...props}
        >
          {iconStart && <Icon name={iconStart} className="mr-1" />}
          <div
            className={classNames("overflow-hidden w-full truncate px-1", {
              "opacity-0": loading,
              "opacity-40": disabled,
            })}
          >
            {children}
          </div>
          {loading && (
            <div className="pt-1 top-1/2 absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <IonSpinner
                data-testid="button-loading-spinner"
                className="h-5 w-5"
              />
            </div>
          )}
          {iconEnd && (
            <Icon data-testid="icon-end" name={iconEnd} className="ml-1" />
          )}
        </button>
      )}
    </>
  )
);

Button.displayName = "BaseButton";

export { Button };
