import type { RouteParams } from "../../TicketPageDetails.types";
import { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ComponentLabel } from "@urbint/silica";
import { Button } from "@/common/Button";
import { useTicketViewEditor } from "@/components/TicketViewEditorProvider";
import { flexFieldsForTicketCard } from "@/components/TicketCard";
import { useFlexTickets } from "@/api";

const TicketDetailsNavigation = () => {
  const { dirtyView, isInitialized, isShowingTasks } = useTicketViewEditor();
  const fields = useMemo(
    () => flexFieldsForTicketCard(isShowingTasks),
    [isShowingTasks]
  );
  const { data } = useFlexTickets(
    { ...dirtyView, fields },
    { enabled: isInitialized }
  );

  const tickets = useMemo(
    () => (data?.pages ? data?.pages.flatMap((p) => p.data) : []),
    [data?.pages]
  );

  const ids = tickets.map((ticket) => ticket.id);

  const history = useHistory();
  const { ticketId } = useParams<RouteParams>();

  const [currentIndex, setCurrentIndex] = useState(() => {
    const currentId = parseInt(ticketId, 10);
    return ids.indexOf(currentId);
  });

  useEffect(() => {
    const currentId = parseInt(ticketId, 10);
    setCurrentIndex(ids.indexOf(currentId));
  }, [ticketId, ids]);

  const handleNextTicket = () => {
    if (currentIndex < ids.length - 1) {
      const nextIndex = currentIndex + 1;
      history.push(`/ticket/${ids[nextIndex]}`);
    }
  };

  const handlePreviousTicket = () => {
    if (currentIndex > 0) {
      const prevIndex = currentIndex - 1;
      history.push(`/ticket/${ids[prevIndex]}`);
    }
  };
  return (
    <div className="flex items-center">
      <Button
        iconStart="chevron_big_up"
        onClick={handlePreviousTicket}
        disabled={currentIndex === 0 || ids.length === 0}
      >
        <ComponentLabel>Previous ticket</ComponentLabel>
      </Button>
      <Button
        iconEnd="chevron_big_down"
        onClick={handleNextTicket}
        disabled={currentIndex === ids.length - 1}
        className="rounded-none border-l border-black" // Add border-left to the next button
      >
        <ComponentLabel>Next ticket</ComponentLabel>
      </Button>
    </div>
  );
};

export { TicketDetailsNavigation };
