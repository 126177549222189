import type { PropsWithChildren } from "react";
import type { Geometry, LineString, Point, Polygon, Position } from "geojson";
import type { Offset } from "mapbox-gl";

type MapPageMapProps = PropsWithChildren<unknown>;

type TicketsToShowProps = {
  tickets: Array<{
    id: number;
  }>;
  position: Position;
  offset?: Offset;
};

const isPoint = (geometry: Geometry): geometry is Point =>
  geometry.type === "Point" &&
  Array.isArray(geometry.coordinates) &&
  geometry.coordinates.length === 2 &&
  typeof geometry.coordinates[0] === "number";

const isLineString = (geometry: Geometry): geometry is LineString =>
  geometry.type === "LineString" &&
  Array.isArray(geometry.coordinates) &&
  geometry.coordinates.every(
    (coord) =>
      Array.isArray(coord) && coord.length === 2 && typeof coord[0] === "number"
  );

const isPolygon = (geometry: Geometry): geometry is Polygon =>
  geometry.type === "Polygon" &&
  Array.isArray(geometry.coordinates) &&
  geometry.coordinates.every(
    (ring) =>
      Array.isArray(ring) &&
      ring.every(
        (coord) =>
          Array.isArray(coord) &&
          coord.length === 2 &&
          typeof coord[0] === "number"
      )
  );

const hasCoordinates = (
  geometry: Geometry
): geometry is Point | LineString | Polygon =>
  isPoint(geometry) || isLineString(geometry) || isPolygon(geometry);

export type { MapPageMapProps, TicketsToShowProps };
export { isPoint, isLineString, isPolygon, hasCoordinates };
