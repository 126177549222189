import type { UseQueryOptions } from "@tanstack/react-query";
import type { PositiveResponseSerializer } from "@/utils/damage-prevention";
import { useQuery } from "@tanstack/react-query";
import { PositiveResponse } from "@/models";
import { UrbintApi } from "@/utils/UrbintApi";
import { queryClient } from "@/api/client";
import { handleApiResponse } from "@/api/helpers";

const api = new UrbintApi();

type IReturn = PositiveResponse[];

const fetchPositiveResponses = (ticketId: number) =>
  api
    .getPage<PositiveResponseSerializer>({
      endPoint: "positive_responses",
      page: 1,
      queryParams: { ticket: String(ticketId) },
    })
    .then(handleApiResponse)
    .then((res) => res.results?.map((r) => new PositiveResponse(r)));

const ticketPositiveResponsesIdent = (ticketId: number) => [
  "ticket/positive_responses",
  ticketId,
];

const usePositiveResponsesForTicket = (
  ticketId: number,
  opts?: UseQueryOptions<IReturn>
) =>
  useQuery<IReturn>(
    ticketPositiveResponsesIdent(ticketId),
    () => fetchPositiveResponses(ticketId),
    opts
  );

const invalidateUsePositiveResponsesForTicketQuery = (ticketId: number) =>
  queryClient.invalidateQueries(ticketPositiveResponsesIdent(ticketId));

export {
  ticketPositiveResponsesIdent,
  usePositiveResponsesForTicket,
  invalidateUsePositiveResponsesForTicketQuery,
};
