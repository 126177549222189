import type { SelectInputWrapperProps } from "./SelectInputWrapper.types";
import { Combobox } from "@headlessui/react";
import { Icon } from "@urbint/silica";
import { useState } from "react";
import { browserScrollToId } from "@/utils";
import { useTypeahead } from "../TypeheadSelectProvider";
import { SelectedOptions } from "./SelectedOptions";
import { RightSideElements } from "./RightSideElements";

const SelectInputWrapper = ({
  type,
  testId,
  inputPlaceholder,
  selectedOptions,
  setSelectedOptions,
  setOptionFilter,
}: SelectInputWrapperProps) => {
  const { searchIconId } = useTypeahead();
  const [enableInput, setEnableInput] = useState(false);

  const handleSelect = (value: string) => {
    setOptionFilter(value);
    setEnableInput(true);
  };

  const handleNavigateToSearchIcon = () => {
    if (selectedOptions.length) {
      browserScrollToId(`${type}-${searchIconId}`);
    }
  };

  return (
    <Combobox.Button
      className="relative group"
      as="div"
      data-testid={testId}
      onClick={() => handleSelect("")}
      onFocus={() => handleNavigateToSearchIcon()}
    >
      <div className="gap-2 flex flex-wrap w-full py-1 pl-2 pr-4 max-h-30 overflow-auto rounded border group-hover:border-black">
        <SelectedOptions
          options={selectedOptions}
          setOptions={setSelectedOptions}
        />
        {!enableInput && !selectedOptions.length && (
          <div className="min-h-[1.75rem]" />
        )}
        {enableInput && (
          <div className="flex w-full">
            <Icon
              id={`${type}-${searchIconId}`}
              name="search"
              className="text-2xl mr-1"
            />
            <Combobox.Input
              onChange={(event) => handleSelect(event.target.value)}
              className="rounded min-h-[1.75rem] flex-grow focus:outline-none"
              placeholder={inputPlaceholder}
            />
          </div>
        )}
      </div>
      <RightSideElements
        shouldRenderClearAllOptions={selectedOptions.length > 1}
        setOptions={setSelectedOptions}
      />
    </Combobox.Button>
  );
};

export { SelectInputWrapper };
