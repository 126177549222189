import type { GalleryNavigationProps } from "./GalleryNavigation.types";
import { useEffect } from "react";
import { useSwiper } from "swiper/react";
import { useResponsive } from "@/hooks";
import { PreviousButton } from "./PreviousButton/PreviousButton";
import { NextButton } from "./NextButton/NextButton";

const GalleryNavigation = ({ slideIndex }: GalleryNavigationProps) => {
  const swiper = useSwiper();
  const { isMd } = useResponsive();

  useEffect(() => {
    swiper.slideTo(slideIndex);
  }, [slideIndex, swiper]);

  return (
    <>
      {isMd && (
        <>
          <PreviousButton
            isDisabled={swiper.isBeginning}
            onClick={() => swiper.slidePrev()}
          />
          <NextButton
            isDisabled={swiper.isEnd}
            onClick={() => swiper.slideNext()}
          />
        </>
      )}
    </>
  );
};

export { GalleryNavigation };
