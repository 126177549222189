import { BodyText } from "@urbint/silica";
import { TaskDetailSection } from "@/pages/TicketPage/TaskDetail/TaskDetailSection";
import { TaskDetailSectionHeader } from "@/pages/TicketPage/TaskDetail/TaskDetailSectionHeader";

const CommentsAndActivitySectionOffline = () => (
  <TaskDetailSection>
    <TaskDetailSectionHeader>Comments and activity</TaskDetailSectionHeader>
    <BodyText>Task activity is not supported when offline.</BodyText>
  </TaskDetailSection>
);

export { CommentsAndActivitySectionOffline };
