import { StatusCodes } from "http-status-codes";
import { UrbintApi } from "@/utils/UrbintApi";

const api = new UrbintApi();

interface IUploadAttachment {
  endpoint: string;
  modelId: number; // Non Ticketed Event Id | Task Id
  attachments: File[];
  request: XMLHttpRequest;
}

// TODO: This needs a refactor
// Ticket for it: https://urbint.atlassian.net/browse/DPAPP-2421
const uploadAttachment = (
  { attachments, endpoint, modelId, request }: IUploadAttachment,
  updateProgress: (v: number) => void
) =>
  new Promise((resolve, reject) => {
    const formData = new FormData();

    attachments.forEach((attachment) =>
      formData.append("attachment", attachment, attachment.name)
    );

    const fetchOpts = api.requestInit();

    const headers: Headers = new Headers({
      ...fetchOpts.headers,
      "X-Requested-With": "XMLHttpRequest",
    });
    headers.delete("Content-Type");

    request.open("POST", `/api/${endpoint}/${modelId}/attachments/`);
    request.withCredentials = true;

    for (const [key, val] of headers.entries()) {
      request.setRequestHeader(key, val);
    }

    request.upload.addEventListener("progress", (e) => {
      updateProgress(e.loaded / e.total);
    });

    request.addEventListener("load", function loadEventListener() {
      updateProgress(-1);
      if (
        this.status === 0 ||
        (this.status >= StatusCodes.OK && this.status < StatusCodes.BAD_REQUEST)
      ) {
        resolve(true);
      } else {
        let msg = this.statusText;
        try {
          msg = JSON.parse(this.response).message;
        } catch (_) {
          // noop
        }
        reject(
          new Error(`${this.status} - ${msg || "Error uploading attachment"}`)
        );
      }
    });

    request.addEventListener("error", () => {
      updateProgress(-1);
      reject(new Error("Error uploading attachment. Network error"));
    });

    request.send(formData);
  });

export { uploadAttachment };
