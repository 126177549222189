const TicketListCardPlaceholder = () => {
  const placeholderClasses = "h-5 rounded animate-pulse block";
  const darkGray = "bg-gray-300";
  const lightGray = "bg-gray-200";
  return (
    <div className="h-[119px] p-3 border-b flex flex-col justify-between bg-white">
      <div className="flex">
        <span className={`w-48 ${placeholderClasses} ${darkGray}`} />
        <span
          className={`
              ${placeholderClasses},
              "w-16",
              ${lightGray},
              "ml-auto"
            `}
        />
      </div>
      <div className="flex">
        <span className={`w-32 ${placeholderClasses} ${lightGray}`} />
        <span
          className={`
              ${placeholderClasses},
              "w-32",
              ${lightGray},
              "ml-auto"
            `}
        />
      </div>
      <div className="flex">
        <span className={`w-32 ${placeholderClasses} ${lightGray}`} />
      </div>
      <div className="flex">
        <span className={`w-64 ${placeholderClasses} ${lightGray}`} />
      </div>
    </div>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { TicketListCardPlaceholder };
