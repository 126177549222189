import type { TicketQueueItemSerializer } from "@/utils/damage-prevention";
import { UrbintModel } from "@/utils/UrbintModel";

/** Possible statuses a ticket may be in while in a user's ticket queue */
enum QueuedTicketStatus {
  QUEUED = "queued",
  VISITED = "visited",
}

class TicketQueueItem extends UrbintModel<TicketQueueItemSerializer> {
  get id() {
    return this.castNumber(this.data.id);
  }

  get createdOn() {
    return this.castDate(this.data.created_on);
  }

  get updatedOn() {
    return this.castDate(this.data.updated_on);
  }

  get sequence() {
    return this.castNumber(this.data.sequence);
  }

  get isVisited() {
    return this.castBool(this.data.is_visited);
  }

  get ticketQueue() {
    throw new Error("Method not implemented.");
  }

  get ticketId() {
    return this.castNumber(this.data.ticket);
  }

  compare(instance: TicketQueueItem): number {
    return this.sequence - instance.sequence;
  }

  serialize(): TicketQueueItemSerializer {
    throw new Error("Method not implemented.");
  }
}

export { QueuedTicketStatus, TicketQueueItem };
