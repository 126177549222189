import type { TicketVersionSerializer } from "@/utils/damage-prevention";
import { parseString } from "xml2js";
import xmlFormatter from "xml-formatter";
import { UrbintModel } from "@/utils/UrbintModel";
import { Contact } from "./Contact";

enum DueDateCategorization {
  PAST_DUE = "PAST_DUE",
  TODAY = "TODAY",
  TOMORROW = "TOMORROW",
  AFTER_TOMORROW = "AFTER_TOMORROW",
  UNKNOWN = "UNKNOWN",
}

enum DamagePotential {
  VERY_HIGH = "VERY_HIGH",
  HIGH = "HIGH",
  MEDIUM = "MEDIUM",
  LOW = "LOW",
  NONE = "NONE",
  MISSING_DATA = "MISSING_DATA",
}

enum ImpactPotential {
  VERY_HIGH = "VERY_HIGH",
  HIGH = "HIGH",
  MEDIUM = "MEDIUM",
  LOW = "LOW",
  NONE = "NONE",
  MISSING_DATA = "MISSING_DATA",
}

class TicketVersion extends UrbintModel<TicketVersionSerializer> {
  get id() {
    return this.castNumber(this.data.id);
  }

  get version() {
    return this.castString(this.data.version);
  }

  get sequence() {
    return this.castString(this.data.sequence);
  }

  get createdOn() {
    return this.castDate(this.data.created_on);
  }

  get updatedOn() {
    return this.castDate(this.data.updated_on);
  }

  get raw() {
    if (this.data.raw?.encoding === "json") {
      try {
        return JSON.stringify(JSON.parse(this.data.raw?.text), null, "  ");
      } catch (e) {
        return this.data.raw?.text;
      }
    } else if (this.data.raw?.encoding === "xml") {
      try {
        let ticketText;

        // NOTE: this is a hardcoded special case for Georgia Power
        parseString(this.data.raw?.text, (err, result) => {
          if (err === null) {
            ticketText = result?.Ticket?.TicketText;
          }
        });

        if (!ticketText) {
          ticketText = xmlFormatter(this.data.raw?.text, {
            collapseContent: true,
          });
        }

        return ticketText;
      } catch (e) {
        return this.data.raw?.text;
      }
    } else {
      return this.data.raw?.text;
    }
  }

  get extracted() {
    // TODO: Is this meant to be stringified?
    // In the tests we're using a string
    return JSON.stringify(this.data.extracted, null, "  ");
  }

  get description() {
    return this.castString(this.data.description);
  }

  get type() {
    return this.data.type;
  }

  get category() {
    return this.castString(this.data.category);
  }

  get contacts() {
    return this.data.contacts
      ? this.data.contacts.map((contact) => new Contact(contact))
      : [];
  }

  get primaryContact() {
    return this.contacts.find((contact) => contact.primary);
  }

  get altContact() {
    return this.contacts.find((contact) => !contact.primary);
  }

  get excavationState() {
    return this.data.excavation_state;
  }

  get excavationCounty() {
    return this.castString(this.data.excavation_county);
  }

  get excavationCity() {
    return this.data.excavation_city;
  }

  get excavationStreetName() {
    return this.data.excavation_street_name;
  }

  get excavationStreetNumber() {
    return this.data.excavation_street_number;
  }

  get excavationWorkType() {
    return this.data.excavation_work_type;
  }

  get equipmentType() {
    return this.castString(this.data.equipment_type);
  }

  get excavationAddress() {
    return this.data.excavation_address;
  }

  get crossStreet1() {
    return this.castString(this.data.cross_street_1);
  }

  get crossStreet2() {
    return this.castString(this.data.cross_street_2);
  }

  get expirationDate() {
    return (
      this.data.expiration_date && this.castDate(this.data.expiration_date)
    );
  }

  get damagePotential(): DamagePotential {
    if (this.data.damage_potential === "None") return DamagePotential.NONE;
    return this.data.damage_potential as DamagePotential;
  }

  get impactPotential(): ImpactPotential {
    if (this.data.impact_potential === "None") return ImpactPotential.NONE;
    return this.data.impact_potential as ImpactPotential;
  }

  get callerPhoneNumber() {
    return this.data.caller_phone_number;
  }

  get callerName() {
    return this.data.caller_name;
  }

  get callerEmailAddress() {
    return this.castString(this.data.caller_email_address);
  }

  get workStartDate() {
    return (
      (this.data.work_start_date && this.castDate(this.data.work_start_date)) ||
      undefined
    );
  }

  get workDoneFor() {
    return this.data.work_done_for;
  }

  get reportedDate() {
    return (
      (this.data.ticket_reported_date &&
        this.castDate(this.data.ticket_reported_date)) ||
      undefined
    );
  }

  get responseDueDate() {
    return (
      (this.data.response_due_date &&
        this.castDate(this.data.response_due_date)) ||
      undefined
    );
  }

  get ticketTransmissionTime() {
    return (
      this.data.ticket_transmission_time &&
      this.castDate(this.data.ticket_transmission_time)
    );
  }

  get locateArea() {
    return this.data.locate_area;
  }

  get locateStatus() {
    return this.data.locate_status;
  }

  get locateRemarks() {
    return this.data.locate_remarks;
  }

  get locateUpdated() {
    return this.data.locate_updated && this.castDate(this.data.locate_updated);
  }

  get serviceAreaMapURL() {
    return this.castString(this.data.service_area_map_url);
  }

  get meetingDetails() {
    return this.castString(this.data.meeting_details);
  }

  get meetingDate() {
    return (
      (this.data.meeting_date && this.castDate(this.data.meeting_date)) ||
      undefined
    );
  }

  get scopeOfWork() {
    return this.castString(this.data.scope_of_work);
  }

  get tlcReceivedAt() {
    return (
      (this.data.tlc_received_at && this.castDate(this.data.tlc_received_at)) ||
      undefined
    );
  }

  get scaledImpactScore() {
    return this.castNumber(this.data.scaled_impact_score);
  }

  get scaledThreatScore() {
    return this.castNumber(this.data.scaled_threat_score);
  }

  compare(instance: TicketVersion) {
    return this.id - instance.id;
  }

  serialize(): TicketVersionSerializer {
    return this.data;
  }
}

export {
  DueDateCategorization,
  DamagePotential,
  ImpactPotential,
  TicketVersion,
};
