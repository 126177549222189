import { useEffect, useRef, useState } from "react";
import { useWindowSize } from "react-use";
import { useDebounce } from "@/hooks";

const useDimensions = () => {
  const targetRef = useRef<HTMLDivElement | null>(null);
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
    left: 0,
    right: 0,
  });
  const { width: windowWidth } = useWindowSize();
  const debouncedWindowWidth = useDebounce(windowWidth, {
    delay: 200,
    throttle: true,
  });

  useEffect(() => {
    if (targetRef.current) {
      setDimensions({
        left: targetRef.current.offsetLeft,
        right: targetRef.current.offsetLeft + targetRef.current.offsetWidth,
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight,
      });
    }
  }, [
    debouncedWindowWidth,
    targetRef.current?.offsetLeft,
    targetRef.current?.offsetWidth,
  ]);

  return [targetRef, dimensions] as [typeof targetRef, typeof dimensions];
};

export { useDimensions };
