import type { UseQueryOptions } from "@tanstack/react-query";
import type { ActivitySerializer } from "@/utils/damage-prevention";
import { useQuery } from "@tanstack/react-query";
import { TicketActivity } from "@/models";
import { UrbintApi } from "@/utils/UrbintApi";
import { queryClient } from "@/api/client";
import { handleApiResponse } from "@/api/helpers";

const api = new UrbintApi();

const ticketActivityIdent = (ticketId: number) => ["ticket/activity", ticketId];

const fetchTicketActivity = (ticketId: number) =>
  api
    .getMany<ActivitySerializer>({
      endPoint: "ticket_activities",
      queryParams: {
        object_id: ticketId.toString(),
      },
    })
    .then(handleApiResponse)
    .then((res) => res.map((r) => new TicketActivity(r)));

const removeUseTicketActivityQuery = (ticketId: number) => {
  queryClient.removeQueries(ticketActivityIdent(ticketId));
};

const useTicketActivity = (
  ticketId: number,
  opts?: UseQueryOptions<TicketActivity[]>
) =>
  useQuery<TicketActivity[]>(
    ticketActivityIdent(ticketId),
    () => fetchTicketActivity(ticketId),
    opts
  );

const prefetchTicketActivity = (ticketId: number) => {
  queryClient.prefetchQuery(ticketActivityIdent(ticketId), () =>
    fetchTicketActivity(ticketId)
  );
};

const invalidateUseTicketActivityQuery = (ticketId: number) =>
  queryClient.invalidateQueries(ticketActivityIdent(ticketId));

export {
  useTicketActivity,
  ticketActivityIdent,
  removeUseTicketActivityQuery,
  invalidateUseTicketActivityQuery,
  prefetchTicketActivity,
};
