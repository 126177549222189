import type { DetailProps } from "../DetailsSectionSplitContent/Detail";
import type { DigLocationDetailsSectionProps } from "./DigLocationDetailsSection.types";
import { addCommaInArrayValues, startCase } from "@/format";
import { useServiceCenters } from "@/api";
import { DetailsSection } from "../DetailsSection";
import { DetailsSectionSplitContent } from "../DetailsSectionSplitContent";

const DigLocationDetailsSection = ({
  ticket,
}: DigLocationDetailsSectionProps) => {
  const { latestVersion, serviceCenters = [] } = ticket || {};
  const streetName = startCase(latestVersion?.excavationStreetName);
  const streetNum = latestVersion?.excavationStreetNumber;
  const address = [streetNum, streetName].filter(Boolean).join(" ");

  // Single Service Center Value
  const { data: serviceCenter = [] } = useServiceCenters();

  // Multiple Service Centers Values
  const serviceCenterNames = serviceCenters.map(
    (serviceCenterName: { name: string }) => serviceCenterName.name
  );

  let serviceCenterValue = addCommaInArrayValues(serviceCenterNames);

  if (serviceCenterNames.length === 0) {
    const serviceCenterName =
      serviceCenter.find((sc) => sc.id === ticket?.serviceCenterId)?.name ?? "";
    serviceCenterValue = startCase(serviceCenterName);
  }

  const leftFields: DetailProps[] = [
    {
      label: "Service Center",
      value: serviceCenterValue,
    },
    {
      label: "County",
      value: startCase(latestVersion?.excavationCounty),
    },
    {
      label: "Cross Street 1",
      value: startCase(latestVersion?.crossStreet1),
    },
    {
      label: "Cross Street 2",
      value: startCase(latestVersion?.crossStreet2),
    },
  ];
  const rightFields: DetailProps[] = [
    { label: "Address", value: address, layout: "vertical" },
  ];
  return (
    <DetailsSection title="Dig Location">
      <DetailsSectionSplitContent left={leftFields} right={rightFields} />
    </DetailsSection>
  );
};

export { DigLocationDetailsSection };
