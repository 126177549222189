import type { RiskBadgeProps } from "./RiskBadge.types";
import { UrbintBadge as Badge } from "@urbint/silica";
import { riskLabel } from "@/format";
import { getBadgeColorAndIcon } from "./RiskBadge.utils";

const RiskBadge = ({ className, value, type = "negative" }: RiskBadgeProps) => {
  const { color, icon } = getBadgeColorAndIcon(value);
  return (
    <Badge
      type={type}
      iconName={icon}
      color={color as any} // ugly, but BadgeColors is not exported by Silica
      className={className}
      text={riskLabel(value).toUpperCase() || " "}
    />
  );
};

export { RiskBadge };
