import type { HeaderProps } from "./Header.types";
import { useCallback } from "react";
import {
  useAssignableUsersForTasks,
  useChangeTaskAssignee,
  useMemberCode,
  useTaskType,
  useUser,
} from "@/api";
import { useToasts } from "@/components/Toasts";
import { useDamagePreventionAuth, useOnlineStatus } from "@/hooks";
import { UserSelect } from "@/components/Select";
import { Avatar } from "@/common/Avatar";
import { TopHeader } from "./TopHeader";

const Header = ({ task, onClose }: HeaderProps) => {
  // TODO: Fix no-non-null-asserted-optional-chain
  const { data: taskType } = useTaskType(task?.taskTypeId!, {
    enabled: task !== undefined,
  });
  // TODO: Fix no-non-null-asserted-optional-chain
  const { data: assignee } = useUser(task?.assigneeId!, {
    enabled: task !== undefined,
  });
  // TODO: Fix no-non-null-asserted-optional-chain
  const { data: memberCode } = useMemberCode(task?.memberCodeId!, {
    enabled: task !== undefined,
  });

  const assignableUsers = useAssignableUsersForTasks(task ? [task] : undefined);
  const changeTaskAssignee = useChangeTaskAssignee();
  const { addToast } = useToasts();
  const setTaskAssignee = useCallback(
    async (userId?: number) => {
      try {
        await changeTaskAssignee.mutateAsync({
          task: task!,
          assignee: userId,
        });
        const msg = userId ? "Task assignee updated" : "Task assignee removed";
        addToast(msg, "success");
      } catch (e) {
        if (e instanceof Error) {
          addToast(`Error updating assignee: ${e.message}`, "error");
        } else {
          addToast(`Error updating assignee`, "error");
        }
      }
    },
    [changeTaskAssignee, task, addToast]
  );
  const { currentUser } = useDamagePreventionAuth();
  const isOnline = useOnlineStatus();
  return (
    <header className="bg-gray-10 py-3 px-4 shadow-10 z-10 relative">
      <TopHeader task={task} closeTaskSidebar={onClose} />
      <div className="flex items-center">
        <div className="text-neutral-shade-primary font-semibold text-2xl flex-1">
          {taskType?.name}
        </div>
        <div className="ml-1">
          <UserSelect
            unAssignedUser={false}
            currentUser={currentUser}
            disabled={
              changeTaskAssignee.isLoading ||
              !currentUser.canAssignTasks ||
              currentUser.canOnlyViewTicketsWithOwnedTasks ||
              !isOnline
            }
            options={assignableUsers}
            onChange={setTaskAssignee}
            value={assignee?.id}
            dropdownClassName="min-w-[250px] right-0"
            buttonComponent={({ ref }) => (
              <div ref={ref}>
                <Avatar
                  name={assignee?.displayName || ""}
                  tooltipPlaceholder={assignee?.displayName || ""}
                  tooltipOrigin="right"
                  size="md"
                />
              </div>
            )}
          />
        </div>
      </div>
      <p className="mt-1.5 text-sm leading-tight text-neutral-shade-secondary">
        {memberCode?.key}
      </p>
    </header>
  );
};

export { Header };
