import type { Ticket } from "@/models";
import { useMemo } from "react";

type PositiveResponseData = {
  latestResponseAt?: Date;
  positiveResponses: {
    memberCode: string;
    memberCodeLabel: string;
    positiveResponseCode: string;
    positiveResponseCodeLabel: string;
  }[];
};

const usePositiveResponseData = ({
  memberCodes,
  positiveResponses: ticketResponses,
}: Ticket): PositiveResponseData => {
  // First result is always the last positive response
  // https://urbint.atlassian.net/l/cp/g91Vrrm1
  const latestResponseAt = ticketResponses
    ? ticketResponses[0]?.transmitTime
    : undefined;

  const positiveResponses = useMemo(() => {
    if (!(memberCodes && ticketResponses)) {
      return [];
    }

    return memberCodes?.map((memberCode) => {
      // Find the memberCode of the positive response
      const positiveResponseOfMemberCode = ticketResponses?.find(
        (ticketResponse) => ticketResponse.memberCode === memberCode.id
      );

      return {
        memberCode: memberCode?.key ?? "0",
        memberCodeLabel: "",
        positiveResponseCode:
          positiveResponseOfMemberCode?.code ?? "No Response",
        positiveResponseCodeLabel: positiveResponseOfMemberCode?.label ?? "",
      };
    });
  }, [memberCodes, ticketResponses]);

  return {
    latestResponseAt,
    positiveResponses,
  };
};

export { usePositiveResponseData };
