import type { SelectOption } from "@/components/Select";
import type { SelectedOptionsProps } from "./SelectedOptions.types";
import { Icon } from "@urbint/silica";

const SelectedOptions = ({ options, setOptions }: SelectedOptionsProps) => (
  <>
    {options.length > 0 &&
      options.map((option: SelectOption<string>) => (
        <div
          role="button"
          className="rounded-xl inline-flex items-center bg-gray-200 px-2 py-1 whitespace-nowrap max-w-[85%]"
          key={option.label}
        >
          <span className="truncate max-w-[265px]">{option.label}</span>
          <Icon
            name="off_outline_close"
            className="ml-2"
            onClick={(e) => {
              e.stopPropagation();
              setOptions(
                options.filter(
                  (filterOption: SelectOption<string>) =>
                    filterOption.label !== option.label
                )
              );
            }}
          />
        </div>
      ))}
  </>
);

export { SelectedOptions };
