import type { User } from "@/models";
import type { TaskModalProps } from "./TaskModal.types";
import type { dialogFooter } from "@/components/Dialog";
import { useEffect } from "react";
import { DropdownSelect, UserSelect } from "@/components/Select";
import { useDamagePreventionAuth } from "@/hooks";
import { Dialog } from "@/components/Dialog";

const TaskModal = ({
  children,
  isOpen,
  onClose,
  title,
  value,
  onChange,
  onSubmit,
  actionTitle,
  isLoading,
  isValid,
  afterClose,
  assigneeOptions,
  assigneeLabel = "Task Assignee",
  taskTypeOptions,
  memberCodeOptions,
  withDisabledTooltip,
  toolTip,
  unAssignedUser = true,
}: TaskModalProps) => {
  const { currentUser } = useDamagePreventionAuth();
  useEffect(() => {
    const matchingUser =
      value.assigneeId &&
      assigneeOptions?.find((u: User) => u.id === value.assigneeId);
    // clear the assigneeId if not found in the assignees
    if (value.assigneeId && !matchingUser)
      onChange({ ...value, assigneeId: undefined });
  }, [onChange, value, assigneeOptions]);

  const modalFooter: dialogFooter = {
    toRender: actionTitle,
    onClose: () => !isLoading && onClose(),
    cancelButtonDisabled: isLoading,
    submitButtonTestId: "modal-action-button",
    submitButtonDisabled: !isValid,
    submitButtonIsLoading: isLoading,
    onClick: onSubmit,
    withDisabledTooltip,
    tooltip: toolTip,
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      afterClose={afterClose}
      header={title}
      footer={modalFooter}
      // Passing "!overflow-visible" to be possible to render dropdowns outside the modal.
      theme={{ content: "!overflow-visible" }}
    >
      <>
        {children}
        <div className="mt-2 space-y-3">
          {taskTypeOptions && taskTypeOptions.length !== 0 && (
            <DropdownSelect
              testId="task-modal-task-type-select"
              options={taskTypeOptions}
              onChange={(taskTypeId) => onChange({ ...value, taskTypeId })}
              value={value.taskTypeId}
              label="Task Type"
              placeholder="Select task type"
              className="border border-solid rounded-md border-neutral-shade-border-light"
            />
          )}
          {assigneeOptions && assigneeOptions.length !== 0 && (
            <UserSelect
              currentUser={currentUser}
              options={assigneeOptions}
              onChange={(assigneeId) => onChange({ ...value, assigneeId })}
              value={value.assigneeId}
              buttonLabel={assigneeLabel}
              unAssignedUser={unAssignedUser}
            />
          )}
          {memberCodeOptions && memberCodeOptions.length !== 0 && (
            <DropdownSelect
              options={memberCodeOptions}
              onChange={(memberCodeId) => onChange({ ...value, memberCodeId })}
              value={value.memberCodeId}
              placeholder="None"
              label="Member code"
              className="border border-solid rounded-md border-neutral-shade-border-light"
            />
          )}
        </div>
      </>
    </Dialog>
  );
};

export { TaskModal };
