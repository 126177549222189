import type { LngLatType, MapFeature } from "../../Map.types";
import type { TicketClusterProps, TicketProps } from "./ClusterLayer.types";
import type { MapboxGeoJSONFeature, MapRef } from "react-map-gl";
import { datadogRum } from "@datadog/browser-rum";

const isTicketCluster = (
  feature:
    | MapFeature<TicketClusterProps>
    | MapFeature<TicketProps>
    | MapboxGeoJSONFeature
): feature is MapFeature<TicketClusterProps> =>
  (feature as MapFeature<TicketClusterProps>).properties.cluster;
const hoverMultiplier = (value: number): mapboxgl.Expression => [
  "case",
  ["boolean", ["feature-state", "hovered"], false],
  value * 1.2,
  value,
];
const hoverClusterMultiplier = (value: number): mapboxgl.Expression => [
  "case",
  [">", ["get", "hovered"], 0],
  value * 1.2,
  value,
];

const calculateClusterBoundaries = (coordinates: LngLatType[]) => {
  if (!coordinates.length) return undefined;

  // Calculate min and max lng/lat

  const [minLongitude, minLatitude, maxLongitude, maxLatitude] =
    coordinates.reduce(
      ([minLngAcc, minLatAcc, maxLngAcc, maxLatAcc], [lng, lat]) => [
        Math.min(minLngAcc, lng),
        Math.min(minLatAcc, lat),
        Math.max(maxLngAcc, lng),
        Math.max(maxLatAcc, lat),
      ],
      [Infinity, Infinity, -Infinity, -Infinity]
    );

  return [minLongitude, minLatitude, maxLongitude, maxLatitude] as [
    number,
    number,
    number,
    number
  ];
};

const flyAndCenterClusters = (
  coords: LngLatType[],
  isSm: boolean,
  mapContext: MapRef
) => {
  const padding = isSm
    ? { top: 50, right: 50, left: 350, bottom: 50 }
    : { top: 50, right: 40, left: 40, bottom: 50 };

  const boundsBox = calculateClusterBoundaries(coords);
  if (!boundsBox) {
    mapContext.zoomOut({ offset: [80, 60] });
    return;
  }

  try {
    mapContext.fitBounds(boundsBox, {
      padding,
      speed: 1.5,
    });
  } catch (error) {
    datadogRum.addError(error, {
      errorIdentifier: "MAP_FITBOUNDS",
    });
  }
};

export {
  isTicketCluster,
  hoverMultiplier,
  hoverClusterMultiplier,
  flyAndCenterClusters,
  calculateClusterBoundaries,
};
