import type { ResponseForm, FlexTask } from "@/models";
import type { Question } from "@/api";
import { useMutation } from "@tanstack/react-query";
import { UrbintApi } from "@/utils/UrbintApi";
import {
  invalidateUseFlexTicketsQuery,
  invalidateUseFlexTicketQuery,
  invalidateUseTicketActivityQuery,
  invalidateUsePositiveResponsesForTicketQuery,
  invalidateUseTasksForTicketQuery,
  invalidateUseAnswerSetQuery,
  invalidateUseDynamicOptionsQuery,
} from "@/api";

const api = new UrbintApi();

type FlexTaskWithTicketId = FlexTask & { ticketId: number };

const bulkAnswerResponseForms = (body: BulkAnswerResponseFormPayload) =>
  api.post(
    "response_form_answers/bulk",
    JSON.stringify({
      task_ids: body.tasks.map((t) => t.id),
      response_form_id: body.responseForm.id,
      questions: body.answers,
    })
  );

type BulkAnswerResponseFormPayload = {
  responseForm: ResponseForm;
  tasks: Array<FlexTaskWithTicketId>;
  answers: Array<Question>;
};

const useBulkAnswerResponseForm = () =>
  useMutation(bulkAnswerResponseForms, {
    onSuccess: (_, data) => {
      invalidateUseFlexTicketsQuery();

      data.tasks.forEach((task) => {
        invalidateUseTicketActivityQuery(task.ticketId);
        invalidateUseTasksForTicketQuery(task.ticketId);
        invalidateUseAnswerSetQuery(task.id!);
        invalidateUsePositiveResponsesForTicketQuery(task.ticketId);
        invalidateUseFlexTicketQuery(task.ticketId);
        invalidateUseDynamicOptionsQuery(data.responseForm.id, task.id!);
      });
      // Implement success logic
    },
  });

export { useBulkAnswerResponseForm };
