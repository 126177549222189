import type { TaskOptionsProps } from "./TaskOptions.types";
import { useState } from "react";
import { Icon } from "@urbint/silica";
import { MenuPanel } from "@/components/MenuPanel";

import { DeleteTaskModal } from "./DeleteTaskModal";

const TaskOptions = ({ task, closeTaskSidebar }: TaskOptionsProps) => {
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [modalState, setModalState] = useState(false);
  return (
    <>
      <MenuPanel
        show={showProfileMenu}
        placement="bottom-end"
        onClickOutside={() => setShowProfileMenu(false)}
        onClick={() => setShowProfileMenu(false)}
        className="mt-1.5"
        renderButton={() => (
          <div>
            <Icon
              name="more_horizontal"
              className={`p-2.5 cursor-pointer ${
                showProfileMenu ? "shadow-inner rounded-md bg-gray-100" : ""
              }`}
              onClick={() => setShowProfileMenu(!showProfileMenu)}
              data-testid="taskOptions_icon"
            />
          </div>
        )}
      >
        <button
          type="button"
          className="px-3 py-4.5 text-system-error-40 w-44 text-left"
          onClick={() => setModalState(true)}
          data-testid="delete-task-option"
        >
          Delete task...
        </button>
      </MenuPanel>
      {task?.id && (
        <DeleteTaskModal
          visibility={modalState}
          setModalVisibility={setModalState}
          taskId={task.id}
          ticketId={task.ticketId}
          closeTaskSidebar={closeTaskSidebar}
        />
      )}
    </>
  );
};

export { TaskOptions };
