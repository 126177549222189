import type { OverflowButtonProps } from "./OverflowButton.types";
import { useContext, useEffect, useRef } from "react";

import { useResponsive } from "@/hooks";
import { Button } from "@/common/Button";
import { OverflowContext } from "../OverflowProvider";

const OverflowButton = ({
  children,
  onClick,
  id,
  maxWidth,
  direction,
  disabled,
  dataTestId,
}: OverflowButtonProps) => {
  const targetRef = useRef<HTMLDivElement | null>(null);
  const { isSm } = useResponsive();
  const { toggleOverflow } = useContext(OverflowContext);
  const isShown =
    !!targetRef.current &&
    (direction === "left"
      ? targetRef.current.offsetLeft + targetRef.current.offsetWidth <= maxWidth
      : targetRef.current.offsetLeft >= 0) &&
    isSm;

  useEffect(() => {
    toggleOverflow(id, isShown);
  }, [isShown]);

  return (
    <div
      ref={targetRef}
      className={isShown ? "" : "invisible"}
      data-testid={dataTestId}
    >
      <Button variant="tertiary" onClick={onClick} disabled={disabled}>
        {children}
      </Button>
    </div>
  );
};

export { OverflowButton };
