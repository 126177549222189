import type { DamageThreatModalContentProps } from "./DamageThreatModalContent.types";
import { BodyText, Subheading } from "@urbint/silica";
import { useDamageThreat } from "@/api";
import { Loading } from "@/common/Loading";
import { DamageThreatList } from "@/components/DamageThreatList";

const DamageThreatModalContent = ({
  isModalOpen,
  ticketId,
  setIsModalOpen,
}: DamageThreatModalContentProps) => {
  const { data, isLoading, isError } = useDamageThreat(isModalOpen, ticketId);

  if (isLoading) {
    return (
      <div className="h-60 relative">
        <Loading />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="py-4">
        <Subheading>
          Apologies, the Damage Threat is having a bit of a delay.
        </Subheading>
        <BodyText>Team is notified, and we&apos;re working on it.</BodyText>
        <BodyText>Thank you for your understanding</BodyText>
      </div>
    );
  }

  if (data && data.length === 0) {
    return (
      <div className="py-4">
        <Subheading>
          Apologies, there&apos;s no Damage Threat information for this ticket.
        </Subheading>
        <BodyText>Thank you for your understanding</BodyText>
      </div>
    );
  }

  return (
    <DamageThreatList damageThreatList={data} setIsModalOpen={setIsModalOpen} />
  );
};

export { DamageThreatModalContent };
