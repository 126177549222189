import { TicketStage } from "@/utils/damage-prevention";

// ticket stage dropdown
const ticketStageOptions = [
  TicketStage.PRE_DIG,
  TicketStage.ACTIVE,
  TicketStage.EXPIRED,
  TicketStage.CANCELED,
];

export { ticketStageOptions };
