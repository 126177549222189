import { useMutation } from "@tanstack/react-query";
import { UrbintApi } from "@/utils/UrbintApi";
import {
  invalidateUseFlexTicketQuery,
  invalidateUseTicketActivityQuery,
  invalidateUseFlexTicketsQuery,
  invalidateUseTasksForTicketQuery,
} from "@/api";

type deleteTaskParams = {
  taskId: number;
  ticketId: number;
};

const api = new UrbintApi();

const deleteTask = (task: deleteTaskParams) => api.delete("tasks", task.taskId);

const useDeleteTask = () =>
  useMutation(deleteTask, {
    onSuccess: (data, params) => {
      const { ticketId } = data.json as any;
      const id = data.error ? params.ticketId : ticketId;

      invalidateUseTasksForTicketQuery(id);
      invalidateUseTicketActivityQuery(id);
      invalidateUseFlexTicketQuery(id);
      invalidateUseFlexTicketsQuery();
    },
  });

export { useDeleteTask };
