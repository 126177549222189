type AppEnv = "development" | "staging" | "production" | "integration";
type AppMetadata = {
  /** `acme-pa.urbint.com` -> `"damage-prevention-pa"` */
  clientId: string;
  /**
   * - `acme-pa.dp.urbint.com` -> `"production"`
   * - `acme-pa.dp.urbinternal.com` -> `"staging"`
   * - `literally.anything.else.com` -> `"development"`
   */
  env: AppEnv;
  /** `acme-pa.urbint.com` -> `"pa"` */
  opco: string;
  /** `acme-pa.urbint.com` -> `"acme"` */
  realm: string;
};

/**
 * Determine the app's operating environment based on the current url.
 */
function appEnv(domains: string[]): AppEnv {
  const isProduction = domains.includes("urbint");

  // feature tests run in CI at local.urbinternal so special care is needed here
  const isStaging =
    domains.includes("urbinternal") &&
    !domains.includes("integ") &&
    (domains.includes("staging-test-realm-op") || !domains.includes("local"));

  // feature tests run in CI at local.urbinternal so special care is needed here
  const isIntegration =
    domains.includes("integ") && domains.includes("urbinternal");

  if (isProduction) return "production";
  if (isStaging) return "staging";
  if (isIntegration) return "integration";
  return "development";
}

/**
 * Examines the current URL and infers metadata from it that can be used to
 * identify the app to other services such as Keycloak or Pendo.
 */
const appMetadata = (): AppMetadata => {
  // Expects a URL in the form of <realm>-<opco>.anything.com
  // or more concretely: nisource-oh.urbint.com
  const domains = window.location.hostname.split(".");
  const deepestSubdomain = domains[0]!;
  const indexOfLastHyphen = deepestSubdomain.lastIndexOf("-");
  const realm =
    indexOfLastHyphen === -1
      ? deepestSubdomain
      : deepestSubdomain.substr(0, indexOfLastHyphen);
  const opco =
    indexOfLastHyphen === -1
      ? "op"
      : deepestSubdomain.substr(indexOfLastHyphen + 1);
  const clientId = `damage-prevention-${opco}`;

  return { clientId, env: appEnv(domains), opco, realm };
};

export { appMetadata };
