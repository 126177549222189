import { useMutation } from "@tanstack/react-query";

import { SavedView } from "@/models";
import { UrbintApi } from "@/utils/UrbintApi";
import { setSavedViewsData } from "@/api";

const api = new UrbintApi();

const updateSavedView = async (view: SavedView) => {
  const resp = await api.patch(
    "saved_views",
    JSON.stringify(view.serialize!()),
    view.id
  );
  if (resp.error) {
    throw resp.error;
  }
  return new SavedView(resp.json);
};

const useUpdateSavedView = () =>
  useMutation(updateSavedView, {
    onSuccess: (updated) => {
      setSavedViewsData((savedViews) => {
        if (!savedViews) {
          return [updated];
        }
        const newSavedViews = [...savedViews];
        const ix = savedViews.findIndex((x) => x.id === updated.id);
        if (ix === -1) {
          newSavedViews.push(updated);
          newSavedViews.sort((a, b) => a.compare(b));
        } else {
          newSavedViews.splice(ix, 1, updated);
        }
        return newSavedViews;
      });
    },
  });

// eslint-disable-next-line import/prefer-default-export
export { useUpdateSavedView };
