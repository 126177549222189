import type { DialogTitleProps } from "./DialogTitle.types";
import { Dialog as HeadlessDialog } from "@headlessui/react";
import classNames from "classnames";
import { Icon } from "@urbint/silica";

const DialogTitle = ({
  children,
  className,
  variant = "normal",
}: DialogTitleProps) => {
  const warning = variant === "warning";
  const error = variant === "error";
  return (
    <>
      {(warning || error) && (
        <div className={`flex relative text-system-${variant}-40`}>
          <div
            className={`w-6 h-6 mr-2 flex items-center justify-center bg-system-${variant}-40 text-white rounded-sm`}
          >
            <Icon name={variant} />
          </div>
          <span className="text-base font-semibold">
            {warning ? "Warning" : "Error"}
          </span>
        </div>
      )}
      <HeadlessDialog.Title
        as="div"
        className={classNames(
          "font-semibold text-xl text-black text-opacity-88",
          className
        )}
      >
        {children}
      </HeadlessDialog.Title>
    </>
  );
};

export { DialogTitle };
