import type { UseQueryOptions } from "@tanstack/react-query";
import type { WorkflowSerializer } from "@/utils/damage-prevention";
import { useQuery } from "@tanstack/react-query";
import { Workflow } from "@/models";
import { UrbintApi } from "@/utils/UrbintApi";
import { queryClient } from "@/api/client";
import { handleApiResponse } from "@/api/helpers";

type IReturn = Workflow[];

const api = new UrbintApi();
const workflowsIdent = () => ["workflows"];

const fetchWorkflows = () =>
  api
    .getPage<WorkflowSerializer>({
      endPoint: "workflows",
      page: 1,
    })
    .then(handleApiResponse)
    .then((res) => res.results.map((r) => new Workflow(r)));

const useWorkflows = (opts?: UseQueryOptions<IReturn>) =>
  useQuery<IReturn>(workflowsIdent(), () => fetchWorkflows(), opts);

const prefetchWorkflows = () =>
  queryClient.prefetchQuery(workflowsIdent(), fetchWorkflows);

export { workflowsIdent, useWorkflows, prefetchWorkflows };
