import type {
  TicketQueueItemSerializer,
  TicketQueueSerializer,
} from "@/utils/damage-prevention";
import { UrbintModel } from "@/utils/UrbintModel";
import { TicketQueueItem } from "./TicketQueueItem";

enum TicketQueueState {
  NEW = "NEW",
  /** queue is optimized */
  OPTIMIZED = "OPTIMIZED",
  /** queue is being optimized */
  OPTIMIZING = "OPTIMIZING",
  /** has been touched by the owner/assigner */
  DIRTY = "DIRTY",
  /** something went wrong with optimization */
  ERROR = "ERROR",
  UNKNOWN = "UNKNOWN",
}

class TicketQueue extends UrbintModel<TicketQueueSerializer> {
  get id() {
    return this.castNumber(this.data.id);
  }

  /** Items in a queue have their own join id, which this function will search
   * for and return true if found in the current queue or false if not.
   */
  containsItemId(itemId: number): boolean {
    return this.items.some((item) => item.id === itemId);
  }

  containsTicketId(ticketId: number): boolean {
    return this.items.some((item) => item.ticketId === ticketId);
  }

  get items() {
    if (this.data?.items) {
      return this.castArray<TicketQueueItemSerializer>(this.data.items).map(
        (item) => new TicketQueueItem(item)
      );
    }
    return [];
  }

  get createdOn() {
    return this.castDate(this.data.created_on);
  }

  get updatedOn() {
    return this.castDate(this.data.updated_on);
  }

  get state() {
    switch (this.data?.state) {
      case "NEW":
        return TicketQueueState.NEW;
      case "OPTIMIZED":
        return TicketQueueState.OPTIMIZED;
      case "OPTIMIZING":
        return TicketQueueState.OPTIMIZING;
      case "DIRTY":
        return TicketQueueState.DIRTY;
      case "ERROR":
        return TicketQueueState.ERROR;
      default:
        return TicketQueueState.UNKNOWN;
    }
  }

  get errorMessage() {
    return this.data.error_msg ? this.castString(this.data.error_msg) : null;
  }

  get createdBy() {
    return this.castNumber(this.data.created_by);
  }

  get ownedBy() {
    return this.castNumber(this.data.owned_by);
  }

  compare(): number {
    throw new Error("Method not implemented.");
  }

  serialize(): TicketQueueSerializer {
    throw new Error("Method not implemented.");
  }
}

export { TicketQueueState, TicketQueue };
