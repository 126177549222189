import type { DialogHeaderProps } from "./DialogHeader.types";
import { Icon } from "@urbint/silica";
import {
  DialogHeaderWrapper,
  DialogHeaderButtons,
  DialogTitle,
} from "./DialogHeaderWrapper";

const DialogHeader = ({
  header,
  onClose,
  variant,
  theme,
}: DialogHeaderProps) => {
  // If we just get a title value
  if (typeof header === "string") {
    return (
      <DialogHeaderWrapper>
        <DialogTitle className={theme?.title} variant={variant}>
          {header}
        </DialogTitle>
        <DialogHeaderButtons>
          <button type="button" className="flex items-center" onClick={onClose}>
            <Icon name="close_big" />
          </button>
        </DialogHeaderButtons>
      </DialogHeaderWrapper>
    );
  }
  // If we get a render prop function
  if (typeof header === "function") {
    return header({ close: onClose });
  }
  return null;
};
export { DialogHeader };
