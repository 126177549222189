import type { OverflowMenuProps } from "./OverflowMenu.types";
import { useContext } from "react";
import classNames from "classnames";

import { useDimensions } from "@/hooks";

import { MenuPanel } from "@/components/MenuPanel";
import { Button } from "@/common/Button";
import { OverflowButton } from "./OverflowButton";
import { OverflowContext, withOverflowProvider } from "./OverflowProvider";

/**
 * Overflow menu that allows buttons to flow out unto any available space.
 *
 *
 * to the left or right or the
 * menu button.
 *
 * ## Example
 *
 * ```tsx
 *  <OverflowMenu {...args}>
        <MenuGroup>
          <MenuItem  overflow={true} onClick={console.log}>
            Add to queue
          </MenuItem>
          <MenuItem id="second" overflow={true} onClick={console.log}>
            Add a task...
          </MenuItem>
          <MenuItem onClick={console.log}>I won't move!</MenuItem>
          <MenuItem  overflow={true} onClick={console.log}>
            Add comments...
          </MenuItem>
          <MenuItem overflow={true} onClick={console.log}>
            Delete them!
          </MenuItem>
        </MenuGroup>
        <MenuGroup>
          <MenuItem>Last item</MenuItem>
        </MenuGroup>
      </OverflowMenu>
      ```
 */
const OverflowMenuComponent = ({
  children,
  direction = "left",
  buttonLabel = "Menu",
  buttonIcon,
  buttonVariant,
  dataTestId,
}: OverflowMenuProps) => {
  const [ref, { width }] = useDimensions();

  const { getOverflowItems, hasItems, isOpen, setIsOpen } =
    useContext(OverflowContext);
  const overflowItems = getOverflowItems();
  const menuHidden = !hasItems();

  const renderMenuButton = () => (
    <Button
      variant={buttonVariant ?? "tertiary"}
      onClick={() => setIsOpen((s) => !s)}
      iconEnd={buttonIcon}
    >
      {buttonLabel}
    </Button>
  );

  return (
    <div
      className={classNames(
        "flex text-neutral-shade-primary font-sans space-x-2 flex-shrink max-w-full items-center min-w-0",
        {
          "flex-row-reverse": direction === "right",
        }
      )}
      data-testid={dataTestId}
    >
      <div
        className={classNames(
          "flex space-x-2 relative overflow-hidden flex-shrink min-w-0",
          {
            "justify-start": direction === "left",
            "justify-end": direction === "right",
          }
        )}
        ref={ref}
      >
        {overflowItems.map((item) => (
          <OverflowButton
            key={item.id}
            id={item.id!}
            maxWidth={width}
            direction={direction}
            onClick={item.onClick}
            disabled={item.disabled}
            dataTestId={item.dataTestId}
          >
            {item.children}
          </OverflowButton>
        ))}
      </div>
      <div className={menuHidden ? "hidden" : ""}>
        <MenuPanel
          show={isOpen && !menuHidden}
          renderButton={renderMenuButton}
          placement="bottom-end"
          onClickOutside={() => setIsOpen(false)}
          className={classNames({ hidden: menuHidden })}
        >
          <div className={classNames("w-60", { hidden: menuHidden })}>
            {children}
          </div>
        </MenuPanel>
      </div>
    </div>
  );
};

const OverflowMenu = withOverflowProvider(OverflowMenuComponent);

export { OverflowMenu };
