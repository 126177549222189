import type {
  MapLayerMouseEvent,
  MapRef,
  MapboxEvent,
  MapboxGeoJSONFeature,
} from "react-map-gl";
import type { ScoreType } from "@/components/Map/Map.types";
import type { MapPageMapProps, TicketsToShowProps } from "./MapPageMap.types";

import { useCallback, useEffect, useRef, useState } from "react";
import { isEmpty } from "lodash";
import { useLocation } from "react-router-dom";
import {
  ClusterLayer,
  clusterLayer,
  ticketLayer,
  FieldMemberLayer,
  GeoAssetsLayer,
  CLUSTER_POPUP_LAYOUT_CHANGE_ZOOM_VALUE,
} from "@/components/Map/Layers";

import { useFlexTickets, usePublicConfig } from "@/api";
import { useDamagePreventionAuth, useResponsive } from "@/hooks";

import { Map } from "@/components/Map/Map";
import {
  MAP_STYLE_SATELLITE,
  MAP_STYLE_STREET,
} from "@/components/Map/Map.utils";
import { useTicketViewEditor } from "@/components/TicketViewEditorProvider";
import { useTicketFocusContext } from "@/pages/MapPage";
import { LocateMeMarker } from "@/components/Map/Markers";
import { ClusterMarkerCard } from "@/components/Map/ClusterMarkerCard";
import { TicketMarkerPopup } from "@/components/Map/Popups";
import { useLayers } from "@/hooks/useLayers";
import { flexFieldsForTicketCard } from "@/components/TicketCard";
import { MeasureTool } from "@/components/MeasureTool/MeasureTool";
import { evaluatePadding } from "@/components/Map/Popups/ClusterMarkerPopup/ClusterMarkerPopup.utils";
import { TopRightMapControls } from "./TopRightMapControls";
import { BottomRightMapControls } from "./BottomRightMapControls";
import { RenderSelectedTickets } from "./RenderSelectedTickets";
import {
  handleFeatureInteraction,
  setOverlapPointScrollLayout,
} from "./MapPageMap.utils";

const MapPageMap = ({ children }: MapPageMapProps) => {
  const { currentUser } = useDamagePreventionAuth();
  const [locateMe, setLocateMe] = useState(false);
  const { dirtyView, isInitialized, isShowingTasks } = useTicketViewEditor();
  const { setFocusedTicket } = useTicketFocusContext();
  const [scoreType, setScoreType] = useState<ScoreType>("damage");
  const { data: publicConfig } = usePublicConfig();
  const location = useLocation();

  const fields = flexFieldsForTicketCard(isShowingTasks);
  const { data } = useFlexTickets(
    { ...dirtyView, fields },
    { enabled: isInitialized }
  );

  useEffect(() => {
    if (publicConfig?.defaultScoreType) {
      setScoreType(publicConfig.defaultScoreType);
    }
  }, [publicConfig?.defaultScoreType]);

  const tickets = data?.pages ? data?.pages.flatMap((page) => page.data) : [];

  const { isSm, isMd } = useResponsive();

  const mapRef = useRef<MapRef>(null);

  const [ticketsToShow, setTicketsToShow] = useState<TicketsToShowProps>();

  const [clusterToShow, setClusterToShow] = useState<
    MapboxGeoJSONFeature | undefined
  >();

  const [isInMeasureMode, setIsInMesureMode] = useState<boolean>(false);

  const { layers, toggleLayer, setLayerEnabled } = useLayers({
    satellite: { enabled: true, visible: false },
    legend: { enabled: true, visible: isMd },
    utilityAssets: { enabled: false, visible: false },
    electricDistributionAssets: { enabled: false, visible: false },
    fiberAssets: { enabled: false, visible: false },
    electricTransmissionAssets: { enabled: false, visible: false },
    networkAssets: { enabled: false, visible: false },
    telcoAssets: { enabled: false, visible: false },
    assetBuffers: { enabled: false, visible: false },
    annotations: { enabled: false, visible: true },
    fieldPersonnel: { enabled: false, visible: false },
  });

  useEffect(() => {
    if (publicConfig?.mapLayerToggle.utility)
      setLayerEnabled("utilityAssets", true);
    if (publicConfig?.mapLayerToggle.electricDistribution)
      setLayerEnabled("electricDistributionAssets", true);
    if (publicConfig?.mapLayerToggle.electricTransmission)
      setLayerEnabled("electricTransmissionAssets", true);
    if (publicConfig?.mapLayerToggle.fiber)
      setLayerEnabled("fiberAssets", true);
    if (publicConfig?.mapLayerToggle.network)
      setLayerEnabled("networkAssets", true);
    if (publicConfig?.mapLayerToggle.telco)
      setLayerEnabled("telcoAssets", true);
    if (publicConfig?.mapLayerToggle.buffers)
      setLayerEnabled("assetBuffers", true);
    if (publicConfig?.mapLayerToggle.annotations)
      setLayerEnabled("annotations", true);

    if (publicConfig?.fleetTracking) {
      setLayerEnabled("fieldPersonnel", true);
      return;
    }
    if (layers.fieldPersonnel.enabled) {
      setLayerEnabled("fieldPersonnel", false);
    }
  }, [publicConfig]);

  /**
   * `handleMapClick` is called when the map is clicked
   */
  const handleMapClick = useCallback(
    (event: MapLayerMouseEvent) => {
      const { features } = event;

      if (!features || isInMeasureMode || isSm) return;
      if (isEmpty(features)) {
        setFocusedTicket(undefined);
        setTicketsToShow(undefined);
        setClusterToShow(undefined);
        return;
      }

      handleFeatureInteraction(
        features || [],
        mapRef,
        tickets,
        setClusterToShow,
        setTicketsToShow,
        setFocusedTicket,
        isInMeasureMode
      );
    },
    [isSm]
  );

  /**
   * `handleMouseEnter` is called when the user hovers/taps some part of the map.
   */
  const handleMouseEnter = ({ features }: MapLayerMouseEvent) => {
    if (!features || isInMeasureMode) return;

    handleFeatureInteraction(
      features || [],
      mapRef,
      tickets,
      setClusterToShow,
      setTicketsToShow,
      setFocusedTicket,
      isInMeasureMode
    );
  };
  /**
   * `handleMouseLeave` is called when the user moves away/untaps some part of the map.
   */
  const handleMouseLeave = () => {
    setTimeout(() => {
      setFocusedTicket(undefined);
      setTicketsToShow(undefined);
      setClusterToShow(undefined);
    }, 100);
  };

  const onMapLoad = (mapOnLoadEvent: MapboxEvent) => {
    mapOnLoadEvent.target.resize();
  };

  useEffect(() => {
    if (mapRef && mapRef.current) {
      // Force the map to resize when user change location
      setTimeout(() => {
        mapRef.current?.getMap().resize();
      }, 100);
    }
  }, [location]);

  return (
    <div className="relative w-full h-full">
      <Map
        style={
          layers.satellite.visible ? MAP_STYLE_SATELLITE : MAP_STYLE_STREET
        }
        onClick={handleMapClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onLoad={onMapLoad}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        interactiveLayerIds={[ticketLayer.id!, clusterLayer.id!]}
        mapRef={mapRef}
        initialViewState={{
          zoom: 2,
          latitude: 37.09024, // Center of North America (approximate)
          longitude: -95.712891,
        }}
      >
        {!layers.fieldPersonnel.visible && locateMe && (
          <LocateMeMarker currentUser={currentUser} />
        )}
        {layers.fieldPersonnel.enabled && (
          <FieldMemberLayer shown={layers.fieldPersonnel.visible} />
        )}
        <GeoAssetsLayer
          utilityShown={layers.utilityAssets.visible}
          electricDistributionShown={layers.electricDistributionAssets.visible}
          electricTransmissionShown={layers.electricTransmissionAssets.visible}
          fiberShown={layers.fiberAssets.visible}
          networkShown={layers.networkAssets.visible}
          telcoShown={layers.telcoAssets.visible}
          buffersShown={layers.assetBuffers.visible}
          annotationsShown={layers.annotations.visible}
        />

        {/* Render Clusters on Map */}
        <ClusterLayer view={dirtyView} scoreType={scoreType} />

        {/* Render Tickets information info on hover or click */}
        {isSm && ticketsToShow && (
          <TicketMarkerPopup
            popupCoordinates={ticketsToShow.position}
            offset={ticketsToShow.offset}
          >
            <RenderSelectedTickets tickets={ticketsToShow.tickets} />
          </TicketMarkerPopup>
        )}

        {isSm &&
          clusterToShow &&
          clusterToShow?.geometry.type === "Point" &&
          clusterToShow.geometry.coordinates &&
          clusterToShow.properties !== null && (
            <TicketMarkerPopup
              popupCoordinates={clusterToShow.geometry.coordinates}
            >
              <ClusterMarkerCard
                selectedCount={clusterToShow.properties.selectedTickets}
                className={evaluatePadding(clusterToShow.properties)}
                risks={clusterToShow.properties}
                scoreType={scoreType}
              />
            </TicketMarkerPopup>
          )}

        {isInMeasureMode && (
          <MeasureTool mapRef={mapRef} isActive={isInMeasureMode} />
        )}

        <TopRightMapControls
          setOnlocateMe={setLocateMe}
          MapLayerControlsProps={{ layers, toggleLayer }}
          measureModeState={{ get: isInMeasureMode, set: setIsInMesureMode }}
        />

        <BottomRightMapControls
          ticketsToShow={ticketsToShow}
          clusterToShow={clusterToShow}
          scoreTypeState={{ scoreType, setScoreType }}
          mapLayerControlsProps={{ layers }}
          mapToggleButton={children}
        />
      </Map>
    </div>
  );
};

export { MapPageMap };
