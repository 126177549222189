import type { SelectOptionsWrapperProps } from "./SelectOptionsWrapper.types";
import type { SelectOption } from "@/components/Select";
import { Combobox } from "@headlessui/react";
import { usePublicConfig } from "@/api";
import { allVisibleNonDisabledOptions } from "../TypeheadSelect.utils";
import { SelectOption as Option } from "./SelectOption";
import {
  getOptions,
  shouldShowSelectAll,
} from "./SelectionOptionsWrapper.utils";
import { SelectAll } from "./SelectAll";
import { SELECTED_LABEL } from "./SelectOptionsWrapper.constants";

const SelectOptionsWrapper = ({
  type,
  options,
  optionsToDisable,
  filterValue,
  handleSelectAll,
  selectedOptions,
}: SelectOptionsWrapperProps) => {
  const { data: publicConfig } = usePublicConfig();
  const visibleOptions = getOptions(options, filterValue);

  const shouldDisableOption = (option: SelectOption<string>) =>
    optionsToDisable.includes(option);

  const enableSelectAll = shouldShowSelectAll(
    filterValue,
    publicConfig?.enableSelectAllInFilters
  );

  return (
    <Combobox.Options
      as="div"
      className="absolute top-[calc(100%+0.125rem)] z-10 w-full pb-8"
    >
      <div className="drop-shadow-lg rounded bg-white divide-y-2 divide-gray-20">
        {enableSelectAll && (
          <SelectAll
            type={type}
            visibleOptions={allVisibleNonDisabledOptions(
              visibleOptions,
              optionsToDisable
            )}
            selectedOptions={selectedOptions}
            handleSelectAll={handleSelectAll}
          />
        )}
        {!enableSelectAll && selectedOptions.length > 0 && (
          <label className="flex items-center py-2 px-4 w-full">{`${SELECTED_LABEL} (${selectedOptions.length})`}</label>
        )}
        <ul className="max-h-80 overflow-auto rounded">
          {!visibleOptions.length && filterValue !== "" ? (
            <div className="relative cursor-default select-none p-2 text-gray-700">
              No options
            </div>
          ) : (
            visibleOptions.map((option: SelectOption<string>) => (
              <Option
                type={type}
                option={option}
                key={option.label}
                isDisable={shouldDisableOption(option)}
              />
            ))
          )}
        </ul>
      </div>
    </Combobox.Options>
  );
};

export { SelectOptionsWrapper };
