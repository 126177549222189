import type { TicketView } from "@/components/TicketViewEditorProvider";
import type { dialogFooter } from "@/components/Dialog";
import type { ActionBarProps } from "./ActionBar.types";
import type { Task } from "@/models";
import { useCallback, useEffect, useMemo, useState } from "react";
import { BodyText, Subheading } from "@urbint/silica";
import { type SavedView } from "@/models";
import { Dialog } from "@/components/Dialog";
import {
  TicketViewEditorProvider,
  useTicketViewEditor,
} from "@/components/TicketViewEditorProvider";
import {
  useCreateSavedView,
  useDeleteSavedView,
  useFlexTicketsCount,
  useResponseFormsForTasks,
  useTicketPoints,
  useUpdateSavedView,
} from "@/api";
import { useTicketSelector } from "@/components/TicketSelectorProvider";
import { useToasts } from "@/components/Toasts";
import {
  BulkAddTasksModal,
  BulkAddTicketCommentsModal,
  BulkChangeAssigneeModal,
  BulkCompleteTasksModal,
  ExpirationDateModal,
} from "@/components/Modals";
import { FilterDialog } from "./FilterDialog";
import {
  BULK_ACTION_DISABLED_COPY,
  MAX_SELECTION_THRESHOLD,
  WARNING_THRESHOLD,
} from "./ActionBar.constants";
import {
  convertSelectedTasksToFlatArray,
  useBulkModals,
} from "./ActionBar.utils";
import { ActionBarLeft } from "./ActionBarLeft";
import { ActionBarRightEntry } from "./ActionBarRight";

const ActionBar = ({
  onUpdateSavedView,
  onUpdateDirtyView,
}: ActionBarProps) => {
  const { addToast } = useToasts();
  const {
    setDirtyView,
    isShowingTasks,
    toggleIsShowingTasks,
    dirtyView,
    savedView,
    isInitialized,
  } = useTicketViewEditor();

  const {
    clearSelectedTickets,
    selectMode,
    selectedTickets,
    selectedTasks,
    clearSelectedTasks,
    toggleTicketId,
  } = useTicketSelector();

  const { data: flexTicketCount } = useFlexTicketsCount(dirtyView, {
    enabled: isInitialized,
  });
  // A bit of a hack. The ticket points contain all ticket ids
  // for the current view, so we're fetching them from here
  const { data: ticketPoints } = useTicketPoints(dirtyView, {
    enabled: isInitialized,
  });

  const createSavedViewMutation = useCreateSavedView();
  const deleteSavedViewMutation = useDeleteSavedView();
  const updateSavedViewMutation = useUpdateSavedView();

  //
  // --- Computed State
  //
  const allSelectedTasks = convertSelectedTasksToFlatArray(
    selectedTasks
  ) as Task[];

  const bulkResponseForms = useResponseFormsForTasks(allSelectedTasks);

  const numberOfSelectedTickets = selectedTickets.length;
  const hasSelections =
    numberOfSelectedTickets > 0 || allSelectedTasks.length > 0;
  const selectionCount =
    selectMode === "tickets"
      ? numberOfSelectedTickets
      : allSelectedTasks.length;

  const allTicketIds: number[] =
    ticketPoints?.features?.map((f) => f.id! as number) ?? [];

  const bulkDisabled = useMemo(
    () => selectionCount > MAX_SELECTION_THRESHOLD,
    [selectionCount]
  );

  const handleClearSelection = () => {
    if (selectMode === "tickets") clearSelectedTickets();
    else clearSelectedTasks();
  };

  //
  // --- State
  //
  const [filterModalShown, showFilterModal] = useState(false);
  const {
    modal,
    cancelAction,
    confirmAction,
    showWarning,
    triggerAction,
    setModal,
    completeAction,
  } = useBulkModals<
    | "bulkCreateTasks"
    | "bulkChangeAssignee"
    | "bulkCreateTicketComments"
    | "bulkCompleteTasks"
    | "bulkChangeExpirationDate"
  >(numberOfSelectedTickets, WARNING_THRESHOLD);
  const [thresholdToastShown, setThresholdToastShown] =
    useState<boolean>(false);

  const handleChangeAssigneeSuccess = () => {
    clearSelectedTasks();
    completeAction();
  };

  const handleCompleteTasksSuccess = () => {
    clearSelectedTasks();
    setModal(undefined);
  };

  const handleFilterModalClose = (view: TicketView) => {
    showFilterModal(false);

    // Update our current view to the new one
    setDirtyView(view);
    onUpdateDirtyView(view);
  };

  const handleUpdateSavedView = useCallback(
    async (view: SavedView) => {
      const updatedView = await updateSavedViewMutation.mutateAsync(view);
      onUpdateSavedView(updatedView);
    },
    [updateSavedViewMutation, onUpdateSavedView]
  );

  const handleCreateSavedView = useCallback(
    async (view: SavedView) => {
      const newView = await createSavedViewMutation.mutateAsync(view);
      onUpdateSavedView(newView);
    },
    [createSavedViewMutation, onUpdateSavedView]
  );

  const handleDeleteSavedView = useCallback(
    async (view: SavedView) => {
      await deleteSavedViewMutation.mutateAsync(view);
      onUpdateSavedView();
    },
    [deleteSavedViewMutation, onUpdateSavedView]
  );

  useEffect(() => {
    if (bulkDisabled && !thresholdToastShown) {
      setThresholdToastShown(true);
      addToast(BULK_ACTION_DISABLED_COPY, "warning");
    }
  }, [bulkDisabled]);

  useEffect(() => {
    // Remove selected id's, if they aren't on the ticket list
    const ticketsIdsNotListed = selectedTickets.filter(
      (item) => !allTicketIds.includes(item)
    );

    ticketsIdsNotListed.forEach(toggleTicketId);
  }, [ticketPoints]);

  const modalFooter: dialogFooter = {
    toRender: "Yes, continue",
    onClose: cancelAction,
    onClick: confirmAction,
  };

  return (
    <>
      <header className="w-full sm:h-[52px] sm:min-h-[52px] bg-white flex flex-col sm:flex-row items-center space-between sm:px-3 sm:py-2 font-semibold text-neutral-shade-secondary font-sans shadow-5 relative z-20">
        <ActionBarLeft
          setThresholdToastShown={setThresholdToastShown}
          onUpdateSavedView={onUpdateSavedView}
        />
        <ActionBarRightEntry
          ticketCount={flexTicketCount?.count || 0}
          showFilterModal={showFilterModal}
          setThresholdToastShown={setThresholdToastShown}
          hasSelections={hasSelections}
          handleClearSelection={handleClearSelection}
          isShowingTasks={isShowingTasks}
          toggleIsShowingTasks={toggleIsShowingTasks}
          bulkDisabled={bulkDisabled}
          triggerAction={triggerAction}
          setModal={setModal}
          selectionCount={selectionCount}
          allTicketIds={allTicketIds}
          allSelectedTasks={allSelectedTasks}
        />
      </header>
      {/* We introduce an isolated ticket view editor context so that the dialog doesn't end up
          mutating the page-wide context (triggering searches) until it closes.
          On dialog close we replace the page-wide context with the new dirty value. */}
      <TicketViewEditorProvider
        savedView={savedView}
        initialDirtyView={dirtyView}
      >
        <FilterDialog
          isOpen={filterModalShown}
          onClose={handleFilterModalClose}
          onCreate={handleCreateSavedView}
          onUpdate={handleUpdateSavedView}
          onDelete={handleDeleteSavedView}
        />
      </TicketViewEditorProvider>
      {/* Modals */}
      <BulkAddTicketCommentsModal
        tickets={selectedTickets}
        isOpen={modal === "bulkCreateTicketComments"}
        onClose={cancelAction}
      />

      <ExpirationDateModal
        tickets={selectedTickets.map((ticketId) => ({ id: ticketId }))}
        isOpen={modal === "bulkChangeExpirationDate"}
        onClose={cancelAction}
      />
      {bulkResponseForms.length > 0 && (
        <BulkCompleteTasksModal
          tasks={allSelectedTasks}
          responseForm={bulkResponseForms.find(Boolean)!}
          isOpen={modal === "bulkCompleteTasks"}
          onClose={cancelAction}
          onSuccess={handleCompleteTasksSuccess}
        />
      )}
      <BulkAddTasksModal
        tickets={selectedTickets}
        isOpen={modal === "bulkCreateTasks"}
        onClose={cancelAction}
        warningThreshold={WARNING_THRESHOLD}
      />
      <BulkChangeAssigneeModal
        tasks={allSelectedTasks}
        isOpen={modal === "bulkChangeAssignee"}
        onClose={cancelAction}
        onSuccess={handleChangeAssigneeSuccess}
      />
      <Dialog
        isOpen={showWarning}
        onClose={cancelAction}
        variant="warning"
        footer={modalFooter}
        header=""
      >
        <Subheading>
          You are about to perform an action on a large number of tickets. Are
          you sure?
        </Subheading>
        <BodyText>
          <p className="mt-3 mb-6">
            This bulk action will take a moment to complete.
          </p>
        </BodyText>
      </Dialog>
    </>
  );
};

export { ActionBar };
