const MACHINE_LEARNING_INSIGHTS_TEXT = `The insights shared provide information related to some of the risk
factors that drive Urbint's Machine Learning Models. However, the actual
Machine Learning model's decision is based on combined effect of a
multitude of engineered features including the ones shared above.`;

const DAMAGES_TEXT = "Damages/1000 tickets";

const damageText = "text-default-on-light";
const titleText = `${damageText} font-semibold pb-1`;

export { MACHINE_LEARNING_INSIGHTS_TEXT, DAMAGES_TEXT, titleText, damageText };
