import { useDamagePreventionAuth } from "@/hooks/useDamagePreventionAuth/useDamagePreventionAuth";
import { getCookies } from "@/utils";

type ApiResponse<T> = {
  json?: T | undefined;
  error?: Error | undefined;
  response?: Response | undefined;
};

const handleApiResponse = <T>(res: ApiResponse<T>) => {
  if (res.error) {
    throw res.error;
  } else if (!res.json) {
    throw new Error(
      "Oops! Something went wrong! Please refresh or try again later"
    );
  }
  return res.json;
};

// eslint-disable-next-line no-undef
const useDefaultFetchHeaders = (withAuth = true): HeadersInit => {
  const { bearerToken } = useDamagePreventionAuth();
  const cookies = getCookies();

  // eslint-disable-next-line no-undef
  const params: HeadersInit = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "X-CSRFToken": cookies.csrftoken,
  };

  if (withAuth) {
    params.Authorization = `Bearer ${bearerToken}`;
  }

  return params;
};

const request = async <T>(
  url: string,
  // eslint-disable-next-line no-undef
  requestBody?: RequestInit
): Promise<T> => {
  if (!url.endsWith("/")) url += "/";

  const response = await fetch(url, requestBody);

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return response.json();
};

const isJwtExpired = (token: Keycloak.KeycloakTokenParsed | undefined) => {
  if (!token?.exp) return false;
  const nowEpochSec = Math.floor(new Date().getTime() / 1000);
  return nowEpochSec > token.exp;
};

export type { ApiResponse };
export { handleApiResponse, useDefaultFetchHeaders, request, isJwtExpired };
