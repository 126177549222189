import type { AvatarProps } from "./Avatar.types";
import { Icon } from "@urbint/silica";
import { Tooltip } from "@/common/Tooltip";
import { isUnassignedUser } from "@/utils/users";
import fallbackIcon from "./DefaultAvatar.svg";
import urbintLogo from "./UrbintLogo.svg";
import { nameToColor, nameToInitials } from "./Avatar.utils";

const Avatar = ({
  user,
  fallback = false,
  name,
  size = "sm",
  urbint = false,
  showTooltip = true,
  tooltipOrigin = "top",
  tooltipPlaceholder = "",
}: AvatarProps) => {
  let displayName = name || (user?.displayName ?? "");
  if (urbint) {
    displayName = "Urbint";
  }

  const isUnassigned =
    isUnassignedUser(user as any) || (!user && !name && !fallback && !urbint);

  let sizeClasses;
  switch (size) {
    case "sm":
      sizeClasses = "h-6 w-6";
      break;
    case "md":
      sizeClasses = "h-8 w-8";
      break;
    case "lg":
      sizeClasses = "h-10 w-10";
      break;
    case "xl":
      sizeClasses = "h-16 w-16";
      break;
    default:
      sizeClasses = "h-8 w-8";
      break;
  }

  return (
    <Tooltip
      title={tooltipPlaceholder || displayName}
      origin={tooltipOrigin}
      disabled={!showTooltip || fallback} // when showing the default profile picture, the tooltip is disabled
    >
      <div
        className={`${sizeClasses} ${
          displayName && !isUnassigned ? nameToColor(displayName) : ""
        } rounded-full flex items-center justify-center relative isolate overflow-hidden`}
      >
        {isUnassigned ? (
          <div
            className="bg-neutral-shade-background-light border-neutral-shade-border-light border flex w-full h-full items-center justify-center rounded-full text-neutral-shade-tertiary aspect-square"
            data-testid="unassigned-user"
          >
            <Icon name="user" />
          </div>
        ) : (
          <>
            {fallback || urbint ? ( // src is provided (UrbintAvatar) or use fallback (default profile picture)
              <img
                alt="urbint-avatar"
                className="z-10"
                src={fallback ? fallbackIcon : urbintLogo}
              />
            ) : (
              <p // show user's initials with a colored background
                className="w-full text-center text-xs font-semibold"
                data-testid="user-assigned"
              >
                {displayName && nameToInitials(displayName)}
              </p>
            )}
          </>
        )}
      </div>
    </Tooltip>
  );
};

export { Avatar };
