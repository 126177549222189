import type { NonTicketedEventAttachmentSerializer } from "@/utils/damage-prevention";
import { UrbintModel } from "@/utils/UrbintModel";

class NonTicketedEventAttachments extends UrbintModel<NonTicketedEventAttachmentSerializer> {
  get id() {
    return this.castNumber(this.data.id);
  }

  get file() {
    return this.castString(this.data.file);
  }

  get type() {
    return this.castString(this.data.type);
  }

  get thumbnailUrl() {
    return this.castString(this.data.thumbnail);
  }

  get creatorId() {
    return this.castNumber(this.data.creator);
  }

  get eventId() {
    return this.castNumber(this.data.event);
  }

  get createdAt() {
    return this.castDate(this.data.created_at);
  }

  compare(instance: NonTicketedEventAttachments) {
    return this.createdAt.getTime() - instance.createdAt.getTime();
  }

  serialize(): NonTicketedEventAttachmentSerializer {
    return this.data;
  }
}

export { NonTicketedEventAttachments };
