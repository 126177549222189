import startCase from "lodash/startCase";
import {
  TicketOrderField,
  type SavedViewSerializer,
  type TicketFilterFieldKey,
  type TicketOrderFieldKey,
} from "@/utils/damage-prevention";
import { UrbintModel } from "@/utils/UrbintModel";

type DateRangeFilter = {
  type: "date";
  after: string | undefined;
  before: string | undefined;
};

type SavedViewFilterValue =
  | string
  | number
  | string[]
  | number[]
  | DateRangeFilter;

interface SavedViewFilter {
  field: TicketFilterFieldKey;
  value?: SavedViewFilterValue;
}

interface SavedViewField {
  field: string;
}

enum SortOrderDirection {
  ASC = "asc",
  DESC = "desc",
}

interface SavedViewOrder {
  field: TicketOrderFieldKey;
  direction: SortOrderDirection;
}

const LABELS: Partial<
  Record<TicketOrderFieldKey | TicketFilterFieldKey, string>
> = {
  damage_potential: "Damage Threat Category",
  impact_potential: "Impact Category",
  response_due_date: "Response Due Date Time",
  stage: "Ticket Stage",
  expiration_date: "Expiration Date Time",
  work_start_date: "Work Start Date Time",
  ticket_reported_date: "Ticket Reported Date Time",
  eight11_center: "One Call Center",
  tlc_received_at: "Received at Date Time",
};

const getFieldLabel = (
  field: TicketOrderFieldKey | TicketFilterFieldKey | undefined
) => {
  if (!field) return "";

  // Override score labels
  if (field === TicketOrderField.scaled_impact_score) {
    return "Impact Score";
  }

  if (field === TicketOrderField.scaled_threat_score) {
    return "Damage Threat Score";
  }

  return startCase(LABELS[field] ?? field.replaceAll("_", " "));
};
class SavedView extends UrbintModel<SavedViewSerializer> {
  get id() {
    return this.castNumber(this.data.id);
  }

  get createdOn() {
    return this.castDate(this.data.created_on);
  }

  get updatedOn() {
    return this.castDate(this.data.updated_on);
  }

  get shared() {
    return this.castBool(this.data.shared);
  }

  get showTasks() {
    return this.castBool(this.data.show_tasks);
  }

  get filters(): SavedViewFilter[] | undefined {
    return this.data.filters;
  }

  get excludeFilters(): SavedViewFilter[] | undefined {
    return this.data.exclude_filters;
  }

  get fields(): SavedViewField[] | undefined {
    return this.data.fields;
  }

  get order(): SavedViewOrder | undefined {
    return this.data.order;
  }

  get name() {
    return this.castString(this.data.name);
  }

  get userId() {
    if (this.data.user === null) return undefined;
    return this.castNumber(this.data.user);
  }

  get isDefaultView() {
    return this.castBool(this.data.is_default_view);
  }

  set isDefaultView(value: boolean) {
    this.data.is_default_view = value;
  }

  compare(instance: SavedView): number {
    let comp = 0;
    if (this.name === "All Live Tickets") comp = -1;
    else if (instance.name === "All Live Tickets") comp = 1;
    else comp = this.name.localeCompare(instance.name);
    return comp;
  }

  serialize(): SavedViewSerializer {
    return this.data;
  }
}

export type {
  DateRangeFilter,
  SavedViewFilter,
  SavedViewField,
  SavedViewOrder,
  SavedViewFilterValue,
};
export { SortOrderDirection, getFieldLabel, SavedView };
