import type { SelectOption } from "../types";
import type { DropdownMultiSelectState } from ".";

const updateStateForNewSearchLetter = <T>(
  state: DropdownMultiSelectState,
  letter: string,
  options?: SelectOption<T>[]
): DropdownMultiSelectState => {
  let activeIdx: number | undefined;
  const searchTerm = state.searchTerm + letter.toLowerCase();
  if (!options?.length || !searchTerm) {
    activeIdx = state.activeIdx;
  } else {
    activeIdx = options?.findIndex((x) =>
      x.label.toLowerCase().startsWith(searchTerm)
    );
    if (activeIdx < 0) {
      activeIdx = state.activeIdx;
    }
  }
  return { ...state, activeIdx, searchTerm };
};

const close = (state: DropdownMultiSelectState): DropdownMultiSelectState => ({
  ...state,
  isOpen: false,
  activeIdx: undefined,
});
export { updateStateForNewSearchLetter, close };
