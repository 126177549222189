import type {
  QuestionSerializer,
  ResponseFormSerializer,
  ResponseOptionSerializer,
} from "@/utils/damage-prevention";
import { UrbintModel } from "@/utils/UrbintModel";
import { Question, QuestionAnswerType } from "./Question";
import { ResponseOption } from "./ResponseOption";

class ResponseForm extends UrbintModel<ResponseFormSerializer> {
  get id() {
    return this.castNumber(this.data.id);
  }

  get questions() {
    return this.castArray<Question>(
      this.data.questions.map((q: QuestionSerializer) => new Question(q))
    );
  }

  get hasMultiPRQuestion(): boolean {
    return (
      this.questions.find(
        (question) =>
          question.answerType === QuestionAnswerType.MULTI_PR_QUESTION
      ) !== undefined
    );
  }

  getResponseOptions(questionId: number, includeDeleted = false) {
    const allFormResponses = this.data.response_options_by_question_id;
    if (
      allFormResponses === undefined ||
      allFormResponses[questionId] === undefined ||
      (allFormResponses[questionId].is_deleted === true && !includeDeleted)
    ) {
      return this.castArray<ResponseOption>([]);
    }

    const filterFunction = (response: ResponseOptionSerializer) =>
      includeDeleted
        ? response.is_deleted === true
        : response.is_deleted === false;

    return this.castArray<ResponseOption>(
      allFormResponses[questionId]
        .filter(filterFunction)
        .map((r: ResponseOptionSerializer) => new ResponseOption(r))
    );
  }

  get name() {
    return this.castNumber(this.data.name);
  }

  compare(instance: ResponseForm): number {
    return this.id - instance.id;
  }

  serialize(): ResponseFormSerializer {
    return this.data;
  }

  static default(): ResponseForm {
    return new ResponseForm({
      id: 0,
      questions: [
        {
          id: 1,
          text: "Comments",
          answer_type: QuestionAnswerType.COMMENT_INPUT_FIELD,
          sequence: 1,
          required: false,
          response_form: 0,
        },
      ],
      response_options_by_question_id: {},
    });
  }
}

export { ResponseForm };
