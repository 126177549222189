import type { DialogFooterProps } from "./DialogFooter.types";
import { Button } from "@/common/Button";

const FooterWrapper = ({ children }: { children: React.ReactNode }) => (
  <footer className="flex flex-row gap-2 justify-end px-6 py-4 sm:rounded-b-lg h-[66px] shadow-10">
    {children}
  </footer>
);

const DialogFooter = ({ footer }: DialogFooterProps) => {
  if (!footer) return null;
  const {
    toRender,
    onClose,
    hasCancel = true,
    cancelButtonDisabled,
    cancelButtonTestId = "modal-cancel-action",
    submitButtonIsLoading,
    submitButtonDisabled,
    submitButtonTestId = "modal-action-button",
    onClick,
    submitButtontype = "primary",
    withDisabledTooltip,
    tooltip,
  } = footer;

  if (typeof toRender === "string") {
    const buttonType = onClick
      ? { onClick: (e: any) => onClick(e) }
      : { type: "submit" };
    return (
      <FooterWrapper>
        {hasCancel && (
          <Button
            type="button"
            onClick={onClose}
            variant="tertiary"
            disabled={cancelButtonDisabled}
            testId={cancelButtonTestId}
          >
            Cancel
          </Button>
        )}
        <Button
          testId={submitButtonTestId}
          {...buttonType}
          type="submit"
          variant={submitButtontype}
          disabled={submitButtonDisabled}
          loading={submitButtonIsLoading}
          withDisabledTooltip={withDisabledTooltip}
          tooltip={tooltip}
        >
          {toRender}
        </Button>
      </FooterWrapper>
    );
  }

  if (typeof toRender === "function") {
    return <FooterWrapper>{toRender()}</FooterWrapper>;
  }

  return null;
};

export { DialogFooter };
