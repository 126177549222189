import type { DamageThreatListProps } from "./DamageThreatList.types";
import { useState } from "react";
import isEqual from "lodash/isEqual";
import { ListView } from "@/common/ListView";
import { ThreatRank } from "@/models/DamageThreatInsight";
import { DamageThreatCard } from "../DamageThreatCard";
import { DAMAGE_THREAT_INSIGHT_TYPE } from "./DamageThreatList.constants";
import { DamageThreatInsightFeedbackSlider } from "./DamageThreatInsightFeedbackSlider";

const DamageThreatList = ({
  damageThreatList,
  setIsModalOpen,
}: DamageThreatListProps) => {
  const [gallerySlideIndex, setGallerySlideIndex] = useState<
    number | undefined
  >(undefined);

  const damageThreatListToShow = damageThreatList.filter(
    ({ insight }) => insight.insightType === DAMAGE_THREAT_INSIGHT_TYPE
  );

  const filteredDamageThreatListSlider = damageThreatListToShow.filter(
    (damageThreatItem) => damageThreatItem.threatRank !== ThreatRank.NA
  );

  return (
    <>
      <ListView>
        {damageThreatListToShow.map((ThreatRiskInsightCard) => (
          <DamageThreatCard
            key={`damage-threat-card-${ThreatRiskInsightCard.ticket}-${ThreatRiskInsightCard.id}`}
            data={ThreatRiskInsightCard}
            openDamageThreatSlider={() => {
              const indexPosition = filteredDamageThreatListSlider.findIndex(
                (filteredThreatItem) =>
                  filteredThreatItem.id === ThreatRiskInsightCard.id
              );

              setGallerySlideIndex(indexPosition);
              setIsModalOpen(false);
            }}
          />
        ))}
      </ListView>
      <DamageThreatInsightFeedbackSlider
        damageThreatList={filteredDamageThreatListSlider}
        gallerySlideIndex={gallerySlideIndex}
        setGallerySlideIndex={setGallerySlideIndex}
        closeSlider={() => {
          setGallerySlideIndex(undefined);
          setIsModalOpen(true);
        }}
      />
    </>
  );
};

export { DamageThreatList };
