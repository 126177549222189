import type { PropsWithChildren } from "react";
import React, { useState, useEffect, useContext } from "react";
import {
  PING_RESOURCE,
  RETRY_DELAY,
  MAX_RETRIES,
} from "./useOnlineStatus.constants";

const checkIfWeAreActuallyOnline = async () => {
  let online = false;

  // Keep trying to access Urbint /healthz until it's true
  while (!online) {
    try {
      console.debug(
        "Browser says that your Online but confirming with /healthz"
      );
      const response = await fetch(PING_RESOURCE);

      // Online status confirmed
      if (response.ok) {
        online = true;
      }
    } catch (error) {
      // Wait before making the next retry
      // eslint-disable-next-line no-await-in-loop, no-promise-executor-return
      await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
    }
  }

  return online;
};

const checkIfWeAreActuallyOffline = async () => {
  let offlineCount = 0;

  // Check navigator.onLine three times
  while (offlineCount < MAX_RETRIES) {
    if (navigator.onLine) {
      return true;
    }
    console.debug(
      `Browser says that your Offline but confirming it ${MAX_RETRIES} times`
    );
    offlineCount += 1;
    // Wait before checking again
    // eslint-disable-next-line no-await-in-loop, no-promise-executor-return
    await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
  }

  return false; // Considered offline after three consecutive checks
};

const OnlineStatusContext = React.createContext(true);

const OnlineStatusProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [onlineStatus, setOnlineStatus] = useState<boolean>(navigator.onLine);

  // Trigger Offline
  const offlineEventHandler = async () => {
    const online = await checkIfWeAreActuallyOffline();
    setOnlineStatus(online);
  };

  // Trigger Online
  const onlineEventHandler = async () => {
    const online = await checkIfWeAreActuallyOnline();
    setOnlineStatus(online);
  };

  useEffect(() => {
    window.addEventListener("offline", offlineEventHandler);
    window.addEventListener("online", onlineEventHandler);

    return () => {
      window.removeEventListener("offline", offlineEventHandler);
      window.removeEventListener("online", onlineEventHandler);
    };
  }, []);

  return (
    <OnlineStatusContext.Provider value={onlineStatus}>
      {children}
    </OnlineStatusContext.Provider>
  );
};

const useOnlineStatus = () => {
  const store = useContext(OnlineStatusContext);
  return store;
};

export { useOnlineStatus, OnlineStatusProvider };
