import { useMutation } from "@tanstack/react-query";

import { SavedView } from "@/models";
import { UrbintApi } from "@/utils/UrbintApi";
import { setSavedViewsData } from "@/api";

const api = new UrbintApi();

const createSavedView = async (view: Partial<SavedView>) => {
  const resp = await api.post("saved_views", JSON.stringify(view.serialize!()));
  if (resp.error) {
    throw resp.error;
  }
  return new SavedView(resp.json);
};

const useCreateSavedView = () =>
  useMutation(createSavedView, {
    onSuccess: (newView) => {
      setSavedViewsData((views) => [...(views ?? []), newView]);
    },
  });

// eslint-disable-next-line import/prefer-default-export
export { useCreateSavedView };
