import type { LayerProps } from "react-map-gl";
import servicePoint from "@/assets/images/servicePoint.png";
import splice from "@/assets/images/splice.png";
import light from "@/assets/images/light.png";
import networkVault from "@/assets/images/networkVault.png";
import manhole from "@/assets/images/manhole.png";
import regUnknown from "@/assets/images/reg_unknown.png";
import pole from "@/assets/images/pole.png";
import transmissionSubstation from "@/assets/images/transmissionSubstation.png";
import regTownBorder from "@/assets/images/reg_town_border.png";
import regCityGate from "@/assets/images/reg_city_gate.png";
import regDistrict from "@/assets/images/reg_district.png";
import regHpCustomer from "@/assets/images/reg_hp_customer.png";
import regLpCustomer from "@/assets/images/reg_lp_customer.png";
import valve from "@/assets/images/valve.png";
import accessPoint from "@/assets/images/accessPoint.png";
import building from "@/assets/images/building.png";
import damageWithTicketIcon from "@/assets/images/damage_with_ticket.png";
import damageIcon from "@/assets/images/damage.png";
import fuse from "@/assets/images/fuse.png";
import pedestal from "@/assets/images/pedestal.png";
import elecSwitch from "@/assets/images/elecSwitch.png";
import surfaceStructure from "@/assets/images/surfaceStructure.png";
import distributionSubstation from "@/assets/images/distributionSubstation.png";
import transformer from "@/assets/images/transformer.png";
import pullBox from "@/assets/images/pullBox.png";
import spliceBox from "@/assets/images/spliceBox.png";
import distributionVault from "@/assets/images/distributionVault.png";

const GENERAL_UNKNOWN_COLOR = "#454545";

/*
  GAS
*/
const TRANSMISSION_LINES_COLOR = "rgb(232, 171, 115)";
const MAINS_COLORS = {
  HIGH: "rgb(216, 70, 70)",
  MEDIUM: "rgb(59, 130, 246)",
  LOW: "rgb(22, 163, 74)",
  UNKNOWN: "rgb(77, 98, 104)",
};
const SERVICE_COLORS = {
  HIGH: "rgb(216, 70, 70)",
  MEDIUM: "rgb(59, 130, 246)",
  LOW: "rgb(22, 163, 74)",
  UNKNOWN: "rgb(49, 63, 67)",
};
const ELEC_DIST_COLORS = {
  OH_PRIMARY: "#238914",
  OH_SECONDARY: "#A327A5",
  UG_PRIMARY: "#D44242",
  UG_SECONDARY: "#A82424",
  CONDUIT: "#753E0A",
  ABANDONED_LINE: "#A0AAAC",
  UNKNOWN: GENERAL_UNKNOWN_COLOR,
};
/*
  ELECTRIC TRANSMISSION
*/
const ELEC_TRANS_COLORS = {
  COND_SPAN_OH: "#97B4ED",
  COND_SPAN_UG: "#E78D8D",
};
/*
  FIBER
*/
const FIBER_COLORS = {
  OH_CABLE: "#226DC3",
  UG_CABLE: "#E8AB73",
  ACCESS_POINT: "#E9A9B4",
  BUILDING: "#7A9974",
  ACCESS_POINT_BORDER: "#E9A9B4",
  BUILDING_BORDER: "#7A9974",
  UNKNOWN: GENERAL_UNKNOWN_COLOR,
};
/*
  NETWORK
*/
const NETWORK_COLORS = {
  SECONDARY_SEGMENT: "#000000",
  CIRCUIT_SEGMENT: "#1C7D72",
  COAXIAL: "#FF9C00",
  DUCTBANK: "#1C7D72",
  UNKNOWN: GENERAL_UNKNOWN_COLOR,
};
/*
  TELCO
*/
const TELCO_COLORS = {
  CABLE: "#E8AB73",
  CONDUIT: "#753E0A",
  PRIORITY_1: "#A82D2D",
  PRIORITY_2: "#A56D38",
  PRIORITY_3: "#38766F",
  PRIORITY_4: "#3D4C99",
  PRIORITY_1_BORDER: "#A82424",
  PRIORITY_2_BORDER: "#A55F1D",
  PRIORITY_3_BORDER: "#1C7D72",
  PRIORITY_4_BORDER: "#4C65D5",
  UNKNOWN: GENERAL_UNKNOWN_COLOR,
};

const TRANSMISSIONS_LAYER_PROPS: LayerProps = {
  id: "transmission_lines",
  type: "line",
  "source-layer": "transmission_lines",
  paint: {
    "line-width": ["interpolate", ["linear"], ["zoom"], 12, 3, 16, 6],
    "line-opacity": 0.8,
    "line-color": TRANSMISSION_LINES_COLOR,
  },
  layout: {},
};
const MAINS_LAYER_PROPS: LayerProps = {
  id: "mains",
  type: "line",
  "source-layer": "mains",
  paint: {
    "line-width": ["interpolate", ["linear"], ["zoom"], 12, 1, 16, 3],
    "line-opacity": 0.8,
    "line-color": [
      "match",
      ["get", "operating_pressure_category"],
      "high",
      MAINS_COLORS.HIGH,
      "medium",
      MAINS_COLORS.MEDIUM,
      "low",
      MAINS_COLORS.LOW,
      MAINS_COLORS.UNKNOWN,
    ],
  },
  layout: {},
};
const SERVICES_LAYER_PROPS: LayerProps = {
  id: "services",
  type: "line",
  "source-layer": "services",
  paint: {
    "line-width": ["interpolate", ["linear"], ["zoom"], 15, 1, 16, 1.5],
    "line-opacity": 0.8,
    "line-color": [
      "match",
      ["get", "operating_pressure_category"],
      "high",
      SERVICE_COLORS.HIGH,
      "medium",
      SERVICE_COLORS.MEDIUM,
      "low",
      SERVICE_COLORS.LOW,
      SERVICE_COLORS.UNKNOWN,
    ],
    "line-dasharray": [3, 1],
  },
  layout: {},
};
const REGULATOR_STATIONS_LAYER_PROPS: LayerProps = {
  id: "regulator_stations",
  type: "symbol",
  "source-layer": "regulator_stations",
  paint: {},
  layout: {
    "icon-size": ["interpolate", ["linear"], ["zoom"], 10, 0.3, 16, 1.0],
    "icon-image": [
      "match",
      ["get", "station_type"],
      "Town Border",
      regTownBorder,
      "City Gate",
      regCityGate,
      "District",
      regDistrict,
      "HP Customer",
      regHpCustomer,
      "LP Customer",
      regLpCustomer,
      regUnknown,
    ],
  },
};
const VALVES_LAYER_PROPS: LayerProps = {
  id: "valves",
  type: "symbol",
  "source-layer": "valves",
  paint: {},
  layout: {
    "icon-size": ["interpolate", ["linear"], ["zoom"], 15, 0.5, 16, 1.0],
    "icon-image": valve,
  },
};
const ELECTRIC_DISTRIBUTION_LINE_LAYER_PROPS: LayerProps = {
  id: "electric_distribution_line",
  type: "line",
  "source-layer": "electric_distribution_line",
  paint: {
    "line-width": [
      "match",
      ["get", "type"],
      "primary",
      2.5,
      "secondary",
      1.5,
      "conduit",
      2.5,
      "abandoned_line",
      2.5,
      2.5,
    ],
    "line-opacity": 0.8,
    "line-color": [
      "match",
      ["get", "type"],
      "primary",
      [
        "case",
        ["==", ["get", "underground"], false],
        ELEC_DIST_COLORS.OH_PRIMARY,
        ELEC_DIST_COLORS.UG_PRIMARY,
      ],
      "secondary",
      [
        "case",
        ["==", ["get", "underground"], false],
        ELEC_DIST_COLORS.OH_SECONDARY,
        ELEC_DIST_COLORS.UG_SECONDARY,
      ],
      "conduit",
      ELEC_DIST_COLORS.CONDUIT,
      "abandoned_line",
      ELEC_DIST_COLORS.ABANDONED_LINE,
      ELEC_DIST_COLORS.UNKNOWN,
    ],
    "line-dasharray": [
      "match",
      ["get", "phase"],
      1,
      ["literal", [2, 1]],
      2,
      ["literal", [4, 4]],
      3,
      ["literal", [1]],
      ["literal", [1]],
    ],
  },
  layout: {},
};
const ELECTRIC_DISTRIBUTION_STRUCTURE_LAYER_PROPS: LayerProps = {
  id: "electric_distribution_structure",
  type: "symbol",
  "source-layer": "electric_distribution_structure",
  paint: {},
  layout: {
    "icon-size": 0.5,
    "icon-image": [
      "match",
      ["get", "type"],
      "fuse",
      fuse,
      "light",
      light,
      "pedestal",
      pedestal,
      "pole",
      pole,
      "switch",
      elecSwitch,
      "surface_structure",
      surfaceStructure,
      "substation",
      distributionSubstation,
      "transformer",
      transformer,
      "manhole",
      manhole,
      "pull_box",
      pullBox,
      "splice_box",
      spliceBox,
      "vault",
      distributionVault,
      regUnknown,
    ],
  },
};
const ELECTRIC_TRANSMISSION_LINE_LAYER_PROPS: LayerProps = {
  id: "electric_transmission_line",
  type: "line",
  "source-layer": "electric_transmission_line",
  paint: {
    "line-width": 4,
    "line-opacity": 0.8,
    "line-color": [
      "case",
      ["==", ["get", "underground"], false],
      ELEC_TRANS_COLORS.COND_SPAN_OH,
      ELEC_TRANS_COLORS.COND_SPAN_UG,
    ],
  },
  layout: {},
};
const ELECTRIC_TRANSMISSION_STRUCTURE_LAYER_PROPS: LayerProps = {
  id: "electric_transmission_structure",
  type: "symbol",
  "source-layer": "electric_transmission_structure",
  paint: {},
  layout: {
    "icon-size": 0.5,
    "icon-image": [
      "match",
      ["get", "type"],
      "pole",
      pole,
      "substation",
      transmissionSubstation,
      regUnknown,
    ],
  },
};
const FIBER_LINE_LAYER_PROPS: LayerProps = {
  id: "fiber_line",
  type: "line",
  "source-layer": "fiber_line",
  paint: {
    "line-width": ["interpolate", ["linear"], ["zoom"], 12, 2.5, 16, 5],
    "line-opacity": 0.8,
    "line-color": [
      "case",
      ["==", ["get", "underground"], false],
      FIBER_COLORS.OH_CABLE,
      FIBER_COLORS.UG_CABLE,
    ],
  },
  layout: {},
};
const FIBER_STRUCTURE_LAYER_PROPS: LayerProps = {
  id: "fiber_structure",
  type: "symbol",
  "source-layer": "fiber_structure",
  paint: {},
  layout: {
    "icon-size": 0.5,
    "icon-image": [
      "match",
      ["get", "type"],
      "splice",
      splice,
      "pole",
      pole,
      "access_point",
      accessPoint,
      "building",
      building,
      regUnknown,
    ],
  },
};
const FIBER_POLYGON_LAYER_PROPS: LayerProps = {
  id: "fiber_polygon",
  type: "fill",
  "source-layer": "fiber_polygon",
  paint: {
    "fill-opacity": 0.3,
    "fill-color": [
      "match",
      ["get", "type"],
      "access_point",
      FIBER_COLORS.ACCESS_POINT,
      "building",
      FIBER_COLORS.BUILDING,
      FIBER_COLORS.UNKNOWN,
    ],
  },
  layout: {},
};
const FIBER_POLYGON_OUTLINE_LAYER_PROPS: LayerProps = {
  id: "fiber_polygon_outline",
  type: "line",
  "source-layer": "fiber_polygon",
  paint: {
    "line-width": ["interpolate", ["linear"], ["zoom"], 12, 1, 16, 3],
    "line-opacity": 0.8,
    "line-color": [
      "match",
      ["get", "type"],
      "access_point",
      FIBER_COLORS.ACCESS_POINT_BORDER,
      "building",
      FIBER_COLORS.BUILDING_BORDER,
      FIBER_COLORS.UNKNOWN,
    ],
  },
  layout: {},
};
const NETWORK_LINE_LAYER_PROPS: LayerProps = {
  id: "network_line",
  type: "line",
  "source-layer": "network_line",
  paint: {
    "line-width": [
      "match",
      ["get", "type"],
      "circuit_segment",
      2.5,
      "coaxial",
      2.5,
      "secondary_segment",
      2.5,
      "ductbank",
      4,
      2.5,
    ],
    "line-opacity": 0.8,
    "line-color": [
      "match",
      ["get", "type"],
      "ductbank",
      NETWORK_COLORS.DUCTBANK,
      "circuit_segment",
      NETWORK_COLORS.CIRCUIT_SEGMENT,
      "secondary_segment",
      NETWORK_COLORS.SECONDARY_SEGMENT,
      "coaxial",
      NETWORK_COLORS.COAXIAL,
      NETWORK_COLORS.UNKNOWN,
    ],
  },
  layout: {},
};
const NETWORK_STRUCTURE_LAYER_PROPS: LayerProps = {
  id: "network_structure",
  type: "symbol",
  "source-layer": "network_structure",
  paint: {},
  layout: {
    "icon-size": 0.5,
    "icon-image": [
      "match",
      ["get", "type"],
      "service_point",
      servicePoint,
      "splice",
      splice,
      "light",
      light,
      "vault",
      networkVault,
      "manhole",
      manhole,
      regUnknown,
    ],
  },
};
const TELCO_LINE_LAYER_PROPS: LayerProps = {
  id: "telco_line",
  type: "line",
  "source-layer": "telco_line",
  paint: {
    "line-width": ["interpolate", ["linear"], ["zoom"], 12, 2.5, 16, 5],
    "line-opacity": 0.8,
    "line-color": [
      "match",
      ["get", "type"],
      "cable",
      TELCO_COLORS.CABLE,
      "conduit",
      TELCO_COLORS.CONDUIT,
      TELCO_COLORS.UNKNOWN,
    ],
  },
  layout: {},
};
const TELCO_POLYGON_LAYER_PROPS: LayerProps = {
  id: "telco_polygon",
  type: "fill",
  "source-layer": "telco_polygon",
  paint: {
    "fill-opacity": 0.35,
    "fill-color": [
      "match",
      ["get", "priority"],
      1,
      TELCO_COLORS.PRIORITY_1,
      2,
      TELCO_COLORS.PRIORITY_2,
      3,
      TELCO_COLORS.PRIORITY_3,
      4,
      TELCO_COLORS.PRIORITY_4,
      TELCO_COLORS.UNKNOWN,
    ],
  },
  layout: {},
};
const TELCO_POLYGON_OUTLINE_LAYER_PROPS: LayerProps = {
  id: "telco_polygon_outline",
  type: "line",
  "source-layer": "telco_polygon",
  paint: {
    "line-width": ["interpolate", ["linear"], ["zoom"], 12, 1, 16, 3],
    "line-opacity": 0.8,
    "line-color": [
      "match",
      ["get", "priority"],
      1,
      TELCO_COLORS.PRIORITY_1_BORDER,
      2,
      TELCO_COLORS.PRIORITY_2_BORDER,
      3,
      TELCO_COLORS.PRIORITY_3_BORDER,
      4,
      TELCO_COLORS.PRIORITY_4_BORDER,
      TELCO_COLORS.UNKNOWN,
    ],
  },
  layout: {},
};
/*
  DAMAGES
*/
const DAMAGES_LAYER_PROPS: LayerProps = {
  id: "damages",
  type: "symbol",
  "source-layer": "damages",
  paint: {},
  layout: {
    "icon-size": ["interpolate", ["linear"], ["zoom"], 15, 0.5, 16, 1.0],
    "icon-image": [
      "case",
      ["to-boolean", ["get", "ticket_id"]],
      damageWithTicketIcon,
      damageIcon,
    ],
  },
};
const ANNOTATIONS_ELEC_DIST_LAYER_PROPS: LayerProps = {
  id: "gis_annotations_elec_dist",
  type: "symbol",
  "source-layer": "gis_annotations",
  paint: {},
  layout: {
    "text-font": ["Roobert Regular", "Roobert Medium", "Open Sans Regular"],
    "text-size": 14,
    "text-optional": true,
    "text-offset": [0, 1.3],
    "text-field": [
      "match",
      ["get", "source_table"],
      "primary_OH_anno",
      ["get", "text"],
      "primary_UG_anno",
      ["get", "text"],
      "secondary_OH_anno",
      ["get", "text"],
      "secondary_UG_anno",
      ["get", "text"],
      "switch_anno",
      ["get", "text"],
      "",
    ],
  },
};
const ANNOTATIONS_NETWORK_LAYER_PROPS: LayerProps = {
  id: "gis_annotations_network",
  type: "symbol",
  "source-layer": "gis_annotations",
  paint: {},
  layout: {
    "text-font": ["Roobert Regular", "Roobert Medium", "Open Sans Regular"],
    "text-size": 14,
    "text-optional": true,
    "text-offset": [0, 1.3],
    "text-field": [
      "match",
      ["get", "source_table"],
      "network_manhole_anno",
      ["get", "text"],
      "network_vault_anno",
      ["get", "text"],
      "network_ductbank_anno",
      ["get", "text"],
      "",
    ],
  },
};
export {
  ANNOTATIONS_ELEC_DIST_LAYER_PROPS,
  ANNOTATIONS_NETWORK_LAYER_PROPS,
  DAMAGES_LAYER_PROPS,
  ELECTRIC_DISTRIBUTION_LINE_LAYER_PROPS,
  ELECTRIC_DISTRIBUTION_STRUCTURE_LAYER_PROPS,
  ELECTRIC_TRANSMISSION_LINE_LAYER_PROPS,
  ELECTRIC_TRANSMISSION_STRUCTURE_LAYER_PROPS,
  FIBER_LINE_LAYER_PROPS,
  FIBER_POLYGON_LAYER_PROPS,
  FIBER_POLYGON_OUTLINE_LAYER_PROPS,
  FIBER_STRUCTURE_LAYER_PROPS,
  MAINS_LAYER_PROPS,
  NETWORK_LINE_LAYER_PROPS,
  NETWORK_STRUCTURE_LAYER_PROPS,
  REGULATOR_STATIONS_LAYER_PROPS,
  SERVICES_LAYER_PROPS,
  TELCO_LINE_LAYER_PROPS,
  TELCO_POLYGON_LAYER_PROPS,
  TELCO_POLYGON_OUTLINE_LAYER_PROPS,
  TRANSMISSIONS_LAYER_PROPS,
  VALVES_LAYER_PROPS,
  ELEC_DIST_COLORS,
  ELEC_TRANS_COLORS,
  FIBER_COLORS,
  MAINS_COLORS,
  NETWORK_COLORS,
  SERVICE_COLORS,
  TELCO_COLORS,
  TRANSMISSION_LINES_COLOR,
};
