import type { EmptyStateProps } from "./EmptyState.types";
import { Icon } from "@urbint/silica";
import { EmptyStateContainer } from "@/components/TicketQueue/EmptyStateContainer";

const EmptyState = ({ type }: EmptyStateProps) => {
  switch (type) {
    case "empty":
      return (
        <EmptyStateContainer testId="no-tickets-in-queue-state">
          No tickets in queue.
        </EmptyStateContainer>
      );
    case "failedFetch":
      return (
        <EmptyStateContainer mode="error">
          <div className="flex flex-row items-center">
            <div className="text-white bg-system-error-30 rounded px-1">
              <Icon name="error" />
            </div>
            <p className="px-2.5">
              We have failed to fetch the tickets in your queue. Please try
              again shortly.
            </p>
          </div>
        </EmptyStateContainer>
      );
    case "complete":
      return (
        <EmptyStateContainer testId="queue-done">
          <span className="text-2xl">🎉</span>
          <p>{`You're all done!`}</p>
        </EmptyStateContainer>
      );
  }
};

export { EmptyState };
