import type { DamagePreventionAppProps } from "./DamagePreventionApp.types";
import { IonApp } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { datadogRum } from "@datadog/browser-rum";
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";
import { UrbintAuth } from "@urbint/auth-client";
import { StrictMode } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { queryClient, createIDBPersister } from "@/api/client";
import { useOnlineStatus } from "@/hooks";
import AppCrashPage from "@/pages/AppCrashPage";
import "../../tailwind.css";
import { appMetadata } from "@/utils/appMetadata";
import { decorateCurrentUser } from "@/utils/decorateCurrentUser";
import {
  tasksGroupedByWorkflowBaseIdent,
  ticketPointsIdentKey,
  notificationsIdent,
} from "@/api";
import { Toasts } from "../Toasts/Toasts";

/* Theme variables */
import "./theme.css";

const { clientId, realm, env } = appMetadata();

let sampleRate = 100;
let replaySampleRate = 100;

// a little "magic" to determine the keycloak url per environment
// default to production
let authServerUrl: URL;

switch (env) {
  case "development":
    authServerUrl = new URL("http://auth.local.urbinternal.com:8080/auth");
    sampleRate = 0;
    replaySampleRate = 0;
    break;

  case "integration":
    authServerUrl = new URL("https://auth.integ.urbinternal.com/auth");
    break;

  case "staging":
    authServerUrl = new URL("https://auth.urbinternal.com/auth");
    break;

  case "production":
  default:
    authServerUrl = new URL("https://auth.urbint.com/auth");
}

// when running feature tests, we hard-code the mock server endpoint
if (realm === "test-realm") {
  authServerUrl = new URL("http://localhost:59999");
}

function handleAppCrash(error: Error, info: { componentStack: string }) {
  // TODO: send error to datadog
  console.error(error);
  console.error(info.componentStack);
}

/**
 * Try to do as much cleanup as possible before the app remounts.
 * This is intended to be a little heavy handed because the priority at this
 * point is getting the user back into a working state.
 */
function onAppCrashReset() {
  window.localStorage.clear();
  window.sessionStorage.clear();
}

datadogRum.init({
  applicationId: "3e66b6a4-b66d-4136-8bd2-9bc5b2c0436d",
  clientToken: "pubad76d5a359b9ea6a4bf7f129c4feecfa",
  site: "datadoghq.com",
  service: "damage-prevention",
  env,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sampleRate,
  replaySampleRate,
  trackInteractions: true,
  defaultPrivacyLevel: "mask-user-input",
});

datadogRum.startSessionReplayRecording();

/**
 * Responsible for application-wide initializations:
 *  * ionic css and theme overrides
 *  * `ErrorBoundary` setup (for handling all uncaught errors)
 *  * `UrbintAuth`
 *  * `QueryClientProvider` initialization.
 */
const DamagePreventionApp = ({ children }: DamagePreventionAppProps) => {
  const isOnline = useOnlineStatus();

  return (
    <StrictMode>
      <ErrorBoundary
        FallbackComponent={AppCrashPage}
        onError={handleAppCrash}
        onReset={onAppCrashReset}
      >
        <UrbintAuth
          currentUserDecorator={decorateCurrentUser}
          onBeforeLogout={() => queryClient.clear()}
          oidcClientId={clientId}
          oidcRealm={realm}
          oidcServerUrl={authServerUrl}
          isOnline={isOnline}
        >
          <IonApp>
            <IonReactRouter>
              <PersistQueryClientProvider
                client={queryClient}
                persistOptions={{
                  persister: createIDBPersister(isOnline),
                  dehydrateOptions: {
                    shouldDehydrateQuery: (query) => {
                      const queryIsReadyForPersistance =
                        query.state.status === "success";
                      if (queryIsReadyForPersistance) {
                        const { queryKey } = query;

                        const excludeFromPersisting = [
                          tasksGroupedByWorkflowBaseIdent,
                          ticketPointsIdentKey,
                          notificationsIdent,
                        ].some((substring) => queryKey.includes(substring));

                        return !excludeFromPersisting;
                      }
                      return queryIsReadyForPersistance;
                    },
                  },
                }}
                onSuccess={
                  // resume mutations after successful initial restore from local db
                  () => queryClient.resumePausedMutations()
                }
              >
                <Toasts>{children}</Toasts>
              </PersistQueryClientProvider>
            </IonReactRouter>
          </IonApp>
        </UrbintAuth>
      </ErrorBoundary>
    </StrictMode>
  );
};

export { DamagePreventionApp };
