import "./Notifications.css";
import type { NavBarViewProps } from "./NavBarView.types";
import type { Opco } from "@/models";
import { useState } from "react";
import cx from "classnames";
import {
  IonHeader,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonList,
  IonSkeletonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Logo } from "@/common/Logo";
import { Avatar } from "@/common/Avatar";
import { MenuPanel } from "@/components/MenuPanel";
import { useFullScreen } from "@/hooks/useFullScreen";
import { NotificationIcon } from "@/components/NotificationIcon";
import { useNotifications, useOpcos } from "@/api";
import { useDamagePreventionAuth, useOnlineStatus } from "@/hooks";
import { NotificationsPanel } from "@/components/NotificationsPanel";
import { TicketQueueNavBarItem } from "../TicketQueueNavBarItem";
import { NavBarItems } from "../NavBarItems";
import { unreadCount } from "./NavBarView.utils";

const NavBarView = ({
  publicConfig,
  location,
  auth,
  queue,
  optimizeQueue,
  ...ionHeaderProps
}: NavBarViewProps) => {
  const { isFullscreen } = useFullScreen();
  const isOnline = useOnlineStatus();
  const { pathname } = location;
  const [showNotificationsMenu, setShowNotificationsMenu] = useState(false);
  const { currentUser } = useDamagePreventionAuth();
  const { data: notifications = [] } = useNotifications();
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const { data: opcos, isInitialLoading: areOpcosLoading } = useOpcos();
  const opco = opcos?.filter((x: Opco) => x.current).find(() => true);
  const tabButtonClasses = (isPageMatch: boolean) => {
    const isActiveClasses = isPageMatch
      ? "border-b-brand-40 text-white"
      : "border-b-transparent text-gray-300";
    const baseClasses =
      "max-w-min border-b-4 text-base font-semibold border-style-inset bg-transparent active:hover:after:selection:text-white";
    return cx(isActiveClasses, baseClasses);
  };

  const navBarTabs = publicConfig?.displayEventsTab
    ? [
        {
          route: "/",
          label: "Tickets",
          tab: "tickets",
        },
        { route: "/events", label: "Events", tab: "events" },
      ]
    : [];

  return (
    <IonHeader {...ionHeaderProps} hidden={isFullscreen}>
      <IonToolbar>
        {/* Urbint logo */}
        <IonItem
          data-testid="urbint-navbar-home-btn"
          button
          detail={false}
          slot="start"
          lines="none"
          routerLink="/"
          routerDirection="back"
          style={{ fontSize: "2.5rem" }}
        >
          <Logo mode="icon" />
        </IonItem>
        {/* App name + tenant */}
        <IonItemGroup slot="start">
          <IonTitle data-testid="urbint-navbar-title">Urbint Lens</IonTitle>
          <IonTitle data-testid="urbint-navbar-subtitle" size="small">
            {opco?.name}
            <IonSkeletonText
              animated
              hidden={!areOpcosLoading}
              style={{ width: "8rem" }}
            />
          </IonTitle>
        </IonItemGroup>

        {isOnline && (
          <>
            {/* Tickets + Events tabs */}
            <IonList className="flex flex-row py-0" slot="start">
              {navBarTabs.map(({ route, label, tab }) => (
                <IonItem
                  key={`navbar-${tab}-tab`}
                  detail={false}
                  button
                  lines="none"
                  routerLink={route}
                  data-testid={`urbint-navbar-${tab}-tab-btn`}
                  className={tabButtonClasses(pathname === route)}
                >
                  <IonLabel>{label}</IonLabel>
                </IonItem>
              ))}
            </IonList>
            {/* Ticket queue */}
            <TicketQueueNavBarItem
              queue={queue}
              optimizeQueue={optimizeQueue}
              currentUser={auth.currentUser}
            />
            <MenuPanel
              arrow
              data-testid="urbint-navbar-notification-menu"
              onClickOutside={() => setShowNotificationsMenu(false)}
              onClick={() => setShowNotificationsMenu(false)}
              placement="bottom-end"
              show={showNotificationsMenu}
              renderButton={() => (
                <IonItem
                  button
                  data-testid="urbint-navbar-notification-menu-btn"
                  detail={false}
                  lines="none"
                  slot="end"
                  onClick={() =>
                    setShowNotificationsMenu(!showNotificationsMenu)
                  }
                >
                  <NotificationIcon
                    unreadCount={unreadCount(notifications)}
                    isMobile={false}
                  />
                </IonItem>
              )}
            >
              <NotificationsPanel
                currentUser={currentUser}
                notifications={notifications}
              />
            </MenuPanel>
          </>
        )}
        {/* Profile icon menu */}
        <MenuPanel
          arrow
          data-testid="urbint-navbar-profile-menu"
          onClickOutside={() => setShowProfileMenu(false)}
          onClick={() => setShowProfileMenu(false)}
          placement="bottom-end"
          show={showProfileMenu}
          disabled={!isOnline}
          renderButton={() => (
            <IonItem
              button
              data-testid="urbint-navbar-profile-menu-btn"
              detail={false}
              lines="none"
              onClick={() => setShowProfileMenu(!showProfileMenu)}
              slot="end"
            >
              <Avatar
                user={currentUser}
                showTooltip={!isOnline}
                tooltipPlaceholder={
                  isOnline ? "" : "Profile settings are disabled while offline"
                }
                tooltipOrigin="right"
                size="lg"
              />
            </IonItem>
          )}
        >
          <NavBarItems auth={auth} onClick={() => setShowProfileMenu(false)} />
        </MenuPanel>
      </IonToolbar>
    </IonHeader>
  );
};
export { NavBarView };
