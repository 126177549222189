import { useMutation } from "@tanstack/react-query";

import { UrbintApi } from "@/utils/UrbintApi";
import {
  invalidateUseFlexTicketQuery,
  invalidateUseFlexTicketsQuery,
  invalidateUseTicketActivityQuery,
  invalidateUseTasksForTicketQuery,
  invalidateUseTicketPointsQuery,
} from "@/api";

const api = new UrbintApi();

const bulkCreateTasks = (body: BulkCreateTaskPayload[]) =>
  api.post("tasks/bulk", JSON.stringify(body));

interface BulkCreateTaskPayload {
  ticket: number;
  task_type: number;
  assignee: number;
}

const useBulkCreateTasks = () =>
  useMutation(bulkCreateTasks, {
    onSuccess: (_, tasks) => {
      tasks.forEach((t) => {
        invalidateUseTasksForTicketQuery(t.ticket);
        invalidateUseTicketActivityQuery(t.ticket);
        invalidateUseFlexTicketQuery(t.ticket);
      });
      invalidateUseFlexTicketsQuery();
      invalidateUseTicketPointsQuery();
    },
  });

export { useBulkCreateTasks };
