import type { CommentsAndActivitySectionProps } from "./CommentsAndActivitySection.types";
import type { FormEvent } from "react";
import { useState, useCallback } from "react";
import { useAddCommentToTask } from "@/api";
import { useToasts } from "@/components/Toasts";
import { AutoGrowingTextArea } from "@/components/AutoGrowingTextArea";
import { useDamagePreventionAuth, useOnlineStatus } from "@/hooks";

import { Avatar } from "@/common/Avatar";
import { ActivityList } from "@/pages/TicketPage/Activities/ActivityList";
import { TaskDetailSection } from "../../../TaskDetail/TaskDetailSection";
import { TaskDetailSectionHeader } from "../../../TaskDetail/TaskDetailSectionHeader";
import { CommentsAndActivitySectionOffline } from "./CommentsAndActivitySectionOffline";

const CommentsAndActivitySection = ({
  ticket,
  groupedActivities,
  taskId,
  onPhotoClick,
}: CommentsAndActivitySectionProps) => {
  const { currentUser } = useDamagePreventionAuth();
  const [commentText, setCommentText] = useState("");
  const canComment = currentUser.canAddTaskComment;
  const hasComment = commentText !== "";
  const { addToast } = useToasts();
  let translateClass = "";
  if (!canComment) {
    translateClass = "-translate-y-6";
  } else if (!hasComment && canComment) {
    translateClass = "-translate-y-8";
  }
  const addCommentToTask = useAddCommentToTask();
  const isOnline = useOnlineStatus();

  const submitHandler = useCallback(
    async (e?: FormEvent) => {
      if (!commentText) return;
      e?.preventDefault();
      try {
        await addCommentToTask.mutateAsync({
          creator: String(currentUser.djangoId),
          // TODO: Fix no-non-null-asserted-optional-chain
          task: taskId!,
          // TODO: Fix no-non-null-asserted-optional-chain
          ticket: ticket?.id!,
          comment: commentText,
        });
        addToast("Successfully created comment.", "success");
        setCommentText("");
      } catch (err) {
        if (err instanceof Error) {
          addToast(`Error creating comment: ${err.message}`, "error");
        } else {
          addToast(`Error creating comment`, "error");
        }
      }
    },
    [
      setCommentText,
      addToast,
      commentText,
      addCommentToTask,
      ticket?.id,
      taskId,
      currentUser.djangoId,
    ]
  );

  if (!isOnline) {
    return <CommentsAndActivitySectionOffline />;
  }

  return (
    <TaskDetailSection>
      <TaskDetailSectionHeader>Comments and activity</TaskDetailSectionHeader>
      <div className="isolate">
        <form
          className={`${canComment ? "" : "hidden"}`}
          onSubmit={submitHandler}
        >
          <div className="flex md:ml-1 items-start">
            <div className="mt-1.5">
              <Avatar user={currentUser} />
            </div>
            <AutoGrowingTextArea
              testId="task-comment-input"
              value={commentText}
              onCtrlOrMetaEnter={submitHandler}
              onChange={setCommentText}
              className="max-h-32 ml-3"
              placeholder="Add a comment..."
            />
          </div>
          <div className="flex mt-3 space-x-4">
            <button
              type="button"
              className="text-neutral-shade-secondary font-sm font-semibold ml-auto"
              onClick={() => setCommentText("")}
              disabled={addCommentToTask.isLoading}
            >
              Cancel
            </button>
            <button
              disabled={addCommentToTask.isLoading}
              data-testid="task-comment-submit"
              type="submit"
              className={`${
                addCommentToTask.isLoading
                  ? "bg-gray-300 cursor-normal"
                  : "bg-brand-40"
              } text-white font-sm font-semibold px-2 py-1 leading-tight rounded shadow-5`}
            >
              Save comment
            </button>
          </div>
        </form>
        <div
          className={`relative pt-6 z-10 transform-gpu transition duration-200 ${translateClass} bg-white min-h-[36px]`}
        >
          <ActivityList
            ticket={ticket}
            groupedActivities={groupedActivities}
            onPhotoClick={onPhotoClick}
          />
        </div>
      </div>
    </TaskDetailSection>
  );
};

export { CommentsAndActivitySection };
