import type { ActivityGroupListItemProps } from "./ActivityGroupListItem.types";
import { useUser } from "@/api";
import { Avatar } from "@/common/Avatar";

import { formatFullDateTime } from "@/format";
import { LazyLoad } from "@/components/LazyLoad";
import { ActivityListItem } from "./ActivityListItem/ActivityListItem";

const ActivityGroupListItem = ({
  ticket,
  activities,
  onPhotoClick,
  wrapperClass,
}: ActivityGroupListItemProps) => {
  const firstActivity = activities[0]!;
  const hasUser = firstActivity.userId !== undefined;
  const { data: user } = useUser(firstActivity.userId!, { enabled: hasUser });
  return (
    <li className={wrapperClass}>
      <header className="flex items-center">
        {hasUser ? <Avatar user={user} /> : <Avatar urbint />}
        <h2 className="text-black text-opacity-88 font-semibold ml-2 text-sm overflow-hidden whitespace-nowrap overflow-ellipsis">
          {hasUser ? user?.displayName : "Urbint"}
        </h2>
        <p className="text-sm text-black text-opacity-66 leading-tight ml-auto flex-shrink-0">
          {formatFullDateTime(firstActivity.date)}
        </p>
      </header>
      <ul className="text-blue-900 text-sm leading-tight tracking-tight italic mt-1 ml-2.5 pl-4 border-l border-solid space-y-2">
        {ticket &&
          activities.map((activity) => (
            <LazyLoad key={activity.id}>
              <ActivityListItem
                ticket={ticket}
                onPhotoClick={onPhotoClick}
                activity={activity}
              />
            </LazyLoad>
          ))}
      </ul>
    </li>
  );
};

export { ActivityGroupListItem };
