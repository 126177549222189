import { useMap } from "react-map-gl";
import { MapControl } from "../MapControl";

const MapZoomControls = () => {
  const { current: map } = useMap();

  if (!map) return null;

  return (
    <>
      <MapControl
        onClick={() => map.zoomIn()}
        icon="plus"
        dataTestId="zoom-in-button"
      />
      <MapControl
        onClick={() => map.zoomOut()}
        icon="minus"
        dataTestId="zoom-out-button"
      />
    </>
  );
};

export { MapZoomControls };
