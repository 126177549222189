import cx from "classnames";
import { useState } from "react";
import { RiskSummary } from "./RiskSummary";
import { TopSummaryCard } from "./TopSummaryCard";

const SummaryCard = () => {
  const [isSectionVisible, setIsSectionVisible] = useState(true);

  return (
    <div className={cx("shadow-elevation-20 w-full")}>
      <TopSummaryCard
        isSectionVisible={isSectionVisible}
        toggleSectionVisibility={() => {
          setIsSectionVisible(!isSectionVisible);
        }}
      />
      {isSectionVisible && <RiskSummary />}
    </div>
  );
};

export { SummaryCard };
