import type { MobileHeaderProps } from "./MobileHeader.types";
import cx from "classnames";
import { IonIcon } from "@ionic/react";
import { arrowBackOutline } from "ionicons/icons";
import { useHistory } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { MobileHeaderTabsAndAnchorsNames } from "@/pages/TicketPage/TicketPage.constants";
import { browserScrollToId } from "@/utils";
import { ACTIVE_NAV } from "./MobileHeader.constants";

const MobileHeader = ({ ticket }: MobileHeaderProps) => {
  const [highlightedTab, setHighlightedTab] = useState<string | null>(null);
  const [letMePass, setLetMePass] = useState(false);
  const history = useHistory();
  const observer = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        let maxIntersectionRatio = 0;
        let visibleTab = null;

        entries.forEach((entry) => {
          if (
            entry.isIntersecting &&
            entry.intersectionRatio > maxIntersectionRatio
          ) {
            maxIntersectionRatio = entry.intersectionRatio;
            visibleTab = entry.target.id;
          }
        });

        if (!letMePass && visibleTab !== null) {
          setHighlightedTab(visibleTab);
        }
      },
      { threshold: [0.3, 0.9] }
    );

    const sections = Object.values(MobileHeaderTabsAndAnchorsNames).map(
      (AnchorName) => document.getElementById(AnchorName)
    );

    sections.forEach((section) => {
      if (section && observer.current) {
        observer.current.observe(section);
      }
    });

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [letMePass]);

  const goBack = () => {
    history.goBack();
  };

  const handleTabClick = (AnchorName: string) => {
    setLetMePass(true);
    browserScrollToId(AnchorName);

    setTimeout(() => {
      setLetMePass(false);
      setHighlightedTab(AnchorName);
    }, 500);
  };

  const renderTabs = () =>
    Object.entries(MobileHeaderTabsAndAnchorsNames).map((entry) => {
      const [tabName, AnchorName] = entry;
      return (
        <button
          type="button"
          key={tabName}
          onClick={() => handleTabClick(AnchorName)}
          className={cx("font-semibold pb-3", {
            [ACTIVE_NAV]: AnchorName === highlightedTab,
          })}
        >
          {tabName}
        </button>
      );
    });

  return (
    <header className="sticky top-0 z-20 px-4 text-white shadow-lg bg-brand-dark">
      <div className="relative flex items-center justify-center pt-1 pb-3">
        <button
          type="button"
          className="absolute left-0 flex items-center text-xl"
          onClick={goBack}
        >
          <IonIcon className="pointer-events-none" icon={arrowBackOutline} />
        </button>
        <h2 className="text-lg font-semibold justify-self-center">
          #{ticket?.number}
        </h2>
      </div>
      <div className="flex items-center">
        <nav className="space-x-6 leading-tight">{renderTabs()}</nav>
      </div>
    </header>
  );
};

export { MobileHeader };
