import type {
  GeocoderResult,
  GeocodeAutocompleteInputProps,
} from "./GeocodeAutocompleteInput.types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useMemo } from "react";
import {
  GEO_ERROR_CODES,
  useGeoLocation,
} from "@/hooks/useGeoLocation/useGeoLocation";
import { usePublicConfig } from "@/api";
import { fetchAddresses } from "./GeocodeAutocompleteInput.utils";
import { GeocodeInputTextField } from "./GeocodeInputTextField";
import { useAddressInputStateReducer } from "./stateReducer";
import { DEFAULT_COUNTRY_CODE } from "./GeocodeAutocompleteInput.constants";

const GeocodeAutocompleteInput = ({
  disabled,
  id,
  onAddressSelected,
  selectedAddress,
  invalidError,
  placeholder,
  manualValue,
}: GeocodeAutocompleteInputProps) => {
  const { data } = usePublicConfig();

  const {
    onBlur,
    reset,
    searchResolved,
    setAddress,
    setError,
    setSearchTerm,
    state,
  } = useAddressInputStateReducer(selectedAddress ?? null);
  const {
    error: geoError,
    loading: geoLoading,
    latitude,
    longitude,
  } = useGeoLocation();

  const {
    address: stateAddress,
    searchTerm,
    loading,
    resultsMenuOpen,
    options,
    error,
  } = state;

  const geoReady = !geoLoading && !geoError;

  const setAddressProxy = useCallback(
    (address: GeocoderResult | null) => {
      setAddress(address);
      onAddressSelected(address);
    },
    [onAddressSelected, setAddress]
  );

  const resetProxy = useCallback(() => {
    reset();
    onAddressSelected(null);
  }, [onAddressSelected, reset]);

  const fetchGeoAddresses = useCallback(
    async (term: string) => {
      const trimmedTerm = term.trim();
      try {
        if (trimmedTerm.length) {
          setSearchTerm(term);
          const options: GeocoderResult[] = await fetchAddresses({
            searchTerm: trimmedTerm,
            countryCode: data?.countryCode ?? DEFAULT_COUNTRY_CODE,
          });
          options.unshift({ placeName: trimmedTerm } as GeocoderResult);
          searchResolved(options);
        } else {
          resetProxy();
        }
      } catch (e) {
        setError(e);
      }
    },
    [searchResolved, setError, setSearchTerm]
  );

  const debouncedFetchGeoAddress = useMemo(
    () => debounce(fetchGeoAddresses, 500),
    [fetchGeoAddresses]
  );

  //
  // --- SIDE EFFECTS ---
  //

  // update error message if invalid input address was submitted
  useEffect(() => {
    if (invalidError) setError(invalidError);
  }, [invalidError]);

  // trigger a manual search based on term changes in the state
  useEffect(() => {
    if (searchTerm !== stateAddress?.placeName) {
      if (manualValue) {
        manualValue(searchTerm);
      }
      debouncedFetchGeoAddress(searchTerm);
    }
  }, [debouncedFetchGeoAddress, stateAddress?.placeName, searchTerm]);

  //
  // --- REVERSE GEOCODING ---
  //
  const fillCurrentAddress = useCallback(async () => {
    // Failed to get the current location for one reason or another
    if (geoError instanceof Error) {
      setError("Something unexpected happened. Please try again later.");
      return;
    }
    if (geoError) {
      switch (geoError.code) {
        case GEO_ERROR_CODES.PERMISSION_DENIED:
          setError("Please enable location services to use this feature.");
          return;
        case GEO_ERROR_CODES.TIMEOUT:
          setError("Timeout error. Please try again later.");
          return;
        case GEO_ERROR_CODES.POSITION_UNAVAILABLE:
          setError("Unable to fetch your location. Please try again later.");
          return;
        default:
          setError("Something unexpected happened. Please try again later.");
          return;
      }
    }

    const reverse = latitude && longitude ? { latitude, longitude } : undefined;

    try {
      setSearchTerm("");
      const geoLocation = { latitude, longitude, error: geoError };
      const [address] = await fetchAddresses({
        searchTerm: "",
        countryCode: data?.countryCode ?? DEFAULT_COUNTRY_CODE,
        reverse,
        geoLocation,
      });
      if (address) setAddressProxy(address);
      else resetProxy();
    } catch (e) {
      setError(e);
    }
  }, [
    geoError,
    latitude,
    longitude,
    resetProxy,
    setAddressProxy,
    setError,
    setSearchTerm,
  ]);

  //
  // --- RENDER ---
  //

  return (
    <Autocomplete
      autoSelect
      blurOnSelect={false}
      clearOnBlur={false}
      clearOnEscape
      disabled={disabled}
      disablePortal
      fullWidth
      onBlur={onBlur}
      onClose={onBlur}
      filterOptions={(x) => x}
      open={!disabled && resultsMenuOpen}
      getOptionLabel={({ placeName }) => placeName}
      id={id}
      value={stateAddress}
      onChange={(_, newAddress) => setAddressProxy(newAddress)}
      loading={loading}
      loadingText="Searching..."
      noOptionsText={
        searchTerm?.length ? searchTerm : "Type to search for a location"
      }
      inputValue={searchTerm}
      onInputChange={(e, newInputValue) => {
        // only "change" event types, not key presses, clicks, or programmatic
        // changes
        if (e && e.type === "change") setSearchTerm(newInputValue);
      }}
      options={options}
      renderInput={(params) => (
        <GeocodeInputTextField
          busy={loading}
          error={error as Error}
          mode={
            !geoReady || stateAddress || searchTerm.length
              ? "clear"
              : "myLocation"
          }
          onClear={resetProxy}
          onClickUseCurrentLocation={fillCurrentAddress}
          placeholder={placeholder}
          {...params}
        />
      )}
      renderOption={({ placeName }) => <span>{placeName}</span>}
      getOptionSelected={(a, b) => a.id === b.id}
    />
  );
};

export { GeocodeAutocompleteInput };
