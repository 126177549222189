import type { TaskCardProps } from "./TaskCard.types";
import type { TaskStatus } from "@/models";
import { useTaskStep, useTaskType, useUser } from "@/api";
import {
  RESPONSE_FORM_PREFIX,
  RESPONSE_FORM_SUBMITTED_SUFFIX,
} from "@/hooks/useResponseFormLogic";
import { TaskCardView } from "./TaskCardView";

const TaskCard = ({ task, onClick, memberCodes }: TaskCardProps) => {
  const hasAssignee = task.assigneeId !== undefined;
  const { data: user } = useUser(task.assigneeId!, { enabled: hasAssignee });
  const { data: taskType } = useTaskType(task.taskTypeId);
  const { data: taskStep } = useTaskStep(task.taskStepId);
  const isTodo = taskStep?.initial;
  const isDone = taskStep?.terminating;
  let status: TaskStatus;
  if (isTodo) {
    status = "todo";
  } else if (isDone) {
    status = "done";
  } else {
    status = "inprogress";
  }

  // ---
  // Temporary fix for offline mode
  // ---
  const isSavedOffline = localStorage.getItem(
    `${RESPONSE_FORM_PREFIX}${task.ticketId}-${task.id}`
  );

  const isSubmmitedOffline = localStorage.getItem(
    `${RESPONSE_FORM_PREFIX}${task.ticketId}-${task.id}${RESPONSE_FORM_SUBMITTED_SUFFIX}`
  );

  if (isSavedOffline) {
    status = "inprogress";
  }

  if (isSubmmitedOffline) {
    status = "done";
  }
  // ---
  // Temporary fix for offline mode
  // ---

  return (
    <TaskCardView
      title={taskType?.name || ""}
      lastAction={isDone ? "Completed" : "Created"}
      lastActionDate={isDone ? task.completedTime : task.createTime}
      memberCode={memberCodes?.find((m) => m.id === task.memberCodeId)}
      status={status}
      onClick={onClick}
      avatarProps={
        hasAssignee
          ? {
              name: user?.displayName || "",
            }
          : undefined
      }
    />
  );
};

export { TaskCard };
