import type { MapControlsProps } from "./MapControls.types";
import cx from "classnames";
import "./MapControls.css";

const MapControls = ({
  children,
  className,
  position = "top",
  dataTestId,
}: MapControlsProps) => {
  const wrapperClassname =
    position === "top"
      ? "absolute top-4 right-3 flex-col z-[5]"
      : "absolute bottom-8 right-0 px-3 flex flex-col items-end max-w-full min-w-full md:min-w-0";
  return (
    <div
      className={cx(
        wrapperClassname,
        "urbint--map-controls-wrapper",
        className
      )}
      data-testid={dataTestId}
    >
      {children}
    </div>
  );
};
export { MapControls };
