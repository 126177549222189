import type { DialogHeaderProps } from "./DialogHeaderWrapper.types";

const DialogHeaderWrapper = ({ children, className }: DialogHeaderProps) => (
  <header
    className={`relative flex flex-row justify-between items-center sm:rounded-t-lg p-6 ${className}`}
  >
    {children}
  </header>
);

export { DialogHeaderWrapper };
