import type { UseQueryOptions } from "@tanstack/react-query";
import type { PublicConfigSerializer } from "@/utils/damage-prevention";
import { useQuery } from "@tanstack/react-query";
import { PublicConfig } from "@/models";
import { UrbintApi } from "@/utils/UrbintApi";
import { handleApiResponse } from "@/api/helpers";

const api = new UrbintApi();

type IReturn = PublicConfig | undefined;

const fetchPublicConfig = () =>
  api
    .getMany<PublicConfigSerializer>({ endPoint: "public_config" })
    .then(handleApiResponse)
    .then((res) => {
      const confs = res.map((r) => new PublicConfig(r));
      // this endpoint should always return a list of 1, but just in case...
      return confs.length > 0 ? confs[0] : undefined;
    });

/**
 * Returns the opco's public config
 */
const usePublicConfig = (opts?: UseQueryOptions<IReturn>) =>
  useQuery<IReturn>(
    ["public_config"],
    () => fetchPublicConfig(),
    // this doesn't need to be refetched
    { ...opts, refetchInterval: false }
  );

export { usePublicConfig };
