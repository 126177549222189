import type {
  GeocoderQueryParams,
  Point,
  GeocoderResult,
  RawGeocoderQueryResponseObject,
} from "./GeocodeAutocompleteInput.types";
import { MAPBOX_ACCESS_TOKEN } from "../Map/Map.utils";

const buildQueryUri = ({
  searchTerm,
  countryCode,
  reverse,
  geoLocation,
}: GeocoderQueryParams) => {
  if (geoLocation?.error) {
    // Geolocation already provides good error messages
    console.debug(geoLocation?.error.message);
  }

  const proximity: Point | undefined =
    geoLocation?.longitude && geoLocation?.latitude
      ? [geoLocation.longitude, geoLocation.latitude]
      : undefined;

  const query = reverse
    ? `${reverse.longitude},${reverse.latitude}`
    : searchTerm;

  const url = new URL("https://api.mapbox.com");
  url.pathname = `geocoding/v5/mapbox.places/${query}.json`;

  url.searchParams.set("types", "address");
  url.searchParams.set("country", countryCode.toLocaleLowerCase());
  if (proximity) url.searchParams.set("proximity", proximity.join(","));
  url.searchParams.set("access_token", MAPBOX_ACCESS_TOKEN);

  return url.toString();
};

const geoQueryOpts = {
  method: "GET",
  headers: {
    referer: window.location.href,
    origin: window.location.href,
    "content-type": "application/json;charset=UTF-8",
  },
};

const fetchAddresses = async ({
  searchTerm,
  countryCode,
  reverse,
  geoLocation,
}: GeocoderQueryParams): Promise<GeocoderResult[]> => {
  // skip short or empty search terms
  if (searchTerm && searchTerm.length <= 2) return [];

  const queryUri = buildQueryUri({
    searchTerm,
    countryCode,
    reverse,
    geoLocation,
  });

  const response = await window.fetch(queryUri, geoQueryOpts);
  if (!response.ok) {
    throw new Error(
      `Unable to perform address lookup. Server replied: "${response.statusText}"`
    );
  }

  const jsonResponse = await response.json();
  const features: RawGeocoderQueryResponseObject[] =
    jsonResponse.features ?? [];

  return features.map<GeocoderResult>((feature) => ({
    center: feature.center,
    context: feature.context,
    geometry: feature.geometry,
    id: feature.id,
    placeName: feature.place_name,
    placeType: feature.place_type,
    properties: feature.properties,
    relevance: feature.relevance,
    text: feature.text,
    type: feature.type,
  }));
};

export { fetchAddresses };
