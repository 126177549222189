import type { LoadingProps } from "./Loading.types";
import { LoadingIndicator } from "../LoadingIndicator";

const Loading = ({ small = false, overlay = true }: LoadingProps) => (
  <>
    {overlay && (
      <div className="absolute top-0 left-0 w-full h-full bg-white opacity-60 z-40" />
    )}
    <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full flex justify-center items-center z-50">
      <div className="h-16">
        <LoadingIndicator lineWeight="thin" />
      </div>
      <span
        className={`${
          small ? "text-[2rem]" : "text-[4rem]"
        } text-[#666] font-sans`}
      >
        Urbint
      </span>
    </div>
  </>
);

export { Loading };
