import { useCurrentTicketQueue, useClearTicketQueue } from "@/api";
import { MoreMenuView } from "./MoreMenuView";

const MoreMenu = () => {
  const queue = useCurrentTicketQueue();
  const clearQueue = useClearTicketQueue();

  if (queue.isError) throw queue.error;
  if (clearQueue.isError) throw clearQueue.error;

  const clearAllItems = () =>
    clearQueue.mutateAsync({ queue: queue.data!, mode: "everything" });
  const clearVisitedItems = () =>
    clearQueue.mutateAsync({ queue: queue.data!, mode: "visited-only" });

  return (
    <MoreMenuView
      onRemoveViewedTickets={clearVisitedItems}
      onClearQueue={clearAllItems}
    />
  );
};

export { MoreMenu };
