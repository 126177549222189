import type { SelectOption } from "@/components/Select";
import type { FilterDialogMenuAction } from "../../FilterDialog.types";

const getMenuOptions = (
  isDirty: boolean,
  canEdit: boolean,
  canCreate: boolean,
  isDefaultView: boolean
): SelectOption<FilterDialogMenuAction>[] =>
  [
    !isDefaultView && {
      label: "Set as default view",
      value: "setDefault",
      dataTestId: "set-default-view-option",
    },
    isDirty && { label: "Reset View", value: "reset" },
    canEdit && { label: "Delete saved view", value: "delete" },
    canEdit && { label: "Edit/update saved view...", value: "edit" },
    canCreate && {
      label: "Create new saved view...",
      value: "create",
      dataTestId: "filter-modal-menu-create-saved-view",
    },
  ].filter(Boolean) as SelectOption<FilterDialogMenuAction>[];

export { getMenuOptions };
