import type { ReactChild } from "react";
import type { ActivityListItemProps } from "./ActivityListItem.types";
import { ActivityListTicketItem } from "./ActivityListTicketItem/ActivityListTicketItem";
import { ActivityListTaskItem } from "./ActivityListTaskItem/ActivityListTaskItem";

const ActivityListItem = ({
  ticket,
  activity,
  onPhotoClick,
}: ActivityListItemProps) => {
  let content: ReactChild = `${activity.modelType} - ${activity.activityType}`;
  if (activity.modelType === "ticket") {
    content = <ActivityListTicketItem ticket={ticket} activity={activity} />;
  } else if (activity.modelType === "task") {
    content = (
      <ActivityListTaskItem
        ticket={ticket}
        activity={activity}
        onPhotoClick={onPhotoClick}
      />
    );
  }
  return <li key={activity.id}>{content}</li>;
};

export { ActivityListItem };
