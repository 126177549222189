import type { TicketQueueSerializer } from "@/utils/damage-prevention";
import { useQuery } from "@tanstack/react-query";
import { TicketQueue } from "@/models";
import { UrbintApi } from "@/utils/UrbintApi";
import { queryClient } from "@/api/client";

const ticketQueuesCurrentIdent = "ticket_queues/current";

const api = new UrbintApi();
const fetchCurrentQueue = () =>
  api
    .fetch<TicketQueueSerializer>(ticketQueuesCurrentIdent)
    .then((res) => new TicketQueue(res.json!));

const refetchIntervalWhileOptimizing = 1000;
let isOptimizing = false;

/**
 * Returns the current ticket queue (optimized route queue) for the user.
 */
const useCurrentTicketQueue = () =>
  useQuery<TicketQueue>([ticketQueuesCurrentIdent], fetchCurrentQueue, {
    refetchIntervalInBackground: true,
    refetchInterval: isOptimizing ? refetchIntervalWhileOptimizing : undefined,
    onSuccess: (queue) => {
      isOptimizing = queue.state === "OPTIMIZING";
    },
  });

const invalidateCurrentTicketQueue = () => {
  queryClient.invalidateQueries([ticketQueuesCurrentIdent]);
};

export {
  ticketQueuesCurrentIdent,
  useCurrentTicketQueue,
  invalidateCurrentTicketQueue,
};
