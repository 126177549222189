import { useMutation } from "@tanstack/react-query";

import { UrbintApi } from "@/utils/UrbintApi";

const api = new UrbintApi();

type MutationData = {
  ticketId: number;
  insightId: number;
};

type DamageThreatFeedback = {
  ticketId: number;
  insightId: number;
  feedback: string;
};

const addDamageThreatFeedbackIdent = ({
  ticketId,
  insightId,
}: MutationData) => ["damageThreatInsight", ticketId, insightId];

const addDamageThreatFeedback = async ({
  ticketId,
  insightId,
  feedback,
}: DamageThreatFeedback) => {
  const response = await api.patch(
    `tickets/${ticketId}/threat_insights`,
    JSON.stringify({
      insight_id: insightId,
      feedback,
    })
  );

  if (response.error) {
    throw new Error(response.error.message);
  }
};

const useAddDamageThreatFeedback = ({ ticketId, insightId }: MutationData) =>
  useMutation(
    addDamageThreatFeedbackIdent({ ticketId, insightId }),
    addDamageThreatFeedback
  );

export { useAddDamageThreatFeedback };
