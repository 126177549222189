import type { HeaderProps } from "./Header.types";
import { CaptionText, Icon } from "@urbint/silica";
import {
  DialogHeaderButtons,
  DialogHeaderWrapper,
  DialogTitle,
} from "@/components/Dialog";

const Header = ({ closeModal, headerTitle, address }: HeaderProps) => (
  <DialogHeaderWrapper className="pb-0">
    <div className="flex flex-col items-start">
      <DialogTitle>{headerTitle}</DialogTitle>
      <CaptionText className="mb-7">{address}</CaptionText>
    </div>
    <DialogHeaderButtons>
      <button
        type="button"
        className="flex items-center w-10 h-10 justify-center"
        onClick={closeModal}
      >
        <Icon name="close_big" />
      </button>
    </DialogHeaderButtons>
  </DialogHeaderWrapper>
);

export { Header };
