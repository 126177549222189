import type { PickerType } from "./DateTimeInput.types";

const getPlaceholder = (type: PickerType) => {
  switch (type) {
    case "datetime":
      return "MM/DD/YYYY HH:MM am";
    case "date":
      return "MM/DD/YYYY";
    case "time":
      return "HH:MM am";
    // no default
  }
};
const parseDate = (date: string | Date | undefined) =>
  typeof date === "string" ? new Date(date) : date;
const getDateFormat = (type: PickerType) => {
  switch (type) {
    case "datetime":
      return "m-d-Y h:iK";
    case "date":
      return "m-d-Y";
    case "time":
      return "h:iK";
    // no default
  }
};
export { getPlaceholder, parseDate, getDateFormat };
