import type {
  AttachmentFromNonTicketedEvent,
  AttachmentFromTicket,
  AttachmentTypes,
} from "./AttachmentsSection.types";
/**
 * Get the correct type of the attachment and it's properties
 */
const getAttachmentType = (
  attachmentType: AttachmentTypes
): AttachmentTypes | null => {
  if ("eventId" in attachmentType) {
    return {
      eventId: attachmentType.eventId,
    };
  }
  if ("taskId" in attachmentType && "ticketId" in attachmentType) {
    return {
      ticketId: attachmentType.ticketId,
      taskId: attachmentType.taskId,
    };
  }

  return null;
};

const isAttachmentFromTicket = (
  AttachmentObj: AttachmentTypes | null
): AttachmentObj is AttachmentFromTicket => {
  if (!AttachmentObj) {
    return false;
  }
  return "taskId" in AttachmentObj && "ticketId" in AttachmentObj;
};

const isAttachmentFromNonTicketedEvent = (
  AttachmentObj: AttachmentTypes | null
): AttachmentObj is AttachmentFromNonTicketedEvent => {
  if (!AttachmentObj) {
    return false;
  }

  return "eventId" in AttachmentObj;
};

export {
  getAttachmentType,
  isAttachmentFromTicket,
  isAttachmentFromNonTicketedEvent,
};
