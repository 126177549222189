import type { TagProps } from "./Tag.types";
import { Icon } from "@urbint/silica";
import cx from "classnames";
import { TagIcons } from "./Tag.constants";

const Tag = ({ children, className, type = "info" }: TagProps) => (
  <div
    data-testid={`${type}-tag`}
    className={cx(
      "p-2 flex gap-2 items-center rounded text-base font-caption-text border border-solid",
      {
        "bg-feedback-info-background border-feedback-info-border":
          type === "info",
        "bg-feedback-error-background  border-feedback-error-border":
          type === "error",
        "bg-feedback-warning-background border-feedback-warning-border":
          type === "warning",
        "bg-feedback-success-background border-feedback-success-border":
          type === "success",
      },
      className
    )}
  >
    <Icon
      className={cx(
        "h-6 w-6 flex justify-center items-center text-white rounded",
        {
          "bg-feedback-info-content": type === "info",
          "bg-feedback-error-content": type === "error",
          "bg-feedback-warning-content ": type === "warning",
          "bg-feedback-success-content": type === "success",
        }
      )}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      name={TagIcons[type]!}
    />
    <div>{children}</div>
  </div>
);

export { Tag };
