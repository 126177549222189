import { useUser } from "@/api";
import { useTicketViewEditor } from "@/components/TicketViewEditorProvider";
import { formatFullDateTime } from "@/format";

const ColumnsDetails = () => {
  const { dirtyView } = useTicketViewEditor();
  const { data: viewOwner } = useUser(dirtyView.userId!, {
    enabled: dirtyView.userId !== undefined,
  });

  return (
    <ul className="divide-y divide-gray-100 divide-solid">
      {[
        { label: "View name", value: dirtyView.name },
        { label: "Owner", value: viewOwner?.displayName || "None" },
        {
          label: "Created on",
          value: formatFullDateTime(dirtyView.createdOn),
        },
        {
          label: "Last updated",
          value: formatFullDateTime(dirtyView.updatedOn),
        },
        {
          label: "Shared with",
          value: dirtyView.isShared ? "Organization" : "None",
        },
      ].map(({ label, value }) => (
        <li key={label} className="flex items-center p-1 text-sm">
          <span className="flex-shrink-0 w-24 text-sm text-black text-opacity-66">
            {label}
          </span>
          <span className="text-black text-opacity-88">{value}</span>
        </li>
      ))}
    </ul>
  );
};
export { ColumnsDetails };
