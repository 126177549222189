import type { FleetLocationSerializer } from "@/utils/damage-prevention";
import { useQuery } from "@tanstack/react-query";
import { FleetLocation } from "@/models";
import { UrbintApi } from "@/utils/UrbintApi";
import { handleApiResponse } from "@/api/helpers";
import {
  FETCH_USER_LOCATION_TIME_INTERVAL,
  REFETCH_INTERVAL_IN_BACKGROUND,
} from "../fleetLocation.constants";

const api = new UrbintApi();

const fetchLocations = () =>
  api
    .getMany<FleetLocationSerializer>({ endPoint: "fleet_location" })
    .then(handleApiResponse)
    .then((results) => results.map((loc) => new FleetLocation(loc)));

function useFleetLocation() {
  return useQuery<FleetLocation[]>(["fleet_location"], fetchLocations, {
    refetchIntervalInBackground: REFETCH_INTERVAL_IN_BACKGROUND,
    refetchInterval: FETCH_USER_LOCATION_TIME_INTERVAL,
  });
}

export { useFleetLocation };
