import type { UseQueryOptions } from "@tanstack/react-query";
import type { TaskTypeSerializer } from "@/utils/damage-prevention";
import { useQuery } from "@tanstack/react-query";
import { TaskType } from "@/models";
import { UrbintApi } from "@/utils/UrbintApi";
import { queryClient } from "@/api/client";
import { handleApiResponse } from "@/api/helpers";

const api = new UrbintApi();

const taskTypesIdent = () => ["task_types"];

const fetchTaskTypes = () =>
  api
    .getPage<TaskTypeSerializer>({
      endPoint: "task_types",
      page: 1,
    })
    .then(handleApiResponse)
    .then((res) => res.results.map((r) => new TaskType(r)));

const useTaskTypes = (opts?: UseQueryOptions<TaskType[]>) =>
  useQuery<TaskType[]>(taskTypesIdent(), () => fetchTaskTypes(), opts);

const prefetchTaskTypes = () =>
  queryClient.prefetchQuery(taskTypesIdent(), fetchTaskTypes);

export { taskTypesIdent, useTaskTypes, prefetchTaskTypes };
