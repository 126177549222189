import { QueryClient } from "@tanstack/react-query";
import { StatusCodes } from "http-status-codes";
import { createIDBPersister } from "./indexedDBPersister";
import { retryDelayStrategy } from "./utils";
import { MAXIMUM_RETRIES } from "./constants";

const timeToLive = 1000 * 60 * 10;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: timeToLive,
      refetchOnWindowFocus: false,
      staleTime: timeToLive,
      networkMode: "offlineFirst",
      cacheTime: Infinity,
      retryDelay: retryDelayStrategy,
    },
    mutations: {
      networkMode: "offlineFirst",
      retry: (failureCount, error) => {
        if (
          error instanceof Error &&
          error.message.includes(String(StatusCodes.UNAUTHORIZED))
        ) {
          return true;
        }
        return MAXIMUM_RETRIES >= failureCount;
      },
      retryDelay: retryDelayStrategy,
    },
  },
});

export { queryClient, createIDBPersister };
