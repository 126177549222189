import type { ChangeEvent, KeyboardEvent } from "react";
import type { SearchInputProps } from "./SearchInput.types";
import { useRef, useState, useEffect, useCallback } from "react";
import classNames from "classnames";

import { Icon } from "@urbint/silica";

import { useDebounce } from "react-use";
import { BaseInput } from "../BaseInput";
import { LoadingIndicator } from "../LoadingIndicator";

const SearchInput = ({
  testId = "",
  busy = false,
  compact,
  disabled = false,
  onClear,
  onSearch,
  onChange,
  onBlur,
  placeholder = "Search",
  searchOnEnter = false,
  value = "",
  searchDebounceMs = 500,
  withSearchIcon = true,
  // eslint-disable-next-line
  inputRef = useRef<HTMLInputElement>(null),
  theme,
}: SearchInputProps) => {
  const [searchTerm, setSearchTerm] = useState(value);

  useEffect(() => {
    setSearchTerm(value);
    if (onChange) onChange(value.toString());
  }, [value, onChange]);

  // Automatic debounced search
  useDebounce(
    () => {
      if (!searchOnEnter) onSearch(searchTerm.toString());
    },
    searchDebounceMs,
    [searchTerm]
  );

  // Update the internal state
  const updateSearchTerm = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    if (onChange) onChange(event.target.value);
  };

  // Clear the search term and notify the parent
  const clearSearchTerm = useCallback(() => {
    if (searchTerm.toString().length > 0) {
      setSearchTerm("");
      if (onClear) onClear();
      inputRef.current?.focus();
    }
  }, [searchTerm, inputRef, onClear]);

  const hasSearch = searchTerm.toString().length;

  const onKeyDown = (e: KeyboardEvent) => {
    if (hasSearch && e.key === "Escape") {
      clearSearchTerm();
    } else if (hasSearch && searchOnEnter && e.key === "Enter") {
      onSearch(searchTerm.toString());
    }
  };

  return (
    <BaseInput
      compact={compact}
      disabled={disabled}
      onClick={() => inputRef.current?.focus()}
      startIcon={withSearchIcon ? "search" : undefined}
      onBlur={onBlur}
      className={classNames("bg-neutral-light-tertiary", theme?.wrapper ?? "")}
    >
      {busy ? (
        <div
          data-testid="loading-indicator"
          slot="start"
          className={classNames("self-center", {
            "h-4 w-4 m-1": compact,
            "h-7 w-7 m-0.5": !compact,
          })}
        >
          <LoadingIndicator lineWeight="thin" />
        </div>
      ) : null}
      <input
        data-testid={testId}
        className={classNames(
          "border-0 outline-none bg-transparent w-full",
          theme?.input ?? ""
        )}
        disabled={disabled}
        onChange={updateSearchTerm}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        ref={inputRef}
        type="text"
        value={searchTerm}
      />
      <button
        data-testid="clear-search"
        aria-label="clear search"
        tabIndex={searchTerm.toString().length === 0 ? -1 : 0}
        className={classNames(
          "flex items-center outline-none focus:bg-gray-10",
          {
            "px-1": compact,
            "px-2": !compact,
            "opacity-0 pointer-events-none": searchTerm.toString().length === 0,
          }
        )}
        onClick={clearSearchTerm}
        slot="end"
        type="button"
      >
        <Icon name="close_big" />
      </button>
    </BaseInput>
  );
};

export { SearchInput };
