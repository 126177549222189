import type { TaskDetailSectionHeaderProps } from "./TaskDetailSectionHeader.types";

const TaskDetailSectionHeader = ({
  children,
  className = "",
}: TaskDetailSectionHeaderProps) => (
  <h2
    className={`text-neutral-shade-primary font-semibold text-lg mb-6 ${className}`}
  >
    {children}
  </h2>
);

export { TaskDetailSectionHeader };
