import type { ClusterParts } from "./ClusterMarker.types";
import { ImpactPotential } from "@/models";
import { riskColor } from "@/format";
import { CLUSTER_SIZE_CATEGORY } from "./ClusterMarker.constants";

const SIZER = 0.4;

const createDonutPath = (start: number, end: number, r: number, r0: number) => {
  if (end - start === 1) end -= 0.00001;
  const a0 = 2 * Math.PI * (start - 0.25);
  const a1 = 2 * Math.PI * (end - 0.25);
  const x0 = Math.cos(a0);
  const y0 = Math.sin(a0);
  const x1 = Math.cos(a1);
  const y1 = Math.sin(a1);
  const largeArc = end - start > 0.5 ? 1 : 0;

  const pathShape: string = [
    `M ${r + r0 * x0}, ${r + r0 * y0}`,
    `L ${r + r * x0}, ${r + r * y0}`,
    `A ${r}, ${r}, 0, ${largeArc}, 1, ${r + r * x1}, ${r + r * y1}`,
    `L ${r + r0 * x1}, ${r + r0 * y1}`,
    `A ${r0}, ${r0}, 0, ${largeArc}, 0, ${r + r0 * x0}, ${r + r0 * y0}`,
  ].join(" ");

  return pathShape;
};
const prepareDonutParams = (
  {
    VERY_HIGH = 0,
    HIGH = 0,
    MEDIUM = 0,
    LOW = 0,
    NO_ASSETS = 0,
    MISSING_DATA = 0,
    NONE = 0,
  }: Record<ClusterParts, number>,
  scaler: number
) => {
  const offsets = [];
  const colors = Object.values(ImpactPotential).map((x) => riskColor(x));
  const counts = [VERY_HIGH, HIGH, MEDIUM, LOW, NO_ASSETS, MISSING_DATA, NONE];
  let total = 0;
  for (const count of counts) {
    offsets.push(total);
    total += count;
  }

  let fontSize = 14 * scaler;
  let donutOffset = CLUSTER_SIZE_CATEGORY.VERY_SMALL * scaler;

  if (total >= 1000) {
    fontSize = 16 * scaler;
    donutOffset = CLUSTER_SIZE_CATEGORY.VERY_LARGE * scaler;
  } else if (total >= 500) {
    fontSize = 16 * scaler;
    donutOffset = CLUSTER_SIZE_CATEGORY.LARGE * scaler;
  } else if (total >= 100) {
    fontSize = 14 * scaler;
    donutOffset = CLUSTER_SIZE_CATEGORY.MEDIUM * scaler;
  } else if (total >= 10) {
    fontSize = 12 * scaler;
    donutOffset = CLUSTER_SIZE_CATEGORY.SMALL * scaler;
  }

  const innerRadius = Math.round(donutOffset * 0.75);
  const donutDiameter = donutOffset * 5 * SIZER;

  return {
    counts,
    donutDiameter,
    fontSize,
    total,
    innerRadius,
    donutOffset,
    colors,
    offsets,
  };
};

export { createDonutPath, prepareDonutParams };
