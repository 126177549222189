import type { DetailButtonProps } from "./DetailButton.types";

const DetailButton = ({ onClick, children }: DetailButtonProps) => (
  <button
    type="button"
    onClick={onClick}
    className="text-blue-600 font-semibold text-sm"
  >
    {children}
  </button>
);

export { DetailButton };
