import type { TicketCardTaskListProps } from "./TicketCardTaskList.types";
import { TaskItem } from "./TaskItem";

const TicketCardTaskList = ({
  ticketId,
  tasks,
  isSelectable,
  onTaskSelected,
  selectedTasks,
  disabled,
}: TicketCardTaskListProps) => {
  if (!tasks || tasks.length === 0)
    return (
      <p
        className="px-3 py-2 text-black bg-black rounded-sm bg-opacity-6 text-opacity-66"
        data-testid="no-matching-tasks-text"
      >
        No matching tasks
      </p>
    );

  return (
    <ul className="space-y-1 font-sans">
      {tasks.map((task) => {
        const isSelected = selectedTasks?.includes(task);
        return (
          <TaskItem
            key={task.id}
            isSelectable={isSelectable}
            isSelected={isSelected}
            onSelect={onTaskSelected}
            ticketId={ticketId}
            task={task}
            disabled={disabled}
          />
        );
      })}
    </ul>
  );
};

export { TicketCardTaskList };
