import type { InvalidAttachmentSizeProps } from "./InvalidAttachmentSize.types";
import { BodyText, Subheading } from "@urbint/silica";
import { Dialog, type dialogFooter } from "@/components/Dialog";

const InvalidAttachmentSize = ({
  modalVisibility,
  setModalVisibility,
}: InvalidAttachmentSizeProps) => {
  const hideModal = () => setModalVisibility(false);
  const modalFooter: dialogFooter = {
    toRender: "Dismiss",
    onClose: hideModal,
    onClick: hideModal,
  };

  return (
    <Dialog
      isOpen={modalVisibility}
      onClose={hideModal}
      variant="error"
      header=""
      footer={modalFooter}
    >
      <Subheading>The file you are trying to upload is too large.</Subheading>
      <BodyText className="mt-4">
        Currently, we only support uploading files that are smaller than 500MB.
      </BodyText>
    </Dialog>
  );
};

export { InvalidAttachmentSize };
