import type {
  PrefetchContextType,
  PrefetchProviderProps,
} from "./PrefetchOfflineData.types";
import { createContext, useContext, useMemo, useState } from "react";

const PrefetchContext = createContext<PrefetchContextType | undefined>(
  undefined
);

const usePrefetch = () => {
  const context = useContext(PrefetchContext);
  if (context === undefined) {
    throw new Error("usePrefetch must be used within a PrefetchProvider");
  }
  return context;
};

const PrefetchProvider = ({ children }: PrefetchProviderProps) => {
  const [prefetchedTickets, setPrefetchedTickets] = useState<number[]>([]);

  const addPrefetchedTicket = (ticketId: number) => {
    setPrefetchedTickets((previousTickets) => {
      localStorage.setItem(
        "prefetchedTickets",
        JSON.stringify([...new Set([...previousTickets, ticketId])])
      );
      return [...new Set([...previousTickets, ticketId])];
    });
  };

  const checkTicketPrefetched = (ticketId: number | undefined) => {
    if (!ticketId) return false;

    const prefetchedTicketsList = JSON.parse(
      localStorage.getItem("prefetchedTickets") || "[]"
    );

    return prefetchedTicketsList.includes(ticketId);
  };

  const value = useMemo(
    () => ({ prefetchedTickets, addPrefetchedTicket, checkTicketPrefetched }),
    [prefetchedTickets]
  );

  return (
    <PrefetchContext.Provider value={value}>
      {children}
    </PrefetchContext.Provider>
  );
};

export { PrefetchProvider, usePrefetch };
