import type { DamagePreventionUserProfile } from "@/utils/types";
import { User } from "@/models";

const compareUsernames =
  ({ username }: DamagePreventionUserProfile) =>
  (a: User, b: User) => {
    const aIsMe = a.username === username;
    const bIsMe = b.username === username;
    if (aIsMe) return -1;
    if (bIsMe) return 1;
    if (a.id === -1) return -1;

    return a.displayName.localeCompare(b.displayName);
  };

const unassignedUser = new User({
  id: -1,
  display_name: "Unassigned",
  username: "unassigned",
});
const isUnassignedUser = (u?: User) =>
  u && u.displayName === unassignedUser.displayName;

export { compareUsernames, unassignedUser, isUnassignedUser };
