const WARNING_THRESHOLD = 50;
const MAX_SELECTION_THRESHOLD = 200;
const BULK_ACTION_DISABLED_COPY =
  "Bulk actions are not supported on more than 200 tickets";

export {
  WARNING_THRESHOLD,
  MAX_SELECTION_THRESHOLD,
  BULK_ACTION_DISABLED_COPY,
};
