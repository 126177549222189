import type { TaskCardViewProps } from "./TaskCardView.types";
import { useMemo } from "react";
import { Avatar } from "@/common/Avatar";
import { formatFullDate } from "@/format";
import { TaskCardIcon } from "../TaskCardIcon";

const TaskCardView = ({
  title,
  lastAction,
  lastActionDate,
  status,
  memberCode,
  onClick,
  avatarProps,
}: TaskCardViewProps) => {
  const statusIcon = (
    <TaskCardIcon
      className="text-lg pointer-events-none mr-1.5"
      status={status}
    />
  );
  const memberCodeSlug = useMemo(
    () =>
      memberCode ? (
        <span className="flex flex-row items-center">
          {memberCode.key}
          <span className="rounded-full m-1 h-1 w-1 bg-gray-200 block" />
        </span>
      ) : null,
    [memberCode]
  );
  return (
    <button
      className="bg-white p-3 border border-black border-opacity-11 rounded w-full text-left"
      data-testid="task-card"
      onClick={onClick}
      type="button"
    >
      <header className="flex items-center">
        {statusIcon}
        <p
          className="font-semibold text-black text-opacity-88 overflow-hidden whitespace-nowrap overflow-ellipsis"
          data-testid="task-name"
        >
          {title}
        </p>
        <span className="ml-auto">
          {avatarProps && <Avatar {...avatarProps} />}
        </span>
      </header>
      <p className="mt-1 text-black text-opacity-66 text-sm leading-tight flex flex-row">
        {memberCodeSlug}
        <span>{`${lastAction} ${formatFullDate(lastActionDate)}`}</span>
      </p>
    </button>
  );
};

export { TaskCardView };
