import type {
  TicketFocus,
  TicketFocusProviderProps,
  TicketId,
} from "./TicketFocus.types";
import { useState } from "react";
import { TicketFocusContext } from "./TicketFocus.context";

const TicketFocusProvider = ({ children }: TicketFocusProviderProps) => {
  const [focusedTicket, setFocusedTicket] = useState<TicketId | undefined>();

  const value: TicketFocus = {
    focusedTicket,
    setFocusedTicket,
  };

  return (
    <TicketFocusContext.Provider value={value}>
      {children}
    </TicketFocusContext.Provider>
  );
};

export { TicketFocusProvider };
