import type { TicketPageDetailsProps } from "./TicketPageDetails.types";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import cx from "classnames";
import { useFullScreen } from "@/hooks";

import { Header } from "../Header";
import { Container } from "../commons";
import {
  MobileHeaderTabsAndAnchorsNames,
  mobileSectionBorder,
} from "../TicketPage.constants";
import { TicketDetailsSection } from "./TicketDetailsSection";
import { DigLocationDetailsSection } from "./DigLocationDetailsSection";
import { PositiveResponsesDetailsSection } from "./PositiveResponsesDetailsSection";
import { LocateDetailsSection } from "./LocateDetailsSection";
import { LastResponseSection } from "./LastResponseSection";
import { ExcavatorDetailsSection } from "./ExcavatorDetailsSection";
import { ProjectInformationSection } from "./ProjectInformationSection";
import { OtherDetailsSection } from "./OtherDetailsSection";
import { NavBarSection } from "./NavBarSection";

const TicketPageDetails = ({ ticket }: TicketPageDetailsProps) => {
  const { pathname } = useLocation();
  const { isFullscreen } = useFullScreen();
  const containerRef = useRef<HTMLDivElement>(null);
  const { meetingDetails, meetingDate, scopeOfWork } =
    ticket?.latestVersion || {};
  const hasProjectInformation = meetingDetails || meetingDate || scopeOfWork;

  useEffect(() => {
    if (typeof containerRef?.current?.scrollTo === "function") {
      // Seems like this is not a function everywhere...
      containerRef.current.scrollTo(0, 0);
    }
  }, [pathname]);

  return (
    <div className="flex-grow" ref={containerRef}>
      <Header ticket={ticket} />
      <div
        id={MobileHeaderTabsAndAnchorsNames.Details}
        className={cx(
          "flex-grow overflow-y-auto max-h-[67%]",
          mobileSectionBorder,
          {
            hidden: isFullscreen,
          }
        )}
      >
        <NavBarSection />
        <Container>
          <TicketDetailsSection ticket={ticket} />
          <DigLocationDetailsSection ticket={ticket} />
          <PositiveResponsesDetailsSection ticket={ticket} />
          <LocateDetailsSection ticket={ticket} />
          <LastResponseSection ticket={ticket} />
          <ExcavatorDetailsSection ticket={ticket} />
          {hasProjectInformation && (
            <ProjectInformationSection ticket={ticket} />
          )}
          <OtherDetailsSection ticket={ticket} />
        </Container>
      </div>
    </div>
  );
};

export { TicketPageDetails };
