import { useCallback, useRef } from "react";
import { createInput } from "@/hooks/useFileInput/utils";

enum FileType {
  PDF = "pdf",
  IMAGES = "image",
  VIDEO = "video",
}

const SupportedFileTypes: Record<FileType, string> = {
  [FileType.IMAGES]: "image/*",
  [FileType.PDF]: ".pdf",
  [FileType.VIDEO]:
    "video/mp4,video/M4P,video/M4V,video/MPG,video/MP2,video/MPEG,video/MPE,video/MPV,video/MOV,video/QT,video/WMV",
};

const useFileInput = (fileType: FileType) => {
  const inputRef = useRef<HTMLInputElement>(
    createInput(SupportedFileTypes[fileType])
  );
  const rejectPendingPromiseRef = useRef<(() => void) | null>(null);

  // trigger a click event on the input to bring up the photo menu
  const getFiles: () => Promise<File[]> = useCallback(
    () =>
      new Promise((resolve, reject) => {
        if (rejectPendingPromiseRef.current) {
          rejectPendingPromiseRef.current();
        }
        rejectPendingPromiseRef.current = reject;
        const onChange = () => {
          inputRef.current.removeEventListener("change", onChange);
          if (inputRef.current.files) {
            rejectPendingPromiseRef.current = null;
            resolve(Array.from(inputRef.current.files));
          } else {
            reject();
          }
          inputRef.current.value = "";
        };
        inputRef.current.addEventListener("change", onChange);
        inputRef.current.click();
      }),
    []
  );

  return getFiles;
};

export { useFileInput, FileType };
