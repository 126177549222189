import type { Row } from "react-table";
import type {
  ColumnData,
  ColumnDefinition,
} from "@/components/TicketTable/TicketTable.types";
import type {
  TicketTableCellProps,
  TasksCellProps,
} from "./useTicketColumns.types";
import { useState } from "react";
import { createPortal } from "react-dom";
import classNames from "classnames";
import { IonCheckbox, IonIcon } from "@ionic/react";
import { checkmarkSharp } from "ionicons/icons";
import upperCase from "lodash/upperCase";
import { Link } from "react-router-dom";
import { usePopper } from "react-popper";
import { RiskBadge } from "@/components/RiskBadge";
import { TicketCardTaskList } from "@/components/TicketCard";
import { extractLocateIssueCodes, locateIssueCodesToString } from "@/utils";
import { Tooltip } from "@/common/Tooltip";
import { formatFullDateTime, startCase } from "@/format";
import { ServiceCenterColumn } from "@/components/ServiceCenterColumn";

const DEFAULT_COLUMNS: ColumnDefinition<ColumnData>[] = [
  {
    id: "locate_problem",
    Header: "Locate Problems",
    fields: ["positive_responses.locate_issue_codes"],
    accessor: ({ ticket }) => {
      const { positiveResponses } = ticket || {};
      const getLocateProblems = locateIssueCodesToString(
        extractLocateIssueCodes(positiveResponses)
      );

      return getLocateProblems;
    },
  },
  {
    id: "tlc_received_at",
    Header: "Received at Date",
    fields: ["latest_version.tlc_received_at"],
    sortFields: ["tlc_received_at"],
    minWidth: 190,
    accessor: ({ ticket }) =>
      formatFullDateTime(ticket.latestVersion?.tlc_received_at),
  },
  {
    id: "ticket_number",
    isLocked: true,
    className:
      "sticky left-0 pl-3 bg-white bg-inherit flex items-center border-r border-neutral-shade-border-default",
    headerClassName:
      "sticky left-0 pl-3 bg-white border-r border-neutral-shade-border-default",
    Header: "Ticket #",
    fields: ["id", "number"],
    sortFields: ["ticket_number"],
    Cell: ({ row, state }: TicketTableCellProps) => {
      const { selectMode, ticket } = row.original;
      return (
        <div className="flex items-center w-full">
          {selectMode === "tickets" && (
            <IonCheckbox
              className="mr-2"
              checked={row.original.isSelected}
              onClick={() => state.toggleTicketId(row.original.ticket.id!)}
            />
          )}
          <Link
            className="font-semibold text-blue-500"
            to={`/ticket/${ticket.id}`}
            data-testid="ticket-id"
          >
            #{ticket.number}
          </Link>
        </div>
      );
    },
  },
  {
    id: "ticket_type",
    Header: "Ticket Type",
    fields: ["latest_version.type"],
    sortFields: ["ticket_type"],
    accessor: ({ ticket }) =>
      ticket.isEmergency ? (
        <span className="font-semibold text-red-500">
          {upperCase(ticket.latestVersion?.type)}
          <span className="italic">!!</span>
        </span>
      ) : (
        startCase(ticket.latestVersion?.type)
      ),
  },
  {
    id: "damage_threat",
    Header: "Damage Threat Category",
    fields: ["latest_version.damage_potential"],
    sortFields: ["damage_potential"],
    accessor: ({ ticket }) => (
      <RiskBadge value={ticket.latestVersion?.damagePotential} />
    ),
  },
  {
    id: "expires_on",
    Header: "Expiration Date",
    fields: ["expires_on"],
    sortFields: ["expires_on"],
    accessor: ({ ticket }) =>
      ticket.expires_on && formatFullDateTime(ticket.expires_on),
  },
  {
    id: "impact",
    Header: "Impact Category",
    fields: ["latest_version.impact_potential"],
    sortFields: ["impact_potential"],
    accessor: ({ ticket }) => (
      <RiskBadge value={ticket.latestVersion?.impactPotential} />
    ),
  },
  {
    id: "address",
    Header: "Address",
    fields: [
      "latest_version.excavation_street_name",
      "latest_version.excavation_street_number",
    ],
    sortFields: ["excavation_address"],
    accessor: ({ ticket }) => {
      const streetName = ticket.latestVersion?.excavationStreetName;
      const streetNum = ticket.latestVersion?.excavationStreetNumber;
      const address = [streetNum, streetName]
        .filter((i) => Boolean(i))
        .join(" ");
      return startCase(address);
    },
  },
  {
    id: "city",
    Header: "City",
    fields: ["latest_version.excavation_city"],
    sortFields: ["excavation_city"],
    accessor: ({ ticket }) => startCase(ticket.latestVersion?.excavationCity),
  },
  {
    id: "state",
    Header: "State",
    fields: ["latest_version.excavation_state"],
    sortFields: ["excavation_state"],
    accessor: ({ ticket }) =>
      ticket.latestVersion?.excavationState?.toUpperCase(),
  },
  {
    id: "county",
    Header: "County",
    fields: ["latest_version.excavation_county"],
    sortFields: ["excavation_county"],
    accessor: ({ ticket }) => startCase(ticket.latestVersion?.excavationCounty),
  },
  {
    id: "excavator_name",
    Header: "Excavator",
    fields: [
      "latest_version.contacts.primary",
      "latest_version.contacts.company_name",
    ],
    sortFields: ["excavator"],
    accessor: ({ ticket }) => {
      const primaryContact = ticket?.latestVersion?.contacts?.find(
        (x) => x.primary
      );
      return startCase(primaryContact?.companyName);
    },
  },
  {
    id: "work_done_for",
    Header: "Work Done For",
    fields: ["latest_version.work_done_for"],
    sortFields: ["work_done_for"],
    accessor: ({ ticket }) => startCase(ticket.latestVersion?.workDoneFor),
  },
  {
    id: "work_start_datetime",
    Header: "Work Start Datetime",
    fields: ["latest_version.work_start_date"],
    sortFields: ["work_start_date"],
    minWidth: 190,
    accessor: ({ ticket }) =>
      formatFullDateTime(ticket.latestVersion?.workStartDate),
  },
  {
    id: "service_centers",
    Header: "Service Center",
    fields: ["service_centers.name", "service_centers.id"],
    sortFields: ["service_center"],
    width: 200,
    accessor: ({ ticket }) => <ServiceCenterColumn ticket={ticket} />,
  },
  {
    id: "stage",
    Header: "Ticket Stage",
    fields: ["stage"],
    sortFields: ["stage"],
    accessor: ({ ticket }) => startCase(ticket.stage),
  },
  {
    id: "work_type",
    Header: "Work Type",
    fields: ["latest_version.excavation_work_type"],
    sortFields: ["excavation_work_type"],
    accessor: ({ ticket }) =>
      startCase(ticket.latestVersion?.excavationWorkType),
  },
  {
    id: "equipment_type",
    Header: "Equipment Type",
    fields: ["latest_version.equipment_type"],
    sortFields: ["equipment_type"],
    accessor: ({ ticket }) => startCase(ticket.latestVersion?.equipmentType),
  },
  {
    id: "most_recent_comment",
    Header: "Most Recent Ticket Comment",
    sortFields: ["latest_comments"],
    width: 400,
    fields: ["latest_comment.comment"],
    accessor: ({ ticket }) => {
      const comment = ticket.latestComment?.comment || "";
      return comment.length > 90 ? (
        <Tooltip title={comment}>{comment.slice(0, 90)}...</Tooltip>
      ) : (
        comment
      );
    },
  },
  {
    id: "open_task_types",
    Header: "Open Task Types",
    width: 300,
    sortFields: ["task_count"],
    fields: [
      "tasks.id",
      "tasks.create_time",
      "tasks.task_step.terminating",
      "tasks.task_step.initial",
      "tasks.task_type.id",
      "tasks.task_type.name",
    ],
    accessor: ({ ticket }) => {
      const text = (ticket.tasks || [])
        .sort(
          (a, b) =>
            (b.createTime?.valueOf() || 0) - (a.createTime?.valueOf() || 0)
        )
        .filter((x) => !x.taskStep?.terminating)
        .map((x) => x.taskType?.name || "")
        .join(", ");
      return text.length > 70 ? (
        <Tooltip title={text}>{text.slice(0, 70)}...</Tooltip>
      ) : (
        text
      );
    },
  },
  {
    id: "tasks",
    Header: ({ rows }: { rows: Row<ColumnData>[] }) => {
      const firstRow = rows.find(Boolean);
      if (!firstRow || firstRow.original.selectMode !== "tasks") return "Tasks";

      return (
        <div className="flex items-center justify-between w-full px-2">
          Tasks <IonIcon icon={checkmarkSharp} />
        </div>
      );
    },
    headerText: "Tasks",
    fields: [
      "tasks.id",
      "tasks.assignee.id",
      "tasks.assignee.email",
      "tasks.assignee.first_name",
      "tasks.assignee.last_name",
      "tasks.task_type.id",
      "tasks.task_type.name",
      "tasks.task_step.id",
      "tasks.task_step.name",
      "tasks.task_step.initial",
      "tasks.task_step.terminating",
      "tasks.completed_time",
    ],
    sortFields: ["task_count"],
    width: 120,
    className:
      "sticky right-0 top-0 z-1 h-full w-full mb-0 bg-inherit border-l border-neutral-shade-border-default flex items-center justify-center px-2",
    headerClassName:
      "sticky right-0 top-0 bg-white z-1 border-l border-neutral-shade-border-default px-2 flex justify-center",
    exclude: true,
    Cell: ({ row, state }: TasksCellProps) => {
      const { ticket, selectMode, selectedTasks = [] } = row.original;
      const [referenceElement, setReferenceElement] = useState<any>();
      const [popperElement, setPopperElement] = useState<any>();
      const [hovered, setHovered] = useState(false);
      const { styles, attributes } = usePopper(
        referenceElement,
        popperElement,
        {
          placement: "bottom-end",
          strategy: "fixed",
        }
      );

      const { toggleTask } = state;
      const selectedCount = selectedTasks.length;

      return (
        <div
          className="w-full"
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <div
            ref={setReferenceElement}
            className={classNames(
              "rounded-full cursor-pointer bg-neutral-shade-background-light border h-6 w-full flex justify-center items-center font-sans font-bold text-neutral-shade-secondary text-sm overflow-hidden",
              {
                "border-neutral-shade-background-medium":
                  selectedCount === 0 || selectMode !== "tasks",
                "border-brand-50": selectedCount > 0,
              }
            )}
            data-testid="tasks-table-view"
          >
            <div className="flex justify-center flex-1">
              {ticket.tasks?.length ?? 0}
            </div>
            {selectMode === "tasks" && (
              <div
                className={classNames(
                  "px-3 border-l h-full flex items-center",
                  {
                    "bg-brand-50 text-white border-brand-50": selectedCount > 0,
                    "bg-white text-neutral-shade-tertiary border-neutral-shade-background-medium":
                      selectedCount === 0,
                  }
                )}
              >
                {selectedCount}
              </div>
            )}
          </div>

          {hovered &&
            createPortal(
              <div
                style={styles.popper}
                ref={setPopperElement}
                {...attributes.popper}
                className="pt-3"
              >
                <div className="flex flex-col px-4 py-5 pb-4 md:w-[350px] w-full max-h-[400px] overflow-y-auto bg-white rounded-lg drop-shadow-md border border-neutral-shade-background-light">
                  <div className="pb-3 mb-3 font-sans font-semibold border-b text-neutral-shade-secondary">
                    Tasks on {ticket.number}
                  </div>
                  <TicketCardTaskList
                    ticketId={ticket.id!}
                    tasks={ticket.tasks}
                    selectedTasks={selectedTasks}
                    isSelectable={selectMode === "tasks"}
                    onTaskSelected={(task, ticketId) =>
                      toggleTask(task!, ticketId)
                    }
                  />
                </div>
              </div>,
              document.body
            )}
        </div>
      );
    },
  },
  {
    id: "response_due_date",
    Header: "Response Due Date",
    fields: ["latest_version.response_due_date"],
    sortFields: ["response_due_date"],
    minWidth: 190,
    accessor: ({ ticket }) =>
      formatFullDateTime(ticket.latestVersion?.responseDueDate),
  },
  {
    id: "member_codes",
    Header: "Member Codes",
    fields: ["member_codes_list.key"],
    sortFields: ["member_codes"],
    accessor: ({ ticket }) => {
      let member_codes_str = "";

      ticket.memberCodesList?.forEach((elem) => {
        member_codes_str += `${elem.key}, `;
      });

      return member_codes_str.substring(0, member_codes_str.length - 2);
    },
  },
  {
    id: "reported_date",
    Header: "Reported Date",
    fields: ["latest_version.ticket_reported_date"],
    sortFields: ["ticket_reported_date"],
    minWidth: 190,
    accessor: ({ ticket }) =>
      formatFullDateTime(ticket.latestVersion?.reportedDate),
  },
  {
    id: "create_time",
    Header: "Create Time",
    fields: ["create_time"],
    sortFields: ["create_time"],
    minWidth: 190,
    accessor: ({ ticket }) => formatFullDateTime(ticket?.createTime),
  },
  {
    id: "cross_street_1",
    Header: "Cross Street 1",
    fields: ["latest_version.cross_street_1"],
    sortFields: ["cross_street_1"],
    minWidth: 190,
    accessor: ({ ticket }) => startCase(ticket.latestVersion?.crossStreet1),
  },
  {
    id: "cross_street_2",
    Header: "Cross Street 2",
    fields: ["latest_version.cross_street_2"],
    sortFields: ["cross_street_2"],
    minWidth: 190,
    accessor: ({ ticket }) => startCase(ticket.latestVersion?.crossStreet2),
  },
  {
    id: "locate_remarks",
    width: 300,
    Header: "Remarks",
    fields: ["latest_version.locate_remarks"],
    accessor: ({ ticket }) => ticket.latestVersion?.locateRemarks,
  },
  {
    id: "response_code",
    Header: "Response Code",
    fields: ["positive_responses.code"],
    accessor: ({ ticket }) =>
      // First result is always the last positive response
      // https://urbint.atlassian.net/l/cp/g91Vrrm1
      ticket.positiveResponses && ticket.positiveResponses[0]?.code,
  },
  {
    id: "response_comment",
    Header: "Response Comment",
    width: 300,
    fields: ["positive_responses.comment"],
    accessor: ({ ticket }) =>
      ticket.positiveResponses && ticket.positiveResponses[0]?.comment,
  },
  {
    id: "response_description",
    Header: "Response Description",
    width: 300,
    fields: ["positive_responses.description"],
    accessor: ({ ticket }) =>
      ticket.positiveResponses && ticket.positiveResponses[0]?.description,
  },
];

const FEATURE_FLAGGED_CONTROLLED_COLUMNS: ColumnDefinition<ColumnData>[] = [
  {
    id: "scaled_impact_score",
    Header: "Impact Score",
    fields: ["latest_version.scaled_impact_score"],
    sortFields: ["scaled_impact_score"],
    accessor: ({ ticket }) =>
      ticket.latestVersion?.scaled_impact_score?.toString() ?? "-",
  },
  {
    id: "scaled_threat_score",
    Header: "Damage Threat Score",
    fields: ["latest_version.scaled_threat_score"],
    sortFields: ["scaled_threat_score"],
    accessor: ({ ticket }) =>
      ticket.latestVersion?.scaled_threat_score?.toString() ?? "-",
  },
];

const ALL_COLUMNS = [...DEFAULT_COLUMNS, ...FEATURE_FLAGGED_CONTROLLED_COLUMNS];

export { DEFAULT_COLUMNS, FEATURE_FLAGGED_CONTROLLED_COLUMNS, ALL_COLUMNS };
