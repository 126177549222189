import type { LogoProps } from "./Logo.types";
import { Icon } from "@urbint/silica/";
import logoWithName from "./LogoWithText.png";

const Logo = ({ mode = "full" }: LogoProps) =>
  mode === "full" ? (
    <img src={logoWithName} alt="Urbint" />
  ) : (
    <Icon name="urbint" className="text-brand-40" />
  );

export { Logo };
