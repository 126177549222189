import { sameLabelAndValueOptions } from "@/utils/formUtils";

const FORM_TITLE =
  "If you think the risk rating needs to be revised, we'd appreciate your feedback to enhance our model.";

const TEXT_AREA_LABEL =
  "We'd love to hear more. Can you provide additional details?";

const DROPDOWN_FIELD_NAME = "dropdown";
const TEXTAREA_FIELD_NAME = "textarea";

const OTHER_OPTION = sameLabelAndValueOptions("Other");

const EXCAVATOR_OPTIONS = [
  sameLabelAndValueOptions("Incorrect excavator name"),
  sameLabelAndValueOptions("Inaccurate excavator damage rate"),
  sameLabelAndValueOptions("Inaccurate excavator rank"),
  OTHER_OPTION,
];

const TICKET_TYPE_OPTIONS = [
  sameLabelAndValueOptions("Incorrect ticket type"),
  sameLabelAndValueOptions("Inaccurate ticket type damage rate"),
  sameLabelAndValueOptions("Inaccurate ticket type rank"),
  OTHER_OPTION,
];

const LOCATION_COMPLEXITY_OPTIONS = [
  sameLabelAndValueOptions("Inaccurate location damage rate"),
  OTHER_OPTION,
];

const WORK_TYPE_OPTIONS = [
  sameLabelAndValueOptions("Incorrect work type"),
  sameLabelAndValueOptions("Inaccurate work type damage rate"),
  sameLabelAndValueOptions("Inaccurate work type rank"),
  OTHER_OPTION,
];

export {
  FORM_TITLE,
  TEXT_AREA_LABEL,
  DROPDOWN_FIELD_NAME,
  TEXTAREA_FIELD_NAME,
  OTHER_OPTION,
  EXCAVATOR_OPTIONS,
  TICKET_TYPE_OPTIONS,
  LOCATION_COMPLEXITY_OPTIONS,
  WORK_TYPE_OPTIONS,
};
