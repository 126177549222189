import type { TaskStatus } from "@/models";
import type { TopHeaderProps } from "./TopHeader.types";
import { IonIcon } from "@ionic/react";
import { chevronBack, closeOutline } from "ionicons/icons";
import { useTaskStep } from "@/api";

import {
  useDamagePreventionAuth,
  useOnlineStatus,
  useResponsive,
} from "@/hooks";
import {
  RESPONSE_FORM_PREFIX,
  RESPONSE_FORM_SUBMITTED_SUFFIX,
} from "@/hooks/useResponseFormLogic";
import { TaskOptions } from "./TaskOptions";
import { TaskStatusBadge } from "./TaskStatusBadge";
import { useTasksStepsContext } from "./TasksSteps.context";

const TopHeader = ({ task, closeTaskSidebar }: TopHeaderProps) => {
  // TODO: Fix no-non-null-asserted-optional-chain
  const { data: taskStep } = useTaskStep(task?.taskStepId!, {
    enabled: task === undefined,
  });
  const {
    currentUser: {
      djangoId: userId,
      canEditDeleteAllTasks,
      canEditDeleteMyTasks,
    } = {},
  } = useDamagePreventionAuth();

  const { isMd } = useResponsive();
  const isOnline = useOnlineStatus();

  let status: TaskStatus;
  if (!taskStep || taskStep?.initial) {
    status = "todo";
  } else if (taskStep.terminating) {
    status = "done";
  } else {
    status = "inprogress";
  }

  // ---
  // Temporary fix for offline mode
  // ---
  const isSavedOffline =
    task &&
    localStorage.getItem(`${RESPONSE_FORM_PREFIX}${task.ticketId}-${task.id}`);

  const isSubmmitedOffline =
    task &&
    localStorage.getItem(
      `${RESPONSE_FORM_PREFIX}${task.ticketId}-${task.id}${RESPONSE_FORM_SUBMITTED_SUFFIX}`
    );

  if (isSavedOffline) {
    status = "inprogress";
  }

  if (isSubmmitedOffline) {
    status = "done";
  }

  const { getTaskStep } = useTasksStepsContext();
  // ---
  // Temporary fix for offline mode
  // ---

  return (
    <div className="flex justify-between items-center font-medium my-4">
      <div className="flex">
        <button
          type="button"
          onClick={closeTaskSidebar}
          className="text-neutral-shade-secondary pr-3 text-xl flex"
          data-testid="back-task-button"
        >
          <IonIcon
            icon={isMd ? chevronBack : closeOutline}
            className="pointer-events-none"
          />
        </button>
        <p className="leading-tight">Task #{task?.sequence}</p>
        <TaskStatusBadge status={getTaskStep(task?.id!) || status} />
      </div>
      <div className="flex items-center">
        {isOnline &&
          (canEditDeleteAllTasks ||
            (canEditDeleteMyTasks && task?.assigneeId === userId)) && (
            <TaskOptions task={task} closeTaskSidebar={closeTaskSidebar} />
          )}
      </div>
    </div>
  );
};
export { TaskStatusBadge, TopHeader };
