import type { ServiceCenterPopupContentProps } from "./ServiceCenterPopupContent.types";
import { BodyText } from "@urbint/silica";

const ServiceCenterPopupContent = ({
  ticket,
}: ServiceCenterPopupContentProps) => {
  const { serviceCenters = [] } = ticket;

  return (
    <>
      {serviceCenters.map((serviceCenter) => (
        <BodyText key={serviceCenter.id}>{serviceCenter.name}</BodyText>
      ))}
    </>
  );
};

export { ServiceCenterPopupContent };
