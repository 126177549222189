import type { ActivityListTicketItemProps } from "./ActivityListTicketItem.types";
import { activityPhraseMap } from "./ActivityListTicketItem.constants";

const ActivityListTicketItem = ({
  ticket,
  activity,
}: ActivityListTicketItemProps) => {
  if (!ticket) {
    return null;
  }

  if (!activity?.activityType) {
    return null;
  }

  const getActivityPhraseFn = activityPhraseMap[activity.activityType];

  if (!getActivityPhraseFn) {
    return <p>{`${activity.modelType} - ${activity.activityType}`}</p>;
  }
  return (
    <p>
      {getActivityPhraseFn({
        activity,
        ticket,
      })}
    </p>
  );
};

export { ActivityListTicketItem };
