import type {
  ColumnData,
  TicketTableInstance,
  TicketTableOptions,
} from "./TicketTable.types";
import { useCallback, useEffect, useMemo, useRef } from "react";
import {
  useColumnOrder,
  useFlexLayout,
  useSortBy,
  useTable,
} from "react-table";
import { useTicketSelector } from "@/components/TicketSelectorProvider";
import { TicketOrderField } from "@/utils/damage-prevention";
import { SortOrderDirection } from "@/models";
import {
  useFlexTickets,
  useFlexTicketsCount,
  resetFlexTicketsQuery,
} from "@/api";
import { useTicketColumns } from "@/hooks";
import { useTicketViewEditor } from "../TicketViewEditorProvider";
import { VirtualList } from "../VirtualList";
import { TicketTableRow } from "./TicketTableRow";
import { TicketTableHeader } from "./TicketTableHeader";

const ROW_HEIGHT = 36;

const TicketTable = () => {
  const {
    flexFieldsForTableColumns,
    ticketOrderToSortBy,
    COLUMN_DEFINITIONS,
    COLUMN_DEFINITIONS_BY_ID,
  } = useTicketColumns();

  const {
    dirtyView,
    isInitialized,
    columns,
    isShowingTasks,
    setSortOrder,
    showTask,
  } = useTicketViewEditor();
  const { data, fetchMissingItems, isFetchingNextPage, hasNextPage, status } =
    useFlexTickets(
      {
        ...dirtyView,
        fields: useMemo(
          () => flexFieldsForTableColumns(columns, isShowingTasks),
          [columns, isShowingTasks]
        ),
      },
      { enabled: isInitialized }
    );
  const { data: dataCount } = useFlexTicketsCount(dirtyView, {
    enabled: isInitialized,
  });
  const {
    selectMode,
    selectedTickets,
    selectedTasks,
    toggleTicketId,
    toggleTask,
  } = useTicketSelector();

  const totalSize = useMemo(() => dataCount?.count || 0, [dataCount]);
  const columnData: ColumnData[] = useMemo(
    () =>
      data?.pages
        .flatMap((p) => p.data)
        .map((t) => {
          if (!t.id) throw new Error("Ticket has no ID");
          return {
            // Should we do this in a more central place?
            // It's up to consuming components to know about/and apply this filter
            ticket: { ...t, tasks: t.tasks?.filter(showTask) },
            isSelected: selectedTickets.includes(t.id),
            selectMode,
            selectedTasks: selectedTasks[t.id] ?? [],
            isShowingTasks,
          };
        }) ?? [],
    [
      data?.pages,
      isShowingTasks,
      selectMode,
      selectedTasks,
      selectedTickets,
      showTask,
    ]
  );

  const parentRef = useRef<HTMLDivElement>(null);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setHiddenColumns,
    setColumnOrder,
    setSortBy,
  } = useTable(
    {
      data: columnData,
      columns: COLUMN_DEFINITIONS,
      autoResetHiddenColumns: false,
      manualSortBy: true,
      initialState: {
        toggleTask,
        toggleTicketId,
        setSortOrder,
        sortBy: ticketOrderToSortBy(dirtyView.order),
      },
    } as TicketTableOptions,
    useFlexLayout,
    useColumnOrder,
    useSortBy
  ) as unknown as TicketTableInstance;

  const getVisibleColumns = useCallback((): Set<string> => {
    const visibleColumns = new Set<string>();
    columns.forEach((col) => {
      const columnDef = COLUMN_DEFINITIONS_BY_ID.get(col.field);

      if (columnDef) {
        visibleColumns.add(columnDef.id);
      }
    });

    if (isShowingTasks) visibleColumns.add("tasks");

    return visibleColumns;
  }, [columns, isShowingTasks]);

  const renderRows = useCallback(
    ({ index, height }: { index: number; height?: number }) => {
      const row = rows[index];
      if (!row) throw new Error(`no data for index {index}`);

      prepareRow(row);
      return (
        <TicketTableRow
          key={row.original.ticket.id}
          row={row}
          height={height}
        />
      );
    },
    [prepareRow, rows]
  );

  const handleSortChange = (columnId: string, isDesc?: boolean) => {
    const sortFields = COLUMN_DEFINITIONS_BY_ID.get(columnId)?.sortFields;

    const sortField = (sortFields ?? [])[0];
    if (!sortField) return;

    // Reset Sort when sort is ascending
    if (isDesc === false) {
      // Reset to default sort order
      setSortOrder({
        field: TicketOrderField.work_start_date,
        direction: SortOrderDirection.DESC,
      });
    } else {
      const desc = !isDesc;
      setSortOrder({
        field: sortField,
        direction: desc ? SortOrderDirection.DESC : SortOrderDirection.ASC,
      });
    }
  };

  useEffect(() => {
    // Slow network prevention we might have a query stuck in fetch while re-entering the component.
    // For more details check: [LENS-10417]
    if (status === "success" && isFetchingNextPage && hasNextPage) {
      resetFlexTicketsQuery();
    }
  }, []);

  useEffect(() => {
    const visibleColumns = getVisibleColumns();
    const hiddenColumns = COLUMN_DEFINITIONS.map((c) => c.id).filter(
      (id) => !visibleColumns.has(id)
    );

    setHiddenColumns(hiddenColumns);
    setColumnOrder([...visibleColumns]);
  }, [setHiddenColumns, columns, getVisibleColumns]);

  useEffect(() => {
    setSortBy(ticketOrderToSortBy(dirtyView.order));
  }, [dirtyView.order]);

  const tableProps = getTableProps({
    className: "w-full overflow-scroll h-full",
  });

  const keyExtractor = useCallback(
    (index: number) => `${dirtyView.name}-${index}`,
    [dirtyView?.name]
  );

  return (
    <div id="ticket-table" className="relative grid w-full h-full font-sans">
      <div
        className={tableProps.className}
        ref={parentRef}
        role={tableProps.role}
        style={tableProps.style}
      >
        <TicketTableHeader
          headerGroups={headerGroups}
          sortChange={handleSortChange}
        />
        <div {...getTableBodyProps({ className: "_tbody relative" })}>
          <VirtualList
            estimateItemHeight={ROW_HEIGHT}
            keyExtractor={keyExtractor}
            items={columnData}
            needsMoreItemsThreshold={50}
            onNeedsMoreItems={fetchMissingItems}
            scrollParentRef={parentRef}
            ViewDelegate={renderRows}
            totalSize={totalSize}
          />
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { TicketTable };
