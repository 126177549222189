import type { TaskAttachments } from "@/models";
import { useMutation } from "@tanstack/react-query";

import { UrbintApi } from "@/utils/UrbintApi";
import {
  invalidateUseTaskAttachmentsQuery,
  invalidateUseTicketActivityQuery,
  removeUseTaskAttachmentQuery,
  removeUseTicketActivityQuery,
  taskAttachmentsIdent,
} from "@/api";
import { handleApiResponse } from "@/api/helpers";
import { queryClient } from "@/api/client";

const api = new UrbintApi();

const deleteTaskAttachmentIdent = () => ["delete_task_attachment"];

type useDeleteTaskAttachmentProps = {
  attachmentId: number;
  taskId: number;
  ticketId: number;
};

const deleteTaskAttachment = ({
  attachmentId,
  taskId,
}: useDeleteTaskAttachmentProps) => {
  if (!taskId) {
    throw new Error("TaskId are not defined");
  }

  return api
    .delete(`tasks/${taskId}/attachments`, attachmentId)
    .then(handleApiResponse);
};

const handleOptimisticUpdate = ({
  attachmentId,
  taskId,
}: useDeleteTaskAttachmentProps) => {
  // Optimistically update to the new value
  queryClient.setQueryData(
    taskAttachmentsIdent(taskId),
    (attachments: TaskAttachments[] | undefined) =>
      attachments?.filter(
        (attachment: TaskAttachments) => attachment.id !== attachmentId
      )
  );
  // Return a context object with the snapshot value
  return { attachmentId, taskId };
};

const useDeleteTaskAttachment = () =>
  useMutation(deleteTaskAttachmentIdent(), deleteTaskAttachment, {
    // When mutate is called:
    onMutate: handleOptimisticUpdate,
    onSettled: (_, __, { taskId, attachmentId, ticketId }) => {
      removeUseTaskAttachmentQuery(taskId, attachmentId);
      removeUseTicketActivityQuery(ticketId);
      invalidateUseTicketActivityQuery(ticketId);
      invalidateUseTaskAttachmentsQuery(taskId);
    },
  });

export { deleteTaskAttachmentIdent, useDeleteTaskAttachment };
