import type { IconProps } from "@urbint/silica";

const TOAST_ICONS: { [key: string]: IconProps["name"] } = {
  success: "check",
  error: "error",
  warning: "warning",
  info: "info_circle",
};

export { TOAST_ICONS };
