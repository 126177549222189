import type { TicketStage } from "@/utils/damage-prevention";
import { useMutation } from "@tanstack/react-query";
import { UrbintApi } from "@/utils/UrbintApi";
import { invalidateUseTasksForTicketQuery } from "@/api/tasks";
import {
  invalidateUseTicketActivityQuery,
  invalidateUseTicketQuery,
} from "../queries";

const api = new UrbintApi();
const setTicketStage = async (ticketId: number, stage: TicketStage) => {
  const response = await api.patch(
    `tickets/${ticketId!}`,
    JSON.stringify({ stage })
  );

  if (response.error) {
    if (response.json) {
      throw response.json;
    }
    throw response.error;
  }
  return response;
};

const useSetTicketStage = (ticketId: number) =>
  useMutation((stage: TicketStage) => setTicketStage(ticketId, stage), {
    onSuccess: () => {
      invalidateUseTicketQuery(ticketId, true);
      invalidateUseTasksForTicketQuery(ticketId);
      invalidateUseTicketActivityQuery(ticketId);
    },
  });

export { useSetTicketStage };
