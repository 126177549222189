import type { OverflowMenuGroupProps } from "./OverflowMenuGroup.types";

const OverflowMenuGroup = ({ children }: OverflowMenuGroupProps) => {
  if (Array.isArray(children) && children.length === 0) return null;

  return (
    <div className="border-t border-solid first:border-t-0 border-neutral-shade-border-light">
      {children}
    </div>
  );
};

export { OverflowMenuGroup };
