import { dangerouslyGetUrbintAuthData } from "@urbint/auth-client";
import { StatusCodes } from "http-status-codes";
import { getCookies } from "@/utils";

import { getServerUrl } from "./getServerUrl";

type KnownLensEndpoints =
  | "811_centers"
  | "apps"
  | "egress_routes"
  | "excavators"
  | "invited_users"
  | "me/preferences"
  | "member_codes"
  | "notifications"
  | "non_ticketed_events"
  | "non_ticketed_events/types"
  | "opco_apps"
  | "opcos"
  | "organization"
  | "permissions"
  | "positive_response_codes"
  | "positive_responses"
  | "public_config"
  | "fleet_location"
  | "reports_config"
  | "response_form_answers"
  | "response_form_answers/bulk"
  | "response_forms"
  | "roles"
  | "saved_views"
  | "service_centers"
  | "task_comments"
  | "task_steps"
  | "task_types"
  | "tasks"
  | "tasks/bulk"
  | "tickets/bulk_action"
  | "ticket_activities"
  | "ticket_comments"
  | "ticket_points"
  | "ticket_versions"
  | "tickets_flex"
  | "tickets"
  | `tickets/${number}`
  | `tickets/${number}/threat_insights`
  | "user_access"
  | "users"
  | "workflows"
  | `tasks/${number}`
  | `tasks/${number}/attachments`
  | `non_ticketed_events/${number}`
  | `non_ticketed_events/${number}/attachments`
  | `tickets_flex/count`
  | `tickets_flex/items`
  | `tickets_flex/${number}`;

type KnownLensEndpointActions =
  | "complete_step"
  | "relations"
  | "form/submit"
  | "search_tickets"
  | "transition_to"
  | "transitions"
  | "dynamic_options"
  | "available_filters"
  | "filters"
  | "photos"
  | "attachments"
  | "count"
  | `attachments/${number}`
  | `impact_insights`;

type ApiResponse<T> = {
  json?: T;
  error?: Error;
  response?: Response;
};

interface ApiMethodParameters {
  endPoint: KnownLensEndpoints;
  body: BodyInit;
  page: number;
  queryParams?: URLSearchParams | Record<string, string>;
  headers?: HeadersInit;
  id?: string | number;
  action?: KnownLensEndpointActions;
}

interface DjangoPaginatedResponse<T> {
  count: number;
  next: string | null;
  previous: string | null;
  results: T[];
}

/**
 * @deprecated
 */
export class UrbintApi {
  controller: AbortController | undefined;

  abort() {
    this.controller?.abort();
  }

  requestInit(init?: RequestInit): RequestInit {
    const cookies = getCookies();
    // race-casey, but should be set by the context before any components
    const { bearerToken } = dangerouslyGetUrbintAuthData();

    if (!this.controller) {
      this.controller = new AbortController();
    }
    return {
      method: "GET",
      ...init,
      credentials: "include",
      headers: {
        ...init?.headers,
        "Content-Type": "application/json",
        Authorization: `Bearer ${bearerToken}`,
        Accept: "application/json",
        "X-CSRFToken": cookies.csrftoken,
      },
      signal: this.controller.signal,
    };
  }

  getOne<T = any>({
    endPoint,
    id,
    action,
    queryParams,
  }: Pick<ApiMethodParameters, "endPoint" | "id" | "action" | "queryParams">) {
    this.controller = new AbortController();
    if (!(queryParams instanceof URLSearchParams))
      queryParams = new URLSearchParams(queryParams);
    const queryString = queryParams ? queryParams.toString() : "";

    const builtEndPoint = `${this.buildEndpoint(
      endPoint,
      id,
      action
    )}?${queryString}`;

    return this.sandbox<T>(() => fetch(builtEndPoint, this.requestInit()));
  }

  getPage<T = any>({
    endPoint,
    page,
    queryParams,
    id,
    action,
  }: Omit<ApiMethodParameters, "body" | "headers">) {
    this.controller = new AbortController();
    if (!(queryParams instanceof URLSearchParams))
      queryParams = new URLSearchParams(queryParams);
    const queryString = queryParams ? queryParams.toString() : "";

    const builtEndPoint = `${this.buildEndpoint(endPoint, id, action)}?page=${
      page || 1
    }&${queryString}`;

    return this.sandbox<DjangoPaginatedResponse<T>>(() =>
      fetch(builtEndPoint, this.requestInit())
    );
  }

  getMany<T = any>({
    endPoint,
    queryParams,
    id,
    action,
  }: Omit<ApiMethodParameters, "page" | "body" | "headers">) {
    this.controller = new AbortController();
    if (!(queryParams instanceof URLSearchParams))
      queryParams = new URLSearchParams(queryParams);
    const queryString = queryParams ? queryParams.toString() : "";

    const builtEndPoint = `${this.buildEndpoint(
      endPoint,
      id,
      action
    )}?${queryString}`;
    const opts = this.requestInit();

    return this.sandbox<T[]>(() => fetch(builtEndPoint, opts));
  }

  request<T = any>({ endPoint, queryParams, ...opts }: ApiMethodParameters) {
    this.controller = new AbortController();

    if (!(queryParams instanceof URLSearchParams))
      queryParams = new URLSearchParams(queryParams);

    const queryString = queryParams ? queryParams.toString() : "";
    const builtEndPoint = `${this.buildEndpoint(endPoint)}?${queryString}`;

    return this.sandbox<T>(() => fetch(builtEndPoint, opts));
  }

  /**
   * A relatively generic, but authenticated wrapper around the native fetch
   * function which also handles error responses from the server in the same
   * way as the rest of the methods of this class would.
   *
   * @param apiPath A relative api path, e.g. `/tickets/`, not `/api/tickets/`
   * @param init The options to pass to the native fetch function, will be merged with default options to provide authentication headers
   */
  fetch<T = any>(apiPath: string, init?: RequestInit | undefined) {
    const opts = this.requestInit(init);
    return this.sandbox<T>(() =>
      window.fetch(this.buildEndpoint(apiPath), opts)
    );
  }

  async post<T = any>(
    endPoint: KnownLensEndpoints,
    body: any,
    action?: string
  ) {
    this.controller = new AbortController();
    const opts = await this.requestInit();
    opts.method = "POST";
    opts.body = body;
    return this.sandbox<T>(() =>
      fetch(this.buildEndpoint(endPoint, action), opts)
    );
  }

  async patch<T = any>(
    endPoint: KnownLensEndpoints,
    body: string,
    id?: string | number, // Id is Optional and not mandatory because we have a me/preferences endpoint (https://urbint.atlassian.net/browse/DPAPP-821).
    action?: KnownLensEndpointActions | undefined
  ) {
    this.controller = new AbortController();
    const opts = await this.requestInit();
    opts.method = "PATCH";
    opts.body = body;
    return this.sandbox<T>(() =>
      fetch(this.buildEndpoint(endPoint, id, action), opts)
    );
  }

  async put<T = any>({
    action,
    body,
    endPoint,
    headers,
    id,
  }: Omit<ApiMethodParameters, "page">) {
    this.controller = new AbortController();
    const opts = await this.requestInit();
    opts.headers = { ...opts.headers, ...(headers || {}) };
    opts.method = "PUT";
    opts.body = body;
    return action
      ? this.sandbox<T>(() =>
          fetch(this.buildEndpoint(endPoint, id, action), opts)
        )
      : this.sandbox<T>(() => fetch(this.buildEndpoint(endPoint, id), opts));
  }

  delete(endPoint: KnownLensEndpoints, id: string | number) {
    this.controller = new AbortController();
    const opts = this.requestInit();
    opts.method = "DELETE";
    return this.sandbox(() => fetch(this.buildEndpoint(endPoint, id), opts));
  }

  // Fetch will reject on network failures, so we'll catch those here.
  private async sandbox<T>(
    execFn: () => Promise<Response>
  ): Promise<ApiResponse<T>> {
    let response: Response | undefined;
    let json: T | undefined;
    let error: Error | undefined;
    try {
      response = await execFn();
      const contentType = response.headers.get("content-type");

      if (contentType === "application/json") {
        json =
          response.status === StatusCodes.NO_CONTENT
            ? {}
            : await response.json();
      }

      if (!response.ok) {
        if (response.status === StatusCodes.FORBIDDEN) {
          const { logout } = dangerouslyGetUrbintAuthData();
          if (logout) logout();
        }
        error = new Error(`${response.status} - ${response.statusText}`);
      }
    } catch (e) {
      error = e instanceof Error ? e : new Error(`${e}`);
    }
    return { response, json, error };
  }

  /**
   * When provided a list of parts, constructs an api endpoint from them
   *    eg, ["tickets", 1, "relations"] will return "api/tickets/1/relations"
   *
   * If any item in the parts list is undefined, it will be skipped
   *    eg, ["tickets", undefined, "ingest"] will return "api/tickets/ingest"
   */
  private buildEndpoint(...parts: (string | number | undefined)[]) {
    const pathname = `api/${parts.filter(Boolean).join("/")}/`;
    return getServerUrl({ pathname }).href;
  }
}

export type {
  KnownLensEndpoints,
  KnownLensEndpointActions,
  DjangoPaginatedResponse,
  ApiResponse,
};
