import type { ThreatBadgeProps } from "./ThreatBadge.type";
import { RiskBadge } from "@/components/RiskBadge";

const ThreatBadge = ({ threatRank }: ThreatBadgeProps) => (
  <div className="flex flex-row gap-1 items-center">
    <RiskBadge value={threatRank} type="simple" />
  </div>
);

export { ThreatBadge };
