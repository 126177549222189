import type { SliderHeaderProps } from "./SliderHeader.types";
import { Icon } from "@urbint/silica";

const SliderHeader = ({ closeSlider }: SliderHeaderProps) => (
  <span
    slot="container-start"
    className="flex justify-end absolute right-2 top-2 z-10"
  >
    <button
      type="button"
      className="flex items-center justify-center text-2xl h-10 w-10"
      onClick={() => closeSlider()}
    >
      <Icon name="close_big" />
    </button>
  </span>
);

export { SliderHeader };
