import type { LastResponseSectionProps } from "./LastResponseSection.types";
import type { DetailProps } from "../DetailsSectionSplitContent/Detail";
import { startCase } from "@/format";
import { DetailsSection } from "../DetailsSection";
import { DetailsSectionSplitContent } from "../DetailsSectionSplitContent";

const LastResponseSection = ({ ticket }: LastResponseSectionProps) => {
  const positiveResponses = ticket?.positiveResponses;
  const lastResponse = positiveResponses && positiveResponses[0];

  const hasAnyFields = Boolean(
    lastResponse?.code || lastResponse?.comment || lastResponse?.description
  );

  if (!lastResponse || !hasAnyFields) {
    return null;
  }

  const { code, description, comment } = lastResponse;

  const leftFields: DetailProps[] = [
    {
      label: "Response Code",
      value: startCase(code),
      layout: "vertical",
    },
    {
      label: "Response Description",
      value: description,
      layout: "vertical",
    },
    {
      label: "Response Comment",
      value: comment,
      layout: "vertical",
    },
  ];

  return (
    <DetailsSection title="Last Response">
      <DetailsSectionSplitContent left={leftFields} />
    </DetailsSection>
  );
};

export { LastResponseSection };
