import type { FormLabelProps } from "./FormLabel.types";

const FormLabel = ({ children, htmlFor }: FormLabelProps) => (
  <label
    className="font-semibold font-sans text-sm leading-5 mb-1 text-neutral-shade-secondary"
    htmlFor={htmlFor}
  >
    {children}
  </label>
);

export { FormLabel };
