import type { WorkflowGroupProps } from "./WorkflowGroup.types";
import type { Task } from "@/models";
import { TaskCard } from "./TaskCard";

const WorkflowGroup = ({
  ticket,
  tasks,
  name,
  onTaskClick,
  titleClassName,
}: WorkflowGroupProps) => {
  const onClickTaskCard = (task: Task) => () =>
    onTaskClick && onTaskClick(task);
  return (
    <section className="first:-mt-4">
      <h3
        className={`font-semibold text-sm text-black text-opacity-66 mt-6 mb-3 ${titleClassName}`}
        data-testid="workflow-title"
      >
        {name} tasks ({tasks.length})
      </h3>
      {tasks.length === 0 ? (
        <p className="text-black text-opacity-66">
          There are no {name.toLowerCase()} tasks for this ticket.
        </p>
      ) : (
        <ul className="space-y-2">
          {tasks.map((task) => (
            <li key={task.id}>
              <TaskCard
                task={task}
                onClick={onClickTaskCard(task)}
                memberCodes={ticket?.memberCodes}
              />
            </li>
          ))}
        </ul>
      )}
    </section>
  );
};

export { WorkflowGroup };
