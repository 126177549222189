import type { ExcavatorDetailsSectionProps } from "./ExcavatorDetailsSection.types";
import { formatPhoneNumber, startCase } from "@/format";
import { DetailsSection } from "../DetailsSection";
import { DetailsSectionSplitContent } from "../DetailsSectionSplitContent";

const ExcavatorDetailsSection = ({ ticket }: ExcavatorDetailsSectionProps) => {
  const primaryContact = ticket?.latestVersion?.primaryContact;
  const altContact = ticket?.latestVersion?.altContact;

  return (
    <DetailsSection title="Excavator Details">
      <DetailsSectionSplitContent
        left={[
          {
            label: "Company",
            value: startCase(primaryContact?.companyName),
            testId: "excavatorCompany",
          },
          {
            label: "Address",
            value: startCase(primaryContact?.address),
            testId: "excavatorAddress",
          },
          {
            label: "Name",
            value: startCase(primaryContact?.name),
            testId: "excavatorName",
          },
          {
            label: "Phone",
            value: formatPhoneNumber(primaryContact?.phoneNumber),
            testId: "excavatorPhone",
          },
          {
            label: "Email",
            value: primaryContact?.email?.toLowerCase(),
            testId: "excavatorEmail",
          },
        ]}
        right={[
          {
            label: "Alt name",
            value: startCase(altContact?.name),
            testId: "excavatorAltName",
          },
          {
            label: "Alt phone",
            value: formatPhoneNumber(altContact?.phoneNumber),
            testId: "excavatorAltPhone",
          },
        ]}
      />
    </DetailsSection>
  );
};

export { ExcavatorDetailsSection };
