import type { TicketQueueNavBarItemProps } from "./TicketQueueNavBarItem.types";
import { Icon } from "@urbint/silica";
import { useState } from "react";
import { MenuPanel } from "@/components/MenuPanel";
import { Button } from "@/common/Button";
import { TicketQueue } from "@/components/TicketQueue";

const TicketQueueNavBarItem = ({
  queue,
  optimizeQueue,
  currentUser,
}: TicketQueueNavBarItemProps) => {
  const [open, setOpen] = useState(false);
  const [optimizeModalIsOpen, setOptimizeModalIsOpen] = useState(false);

  const show = () => setOpen(true);
  const hide = () => setOpen(false);
  const optimizeModalDidClose = () => {
    // re-open the menu after the modal closes
    if (optimizeModalIsOpen) {
      show();
      setOptimizeModalIsOpen(false);
    }
  };
  const optimizeModalDidOpen = () => {
    // close the menu so it's not open at the same time as the modal
    if (optimizeModalIsOpen === false) {
      hide();
      setOptimizeModalIsOpen(true);
    }
  };

  // close the menu as long as it doesn't have any sub modals open at the moment
  const handleClickOutside = () => {
    if (optimizeModalIsOpen === false) hide();
  };

  return (
    <MenuPanel
      arrow
      show={open}
      onClickOutsideMenus={handleClickOutside}
      placement="bottom-end"
      renderButton={() => (
        <div slot="end" className="flex flex-col justify-center h-14">
          <Button
            className="font-semibold text-white bg-brand-40 h-9"
            onClick={open ? hide : show}
            testId="queue-button"
          >
            <div className="flex">
              <Icon name="route" className="mr-2 text-2xl" />
              <span>{queue?.data?.items.length ?? "!!"}</span>
            </div>
          </Button>
        </div>
      )}
    >
      <div className="flex flex-col max-h-[80vh] overflow-hidden">
        <TicketQueue
          onCloseOptimizeModal={optimizeModalDidClose}
          onOpenOptimizeModal={optimizeModalDidOpen}
          queue={queue}
          optimizeQueue={optimizeQueue}
          currentUser={currentUser}
        />
      </div>
    </MenuPanel>
  );
};

export { TicketQueueNavBarItem };
