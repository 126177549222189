import type { LazyLoadProps } from "./LazyLoad.types";
import { useRef, useEffect, useState } from "react";

const defaultPlaceHolder = () => (
  <span
    data-testid="placeholder"
    className=" block h-5 w-full bg-gray-30 rounded-md animate-pulse"
  />
);

const LazyLoad = ({
  children,
  threshold = 0.1,
  rootMargin = "0px",
  Placeholder = defaultPlaceHolder,
}: LazyLoadProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const observerInstance = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setIsVisible(true);
              observer.disconnect();
            }
          });
        },
        { threshold, rootMargin }
      );

      if (elementRef.current) {
        observerInstance.observe(elementRef.current);
      }

      return () => {
        if (elementRef.current) {
          observerInstance.unobserve(elementRef.current);
        }
      };
    }
  }, [threshold, rootMargin]);

  return <div ref={elementRef}>{isVisible ? children : <Placeholder />}</div>;
};

export { LazyLoad };
