import type { NonTicketedEventAttachments } from "@/models/NonTicketedEventAttachments";
import { useMutation } from "@tanstack/react-query";
import { handleApiResponse } from "@/api/helpers";
import { UrbintApi } from "@/utils/UrbintApi";
import { nonTicketedEventAttachmentsIdent } from "@/api";
import { queryClient } from "@/api/client";

const api = new UrbintApi();

const deleteNonTicketedEventAttachmentIdent = () => [
  "delete_non_ticketed_event_attachment",
];

type deleteNonTicketedEventAttachment = {
  eventId: number;
  attachmentId: number;
};

const deleteNonTicketedEventAttachment = ({
  eventId,
  attachmentId,
}: deleteNonTicketedEventAttachment) => {
  if (!eventId) {
    throw new Error("Event id not defined");
  }

  return api
    .delete(`non_ticketed_events/${eventId}/attachments`, attachmentId)
    .then(handleApiResponse);
};

const handleOptimisticUpdate = ({
  eventId,
  attachmentId,
}: deleteNonTicketedEventAttachment) => {
  queryClient.setQueryData(
    nonTicketedEventAttachmentsIdent(eventId),
    (attachments: NonTicketedEventAttachments[] | undefined) =>
      attachments?.filter(
        (attachment: NonTicketedEventAttachments) =>
          attachment.id !== attachmentId
      )
  );
  return { eventId, attachmentId };
};

const useDeleteNonTicketedEventAttachment = () =>
  useMutation(
    deleteNonTicketedEventAttachmentIdent(),
    deleteNonTicketedEventAttachment,
    {
      onMutate: handleOptimisticUpdate,
    }
  );

export {
  useDeleteNonTicketedEventAttachment,
  deleteNonTicketedEventAttachmentIdent,
};
