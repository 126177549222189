import type { EmptyStateContainerProps } from "./EmptyStateContainer.types";
import classNames from "classnames";

const EmptyStateContainer = ({
  children,
  mode,
  testId,
}: EmptyStateContainerProps) => (
  <div
    className={classNames(
      "rounded-lg flex flex-col items-center justify-center m-3",
      {
        "bg-gray-10 text-neutral-shade-secondary font-semibold  p-4":
          mode !== "error",
        "bg-system-error-10 border border-system-error-30  px-3 py-2":
          mode === "error",
      }
    )}
    data-testid={testId}
  >
    {children}
  </div>
);

export { EmptyStateContainer };
