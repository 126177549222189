import type { DamagePreventionUserProfile } from "@/utils/types";
import { useUrbintAuth } from "@urbint/auth-client";

/**
 * A typed wrapper around useUrbintAuth that yields a currentUser property that
 * is properly typed according to how `decorateCurrentUser` modified it
 * specifically for damage prevention.
 */
const useDamagePreventionAuth = () =>
  useUrbintAuth<DamagePreventionUserProfile>();

export { useDamagePreventionAuth };
