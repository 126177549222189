/** This file contains types (and/or interfaces, enums) for the `Map` component */
import type { PropsWithChildren } from "react";
import type { Feature, Point } from "geojson";
import type {
  Layer,
  Map as GLMapType,
  PointLike,
  PaddingOptions,
} from "mapbox-gl";
import type { LngLatBoundsLike, ViewState } from "react-map-gl";
import {
  type MapEvent,
  type MapRef,
  type MapLayerMouseEvent,
} from "react-map-gl";

//
// --- Types
//

/**
 * Bounding box type.
 */
type BBoxType = [LngLatType, LngLatType];

/**
 * Props for clustering features.
 */
type ClusterProps = {
  cluster: boolean;
  point_count: boolean;
  cluster_id: number;
};

/**
 * Array containing data point longitude ant latitude.
 */
type LngLatType = [number, number];

/**
 * Props for main `Map` component.
 */
type MapProps = PropsWithChildren<{
  onClick?: (event: MapLayerMouseEvent) => void;
  onMouseEnter?: (event: MapLayerMouseEvent) => void;
  onMouseLeave?: (event: MapLayerMouseEvent) => void;
  onLoad?: (event: MapEvent) => void;
  interactiveLayerIds?: string[];
  /**
   * mapbox style - https://docs.mapbox.com/api/maps/styles
   */
  style: string;
  token?: string;
  mapRef?: React.RefObject<MapRef>;
  viewState?: Partial<ViewState>;
  initialViewState?: Partial<ViewState> & {
    bounds?: LngLatBoundsLike;
    longitude?: number;
    latitude?: number;
    fitBoundsOptions?: {
      offset?: PointLike;
      minZoom?: number;
      maxZoom?: number;
      padding?: number | PaddingOptions;
    };
  };
}>;

/**
 * Map type definition.
 */
type MapType = GLMapType;

/**
 * Map feature type definition.
 */
type MapFeature<T = any> = Feature<Point, T> & {
  layer: Layer;
  source: string;
  id: number;
};

/**
 * Padding props.
 */
type PaddingProps = {
  top: number;
  left: number;
  bottom: number;
  right: number;
};

//
// --- ENUMS
//

/**
 * Decision expression operators
 * @reference https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/#decision
 */
enum DecisionTypeEnum {
  ALL = "all",
  ANY = "any",
  CASE = "case",
  COALESCE = "coalesce",
  EQUAL = "==",
  GT = ">",
  GTE = ">=",
  LT = "<",
  LTE = "<=",
  MATCH = "match",
  NOT = "!",
  NOT_EQUAL = "!=",
  WITHIN = "within",
}

/**
 * Lookup expression operators
 * @reference https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/#lookup
 */
enum LookupTypeEnum {
  AT = "at",
  GET = "get",
  HAS = "has",
  IN = "in",
  INDEX_OF = "index-of",
  LENGTH = "length",
  SLICE = "slice",
}

/**
 * Map layer IDs
 */
enum MapLayerIdEnum {
  CLUSTER = "cluster",
  TICKET = "ticket",
  TICKET_SELECTION = "ticket-selection",
  TICKET_QUEUE = "ticket-queue",
}

/**
 * Map layer types
 * @reference https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/
 */
enum MapLayerTypeEnum {
  BACKGROUND = "background",
  CIRCLE = "circle",
  FILL = "fill",
  FILL_EXTRUSION = "fill-extrusion",
  HEATMAP = "heatmap",
  HILLSHADE = "hillshade",
  LINE = "line",
  RASTER = "raster",
  SKY = "sky",
  SYMBOL = "symbol",
}

/**
 * Map source IDs
 */
enum MapSourceIdEnum {
  TICKETS = "tickets",
}

export type {
  BBoxType,
  ClusterProps,
  LngLatType,
  MapProps,
  MapType,
  MapFeature,
  PaddingProps,
};
type ScoreType = "damage" | "impact" | "due_date";
export {
  DecisionTypeEnum,
  LookupTypeEnum,
  MapLayerIdEnum,
  MapLayerTypeEnum,
  MapSourceIdEnum,
  type ScoreType,
};
