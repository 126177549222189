import type {
  SelectMode,
  TicketId,
  TicketSelectorContextProps,
  TicketSelectorProps,
} from "./TicketSelector.types";
import type { FlexTask, FlexTicket } from "@/models";
import { useCallback, useState, useMemo } from "react";
import { TicketSelectorContext } from "./TicketSelector.context";

const TicketSelectorProvider = ({ children }: TicketSelectorProps) => {
  const [selectedTickets, setSelectedTickets] = useState<TicketId[]>([]);
  const [selectedTasks, setTasks] = useState<{ [key in TicketId]: FlexTask[] }>(
    {}
  );
  const [selectMode, setSelectMode] = useState<SelectMode | undefined>();

  /** Toggle the selection state of a ticket on/of and return the updated state */
  const toggleTicketId = useCallback((ticketId: TicketId) => {
    setSelectedTickets((selectedTicket) => {
      if (selectedTicket.includes(ticketId)) {
        return selectedTicket.filter((id) => id !== ticketId);
      }
      return [...selectedTicket, ticketId];
    });
  }, []);

  /** Toggle selection state for a task on/off and return the updated state */
  const toggleTask = useCallback((task: FlexTask, ticketId: TicketId) => {
    setTasks((s) => {
      const currentTaskIds = s[ticketId] ?? [];
      const isEnabled = s[ticketId]?.includes(task);

      if (isEnabled) {
        const taskIds = currentTaskIds?.filter((t) => t !== task);
        const newState = { ...s, [ticketId]: taskIds };

        if (taskIds.length === 0) {
          delete newState[ticketId];
        }

        return newState;
      }
      return { ...s, [ticketId]: [...currentTaskIds, task] };
    });
  }, []);

  const clearSelectedTickets = useCallback(() => {
    setSelectedTickets([]);
  }, []);

  const clearSelectedTasks = useCallback(() => {
    setTasks({});
    setSelectedTickets([]);
  }, []);

  /** Given a list of tickets loop through each one of them and toggle each task */
  const selectAllTasks = useCallback((tickets: FlexTicket[]) => {
    // Prevention for un-toggling an already selected task
    clearSelectedTasks();

    tickets.forEach((ticket) => {
      const { id } = ticket;
      if (id) {
        ticket.tasks?.forEach((task) => {
          toggleTask(task, id);
        });
      }
    });
  }, []);

  const reset = useCallback(() => {
    setSelectedTickets([]);
    setTasks({});
    setSelectMode(undefined);
  }, []);

  const getSelectedTasksForTicket = useCallback(
    (ticket?: FlexTicket) => {
      if (!ticket || !ticket.id) return undefined;
      return selectedTasks[ticket.id];
    },
    [selectedTasks]
  );

  const getAllSelectedTasks = useCallback(
    () => Object.values(selectedTasks).flat(),
    [selectedTasks]
  );

  const value: TicketSelectorContextProps = useMemo(
    () => ({
      selectedTasks,
      selectedTickets,
      selectMode,
      selectAllTasks,
      toggleTask,
      toggleTicketId,
      setSelectMode,
      reset,
      clearSelectedTasks,
      clearSelectedTickets,
      getSelectedTasksForTicket,
      getAllSelectedTasks,
      setSelectedTickets,
    }),
    [
      selectedTasks,
      selectedTickets,
      selectMode,
      selectAllTasks,
      toggleTask,
      toggleTicketId,
      reset,
      clearSelectedTasks,
      clearSelectedTickets,
      getSelectedTasksForTicket,
      getAllSelectedTasks,
      setSelectedTickets,
    ]
  );

  return (
    <TicketSelectorContext.Provider value={value}>
      {children}
    </TicketSelectorContext.Provider>
  );
};

export { TicketSelectorProvider };
