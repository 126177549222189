import type { MemberCodeSerializer } from "@/utils/damage-prevention";
import { UrbintModel } from "@/utils/UrbintModel";

class MemberCode extends UrbintModel<MemberCodeSerializer> {
  get id() {
    return this.castNumber(this.data.id);
  }

  get key() {
    return this.castString(this.data.key);
  }

  get utilityType() {
    return this.castString(this.data.utility_type);
  }

  get oneCallCenter() {
    return this.castNumber(this.data.eight11_center);
  }

  compare(instance: MemberCode) {
    return this.id - instance.id;
  }

  serialize(): MemberCodeSerializer {
    return this.data;
  }
}

export { MemberCode };
