import type { SelectMenuProps } from "./SelectMenu.types";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";

const SelectMenu = <T,>({
  children,
  options,
  disabled,
  theme,
  onSelect,
}: SelectMenuProps<T>) => (
  <Menu as="div" className="relative font-sans h-full w-full">
    {({ open }) => (
      <>
        <Menu.Button
          disabled={disabled}
          className={classNames(theme?.menuBtn, {
            [`${theme?.close?.menuBtn}`]: open,
          })}
        >
          {children}
        </Menu.Button>
        <Transition
          show={open}
          as={Fragment}
          enter="duration-75"
          enterFrom="opacity-0 scale-0"
          enterTo="opacity-100 scale-100"
          leave="duration-75"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-0"
        >
          <Menu.Items
            static
            className="absolute right-0 font-sans transition transform-gpu ease-out duration-100 z-30 hd-10 origin-top-right mt-2 bg-white shadow-elevation-20 w-56 py-2 max-h-56 overflow-y-auto"
          >
            {options?.map((opt) => (
              <Menu.Item key={opt.label}>
                {({ active }) => (
                  <button
                    type="button"
                    data-testid={opt?.dataTestId}
                    className={`w-full px-4 py-3 text-left text-base ${
                      active && "bg-black bg-opacity-6"
                    }`}
                    onClick={onSelect && (() => onSelect(opt.value))}
                  >
                    {opt.label}
                  </button>
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </>
    )}
  </Menu>
);

export { SelectMenu };
