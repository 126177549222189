import type { MapsButtonProps } from "./MapsButton.types";
import classNames from "classnames";
import { Button } from "../Button";

/**
 * Attempts to open the given URL in the device's default maps application,
 * falling back to Google Maps if necessary.
 */
function openMapsLink(address: string) {
  if (!address || address.trim().length === 0) {
    alert(
      "Sorry, there is no address available to send to your maps application"
    );
    return;
  }
  try {
    const url = new URL(`maps:q=${address}`);
    window.location.assign(url.href);
  } catch (e) {
    // open the address in google maps
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${address}`,
      "urbint-google-maps"
    );
  }
}

/**
 * Attempts to open the given URL in the device's default maps application.
 *
 * If the browser doesn't support the `maps:` protocol, it will instead open a
 * new tab/window to Google Maps.
 */
const MapsButton = ({
  onClick,
  query,
  children,
  className,
  ...props
}: MapsButtonProps) => (
  <Button
    aria-label={`Open ${query} in your device's maps application`}
    className={classNames("border", className)}
    iconStart="external_link"
    onClick={(e) => {
      openMapsLink(query);
      if (onClick) onClick(e);
    }}
    {...props}
  >
    {children || <>Open in&hellip;</>}
  </Button>
);

export { MapsButton };
