import type { TaskAttachmentSerializer } from "@/utils/damage-prevention";
import { UrbintModel } from "@/utils/UrbintModel";

class TaskAttachments extends UrbintModel<TaskAttachmentSerializer> {
  get id() {
    return this.castNumber(this.data.id);
  }

  get file() {
    return this.castString(this.data.file);
  }

  get type() {
    return this.castString(this.data.type);
  }

  get thumbnailUrl() {
    return this.castString(this.data.thumbnail);
  }

  get creatorId() {
    return this.castNumber(this.data.creator);
  }

  get taskId() {
    return this.castNumber(this.data.task);
  }

  get createdAt() {
    return this.castDate(this.data.created_at);
  }

  get offline() {
    return this.castBool(this.data.offline);
  }

  get fileBase64() {
    return this.castString(this.data.fileBase64);
  }

  get thumbnailBase64() {
    return this.castString(this.data.thumbnailBase64);
  }

  compare(instance: TaskAttachments) {
    return this.createdAt.getTime() - instance.createdAt.getTime();
  }

  serialize(): TaskAttachmentSerializer {
    return this.data;
  }
}

export { TaskAttachments };
