import {
  MY_OPEN_TICKETS_SAVED_VIEW_NAME,
  MY_RECENTLY_CLOSED_TICKETS_SAVED_VIEW_NAME,
} from "@/api";

const DEFAULT_OFFLINE_SAVED_VIEWS = [
  MY_OPEN_TICKETS_SAVED_VIEW_NAME,
  MY_RECENTLY_CLOSED_TICKETS_SAVED_VIEW_NAME,
];

export { DEFAULT_OFFLINE_SAVED_VIEWS };
