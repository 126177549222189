import type { Layers } from "@/components/Map/Controls/MapLayerControls/MapLayer.types";
import { useCallback, useState } from "react";

const useLayers = <T extends Layers>(initLayers: T) => {
  const [layers, setLayers] = useState<T>(initLayers);

  const toggleLayer = useCallback(
    (layerID: keyof Layers) =>
      setLayers((l) => ({
        ...l,
        [layerID]: {
          enabled: l[layerID]?.enabled,
          visible: !l[layerID]?.visible,
        },
      })),
    []
  );

  const setLayerEnabled = useCallback(
    (layerID: keyof Layers, enabled: boolean) =>
      setLayers((l) => ({
        ...l,
        [layerID]: {
          enabled,
          visible: l[layerID]?.visible,
        },
      })),
    []
  );

  return { layers, toggleLayer, setLayerEnabled };
};

export { useLayers };
