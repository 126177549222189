import type { SelectOption } from "../types";

const allVisibleNonDisabledOptions = (
  allVisibleOptions: SelectOption<string>[],
  disabledOptions: SelectOption<string>[]
) => {
  const disabledValuesSet = new Set(
    disabledOptions.map((option) => option.value)
  );

  // Filter allVisibleOptions to exclude disabled options
  const visibleNonDisabledOptions = allVisibleOptions.filter(
    (option) => !disabledValuesSet.has(option.value)
  );
  return visibleNonDisabledOptions;
};

export { allVisibleNonDisabledOptions };
