import type { MemberCodeWithPrCode, PrCode } from "../../../TaskDetail.types";
import type { MultiPositiveResponseCodesSelectorsProps } from "./MultiPositiveResponseCodesSelectors.types";
import { DropdownSelect } from "@/components/Select";

export type Props = {
  value: string | undefined;
  className?: string;
  onChange: (value: string) => void;
  placeholder?: string;
  disabled: boolean | undefined;
  showError: boolean;
  memberCodesWithPrCodes: MemberCodeWithPrCode[];
};

type ParsedValueToJSON = {
  [key: string]: string;
};

const MultiPositiveResponseCodesSelectors = ({
  value,
  className = "",
  onChange,
  placeholder = "",
  disabled,
  showError = false,
  memberCodesWithPrCodes,
}: MultiPositiveResponseCodesSelectorsProps) => {
  const memberCodesWithPrCodeQty = memberCodesWithPrCodes.length;
  if (!memberCodesWithPrCodeQty) return null;

  const parsedValue: ParsedValueToJSON = value && JSON.parse(value);
  const onSelectChange =
    (memberCode: string, allOptionsValues: ParsedValueToJSON) =>
    (newValue: string) => {
      onChange(
        JSON.stringify({
          ...allOptionsValues,
          [memberCode]: newValue,
        })
      );
    };
  const generateOptions = (prCodes: PrCode[]) =>
    prCodes.map((prCode: PrCode) => ({
      value: prCode.id.toString(),
      label: prCode.text,
    }));

  const generateFieldLabel = (prCodes: PrCode[], memberCodeId: string) => {
    if (!parsedValue[memberCodeId]) return null;
    return prCodes.find(
      (prCode) => prCode.id.toString() === parsedValue[memberCodeId]
    )?.text;
  };

  return (
    <div>
      <h2 className="font-semibold mb-3">Send positive Response</h2>
      {memberCodesWithPrCodeQty > 1 && (
        <div className="mb-6 text-gray-700 opacity-75">
          <p className="mb-3">
            Select the member codes that you want to send positive response.
          </p>
          <p
            className={`${showError && "text-system-error-40"}`}
            data-testid="response-required-error"
          >
            At least one response is required.
          </p>
        </div>
      )}
      {memberCodesWithPrCodes.map((memberCodeWithPrCode) => (
        <div className="mb-6" key={memberCodeWithPrCode.id}>
          <DropdownSelect
            testId={`${memberCodeWithPrCode.id}-select`}
            label={memberCodeWithPrCode.label}
            className={className}
            optionsClassName="w-full"
            placeholder={placeholder}
            disabled={disabled}
            value={
              parsedValue ? parsedValue[memberCodeWithPrCode.id.toString()] : ""
            }
            onChange={onSelectChange(
              memberCodeWithPrCode.id.toString(),
              parsedValue
            )}
            options={generateOptions(memberCodeWithPrCode.prCodes)}
          />
          {parsedValue && parsedValue[memberCodeWithPrCode.id.toString()] && (
            <p className="text-sm text-neutral-shade-secondary leading-tight mt-1">
              {generateFieldLabel(
                memberCodeWithPrCode.prCodes,
                memberCodeWithPrCode.id.toString()
              )}
            </p>
          )}
        </div>
      ))}
    </div>
  );
};

export { MultiPositiveResponseCodesSelectors };
