import type { activityPhraseMapProps } from "./ActivityListTicketItem.types";
import { ActivityTypes } from "@/pages/TicketPage/Activities/Activities.constants";
import {
  getAutomationEngineCreatePhrase,
  getAutomationEngineUpdatePhrase,
  getForwardTicketPhrase,
  getFowardTicketResponsePhrase,
  getPositiveResponsePhrase,
  getPositiveResponseResponsePhrase,
  getTicketCommentPhrase,
  getTicketStageChangedPhrase,
  getTicketStatusChangedPhrase,
  getTicketVersionPhrase,
  getTicketExpirationDateChangedPhrase,
} from "./ActivityListTicketItem.utils";

const activityPhraseMap: activityPhraseMapProps = {
  [ActivityTypes.AUTOMATION_ENGINE_CREATE]: getAutomationEngineCreatePhrase,
  [ActivityTypes.AUTOMATION_ENGINE_UPDATE]: getAutomationEngineUpdatePhrase,
  [ActivityTypes.POSITIVE_RESPONSE]: getPositiveResponsePhrase,
  [ActivityTypes.POSITIVE_RESPONSE_RESPONSE]: getPositiveResponseResponsePhrase,
  [ActivityTypes.FORWARD_TICKET]: getForwardTicketPhrase,
  [ActivityTypes.FORWARD_TICKET_RESPONSE]: getFowardTicketResponsePhrase,
  [ActivityTypes.TICKET_VERSION]: getTicketVersionPhrase,
  [ActivityTypes.TICKET_COMMENT]: getTicketCommentPhrase,
  [ActivityTypes.TICKET_STATUS_CHANGED]: getTicketStatusChangedPhrase,
  [ActivityTypes.TICKET_STAGE_CHANGED]: getTicketStageChangedPhrase,
  [ActivityTypes.TICKET_EXPIRATION_CHANGED]:
    getTicketExpirationDateChangedPhrase,
};

const NO_EXPIRATION_DATE_SET = "None";

export { activityPhraseMap, NO_EXPIRATION_DATE_SET };
