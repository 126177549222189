import type { TaskTypeSerializer } from "@/utils/damage-prevention";
import { UrbintModel } from "@/utils/UrbintModel";

class TaskType extends UrbintModel<TaskTypeSerializer> {
  get id() {
    return this.castNumber(this.data.id);
  }

  get name() {
    return this.castString(this.data.name);
  }

  get workflowId() {
    return this.castNumber(this.data.workflow);
  }

  get responseFormId() {
    return this.castNumber(this.data.response_form);
  }

  compare(instance: TaskType): number {
    return this.name.localeCompare(instance.name);
  }

  serialize(): TaskTypeSerializer {
    return this.data;
  }
}

export { TaskType };
