import type { UserSelectProps } from "./UserSelect.types";
import type { User } from "@/models";
import { Listbox, Portal } from "@headlessui/react";
import { Icon } from "@urbint/silica";
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDisposables, useResponsive, useWindowEventListener } from "@/hooks";
import { isUnassignedUser, unassignedUser } from "@/utils/users";
import { Avatar } from "@/common/Avatar";
import { UserSelectDefaultButton } from "./UserSelectDefaultButton";
import { addUnassignedUser } from "./UserSelect.utils";

const UserSelect = ({
  options = [],
  value,
  buttonLabel,
  dropdownClassName = "",
  onChange,
  buttonComponent = UserSelectDefaultButton,
  currentUser,
  disabled = false,
  required = false,
  usePortal = false,
  unAssignedUser = true,
}: UserSelectProps) => {
  const d = useDisposables();
  const { isMd: isDesktop } = useResponsive();

  const [selectedUser, setSelectedUser] = useState<User | undefined>(
    value ? options.find((u: User) => u.id === value) : unassignedUser
  );
  const [searchTerm, setSearchTerm] = useState("");
  const inputRef = useRef<any>();
  const firstOption = useRef<any>();
  const buttonRef = useRef<any>();
  const userSelectOptionsRef = useRef<HTMLDivElement | null>(null);

  const isCurrentUser = useCallback(
    (u?: User) => u && currentUser && u.id === currentUser.djangoId,
    [currentUser]
  );

  const onSelect = useCallback(
    (u: User) => {
      // clear the search term
      setSearchTerm("");
      if (onChange) {
        if (isUnassignedUser(u)) {
          // unassigned, nothing to pass
          onChange();
        } else {
          onChange(u.id);
        }
      }
      // send focus to button after selecting
      d.requestAnimationFrame(() => buttonRef?.current?.focus());
    },
    [d, onChange, setSearchTerm]
  );

  const filteredUsers = useMemo(() => {
    // add 'unassigned' if select is not required
    // be sure to not add if it's already added
    if (!required && !options.find(isUnassignedUser) && unAssignedUser) {
      addUnassignedUser(options, unassignedUser);
    }
    return options
      .filter((user) => user.matches(searchTerm))
      .sort((a, b) => {
        if (currentUser && isCurrentUser(a)) return -1;
        if (currentUser && isCurrentUser(b)) return 1;
        if (isUnassignedUser(a)) return -1;
        if (isUnassignedUser(b)) return 1;
        return a.compare(b);
      });
  }, [required, currentUser, isCurrentUser, options, searchTerm]);

  useEffect(() => {
    const matchingUser = value
      ? options.find((u: User) => u.id === value)
      : unassignedUser;
    setSelectedUser(matchingUser);
  }, [options, value]);

  useWindowEventListener(
    "keydown",
    (e: KeyboardEvent) => {
      if (
        (e.key === " " ||
          e.key === "Backspace" ||
          (e.key.length === 1 && e.key >= "A" && e.key <= "z")) &&
        inputRef?.current === document.activeElement
      ) {
        // prevent these keys from hitting the listbox's key handling
        e.preventDefault();
        e.stopPropagation();

        if (e.key === "Backspace") {
          setSearchTerm(searchTerm.slice(0, -1));
        } else {
          setSearchTerm(`${searchTerm}${e.key}`);
        }

        d.nextFrame(() => {
          // focus the first opt in the list
          firstOption?.current?.focus();
          inputRef?.current?.focus();
        });
      }
    },
    { capture: true },
    [d]
  );

  if (usePortal) {
    const rect = buttonRef?.current?.getBoundingClientRect();
    const tip = userSelectOptionsRef.current!;
    if (tip && rect) {
      tip.style.top = `${rect.bottom}px`;
      tip.style.right = `${window.innerWidth - rect.left - rect.width}px`;
    }
  }

  const selectOptions = (
    <Listbox.Options
      ref={userSelectOptionsRef}
      as="div"
      unmount={!usePortal}
      className={`shadow-20 bg-white rounded-[6px] z-[100] absolute max-w-[90%] sm:max-w-xs ${dropdownClassName}`}
    >
      {
        /* using the render function here allows the input to grab focus on open. */
        () => (
          <>
            <div className="bg-neutral-shade-background-medium rounded-t-[6px] flex flex-col items-center py-2">
              <div className="px-3 w-full flex flex-row items-center relative">
                <Icon
                  className="absolute left-4 text-2xl text-neutral-shade-tertiary"
                  name="search"
                />
                <input
                  className={`flex border-[1px] border-black border-opacity-[22%] border border-solid rounded-[4px]
                     focus:border-brand-40 focus:outline-none font-sans text-base pl-8 py-[2px] w-full leading-3`}
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  ref={(r) => {
                    if (isDesktop) r?.focus();
                    inputRef.current = r;
                  }}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                  }}
                  data-testid="search-user-input"
                />
                {searchTerm ? (
                  <Icon
                    onClick={() => setSearchTerm("")}
                    className="absolute right-5 cursor-pointer"
                    name="close_big"
                  />
                ) : null}
              </div>
            </div>
            <div className="max-h-56 md:h-auto overflow-y-scroll pt-2">
              {filteredUsers.length > 0 ? (
                filteredUsers.map((user, i) => (
                  <Listbox.Option
                    key={
                      // using the index maintains keyboard directional movement while filtering
                      /* eslint react/no-array-index-key: 0 */
                      `${i}.${user.id}`
                    }
                    value={user}
                    as={Fragment}
                    data-testid="assinee-option"
                  >
                    {({ active, selected }) => (
                      <div
                        className={`flex flex-row cursor-pointer relative ${
                          active ? "bg-neutral-shade-background-light" : ""
                        }`}
                        ref={i === 0 ? firstOption : undefined}
                      >
                        {selected && (
                          // the little mcgibbit on the side
                          <span className="absolute h-full rounded-r-lg border-r-4 border-l-0 border-brand-40" />
                        )}
                        <li className="flex flex-row items-center space-x-2 p-2 pl-3">
                          <Avatar user={user} />
                          <span className="truncate">
                            {user.displayName}{" "}
                            {isCurrentUser(user) ? <span>(you)</span> : ""}
                          </span>
                        </li>
                      </div>
                    )}
                  </Listbox.Option>
                ))
              ) : (
                <div className="text-neutral-shade-tertiary flex items-center justify-center pb-4 pt-2">
                  No matches.
                </div>
              )}
            </div>
          </>
        )
      }
    </Listbox.Options>
  );
  return (
    <Listbox
      as="div"
      className="w-full"
      value={selectedUser}
      onChange={onSelect}
      disabled={disabled}
      data-testid="assignee-select"
    >
      {buttonLabel ? (
        <Listbox.Label
          as="div"
          className="text-neutral-shade-secondary text-sm font-semibold leading-3 my-2"
        >
          {buttonLabel}
        </Listbox.Label>
      ) : null}
      <Listbox.Button
        as="div"
        className={`${disabled ? "" : "cursor-pointer"}`}
      >
        {buttonComponent({
          disabled,
          selectedUser,
          isCurrentUser: Boolean(isCurrentUser(selectedUser)),
          ref: (ref) => {
            buttonRef.current = ref;
          },
        })}
      </Listbox.Button>
      {usePortal ? <Portal>{selectOptions}</Portal> : selectOptions}
    </Listbox>
  );
};
export { UserSelect };
