import type { IconName } from "@urbint/silica";
import { InsightName } from "@/models/Insight";

const getIcon = (insightName: InsightName): IconName => {
  switch (insightName) {
    case InsightName.ASSET_TYPE:
      return "settings_future";
    case InsightName.HIGH_PROFILE_BUILDING:
      return "building";
    case InsightName.OUTAGE_ASSET_CRITERIA:
      return "cross_lightning";
    case InsightName.ASSET_CLASSIFICATION:
      return "tag-outline";
    case InsightName.BORING:
      return "cylinder";
    case InsightName.REPAIR_ASSET_CRITERIA:
      return "wrench";
    default:
      return "help_questionmark";
  }
};

const getTitle = (insightName: InsightName): string => {
  switch (insightName) {
    case InsightName.ASSET_TYPE:
      return "Asset Type";
    case InsightName.HIGH_PROFILE_BUILDING:
      return "High Profile Building Data";
    case InsightName.OUTAGE_ASSET_CRITERIA:
      return "Outage Criteria";
    case InsightName.ASSET_CLASSIFICATION:
      return "Asset Classification";
    case InsightName.BORING:
      return "Boring";
    case InsightName.REPAIR_ASSET_CRITERIA:
      return "Repair Criteria";
    default:
      return "";
  }
};

export { getIcon, getTitle };
