import { useMemo } from "react";

/**
 * Static ID that will be incremented every time a component is instantiated to
 * ensure a unique value.
 */
let ARIA_ID = 0;

const useAriaId = (): number => useMemo(() => ++ARIA_ID, []);

export { useAriaId };
