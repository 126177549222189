import { scaleLinear } from "d3-scale";
import {
  DEFAULT_GREEN_ARC_COLOR,
  DEFAULT_ORANGE_ARC_COLOR,
  DEFAULT_RED_ARC_COLOR,
  DEFAULT_YELLOW_ARC_COLOR,
} from "./Gauge.constants";

const getCoordsOnArc = (angle: number, offset = 0.95) => [
  Math.cos(angle - Math.PI / 2) * offset,
  Math.sin(angle - Math.PI / 2) * offset,
];

const getDefaultBlobColor = (percentage: number) => {
  if (percentage <= 25) return DEFAULT_GREEN_ARC_COLOR;
  if (percentage > 25 && percentage <= 50) return DEFAULT_YELLOW_ARC_COLOR;
  if (percentage > 50 && percentage <= 75) return DEFAULT_ORANGE_ARC_COLOR;
  return DEFAULT_RED_ARC_COLOR;
};

const angleScale = scaleLinear()
  .domain([0, 1])
  .range([-Math.PI / 2, Math.PI / 2])
  .clamp(true);

export { getCoordsOnArc, getDefaultBlobColor, angleScale };
