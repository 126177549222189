import type { DeleteTaskModalProps } from "./DeleteTaskModal.types";

import { BodyText, Subheading } from "@urbint/silica";
import { useDeleteTask } from "@/api";
import { Dialog, type dialogFooter } from "@/components/Dialog";
import { useToasts } from "@/components/Toasts";

const DeleteTaskModal = ({
  visibility,
  setModalVisibility,
  taskId,
  ticketId,
  closeTaskSidebar,
}: DeleteTaskModalProps) => {
  const deleteTaskApiCall = useDeleteTask();
  const { addToast } = useToasts();
  const deleteTask = async (task: number, ticket: number) => {
    try {
      const mutation = await deleteTaskApiCall.mutateAsync(
        {
          taskId: task,
          ticketId: ticket,
        },
        {
          onSuccess: () => {
            addToast("Task deleted", "success");
            setModalVisibility(false);
            closeTaskSidebar();
          },
        }
      );

      if (mutation.error) {
        throw mutation.error;
      }
    } catch (e) {
      const toastText = "Error deleting task";

      if (e instanceof Error) {
        // Task was not found it was already deleted
        if (e.message.includes("404")) {
          addToast(`${toastText} it no longer exists`, "error");
        } else {
          // Informative error
          addToast(`${toastText}: ${e.message}`, "error");
        }
      } else {
        // Generic unknown error
        addToast(`${toastText}`, "error");
      }
    }
  };

  const modalFooter: dialogFooter = {
    toRender: "Delete task",
    onClose: () => setModalVisibility(false),
    onClick: () => deleteTask(taskId, ticketId),
    submitButtontype: "danger",
    submitButtonTestId: "delete-task-modal-button",
  };

  return (
    <Dialog
      isOpen={visibility}
      onClose={() => setModalVisibility(false)}
      header=""
      footer={modalFooter}
      variant="warning"
    >
      <Subheading>Are you sure you want to delete this task?</Subheading>
      <BodyText className="mt-4">
        Once deleted, all responses, comments, and attachments will be removed.
      </BodyText>
    </Dialog>
  );
};

export { DeleteTaskModal };
