import type { TaskAttachments } from "@/models";
import type { Slide } from "@/components/Gallery/Gallery.types";
import type { TaskDetailProps } from "./TaskDetail.types";
import { Transition } from "@headlessui/react";
import { IonIcon, useIonViewDidLeave } from "@ionic/react";
import { refreshOutline } from "ionicons/icons";
import { Fragment, useCallback, useEffect, useState } from "react";
import { Portal } from "@/components/Portal";
import {
  useTaskAttachments,
  useTaskByIdForTicket,
  useTicketActivity,
} from "@/api";
import { useDamagePreventionAuth, useResponsive } from "@/hooks";
import { Gallery } from "@/components/Gallery";
import { FileType } from "@/hooks/useFileInput/useFileInput";
import { useOrganizeTicketActivities } from "@/hooks/useOrganizeTicketActivities";
import { Header } from "@/pages/TicketPage/TicketPageDetails/Header";
import { AttachmentsSection } from "@/components/AttachmentsSection";
import { TaskResponseFormSection } from "@/pages/TicketPage/TaskDetail/TaskResponseFormSection";
import { InvalidAttachmentSize } from "@/components/InvalidAttachmentSize";
import { DeleteAttachmentModal } from "@/components/AttachmentsSection/DeleteAttachmentModal";
import { CommentsAndActivitySection } from "./CommentsAndActivitySection";

const TaskDetail = ({ ticket, taskId, onClose }: TaskDetailProps) => {
  const [isShowing, setIsShowing] = useState(!!taskId);
  // TODO: Fix no-non-null-asserted-optional-chain
  const {
    currentUser: { canDeletePhotos },
  } = useDamagePreventionAuth();
  const { data: activities } = useTicketActivity(ticket?.id!, {
    enabled: ticket?.id !== undefined,
  });
  const { groupedActivities, setSelectedTaskId } =
    useOrganizeTicketActivities(activities);
  useEffect(() => {
    if (!ticket) return;
    setSelectedTaskId(taskId);
    setIsShowing(!!taskId);
  }, [ticket, taskId, setIsShowing, setSelectedTaskId]);
  const { isMd } = useResponsive();
  const Container = isMd ? Fragment : Portal;
  const {
    data: task,
    isSuccess: isTaskLoaded,
    isInitialLoading: isTaskLoading,
    // TODO: Fix no-non-null-asserted-optional-chain
  } = useTaskByIdForTicket(ticket?.id!, taskId!, {
    enabled: taskId !== undefined && ticket?.id !== undefined,
  });
  const [photoGalleryId, setPhotoGalleryId] = useState<number | undefined>(
    undefined
  );

  const { data: attachments } = useTaskAttachments(taskId!, undefined, {
    enabled: taskId !== undefined,
  });
  useIonViewDidLeave(() => {
    setIsShowing(false);
  }, [setIsShowing]);
  const [deleteThumbState, setDeleteThumbState] = useState<{
    modalVisibility: boolean;
    thumbId: number | null;
  }>({
    modalVisibility: false,
    thumbId: null,
  });
  const [
    invalidAttachmentSizeModalVisible,
    setInvalidAttachmentSizeModalVisibility,
  ] = useState(false);

  const closeGallery = useCallback(() => setPhotoGalleryId(undefined), []);

  const processGalleryAttachments = useCallback(
    (galleryAttachments: TaskAttachments[]): Slide[] =>
      galleryAttachments
        .filter((attachment) => attachment.type !== FileType.PDF)
        .map(
          (attachment) =>
            ({
              image: attachment.file,
              alt: attachment.file,
              title: attachment.file.split("/").pop()?.split("?")[0],
              createdAt: attachment.createdAt,
              author: attachment.creatorId,
              type: attachment.type,
              metadata: {
                taskId: attachment.taskId,
                attachmentId: attachment.id,
              },
            } as unknown as Slide)
        ),
    []
  );

  const onDelete = useCallback(({ metadata }) => {
    setDeleteThumbState({
      modalVisibility: true,
      thumbId: metadata?.attachmentId || null,
    });
  }, []);

  const canRenderAttachmentRelatedContent = ticket?.id && taskId;

  return (
    <Container>
      <Transition
        show={isShowing}
        unmount={false}
        className="md:ml-px bg-white z-10 fixed md:absolute top-0 bottom-0 left-0 h-full w-full transform-gpu duration-100 shadow isolate overflow-y-auto"
        enterFrom="translate-y-full md:translate-y-0 md:-translate-x-full"
        enterTo="translate-y-0 translate-x-0"
        leaveFrom="translate-x-0"
        leaveTo="translate-y-full md:translate-y-0 md:-translate-x-full"
        afterLeave={onClose}
      >
        {isShowing && (
          <>
            {isTaskLoading && (
              <div className="flex flex-col items-center my-32">
                <IonIcon
                  icon={refreshOutline}
                  className="text-4xl text-neutral-shade-secondary animate-spin"
                />
              </div>
            )}
            {!task && isTaskLoaded ? (
              <div className="flex items-center flex-col my-32">
                <p className="text-neutral-shade-tertiary">
                  Could not find selected task.
                </p>
                <button
                  type="button"
                  className="text-blue-500 my-2"
                  onClick={() => setIsShowing(false)}
                >
                  Show all tasks
                </button>
              </div>
            ) : (
              task &&
              isTaskLoaded && (
                <>
                  <Header
                    task={task}
                    onClose={() => {
                      setIsShowing(false);
                      onClose();
                    }}
                  />
                  <div className="divide-y-4 divide-gray-20">
                    <TaskResponseFormSection
                      task={task}
                      ticketNumber={ticket?.number}
                    />
                    {canRenderAttachmentRelatedContent && (
                      <AttachmentsSection
                        className="p-6"
                        attachmentType={{ ticketId: ticket?.id, taskId }}
                        onInvalidAttachmentSize={() => {
                          setInvalidAttachmentSizeModalVisibility(true);
                        }}
                        onAttachmentClick={(thumbnailId) =>
                          setPhotoGalleryId(thumbnailId)
                        }
                        canDelete={canDeletePhotos}
                        onDeleteClick={(thumbnailId) =>
                          setDeleteThumbState({
                            modalVisibility: true,
                            thumbId: thumbnailId,
                          })
                        }
                      />
                    )}
                    {activities && (
                      <CommentsAndActivitySection
                        ticket={ticket}
                        taskId={taskId}
                        onPhotoClick={(thumbnailId) => {
                          setPhotoGalleryId(thumbnailId);
                        }}
                        groupedActivities={groupedActivities}
                      />
                    )}
                    <InvalidAttachmentSize
                      modalVisibility={invalidAttachmentSizeModalVisible}
                      setModalVisibility={(visibility: boolean) =>
                        setInvalidAttachmentSizeModalVisibility(visibility)
                      }
                    />
                    {canRenderAttachmentRelatedContent && (
                      <DeleteAttachmentModal
                        attachmentType={{ ticketId: ticket?.id, taskId }}
                        thumbState={deleteThumbState}
                        setModalVisibility={(value: boolean) =>
                          setDeleteThumbState((prevState) => ({
                            ...prevState,
                            modalVisibility: value,
                          }))
                        }
                      />
                    )}
                  </div>
                  {attachments && (
                    <Portal>
                      <Gallery
                        opened={!!photoGalleryId || photoGalleryId === 0}
                        onClose={closeGallery}
                        canDelete={canDeletePhotos}
                        onDelete={onDelete}
                        initialSlide={photoGalleryId}
                        slides={processGalleryAttachments(attachments)}
                      />
                    </Portal>
                  )}
                </>
              )
            )}
          </>
        )}
      </Transition>
    </Container>
  );
};

export { TaskDetail };
