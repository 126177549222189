import type { Dispatch, SetStateAction } from "react";
import classNames from "classnames";
import { Icon } from "@urbint/silica";

type DeleteProp = {
  thumbHover: boolean;
  setDeleteHover: Dispatch<SetStateAction<boolean>>;
  onDeleteClick: (() => void) | undefined;
  deleteHover: boolean;
};

const DeleteOverlay = ({
  thumbHover,
  setDeleteHover,
  onDeleteClick,
  deleteHover,
}: DeleteProp) => {
  if (!thumbHover) return null;

  return (
    <div className="absolute w-full h-full bg-neutral-shade-border-default z-20 rounded">
      <button
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();

          return onDeleteClick?.();
        }}
        onMouseEnter={() => setDeleteHover(true)}
        onMouseLeave={() => setDeleteHover(false)}
        type="button"
        data-testid="thumbnail_Delete"
        className={classNames(
          "absolute top-1 right-1 flex items-center justify-center rounded w-[18px] sm:w-6 transition-all",
          {
            "bg-system-error-30": deleteHover,
            "bg-neutral-light-secondary": !deleteHover,
          }
        )}
        style={{
          aspectRatio: "1/1",
        }}
      >
        <Icon
          name="trash_full"
          className={classNames(
            "w-[10.5px] sm:w-[13.5px] flex items-center justify-center transition-all",
            {
              "text-white": deleteHover,
              "text-default-on-light": !deleteHover,
            }
          )}
        />
      </button>
    </div>
  );
};

export { DeleteOverlay };
