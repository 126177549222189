import type { SelectAllProps } from "./SelectAll.types";
import cx from "classnames";
import { useState } from "react";
import { Checkbox } from "@/common/Checkbox";
import { browserScrollToId } from "@/utils";
import { useTypeahead } from "../../TypeheadSelectProvider";
import { SELECT_ALL_ID, SELECT_ALL_LABEL } from "./SelectAll.constants";

const SelectAll = ({
  type,
  visibleOptions,
  selectedOptions,
  handleSelectAll,
}: SelectAllProps) => {
  const [isActive, setIsActive] = useState(false);
  const { searchIconId } = useTypeahead();

  if (!visibleOptions.length) {
    return null;
  }

  const areAllVisibleOptionsSelected = () =>
    visibleOptions.length > 0 &&
    visibleOptions.every((option) =>
      selectedOptions.some(
        (selectedOption) => selectedOption.value === option.value
      )
    );

  const toggleSelectAll = () => {
    const allSelected = areAllVisibleOptionsSelected();
    handleSelectAll(!allSelected, visibleOptions);
    browserScrollToId(`${type}-${searchIconId}`, true);
  };

  const isChecked = areAllVisibleOptionsSelected();
  const indeterminate = !isChecked && selectedOptions.length > 0;

  return (
    <button
      type="button"
      className={cx("flex items-center py-2 px-4 cursor-pointer w-full", {
        "bg-gray-200": isActive && !isChecked,
        "bg-slate-200": isActive && isChecked,
      })}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          toggleSelectAll();
        }
      }}
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
      onClick={toggleSelectAll}
    >
      <Checkbox
        id={SELECT_ALL_ID}
        checked={isChecked}
        indeterminate={indeterminate}
        onChange={(value) => handleSelectAll(value, visibleOptions)}
        className="shrink-0 mr-2"
      />
      <label htmlFor={SELECT_ALL_ID} className="cursor-pointer">
        {selectedOptions.length
          ? `${SELECT_ALL_LABEL} (${selectedOptions.length})`
          : SELECT_ALL_LABEL}
      </label>
    </button>
  );
};

export { SelectAll };
