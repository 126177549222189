import type { PositiveResponse } from "@/models/PositiveResponse";

const extractLocateIssueCodes = (positiveResponses?: PositiveResponse[]) => {
  const locateIssueCodes: Set<string> = new Set();
  positiveResponses?.forEach((positiveResponse) => {
    if (positiveResponse.locateIssueCodes) {
      // extract issue codes
      const issues = positiveResponse.locateIssueCodes.split(",");
      issues.forEach(locateIssueCodes.add, locateIssueCodes);
    }
  });
  return locateIssueCodes;
};

const locateIssueCodesToString = (locateIssueCodes: Set<string>) =>
  Array.from(locateIssueCodes).join(",");

export { extractLocateIssueCodes, locateIssueCodesToString };
