const Units = [
  { label: "miles", abbreviation: "mi" },
  { label: "feet", abbreviation: "ft" },
  { label: "kilometers", abbreviation: "km" },
] as const;

type Unit = typeof Units[number];

function isValidUnit(unit: any): unit is Unit {
  return Units.some(
    (u) => u.label === unit.label && u.abbreviation === unit.abbreviation
  );
}

export { Units, isValidUnit };
export type { Unit };
