import type { ImpactModalContentProps } from "./ImpactModalContent.types";
import { BodyText, Subheading } from "@urbint/silica";
import { useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { useImpactPotential } from "@/api";
import { Loading } from "@/common/Loading";
import { ImpactList } from "./ImpactList";

const ImpactModalContent = ({
  isModalOpen,
  ticketId,
}: ImpactModalContentProps) => {
  const { data, isError, isLoading } = useImpactPotential(
    isModalOpen,
    ticketId
  );

  useEffect(() => {
    if (isError) {
      datadogRum.addError(
        new Error(
          `Request to Impact Model Explainer Fail on TicketId: ${ticketId}`
        ),
        {
          errorIdentifier: "GET_MODEL_EXPLAINER_IMPACT",
        }
      );
    }
  }, [isError]);

  if (isLoading) {
    return (
      <div className="h-60 relative">
        <Loading />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="py-4">
        <Subheading>
          Apologies, the Impact Insights is having a bit of a delay.
        </Subheading>
        <BodyText>Team is notified, and we&apos;re working on it.</BodyText>
        <BodyText>Thank you for your understanding</BodyText>
      </div>
    );
  }

  if (data && data.length === 0) {
    return (
      <div className="py-4">
        <Subheading>
          Apologies, there&apos;s no Impact Insights information for this
          ticket.
        </Subheading>
        <BodyText>Thank you for your understanding</BodyText>
      </div>
    );
  }

  if (data) {
    return <ImpactList list={data} />;
  }

  return null;
};

export { ImpactModalContent };
