import type { SelectOptionProps } from "./SelectOption.types";
import { Combobox } from "@headlessui/react";
import { Fragment, useRef, useState } from "react";
import cx from "classnames";
import { Tooltip } from "@/common/Tooltip";
import { Checkbox } from "@/common/Checkbox";
import { browserScrollToId } from "@/utils";
import { useTypeahead } from "../../TypeheadSelectProvider";
import { setIsTooltipEnabled } from "./SelectOption.utils";

const SelectOption = ({
  type,
  option,
  isDisable = false,
}: SelectOptionProps) => (
  <Combobox.Option value={option} as={Fragment} disabled={isDisable}>
    {({ active, selected }) => {
      const { searchIconId } = useTypeahead();
      const optionLabelDivRef = useRef<HTMLDivElement>(null);
      const [tooltipEnabled, setTooltipEnabled] = useState<boolean>(false);
      return (
        <li
          onMouseEnter={() =>
            setIsTooltipEnabled(optionLabelDivRef, setTooltipEnabled)
          }
          className={cx("text-black bg-white py-2 px-4 cursor-pointer", {
            "opacity-20 cursor-auto": isDisable,
            "bg-gray-200": active && !selected,
            "bg-slate-200": active && selected,
          })}
          onClick={() => browserScrollToId(`${type}-${searchIconId}`, true)}
        >
          <div className="flex items-center">
            <Checkbox checked={selected} className="shrink-0 mr-2" />
            <Tooltip
              origin="top"
              disabled={!tooltipEnabled}
              title={option.label}
            >
              <div className="line-clamp-5" ref={optionLabelDivRef}>
                {option.label}
              </div>
            </Tooltip>
          </div>
        </li>
      );
    }}
  </Combobox.Option>
);

export { SelectOption };
