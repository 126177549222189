import type { Notification } from "@/models/Notification";
import type { NotificationsPanelProps } from "./NotificationsPanel.types";
import { useMemo } from "react";
import cx from "classnames";
import { NotificationItem } from "./NotificationItem";

const NotificationsPanel = ({
  notifications,
  unreadCount,
  currentUser,
}: NotificationsPanelProps) => {
  const [readNotifications = [], unreadNotifications = []] = useMemo(() => {
    notifications.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());
    return notifications.reduce<Notification[][]>(
      (acc, notification) => {
        acc[notification.read ? 0 : 1]!.push(notification);
        return acc;
      },
      [[], []]
    );
  }, [notifications]);
  const sortedNotifications = [...unreadNotifications, ...readNotifications];
  return (
    <div className="flex flex-col min-w-[320px] max-w-[640px] max-h-[60vh] w-auto">
      <div className="fixed w-full">
        <h1 className="bg-[#1e3340] flex-1 font-semibold md:bg-white md:text-gray-700 p-4 text-lg text-white">
          {unreadCount ? `Notifications (${unreadCount})` : "Notifications"}
        </h1>
        <hr />
      </div>
      {sortedNotifications.length > 0 ? (
        sortedNotifications.map((notification, idx) => (
          <div
            key={notification.id}
            className={cx("w-full", idx === 0 ? "mt-[60px]" : "")}
          >
            <NotificationItem
              currentUser={currentUser}
              notification={notification}
            />
            {/* Only show `<hr />` if there's another notification afterward. */}
            {idx < sortedNotifications.length - 1 && <hr />}
          </div>
        ))
      ) : (
        <div className="flex items-center justify-center p-4 text-lg text-gray-500 select-none mt-[60px]">
          No notifications
        </div>
      )}
    </div>
  );
};

export { NotificationsPanel };
