import type { FieldMemberPopupProps } from "./FieldMemberPopup.types";
import type { Offset } from "mapbox-gl";
import cx from "classnames";
import { Popup } from "react-map-gl";

import { formatRelativeTime, pluralize } from "@/format";
import { PhoneNumberLine } from "./PhoneNumberLine";

const FieldMemberPopup = ({
  latitude,
  longitude,
  ticketCount,
  firstName,
  lastName,
  phoneNumber,
  lastUpdate,
}: FieldMemberPopupProps) => (
  <Popup
    latitude={latitude}
    longitude={longitude}
    className="bg-transparent relative"
    closeButton={false}
    closeOnClick
    anchor="top"
    offset={[0, 15] as Offset}
  >
    <div className={cx("font-sans", "w-auto", "box-content", "flex")}>
      <div
        className={cx(
          "bg-white",
          "inline-block",
          "w-auto",
          "drop-shadow-md",
          "rounded",
          "pt-1",
          "align-baseline",
          "bg-neutral-shade-secondary",
          "text-center"
        )}
      >
        <span
          className={cx(
            "text-white",
            "text-field-member",
            "subpixel-antialiased",
            "align-bottom"
          )}
        >
          {firstName} {lastName}
        </span>
        <br />
        <PhoneNumberLine phoneNumber={phoneNumber} />
        <span
          className={cx(
            "text-gray-300",
            "px-2",
            "align-top",
            "text-sm",
            "subpixel-antialiased"
          )}
        >
          {formatRelativeTime(lastUpdate)}
          <span className="mx-2">•</span>
          {pluralize(ticketCount, "ticket")}
        </span>
      </div>
    </div>
  </Popup>
);

export { FieldMemberPopup };
