import type { RangeDateTimeProps, RelativeOption } from "./RangeDateTime.types";
import { useCallback, useMemo, useState } from "react";
import isEqual from "lodash/isEqual";
import { DropdownSelect } from "@/components/Select";
import { CustomInput } from "@/components/DateTimeInput/CustomInput";
import { getDateFormat, getPlaceholder } from "../DateTimeInput.utils";
import {
  RELATIVE_OPTION_TO_START_AND_END,
  RELATIVE_OPTIONS,
} from "./RangeDateTime.constants";

const RangeDateTime = ({
  onDatesChange,
  startDate,
  endDate,
  type,
  staticMode,
  disabled,
  onBlur,
  relative,
}: RangeDateTimeProps) => {
  const defaultDates: Array<Date | string | undefined> = useMemo(
    () => [startDate, endDate],
    [startDate, endDate]
  );

  const [relativeValue, setRelativeValue] = useState<
    RelativeOption | undefined
  >(() => {
    if (!relative) return "custom";
    if (!(startDate && endDate)) return undefined;
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const k in RELATIVE_OPTION_TO_START_AND_END) {
      const relativeOption = k as RelativeOption;
      const range = RELATIVE_OPTION_TO_START_AND_END[relativeOption];
      if (isEqual(range, [startDate, endDate])) {
        return relativeOption;
      }
    }
    return startDate || endDate ? "custom" : undefined;
  });
  const showCustom = relativeValue === "custom";

  const onRelativeChange = useCallback(
    (value: RelativeOption) => {
      const values = RELATIVE_OPTION_TO_START_AND_END[value];
      values && onDatesChange && onDatesChange(values);
      setRelativeValue(value);
    },
    [onDatesChange, setRelativeValue]
  );

  return (
    <div className="space-y-2">
      {relative && (
        <DropdownSelect
          className="border rounded"
          value={relativeValue}
          options={RELATIVE_OPTIONS}
          onChange={onRelativeChange}
          testId="relative-date-select"
        />
      )}
      {showCustom && (
        <CustomInput
          onChange={onDatesChange}
          static={staticMode}
          options={{
            mode: "range",
            dateFormat: getDateFormat(type),
            enableTime: type === "datetime",
          }}
          onClose={onBlur}
          disabled={disabled}
          type={type}
          value={defaultDates as Date[]}
          placeholder={`${getPlaceholder(type)} --> ${getPlaceholder(type)}`}
        />
      )}
    </div>
  );
};

export { RangeDateTime };
