import type { PositiveResponseCodeSerializer } from "@/utils/damage-prevention";
import { UrbintModel } from "@/utils/UrbintModel";

class PositiveResponseCode extends UrbintModel<PositiveResponseCodeSerializer> {
  get id() {
    return this.castNumber(this.data.id);
  }

  get code() {
    return this.data.code;
  }

  get label() {
    return this.data.label;
  }

  get description() {
    return this.data.description;
  }

  get isOverride() {
    return this.castBool(this.data.is_override);
  }

  get oneCallCenter() {
    return this.castNumber(this.data.eight11_center);
  }

  serialize(): PositiveResponseCodeSerializer {
    return this.data;
  }

  compare(instance: PositiveResponseCode): number {
    return this.id - instance.id;
  }
}

export { PositiveResponseCode };
