const LOCAL_UPLOADS_FOLDER =
  "http://strickland-op.dp.local.urbinternal.com:7777/uploads";

enum ActivityTypes {
  AUTOMATION_ENGINE_CREATE = "automation_engine_create",
  AUTOMATION_ENGINE_UPDATE = "automation_engine_update",
  ANSWERSET_SUBMIT = "answerset_submit",
  ANSWERSET_EDIT = "answerset_edit",
  IGNORE = "ignore",
  POSITIVE_RESPONSE = "positive_response",
  POSITIVE_RESPONSE_RESPONSE = "positive_response_response",
  FORWARD_TICKET = "forward_ticket",
  FORWARD_TICKET_RESPONSE = "forward_ticket_response",
  TASK_ASSIGNMENT = "task_assignment",
  TASK_ATTACHMENT = "task_attachment",
  TASK_ATTACHMENT_DELETED = "task_attachment_deleted",
  TASK_STATUS_UPDATED = "task_status_updated",
  TASK_COMMENT = "task_comment",
  TASK_CREATION = "task_creation",
  TASK_DELETED = "task_deleted",
  TICKET_CREATION = "ticket_creation",
  TICKET_VERSION = "ticket_version",
  EMPTY = "",
  TICKET_COMMENT = "ticket_comment",
  TICKET_STATUS_CHANGED = "ticket_status_changed",
  TICKET_STAGE_CHANGED = "ticket_stage_changed",
  TICKET_EXPIRATION_CHANGED = "ticket_expiration_changed",
}

export { LOCAL_UPLOADS_FOLDER, ActivityTypes };
