import type { UseQueryOptions } from "@tanstack/react-query";
import type { ResponseFormSerializer } from "@/utils/damage-prevention";
import { useQuery } from "@tanstack/react-query";
import { ResponseFormAnswerSet } from "@/models";
import { UrbintApi } from "@/utils/UrbintApi";
import { queryClient } from "@/api/client";
import { handleApiResponse } from "@/api/helpers";

const api = new UrbintApi();

const answerSetIdent = (taskId: number, withPopulate = false) => [
  "answer_sets",
  taskId,
  withPopulate,
];

const fetchAnswerSets = (taskId: number, withPopulate = false) =>
  api
    .getPage<ResponseFormSerializer>({
      endPoint: "response_form_answers",
      page: 1,
      queryParams: {
        task: taskId.toString(),
        ...(withPopulate && { populate: "question" }),
      },
    })
    .then(handleApiResponse)
    .then((res) => {
      if (res.results[0]) {
        return new ResponseFormAnswerSet(res.results[0]);
      }
      return new ResponseFormAnswerSet({});
    });

const useAnswerSet = (
  taskId: number,
  opts?: UseQueryOptions<ResponseFormAnswerSet | undefined>,
  withPopulate = false
) =>
  useQuery<ResponseFormAnswerSet | undefined>(
    answerSetIdent(taskId, withPopulate),
    () => fetchAnswerSets(taskId, withPopulate),
    opts
  );

const prefetchUseAnswerSet = (taskId: number) =>
  queryClient.prefetchQuery(answerSetIdent(taskId, false), () =>
    fetchAnswerSets(taskId, false)
  );

const invalidateUseAnswerSetQuery = (taskId: number, withPopulate = false) =>
  queryClient.invalidateQueries(answerSetIdent(taskId, withPopulate));

export { invalidateUseAnswerSetQuery, useAnswerSet, prefetchUseAnswerSet };
