import type { ServiceCenterSerializer } from "@/utils/damage-prevention";
import { UrbintModel } from "@/utils/UrbintModel";

class ServiceCenter extends UrbintModel<ServiceCenterSerializer> {
  get id() {
    return this.castNumber(this.data.id);
  }

  get name() {
    return this.castString(this.data.name);
  }

  serialize(): ServiceCenterSerializer {
    return this.data;
  }

  compare(instance: ServiceCenter): number {
    return this.id - instance.id;
  }
}

export { ServiceCenter };
