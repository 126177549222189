import type { OtherDetailsSectionProps } from "./OtherDetailsSection.types";
import { Link } from "react-router-dom";
import { useRelatedTickets } from "@/api";
import { DetailsSection } from "../DetailsSection";
import { DetailsSectionSplitContent } from "../DetailsSectionSplitContent";

const OtherDetailsSection = ({ ticket }: OtherDetailsSectionProps) => {
  // TODO: Fix no-non-null-asserted-optional-chain
  const { data: relatedTickets } = useRelatedTickets(ticket?.id!, {
    enabled: ticket !== undefined,
  });
  let relatedTicketsContent;
  if (relatedTickets?.length === 0) {
    relatedTicketsContent = (
      <p className="my-1 text-black text-opacity-33 text-sm">
        No related tickets
      </p>
    );
  } else {
    relatedTicketsContent = (
      <ul>
        {relatedTickets?.map((relatedTicket) => (
          <li key={relatedTicket.number}>
            <Link className="text-blue-600" to={`/ticket/${relatedTicket.id}`}>
              #{relatedTicket.number}
            </Link>
          </li>
        ))}
      </ul>
    );
  }
  return (
    <DetailsSection title="Other details">
      <DetailsSectionSplitContent
        left={[
          {
            label: "Related tickets",
            layout: "vertical",
            value: relatedTicketsContent,
          },
        ]}
      />
    </DetailsSection>
  );
};

export { OtherDetailsSection };
