import type { FormEvent } from "react";
import type { BulkCompleteTasksModalProps } from "./BulkCompleteTasksModal.types";
import type { dialogFooter } from "@/components/Dialog";
import { useToasts } from "@/components/Toasts";
import { pluralize } from "@/utils";
import { useBulkAnswerResponseForm } from "@/api";
import { Tag } from "@/common/Tag";
import { useResponseFormHandler } from "@/hooks/useResponseFormHandler";
import { TaskResponseFormQuestionInput } from "@/pages/TicketPage/TaskDetail/TaskResponseFormSection/TaskResponseFormQuestionInput";
import { Dialog } from "@/components/Dialog";

const BulkCompleteTasksModal = ({
  isOpen,
  onClose,
  tasks,
  responseForm,
  onSuccess,
}: BulkCompleteTasksModalProps) => {
  const { addToast } = useToasts();
  const {
    getIsQuestionDirty,
    getOptionsForQuestion,
    getQuestionValue,
    setIsQuestionDirty,
    setQuestionValue,
    formValues,
    isReadyForSubmission,
  } = useResponseFormHandler(responseForm);
  const bulkAnswerResponseForm = useBulkAnswerResponseForm();

  //
  // --- Handlers
  //
  const handleSubmit = async (e?: FormEvent) => {
    e?.preventDefault();
    const answers = Object.keys(formValues).map((k) => {
      const id = parseInt(k, 10);
      return { id, response: formValues[id] };
    });
    await bulkAnswerResponseForm.mutate(
      { responseForm, tasks, answers },
      {
        onSuccess: () => {
          addToast(`Completed ${pluralize(tasks.length, "task")}`, "success");
          onSuccess();
        },
        onError: () => {
          addToast(
            `Failed to complete ${pluralize(tasks.length, "task")}.`,
            "error"
          );
          onClose();
        },
      }
    );
  };

  const sortedQuestions = responseForm?.questions.sort(
    (q1, q2) => q1.sequence - q2.sequence
  );

  const modalFooter: dialogFooter = {
    toRender: "Complete Tasks",
    onClose,
    submitButtonTestId: "bulk-add-ticket-comments-action-button",
    submitButtonDisabled: !isReadyForSubmission,
    submitButtonIsLoading: bulkAnswerResponseForm.isLoading,
    onClick: handleSubmit,
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      header="Complete tasks"
      footer={modalFooter}
    >
      <>
        <Tag className="my-2 mb-6" type="info">
          <p>You are completing {pluralize(tasks.length, "task")}.</p>
        </Tag>
        <form className="mt-5" onSubmit={handleSubmit}>
          {sortedQuestions?.map((question, ix) => (
            <TaskResponseFormQuestionInput
              key={question.id}
              testId={`task-response-question-${ix}`}
              question={question}
              disabled={false}
              value={getQuestionValue(question)}
              options={getOptionsForQuestion(question)}
              isDirty={getIsQuestionDirty(question)}
              onChange={(val) => setQuestionValue(question, val)}
              onBlur={() => {
                setIsQuestionDirty(question);
              }}
            />
          ))}
        </form>
      </>
    </Dialog>
  );
};

export { BulkCompleteTasksModal };
