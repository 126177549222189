const browserScrollToId = (id: string, withDelay = false, delayTime = 500) => {
  const element = document.getElementById(id);

  const scrollFunction = () => {
    if (element !== null) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

  if (withDelay) {
    setTimeout(scrollFunction, delayTime);
  } else {
    scrollFunction();
  }
};

export { browserScrollToId };
