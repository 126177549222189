import type { BulkChangeAssigneeModalProps } from "./BulkChangeAssigneeModal.types";
import type { TaskForm } from "@/components/Modals/TaskModal/TaskModal.types";
import { useCallback, useState } from "react";
import { useToasts } from "@/components/Toasts";
import { TaskModal } from "@/components/Modals/";
import { useAssignableUsersForTasks, useBulkChangeAssignee } from "@/api";
import { pluralize } from "@/format";
import { Tag } from "@/common/Tag";
import { formValid } from "./BulkChangeAssigneeModal.utils";

const BulkChangeAssigneeModal = ({
  isOpen,
  onClose,
  tasks,
  onSuccess,
}: BulkChangeAssigneeModalProps) => {
  const [form, setForm] = useState<TaskForm>({});
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const reset = () => {
    setForm({});
  };
  const bulkUpdateAssignee = useBulkChangeAssignee();
  const handleSubmit = useCallback(() => {
    if (!formValid(form)) return;
    setLoading(true);
    const taskList = Array.from(tasks).map((task) => ({
      id: task.id!,
      assignee: form.assigneeId! || null,
    }));

    bulkUpdateAssignee.mutate(taskList, {
      onSuccess: (resp) => {
        setLoading(false);
        addToast(
          `Assignee changed on ${pluralize(tasks.length, "task")}.`,
          "success"
        );
        onSuccess && onSuccess(resp);
        onClose();
      },
      onError: () => {
        setLoading(false);
        addToast(
          `Failed to change assignee for ${pluralize(tasks.length, "task")}.`,
          "error"
        );
        onClose();
      },
    });
  }, [
    addToast,
    onClose,
    onSuccess,
    setLoading,
    bulkUpdateAssignee,
    form,
    tasks,
  ]);

  const assignableUsers = useAssignableUsersForTasks(tasks);

  return (
    <TaskModal
      title="Edit assignee"
      actionTitle="Save changes"
      isOpen={isOpen}
      isLoading={loading}
      isValid={formValid(form)}
      assigneeOptions={assignableUsers}
      assigneeLabel="Assign tasks to"
      value={form}
      onChange={setForm}
      afterClose={reset}
      onClose={onClose}
      onSubmit={handleSubmit}
      withDisabledTooltip="Please choose an assignee before saving, Assignee field cannot be left blank."
      toolTip
      unAssignedUser={false}
    >
      <Tag className="my-2 mb-6" type="info">
        <p>
          You are editing the assignee on {pluralize(tasks.length, "task")}.
        </p>
      </Tag>
    </TaskModal>
  );
};

export { BulkChangeAssigneeModal };
