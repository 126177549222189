import type { TicketView, TicketViewOrder } from "./TicketViewEditor.types";
import { SavedView, SortOrderDirection } from "@/models";
import { TicketOrderField } from "@/utils/damage-prevention";

const defaultView: TicketView = {
  id: undefined,
  name: "",
  isShared: false,
  isShowingTasks: false,
  userId: undefined,
  createdOn: undefined,
  updatedOn: undefined,
  order: {
    field: TicketOrderField.emergency,
    direction: SortOrderDirection.DESC,
  },
  filters: [],
  excludeFilters: [],
  columns: [{ field: "ticket_number" }],
};

const savedViewToTicketView = (view: SavedView): TicketView => ({
  id: view.id,
  name: view.name,
  userId: view.userId,
  isShared: view.shared,
  isShowingTasks: view.showTasks,
  order: (view.order as TicketViewOrder) || defaultView.order,
  filters: view.filters || defaultView.filters,
  excludeFilters: view.excludeFilters || defaultView.excludeFilters,
  columns: view.fields || defaultView.columns,
  createdOn: view.createdOn,
  updatedOn: view.updatedOn,
});

const ticketViewToSavedView = (view: TicketView): SavedView =>
  new SavedView({
    id: view.id,
    name: view.name,
    user: view.userId,
    shared: view.isShared,
    show_tasks: view.isShowingTasks,
    order: view.order,
    filters: view.filters,
    exclude_filters: view.excludeFilters,
    fields: view.columns,
  });

export { defaultView, savedViewToTicketView, ticketViewToSavedView };
