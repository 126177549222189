import type { DetailProps } from "../DetailsSectionSplitContent/Detail";
import type { LocateDetailsSectionProps } from "./LocateDetailsSection.types";
import { formatFullDateTime, startCase } from "@/format";
import {
  extractLocateIssueCodes,
  locateIssueCodesToString,
} from "@/utils/locateIssueCodes";
import { DetailsSection } from "../DetailsSection";
import { DetailsSectionSplitContent } from "../DetailsSectionSplitContent";

const LocateDetailsSection = ({ ticket }: LocateDetailsSectionProps) => {
  const latestVersion = ticket?.latestVersion;
  const locateIssueCodes = extractLocateIssueCodes(ticket?.positiveResponses);
  const leftFields: DetailProps[] = [
    {
      label: "Locate Area",
      value: startCase(latestVersion?.locateArea),
      layout: "vertical",
    },
    {
      label: "Description",
      value: startCase(latestVersion?.description),
      layout: "vertical",
    },
    {
      label: "Locate problem?",
      value: locateIssueCodes.size ? "Yes" : "",
      layout: "horizontal",
    },
    {
      label: "Problem cause",
      value: startCase(locateIssueCodesToString(locateIssueCodes)),
      layout: "horizontal",
    },
  ];

  const rightFields: DetailProps[] = [
    {
      label: "Remarks",
      value: startCase(latestVersion?.locateRemarks),
      layout: "vertical",
    },
    {
      label: "Due date extended",
      value: formatFullDateTime(
        ticket?.positiveResponses?.at(-1)?.dueDateExtended
      ),
    },
  ];
  return (
    <DetailsSection title="Locate Details">
      <DetailsSectionSplitContent left={leftFields} right={rightFields} />
    </DetailsSection>
  );
};

export { LocateDetailsSection };
