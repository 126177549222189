import type { BulkAddTasksModalProps } from "./BulkAddTasksModal.types";
import type { TaskForm } from "../TaskModal/TaskModal.types";
import { useMemo, useState } from "react";
import {
  useAssignableUsersForTaskTypes,
  useBulkCreateTasks,
  useTaskTypes,
} from "@/api";
import { pluralize } from "@/utils";
import { Tag } from "@/common/Tag";
import { useToasts } from "../../Toasts";
import { TaskModal } from "../TaskModal";
import { formValid } from "../Modals.utils";

const BulkAddTasksModal = ({
  isOpen,
  onClose,
  tickets,
  warningThreshold,
}: BulkAddTasksModalProps) => {
  const [form, setForm] = useState<TaskForm>({});
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const reset = () => {
    setForm({});
  };
  const bulkCreateTasks = useBulkCreateTasks();

  const handleSubmit = () => {
    if (!formValid(form)) return;
    setLoading(true);
    const tasks = Array.from(tickets).map((ticket) => ({
      ticket,
      task_type: form.taskTypeId!,
      assignee: form.assigneeId!,
    }));

    bulkCreateTasks.mutate(tasks, {
      onSuccess: () => {
        setLoading(false);
        addToast(
          `'${
            taskTypes?.find((t) => t.id === form.taskTypeId)?.name
          }' tasks added to ${pluralize(tickets.length, "ticket")}.`,
          "success"
        );
        onClose();
      },
      onError: () => {
        setLoading(false);
        addToast(
          `Failed to add tasks for ${pluralize(tickets.length, "ticket")}.`,
          "error"
        );
        onClose();
      },
    });
  };

  const { data: taskTypes } = useTaskTypes();
  const taskTypeOptions = useMemo(
    () => taskTypes?.map(({ id, name }) => ({ value: id, label: name })),
    [taskTypes]
  );
  const selectedTaskType = taskTypes?.find((t) => t.id === form.taskTypeId);
  const assignableUsers = useAssignableUsersForTaskTypes(
    selectedTaskType ? [selectedTaskType] : []
  );

  return (
    <TaskModal
      title="Add task"
      actionTitle="Add tasks"
      isOpen={isOpen}
      isLoading={loading}
      isValid={formValid(form)}
      taskTypeOptions={taskTypeOptions}
      assigneeOptions={assignableUsers}
      value={form}
      onChange={setForm}
      afterClose={reset}
      onClose={onClose}
      onSubmit={handleSubmit}
    >
      <Tag
        className="my-2 mb-6"
        type={tickets.length > warningThreshold ? "warning" : "info"}
      >
        <p>You are adding a task to {pluralize(tickets.length, "ticket")}.</p>
      </Tag>
    </TaskModal>
  );
};

export { BulkAddTasksModal };
