import type { FlexTicketQueryFields } from "@/models";

const TICKET_CARD_FIELDS: FlexTicketQueryFields[] = [
  "id",
  "number",
  "latest_version.damage_potential",
  "latest_version.excavation_state",
  "latest_version.excavation_city",
  "latest_version.excavation_street_name",
  "latest_version.excavation_street_number",
  "latest_version.excavation_work_type",
  "latest_version.work_start_date",
  "latest_version.equipment_type",
  "latest_version.type",
  "latest_version.contacts.company_name",
  "latest_version.contacts.primary",
  "latest_version.tlc_received_at",
  "is_emergency",
];

const TICKET_CARD_TASK_FIELDS: FlexTicketQueryFields[] = [
  "tasks.id",
  "tasks.completed_time",
  "tasks.assignee.id",
  "tasks.assignee.email",
  "tasks.assignee.first_name",
  "tasks.assignee.last_name",
  "tasks.assignee.is_active",
  "tasks.task_type.id",
  "tasks.task_type.name",
  "tasks.task_step.id",
  "tasks.task_step.name",
  "tasks.task_step.initial",
  "tasks.task_step.terminating",
  "tasks.latest_comment.comment",
];

const TICKET_CARD_FIELDS_WITH_TASKS = TICKET_CARD_FIELDS.concat(
  TICKET_CARD_TASK_FIELDS
);

export { TICKET_CARD_FIELDS_WITH_TASKS, TICKET_CARD_FIELDS };
