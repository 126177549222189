import type { ListViewProps } from "./ListView.types";
import cx from "classnames";

const ListView = ({ className, children }: ListViewProps) => (
  <ul className={cx("grid gap-2 grid-cols-1 sm:grid-cols-2", className)}>
    {children}
  </ul>
);

export { ListView };
