import type {
  UseInfiniteQueryOptions,
  UseQueryOptions,
} from "@tanstack/react-query";
import type { TicketField } from "@/hooks/useFlexFields";
import type { FlexTicketQueryFields } from "@/models";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import { useDebounce, useFlexTicketsFields } from "@/hooks";
import {
  type FlexTicket,
  type SavedViewFilter,
  type SavedViewOrder,
  respToFlexTicket,
} from "@/models";
import { UrbintApi } from "@/utils/UrbintApi";
import { queryClient } from "@/api/client";
import { handleApiResponse } from "@/api/helpers";
import { prepareRequestBody } from "@/api/utils";

const api = new UrbintApi();
const fetchTicketsLimit = 200;

interface UseFlexTicketsOpts {
  fields?: TicketField[];
  filters?: SavedViewFilter[];
  excludeFilters?: SavedViewFilter[];
  order?: SavedViewOrder;
  searchTerm?: string;
  allowAbort?: boolean;
}

type UseFlexTicketsResult = {
  nextOffset?: number | null;
  data: FlexTicket[];
};

type requestDataProps = UseFlexTicketsOpts & {
  searchTerm?: string;
  ticketsFields?: FlexTicketQueryFields[];
};

const flexTicketsIdentKey = ["flex_tickets"];
const flexTicketsIdent = (requestData: requestDataProps) => {
  const params = prepareRequestBody(requestData);
  return [flexTicketsIdentKey, JSON.stringify(params)];
};

type FetchTicketsPageParams = {
  offset: number;
  limit: number;
};

const fetchTickets =
  (requestData: requestDataProps) =>
  async ({ pageParam }: { pageParam?: FetchTicketsPageParams }) => {
    const reqOpts = {
      ...requestData,
      pageParameters: pageParam,
      ticketsLimit: fetchTicketsLimit,
    };

    const params = prepareRequestBody(reqOpts);

    // Cancel previous requests
    const { allowAbort = true } = reqOpts ?? {};

    if (allowAbort) {
      api.abort();
    }

    const res = await api.post("tickets_flex/items", JSON.stringify(params));

    const { data, nextOffset } = handleApiResponse(res);
    const tickets = data.map(respToFlexTicket);
    return nextOffset ? { data: tickets, nextOffset } : { data: tickets };
  };

const emptyFields: TicketField[] = [];

const useFlexTickets = (
  /** Typically a DirtyView. You'll also register any flex fields needed here. Calling this twice will aggregate the flex field
   * and make sure the query is only called once. credit to: @rschmuckler
   */
  args: UseFlexTicketsOpts,
  opts?: UseInfiniteQueryOptions<UseFlexTicketsResult>
) => {
  const requestData: requestDataProps = {
    ...args,
    searchTerm: useDebounce(args.searchTerm, { delay: 400, throttle: false }),
    ticketsFields: useFlexTicketsFields(args.fields || emptyFields)(),
  };

  const ident = flexTicketsIdent(requestData);

  const {
    data,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    ...infiniteOptions
  } = useInfiniteQuery<UseFlexTicketsResult>(ident, fetchTickets(requestData), {
    ...opts,
    getNextPageParam: (lastPage: UseFlexTicketsResult) => {
      const params: FetchTicketsPageParams = {
        offset: lastPage?.nextOffset || 0,
        limit: fetchTicketsLimit,
      };
      return params;
    },
  });

  const fetchMissingItems = useCallback(
    (missingItems: number) => {
      if (!isFetchingNextPage && hasNextPage) {
        const nextOffset = data?.pages
          ? data.pages[data.pages.length - 1]?.nextOffset
          : null;
        if (nextOffset) {
          const limit =
            Math.ceil(Math.abs(missingItems || 1) / fetchTicketsLimit) *
            fetchTicketsLimit;
          const params: FetchTicketsPageParams = { offset: nextOffset, limit };
          fetchNextPage({ pageParam: params });
        }
      }
    },
    [isFetchingNextPage, hasNextPage, data?.pages, fetchNextPage]
  );

  return {
    fetchMissingItems,
    data,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    ...infiniteOptions,
  };
};

interface FlexTicketsCount {
  count?: number;
}

const flexTicketsCountIdentKey = ["flex_tickets_count"];
const flexTicketsCountIdent = (requestData: string) => [
  flexTicketsCountIdentKey,
  requestData,
];

const fetchTicketsCount = (requestData: string) => async () => {
  // Cancel previous requests
  api.abort();
  const res = await api.post("tickets_flex/count", requestData);

  return handleApiResponse(res);
};

const useFlexTicketsCount = (
  args: UseFlexTicketsOpts,
  opts?: UseQueryOptions<FlexTicketsCount>
) => {
  const requestData: requestDataProps = {
    ...args,
    searchTerm: useDebounce(args.searchTerm, { delay: 400, throttle: false }),
  };

  const requestBody = JSON.stringify(prepareRequestBody(requestData));

  return useQuery<FlexTicketsCount>(
    flexTicketsCountIdent(requestBody),
    fetchTicketsCount(requestBody),
    opts
  );
};

const prefetchFlexTickets = (
  args: UseFlexTicketsOpts,
  opts?: UseInfiniteQueryOptions<UseFlexTicketsResult>
) => {
  const requestData: requestDataProps = {
    ...args,
    ticketsFields: useFlexTicketsFields(args.fields || emptyFields)(),
  };

  const ident = flexTicketsIdent(requestData);
  queryClient.prefetchInfiniteQuery(ident, fetchTickets(requestData), opts);
};

const prefetchFlexTicketsCount = (
  args: requestDataProps,
  opts?: UseQueryOptions<FlexTicketsCount>
) => {
  const requestBody = JSON.stringify(prepareRequestBody(args));
  queryClient.prefetchQuery(
    flexTicketsCountIdent(requestBody),
    fetchTicketsCount(requestBody),
    opts
  );
};

const invalidateUseFlexTicketsQuery = () => {
  queryClient.invalidateQueries([flexTicketsIdentKey]);
  queryClient.invalidateQueries([flexTicketsCountIdentKey]);
};

const resetFlexTicketsQuery = () => {
  queryClient.resetQueries(flexTicketsIdentKey);
  queryClient.resetQueries(flexTicketsCountIdentKey);
};

export type { UseFlexTicketsOpts, requestDataProps, FetchTicketsPageParams };
export {
  useFlexTickets,
  useFlexTicketsCount,
  invalidateUseFlexTicketsQuery,
  resetFlexTicketsQuery,
  prefetchFlexTickets,
  prefetchFlexTicketsCount,
};
