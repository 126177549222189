import type { MapLocateMeControlProps } from "./MapLocateMeControl.types";
import classNames from "classnames";
import { useMap } from "react-map-gl";
import { Tooltip } from "@/common/Tooltip";
import { useGeoLocation } from "@/hooks";
import { MapControl } from "../MapControl";

const MapLocateMeControl = ({ onLocateMe }: MapLocateMeControlProps) => {
  const { current: map } = useMap();
  const { loading, latitude, longitude, error } = useGeoLocation();
  const availableGeoLocation = map && !loading && !error;

  const handleLocateMe = () => {
    if (availableGeoLocation && latitude && longitude) {
      onLocateMe(true);
      map.flyTo({ center: [longitude, latitude], zoom: 14 });
    }
  };

  const cursorBehavior = availableGeoLocation
    ? "cursor-pointer"
    : "cursor-not-allowed opacity-60";
  const tooltipTitle = availableGeoLocation
    ? "Show my location"
    : "Location not available";

  return (
    <Tooltip
      theme={{ container: classNames(cursorBehavior) }}
      origin="right"
      title={loading ? "Getting current location..." : tooltipTitle}
    >
      <MapControl onClick={handleLocateMe} icon="target" />
    </Tooltip>
  );
};

export { MapLocateMeControl };
