import "form-request-submit-polyfill";
import { setupConfig } from "@ionic/react";
import ReactDOM from "react-dom";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { DamagePreventionApp } from "@/components/DamagePreventionApp";
import { FullStory } from "@/components/FullStory";
import { PRODUCTION } from "@/utils/environment.constants";
import { OnlineStatusProvider } from "@/hooks/useOnlineStatus";
import { App } from "./App";
import { reportWebVitals } from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { ReactQueryDevtoolsProduction } from "./ReactQueryDevToolsProduction";

setupConfig({ mode: "md" });

const fullStoryEnabled = process.env.NODE_ENV === PRODUCTION;

ReactDOM.render(
  <OnlineStatusProvider>
    <DamagePreventionApp>
      {fullStoryEnabled && <FullStory />}
      <App />
      <ReactQueryDevtools initialIsOpen={false} />
      <ReactQueryDevtoolsProduction />
    </DamagePreventionApp>
  </OnlineStatusProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
