import type { SelectOption } from "@/components/Select";
import { filterDuplicatesByLabel } from "../../Select.utils";
import {
  SELECT_ALL_MIN_CHARACTER_LENGTH,
  VISIBLE_OPTIONS_LIMIT,
} from "./SelectOptionsWrapper.constants";

const getOptions = (options: SelectOption<string>[], filterValue: string) => {
  const filteredOptions =
    filterValue === ""
      ? options
      : options?.filter((option: SelectOption<string>) =>
          option.label.toLowerCase().includes(filterValue.toLowerCase())
        );

  return filterDuplicatesByLabel(
    filteredOptions.slice(0, VISIBLE_OPTIONS_LIMIT)
  );
};

const shouldShowSelectAll = (
  filterValue: string,
  featureFlagEnabled = false
) => {
  if (featureFlagEnabled) {
    return (
      filterValue.replace(/\s+/g, "").length >= SELECT_ALL_MIN_CHARACTER_LENGTH
    );
  }
  return false;
};

export { getOptions, shouldShowSelectAll };
