import type { SlideContentProps } from "./SlideContent.types";
import { DamageThreatInsightDetail } from "@/components/DamageThreatInsightDetail";

const SlideContent = ({ slideInfo, ...props }: SlideContentProps) => (
  <div className="px-4 py-6 bg-white" {...props}>
    <DamageThreatInsightDetail threatData={slideInfo} />
  </div>
);

export { SlideContent };
