import type { SwitchProps } from "./Switch.types";
import { Switch as HeadlessSwitch } from "@headlessui/react";
import { Icon } from "@urbint/silica";
import classNames from "classnames";

const Switch = ({
  checked,
  onChange,
  label,
  showLabel = true,
  className = "",
  labelClassName = "",
  dataTestId,
  disabled,
}: SwitchProps) => (
  <HeadlessSwitch.Group>
    <div
      data-testid={dataTestId}
      className={classNames("flex items-center", className)}
    >
      <HeadlessSwitch
        data-testid={
          checked ? `${dataTestId}-checked` : `${dataTestId}-unchecked`
        }
        disabled={disabled}
        className={classNames(
          `w-7 h-[1.125rem] rounded-xl border-box border items-center flex px-0.5
            transition duration-200 flex-shrink-0  border-black border-opacity-22`,
          {
            "bg-blue-500": checked,
            "bg-white": !checked,
            "mr-2": showLabel,
            "opacity-40": disabled,
          }
        )}
        checked={checked}
        onChange={onChange}
      >
        <span
          className={classNames(
            "transform-gpu transition duration-200 bg-white h-3 w-3 rounded-full flex border border-black border-opacity-11 items-center justify-center",
            {
              "translate-x-[0.625rem]": checked,
              "translate-x-0 bg-gray-200": !checked,
            }
          )}
        >
          <Icon
            name="check"
            className={classNames(
              "transition duration-200 icon-bold text-blue-700 text-sm",
              {
                "opacity-100": checked,
                "opacity-0": !checked,
              }
            )}
          />
        </span>
      </HeadlessSwitch>
      <HeadlessSwitch.Label
        className={classNames(
          "leading-tight whitespace-nowrap",
          {
            "sr-only": !showLabel,
            "opacity-40": disabled,
            "cursor-pointer": !disabled,
          },
          labelClassName
        )}
      >
        {label}
      </HeadlessSwitch.Label>
    </div>
  </HeadlessSwitch.Group>
);

export { Switch };
