enum BANNER_TYPE {
  OFFLINE = "offline",
  SYNCING = "syncing",
  ERROR = "error",
  SUCCESS = "success",
  OFFLINE_FORM_DEFAULT = "offlineForm",
  OFFLINE_FORM_SUBMITTED = "offlineFormSubmitted",
  OFFLINE_ATTACHMENTS_SUBMITTED = "offlineAttachmentSubmitted",
  OFFLINE_ATTACHMENT_DELETED = "offlineAttachmentDeleted",
}

enum ICON_NAME {
  SUCCESS = "circle_check",
  ERROR = "error",
  WARNING = "warning",
  SYNCING = "info_circle",
}

const BASE_STYLE = "flex flex-auto items-center";

/**
 * Pre default banners
 */
const BANNER_STATUS = {
  OFFLINE: {
    icon: ICON_NAME.WARNING,
    iconBackground: "bg-system-warning-40",
    label: "No internet connection.",
    labelLink: "Learn more",
    styles: "h-10 bg-system-warning-10",
  },
  SYNCING: {
    icon: ICON_NAME.SYNCING,
    iconBackground: "bg-system-info-40",
    label: "Sync in progress, please do not refresh or close this browser",
    labelLink: null,
    styles: "h-10 bg-system-info-10",
  },
  ERROR: {
    icon: ICON_NAME.ERROR,
    iconBackground: "bg-system-error-40",
    label: "Failed to sync offline changes.",
    labelLink: "Retry",
    styles: "h-10 bg-system-error-10",
  },
  SUCCESS: {
    icon: ICON_NAME.SUCCESS,
    iconBackground: "bg-system-success-40",
    label: "Offline changes synced!",
    labelLink: null,
    styles: "h-10 bg-system-success-10",
  },
  OFFLINE_FORM_DEFAULT: {
    icon: ICON_NAME.SYNCING,
    iconBackground: "bg-system-info-40",
    label:
      "You are currently offline. Your task actions will be synced as soon as you are back online. Please do not refresh or close the browser.",
    labelLink: null,
    styles: "h-auto bg-system-info-10",
  },
  OFFLINE_FORM_SUBMITTED: {
    icon: ICON_NAME.SUCCESS,
    iconBackground: "bg-system-success-40",
    label: "Task submitted offline",
    labelLink: null,
    styles: "h-10 bg-system-success-10",
  },
  OFFLINE_ATTACHMENTS_SUBMITTED: {
    icon: ICON_NAME.SUCCESS,
    iconBackground: "bg-system-success-40",
    label:
      "Attachment submitted while in offline, the action will be synced once we comeback online.",
    labelLink: null,
    styles: "h-auto bg-system-success-10",
  },
  OFFLINE_ATTACHMENTS_DELETED: {
    icon: ICON_NAME.SUCCESS,
    iconBackground: "bg-system-success-40",
    label: "Attachment/s deleted while in offline",
    labelLink: null,
    styles: "h-auto bg-system-success-10",
  },
};

export { BANNER_STATUS, BANNER_TYPE, ICON_NAME, BASE_STYLE };
