import { useMutation } from "@tanstack/react-query";

import { useDamagePreventionAuth } from "@/hooks";
import { getCookies } from "@/utils";
import { invalidateUseTicketActivityQuery } from "@/api";

type ForwardTicketPayload = {
  egress_id: number;
  member_codes: number[];
};

type ForwardTicketResponse = {
  message: string;
};

const forwardTicketFetcher = async (
  body: ForwardTicketPayload,
  bearerToken: string,
  ticketId?: number
) => {
  if (ticketId === undefined) {
    return;
  }
  const cookies = getCookies();
  const res = await fetch(`/api/tickets/${ticketId}/forward/`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${bearerToken}`,
      Accept: "application/json",
      "X-CSRFToken": cookies.csrftoken,
    },
  });

  return await res.json();
};

const useForwardTicket = (ticketId?: number) => {
  const { bearerToken } = useDamagePreventionAuth();

  return useMutation<ForwardTicketResponse, unknown, ForwardTicketPayload>(
    (forwardTicketPayload: ForwardTicketPayload) =>
      forwardTicketFetcher(forwardTicketPayload, bearerToken, ticketId),
    {
      onSuccess() {
        if (ticketId) {
          invalidateUseTicketActivityQuery(ticketId);
        }
      },
    }
  );
};

// eslint-disable-next-line import/prefer-default-export
export { useForwardTicket };
