import type { LegendCircleSVGProps } from "./LegendCircleSVG.types";
import React from "react";

const LegendCircleSVG = ({
  fill,
  stroke,
  opacity = 0.5,
}: LegendCircleSVGProps) => (
  <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
    <circle
      cx="10"
      cy="10"
      r="9"
      fill={fill}
      fillOpacity={opacity}
      stroke={stroke}
      strokeWidth="1"
    />
  </svg>
);

export { LegendCircleSVG };
