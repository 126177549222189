import type { ActionBarLeftProps } from "./ActionBarLeft.types";
import classNames from "classnames";
import { Button } from "@/common/Button";
import { useTicketSelector } from "@/components/TicketSelectorProvider";
import { useResponsive } from "@/hooks";
import { SavedViewSelectDropdown } from "../SavedViewSelectDropdown";

const ActionBarLeft = ({
  setThresholdToastShown,
  onUpdateSavedView,
}: ActionBarLeftProps) => {
  const { isSm } = useResponsive();

  const { selectMode, reset } = useTicketSelector();

  const handleExitSelectMode = () => {
    setThresholdToastShown(false);
    reset();
  };

  return (
    <div
      className={classNames(
        "min-w-60 w-full h-12 sm:h-full flex items-center justify-between sm:justify-start px-3 sm:px-0 z-10",
        {
          "sm:w-60": selectMode,
          "sm:w-auto": !selectMode,
          "bg-gray-10": selectMode && !isSm,
        }
      )}
    >
      {selectMode ? (
        <>
          <div className="text-lg text-neutral-shade-primary">
            Selecting {selectMode === "tickets" ? "tickets" : "tasks"}...
          </div>
          <Button
            className="visible sm:hidden"
            variant="tertiary"
            onClick={handleExitSelectMode}
          >
            Cancel
          </Button>
        </>
      ) : (
        <SavedViewSelectDropdown onViewSelect={onUpdateSavedView} />
      )}
    </div>
  );
};

export { ActionBarLeft };
