import type { UseQueryOptions } from "@tanstack/react-query";
import type { TaskAttachmentSerializer } from "@/utils/damage-prevention";
import { useQuery } from "@tanstack/react-query";
import { datadogRum } from "@datadog/browser-rum";
import { TaskAttachments } from "@/models";
import { UrbintApi } from "@/utils/UrbintApi";
import { queryClient } from "@/api/client";
import { handleApiResponse } from "@/api/helpers";

const api = new UrbintApi();

const taskAttachmentsIdent = (taskId: number, attachmentId?: number) => [
  "task/attachments",
  taskId,
  attachmentId,
];

const fetchTaskAttachments = (ticketId: number, attachmentId?: number) => {
  if (!ticketId) {
    const ErrorMessage = new Error(
      "Missing ticketId from Task Attachments api call."
    );
    datadogRum.addError(ErrorMessage, {
      errorIdentifier: "GET_TASK_ATTACHMENTS",
    });
    return Promise.reject(ErrorMessage);
  }
  if (attachmentId) {
    return api
      .getOne<TaskAttachmentSerializer>({
        endPoint: `tasks/${ticketId}`,
        action: `attachments/${attachmentId}`,
      })
      .then(handleApiResponse)
      .then((res) => [new TaskAttachments(res)]);
  }

  return api
    .getPage<TaskAttachmentSerializer>({
      endPoint: "tasks",
      id: ticketId,
      action: "attachments",
      page: 1,
    })
    .then(handleApiResponse)
    .then((res) => res.results.map((r) => new TaskAttachments(r)));
};

const removeUseTaskAttachmentQuery = (taskId: number, attachmentId: number) => {
  queryClient.removeQueries(taskAttachmentsIdent(taskId, attachmentId));
};

const useTaskAttachments = (
  taskId: number,
  attachmentId?: number,
  opts?: UseQueryOptions<TaskAttachments[]>
) =>
  useQuery<TaskAttachments[]>(
    taskAttachmentsIdent(taskId, attachmentId),
    () => fetchTaskAttachments(taskId, attachmentId),
    {
      ...opts,
      onError: (error) =>
        datadogRum.addError(error, {
          errorIdentifier: "GET_TASK_ATTACHMENTS",
        }),
    }
  );

const prefetchTaskAttachments = (taskId: number) =>
  queryClient.prefetchQuery<TaskAttachments[]>(
    taskAttachmentsIdent(taskId),
    () => fetchTaskAttachments(taskId)
  );

const invalidateUseTaskAttachmentsQuery = (taskId: number) =>
  queryClient.invalidateQueries(taskAttachmentsIdent(taskId));

export {
  taskAttachmentsIdent,
  removeUseTaskAttachmentQuery,
  useTaskAttachments,
  prefetchTaskAttachments,
  invalidateUseTaskAttachmentsQuery,
};
