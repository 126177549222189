import type { INPUT_TYPE } from "../FormBuilder.types";
import { QuestionAnswerType } from "@/models";
import { DropdownMultiSelect, DropdownSelect } from "@/components/Select";
import { AutoGrowingTextArea } from "@/components/AutoGrowingTextArea";
import { DateTimeInput } from "@/components/DateTimeInput";

const INPUT_TYPE_MAP: INPUT_TYPE = {
  [QuestionAnswerType.SINGLE_SELECT_PICKLIST]: DropdownSelect,
  [QuestionAnswerType.POSITIVE_RESPONSE_CODE]: DropdownSelect,
  [QuestionAnswerType.COMMENT_INPUT_FIELD]: AutoGrowingTextArea,
  [QuestionAnswerType.TEXT_INPUT_FIELD]: AutoGrowingTextArea,
  [QuestionAnswerType.DATE_SELECTOR]: DateTimeInput,
  [QuestionAnswerType.DATE_TIME_SELECTOR]: DateTimeInput,
  [QuestionAnswerType.MULTI_SELECT_PICKLIST]: DropdownMultiSelect,
};
const SELECT_FIELDS = [
  QuestionAnswerType.SINGLE_SELECT_PICKLIST,
  QuestionAnswerType.POSITIVE_RESPONSE_CODE,
  QuestionAnswerType.MULTI_SELECT_PICKLIST,
];

export { INPUT_TYPE_MAP, SELECT_FIELDS };
