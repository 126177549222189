import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";

import { UrbintApi } from "@/utils/UrbintApi";
import { handleApiResponse } from "@/api/helpers";

type TaskType = {
  id: number;
  name: string;
};

const api = new UrbintApi();

const fetchNonTicketEventTypes = () =>
  api
    .getMany<TaskType>({ endPoint: "non_ticketed_events/types" })
    .then(handleApiResponse);

const useNonTicketEventTypes = (
  opts?: UseQueryOptions<
    TaskType[],
    unknown,
    TaskType[],
    ["non_ticketed_events/types"]
  >
) => useQuery(["non_ticketed_events/types"], fetchNonTicketEventTypes, opts);

// eslint-disable-next-line import/prefer-default-export
export { useNonTicketEventTypes };
