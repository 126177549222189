import type { DamageThreatInsightDetailProps } from "./DamageThreatInsightDetail.types";
import { BodyText, CaptionText, Subheading } from "@urbint/silica";
import { Gauge } from "@/common/Gauge";
import { RiskBadge } from "../RiskBadge";
import {
  MACHINE_LEARNING_INSIGHTS_TEXT,
  damageText,
  titleText,
} from "./DamageThreatInsightDetail.constants";
import { DamageThreatInsightFeedbackForm } from "./DamageThreatInsightFeedbackForm";
import { getGaugeArcColor } from "./DamageThreatInsightDetail.utils";

const DamageThreatInsightDetail = ({
  threatData,
}: DamageThreatInsightDetailProps) => {
  const color = getGaugeArcColor(threatData?.threatRank);
  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-3 sm:gap-8">
        <Gauge
          value={Number(threatData.percentile)}
          theme={{ wrapper: "max-w-xs mx-auto sm:col-span-2", arcColor: color }}
        />
        <div className="flex flex-row justify-evenly sm:flex-col sm:col-span-1 ">
          <div className="flex flex-col items-start">
            <BodyText className={titleText}>Relative Rank</BodyText>
            <RiskBadge value={threatData?.threatRank} />
          </div>
          <div>
            <BodyText className={titleText}>Damage rate</BodyText>
            {threatData?.damageRate !== null && (
              <Subheading className={`${damageText} text-2xl pb-0.5`}>
                {threatData.damageRate}
              </Subheading>
            )}
            <BodyText className={`${damageText} text-xs`}>
              Damages/1000 tickets
            </BodyText>
          </div>
        </div>
      </div>
      <CaptionText className="italic py-4">
        {MACHINE_LEARNING_INSIGHTS_TEXT}
      </CaptionText>
      <DamageThreatInsightFeedbackForm
        threatData={threatData}
        ticketId={threatData.ticket}
      />
    </>
  );
};

export { DamageThreatInsightDetail };
