import { FieldMemberStatusType } from "./FieldMemberMarker.types";

/**
 * Defines field member status from `lastUpdate`
 * @param lastUpdate timestamp
 * @returns field member status type
 */
const getFieldMemberStatus = (lastUpdate: Date) => {
  const now = new Date();
  const delta = Math.abs(now.getTime() - lastUpdate.getTime());
  const hoursElapsed = Math.floor(delta / (1000 * 60 * 60));

  // Do not show users that are inactive for >= 3 days
  const noneHourThreshold = 3 * 24;

  // Do not show status indicator for users that are inactive for >= 3 hours
  const hiddenHourThreshold = 3;

  // User is considered inactive if:
  // `lastUpdate` > 1 hour && `lastUpdate` < 3 hours
  const inactiveHourThreshold = 1;

  if (!lastUpdate || hoursElapsed >= noneHourThreshold) {
    return FieldMemberStatusType.NONE;
  }

  if (hoursElapsed >= hiddenHourThreshold) {
    return FieldMemberStatusType.HIDDEN;
  }

  if (hoursElapsed >= inactiveHourThreshold) {
    return FieldMemberStatusType.INACTIVE;
  }

  return FieldMemberStatusType.ACTIVE;
};

/**
 * Composes two letter identifier for field members
 * @param firstName user's first name
 * @param lastName user's last name
 * @returns uppercase field member initials or two letter identifier
 */

const getFieldMemberIdentifier = (firstName: string, lastName: string) => {
  firstName = firstName ? firstName.trim() : "";
  lastName = lastName ? lastName.trim() : "";

  // Display user initials if user has first and last names
  if (firstName.length >= 1 && lastName.length >= 1) {
    return `${firstName[0]}${lastName[0]}`.toUpperCase();
  }

  // Display first two letters of first name if user does not have a last name
  // or first letter of first name if first name length has only one character
  if (firstName.length >= 1 && lastName.length === 0) {
    return `${firstName[0]}${
      firstName.length >= 2 ? firstName[1] : ""
    }`.toUpperCase();
  }

  // Display first two letters of last name if user does not have a first name
  // or first letter of last name if last name length has only one character
  if (firstName.length === 0 && lastName.length >= 1) {
    return `${lastName[0]}${
      lastName.length >= 2 ? lastName[1] : ""
    }`.toUpperCase();
  }

  // This should not occur because all users should have at least one name
  return `??`;
};
export { getFieldMemberStatus, getFieldMemberIdentifier };
