import type { SelectOption } from "@/components/Select";
import type { RelativeOption } from "./RangeDateTime.types";

const RELATIVE_OPTIONS: SelectOption<RelativeOption>[] = [
  { value: "today", label: "Today" },
  { value: "tomorrow", label: "Tomorrow" },
  { value: "next_three", label: "Next 3 days" },
  { value: "next_seven", label: "Next 7 days" },
  { value: "yesterday", label: "Yesterday" },
  { value: "last_three", label: "Last 3 days" },
  { value: "last_seven", label: "Last 7 days" },
  { value: "last_thirty", label: "Last 30 days" },
  { value: "last_sixty", label: "Last 60 days" },
  { value: "last_ninety", label: "Last 90 days" },
  { value: "custom", label: "Custom" },
];
const RELATIVE_OPTION_TO_START_AND_END: Record<
  RelativeOption,
  [string | undefined, string | undefined]
> = {
  today: ["today", "tomorrow"],
  tomorrow: ["tomorrow", "2 days"],
  next_three: ["today", "4 days"],
  next_seven: ["today", "8 days"],
  yesterday: ["yesterday", "today"],
  last_three: ["-3 days", "now"],
  last_seven: ["-7 days", "now"],
  last_thirty: ["-30 days", "now"],
  last_sixty: ["-60 days", "now"],
  last_ninety: ["-90 days", "now"],
  custom: [undefined, undefined],
};
export { RELATIVE_OPTIONS, RELATIVE_OPTION_TO_START_AND_END };
