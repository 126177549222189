// RangeSlider.tsx
import type { Range } from "react-input-range";
import type { RangeSliderProps } from "./RangeSlider.types";

import { useState } from "react";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import { MIN_RANGE, MAX_RANGE, STEP } from "./RangeSlider.constants";

import "./RangeSlider.css";

// TODO: Temporary component, will soon be replaced by Silica Range Slider
const RangeSlider = ({
  min = 0,
  max = 100,
  onValuesChange,
}: RangeSliderProps) => {
  const [values, setValues] = useState<Range>(() => ({ min, max }));

  const handleChange = (value: number | Range) => {
    if (typeof value === "object") {
      // Apparently this Lib has a open issue: https://github.com/davidchin/react-input-range/issues/158
      // So we need to apply a safeguard to prevent out of bounds numbers
      const safeMin = value.min <= MIN_RANGE ? MIN_RANGE : value.min;
      const safeMax = value.max >= MAX_RANGE ? MAX_RANGE : value.max;

      setValues({ min: safeMin, max: safeMax });
      onValuesChange([safeMin, safeMax]);
    }
  };

  return (
    <div className="p-5">
      <InputRange
        minValue={MIN_RANGE}
        maxValue={MAX_RANGE}
        value={values}
        step={STEP}
        onChange={handleChange}
      />
    </div>
  );
};

export { RangeSlider };
