import type { OverflowMenuItemProps } from "./OverflowMenuItem.types";
import { useContext, useEffect, useRef, useCallback } from "react";
import classNames from "classnames";

import { uniqueId } from "lodash";

import { OverflowContext } from "../OverflowProvider";

const OverflowMenuItem = ({
  children,
  onClick,
  overflow,
  disabled,
  dataTestId,
}: OverflowMenuItemProps) => {
  const {
    menuItems,
    registerMenuItem,
    deregisterMenuItem,
    setIsOpen,
    updateMenuItem,
  } = useContext(OverflowContext);
  const idRef = useRef<string>(uniqueId("overflow-item-"));
  const isShown = overflow ? !menuItems[idRef.current]?.isOverflowing : true;

  useEffect(() => {
    if (idRef.current) {
      registerMenuItem(idRef.current, {
        onClick,
        overflow,
        children,
        disabled,
        id: idRef.current,
        dataTestId,
      });
    }

    return () => {
      deregisterMenuItem(idRef.current);
    };
    // DON'T include registerMenuItem in this array
  }, [idRef.current]);

  useEffect(() => {
    if (idRef.current) {
      updateMenuItem(idRef.current, { onClick, overflow, children, disabled });
    }
  }, [onClick, overflow, disabled, children]);

  const handleClick = useCallback(() => {
    if (disabled) return;
    onClick && onClick();
    setIsOpen(false);
  }, [onClick, setIsOpen, disabled]);

  if (!isShown) return null;

  return (
    <div
      onClick={handleClick}
      className={classNames(
        "h-14 w-full px-3 flex items-center justify-start hover:bg-neutral-shade-background-light cursor-pointer",
        {
          "opacity-33 cursor-default": disabled,
        }
      )}
      data-testid={dataTestId}
    >
      {children}
    </div>
  );
};

export { OverflowMenuItem };
