import type { Selector, TicketView } from "./TicketViewEditor.types";
import type { DateRangeFilter, FlexTask, SavedViewField } from "@/models";
import isNumber from "lodash/isNumber";
import isString from "lodash/isString";
import get from "lodash/get";
import isEqual from "lodash/isEqual";

import { type SavedViewFilter, SortOrderDirection } from "@/models";
import { indexBy } from "@/utils";
import { parseRelativeDateTime } from "@/format";

const isFilterValid = (filter: SavedViewFilter) => {
  // Number Filter
  if (isNumber(filter.value)) {
    return true;
    // Date
  }
  if (get(filter.value, "type") === "date") {
    return true;
    // string[] or number[]
  }
  if (Array.isArray(filter.value)) {
    return filter.value.length > 0;
    // string
  }
  if (isString(filter.value)) {
    return filter.value.length > 0;
  }

  return !!filter.value;
};

const toggleSortOrderDirection = (search: TicketView) => {
  const { order } = search;
  if (!order) {
    return search;
  }
  return {
    ...search,
    order: {
      ...order,
      direction:
        order.direction === SortOrderDirection.ASC
          ? SortOrderDirection.DESC
          : SortOrderDirection.ASC,
    },
  };
};

function findFilterIndex<T>(
  selector: Selector<T>,
  filters: Array<SavedViewFilter | SavedViewField>
): number {
  if ("index" in selector) {
    return selector.index;
  }
  if ("field" in selector) {
    return filters.findIndex((x) => x.field === selector.field);
  }
  return filters.findIndex((x) => isEqual(x, selector.value));
}

const buildShowTaskFn = (filters: SavedViewFilter[], currentUser: any) => {
  const filtersByField = indexBy(filters, (x) => x.field);
  // Assignee ID filter
  const openAssigneeIds = filtersByField.get("open_task_assignees")?.value as
    | (number | "unassigned" | "me")[]
    | undefined;
  const openAssigneeIdSet = openAssigneeIds
    ? new Set(openAssigneeIds)
    : new Set();
  const otherAssigneeIds = filtersByField.get("task_assignees")?.value as
    | (number | "unassigned" | "me")[]
    | undefined;
  const otherAssigneeIdSet = otherAssigneeIds
    ? new Set(otherAssigneeIds)
    : new Set();
  const assigneeIdSet = new Set([...openAssigneeIdSet, ...otherAssigneeIdSet]);

  const isAssigneeValid =
    assigneeIdSet && assigneeIdSet.size > 0
      ? (task: FlexTask) =>
          assigneeIdSet.has(task.assignee?.id || "unassigned") ||
          (assigneeIdSet.has("me") &&
            task.assignee?.email === currentUser.email)
      : () => true;

  // Task status filter
  const taskStatusNames = filtersByField.get("open_task_statuses")?.value as
    | string[]
    | undefined;
  const anyOpenTaskFilters =
    [...filtersByField.keys()].filter((x) => x.startsWith("open_task")).length >
    0;
  const taskStatusNameSet = taskStatusNames && new Set(taskStatusNames);
  const isStatusValid = taskStatusNameSet
    ? (task: FlexTask) =>
        (task.taskStep?.name && taskStatusNameSet.has(task.taskStep.name)) ||
        false
    : (task: FlexTask) =>
        anyOpenTaskFilters ? !task.taskStep?.terminating : true;

  // Task Type Filter
  const taskTypeIds = filtersByField.get("open_task_types")?.value as
    | number[]
    | undefined;
  const taskTypeIdSet = taskTypeIds && new Set(taskTypeIds);
  const isTypeValid = taskTypeIdSet
    ? (task: FlexTask) =>
        (task.taskType?.id && taskTypeIdSet.has(task.taskType?.id)) || false
    : () => true;

  // Task completion filter
  const taskCompletionDateRange = filtersByField.get("task_completion_date");
  const completedBetweenDates = taskCompletionDateRange
    ? (task: FlexTask) => {
        const { before, after } =
          taskCompletionDateRange.value as DateRangeFilter;
        const beforeDate = parseRelativeDateTime(before);
        const afterDate = parseRelativeDateTime(after);
        return Boolean(
          task?.completedTime &&
            beforeDate &&
            afterDate &&
            task.completedTime <= beforeDate &&
            task.completedTime >= afterDate
        );
      }
    : () => true;

  return (task: FlexTask) =>
    isAssigneeValid(task) &&
    isStatusValid(task) &&
    isTypeValid(task) &&
    completedBetweenDates(task);
};

export {
  isFilterValid,
  toggleSortOrderDirection,
  findFilterIndex,
  buildShowTaskFn,
};
