import type { SidebarProps } from "./Sidebar.types";
import { Children, useState } from "react";
import { useResponsive } from "@/hooks";

const Sidebar = ({ tabs, children }: SidebarProps) => {
  const [activeTab, setTab] = useState<number>(0);
  const childArray = Children.toArray(children);
  if (childArray.length !== tabs.length) {
    throw new Error("Mismatched tabs and child element count.");
  }
  const { isMd } = useResponsive();
  return (
    <div className="w-full md:h-full md:bg-bluegray-50 md:border-l border-black border-opacity-11 flex flex-col">
      <div className="hidden md:block p-6 pb-0 bg-white space-x-6 text-lg border-b border-black border-opacity-6">
        {Object.values(tabs).map((x, ix) => (
          <button
            type="button"
            key={`${x}-tab-btn`}
            onClick={() => setTab(ix)}
            data-testid={`${x}-sidebar-tab-button`}
            className={`font-semibold border-b-2 pb-2 ${
              activeTab === ix
                ? "text-blue-800 border-blue-800"
                : "text-black text-opacity-66 border-transparent"
            }`}
          >
            {x}
          </button>
        ))}
      </div>
      <div className="flex-grow overflow-hidden">
        <div
          style={{
            transform: isMd ? `translate3d(-${activeTab * 100}%, 0, 0)` : "",
          }}
          className="flex flex-col md:flex-row md:h-full transition duration-200"
        >
          {childArray.map((child, ix) => (
            /* eslint react/no-array-index-key: 0 */
            <div key={ix} className="flex-shrink-0 h-full w-full">
              {activeTab === ix && child}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export { Sidebar };
