import type { LoadingIndicatorProps } from "./LoadingIndicator.types";
import classNames from "classnames";
import "./LoadingIndicator.css";

const LoadingIndicator = ({
  color = "brand",
  lineWeight = "medium",
}: LoadingIndicatorProps) => {
  const classes = classNames({
    "border-2": lineWeight === "thin",
    "border-4": lineWeight === "medium",
    "border-8": lineWeight === "heavy",
    "border-black": color === "dark",
    "border-white": color === "light",
    "border-brand-40": color === "brand",
  });
  return (
    <div className="urbint-loading-indicator" aria-busy aria-live="polite">
      <div
        className={classNames(classes, {
          "bg-black": color === "dark",
          "bg-white": color === "light",
          "bg-brand-40": color === "brand",
        })}
      />
      <div className={classes} />
      <div className={classes} />
    </div>
  );
};

export { LoadingIndicator };
