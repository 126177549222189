import type {
  TicketQueue,
  QueuedTicketStatus,
  TicketQueueItem,
} from "@/models";
import type { TicketQueueSerializer } from "@/utils/damage-prevention";
import { useMutation } from "@tanstack/react-query";
import { UrbintApi } from "@/utils/UrbintApi";
import { handleApiResponse } from "@/api/helpers";
import { invalidateCurrentTicketQueue } from "@/api";

const api = new UrbintApi();
const updateItem = (props: {
  item: TicketQueueItem;
  queue: TicketQueue;
  status: QueuedTicketStatus;
}) => {
  const body = {
    item_id: props.item.id,
    visited: props.status === "visited",
  };
  return api
    .fetch<TicketQueueSerializer>(
      `ticket_queues/${props.queue.id}/item_update`,
      {
        method: "POST",
        body: JSON.stringify(body),
      }
    )
    .then(handleApiResponse);
};

/**
 * Marks an item in the queue as visited or not visited
 */
const useUpdateTicketQueueItem = () =>
  useMutation(updateItem, { onSuccess: invalidateCurrentTicketQueue });

export { useUpdateTicketQueueItem };
