import "./MenuPanel.css";
import type { MenuPanelProps } from "./types";
import { Transition } from "@headlessui/react";
import Tippy from "@tippyjs/react/headless";
import classNames from "classnames";

/**
 * Handles menu animations, positioning and styling.
 * 
 * ## Example:
 * ```tsx
  export const MyMenu = () => {
    // control the menu open / close state
    const [visible, setVisible] = useState(args.show ?? false);
    const show = () => setVisible(true);
    const hide = () => setVisible(false);
    
    const renderButton = () => (
      <button onClick={visible ? hide : show}>Click me!</button>
    );

    return (
      <MenuPanel
        onClickOutside={hide}
        renderButton={renderButton}
        show={visible}
      />
    );
  };
 * ```
 */
const MenuPanel = ({
  arrow,
  children,
  className,
  onClickOutside,
  onClickOutsideMenus,
  placement = "bottom",
  renderButton,
  show,
  onClose,
  reference,
  disabled = false,
  ...divProps
}: MenuPanelProps) => (
  <Tippy
    allowHTML
    appendTo={document.body}
    arrow={arrow}
    interactive
    offset={[0, 6]}
    reference={reference ?? undefined}
    disabled={disabled}
    onClickOutside={(_, event) => {
      if (onClickOutside) {
        onClickOutside(event);
      }

      if (onClickOutsideMenus) {
        const isOtherMenu = (event.target as HTMLElement)?.closest(
          ".menu-panel"
        );
        if (isOtherMenu) return;
        onClickOutsideMenus(event);
      }
    }}
    placement={placement}
    popperOptions={{
      modifiers: [
        {
          name: "preventOverflow",
          options: { enabled: true, padding: 8 },
        },
        { name: "flip", options: { enabled: true } },
      ],
    }}
    visible
    render={(attrs) => (
      <Transition
        {...divProps}
        {...attrs}
        as="div"
        className={classNames("menu-panel", className)}
        leave="transition linear duration-300 motion-reduce:transition-none"
        leaveFrom="transform opacity-100"
        leaveTo="transform opacity-0"
        show={show}
        unmount={false}
        afterLeave={onClose}
      >
        {arrow ? <div data-popper-arrow /> : null}
        <div className="overflow-y-auto overflow-x-hidden flex-1 flex flex-col rounded">
          {children}
        </div>
      </Transition>
    )}
  >
    {renderButton && renderButton()}
  </Tippy>
);

export { MenuPanel };
